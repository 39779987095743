import dayjs from 'dayjs'
import { store } from 'src/App'
import { TIME_PERIOD } from 'src/constants/expense'
import { COLUMN_NAME, TYPE_OF_VALUE } from 'src/constants/report/expense'
import { generateFilterData } from 'src/redux/slices/expense/events'
import _ from 'lodash'

export const redirectToReport = (history, monthNumber) => {
  const {
    filtersSummary,
    timePeriod,
    yearBudget,
    dateRange: { startDate },
  } = store.getState().expense.dashboard

  let year = Number(yearBudget)
  if (timePeriod === TIME_PERIOD.CUSTOM) {
    year = Number(dayjs(startDate).format('YYYY'))
  }
  const daysInMonth = dayjs(`${year}-${monthNumber}`).daysInMonth()

  const filters = _.map(filtersSummary, (filter) => {
    return {
      ...filter,
      label:
        _.get(filter, 'key', '') === COLUMN_NAME.OLD_ACCOUNT_CODE
          ? _.join(_.get(filter, 'oldAccLabel', []), ', ')
          : _.join(_.get(filter, 'label', []), ', '),
    }
  })

  filters.push(
    generateFilterData({
      id: _.maxBy(filters, 'id')?.id + 1,
      key: 'TRAINING_DATE',
      value: {
        startDate: `${year}-${monthNumber}-1`,
        endDate: `${year}-${monthNumber}-${daysInMonth}`,
      },
      type: TYPE_OF_VALUE.DATE_RANGE,
      label: `${dayjs(`${year}-${monthNumber}-1`).format(
        'DD/MM/YYYY',
      )} -  ${dayjs(`${year}-${monthNumber}-${daysInMonth}`).format(
        'DD/MM/YYYY',
      )}`,
    }),
  )

  localStorage.setItem('filters_expense', JSON.stringify(filters))
  history.push(`/expense/report?hasFiltered=true`)
}
