import _ from 'lodash'
import { store } from '../../../App'
import {
  resetTablePage,
  setFilterProp,
  setInitialTable,
  setSearchText,
  setStatusFilter,
  startLoading,
  stopLoading,
  setFilterTotal,
  resetPage,
} from '../../../redux/slices/table'
import {
  closeDialog,
  loadingDialog,
  openDialog,
  stopLoadingDialog,
} from '../../../redux/slices/dialog'
import { downloadFile } from '../../../services/util'
import { deleteCourse } from '../../../services/eLearning/course/form'
import callAxios from '../../../utils/baseService'
import {
  COURSE_STATUS,
  COURSE_TYPE,
  MODULE_STATUS,
  headCellsCourse,
} from '../../../constants/eLearning'
import {
  eLearningCourseDownload,
  eLearningCourseFilter,
} from '../../../utils/apiPath'
import { convertFormatDateTime } from '../../../utils/lib'
import { DEFAULT_COURSE_TYPE, DEFUALT_MODULE_STATUS } from './model'
import { COURSE_TYPE_TH } from './Form/model'

export const onDownload = (selected, sort, order) => async (dispatch) => {
  dispatch(startLoading())
  const body = {
    order: order.toUpperCase(),
    sort: sort,
    type: 'CUSTOM',
    list: selected,
  }

  await dispatch(
    downloadFile({
      url: eLearningCourseDownload,
      body: body,
      fileName: `รายการE-Learning.xlsx`,
    }),
  )
  dispatch(stopLoading())
}

export const handleDeleteModule = (row, table) => async (dispatch) => {
  dispatch(
    openDialog({
      title: 'ยืนยันลบหลักสูตร',
      message: 'คุณต้องการลบหลักสูตรใช่หรือไม่',
      colorDisagreeText: 'error',
      colorAgreeText: 'error',
      handleConfirm: () => dispatch(handleConfirmDelete(row.uuid, table)),
    }),
  )
}

export const handleConfirmDelete = (uuid, table) => async (dispatch) => {
  dispatch(loadingDialog())
  await dispatch(deleteCourse(uuid))
  dispatch(stopLoadingDialog())
  dispatch(closeDialog())

  dispatch(startLoading())
  await dispatch(
    fetchDataList({
      method: 'initial',
      table,
      page: 1,
      status: [],
      keyword: '',
    }),
  )
  dispatch(stopLoading())
}

export const fetchDataList = (props) => async (dispatch) => {
  const { method, page, status, keyword, setInitial } = props
  const { table, filter } = store.getState().table
  const isInitial = method == 'initial'
  const realPage = page <= 0 ? 1 : +page
  const filterProp = _.get(filter, 'filterProp', {})
  const title = isInitial ? '' : _.get(filterProp, 'name', keyword)
  const body = {
    limit: _.get(table, 'limit', '20'),
    page: realPage,
    order: table.order.toString().toUpperCase(),
    sort: _.get(table, 'sort', 'updatedAt'),
    status: isInitial ? DEFUALT_MODULE_STATUS : status,
    courseType: _.get(filterProp, 'courseType', DEFAULT_COURSE_TYPE),
    courseCode: _.get(filterProp, 'courseCode', ''),
    question: _.get(filterProp, 'question', []),
    name: method === 'filter' ? keyword : title,
    nameForLearner:
      method === 'filter'
        ? _.get(filterProp, 'nameForLearner', keyword)
        : keyword,
    createdBy: _.get(filterProp, 'createdBy', ''),
    updatedBy: _.get(filterProp, 'updatedBy', ''),
  }

  dispatch(startLoading())
  const isCancel = await callAxios
    .post(eLearningCourseFilter, body)
    .then((res) => {
      const row = _.get(res, 'data.result', []).map((item) => ({
        ...item,
        courseType: COURSE_TYPE_TH[_.lowerCase(item.courseType)],
        nameForLearner: _.get(item, 'nameForLearner', '-') || '-',
        createdAt: convertFormatDateTime({
          value: item.createdAt,
          type: 'date',
        }),
      }))
      dispatch(
        setInitialTable({
          rows: row,
          allCount: _.get(res, 'data.totalCount', 0),
          headCells: headCellsCourse,
          placeholder: 'ค้นหา',
          searchKey: 'name',
          status: status,
          handleSearch: (text) => dispatch(handleQuickSearch(table, text)),
          onDownload: (selected, sort, order) =>
            dispatch(onDownload(selected, sort, order)),
          onDelete: (row) => dispatch(handleDeleteModule(row, table)),
        }),
      )
    })
    .catch((e) => {
      dispatch(
        setInitialTable({ rows: [], allCount: 0, headCells: headCellsCourse }),
      )
      return e.message?.includes('method')
    })

  setInitial && setInitial(false)
  if (!isCancel) dispatch(stopLoading())
}

export const onFilterClick = (filter) => (dispatch) => {
  dispatch(setSearchText(''))

  let status = []
  if (filter.statusChecked) {
    if (filter.status.activeChecked) {
      status.push(MODULE_STATUS.ACTIVE)
    }
    if (filter.status.inActiveChecked) {
      status.push(MODULE_STATUS.INACTIVE)
    }
    if (filter.status.deletedChecked) {
      status.push(MODULE_STATUS.DELETED)
    }
    if (filter.status.draftChecked) {
      status.push(COURSE_STATUS.DRAFT)
    }
  } else {
    status = DEFUALT_MODULE_STATUS
  }
  dispatch(setStatusFilter(status))

  let typeList = []
  if (filter.courseTypeChecked) {
    if (filter.courseType.generalChecked) {
      typeList.push(COURSE_TYPE.GENERAL)
    }
    if (filter.courseType.oicChecked) {
      typeList.push(COURSE_TYPE.OIC)
    }
  } else {
    typeList = DEFAULT_COURSE_TYPE
  }

  let questionList = []
  if (filter.questionChecked) {
    if (filter.question.waitingChecked) {
      questionList.push('WAITING')
    }
    if (filter.question.answeredChecked) {
      questionList.push('ANSWERED')
    }
    if (filter.question.noAnswerChecked) {
      questionList.push('NO_ANSWER')
    }
  }

  const filterProp = {
    courseCode: filter.courseCodeChecked ? filter.courseCode : '',
    name: filter.nameChecked ? filter.name : '',
    nameForLearner: filter.nameForLearnerChecked ? filter.nameForLearner : '',
    courseType: typeList,
    status: status,
    question: questionList,
    createdBy: filter.createdByChecked ? filter.createdBy : '',
    updatedBy: filter.updatedByChecked ? filter.updatedBy : '',
  }
  dispatch(setFilterProp(filterProp))
  dispatch(setFilterTotal(filter))
  dispatch(resetTablePage())
  dispatch(
    fetchDataList({
      method: 'filter',
      page: 1,
      status,
      keyword: filterProp.name,
    }),
  )
}

export const handleQuickSearch = (table, keyword) => (dispatch) => {
  dispatch(setSearchText(keyword))
  dispatch(setFilterProp(null))
  dispatch(resetTablePage())
  if (table.page === 1) {
    dispatch(
      fetchDataList({
        method: 'search',
        page: 1,
        status: DEFUALT_MODULE_STATUS,
        keyword,
      }),
    )
  } else {
    dispatch(resetPage())
    dispatch(setStatusFilter(DEFUALT_MODULE_STATUS))
  }
}
