import _ from 'lodash'
import {
  closeDialog,
  openDialog,
  openErrorDialog,
} from '../../../../redux/slices/dialog'

export const checkScrollWidth = (setScroll) => {
  if (window !== undefined) setScroll(window.scrollY > 30)
}

export const handleValidate = (props, formikProps) => async (dispatch) => {
  const { setValues, validateForm } = formikProps
  setValues(props)

  const errorObj = await validateForm(props)
  if (_.isEmpty(errorObj)) return

  const errorList = handleErrorObject(errorObj)
  let message = 'กรุณากรอกข้อมูล : '
  errorList.forEach((item, index) => {
    const errorLength = errorList.length
    const and = index !== 0 && index === errorLength - 1 ? 'และ' : ''
    const comma = index < errorLength - 2 ? ',' : ''
    message = `${message} ${and}${item}${comma}`
  })
  dispatch(openErrorDialog({ title: 'กรุณากรอกข้อมูลให้ครบถ้วน', message }))
}

export const handleErrorObject = (errorObj) => {
  let errorList = []

  for (const key in errorObj) {
    const value = errorObj[key]

    if (typeof value == 'string') {
      const field = mapErrroField(key, value)
      errorList.push(field)
    } else {
      errorList = _.concat(errorList, handleErrorObject(value))
    }
  }

  return _.uniq(errorList)
}

export const mapErrroField = (field, value) => {
  switch (field) {
    case 'assignNee':
      return 'แอดมินหลัก'
    case 'startRegisterDateTime':
      return 'วันเปิดรับสมัคร'
    case 'endRegisterTime':
      return 'กรุณากรอกวันเวลาให้น้อยกว่าวันเวลาเริ่มอบรม'
    case 'endRegisterDateTime':
      return 'วันปิดรับสมัคร'
    case 'link':
      return ' Link ห้องเรียน'
    case 'learnerNumber':
      return 'จำนวนผู้เรียนต่อห้อง'
    case 'onlineRoom':
      if (value === 'required') return 'เพิ่มห้องเรียน'
      return 'จำนวนผู้เรียนต่อห้องทุกห้องรวมกันต้องเท่ากับจำนวนที่คาดหวัง'
    case 'uuid':
      return 'วิทยากรภายนอก, หน้าที่'
    case 'startTime':
    case 'endTime':
      return 'เวลาอบรมของวิทยากร'
    case 'meetingRooms':
    case 'meetingRoomUuid':
      return 'ห้องอบรม'
    case 'meetingRoomOther':
      return 'ห้องอบรม​ (Other)'
    default:
      return ''
  }
}

export const handleSuccess = (isSuccess, uuid) => (dispatch) => {
  if (isSuccess) {
    dispatch(
      openDialog({
        type: 'success',
        title: 'สำเร็จ',
        message: 'ข้อมูลถูกบันทึกเรียบร้อยแล้ว',
        handleConfirm: () => {
          dispatch(closeDialog())
          window.location.href = `/manage-class/detail/${uuid}`
        },
      }),
    )
  }
}

export const handleError = (error) => (dispatch) => {
  if (error) {
    dispatch(
      openErrorDialog({
        title: _.get(error, 'title'),
        message: _.get(error, 'message'),
      }),
    )
  }
}
