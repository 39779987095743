import React, { useState } from 'react'
import _ from 'lodash'
import Divider from '@mui/material/Divider'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'

import ExpandMoreOutlined from '@mui/icons-material/ExpandMoreOutlined'
import ExpandLess from '@mui/icons-material/ExpandLess'

import DatePicker from '../../../../../../../components/Input/DatePicker'
import SelectGroupChip from '../../../../../../../components/SelectGroupChip'
import History from './History'
import * as StyledForm from '../Styled'
import { BoxUl, BoxLi } from './Styled'
import {
  mapStatusTesting,
  handleChangeInfo,
  BoxInfo,
  mapAssignee,
  checkDateDisabled,
} from './event'

const Index = () => {
  const { id: uuid, codeId, createType } = useParams()
  const errors = { error: 'test' }
  const dispatch = useDispatch()
  const {
    setting,
    dateServer,
    listAssignee,
    isDraft,
    isClone,
    disableSetting,
    onViewMode,
    typeOfETesting,
    listExaminer,
  } = useSelector(
    (state) => ({
      setting: state.eTestingForm.setting,
      dateServer: state.eTestingForm.dateServer,
      listAssignee: state.eTestingForm.listAssignee,
      isDraft: state.eTestingForm.isDraft,
      isClone: state.eTestingForm.isClone,
      disableSetting: state.eTestingForm.disableSetting,
      onViewMode: state.eTestingForm.onViewMode,
      typeOfETesting: state.eTestingForm.typeOfETesting,
      listExaminer: state.eTestingForm.listExaminer,
    }),
    shallowEqual,
  )
  const [expand, setExpand] = useState({
    courseRef: false,
    history: false,
  })

  return (
    <>
      <StyledForm.BoxCard>
        <StyledForm.BoxForm>
          <StyledForm.BoxFlex>
            <DatePicker
              id="startDate"
              name="startDate"
              labelText="วันเริ่มต้นใช้งาน"
              placeholder=""
              required
              disabledInput={
                (disableSetting && !isDraft && !isClone ? true : false) ||
                onViewMode
              }
              value={_.get(setting, 'startDate', null)}
              onChange={(e) =>
                dispatch(handleChangeInfo({ value: e, key: 'startDate' }))
              }
              textError={_.get(errors, 'setting.startDate', '')}
              disabledStartDate={null}
              disabledEndDate={null}
              currentDate={
                (isDraft || isClone) && !codeId && !createType
                  ? checkDateDisabled(
                      _.get(setting, 'oldStartDate', null),
                      dateServer,
                    )
                  : dateServer
              }
              hideTextError={
                _.get(errors, 'setting.startDate', '') ? false : true
              }
            />
          </StyledForm.BoxFlex>
          <StyledForm.BoxFlex isSwitch>
            <Typography variant="body1">สถานะของข้อสอบ</Typography>
            <Typography
              variant="body1"
              color={
                _.get(setting, 'status', 'DRAFT') == 'ACTIVE'
                  ? 'primary'
                  : 'text.secondary'
              }
            >
              {mapStatusTesting(_.get(setting, 'status', 'DRAFT'))}
            </Typography>
          </StyledForm.BoxFlex>
          <Box>
            <StyledForm.BoxFlex isSwitch>
              <Typography variant="body1">
                หลักสูตรที่นำไปใช้ ({_.get(setting, 'courseRef', []).length})
              </Typography>
              <IconButton
                data-testid="expand-corseRef"
                onClick={() =>
                  setExpand((prevExpand) => ({
                    ...prevExpand,
                    courseRef: !prevExpand.courseRef,
                  }))
                }
              >
                {expand.courseRef && <ExpandLess color="primary" />}
                {!expand.courseRef && <ExpandMoreOutlined color="primary" />}
              </IconButton>
            </StyledForm.BoxFlex>
            {expand.courseRef && _.get(setting, 'courseRef', []).length > 0 && (
              <BoxUl>
                {_.get(setting, 'courseRef', []).map((item, index) => {
                  return (
                    <li key={index}>
                      <BoxLi>
                        <Typography
                          variant="body1"
                          sx={{ wordBreak: 'break-word' }}
                        >
                          {item.courseName}{' '}
                          <Typography variant="span" color="primary">
                            {item.nameForLearner}
                          </Typography>{' '}
                          เวอร์ชั่น {item.version}
                        </Typography>
                        <Typography variant="body1" color="text.silver">
                          {item.isOnTesting && '(On testing)'}
                        </Typography>
                      </BoxLi>
                    </li>
                  )
                })}
              </BoxUl>
            )}

            <Divider sx={{ mt: 2 }} />
          </Box>
          <StyledForm.BoxFlex>
            <SelectGroupChip
              id="assignee"
              name="assignee"
              labelText="ผู้ร่วมจัดทำ"
              placeholder=""
              options={listAssignee}
              showTextError={false}
              isMenuPosition={true}
              disabled={onViewMode}
              value={mapAssignee(_.get(setting, 'assignees', []))}
              onChange={(list) =>
                dispatch(
                  handleChangeInfo({
                    value: list,
                    key: 'assignees',
                  }),
                )
              }
            />
          </StyledForm.BoxFlex>
          {typeOfETesting === 'STANDALONE' && (
            <StyledForm.BoxFlex>
              <SelectGroupChip
                id="examiner"
                name="examiner"
                labelText="ผู้ตรวจข้อสอบ"
                placeholder=""
                options={listExaminer}
                showTextError={false}
                isMenuPosition={true}
                disabled={onViewMode || disableSetting}
                value={mapAssignee(_.get(setting, 'examiners', []))}
                onChange={(list) =>
                  dispatch(
                    handleChangeInfo({
                      value: list,
                      key: 'examiners',
                    }),
                  )
                }
              />
            </StyledForm.BoxFlex>
          )}
          <StyledForm.BoxFlex>
            <BoxInfo
              labelText="วันที่สร้าง"
              labelValue={_.get(setting, 'createdAt', '-')}
            />
            <BoxInfo
              labelText="สร้างโดย"
              labelValue={_.get(setting, 'createdBy.name', '-')}
            />
          </StyledForm.BoxFlex>
          <StyledForm.BoxFlex>
            <BoxInfo
              labelText="วันที่แก้ไขล่าสุด"
              labelValue={_.get(setting, 'updatedAt', '-')}
            />
            <BoxInfo
              labelText="แก้ไขล่าสุดโดย"
              labelValue={_.get(setting, 'updatedBy.name', '-')}
              isIcon={uuid && !isClone ? true : false}
              onChangeHistory={() =>
                setExpand((prevExpand) => ({
                  ...prevExpand,
                  history: !prevExpand.history,
                }))
              }
            />
          </StyledForm.BoxFlex>
        </StyledForm.BoxForm>
      </StyledForm.BoxCard>

      <History
        isOpen={expand.history}
        onCloseDrawer={() =>
          setExpand((prevExpand) => ({
            ...prevExpand,
            history: !prevExpand.history,
          }))
        }
      />
    </>
  )
}

export default Index
