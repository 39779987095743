import { Box } from '@mui/material'
import { Column, LoadingPage } from '../../Styled'
import _ from 'lodash'
import { shallowEqual, useSelector } from 'react-redux'
import { CourseInfoWithAdminSelect } from '../CourseInfoWithAdminSelect'
import { TrainingRoomsByDate } from '../TrainingRoomsByDate'

const Content = () => {
  const { data, isLoadingManageAdminDrawer } = useSelector(
    (state) => ({
      data: state.manageClassForm.data,
      isLoadingManageAdminDrawer: state.manageClass.isLoadingManageAdminDrawer,
    }),
    shallowEqual,
  )

  const dates = _.get(data, 'monthlyPlan.dates', [])
  const trainingPlatForm = _.get(data, 'trainingPlatForm', [])
  const isClassroom = _.some(trainingPlatForm, {
    uuid: '92958e67-5503-4e60-adbc-fb269b68cf8d',
  })

  return (
    <Box>
      {isLoadingManageAdminDrawer ? (
        <LoadingPage isLoading={isLoadingManageAdminDrawer} />
      ) : (
        <Box
          sx={{
            overflowY: 'auto',
          }}
          height="100%"
          p={3}
        >
          <CourseInfoWithAdminSelect />
          <Column gap={1}>
            {_.map(dates, (date, idxD) => {
              return (
                <TrainingRoomsByDate
                  key={idxD}
                  idxD={idxD}
                  {...date}
                  isClassroom={isClassroom}
                />
              )
            })}
          </Column>
        </Box>
      )}
    </Box>
  )
}

export default Content
