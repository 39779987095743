import axios from 'axios'
import _ from 'lodash'
import { store } from '../../../../App'
import { enumStatus } from '../../../../components/CRUD/enum/status'
import { setFieldValue } from '../../../../redux/slices/crud'
import { CRUDListFilterPath } from '../../../../utils/apiPath'
import { getOptionsCostCenter } from '../mutation/getOptionsCostCenter'
import { getOptionsAccountCode } from '../mutation/getOptionsAccountCode'
import { getOptionsExpenseCategory } from '../mutation/getOptionsExpenseCategory'
import { yearBudgetOptions } from '../model/yearBudgetOptions'

export const bodyCostCenter = {
  limit: -1,
  page: 1,
  order: 'ASC',
  sort: 'updatedAt',
  quickSearch: '',
  name: '',
  costCenter: '',
  groupCode: '',
  updateStartDate: '',
  updateFinishDate: '',
  createStartDate: '',
  createFinishDate: '',
  updatedBy: '',
  status: [enumStatus.ACTIVE],
  createdBy: '',
}

const bodyAccountCode = {
  limit: -1,
  page: 1,
  order: 'ASC',
  sort: 'updatedAt',
  quickSearch: '',
  name: '',
  oldAccountCode: '',
  newAccountCode: '',
  category: '',
  updateStartDate: '',
  updateFinishDate: '',
  createStartDate: '',
  createFinishDate: '',
  updatedBy: '',
  status: [enumStatus.ACTIVE],
  createdBy: '',
}

export const bodyExpenseCategory = {
  name: '',
  order: 'ASC',
  sort: 'name',
  status: [enumStatus.ACTIVE],
  limit: -1,
  page: 1,
}

const listAPIFetchOptions = [
  {
    body: bodyCostCenter,
    url: CRUDListFilterPath,
    method: 'post',
    headers: {
      'x-type': 'COST_CENTER',
      key: 'costCenter',
    },
  },
  {
    body: bodyAccountCode,
    url: CRUDListFilterPath,
    method: 'post',
    headers: {
      'x-type': 'ACCOUNT_CODE',
      key: 'accountCode',
    },
  },
  {
    body: bodyExpenseCategory,
    url: CRUDListFilterPath,
    method: 'post',
    headers: {
      'x-type': 'EXPENSE_CATEGORY',
      key: 'category',
    },
  },
]

export const fetchMasterDataOptions = async () => {
  store.dispatch(setFieldValue({ key: 'isLoading', value: true }))
  const masterData = await Promise.all(
    _.map(listAPIFetchOptions, (item) => {
      return axios({
        method: item.method,
        url: item?.url,
        data: item?.body,
        headers: item?.headers,
      })
    }),
  ).then((response) => {
    return _.reduce(
      response,
      (acc, item) => {
        const key = _.get(item, 'config.headers.key', undefined)

        let options = []
        switch (key) {
          case 'costCenter':
            options = getOptionsCostCenter(_.get(item, 'data.result', []))
            break
          case 'accountCode':
            options = getOptionsAccountCode(_.get(item, 'data.result', []))
            break
          case 'category':
            options = getOptionsExpenseCategory(_.get(item, 'data.result', []))
            break
        }

        return {
          ...acc,
          [key]: { options: options, isLoading: false },
        }
      },
      {},
    )
  })

  store.dispatch(
    setFieldValue({
      key: 'masterData',
      value: {
        ...masterData,
        yearBudget: {
          isLoading: false,
          options: yearBudgetOptions(),
        },
      },
    }),
  )
  store.dispatch(setFieldValue({ key: 'isLoading', value: false }))
}
