import {
  Divider,
  TableContainer,
  Table,
  TableCell,
  TableHead,
  TableBody,
  TableRow,
  Typography,
  Paper,
  Button,
} from '@mui/material'
import React, { Fragment } from 'react'
import _ from 'lodash'
import { headersByCostCenter } from '../../models/headersByCostCenter'
import { useTheme } from '@mui/system'
import { ArrowForward } from '@mui/icons-material'
import { shallowEqual, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { StatusRow } from './StatusRow'

const SummaryByCostCenterSection = () => {
  const theme = useTheme()
  const { budgetActualByCostCenter } = useSelector(
    (state) => ({
      budgetActualByCostCenter:
        state.expense.dashboard.budgetActualByCostCenter,
    }),
    shallowEqual,
  )

  return (
    <>
      <Divider />
      <Typography variant="h6">แยกราย Cost Center / Department</Typography>

      <TableContainer
        component={Paper}
        sx={{
          border: `1px solid ${theme.palette.blue.blueLine}`,
          borderRadius: 2,
          boxShadow: 'none',
        }}
      >
        <Table>
          <TableHead>
            <TableRow>
              {_.map(headersByCostCenter, (header, idxH) => {
                return <TableCell key={idxH}>{header.label}</TableCell>
              })}
            </TableRow>
          </TableHead>
          <TableBody
            sx={{
              '& .MuiTableRow-root:last-of-type .MuiTableCell-root': {
                borderBottom: 'none',
              },
            }}
          >
            {_.map(budgetActualByCostCenter, (item, idx) => {
              return (
                <Fragment key={idx}>
                  <TableRow>
                    {_.map(headersByCostCenter, (header, idxH) => {
                      return (
                        <TableCell
                          key={idxH}
                          rowSpan={
                            header.id === 'costCenter' ||
                            header.id === 'viewDetails'
                              ? 2
                              : 1
                          }
                          sx={{
                            backgroundColor:
                              header.id === 'costCenter' ||
                              header.id === 'viewDetails'
                                ? theme.palette.common.white
                                : theme.palette.background.blue,
                          }}
                          align={
                            header.id === 'budget' || header.id === 'actual'
                              ? 'right'
                              : 'left'
                          }
                          width={header?.width}
                        >
                          {header.id === 'costCenter' ? (
                            item[header.id]
                          ) : header.id === 'viewDetails' ? (
                            <Link to={`/expense/dashboard/${item['uuid']}`}>
                              <Button
                                color="primary"
                                variant="text"
                                sx={{ ml: 1 }}
                                endIcon={<ArrowForward />}
                              >
                                แสดงรายละเอียด
                              </Button>
                            </Link>
                          ) : header.id === 'status' ? (
                            <StatusRow
                              data={item}
                              elementId={`percentAllYearChart-${idx}`}
                            />
                          ) : (
                            item[`${header.id}AllYear`]
                          )}
                        </TableCell>
                      )
                    })}
                  </TableRow>
                  <TableRow>
                    {_.map(headersByCostCenter, (header, idxH) => {
                      if (
                        header.id === 'costCenter' ||
                        header.id === 'viewDetails'
                      )
                        return null
                      return (
                        <TableCell
                          key={idxH}
                          sx={{
                            background: theme.palette.info.background,
                          }}
                          align={
                            header.id === 'budget' || header.id === 'actual'
                              ? 'right'
                              : 'left'
                          }
                        >
                          {header.id === 'status' ? (
                            <StatusRow
                              data={item}
                              keyPC="YTD"
                              elementId={`percentYTDChart-${idx}`}
                            />
                          ) : (
                            item[`${header.id}YTD`]
                          )}
                        </TableCell>
                      )
                    })}
                  </TableRow>
                </Fragment>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default SummaryByCostCenterSection
