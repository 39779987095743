import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import HistoryDrawer from '../../../components/View/HistoryDrawer'
import { fetchDataHistory } from './events'

const History = ({ isOpen, onCloseDrawer }) => {
  const { id: uuid } = useParams()
  const dispatch = useDispatch()
  const table = useSelector((state) => state.basicTable)
  const { page, sort, limit } = table

  useEffect(() => {
    isOpen && dispatch(fetchDataHistory({ uuid, table }))
  }, [isOpen, page, sort, limit])

  return <HistoryDrawer isOpen={isOpen} onCloseDrawer={onCloseDrawer} />
}

export default History
