import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import visuallyHidden from '@mui/utils/visuallyHidden'
import InboxTwoTone from '@mui/icons-material/InboxTwoTone'

import {
  StyledTableCell,
  StyledTableRow,
  StyledTableBodyCell,
  StyledTableSortLabel,
} from './Styled'
import * as events from './events'
import Typography from '@mui/material/Typography'
import _ from 'lodash'

import { ROW_PAGE_PER_LIST } from '../../../constants/table'

function EnhancedTableHead(props) {
  const { headCells, onRequestSort } = props

  return (
    <TableHead>
      <StyledTableRow>
        {headCells &&
          headCells.map((headCell) => {
            const valueId = headCell.id
            const order = headCell?.order
              ? String(headCell?.order).toLowerCase()
              : undefined
            return (
              <StyledTableCell
                key={headCell.id}
                align={headCell.align ? headCell.align : 'left'}
                sortDirection={order}
              >
                <StyledTableSortLabel
                  data-testid={`sort-cell-${valueId}`}
                  active={!_.isEmpty(order)}
                  direction={order ? order : 'asc'}
                  onClick={() => {
                    !headCell.hideSortIcon && onRequestSort(valueId, headCells)
                  }}
                  hideSortIcon={headCell.hideSortIcon}
                  headcell={headCell}
                >
                  {headCell.label}
                  {headCell?.id === valueId && (
                    <Box component="span" sx={visuallyHidden}>
                      {order === 'desc'
                        ? 'sorted descending'
                        : 'sorted ascending'}
                    </Box>
                  )}
                </StyledTableSortLabel>
              </StyledTableCell>
            )
          })}
      </StyledTableRow>
    </TableHead>
  )
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
}

export default function NormalTable({
  headCells,
  listRows,
  totalRows,
  showPagination,
  isHiddenOptions,
  stopLoading,
  startLoading,
  handlePagination,
  onRequestSort,
  minWidth = 750,
}) {
  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('')
  const [selected, setSelected] = useState([])
  const [page, setPage] = useState(0)

  const [rowsPerPage, setRowsPerPage] = useState(100)

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = listRows.map((n) => n.name)
      setSelected(newSelected)
      return
    }
    setSelected([])
  }

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name)
    let newSelected = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      )
    }

    setSelected(newSelected)
  }

  const handleChangePage = (event, newPage) => {
    const realPage = newPage < 0 ? 1 : +newPage
    setPage(realPage)
    if (showPagination) {
      handlePagination({
        page: newPage + 1,
        limit: rowsPerPage,
      })
    }
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
    if (showPagination) {
      handlePagination({
        page: 0,
        limit: parseInt(event.target.value, 10),
      })
    }
  }

  return (
    <Box sx={{ width: '100%' }}>
      <TableContainer>
        <Table
          sx={{
            minWidth: minWidth,
          }}
          aria-labelledby="tableTitle"
        >
          <EnhancedTableHead
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={onRequestSort ? onRequestSort : handleRequestSort}
            rowCount={listRows.length}
            headCells={headCells}
          />
          <TableBody>
            {listRows.map((row, rowIndex) => {
              return (
                <StyledTableRow
                  hover
                  data-testid={`table-row-${rowIndex}`}
                  onClick={(event) => handleClick(event, row.name)}
                  tabIndex={-1}
                  key={`${row.name}-${rowIndex}`}
                >
                  {headCells.map((i, cellIndex) =>
                    events.checkConditionView(
                      headCells,
                      row,
                      rowIndex,
                      cellIndex,
                      startLoading,
                      stopLoading,
                    ),
                  )}
                </StyledTableRow>
              )
            })}
            {listRows.length === 0 && (
              <TableRow>
                <StyledTableBodyCell colSpan={headCells.length}>
                  <Box>
                    <InboxTwoTone />
                    <Typography variant="body2" color="text.lightGray">
                      ไม่พบข้อมูล
                    </Typography>
                  </Box>
                </StyledTableBodyCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {showPagination && (
        <TablePagination
          rowsPerPageOptions={isHiddenOptions ? '' : ROW_PAGE_PER_LIST}
          component={'div'}
          count={totalRows}
          rowsPerPage={rowsPerPage}
          labelRowsPerPage={isHiddenOptions ? '' : 'แถวต่อหน้า:'}
          page={page < 0 ? 1 : +page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{ '& .MuiSvgIcon-root': { top: 'unset !important' } }}
        />
      )}
    </Box>
  )
}
