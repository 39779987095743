import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { setReportConfig } from '../../../redux/slices/reportComp'
import Report from '../../../components/Report'
import { reportConfig, reportConfigPic } from './reportConfig'
import _ from 'lodash'

const Index = () => {
  const dispatch = useDispatch()
  const user = JSON.parse(localStorage.getItem('user'))

  useEffect(() => {
    const permissionList = _.get(user, 'permission', [])
    const hasPermissionALL = _.find(permissionList, (item) => {
      return (
        item.owner === 'ALL' &&
        item.permission === 'REPORT' &&
        item.module === 'INVENTORY'
      )
    })
    dispatch(
      setReportConfig({
        reportConfig: hasPermissionALL ? reportConfig : reportConfigPic,
      }),
    )
  }, [])

  return <Report user={user} />
}
export default Index
