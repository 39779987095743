import Box from '@mui/material/Box'
import styled from '@mui/system/styled'
import _ from 'lodash'

export const StyledEditorBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'textError',
})(({ theme, textError }) => {
  const borderColor = !_.isEmpty(textError) && theme?.palette?.error?.main
  return {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    '& .tox-tinymce': { borderColor },
    '& .jodit-react-container': {
      '& .jodit-container': { borderColor },
      '& .jodit-workplace': {
        height: '270px !important',
        maxHeight: '270px !important',
        '& *': { margin: '0px !important', whiteSpace: 'nowrap !important' },
      },
    },
  }
})
