import {
  setPayloadKey,
  startLoading2,
  stopLoading2,
} from '../../../../../redux/slices/manage/prospectProfile'
import axios from 'axios'
import {
  prospectProfileExamView,
  staffProfileExamView,
} from '../../../../../utils/apiPath'

export const fetchExam = (body) => async (dispatch) => {
  dispatch(startLoading2())
  const response = await axios.post(
    body?.type === 'NON_ACADEMY'
      ? staffProfileExamView
      : prospectProfileExamView,
    body,
  )
  dispatch(setPayloadKey({ key: 'exam', data: response?.data?.exam }))
  dispatch(stopLoading2())
}
