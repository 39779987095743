import React from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import {
  StyledCancelButton,
  StyledDialogActions,
  StyledLoadingButton,
} from '../../../../../../components/redux/PopUpDialog/Styled'
import { closeDialog } from '../../../../../../redux/slices/dialog'
import { handleImportFile, handleSubmitAdd } from './events'

const UploadBatch = () => {
  const { id: uuid } = useParams()
  const { isLoading, disableButton, fileName } = useSelector(
    (state) => ({
      isLoading: state.dialog.isLoading,
      disableButton: state.dialog.disableButton,
      fileName: state.eLearningCourseBatchDetail.fileName,
    }),
    shallowEqual,
  )
  const dispatch = useDispatch()
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      <label
        style={{
          display: 'flex',
          gap: 16,
          alignItems: 'center',
          padding: '0 16px',
        }}
        htmlFor="import-template"
      >
        <Button variant="contained" size="s" component="span">
          เลือกไฟล์
        </Button>
        <input
          style={{ display: 'none' }}
          data-testid="import-template"
          id="import-template"
          accept=".xlsx, .xls, .csv"
          type="file"
          onChange={(e) => dispatch(handleImportFile(e))}
        />
        <Typography variant="body2" color="text.secondary">
          {fileName || 'No File Chosen'}
        </Typography>
      </label>
      <StyledDialogActions>
        <StyledCancelButton
          data-testid="btn-disagree"
          onClick={() => dispatch(closeDialog())}
          variant="outlined"
          color="primary"
        >
          ยกเลิก
        </StyledCancelButton>
        <StyledLoadingButton
          data-testid="btn-agree"
          loading={isLoading}
          color="primary"
          endIcon={<></>}
          disabled={disableButton}
          onClick={() => dispatch(handleSubmitAdd(uuid))}
        >
          บันทึก
        </StyledLoadingButton>
      </StyledDialogActions>
    </Box>
  )
}

export default UploadBatch
