import { fetchMasterDataOptions } from '../../components/Report/handler/fetchMasterDataOptions'
import { fetchOptionsReportByKey } from '../../components/Report/handler/fetchOptionsReportByKey'

export const reportMixModuleMcExpenseConst = {
  COLUMN_NAME: {
    NO: 'no',
    COURSE_CODE: 'courseCode',
    COURSE_NAME_EN: 'courseNameEN',
    COURSE_NAME_TH: 'courseNameTH',
    COURSE_NAME_LEARNER: 'courseNameForLearner',
    TRAINING_PLATFORM: 'trainingPlatform',
    TRAINING_DATE: 'traningDate',
    STATION: 'station',
    EXPECT_NUMBER_PEOPLE: 'expectNumberPeople',
    COUNT_CLASS: 'countClass',
    COUNT_STUDENT: 'countStudent',
    COUNT_SEAT_EMPTY: 'countSeatEmpty',
    COUNT_NOT_ATTENDANCE: 'countNotAttendance',
    COUNT_ATTENDANCE: 'countAttendance',
    COUNT_PASS: 'countPass',
    COUNT_NOT_PASS: 'countNotPass',
    STATUS_CLASS: 'statusClass',
    STATION_NAME: 'station',
    EXPENSE_SUMMARY: 'expenseSummary',
    COST_PER_HEAD: 'costPerHead',
    YEAR_BUDGET: 'yearBudget',
    COST_CENTER_1: 'costCenter1',
    COST_CENTER_2: 'costCenter2',
    COST_CENTER_3: 'costCenter3',
    COST_CENTER_4: 'costCenter4',
    COST_CENTER_5: 'costCenter5',
    COST_CENTER_6: 'costCenter6',
    COST_CENTER_7: 'costCenter7',
    COST_CENTER_8: 'costCenter8',
    COST_CENTER_9: 'costCenter9',
    COST_CENTER_10: 'costCenter10',
    COST_CENTER_11: 'costCenter11',
    COST_CENTER_12: 'costCenter12',
    COST_CENTER_13: 'costCenter13',
    COST_CENTER_14: 'costCenter14',
    COST_CENTER_15: 'costCenter15',
    ACCOUNT_CODE_OLD_1: 'accountCodeOld1',
    ACCOUNT_CODE_OLD_2: 'accountCodeOld2',
    ACCOUNT_CODE_OLD_3: 'accountCodeOld3',
    ACCOUNT_CODE_OLD_4: 'accountCodeOld4',
    ACCOUNT_CODE_OLD_5: 'accountCodeOld5',
    ACCOUNT_CODE_OLD_6: 'accountCodeOld6',
    ACCOUNT_CODE_OLD_7: 'accountCodeOld7',
    ACCOUNT_CODE_OLD_8: 'accountCodeOld8',
    ACCOUNT_CODE_OLD_9: 'accountCodeOld9',
    ACCOUNT_CODE_OLD_10: 'accountCodeOld10',
    ACCOUNT_CODE_OLD_11: 'accountCodeOld11',
    ACCOUNT_CODE_OLD_12: 'accountCodeOld12',
    ACCOUNT_CODE_OLD_13: 'accountCodeOld13',
    ACCOUNT_CODE_OLD_14: 'accountCodeOld14',
    ACCOUNT_CODE_OLD_15: 'accountCodeOld15',
    ACCOUNT_CODE_NEW_1: 'accountCodeNew1',
    ACCOUNT_CODE_NEW_2: 'accountCodeNew2',
    ACCOUNT_CODE_NEW_3: 'accountCodeNew3',
    ACCOUNT_CODE_NEW_4: 'accountCodeNew4',
    ACCOUNT_CODE_NEW_5: 'accountCodeNew5',
    ACCOUNT_CODE_NEW_6: 'accountCodeNew6',
    ACCOUNT_CODE_NEW_7: 'accountCodeNew7',
    ACCOUNT_CODE_NEW_8: 'accountCodeNew8',
    ACCOUNT_CODE_NEW_9: 'accountCodeNew9',
    ACCOUNT_CODE_NEW_10: 'accountCodeNew10',
    ACCOUNT_CODE_NEW_11: 'accountCodeNew11',
    ACCOUNT_CODE_NEW_12: 'accountCodeNew12',
    ACCOUNT_CODE_NEW_13: 'accountCodeNew13',
    ACCOUNT_CODE_NEW_14: 'accountCodeNew14',
    ACCOUNT_CODE_NEW_15: 'accountCodeNew15',
    EXPENSE_1: 'expense1',
    EXPENSE_2: 'expense2',
    EXPENSE_3: 'expense3',
    EXPENSE_4: 'expense4',
    EXPENSE_5: 'expense5',
    EXPENSE_6: 'expense6',
    EXPENSE_7: 'expense7',
    EXPENSE_8: 'expense8',
    EXPENSE_9: 'expense9',
    EXPENSE_10: 'expense10',
    EXPENSE_11: 'expense11',
    EXPENSE_12: 'expense12',
    EXPENSE_13: 'expense13',
    EXPENSE_14: 'expense14',
    EXPENSE_15: 'expense15',
    //filter
    START_CLASS_DATE: 'startClassDate',
    END_CLASS_DATE: 'endClassDate',
    HOURS: 'hours',
    START_CLASS_DATE_TIME: 'startClassDateTime',
    END_CLASS_DATE_TIME: 'endClassDateTime',
    CLOSE_CLASS_DATE_TIME: 'closeClassDateTime',
    DIFF_CLASS_DATE_TIME: 'diffClassDateTime',
    STATUS_RESULT: 'statusResult',
    COST_CENTER: 'costCenter',
    COST_CENTER_FILTER: 'costCenter',
    COST_CENTER_NAME: 'costCenterName',
    COST_CENTER_NAME_FILTER: 'costCenterName',
    ACCOUNT_CODE_NEW: 'accountCodeNew',
    ACCOUNT_CODE_OLD: 'accountCodeOld',
    DESCRIPTION: 'description',
    CATEGORY: 'category',
    ROOM_NAME_OTHER: 'roomNameOther',
  },
  COLUMN_NAME_TH: {
    NO: 'ลำดับ',
    COURSE_CODE: 'รหัสหลักสูตร',
    COURSE_NAME_EN: 'ชื่อหลักสูตร EN',
    COURSE_NAME_TH: 'ชื่อหลักสูตร TH',
    COURSE_NAME_LEARNER: 'ชื่อหลักสูตรสำหรับ Learner',
    TRAINING_PLATFORM: 'รูปแบบการสอน',
    TRAINING_DATE: 'Training Date',
    STATION: 'Station',
    EXPECT_NUMBER_PEOPLE: 'จำนวนที่คาดหวัง',
    COUNT_CLASS: 'จำนวน Class',
    COUNT_STUDENT: 'จำนวนสมัคร',
    COUNT_SEAT_EMPTY: 'จำนวนที่ว่าง',
    COUNT_NOT_ATTENDANCE: 'จำนวนคนไม่เข้าอบรม',
    COUNT_ATTENDANCE: 'จำนวนผู้เข้าเรียน (Attendee)',
    COUNT_PASS: 'ผ่าน',
    COUNT_NOT_PASS: 'ไม่ผ่าน',
    STATUS_CLASS: 'statusClass',
    // STATION_NAME: 'stationName',
    EXPENSE_SUMMARY: 'Expense summary',
    COST_PER_HEAD: 'Cost / Head',
    YEAR_BUDGET: 'ปี Budget',
    COST_CENTER_1: 'Cost Center_1',
    COST_CENTER_2: 'Cost Center_2',
    COST_CENTER_3: 'Cost Center_3',
    COST_CENTER_4: 'Cost Center_4',
    COST_CENTER_5: 'Cost Center_5',
    COST_CENTER_6: 'Cost Center_6',
    COST_CENTER_7: 'Cost Center_7',
    COST_CENTER_8: 'Cost Center_8',
    COST_CENTER_9: 'Cost Center_9',
    COST_CENTER_10: 'Cost Center_10',
    COST_CENTER_11: 'Cost Center_11',
    COST_CENTER_12: 'Cost Center_12',
    COST_CENTER_13: 'Cost Center_13',
    COST_CENTER_14: 'Cost Center_14',
    COST_CENTER_15: 'Cost Center_15',
    ACCOUNT_CODE_OLD_1: 'Account Code (Old)_1',
    ACCOUNT_CODE_OLD_2: 'Account Code (Old)_2',
    ACCOUNT_CODE_OLD_3: 'Account Code (Old)_3',
    ACCOUNT_CODE_OLD_4: 'Account Code (Old)_4',
    ACCOUNT_CODE_OLD_5: 'Account Code (Old)_5',
    ACCOUNT_CODE_OLD_6: 'Account Code (Old)_6',
    ACCOUNT_CODE_OLD_7: 'Account Code (Old)_7',
    ACCOUNT_CODE_OLD_8: 'Account Code (Old)_8',
    ACCOUNT_CODE_OLD_9: 'Account Code (Old)_9',
    ACCOUNT_CODE_OLD_10: 'Account Code (Old)_10',
    ACCOUNT_CODE_OLD_11: 'Account Code (Old)_11',
    ACCOUNT_CODE_OLD_12: 'Account Code (Old)_12',
    ACCOUNT_CODE_OLD_13: 'Account Code (Old)_13',
    ACCOUNT_CODE_OLD_14: 'Account Code (Old)_14',
    ACCOUNT_CODE_OLD_15: 'Account Code (Old)_15',
    ACCOUNT_CODE_NEW_1: 'Account Code (New)_1',
    ACCOUNT_CODE_NEW_2: 'Account Code (New)_2',
    ACCOUNT_CODE_NEW_3: 'Account Code (New)_3',
    ACCOUNT_CODE_NEW_4: 'Account Code (New)_4',
    ACCOUNT_CODE_NEW_5: 'Account Code (New)_5',
    ACCOUNT_CODE_NEW_6: 'Account Code (New)_6',
    ACCOUNT_CODE_NEW_7: 'Account Code (New)_7',
    ACCOUNT_CODE_NEW_8: 'Account Code (New)_8',
    ACCOUNT_CODE_NEW_9: 'Account Code (New)_9',
    ACCOUNT_CODE_NEW_10: 'Account Code (New)_10',
    ACCOUNT_CODE_NEW_11: 'Account Code (New)_11',
    ACCOUNT_CODE_NEW_12: 'Account Code (New)_12',
    ACCOUNT_CODE_NEW_13: 'Account Code (New)_13',
    ACCOUNT_CODE_NEW_14: 'Account Code (New)_14',
    ACCOUNT_CODE_NEW_15: 'Account Code (New)_15',
    EXPENSE_1: 'Expense_1',
    EXPENSE_2: 'Expense_2',
    EXPENSE_3: 'Expense_3',
    EXPENSE_4: 'Expense_4',
    EXPENSE_5: 'Expense_5',
    EXPENSE_6: 'Expense_6',
    EXPENSE_7: 'Expense_7',
    EXPENSE_8: 'Expense_8',
    EXPENSE_9: 'Expense_9',
    EXPENSE_10: 'Expense_10',
    EXPENSE_11: 'Expense_11',
    EXPENSE_12: 'Expense_12',
    EXPENSE_13: 'Expense_13',
    EXPENSE_14: 'Expense_14',
    EXPENSE_15: 'Expense_15',
    //filter
    START_CLASS_DATE: 'Training Period',
    END_CLASS_DATE: 'Training Period End',
    HOURS: 'เวลาจัดสอนทั้งหมด',
    START_CLASS_DATE_TIME: 'วันเวลาเริ่มคลาส',
    END_CLASS_DATE_TIME: 'วันเวลาสิ้นสุดคลาส',
    CLOSE_CLASS_DATE_TIME: 'วันเวลาปิดคลาส',
    DIFF_CLASS_DATE_TIME: 'วันเวลา Diff คลาส',
    STATUS_RESULT: 'สถานะการอบรม',
    COST_CENTER: 'Cost Center',
    COST_CENTER_NAME: 'Cost Center Name',
    ACCOUNT_CODE_NEW: 'Account Code (New Code)',
    ACCOUNT_CODE_OLD: 'Account Code (Old Code)',
    DESCRIPTION: 'Description',
    CATEGORY: 'Category',
    ROOM_NAME_OTHER: 'สถานที่ Other',
  },
  TYPE_OF_VALUE: {
    DATE_RANGE: 'DATE_RANGE',
    INPUT_NUMBER: 'INPUT_NUMBER',
    INPUT_TEXT: 'INPUT_TEXT',
    DROPDOWN_MULTI: 'DROPDOWN_MULTI',
    DROPDOWN_SINGLE: 'DROPDOWN_SINGLE',
    INPUT_NUMBER_RANGE: 'INPUT_NUMBER_RANGE',
  },
  trainingPlatformOptions: [
    { label: 'Classroom', value: 'Classroom' },
    { label: 'Virtual Classroom', value: 'Virtual Classroom' },
    // { label: 'E-Learning', value: 'E-Learning' },
  ],
  statusResultOptions: [
    { label: 'ผ่าน', value: 'PASS' },
    { label: 'ไม่ผ่าน', value: 'NOT_PASS' },
  ],
  statusOptions: [
    {
      label: 'Unassign',
      value: 'UNASSIGN',
    },
    {
      label: 'Todo',
      value: 'TODO',
    },
    {
      label: 'Open register',
      value: 'OPENREGISTRATION',
    },
    {
      label: 'Closed register',
      value: 'CLOSEREGISTRATION',
    },
    {
      label: 'In progress',
      value: 'INPROGRESS',
    },
    {
      label: 'Start class',
      value: 'STARTCLASS',
    },

    {
      label: 'End class',
      value: 'ENDCLASS',
    },

    {
      label: 'Closed class',
      value: 'CLOSED',
    },

    {
      label: 'Cancelled',
      value: 'CANCELED',
    },
  ],
}

export const reportMixModuleMcExpenseDefaultColumn = {
  defaultColumns: [
    {
      id: 1,
      name: reportMixModuleMcExpenseConst.COLUMN_NAME.NO,
      value: reportMixModuleMcExpenseConst.COLUMN_NAME.NO,
      label: reportMixModuleMcExpenseConst.COLUMN_NAME_TH.NO,
      minWidth: 60,
      align: 'center',
    },
    {
      id: 2,
      name: reportMixModuleMcExpenseConst.COLUMN_NAME.COURSE_CODE,
      value: reportMixModuleMcExpenseConst.COLUMN_NAME.COURSE_CODE,
      label: reportMixModuleMcExpenseConst.COLUMN_NAME_TH.COURSE_CODE,
      minWidth: 160,
    },
    {
      id: 3,
      name: reportMixModuleMcExpenseConst.COLUMN_NAME.COURSE_NAME_EN,
      value: reportMixModuleMcExpenseConst.COLUMN_NAME.COURSE_NAME_EN,
      label: reportMixModuleMcExpenseConst.COLUMN_NAME_TH.COURSE_NAME_EN,
      minWidth: 210,
    },
    {
      id: 4,
      name: reportMixModuleMcExpenseConst.COLUMN_NAME.COURSE_NAME_TH,
      value: reportMixModuleMcExpenseConst.COLUMN_NAME.COURSE_NAME_TH,
      label: reportMixModuleMcExpenseConst.COLUMN_NAME_TH.COURSE_NAME_TH,
      minWidth: 210,
    },
    {
      id: 5,
      name: reportMixModuleMcExpenseConst.COLUMN_NAME.COURSE_NAME_LEARNER,
      value: reportMixModuleMcExpenseConst.COLUMN_NAME.COURSE_NAME_LEARNER,
      label: reportMixModuleMcExpenseConst.COLUMN_NAME_TH.COURSE_NAME_LEARNER,
      minWidth: 210,
    },
    {
      id: 6,
      name: reportMixModuleMcExpenseConst.COLUMN_NAME.TRAINING_PLATFORM,
      value: reportMixModuleMcExpenseConst.COLUMN_NAME.TRAINING_PLATFORM,
      label: reportMixModuleMcExpenseConst.COLUMN_NAME_TH.TRAINING_PLATFORM,
      minWidth: 160,
    },
    {
      id: 7,
      name: reportMixModuleMcExpenseConst.COLUMN_NAME.TRAINING_DATE,
      value: reportMixModuleMcExpenseConst.COLUMN_NAME.TRAINING_DATE,
      label: reportMixModuleMcExpenseConst.COLUMN_NAME_TH.TRAINING_DATE,
      minWidth: 160,
    },
    {
      id: 8,
      name: reportMixModuleMcExpenseConst.COLUMN_NAME.STATION,
      value: reportMixModuleMcExpenseConst.COLUMN_NAME.STATION,
      label: reportMixModuleMcExpenseConst.COLUMN_NAME_TH.STATION,
      minWidth: 160,
    },
    {
      id: 9,
      name: reportMixModuleMcExpenseConst.COLUMN_NAME.EXPECT_NUMBER_PEOPLE,
      value: reportMixModuleMcExpenseConst.COLUMN_NAME.EXPECT_NUMBER_PEOPLE,
      label: reportMixModuleMcExpenseConst.COLUMN_NAME_TH.EXPECT_NUMBER_PEOPLE,
      minWidth: 160,
    },
    {
      id: 10,
      name: reportMixModuleMcExpenseConst.COLUMN_NAME.COUNT_CLASS,
      value: reportMixModuleMcExpenseConst.COLUMN_NAME.COUNT_CLASS,
      label: reportMixModuleMcExpenseConst.COLUMN_NAME_TH.COUNT_CLASS,
      minWidth: 160,
    },
    {
      id: 11,
      name: reportMixModuleMcExpenseConst.COLUMN_NAME.COUNT_STUDENT,
      value: reportMixModuleMcExpenseConst.COLUMN_NAME.COUNT_STUDENT,
      label: reportMixModuleMcExpenseConst.COLUMN_NAME_TH.COUNT_STUDENT,
      minWidth: 160,
    },
    {
      id: 12,
      name: reportMixModuleMcExpenseConst.COLUMN_NAME.COUNT_SEAT_EMPTY,
      value: reportMixModuleMcExpenseConst.COLUMN_NAME.COUNT_SEAT_EMPTY,
      label: reportMixModuleMcExpenseConst.COLUMN_NAME_TH.COUNT_SEAT_EMPTY,
      minWidth: 160,
    },
    {
      id: 13,
      name: reportMixModuleMcExpenseConst.COLUMN_NAME.COUNT_NOT_ATTENDANCE,
      value: reportMixModuleMcExpenseConst.COLUMN_NAME.COUNT_NOT_ATTENDANCE,
      label: reportMixModuleMcExpenseConst.COLUMN_NAME_TH.COUNT_NOT_ATTENDANCE,
      minWidth: 160,
    },
    {
      id: 14,
      name: reportMixModuleMcExpenseConst.COLUMN_NAME.COUNT_ATTENDANCE,
      value: reportMixModuleMcExpenseConst.COLUMN_NAME.COUNT_ATTENDANCE,
      label: reportMixModuleMcExpenseConst.COLUMN_NAME_TH.COUNT_ATTENDANCE,
      minWidth: 100,
    },
    {
      id: 15,
      name: reportMixModuleMcExpenseConst.COLUMN_NAME.COUNT_PASS,
      value: reportMixModuleMcExpenseConst.COLUMN_NAME.COUNT_PASS,
      label: reportMixModuleMcExpenseConst.COLUMN_NAME_TH.COUNT_PASS,
      minWidth: 100,
    },
    {
      id: 16,
      name: reportMixModuleMcExpenseConst.COLUMN_NAME.COUNT_NOT_PASS,
      value: reportMixModuleMcExpenseConst.COLUMN_NAME.COUNT_NOT_PASS,
      label: reportMixModuleMcExpenseConst.COLUMN_NAME_TH.COUNT_NOT_PASS,
      minWidth: 160,
    },
    {
      id: 17,
      name: reportMixModuleMcExpenseConst.COLUMN_NAME.EXPENSE_SUMMARY,
      value: reportMixModuleMcExpenseConst.COLUMN_NAME.EXPENSE_SUMMARY,
      label: reportMixModuleMcExpenseConst.COLUMN_NAME_TH.EXPENSE_SUMMARY,
      minWidth: 160,
    },
    {
      id: 18,
      name: reportMixModuleMcExpenseConst.COLUMN_NAME.COST_PER_HEAD,
      value: reportMixModuleMcExpenseConst.COLUMN_NAME.COST_PER_HEAD,
      label: reportMixModuleMcExpenseConst.COLUMN_NAME_TH.COST_PER_HEAD,
      minWidth: 160,
    },
    {
      id: 19,
      name: reportMixModuleMcExpenseConst.COLUMN_NAME.YEAR_BUDGET,
      value: reportMixModuleMcExpenseConst.COLUMN_NAME.YEAR_BUDGET,
      label: reportMixModuleMcExpenseConst.COLUMN_NAME_TH.YEAR_BUDGET,
      minWidth: 160,
    },
    {
      id: 20,
      name: reportMixModuleMcExpenseConst.COLUMN_NAME.COST_CENTER_1,
      value: reportMixModuleMcExpenseConst.COLUMN_NAME.COST_CENTER_1,
      label: reportMixModuleMcExpenseConst.COLUMN_NAME_TH.COST_CENTER_1,
      minWidth: 160,
    },
    {
      id: 21,
      name: reportMixModuleMcExpenseConst.COLUMN_NAME.COST_CENTER_2,
      value: reportMixModuleMcExpenseConst.COLUMN_NAME.COST_CENTER_2,
      label: reportMixModuleMcExpenseConst.COLUMN_NAME_TH.COST_CENTER_2,
      minWidth: 160,
    },
    {
      id: 22,
      name: reportMixModuleMcExpenseConst.COLUMN_NAME.COST_CENTER_3,
      value: reportMixModuleMcExpenseConst.COLUMN_NAME.COST_CENTER_3,
      label: reportMixModuleMcExpenseConst.COLUMN_NAME_TH.COST_CENTER_3,
      minWidth: 160,
    },
    {
      id: 23,
      name: reportMixModuleMcExpenseConst.COLUMN_NAME.COST_CENTER_4,
      value: reportMixModuleMcExpenseConst.COLUMN_NAME.COST_CENTER_4,
      label: reportMixModuleMcExpenseConst.COLUMN_NAME_TH.COST_CENTER_4,
      minWidth: 160,
    },
    {
      id: 24,
      name: reportMixModuleMcExpenseConst.COLUMN_NAME.COST_CENTER_5,
      value: reportMixModuleMcExpenseConst.COLUMN_NAME.COST_CENTER_5,
      label: reportMixModuleMcExpenseConst.COLUMN_NAME_TH.COST_CENTER_5,
      minWidth: 160,
    },
    {
      id: 25,
      name: reportMixModuleMcExpenseConst.COLUMN_NAME.COST_CENTER_6,
      value: reportMixModuleMcExpenseConst.COLUMN_NAME.COST_CENTER_6,
      label: reportMixModuleMcExpenseConst.COLUMN_NAME_TH.COST_CENTER_6,
      minWidth: 160,
    },
    {
      id: 26,
      name: reportMixModuleMcExpenseConst.COLUMN_NAME.COST_CENTER_7,
      value: reportMixModuleMcExpenseConst.COLUMN_NAME.COST_CENTER_7,
      label: reportMixModuleMcExpenseConst.COLUMN_NAME_TH.COST_CENTER_7,
      minWidth: 160,
    },
    {
      id: 27,
      name: reportMixModuleMcExpenseConst.COLUMN_NAME.COST_CENTER_8,
      value: reportMixModuleMcExpenseConst.COLUMN_NAME.COST_CENTER_8,
      label: reportMixModuleMcExpenseConst.COLUMN_NAME_TH.COST_CENTER_8,
      minWidth: 160,
    },
    {
      id: 28,
      name: reportMixModuleMcExpenseConst.COLUMN_NAME.COST_CENTER_9,
      value: reportMixModuleMcExpenseConst.COLUMN_NAME.COST_CENTER_9,
      label: reportMixModuleMcExpenseConst.COLUMN_NAME_TH.COST_CENTER_9,
      minWidth: 160,
    },
    {
      id: 29,
      name: reportMixModuleMcExpenseConst.COLUMN_NAME.COST_CENTER_10,
      value: reportMixModuleMcExpenseConst.COLUMN_NAME.COST_CENTER_10,
      label: reportMixModuleMcExpenseConst.COLUMN_NAME_TH.COST_CENTER_10,
      minWidth: 160,
    },
    {
      id: 30,
      name: reportMixModuleMcExpenseConst.COLUMN_NAME.COST_CENTER_11,
      value: reportMixModuleMcExpenseConst.COLUMN_NAME.COST_CENTER_11,
      label: reportMixModuleMcExpenseConst.COLUMN_NAME_TH.COST_CENTER_11,
      minWidth: 160,
    },
    {
      id: 31,
      name: reportMixModuleMcExpenseConst.COLUMN_NAME.COST_CENTER_12,
      value: reportMixModuleMcExpenseConst.COLUMN_NAME.COST_CENTER_12,
      label: reportMixModuleMcExpenseConst.COLUMN_NAME_TH.COST_CENTER_12,
      minWidth: 160,
    },
    {
      id: 32,
      name: reportMixModuleMcExpenseConst.COLUMN_NAME.COST_CENTER_13,
      value: reportMixModuleMcExpenseConst.COLUMN_NAME.COST_CENTER_13,
      label: reportMixModuleMcExpenseConst.COLUMN_NAME_TH.COST_CENTER_13,
      minWidth: 160,
    },
    {
      id: 33,
      name: reportMixModuleMcExpenseConst.COLUMN_NAME.COST_CENTER_14,
      value: reportMixModuleMcExpenseConst.COLUMN_NAME.COST_CENTER_14,
      label: reportMixModuleMcExpenseConst.COLUMN_NAME_TH.COST_CENTER_14,
      minWidth: 160,
    },
    {
      id: 34,
      name: reportMixModuleMcExpenseConst.COLUMN_NAME.COST_CENTER_15,
      value: reportMixModuleMcExpenseConst.COLUMN_NAME.COST_CENTER_15,
      label: reportMixModuleMcExpenseConst.COLUMN_NAME_TH.COST_CENTER_15,
      minWidth: 160,
    },
    {
      id: 35,
      name: reportMixModuleMcExpenseConst.COLUMN_NAME.ACCOUNT_CODE_OLD_1,
      value: reportMixModuleMcExpenseConst.COLUMN_NAME.ACCOUNT_CODE_OLD_1,
      label: reportMixModuleMcExpenseConst.COLUMN_NAME_TH.ACCOUNT_CODE_OLD_1,
      minWidth: 160,
    },
    {
      id: 36,
      name: reportMixModuleMcExpenseConst.COLUMN_NAME.ACCOUNT_CODE_OLD_2,
      value: reportMixModuleMcExpenseConst.COLUMN_NAME.ACCOUNT_CODE_OLD_2,
      label: reportMixModuleMcExpenseConst.COLUMN_NAME_TH.ACCOUNT_CODE_OLD_2,
      minWidth: 160,
    },
    {
      id: 37,
      name: reportMixModuleMcExpenseConst.COLUMN_NAME.ACCOUNT_CODE_OLD_3,
      value: reportMixModuleMcExpenseConst.COLUMN_NAME.ACCOUNT_CODE_OLD_3,
      label: reportMixModuleMcExpenseConst.COLUMN_NAME_TH.ACCOUNT_CODE_OLD_3,
      minWidth: 210,
    },
    {
      id: 38,
      name: reportMixModuleMcExpenseConst.COLUMN_NAME.ACCOUNT_CODE_OLD_4,
      value: reportMixModuleMcExpenseConst.COLUMN_NAME.ACCOUNT_CODE_OLD_4,
      label: reportMixModuleMcExpenseConst.COLUMN_NAME_TH.ACCOUNT_CODE_OLD_4,
      minWidth: 160,
    },
    {
      id: 39,
      name: reportMixModuleMcExpenseConst.COLUMN_NAME.ACCOUNT_CODE_OLD_5,
      value: reportMixModuleMcExpenseConst.COLUMN_NAME.ACCOUNT_CODE_OLD_5,
      label: reportMixModuleMcExpenseConst.COLUMN_NAME_TH.ACCOUNT_CODE_OLD_5,
      minWidth: 160,
    },
    {
      id: 40,
      name: reportMixModuleMcExpenseConst.COLUMN_NAME.ACCOUNT_CODE_OLD_6,
      value: reportMixModuleMcExpenseConst.COLUMN_NAME.ACCOUNT_CODE_OLD_6,
      label: reportMixModuleMcExpenseConst.COLUMN_NAME_TH.ACCOUNT_CODE_OLD_6,
      minWidth: 160,
    },
    {
      id: 41,
      name: reportMixModuleMcExpenseConst.COLUMN_NAME.ACCOUNT_CODE_OLD_7,
      value: reportMixModuleMcExpenseConst.COLUMN_NAME.ACCOUNT_CODE_OLD_7,
      label: reportMixModuleMcExpenseConst.COLUMN_NAME_TH.ACCOUNT_CODE_OLD_7,
      minWidth: 160,
    },
    {
      id: 42,
      name: reportMixModuleMcExpenseConst.COLUMN_NAME.ACCOUNT_CODE_OLD_8,
      value: reportMixModuleMcExpenseConst.COLUMN_NAME.ACCOUNT_CODE_OLD_8,
      label: reportMixModuleMcExpenseConst.COLUMN_NAME_TH.ACCOUNT_CODE_OLD_8,
      minWidth: 160,
    },
    {
      id: 43,
      name: reportMixModuleMcExpenseConst.COLUMN_NAME.ACCOUNT_CODE_OLD_9,
      value: reportMixModuleMcExpenseConst.COLUMN_NAME.ACCOUNT_CODE_OLD_9,
      label: reportMixModuleMcExpenseConst.COLUMN_NAME_TH.ACCOUNT_CODE_OLD_9,
      minWidth: 160,
    },
    {
      id: 44,
      name: reportMixModuleMcExpenseConst.COLUMN_NAME.ACCOUNT_CODE_OLD_10,
      value: reportMixModuleMcExpenseConst.COLUMN_NAME.ACCOUNT_CODE_OLD_10,
      label: reportMixModuleMcExpenseConst.COLUMN_NAME_TH.ACCOUNT_CODE_OLD_10,
      minWidth: 160,
    },
    {
      id: 45,
      name: reportMixModuleMcExpenseConst.COLUMN_NAME.ACCOUNT_CODE_OLD_11,
      value: reportMixModuleMcExpenseConst.COLUMN_NAME.ACCOUNT_CODE_OLD_11,
      label: reportMixModuleMcExpenseConst.COLUMN_NAME_TH.ACCOUNT_CODE_OLD_11,
      minWidth: 160,
    },
    {
      id: 46,
      name: reportMixModuleMcExpenseConst.COLUMN_NAME.ACCOUNT_CODE_OLD_12,
      value: reportMixModuleMcExpenseConst.COLUMN_NAME.ACCOUNT_CODE_OLD_12,
      label: reportMixModuleMcExpenseConst.COLUMN_NAME_TH.ACCOUNT_CODE_OLD_12,
      minWidth: 160,
    },
    {
      id: 47,
      name: reportMixModuleMcExpenseConst.COLUMN_NAME.ACCOUNT_CODE_OLD_13,
      value: reportMixModuleMcExpenseConst.COLUMN_NAME.ACCOUNT_CODE_OLD_13,
      label: reportMixModuleMcExpenseConst.COLUMN_NAME_TH.ACCOUNT_CODE_OLD_13,
      minWidth: 160,
    },
    {
      id: 48,
      name: reportMixModuleMcExpenseConst.COLUMN_NAME.ACCOUNT_CODE_OLD_14,
      value: reportMixModuleMcExpenseConst.COLUMN_NAME.ACCOUNT_CODE_OLD_14,
      label: reportMixModuleMcExpenseConst.COLUMN_NAME_TH.ACCOUNT_CODE_OLD_14,
      minWidth: 160,
    },
    {
      id: 49,
      name: reportMixModuleMcExpenseConst.COLUMN_NAME.ACCOUNT_CODE_OLD_15,
      value: reportMixModuleMcExpenseConst.COLUMN_NAME.ACCOUNT_CODE_OLD_15,
      label: reportMixModuleMcExpenseConst.COLUMN_NAME_TH.ACCOUNT_CODE_OLD_15,
      minWidth: 160,
    },
    {
      id: 50,
      name: reportMixModuleMcExpenseConst.COLUMN_NAME.ACCOUNT_CODE_NEW_1,
      value: reportMixModuleMcExpenseConst.COLUMN_NAME.ACCOUNT_CODE_NEW_1,
      label: reportMixModuleMcExpenseConst.COLUMN_NAME_TH.ACCOUNT_CODE_NEW_1,
      minWidth: 160,
    },
    {
      id: 51,
      name: reportMixModuleMcExpenseConst.COLUMN_NAME.ACCOUNT_CODE_NEW_2,
      value: reportMixModuleMcExpenseConst.COLUMN_NAME.ACCOUNT_CODE_NEW_2,
      label: reportMixModuleMcExpenseConst.COLUMN_NAME_TH.ACCOUNT_CODE_NEW_2,
      minWidth: 160,
    },
    {
      id: 52,
      name: reportMixModuleMcExpenseConst.COLUMN_NAME.ACCOUNT_CODE_NEW_3,
      value: reportMixModuleMcExpenseConst.COLUMN_NAME.ACCOUNT_CODE_NEW_3,
      label: reportMixModuleMcExpenseConst.COLUMN_NAME_TH.ACCOUNT_CODE_NEW_3,
      minWidth: 160,
    },
    {
      id: 53,
      name: reportMixModuleMcExpenseConst.COLUMN_NAME.ACCOUNT_CODE_NEW_4,
      value: reportMixModuleMcExpenseConst.COLUMN_NAME.ACCOUNT_CODE_NEW_4,
      label: reportMixModuleMcExpenseConst.COLUMN_NAME_TH.ACCOUNT_CODE_NEW_4,
      minWidth: 160,
    },
    {
      id: 54,
      name: reportMixModuleMcExpenseConst.COLUMN_NAME.ACCOUNT_CODE_NEW_5,
      value: reportMixModuleMcExpenseConst.COLUMN_NAME.ACCOUNT_CODE_NEW_5,
      label: reportMixModuleMcExpenseConst.COLUMN_NAME_TH.ACCOUNT_CODE_NEW_5,
      minWidth: 160,
    },
    {
      id: 55,
      name: reportMixModuleMcExpenseConst.COLUMN_NAME.ACCOUNT_CODE_NEW_6,
      value: reportMixModuleMcExpenseConst.COLUMN_NAME.ACCOUNT_CODE_NEW_6,
      label: reportMixModuleMcExpenseConst.COLUMN_NAME_TH.ACCOUNT_CODE_NEW_6,
      minWidth: 160,
    },
    {
      id: 56,
      name: reportMixModuleMcExpenseConst.COLUMN_NAME.ACCOUNT_CODE_NEW_7,
      value: reportMixModuleMcExpenseConst.COLUMN_NAME.ACCOUNT_CODE_NEW_7,
      label: reportMixModuleMcExpenseConst.COLUMN_NAME_TH.ACCOUNT_CODE_NEW_7,
      minWidth: 160,
    },
    {
      id: 57,
      name: reportMixModuleMcExpenseConst.COLUMN_NAME.ACCOUNT_CODE_NEW_8,
      value: reportMixModuleMcExpenseConst.COLUMN_NAME.ACCOUNT_CODE_NEW_8,
      label: reportMixModuleMcExpenseConst.COLUMN_NAME_TH.ACCOUNT_CODE_NEW_8,
      minWidth: 160,
    },
    {
      id: 58,
      name: reportMixModuleMcExpenseConst.COLUMN_NAME.ACCOUNT_CODE_NEW_9,
      value: reportMixModuleMcExpenseConst.COLUMN_NAME.ACCOUNT_CODE_NEW_9,
      label: reportMixModuleMcExpenseConst.COLUMN_NAME_TH.ACCOUNT_CODE_NEW_9,
      minWidth: 160,
    },
    {
      id: 59,
      name: reportMixModuleMcExpenseConst.COLUMN_NAME.ACCOUNT_CODE_NEW_10,
      value: reportMixModuleMcExpenseConst.COLUMN_NAME.ACCOUNT_CODE_NEW_10,
      label: reportMixModuleMcExpenseConst.COLUMN_NAME_TH.ACCOUNT_CODE_NEW_10,
      minWidth: 160,
    },
    {
      id: 60,
      name: reportMixModuleMcExpenseConst.COLUMN_NAME.ACCOUNT_CODE_NEW_11,
      value: reportMixModuleMcExpenseConst.COLUMN_NAME.ACCOUNT_CODE_NEW_11,
      label: reportMixModuleMcExpenseConst.COLUMN_NAME_TH.ACCOUNT_CODE_NEW_11,
      minWidth: 160,
    },
    {
      id: 61,
      name: reportMixModuleMcExpenseConst.COLUMN_NAME.ACCOUNT_CODE_NEW_12,
      value: reportMixModuleMcExpenseConst.COLUMN_NAME.ACCOUNT_CODE_NEW_12,
      label: reportMixModuleMcExpenseConst.COLUMN_NAME_TH.ACCOUNT_CODE_NEW_12,
      minWidth: 160,
    },
    {
      id: 62,
      name: reportMixModuleMcExpenseConst.COLUMN_NAME.ACCOUNT_CODE_NEW_13,
      value: reportMixModuleMcExpenseConst.COLUMN_NAME.ACCOUNT_CODE_NEW_13,
      label: reportMixModuleMcExpenseConst.COLUMN_NAME_TH.ACCOUNT_CODE_NEW_13,
      minWidth: 160,
    },
    {
      id: 63,
      name: reportMixModuleMcExpenseConst.COLUMN_NAME.ACCOUNT_CODE_NEW_14,
      value: reportMixModuleMcExpenseConst.COLUMN_NAME.ACCOUNT_CODE_NEW_14,
      label: reportMixModuleMcExpenseConst.COLUMN_NAME_TH.ACCOUNT_CODE_NEW_14,
      minWidth: 160,
    },
    {
      id: 64,
      name: reportMixModuleMcExpenseConst.COLUMN_NAME.ACCOUNT_CODE_NEW_15,
      value: reportMixModuleMcExpenseConst.COLUMN_NAME.ACCOUNT_CODE_NEW_15,
      label: reportMixModuleMcExpenseConst.COLUMN_NAME_TH.ACCOUNT_CODE_NEW_15,
      minWidth: 160,
    },
    {
      id: 65,
      name: reportMixModuleMcExpenseConst.COLUMN_NAME.EXPENSE_1,
      value: reportMixModuleMcExpenseConst.COLUMN_NAME.EXPENSE_1,
      label: reportMixModuleMcExpenseConst.COLUMN_NAME_TH.EXPENSE_1,
      minWidth: 160,
    },
    {
      id: 66,
      name: reportMixModuleMcExpenseConst.COLUMN_NAME.EXPENSE_2,
      value: reportMixModuleMcExpenseConst.COLUMN_NAME.EXPENSE_2,
      label: reportMixModuleMcExpenseConst.COLUMN_NAME_TH.EXPENSE_2,
      minWidth: 160,
    },
    {
      id: 67,
      name: reportMixModuleMcExpenseConst.COLUMN_NAME.EXPENSE_3,
      value: reportMixModuleMcExpenseConst.COLUMN_NAME.EXPENSE_3,
      label: reportMixModuleMcExpenseConst.COLUMN_NAME_TH.EXPENSE_3,
      minWidth: 160,
    },
    {
      id: 68,
      name: reportMixModuleMcExpenseConst.COLUMN_NAME.EXPENSE_4,
      value: reportMixModuleMcExpenseConst.COLUMN_NAME.EXPENSE_4,
      label: reportMixModuleMcExpenseConst.COLUMN_NAME_TH.EXPENSE_4,
      minWidth: 160,
    },
    {
      id: 69,
      name: reportMixModuleMcExpenseConst.COLUMN_NAME.EXPENSE_5,
      value: reportMixModuleMcExpenseConst.COLUMN_NAME.EXPENSE_5,
      label: reportMixModuleMcExpenseConst.COLUMN_NAME_TH.EXPENSE_5,
      minWidth: 160,
    },
    {
      id: 70,
      name: reportMixModuleMcExpenseConst.COLUMN_NAME.EXPENSE_6,
      value: reportMixModuleMcExpenseConst.COLUMN_NAME.EXPENSE_6,
      label: reportMixModuleMcExpenseConst.COLUMN_NAME_TH.EXPENSE_6,
      minWidth: 160,
    },
    {
      id: 71,
      name: reportMixModuleMcExpenseConst.COLUMN_NAME.EXPENSE_7,
      value: reportMixModuleMcExpenseConst.COLUMN_NAME.EXPENSE_7,
      label: reportMixModuleMcExpenseConst.COLUMN_NAME_TH.EXPENSE_7,
      minWidth: 160,
    },
    {
      id: 72,
      name: reportMixModuleMcExpenseConst.COLUMN_NAME.EXPENSE_8,
      value: reportMixModuleMcExpenseConst.COLUMN_NAME.EXPENSE_8,
      label: reportMixModuleMcExpenseConst.COLUMN_NAME_TH.EXPENSE_8,
      minWidth: 160,
    },
    {
      id: 73,
      name: reportMixModuleMcExpenseConst.COLUMN_NAME.EXPENSE_9,
      value: reportMixModuleMcExpenseConst.COLUMN_NAME.EXPENSE_9,
      label: reportMixModuleMcExpenseConst.COLUMN_NAME_TH.EXPENSE_9,
      minWidth: 160,
    },
    {
      id: 74,
      name: reportMixModuleMcExpenseConst.COLUMN_NAME.EXPENSE_10,
      value: reportMixModuleMcExpenseConst.COLUMN_NAME.EXPENSE_10,
      label: reportMixModuleMcExpenseConst.COLUMN_NAME_TH.EXPENSE_10,
      minWidth: 160,
    },
    {
      id: 75,
      name: reportMixModuleMcExpenseConst.COLUMN_NAME.EXPENSE_11,
      value: reportMixModuleMcExpenseConst.COLUMN_NAME.EXPENSE_11,
      label: reportMixModuleMcExpenseConst.COLUMN_NAME_TH.EXPENSE_11,
      minWidth: 160,
    },
    {
      id: 76,
      name: reportMixModuleMcExpenseConst.COLUMN_NAME.EXPENSE_12,
      value: reportMixModuleMcExpenseConst.COLUMN_NAME.EXPENSE_12,
      label: reportMixModuleMcExpenseConst.COLUMN_NAME_TH.EXPENSE_12,
      minWidth: 160,
    },
    {
      id: 77,
      name: reportMixModuleMcExpenseConst.COLUMN_NAME.EXPENSE_13,
      value: reportMixModuleMcExpenseConst.COLUMN_NAME.EXPENSE_13,
      label: reportMixModuleMcExpenseConst.COLUMN_NAME_TH.EXPENSE_13,
      minWidth: 160,
    },
    {
      id: 78,
      name: reportMixModuleMcExpenseConst.COLUMN_NAME.EXPENSE_14,
      value: reportMixModuleMcExpenseConst.COLUMN_NAME.EXPENSE_14,
      label: reportMixModuleMcExpenseConst.COLUMN_NAME_TH.EXPENSE_14,
      minWidth: 160,
    },
    {
      id: 79,
      name: reportMixModuleMcExpenseConst.COLUMN_NAME.EXPENSE_15,
      value: reportMixModuleMcExpenseConst.COLUMN_NAME.EXPENSE_15,
      label: reportMixModuleMcExpenseConst.COLUMN_NAME_TH.EXPENSE_15,
      minWidth: 160,
    },
  ],
  filterColumnOptions: [
    {
      id: 3,
      name: reportMixModuleMcExpenseConst.COLUMN_NAME.COURSE_CODE,
      value: reportMixModuleMcExpenseConst.COLUMN_NAME.COURSE_CODE,
      label: reportMixModuleMcExpenseConst.COLUMN_NAME_TH.COURSE_CODE,
      minWidth: 160,
      type: reportMixModuleMcExpenseConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 4,
      name: reportMixModuleMcExpenseConst.COLUMN_NAME.COURSE_NAME_EN,
      value: reportMixModuleMcExpenseConst.COLUMN_NAME.COURSE_NAME_EN,
      label: reportMixModuleMcExpenseConst.COLUMN_NAME_TH.COURSE_NAME_EN,
      minWidth: 160,
      type: reportMixModuleMcExpenseConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 4,
      name: reportMixModuleMcExpenseConst.COLUMN_NAME.COURSE_NAME_TH,
      value: reportMixModuleMcExpenseConst.COLUMN_NAME.COURSE_NAME_TH,
      label: reportMixModuleMcExpenseConst.COLUMN_NAME_TH.COURSE_NAME_TH,
      minWidth: 160,
      type: reportMixModuleMcExpenseConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 4,
      name: reportMixModuleMcExpenseConst.COLUMN_NAME.COURSE_NAME_LEARNER,
      value: reportMixModuleMcExpenseConst.COLUMN_NAME.COURSE_NAME_LEARNER,
      label: reportMixModuleMcExpenseConst.COLUMN_NAME_TH.COURSE_NAME_LEARNER,
      minWidth: 160,
      type: reportMixModuleMcExpenseConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 4,
      name: reportMixModuleMcExpenseConst.COLUMN_NAME.TRAINING_PLATFORM,
      value: reportMixModuleMcExpenseConst.COLUMN_NAME.TRAINING_PLATFORM,
      label: reportMixModuleMcExpenseConst.COLUMN_NAME_TH.TRAINING_PLATFORM,
      minWidth: 160,
      type: reportMixModuleMcExpenseConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      // masterDataName: reportMixModuleMcExpenseConst.COLUMN_NAME.TRAINING_PLATFORM,
      // fetchOptions: () => fetchMasterDataOptions(),
      options: reportMixModuleMcExpenseConst.trainingPlatformOptions,
    },
    {
      id: 4,
      name: reportMixModuleMcExpenseConst.COLUMN_NAME.EXPECT_NUMBER_PEOPLE,
      value: reportMixModuleMcExpenseConst.COLUMN_NAME.EXPECT_NUMBER_PEOPLE,
      label: reportMixModuleMcExpenseConst.COLUMN_NAME_TH.EXPECT_NUMBER_PEOPLE,
      minWidth: 160,
      type: reportMixModuleMcExpenseConst.TYPE_OF_VALUE.INPUT_NUMBER,
    },
    {
      id: 4,
      name: reportMixModuleMcExpenseConst.COLUMN_NAME.COUNT_CLASS,
      value: reportMixModuleMcExpenseConst.COLUMN_NAME.COUNT_CLASS,
      label: reportMixModuleMcExpenseConst.COLUMN_NAME_TH.COUNT_CLASS,
      minWidth: 160,
      type: reportMixModuleMcExpenseConst.TYPE_OF_VALUE.INPUT_NUMBER,
    },
    {
      id: 4,
      name: reportMixModuleMcExpenseConst.COLUMN_NAME.COUNT_STUDENT,
      value: reportMixModuleMcExpenseConst.COLUMN_NAME.COUNT_STUDENT,
      label: reportMixModuleMcExpenseConst.COLUMN_NAME_TH.COUNT_STUDENT,
      minWidth: 160,
      type: reportMixModuleMcExpenseConst.TYPE_OF_VALUE.INPUT_NUMBER,
    },
    {
      id: 4,
      name: reportMixModuleMcExpenseConst.COLUMN_NAME.COUNT_SEAT_EMPTY,
      value: reportMixModuleMcExpenseConst.COLUMN_NAME.COUNT_SEAT_EMPTY,
      label: reportMixModuleMcExpenseConst.COLUMN_NAME_TH.COUNT_SEAT_EMPTY,
      minWidth: 160,
      type: reportMixModuleMcExpenseConst.TYPE_OF_VALUE.INPUT_NUMBER,
    },
    {
      id: 4,
      name: reportMixModuleMcExpenseConst.COLUMN_NAME.COUNT_NOT_ATTENDANCE,
      value: reportMixModuleMcExpenseConst.COLUMN_NAME.COUNT_NOT_ATTENDANCE,
      label: reportMixModuleMcExpenseConst.COLUMN_NAME_TH.COUNT_NOT_ATTENDANCE,
      minWidth: 160,
      type: reportMixModuleMcExpenseConst.TYPE_OF_VALUE.INPUT_NUMBER,
    },
    {
      id: 4,
      name: reportMixModuleMcExpenseConst.COLUMN_NAME.COUNT_ATTENDANCE,
      value: reportMixModuleMcExpenseConst.COLUMN_NAME.COUNT_ATTENDANCE,
      label: reportMixModuleMcExpenseConst.COLUMN_NAME_TH.COUNT_ATTENDANCE,
      minWidth: 160,
      type: reportMixModuleMcExpenseConst.TYPE_OF_VALUE.INPUT_NUMBER,
    },
    {
      id: 4,
      name: reportMixModuleMcExpenseConst.COLUMN_NAME.COUNT_PASS,
      value: reportMixModuleMcExpenseConst.COLUMN_NAME.COUNT_PASS,
      label: reportMixModuleMcExpenseConst.COLUMN_NAME_TH.COUNT_PASS,
      minWidth: 160,
      type: reportMixModuleMcExpenseConst.TYPE_OF_VALUE.INPUT_NUMBER,
    },
    {
      id: 4,
      name: reportMixModuleMcExpenseConst.COLUMN_NAME.COUNT_NOT_PASS,
      value: reportMixModuleMcExpenseConst.COLUMN_NAME.COUNT_NOT_PASS,
      label: reportMixModuleMcExpenseConst.COLUMN_NAME_TH.COUNT_NOT_PASS,
      minWidth: 160,
      type: reportMixModuleMcExpenseConst.TYPE_OF_VALUE.INPUT_NUMBER,
    },
    {
      id: 16,
      name: reportMixModuleMcExpenseConst.COLUMN_NAME.STATION_NAME,
      value: reportMixModuleMcExpenseConst.COLUMN_NAME.STATION,
      label: reportMixModuleMcExpenseConst.COLUMN_NAME_TH.STATION,
      minWidth: 160,
      type: reportMixModuleMcExpenseConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      masterDataName: reportMixModuleMcExpenseConst.COLUMN_NAME.STATION,
      fetchOptions: () => fetchMasterDataOptions(),
    },
    {
      id: 16,
      name: reportMixModuleMcExpenseConst.COLUMN_NAME.EXPENSE_SUMMARY,
      value: reportMixModuleMcExpenseConst.COLUMN_NAME.EXPENSE_SUMMARY,
      label: reportMixModuleMcExpenseConst.COLUMN_NAME_TH.EXPENSE_SUMMARY,
      minWidth: 160,
      type: reportMixModuleMcExpenseConst.TYPE_OF_VALUE.INPUT_NUMBER_RANGE,
    },
    {
      id: 16,
      name: reportMixModuleMcExpenseConst.COLUMN_NAME.COST_CENTER_FILTER,
      value: reportMixModuleMcExpenseConst.COLUMN_NAME.COST_CENTER,
      label: reportMixModuleMcExpenseConst.COLUMN_NAME_TH.COST_CENTER,
      minWidth: 160,
      type: reportMixModuleMcExpenseConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      masterDataName: 'costCenter',
      fetchOptions: () => fetchMasterDataOptions(),
    },
    {
      id: 16,
      name: reportMixModuleMcExpenseConst.COLUMN_NAME.COST_CENTER_NAME_FILTER,
      value: reportMixModuleMcExpenseConst.COLUMN_NAME.COST_CENTER_NAME,
      label: reportMixModuleMcExpenseConst.COLUMN_NAME_TH.COST_CENTER_NAME,
      minWidth: 160,
      type: reportMixModuleMcExpenseConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      masterDataName: 'costCenter',
      fetchOptions: () => fetchMasterDataOptions(),
    },
    {
      id: 16,
      name: reportMixModuleMcExpenseConst.COLUMN_NAME.YEAR_BUDGET,
      value: reportMixModuleMcExpenseConst.COLUMN_NAME.YEAR_BUDGET,
      label: reportMixModuleMcExpenseConst.COLUMN_NAME_TH.YEAR_BUDGET,
      minWidth: 160,
      type: reportMixModuleMcExpenseConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      fetchOptions: () => yearBudgetOptions(),
    },
    {
      id: 4,
      name: reportMixModuleMcExpenseConst.COLUMN_NAME.ACCOUNT_CODE_NEW,
      value: reportMixModuleMcExpenseConst.COLUMN_NAME.ACCOUNT_CODE_NEW,
      label: reportMixModuleMcExpenseConst.COLUMN_NAME_TH.ACCOUNT_CODE_NEW,
      minWidth: 160,
      type: reportMixModuleMcExpenseConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 4,
      name: reportMixModuleMcExpenseConst.COLUMN_NAME.ACCOUNT_CODE_OLD,
      value: reportMixModuleMcExpenseConst.COLUMN_NAME.ACCOUNT_CODE_OLD,
      label: reportMixModuleMcExpenseConst.COLUMN_NAME_TH.ACCOUNT_CODE_OLD,
      minWidth: 160,
      type: reportMixModuleMcExpenseConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 4,
      name: reportMixModuleMcExpenseConst.COLUMN_NAME.DESCRIPTION,
      value: reportMixModuleMcExpenseConst.COLUMN_NAME.DESCRIPTION,
      label: reportMixModuleMcExpenseConst.COLUMN_NAME_TH.DESCRIPTION,
      minWidth: 160,
      type: reportMixModuleMcExpenseConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 4,
      name: reportMixModuleMcExpenseConst.COLUMN_NAME.CATEGORY,
      value: reportMixModuleMcExpenseConst.COLUMN_NAME.CATEGORY,
      label: reportMixModuleMcExpenseConst.COLUMN_NAME_TH.CATEGORY,
      minWidth: 160,
      type: reportMixModuleMcExpenseConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
  ],
}

const yearBudgetOptions = async () => {
  let currentYear = 2020
  let numberOfYears = 2100
  const yearList = []

  while (currentYear <= numberOfYears) {
    yearList.push({ label: currentYear.toString(), value: String(currentYear) })
    currentYear++
  }
  return yearList
}
