import React, { useRef } from 'react'
import ReactJoditEditor from 'jodit-react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { getPlainText } from '../../utils/lib'
import { joditConfig } from './model'
import { StyledEditorBox } from './Styled'
import { handleUploader } from './events'

const JoditEditor = ({
  id,
  boxSx,
  required,
  disabled,
  readOnly,
  isTextLefe,
  labelText,
  value,
  textError,
  textInfo,
  showMaxLength,
  onBlur,
  onChange,
}) => {
  const editor = useRef(null)
  const plainText = getPlainText(value)
  const config = {
    ...joditConfig,
    id,
    readOnly,
    disabled,
    uploader: handleUploader(),
  }

  return (
    <StyledEditorBox
      sx={{ ...boxSx }}
      textError={textError}
      disabled={disabled}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <Box display="flex">
          <Typography
            variant="body2"
            color={disabled ? 'text.gray' : 'text.secondary'}
          >
            {labelText}
          </Typography>
          {required && (
            <Typography
              sx={{ lineHeight: 1, ml: 0.5 }}
              color={disabled ? 'text.gray' : 'error'}
            >
              *
            </Typography>
          )}
        </Box>
      </Box>

      <ReactJoditEditor
        ref={editor}
        config={config}
        tabIndex={1}
        value={value}
        onBlur={onBlur}
        onChange={onChange}
      />

      <Box
        sx={{
          mb: 3,
          display: 'flex',
          justifyContent: isTextLefe ? 'flex-start' : 'space-between',
        }}
      >
        <Box>
          {!textError && textInfo && textInfo.length > 0 && (
            <Typography variant="body2" color="text.lightGray">
              {textInfo}
            </Typography>
          )}
          {textError && textError.length > 0 && (
            <Typography variant="body2" color="error">
              {textError}
            </Typography>
          )}
        </Box>
        {showMaxLength > 0 && (
          <Typography
            sx={{
              height: '25px',
              textAlign: 'end',
              color: plainText.length > showMaxLength ? 'error.main' : 'unset',
            }}
            variant="body2"
          >
            {`${plainText.length}/${showMaxLength}`}
          </Typography>
        )}
      </Box>
    </StyledEditorBox>
  )
}

export default JoditEditor
