import { hasAllDashboardPerms } from '../handler/hasAllDashboardPerms'
// import { stationFilter } from '../../../../utils/apiPath'
// import { callAPI } from '../../../../utils/lib'
import _ from 'lodash'

export const defaultFilter = () => {
  const user = JSON.parse(localStorage.getItem('user'))
  const stationData = _.get(user, 'area[0].station')
  const stationFilter = hasAllDashboardPerms()
    ? ''
    : [
        {
          label: stationData.station,
          uuid: stationData.uuid,
        },
      ]
  return {
    startDateChecked: false,
    startDate: '',
    endDate: '',
    displayDate: false,
    inventoryChecked: false,
    inventory: '',
    stationChecked: false,
    station: stationFilter,
    courseChecked: false,
    course: '',
  }
}

export const defaultReqFilter = () => {
  const user = JSON.parse(localStorage.getItem('user'))
  const stationData = _.get(user, 'area[0].station')
  const stationFilter = hasAllDashboardPerms()
    ? []
    : [
        {
          label: stationData.station,
          uuid: stationData.uuid,
        },
      ]
  return {
    startDateChecked: false,
    startDate: '',
    endDate: '',
    displayDate: false,
    inventoryChecked: false,
    inventory: '',
    stationChecked: false,
    station: stationFilter,
    courseReqChecked: false,
    courseReq: '',
    statusChecked: false,
    status: [],
    ticketTypeChecked: false,
    ticketType: [],
    returnDateChecked: false,
    returnDateFrom: '',
    returnDateTo: '',
  }
}

// const handleFetchStationLabel = async (uuid) => {
//   const status = ['ACTIVE']
//   const body = {
//     station: '',
//     limit: -1,
//     order: 'DESC',
//     page: 1,
//     sort: 'updatedAt',
//     status: status,
//     createdBy: '',
//     updatedBy: '',
//   }
//   let findData = {}
//   await callAPI({
//     method: 'post',
//     url: stationFilter,
//     body,
//     onSuccess: (data) => {
//       findData = _.get(data, 'result', []).find((item) => item.uuid === uuid)
//     },
//   })
//   return findData
// }
