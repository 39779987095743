import React from 'react'
import _ from 'lodash'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Card from '@mui/material/Card'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'

import { LabelText, BoxImage, Image, BoxContent, IconStatus } from './Styled'
import { mapStatus, handlePassTraining } from './events'
import { useDispatch } from 'react-redux'

const Index = () => {
  const dispatch = useDispatch()
  const { id: uuid } = useParams()
  const initialState = useSelector(
    (state) => state.manageClassLearnerDetail.initialState,
  )
  const status = mapStatus[_.get(initialState, 'status', 'CANCELED')]
  const statusClass = _.get(initialState, 'statusClass', 'INPROGRESS')
  const classLearnerAttendancePass = _.get(
    initialState,
    'classLearnerAttendancePass',
    false,
  )
  const classLearnerCoursePass = _.get(
    initialState,
    'classLearnerCoursePass',
    false,
  )
  const isPass = classLearnerCoursePass && classLearnerAttendancePass
  const body = {
    uuid: uuid,
  }
  const checkCloseBeforeEnd = _.get(
    initialState,
    'statusClosedBeforeEnd',
    false,
  )
  let statusTH = isPass ? 'ผ่านการอบรม' : 'ไม่ผ่านการอบรม'

  if (statusClass === 'INPROGRESS') {
    statusTH = '-'
  }

  if (checkCloseBeforeEnd && isPass !== true) {
    statusTH = '-'
  }

  return (
    <Card sx={{ display: 'flex', flexDirection: 'row', mt: 2 }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        <Typography variant="h4" sx={{ m: 2, mb: 0 }}>
          {_.get(initialState, 'firstNameTH', '')}{' '}
          {_.get(initialState, 'lastNameTH', '') || ''}
        </Typography>
        <BoxContent>
          <Box sx={{ width: 450 }}>
            <LabelText variant="body1">ID</LabelText>
            <LabelText variant="body1">หลักสูตร</LabelText>
            <LabelText variant="body1">สถานะ</LabelText>
            <LabelText variant="body1">ผลการอบรม</LabelText>
          </Box>
          <Box sx={{ width: 450 }}>
            <LabelText variant="body1">
              {_.get(initialState, 'codeId', '')}
            </LabelText>
            <LabelText variant="body1">
              {_.get(initialState, 'courseCode', '')}{' '}
              {_.get(initialState, 'course', '')}
            </LabelText>
            <Box sx={{ display: 'flex', flexDirection: 'row', mb: 1.5 }}>
              <IconStatus
                isBorder={_.get(status, 'isBorder', false)}
                color={_.get(status, 'color', 'CANCELED')}
              />
              {_.get(status, 'label', 'ถูกยกเลิกโดยแอดมิน')}
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
              <LabelText variant="body1">
                {statusTH}
                {statusClass === 'CLOSED' && (
                  <Button
                    sx={{ ml: 3 }}
                    variant="outlined"
                    size="s"
                    onClick={() => dispatch(handlePassTraining(body, isPass))}
                  >
                    {isPass ? 'ไม่ให้ผ่านการอบรม' : 'ให้ผ่านการอบรม'}
                  </Button>
                )}
              </LabelText>
            </Box>
          </Box>
        </BoxContent>
      </Box>
      {!_.isEmpty(_.get(initialState, 'image', '')) && (
        <BoxImage>
          <Image
            src={`${window.__env__.REACT_APP_API_URL}/file/${_.get(
              initialState,
              'image',
              '',
            )}`}
          />
        </BoxImage>
      )}
    </Card>
  )
}

export default Index
