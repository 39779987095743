import React, { useState, useEffect } from 'react'
import { Chart } from 'chart.js'
import { Box } from '@mui/material'
import { useTheme } from '@mui/system'
import _ from 'lodash'

const HorizontalBarChart = ({
  dataSet,
  labels,
  height = '100%',
  width = 'auto',
  eleId = 'horizontalBarChart',
  borderRadius = 10,
  barThickness = 50,
}) => {
  const theme = useTheme()
  const [chart, setChart] = useState()
  useEffect(() => {
    if (chart) {
      chart.destroy()
    }
    const data = _.get(dataSet, 'data', 0)
    const bgColor = _.get(dataSet, 'backgroundColor', '')

    const remainingPercentage = 100 - data

    const datasetsDefault = []
    if (remainingPercentage) {
      datasetsDefault.push({
        backgroundColor: theme.palette.text.silver,
        data: [remainingPercentage],
        borderRadius: {
          topRight: borderRadius,
          bottomRight: borderRadius,
          topLeft: remainingPercentage && data === 0 ? borderRadius : 0,
          bottomLeft: remainingPercentage && data === 0 ? borderRadius : 0,
        },
        borderSkipped: remainingPercentage ? false : true,
        order: 1,
      })
    }

    if (data) {
      datasetsDefault.push({
        backgroundColor: bgColor,
        data: [data],
        borderRadius: {
          topLeft: borderRadius,
          bottomLeft: borderRadius,
          topRight: !remainingPercentage ? borderRadius : 0,
          bottomRight: !remainingPercentage ? borderRadius : 0,
        },
        borderSkipped: false,
        order: 0,
      })
    }
    const ctx = document.getElementById(eleId)
    const newChart = new Chart(ctx, {
      type: 'bar',
      data: {
        labels: labels,
        datasets: datasetsDefault,
      },
      options: {
        indexAxis: 'y',
        maintainAspectRatio: false,
        scales: {
          x: {
            grid: {
              display: false,
              drawBorder: false,
            },
            ticks: {
              display: false,
            },
            stacked: true,
            beginAtZero: true,
          },
          y: {
            display: false,
            stacked: true,
            beginAtZero: true,
          },
        },
        plugins: {
          legend: { display: false },
          tooltip: {
            enabled: false,
          },
        },
        barThickness: barThickness,
        barPercentage: 0.5,
      },
    })
    setChart(newChart)
  }, [dataSet, labels])

  return (
    <Box sx={{ height: height, width: width }}>
      <canvas id={eleId} />
    </Box>
  )
}

export default HorizontalBarChart
