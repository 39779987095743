import { hasPermission } from '../../../../utils/lib'

export const hasAllDashboardPerms = () => {
  const user = JSON.parse(localStorage.getItem('user'))
  return hasPermission({
    userPermissions: user.permission,
    permissionList: [
      {
        module: 'INVENTORY',
        owner: 'ALL',
        permission: ['DASHBOARD'],
      },
    ],
  })
}
