import React, { useEffect, useRef, useState } from 'react'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Typography from '@mui/material/Typography'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import { styled, useTheme } from '@mui/material/styles'
import _ from 'lodash'
import dayjs from 'dayjs'
// import Condition from './Condition'
import Prerequisite from './Prerequisite'
import SpeakerProductType from './SpeakerProductType'

import TextInput from '../../components/Input/TextInput'
import AlertDialog from '../../components/AlertDialog'
import SelectGroupChip from '../../components/SelectGroupChip'
import DatePicker from '../../components/Input/DatePicker'
import Select from '../../components/Input/Select'
import Editor from '../../components/Editor/BasicEditor'
// import QuillEditor from '../../components/Editor/QuillEditor'
import Upload from '../../components/Upload/BasicUpload'
import CreatableGroupChip from '../../components/CreatableGroupChip'
import { callAPI, convertFormatDateTime, getOptionsKey } from '../../utils/lib'
import { filterRoadMap } from '../../utils/apiPath'
import { defaultCoursePlatform } from './DefaultValue'
import ContributorForm from './ContributorForm'
import TestAndEvaluateSetting from './TestAndEvaluateSetting'
import AttendanceSetting from './AttendanceSetting'
import ECertificationSetting from './ECertificationSetting'
import DocumentSetting from './DocumentSetting'
import PointSetting from './PointSetting'
import OicExamSetting from './OicExamSetting'
import MaterialSetting from './MaterialSetting'
import * as lib from '../../../src/utils/lib'
import { Row } from '../../components/Layout/Styled'

export const minuteCountOptions = [
  { label: 0, value: 0 },
  { label: 5, value: 5 },
  { label: 10, value: 10 },
  { label: 15, value: 15 },
  { label: 20, value: 20 },
  { label: 25, value: 25 },
  { label: 30, value: 30 },
  { label: 35, value: 35 },
  { label: 40, value: 40 },
  { label: 45, value: 45 },
  { label: 50, value: 50 },
  { label: 55, value: 55 },
]
export const courseLevelControlOptions = [
  { label: 'ระดับสูง', value: 'ระดับสูง' },
  { label: 'ระดับกลาง', value: 'ระดับกลาง' },
  { label: 'ระดับต่ำ', value: 'ระดับต่ำ' },
]
export const courseStatus = {
  DRAFT: 'ฉบับร่าง',
  WAITING_FOR_ACTIVE: 'รอเปิดใช้งาน',
  ACTIVE: 'เปิดใช้งาน',
  INACTIVE: 'ปิดใช้งาน',
  DELETED: 'ถูกลบแล้ว',
}

export const getLastStartDateVersion = ({
  disabledDate,
  newVersion,
  isEditForm,
  version,
}) => {
  if (!newVersion && !isEditForm) return null
  if (newVersion) {
    const getLastDateVersion = disabledDate[disabledDate.length - 1]
    const lastDate = _.get(getLastDateVersion, 'startDate', null)
    const lastStatus = _.get(getLastDateVersion, 'status', '')
    return lastStatus === 'WAITING_FOR_ACTIVE'
      ? dayjs(lastDate).add(1, 'day').format('YYYY-MM-DD')
      : lastDate
  } else if (isEditForm && version && version !== 1) {
    const indexOfCurrentVersion = disabledDate.indexOf(
      disabledDate.find((item) => item.version === version),
    )
    const beforeVersion = disabledDate[indexOfCurrentVersion - 1]
    // const beforeVersion = disabledDate.find(
    //   (item) => item.version === version - 1
    // )
    const date = _.get(beforeVersion, 'startDate', null)
    const status = _.get(beforeVersion, 'status', '')
    return status === 'WAITING_FOR_ACTIVE'
      ? dayjs(date).add(1, 'day').format('YYYY-MM-DD')
      : date
  } else if (isEditForm && version && version === 1) {
    const currentVersion = disabledDate.find((item) => item.version === version)
    // const date = _.get(currentVersion, 'startDate', null)
    const status = _.get(currentVersion, 'status', '')

    return status === 'WAITING_FOR_ACTIVE' ? null : dayjs().format('YYYY-MM-DD')
  }
  return null
}

export const getLastEndDateVersion = ({
  disabledDate,
  newVersion,
  isEditForm,
  version,
}) => {
  if ((!newVersion && !isEditForm) || disabledDate.length === 0) return null
  if (isEditForm && version) {
    const indexOfCurrentVersion = disabledDate.indexOf(
      disabledDate.find((item) => item.version === version),
    )
    const afterVersion = disabledDate[indexOfCurrentVersion + 1]
    const date = _.get(afterVersion, 'startDate', null)
    const status = _.get(afterVersion, 'status', '')
    return status === 'WAITING_FOR_ACTIVE'
      ? dayjs(date).subtract(1, 'day').format('YYYY-MM-DD')
      : date
  }
  return null
}

const CourseSettingForm = (props) => {
  const theme = useTheme()
  const {
    courseCode,
    newVersion,
    isEditForm,
    cloneBy,
    dialog,
    setDialog,
    handleClose,
    formik,
    isLoading,
    initialValues,
    setInitialValues,
    level,
    roadmap,
    trainingPlatform,
    productType,
    distribution,
    acquiredSkill,
    staff,
    otheRoadmap,
    point,
    handleUploadFile,
    eCertification,
    license,
    allCourse,
    disabledDate,
  } = props
  const [levelByDist, setLevelByDist] = useState([])
  const [otherLevelByDist, setOtherLevelByDist] = useState([])
  const [roadmapByDistOptions, setRoadmapByDistOptions] = useState([])
  const [inputValue, setInputValue] = useState('')

  const lastStartDateCourseVersion = getLastStartDateVersion({
    disabledDate,
    newVersion,
    isEditForm,
    version: formik.values.version,
  })
  const lastEndDateCourseVersion = getLastEndDateVersion({
    disabledDate,
    newVersion,
    isEditForm,
    version: formik.values.version,
  })

  useEffect(() => {
    setupRoadmapOptions(
      roadmap,
      newVersion,
      isEditForm,
      cloneBy,
      formik,
      setRoadmapByDistOptions,
      getLevelByDistributionId,
    )
  }, [roadmap, formik.values.distribution])

  const otheRoadmapOptions =
    otheRoadmap &&
    getOptionsKey({
      data: otheRoadmap,
      label: 'title',
      key: 'uuid',
    })
  const pointOptions =
    point &&
    getOptionsKey({
      data: point,
      label: 'name',
      key: 'uuid',
    })
  const levelOptions =
    level &&
    getOptionsKey({
      data: level,
      label: 'title',
      key: 'uuid',
    })

  const trainingPlatformOptions =
    trainingPlatform &&
    getOptionsKey({
      data: trainingPlatform,
      label: 'trainingPlatform',
      key: 'uuid',
    })
  const productTypeOptions =
    productType &&
    getOptionsKey({
      data: productType,
      label: 'productType',
      key: 'uuid',
    })
  const distributionOptions =
    distribution &&
    getOptionsKey({
      data: distribution,
      label: 'distribution',
      key: 'uuid',
    })
  const acquiredSkillOptions =
    acquiredSkill &&
    getOptionsKey({
      data: acquiredSkill,
      label: 'acquiredSkill',
      key: 'uuid',
    })
  const staffOptions =
    staff &&
    getOptionsKey({
      data: staff,
      label: 'fullnameTH',
      key: 'uuid',
    })
  const fetchRoadMapByDistId = async (id) => {
    const bodyRoadMap = {
      limit: -1,
      page: 1,
      order: 'ASC',
      sort: 'title',
      title: '',
      abbreviation: '',
      distribution: [id],
      status: ['ACTIVE'],
    }
    await callAPI({
      url: filterRoadMap,
      method: 'POST',
      body: bodyRoadMap,
      onSuccess: (res) => {
        const roadmapOpt =
          res.result &&
          getOptionsKey({
            data: res.result,
            label: 'title',
            key: 'uuid',
          })
        if (roadmapOpt && roadmapOpt.length > 0) {
          setRoadmapByDistOptions([...roadmapOpt, ...otheRoadmapOptions])
        } else {
          setRoadmapByDistOptions(otheRoadmapOptions)
        }
      },
    })
  }

  const coursePurposeEditorRef = useRef(null)
  const courseOutlineEditorRef = useRef(null)

  const getLevelByDistributionId = (id) => {
    if (distribution === null) {
      return
    }
    const findLevelByDist = distribution.find((item) => item.uuid === id)
    if (!findLevelByDist) {
      return
    }
    const levelByDistOptions =
      findLevelByDist &&
      getOptionsKey({
        data: findLevelByDist.level,
        label: 'title',
        key: 'uuid',
      })
    setLevelByDist(levelByDistOptions)
    const dif = _.differenceBy(levelOptions, levelByDistOptions, 'value')
    setOtherLevelByDist(dif)
  }

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          gap: 3,
          flexDirection: 'row-reverse',
          mx: 3,
          [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '20%',
            minWidth: 200,
            gap: 3,
            [theme.breakpoints.only('md')]: {
              width: '30%',
            },
            [theme.breakpoints.down('md')]: {
              flexDirection: 'row',
              width: '100%',
              flexWrap: 'wrap',
            },
            [theme.breakpoints.down('sm')]: {
              width: '100%',
            },
          }}
        >
          <Card
            sx={{
              width: '100%',
              px: 3,
              py: 2,
              display: 'flex',
              flexDirection: 'column',
              [theme.breakpoints.down('md')]: {
                flex: '0 0 calc(50% - 12px)',
              },
              [theme.breakpoints.down('sm')]: {
                flex: 'unset',
              },
            }}
          >
            <Typography variant="body1b" sx={{ mb: 1 }}>
              สถานะหลักสูตร
            </Typography>
            <BoxStatus>
              <LabelStatus>สถานะ</LabelStatus>
              <Typography variant="body1" color="primary">
                {_.get(initialValues, 'status', null) && !cloneBy
                  ? courseStatus[initialValues.status]
                  : '-'}
              </Typography>
            </BoxStatus>
            <BoxStatus>
              <LabelStatus>วันที่สร้าง</LabelStatus>
              <Typography variant="body1">
                {_.get(initialValues, 'createdAt', null) && !cloneBy
                  ? convertFormatDateTime({
                      value: _.get(initialValues, 'createdAt', null),
                      type: 'date',
                    })
                  : '-'}
              </Typography>
            </BoxStatus>
            <BoxStatus>
              <LabelStatus>วันที่แก้ไขล่าสุด</LabelStatus>
              <Typography variant="body1">
                {_.get(initialValues, 'updatedAt', null) && !cloneBy
                  ? convertFormatDateTime({
                      value: _.get(initialValues, 'updatedAt', null),
                      type: 'date',
                    })
                  : '-'}
              </Typography>
            </BoxStatus>
            <BoxStatus>
              <LabelStatus>แก้ไขล่าสุดโดย</LabelStatus>
              <Typography variant="body1">
                {_.get(initialValues, 'updatedBy', null) && !cloneBy
                  ? _.get(initialValues, 'updatedBy', '-')
                  : '-'}
              </Typography>
            </BoxStatus>
          </Card>
          <Card
            sx={{
              width: '100%',
              px: 3,
              py: 2,
              gap: 2,
              display: 'flex',
              flexDirection: 'column',
              [theme.breakpoints.down('md')]: {
                flex: '0 0 calc(50% - 12px)',
              },
              [theme.breakpoints.down('sm')]: {
                flex: 'unset',
              },
            }}
          >
            <Typography variant="body1b">ผู้รับผิดชอบ</Typography>
            <ContributorForm
              assignee={formik.values.assignee}
              setDialog={setDialog}
              staffOptions={staffOptions}
              formik={formik}
              staff={staff}
              isEditForm={isEditForm}
            />
          </Card>
          <Card
            sx={{
              width: '100%',
              px: 3,
              py: 2,
              gap: 2,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Typography variant="body1b">รูปหน้าปกหลักสูตร *</Typography>
            <Upload
              handleUploadFile={handleUploadFile}
              value={formik.values.imageKey}
              textError={formik?.errors?.imageKey}
              style={{ width: '100%', height: '150px' }}
              titlePreviewImg={'รูปหน้าปกหลักสูตร'}
              onDelete={() => formik.setFieldValue('imageKey', '')}
              disabled={isLoading}
            />
          </Card>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '80%',
            minWidth: 275,
            gap: 3,
            [theme.breakpoints.only('md')]: {
              width: '70%',
            },
            [theme.breakpoints.down('md')]: {
              width: '100%',
            },
          }}
        >
          <Card sx={{ width: '100%', minWidth: 275, pt: 2, px: 3 }}>
            {isEditForm && (
              <TextInput
                id="version"
                name="version"
                labelText="เวอร์ชั่น"
                type="basic"
                required
                disabled
                value={formik.values.version}
              />
            )}
            <TextInput
              id="englishName"
              name="englishName"
              labelText="ชื่อหลักสูตร EN"
              type="basic"
              textError={formik?.errors?.englishName}
              required
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.englishName}
            ></TextInput>
            <TextInput
              id="thaiName"
              name="thaiName"
              labelText="ชื่อหลักสูตร TH"
              type="basic"
              textError={formik?.errors?.thaiName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.thaiName}
            ></TextInput>
            <TextInput
              id="nameForLearner"
              name="nameForLearner"
              labelText="ชื่อหลักสูตร (สำหรับ Learner)"
              type="basic"
              textError={formik?.errors?.nameForLearner}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.nameForLearner}
            />
          </Card>
          <Card
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              minWidth: 275,
              pt: 2,
              px: 3,
            }}
          >
            <Typography variant="h6">ระยะเวลาของหลักสูตร</Typography>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: 3,
                mt: 2,
                [theme.breakpoints.down('sm')]: {
                  flexDirection: 'column',
                  gap: 0,
                },
              }}
            >
              <DatePicker
                id="fullDate"
                name="fullDate"
                labelText="วันเริ่มต้น"
                required
                onChange={(value) => onDateChange(value, 'startDate', formik)}
                value={formik.values.startDate}
                textError={formik?.errors?.startDate}
                isShouldDisableDate={true}
                disabledDateRange={false}
                hideDisableDateRange={!lastStartDateCourseVersion}
                disabledStartDate={lastStartDateCourseVersion}
                disabledEndDate={lastEndDateCourseVersion}
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: 3,
                [theme.breakpoints.down('sm')]: {
                  flexDirection: 'column',
                  gap: 0,
                },
              }}
            >
              <TextInput
                id="hours"
                name="hours"
                labelText="จำนวนชั่วโมงของหลักสูตร (ไม่รวมเวลาพักเที่ยง)"
                type="basic"
                maxlength={4}
                textError={formik?.errors?.hours}
                required
                defaultValue={formik.values.hours}
                onChange={(e) =>
                  handleNumbericDecimalChange(e, setInitialValues)
                }
                inputProps={{
                  onInput: (e) => handleInputFormat(e),
                  onPaste: (e) => handlePasteFormat(e),
                }}
                onBlur={(e) => {
                  handleNumbericBlur(e, formik, false)
                }}
                value={initialValues.hours}
              ></TextInput>
              {!_.isEmpty(minuteCountOptions) && (
                <Select
                  dataTestid="select-minutes"
                  id="minutes"
                  name="minutes"
                  labelText="จำนวนนาที"
                  type="basic"
                  textError={formik?.errors?.minutes}
                  required
                  options={minuteCountOptions}
                  handleChange={(e) => onMinuteChange(e, formik)}
                  value={formik.values.minutes ?? '0'}
                  placeholder={'นาที'}
                ></Select>
              )}
            </Box>
          </Card>
          <ECertificationSetting
            eCertification={eCertification}
            initialValues={initialValues}
            setInitialValues={setInitialValues}
            formik={formik}
          />
          <Card
            sx={{
              width: '100%',
              minWidth: 275,
              display: 'flex',
              flexDirection: 'column',
              pt: 2,
              pb: 3,
              px: 3,
            }}
          >
            <Typography variant="h6">รายละเอียดหลักสูตร</Typography>
            <CreatableGroupChip
              boxSx={{ mb: 3 }}
              id="tag"
              labelText="Tag"
              placeholder="กรุณาเพิ่ม Tag"
              value={_.get(formik.values, 'tag', []) || []}
              inputValue={inputValue}
              onChange={(value) => formik.setFieldValue('tag', value)}
              onInputChange={(value) => setInputValue(value)}
              onKeyDown={(e) =>
                handleKeyDown(e, formik, inputValue, setInputValue)
              }
            />
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: 3,
                [theme.breakpoints.down('sm')]: {
                  flexDirection: 'column',
                  gap: 0,
                },
              }}
            >
              {!_.isEmpty(distributionOptions) && (
                <Select
                  dataTestid="select-distribution"
                  id="distribution"
                  name="distribution"
                  labelText="ช่องทางการจำหน่าย (Distribution Channel)"
                  type="basic"
                  textError={formik?.errors?.distribution}
                  required
                  options={distributionOptions}
                  disabled={checkChangeForm(newVersion, isEditForm)}
                  placeholder="กรุณาเลือก"
                  handleChange={(e) =>
                    onSelectChange(
                      e,
                      'distribution',
                      props,
                      formik,
                      getLevelByDistributionId,
                      fetchRoadMapByDistId,
                    )
                  }
                  value={formik.values.distribution.uuid}
                />
              )}
              <Box
                sx={{
                  width: '100%',
                  [theme.breakpoints.down('sm')]: {
                    pb: 3,
                  },
                }}
              >
                <Typography variant="body2b">
                  ชื่อย่อช่องทางการจำหน่าย
                </Typography>
                <Typography>
                  {formik?.values?.distribution?.abbreviation
                    ? formik?.values?.distribution?.abbreviation
                    : '-'}
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: 3,
                [theme.breakpoints.down('sm')]: {
                  flexDirection: 'column',
                  gap: 0,
                },
              }}
            >
              <Select
                dataTestid="select-roadmap"
                id="roadmap"
                name="roadmap"
                labelText="กลุ่มหลักสูตร (Roadmap)"
                type="basic"
                textError={formik?.errors?.roadmap}
                required
                disabled={
                  checkChangeForm(newVersion, isEditForm)
                    ? true
                    : checkHaveUUID('distribution', formik)
                }
                options={
                  isEditForm
                    ? [
                        {
                          value: formik.values.roadmap.uuid,
                          label: formik.values.roadmap.title,
                        },
                      ]
                    : roadmapByDistOptions
                }
                placeholder="กรุณาเลือก"
                handleChange={(e) =>
                  onSelectChange(
                    e,
                    'roadmap',
                    props,
                    formik,
                    getLevelByDistributionId,
                    fetchRoadMapByDistId,
                  )
                }
                value={formik.values.roadmap.uuid}
              />
              <Box
                sx={{
                  width: '100%',
                  [theme.breakpoints.down('sm')]: {
                    pb: 3,
                  },
                }}
              >
                <Typography variant="body2b">ชื่อย่อกลุ่มหลักสูตร</Typography>
                <Typography>
                  {' '}
                  {formik?.values?.roadmap?.uuid
                    ? formik.values?.roadmap?.abbreviation
                    : '-'}
                </Typography>
              </Box>
            </Box>
            <SelectGroupChip
              boxSx={{ mb: 3 }}
              id="levelOfLearner"
              name="levelOfLearner"
              labelText="ระดับของผู้เรียน"
              required
              placeholder={'กรุณาเลือก'}
              options={levelByDist}
              disabled={
                checkChangeForm(newVersion, isEditForm)
                  ? false
                  : checkHaveUUID('distribution', formik)
              }
              onChange={(list) =>
                onSelectGroupChipChange(list, 'levelOfLearner', formik)
              }
              value={formik.values.levelOfLearner}
              textError={formik?.errors?.levelOfLearner}
            />
            <SelectGroupChip
              boxSx={{ mb: 3 }}
              id="otherLevelOfLearner"
              name="otherLevelOfLearner"
              labelText="ระดับของผู้เรียน ช่องทางการจำหน่ายอื่น"
              placeholder={'กรุณาเลือก'}
              options={otherLevelByDist}
              disabled={
                checkChangeForm(newVersion, isEditForm)
                  ? false
                  : checkHaveUUID('distribution', formik)
              }
              onChange={(list) =>
                onSelectGroupChipChange(list, 'otherLevelOfLearner', formik)
              }
              value={formik.values.otherLevelOfLearner}
              textError={formik?.errors?.otherLevelOfLearner}
            />
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: 3,
                mb: 3,
                [theme.breakpoints.down('sm')]: {
                  flexDirection: 'column',
                  gap: 0,
                },
              }}
            >
              <SelectGroupChip
                id="trainingPlatform"
                name="trainingPlatform"
                labelText="รูปแบบการสอน"
                required
                textError={formik?.errors?.trainingPlatform}
                placeholder={'กรุณาเลือก'}
                options={trainingPlatformOptions}
                disabled={
                  checkChangeForm(newVersion, isEditForm)
                    ? true
                    : checkHaveUUID('roadmap', formik)
                }
                onChange={(list) =>
                  onSelectGroupChipChange(list, 'trainingPlatform', formik)
                }
                value={initialValues.trainingPlatform}
              />
              <Box
                sx={{
                  width: '100%',
                  [theme.breakpoints.down('sm')]: {
                    pb: 3,
                  },
                }}
              >
                <Typography variant="body2b">รหัสหลักสูตร</Typography>
                <Typography id="courseCode" name="courseCode">
                  {checkChangeForm(newVersion, isEditForm) &&
                  cloneBy !== 'version'
                    ? courseCode
                    : genCourseCode({
                        formik: formik,
                        trainingPlatform: trainingPlatform,
                        allRoadMap: roadmap,
                        allDist: distribution,
                        cloneBy,
                      })}
                </Typography>
              </Box>
            </Box>
            <SelectGroupChip
              boxSx={{ mb: 3 }}
              id="acquiredSkill"
              name="acquiredSkill"
              labelText="ระดับหลักสูตร"
              required
              textError={formik?.errors?.acquiredSkill}
              placeholder={'กรุณาเลือก'}
              options={acquiredSkillOptions}
              onChange={(list) =>
                onSelectGroupChipChange(list, 'acquiredSkill', formik)
              }
              value={initialValues.acquiredSkill}
            />
            <SelectGroupChip
              boxSx={{ mb: 3 }}
              id="productType"
              name="productType"
              labelText="ทักษะที่ได้จากหลักสูตร"
              required
              textError={formik?.errors?.productType}
              placeholder={'กรุณาเลือก'}
              options={productTypeOptions}
              onChange={(list) =>
                onSelectGroupChipChange(list, 'productType', formik)
              }
              value={initialValues.productType}
            />
            {window.__env__.ENABLE_SKILL_MAPPING && (
              <>
                <Row>
                  <Typography variant="body2" color={'text.secondary'}>
                    ทักษะของวิทยากร
                  </Typography>
                  <Typography sx={{ lineHeight: 1, ml: 0.5 }} color={'error'}>
                    *
                  </Typography>
                </Row>
                <SpeakerProductType
                  formik={formik}
                  productTypeOptions={productTypeOptions}
                />
              </>
            )}
            {/* <QuillEditor
              required
              id="coursePurpose"
              labelText="จุดประสงค์ของหลักสูตร"
              value={formik?.values?.coursePurpose}
              textError={formik?.errors?.coursePurpose}
              onChange={(value) => formik.setFieldValue('coursePurpose', value)}
            /> */}
            {/* <QuillEditor
              required
              id="courseOutline"
              labelText="โครงร่างของหลักสูตร"
              value={formik?.values?.courseOutline}
              textError={formik?.errors?.courseOutline}
              onChange={(value) => formik.setFieldValue('courseOutline', value)}
            /> */}
            <Editor
              id="coursePurpose"
              name="coursePurpose"
              editorRef={coursePurposeEditorRef}
              onBlur={() =>
                onBlur(coursePurposeEditorRef, courseOutlineEditorRef, formik)
              }
              labelText="จุดประสงค์ของหลักสูตร"
              required
              value={formik?.values?.coursePurpose}
              textError={formik?.errors?.coursePurpose}
            />
            <Editor
              id="courseOutline"
              name="courseOutline"
              editorRef={courseOutlineEditorRef}
              onBlur={() =>
                onBlur(coursePurposeEditorRef, courseOutlineEditorRef, formik)
              }
              labelText="โครงร่างของหลักสูตร"
              required
              value={formik.values.courseOutline}
              textError={formik?.errors?.courseOutline}
            />
          </Card>
          <PointSetting
            initialValues={initialValues}
            setInitialValues={setInitialValues}
            formik={formik}
            pointOptions={pointOptions}
          />
          <Card
            sx={{
              width: '100%',
              minWidth: 275,
              display: 'flex',
              flexDirection: 'column',
              pt: 2,
              pb: 3,
              px: 3,
              gap: 2,
            }}
          >
            <Typography variant="h6">เงื่อนไขการเข้าอบรม</Typography>
            <Prerequisite
              formik={formik}
              license={license}
              allCourse={allCourse}
            />

            {/* <Condition
              formik={formik}
              license={license}
              allCourse={allCourse}
            /> */}
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
              }}
            ></Box>
          </Card>

          <Card
            sx={{
              width: '100%',
              minWidth: 275,
              display: 'flex',
              flexDirection: 'column',
              pt: 2,
              pb: 3,
              px: 3,
              gap: 2,
            }}
          >
            <Typography variant="h6">ระดับการควบคุม / การจัดการ</Typography>
            <Box
              sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}
            >
              <Select
                dataTestid="select-courseLevelControl"
                boxSx={{
                  width: '50%',
                  [theme.breakpoints.down('lg')]: {
                    width: '100%',
                  },
                }}
                id="courseLevelControl"
                name="courseLevelControl"
                labelText="ระดับการควบคุมหลักสูตร"
                type="basic"
                textError={formik?.errors?.courseLevelControl}
                required
                options={courseLevelControlOptions}
                handleChange={(e) => onCourseLevelControlChange(e, formik)}
                value={formik.values.courseLevelControl}
                placeholder="กรุณาเลือก"
              />
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                }}
              >
                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                  <Typography variant="body2" color={'text.secondary'}>
                    การอนุมัติหลักสูตร
                  </Typography>
                  <Typography sx={{ lineHeight: 1, ml: 0.5 }} color={'error'}>
                    *
                  </Typography>
                </Box>
                <RadioGroup
                  sx={{ ml: 1 }}
                  aria-label="approval"
                  defaultValue={formik.initialValues.isApproved}
                  name="isApproved"
                  id="isApproved"
                  value={formik.values.isApproved}
                  onChange={(e) => handleRadioChange(e, formik)}
                >
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="ไม่มีการอนุมัติ"
                  />
                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="มีการอนุมัติ"
                  />
                </RadioGroup>
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
              }}
            >
              <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                <Typography variant="body2" color={'text.secondary'}>
                  การเตรียมอาหาร Break / Lunch
                </Typography>
                <Typography sx={{ lineHeight: 1, ml: 0.5 }} color={'error'}>
                  *
                </Typography>
              </Box>
              <RadioGroup
                sx={{ ml: 1 }}
                aria-label="breakLucnch"
                defaultValue={formik.initialValues.hasBreakMeal}
                name="hasBreakMeal"
                id="hasBreakMeal"
                value={formik.values.hasBreakMeal}
                onChange={(e) => handleRadioChange(e, formik)}
              >
                <FormControlLabel
                  value={false}
                  control={<Radio />}
                  label="ไม่มี"
                />
                <FormControlLabel value={true} control={<Radio />} label="มี" />
              </RadioGroup>
              {formik?.errors?.hasBreakMeal ? (
                <Typography variant="body2" color="error">
                  {formik?.errors?.hasBreakMeal}
                </Typography>
              ) : (
                <Box sx={{ height: '25px' }}>
                  {formik?.errors?.hasBreakMeal}
                </Box>
              )}
            </Box>
            {formik?.values?.hasBreakMeal && (
              <TextInput
                boxSx={{
                  width: '50%',
                  [theme.breakpoints.down('lg')]: {
                    width: '100%',
                  },
                }}
                id="breakMealBudget"
                name="breakMealBudget"
                labelText="ค่าใช้จ่ายต่อหัว"
                maxlength={6}
                type="basic"
                placeholder="กรุณากรอก"
                textError={formik?.errors?.breakMealBudget}
                required
                endAdornmentText={'บาท'}
                onChange={(e) =>
                  handleNumbericDecimalChange(e, setInitialValues)
                }
                inputProps={{
                  type: 'number',
                  onInput: lib.handleNumberInput,
                }}
                onBlur={(e) => {
                  handleNumbericBlur(e, formik, true)
                }}
                value={initialValues.breakMealBudget}
                defaultValue={formik.values.breakMealBudget}
              ></TextInput>
            )}
          </Card>
          <OicExamSetting
            initialValues={initialValues}
            setInitialValues={setInitialValues}
            formik={formik}
          />
          <DocumentSetting
            initialValues={initialValues}
            setInitialValues={setInitialValues}
            formik={formik}
          />
          <AttendanceSetting
            initialValues={initialValues}
            setInitialValues={setInitialValues}
            formik={formik}
          />
          <MaterialSetting
            initialValues={initialValues}
            setInitialValues={setInitialValues}
            formik={formik}
          />
          <TestAndEvaluateSetting
            initialValues={initialValues}
            setInitialValues={setInitialValues}
            formik={formik}
          />
        </Box>
      </Box>
      <AlertDialog
        open={dialog.open}
        setOpen={setDialog}
        handleClose={handleClose}
        title={dialog.title}
        content={dialog.content}
        variant={dialog.variant}
        onConfirmClick={dialog.onConfirmClick}
        onCancelClick={dialog.onCancelClick}
        isLoading={isLoading}
      />
    </>
  )
}
export default CourseSettingForm

const BoxStatus = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
}))
const LabelStatus = styled(Typography)(() => ({
  width: '50%',
}))

export const setupRoadmapOptions = (
  roadmap,
  newVersion,
  isEditForm,
  cloneBy,
  formik,
  setRoadmapByDistOptions,
  getLevelByDistributionId,
) => {
  const roadmapOptions =
    roadmap &&
    getOptionsKey({
      data: roadmap,
      label: 'title',
      key: 'uuid',
    })
  setRoadmapByDistOptions(roadmapOptions)

  if (newVersion || isEditForm || cloneBy === 'course') {
    getLevelByDistributionId(formik.values.distribution.uuid)
  }
}

export const onBlur = (
  coursePurposeEditorRef,
  courseOutlineEditorRef,
  formik,
) => {
  if (coursePurposeEditorRef.current) {
    formik.setFieldValue(
      'coursePurpose',
      coursePurposeEditorRef.current.getContent(),
    )
  }
  if (courseOutlineEditorRef.current) {
    formik.setFieldValue(
      'courseOutline',
      courseOutlineEditorRef.current.getContent(),
    )
  }
}

export const onDateChange = (value, name, formik) => {
  try {
    const dateISO = new Date(value)
    dateISO.setHours(7)
    if (dateISO.toISOString() === '1970-01-01T00:00:00.000Z') {
      formik.setFieldValue(name, '')
    } else {
      formik.setFieldValue(name, dateISO.toISOString())
    }
  } catch (er) {
    console.log(er)
  }
}

export const handleRadioChange = (e, formik) => {
  const { name, value } = e.target
  formik.setFieldValue(name, value === 'true')
}

export const handleNumbericDecimalChange = (e, setInitialValues) => {
  const { name, value } = e.target
  const replaceValue = value.replace(/[^\d]/g, '')
  const valueDec = value === '' ? 0 : parseInt(replaceValue)
  setInitialValues((prev) => ({ ...prev, [`${name}`]: valueDec }))
}

const regex = /^[0-9]+$/

export const handleInputFormat = (e) => {
  const key = e.nativeEvent.data

  if (_.isNil(key)) return e.target.value

  if (!regex.test(key)) e.target.value = e.target.value.slice(0, -1)
  if (!regex.test(e.target.value)) e.target.value = ''
  return e.target.value
}

export const handlePasteFormat = (e) => {
  const key = e.clipboardData.getData('text')
  if (!regex.test(key)) {
    e.preventDefault()
    return false
  }
}

export const handleNumbericBlur = (e, formik, isDecimal) => {
  const { name, value } = e.target
  const fixed = isDecimal ? 2 : 0
  const decimalValue = parseFloat(value).toFixed(fixed)
  formik.setFieldValue(name, decimalValue)
}

export const onSelectChange = (
  e,
  inputName,
  props,
  formik,
  getLevelByDistributionId,
  fetchRoadMapByDistId,
) => {
  const nameObj = {
    distribution: props.distribution,
    roadmap: props.roadmap,
    level: props.level,
    acquiredSkill: props.acquiredSkill,
    trainingPlatform: props.trainingPlatform,
    productType: props.productType,
  }
  const value = nameObj[inputName].find((i) => i.uuid === e.target.value)

  if (!value) return

  formik.setFieldValue(inputName, value)
  if (inputName === 'distribution') {
    getLevelByDistributionId(e.target.value)
    fetchRoadMapByDistId(e.target.value)
    const resetInputObj = {
      distribution: value,
      roadmap: defaultCoursePlatform.roadmap,
      levelOfLearner: defaultCoursePlatform.levelOfLearner,
      otherLevelOfLearner: defaultCoursePlatform.otherLevelOfLearner,
    }
    formik.setValues((prev) => ({ ...prev, ...resetInputObj }))
  }
}

export const createOption = (label) => ({ label, value: label })

export const handleKeyDown = (event, formik, inputValue, setInputValue) => {
  if (!inputValue) return

  const oldTags = _.get(formik.values, 'tag', []) || []
  const newTags = [...oldTags, createOption(inputValue)]
  switch (event.key) {
    case 'Enter':
    case 'Tab':
      formik.setFieldValue('tag', newTags)
      setInputValue('')
      event.preventDefault()
  }
}

export const onMinuteChange = (e, formik) => {
  formik.setFieldValue('minutes', +e.target.value)
}

export const checkHaveUUID = (field, formik) => {
  if (!formik) return
  return !formik.values[field].uuid
}

export const onSelectGroupChipChange = (list, inputName, formik) => {
  formik.setFieldValue(inputName, list)
}

export const onCourseLevelControlChange = (e, formik) => {
  formik.setFieldValue('courseLevelControl', e.target.value)
}

export const checkChangeForm = (newVersion, isEditForm) => {
  return newVersion === true || isEditForm === true
}

export const genCourseCode = ({
  formik,
  trainingPlatform,
  allRoadMap,
  allDist,
  cloneBy,
}) => {
  const {
    distribution,
    roadmap,
    trainingPlatform: trainningPlatformSelect,
  } = formik.values
  if (cloneBy === 'version') {
    return formik.values.courseCode
  }
  let distributionCode = distribution?.abbreviation || ''
  if (distribution?.value && allDist !== null) {
    const findByInitial = allDist.find(
      (item) => item.uuid === distribution.value,
    )
    distributionCode = findByInitial ? findByInitial.abbreviation : ''
  }
  let trainingPlatformCode = ''
  if (trainingPlatform && trainningPlatformSelect.length > 0) {
    const findChange = trainingPlatform.find(
      (item) => item.uuid === trainningPlatformSelect[0].value,
    )
    if (findChange) {
      trainingPlatformCode = findChange.abbreviation
    } else {
      const findByInitial =
        trainingPlatform.length > 0 &&
        trainingPlatform.find(
          (item) => item.uuid === trainningPlatformSelect[0].uuid,
        )

      trainingPlatformCode = _.get(findByInitial, 'abbreviation', '')
    }
    if (trainningPlatformSelect.length > 1) {
      trainingPlatformCode = 'HB'
    }
  }
  let roadmapCode = roadmap?.abbreviation || ''
  if (roadmap?.value && allRoadMap !== null) {
    const findByInitial = allRoadMap.find((item) => item.uuid === roadmap.value)

    roadmapCode = findByInitial ? findByInitial.abbreviation : ''
  }
  return distribution && trainingPlatformCode && roadmapCode
    ? `${distributionCode}-${trainingPlatformCode}-${roadmapCode}-XXXXX`
    : `-`
}
