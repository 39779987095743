import React from 'react'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { styled } from '@mui/system'
import Tooltip from '@mui/material/Tooltip'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import useLocalStorage from '@rehooks/local-storage'
import { themeColor } from '../../utils/themeData'
import FiberManualRecordRoundedIcon from '@mui/icons-material/FiberManualRecordRounded'
import { getPeriodTimeText, validatePermission } from '../../utils/lib'
import EventColumn from './EventColumn'
import _ from 'lodash'

export const filterPersonalPlan = (list) => {
  return list.map((item) => {
    const staffs = _.get(item, 'staffs', [])
    const resStaff = staffs.map((itemS) => {
      const workTypes = _.get(itemS, 'workTypes', [])
      const filterWorkType = workTypes.map((itemW) => {
        const value = itemW.startTime.replace(':', '.')
        return {
          ...itemW,
          sorted: parseFloat(value),
        }
      })
      return {
        ...itemS,
        workTypes: _.orderBy(filterWorkType, ['sorted'], ['asc']),
      }
    })
    return {
      ...item,
      staffs: resStaff,
    }
  })
}

export const getEventTooltipText = (work) => {
  let timeText = ''
  const timeObj = {
    MORNING: 'ครึ่งเช้า',
    AFTERNOON: 'กำหนดเอง',
    CUSTOM: 'กำหนดเอง',
    FULL: 'เต็มวัน',
  }
  timeText += timeObj[work.period]
  timeText += ` ${getPeriodTimeText(work.startTime)}`
  timeText += `-${getPeriodTimeText(work.endTime)}`
  return `${timeText}`
}

export const handleDateClick = (state, item, indexStaff, handleClick) => {
  const date = new Date(state.data.year, state.data.month, item.date)
  handleClick(date, _.get(item, `staffs[${indexStaff}]`, null))
}

const Workload = (props) => {
  const {
    state,
    handleClick,
    handleClickEdit,
    isOpenWorkload,
    setIsOpenWorkload,
    isShowDetail = false,
  } = props

  const [user] = useLocalStorage('user')
  const { detail, summary, threshold, personalPlan } = state.personalPlan
  const departmentList = summary.map((item) => item.department)
  const uniqDepartmentList = _.uniq(departmentList)
  const invert = _.invertBy(departmentList)

  const truncateString = (str, num) => {
    return str.slice(0, num)
  }

  const getCol = (item) => {
    return invert[item].length
  }

  return (
    <Box
      sx={{
        backgroundColor: 'background.paper',
        display: 'flex',
      }}
    >
      <Table>
        <HeaderRow>
          <Col
            style={{
              position: isShowDetail ? 'sticky' : 'relative',
              top: isShowDetail ? '80px' : '0px',
              left: 0,
              zIndex: 11,
              backgroundColor: themeColor.primary.main,
            }}
            rowSpan="2"
            colSpan="1"
            type="title"
          >
            <Typography variant="body1b">วันที่</Typography>
          </Col>

          {uniqDepartmentList &&
            uniqDepartmentList.map((item, key) => {
              return (
                <Col
                  key={key}
                  style={{
                    position: isShowDetail ? 'sticky' : 'relative',
                    top: isShowDetail ? '80px' : '0px',
                    zIndex: 10,
                    backgroundColor: themeColor.primary.main,
                  }}
                  colSpan={getCol(item)}
                  type="title"
                >
                  <Typography variant="body1b">{item}</Typography>
                </Col>
              )
            })}
        </HeaderRow>
        <HeaderRow>
          {summary &&
            summary.map((item, key) => {
              return (
                <Col
                  key={key}
                  style={{
                    position: isShowDetail ? 'sticky' : 'relative',
                    top: isShowDetail ? '124px' : '0px',
                    zIndex: 10,
                    padding: '5px',
                    cursor: 'default',
                    backgroundColor: themeColor.primary.main,
                  }}
                  type="title"
                >
                  <Tooltip
                    title={
                      <Typography variant="tooltip">
                        {_.get(item, 'englishName', '')}
                      </Typography>
                    }
                    arrow
                    placement="top"
                  >
                    <Typography variant="body1b">
                      {truncateString(item.name, 20)}
                    </Typography>
                  </Tooltip>
                </Col>
              )
            })}
        </HeaderRow>
        <Row>
          <Col
            colspan="1"
            style={{
              position: 'sticky',
              left: 0,
              zIndex: 9,
              backgroundColor: '#F1F4FA',
            }}
          >
            <Typography
              data-testid="text-collapse"
              sx={{ cursor: 'pointer' }}
              variant="body1b"
              color="primary"
              onClick={() => setIsOpenWorkload(!isOpenWorkload)}
            >
              วันทำงาน{' '}
              {isOpenWorkload ? (
                <ExpandLess sx={{ verticalAlign: 'middle' }} />
              ) : (
                <ExpandMore sx={{ verticalAlign: 'middle' }} />
              )}
            </Typography>
          </Col>
          {summary &&
            summary.map((item, index) => {
              return (
                <Col
                  colspan="1"
                  style={{
                    textAlign: 'start',
                  }}
                  onClick={handleClick}
                  key={index}
                >
                  <Typography
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: '100%',
                      textAlign: 'center',
                    }}
                    variant="body1b"
                    color={threshold > item.minutes ? 'error' : 'primary'}
                  >
                    {item.hours}
                  </Typography>
                </Col>
              )
            })}
        </Row>
        {detail &&
          detail.map((item, index) => {
            const staffs = item.staffs
            return (
              <DetailRow
                isOpenWorkload={isOpenWorkload}
                item={item}
                key={index}
              >
                <Col
                  colspan="1"
                  style={{
                    position: 'sticky',
                    left: 0,
                    zIndex: 9,
                    backgroundColor: '#fff',
                    paddingRight: '20px',
                    paddingLeft: '20px',
                  }}
                  sx={{
                    flexDirection: 'row',
                    justifyContent: 'center',
                    position: 'relative',
                  }}
                >
                  <Typography variant="body1">{item.title}</Typography>
                  {item.title === 'Academy Activity' && (
                    <Tooltip
                      sx={{
                        position: 'absolute',
                        right: 2,
                        top: 12,
                        width: '16px',
                        cursor: 'pointer',
                      }}
                      title={
                        <Typography variant="tooltip">
                          ไม่รวม Work Office
                        </Typography>
                      }
                      arrow
                      placement="right"
                    >
                      <InfoOutlinedIcon color="primary" />
                    </Tooltip>
                  )}
                </Col>

                {staffs.map((staff, indexStaff) => {
                  const hours = staff.hours
                  return (
                    <Col
                      key={indexStaff}
                      colspan="1"
                      style={{
                        textAlign: 'start',
                        verticalAlign: 'text-top',
                      }}
                    >
                      <Box
                        sx={{
                          p: 1,
                          display: 'flex',
                          flexDirection: 'column',
                          width: '100%',
                          textAlign: 'center',
                        }}
                      >
                        <Typography variant="body1">{hours}</Typography>
                      </Box>
                    </Col>
                  )
                })}
              </DetailRow>
            )
          })}
        {isShowDetail &&
          personalPlan &&
          filterPersonalPlan(personalPlan).map((item, planIndex) => {
            const staffs = item.staffs
            return (
              <RowPersonal item={item} key={planIndex}>
                <ColPersonal
                  style={{
                    position: 'sticky',
                    left: 0,
                    backgroundColor: 'white',
                    zIndex: 9,
                  }}
                >
                  <Typography variant="body1b">{item.day} </Typography>
                  {item.title && (
                    <Tooltip
                      title={
                        <Typography variant="tooltip">{item.title}</Typography>
                      }
                      arrow
                      placement="right"
                    >
                      <FiberManualRecordRoundedIcon
                        sx={{
                          fontSize: '16px',
                          position: 'absolute',
                          top: 0.5,
                          right: 0.5,
                          color: themeColor?.monthlyPlan?.holidayBorder,
                        }}
                      />
                    </Tooltip>
                  )}
                  <Typography variant="body1b">{item.date}</Typography>
                </ColPersonal>
                {staffs.map((staff, indexStaff) => {
                  const workTypes = _.get(staff, 'workTypes', '')
                  const isBorrow = _.get(staff, 'isBorrow', false)

                  return (
                    <ColPersonal
                      key={indexStaff}
                      data-testid={`box-day-${planIndex}-${indexStaff}`}
                      colspan="1"
                      style={{
                        width: '150px',
                        textAlign: 'start',
                        verticalAlign: 'text-top',
                      }}
                      onClick={() =>
                        validatePermission({
                          user: user,
                          moduleType: 'MONTHLY_PLAN',
                          permission: [
                            'CREATE_ALL',
                            'CREATE_ALL_EXC_HOLIDAY',
                            'CREATE_LEAVE',
                            'APPROVE_ZONE',
                            'ASSISTS_ZONE',
                          ],
                        }) &&
                        !isBorrow &&
                        !state.isAllStation &&
                        handleDateClick(state, item, indexStaff, handleClick)
                      }
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                        }}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',
                          }}
                        >
                          <EventColumn
                            events={workTypes}
                            handleClickEdit={handleClickEdit}
                            getEventTooltipText={getEventTooltipText}
                          />
                        </Box>
                      </Box>
                    </ColPersonal>
                  )
                })}
              </RowPersonal>
            )
          })}
      </Table>
    </Box>
  )
}
export default Workload

const Col = styled('td')(({ type }) => ({
  width: '150px',
  height: '44px',
  textAlign: 'center',
  border: `0.1px solid ${themeColor.text.mercury}`,
  color: type === 'title' ? themeColor.text.white : themeColor.common.black,
  whiteSpace: 'pre',
}))

const HeaderRow = styled('tr')(() => ({
  width: '100%',
  height: '44px',
}))

const Row = styled('tr')(() => ({
  width: '100%',
  height: '44px',
  backgroundColor: 'rgba(73, 118, 186, 0.08)',
}))
const DetailRow = styled('tr')(({ isOpenWorkload }) => ({
  width: '100%',
  height: '44px',
  display: isOpenWorkload ? 'table-row' : 'none',
}))

const Table = styled('table')(() => ({
  borderSpacing: 0,
}))

const ColPersonal = styled('td')({
  width: '75px',
  textAlign: 'center',
  border: `0.1px solid ${themeColor.text.mercury}`,
  position: 'relative',
})

const RowPersonal = styled('tr')(({ item }) => ({
  width: '100%',
  height: '90px',
  display: 'table-row',
  whiteSpace: 'noWrap',
  backgroundColor:
    item.day === 'Sat' || item.day === 'Sun' || item.title
      ? `${themeColor.monthlyPlan.holiday}`
      : 'unset',
}))
