import {
  setIsOpenFilterDrawer,
  setFiltersDashboard,
} from '../../../../redux/slices/expense'
import { store } from '../../../../App'
import { fetchDashboardData } from './fetchDashboardData'

export const filterDashboard = async () => {
  const { displayFilters } = store.getState().expense.dashboard

  store.dispatch(setIsOpenFilterDrawer(false))
  store.dispatch(setFiltersDashboard(displayFilters))
  fetchDashboardData()
}
