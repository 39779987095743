import { IconButton, Typography } from '@mui/material'
import React from 'react'
import { Row, StyledBox } from '../Styled'
import FinancialColumn from './FinancialColumn'
import HorizontalBarChart from '../../../../../components/Chart/HorizontalBarChart'
import { shallowEqual, useSelector } from 'react-redux'
import dayjs from 'dayjs'
import { useTheme } from '@mui/system'
import {
  formattedFloatNumber,
  validatePermission,
} from '../../../../../utils/lib'
import { ChevronRightOutlined } from '@mui/icons-material'
import { useParams, useHistory } from 'react-router-dom'
import { redirectToReport } from '../../handler/redirectToReport'
import useLocalStorage from '@rehooks/local-storage'
import { PERMISSION_REPORT_EXPENSE } from '../../../../../constants/report/expense'

const MonthlyFinancialDetailsBox = ({
  month,
  budget,
  actual,
  remaining,
  colorActual,
  colorRemaining,
  bgColor,
  percent,
  idx,
  budgetExceeded,
  monthNumber,
}) => {
  const theme = useTheme()
  const { uuid } = useParams()
  const history = useHistory()
  const { yearBudget, tabActive } = useSelector(
    (state) => ({
      yearBudget: state.expense.dashboard.yearBudget,
      tabActive: state.expense.dashboard.tabActive,
    }),
    shallowEqual,
  )

  const currentYear = dayjs().year()
  const currentMonth = dayjs().month() + 1

  const [user] = useLocalStorage('user')

  const hasReportExpense = validatePermission({
    user,
    moduleType: 'REPORT_EXPENSE',
    permission: [PERMISSION_REPORT_EXPENSE.REPORT_EXPENSE],
  })

  return (
    <StyledBox
      sx={{
        flexBasis: 'calc(33.33% - 11px)',
        boxSizing: 'border-box',
        border:
          yearBudget === currentYear && monthNumber === currentMonth
            ? `3px solid ${theme.palette.primary.outlinedResting}`
            : '',
      }}
    >
      <Row justifyContent="space-between">
        <Typography variant="body2">{month}</Typography>
        {tabActive === 1 && !uuid && hasReportExpense && (
          <IconButton
            sx={{ backgroundColor: 'primary.outlinedHoverBackground' }}
            onClick={() => redirectToReport(history, monthNumber)}
          >
            <ChevronRightOutlined sx={{ color: 'text.lightGray' }} />
          </IconButton>
        )}
      </Row>
      <Row gap={2}>
        <Typography variant="body1b" display="inline">
          Budget
        </Typography>
        <Typography variant="body2" display="inline">
          {formattedFloatNumber(budget)} ฿
        </Typography>
      </Row>
      <HorizontalBarChart
        width="100%"
        height={32}
        labels={['']}
        dataSet={{
          backgroundColor: bgColor,
          data: percent,
        }}
        eleId={`monthlySummaryBudgetChart-${idx}`}
        borderRadius={10}
        barThickness={32}
      />
      <Row sx={{ width: '100%' }}>
        <FinancialColumn
          label="Actual"
          amount={actual}
          color={colorActual}
          budgetExceeded={budgetExceeded}
        />
        <FinancialColumn
          label="Remaining"
          amount={remaining}
          color={colorRemaining}
          budgetExceeded={budgetExceeded}
        />
      </Row>
    </StyledBox>
  )
}

export default MonthlyFinancialDetailsBox
