import React, { useState } from 'react'
import { styled, useTheme } from '@mui/material/styles'
import TruncateMarkup from 'react-truncate-markup'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import _ from 'lodash'

const UserLevelItemStyle = styled('div')(({ theme }) => ({
  border: '1px solid',
  borderColor: theme?.palette?.text?.silver,
  paddingLeft: 12,
  paddingRight: 12,
  marginRight: 8,
  borderRadius: 20,
  marginBottom: 5,
  marginTop: 5,
  wordBreak: 'break-all',
}))

const UserLevelStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  cursor: 'pointer',
  [theme.breakpoints.down('sm')]: {
    '& .MuiSvgIcon-root': {
      alignSelf: 'center',
    },
  },
}))

export const UserLevelListGroup = ({ levelList }) => {
  const theme = useTheme()
  const [expand, setExpand] = useState(false)
  let showItem = []

  const filterAcquiredSkill = levelList.filter((d) => d.skillType !== '-')
  const groupAcquiredSkill = _.groupBy(filterAcquiredSkill, (d) => d.skillType)

  for (const key in groupAcquiredSkill) {
    showItem = [...showItem, groupAcquiredSkill[key]]
  }

  const usersLeftEllipsis = (node) => {
    const usersRendered = node.props.children

    return `+${filterAcquiredSkill.length - usersRendered.length} more`
  }

  return (
    <>
      {!expand && (
        <UserLevelStyle
          data-testid={'user-level-group-expand'}
          onClick={() => setExpand(true)}
        >
          <TruncateMarkup lines={1} ellipsis={usersLeftEllipsis}>
            <div
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {showItem &&
                showItem.map((item) =>
                  item.map((d, i) => {
                    return (
                      <TruncateMarkup.Atom key={i}>
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            [theme.breakpoints.down('sm')]: {
                              flexDirection: 'column',
                              alignItems: 'start',
                            },
                            pb: 1.5,
                            alignItems: 'center',
                          }}
                        >
                          {i === 0 && (
                            <Typography>{d.skillType}: &nbsp;</Typography>
                          )}
                          <UserLevelItemStyle>
                            {d.acquiredSkill}
                          </UserLevelItemStyle>
                        </Box>
                      </TruncateMarkup.Atom>
                    )
                  }),
                )}
            </div>
          </TruncateMarkup>
        </UserLevelStyle>
      )}

      {expand && (
        <UserLevelStyle
          data-testid={'user-level-group-not-expand'}
          onClick={() => setExpand(false)}
        >
          {showItem &&
            showItem.map((item) =>
              item.map((d, i) => {
                return (
                  <TruncateMarkup.Atom key={i}>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        pb: 1.5,
                        alignItems: 'center',
                        [theme.breakpoints.down('sm')]: {
                          flexDirection: 'column',
                          alignItems: 'start',
                          justifyContent: 'flex-end',
                        },
                      }}
                    >
                      {i === 0 && (
                        <Typography>{d.skillType}: &nbsp;</Typography>
                      )}
                      <UserLevelItemStyle>{d.acquiredSkill}</UserLevelItemStyle>
                    </Box>
                  </TruncateMarkup.Atom>
                )
              }),
            )}

          <KeyboardArrowUpIcon />
        </UserLevelStyle>
      )}
    </>
  )
}

const UserLevelList = ({ levelList }) => {
  const [expand, setExpand] = useState(false)
  const usersLeftEllipsis = (node) => {
    const usersRendered = node.props.children
    return `+${levelList.length - usersRendered.length} more`
  }

  return (
    <>
      {!expand && (
        <UserLevelStyle
          data-testid={'user-level-not-expand'}
          onClick={() => setExpand(true)}
        >
          <TruncateMarkup lines={1} ellipsis={usersLeftEllipsis}>
            <div
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {levelList &&
                levelList.map((item, i) => {
                  return (
                    <TruncateMarkup.Atom key={i}>
                      <UserLevelItemStyle>{item}</UserLevelItemStyle>
                    </TruncateMarkup.Atom>
                  )
                })}
            </div>
          </TruncateMarkup>
        </UserLevelStyle>
      )}
      {expand && (
        <UserLevelStyle
          data-testid={'user-level-expand'}
          onClick={() => setExpand(false)}
        >
          {levelList &&
            levelList.map((item, i) => {
              return <UserLevelItemStyle key={i}>{item}</UserLevelItemStyle>
            })}

          <KeyboardArrowUpIcon />
        </UserLevelStyle>
      )}
    </>
  )
}
export default UserLevelList
