export const initialState = {
  isLoading: false,
  courseList: [],
  courseType: '',
  courseCode: '',
  error: '',
  startDate: '',
  finishDate: '',
  firstNameTH: '',
  lastNameTH: '',
  idCardNo: '',
  unitCode: '',
  agentType: '',
  fullAgentCode: '',
  agentCode: '',
}
