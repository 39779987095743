import { path } from 'src/constants/path'

export const getViewUrl = (row) => {
  const { userProfile } = row
  const { userType } = userProfile
  const disChannel = row.userType
  if (['STAFF', 'NON_ACADEMY'].includes(userType)) {
    const { type } = userProfile.staffProfile
    return type === 'STAFF'
      ? `${path.staffProfile}/${userProfile.staffProfile.uuid}`
      : `${path.nonAcademyProfile}/${userProfile.staffProfile.uuid}`
  } else if (userType === 'AGENT') {
    const uuid = userProfile.agentProfile.uuid
    if (disChannel === 'CS') {
      return `${path.csAgentProfile}/view/${uuid}`
    } else {
      return `${path.agentProfile}/view/${uuid}`
    }
  } else if (userType === 'PROSPECT') {
    const uuid = userProfile.prospectProfile.uuid
    if (disChannel === 'CS') {
      return `${path.csUserProfile}/view/${uuid}`
    } else {
      return `${path.prospectProfile}/view/${uuid}`
    }
  }
}

export const getViewUrlETest = (row) => {
  const { userProfile } = row
  const { userType } = userProfile
  const disChannel = row.abbreviationDistribution
  if (['STAFF', 'NON_ACADEMY'].includes(userType)) {
    const { type } = userProfile.staffProfile
    return type === 'STAFF'
      ? `${path.staffProfile}/${userProfile.staffProfile.uuid}`
      : `${path.nonAcademyProfile}/${userProfile.staffProfile.uuid}`
  } else if (userType === 'AGENT') {
    const uuid = userProfile.agentProfile.uuid
    if (disChannel === 'CS') {
      return `${path.csAgentProfile}/view/${uuid}`
    } else {
      return `${path.agentProfile}/view/${uuid}`
    }
  } else if (userType === 'PROSPECT') {
    const uuid = userProfile.prospectProfile.uuid
    if (disChannel === 'CS') {
      return `${path.csUserProfile}/view/${uuid}`
    } else {
      return `${path.prospectProfile}/view/${uuid}`
    }
  }
}
