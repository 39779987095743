import React from 'react'
import _ from 'lodash'
import { store } from '../../../../../../../App'
import {
  loadingDialog,
  openDialog,
  closeDialog,
  stopLoadingDialog,
} from '../../../../../../../redux/slices/dialog'
import { resetForm } from '../../../../../../../redux/slices/manageClassLearner'
import { announceCourseBatchLearner } from '../../../../../../../services/eLearning/course/form'
import { postIneligibleLearner } from '../../../../../../../services/eLearning/course/learner'
import AddLearnerDialog from '../AddLearnerDialog'
import ReasonDialog from '../ReasonDialog'

export const handleAddLearner = () => (dispatch) => {
  dispatch(resetForm())
  dispatch(
    openDialog({
      type: 'mini',
      maxWidth: 'form',
      disableButton: true,
      content: <AddLearnerDialog />,
    }),
  )
}

export const handleSelectedList = (selected, rows) => {
  const mapSelectedList = selected.map((item) => ({ uuid: item }))
  return _.intersectionBy(rows, mapSelectedList, 'uuid')
}

export const filterWaitingAnnonce = (selected, rows) => {
  const intersecSelected = handleSelectedList(selected, rows)
  const filterWaitingAnnounceStatus = intersecSelected.filter(
    (item) => item.statusLearner === 'WAITING_ANNOUNCE',
  )
  return filterWaitingAnnounceStatus.map((item) => item.uuid)
}

export const handleAnnounceLearner = (uuid, selected, rows) => (dispatch) => {
  const body = {
    courseBatchUuid: uuid,
    courseLearner: filterWaitingAnnonce(selected, rows),
  }
  dispatch(
    openDialog({
      title: 'ประกาศผู้มีสิทธิ์เข้าเรียน',
      message:
        'ระบบจะอัปเดตสถานะพร้อมส่งอีเมลยืนยันแจ้งกับผู้เรียนที่มีสิทธิ์ทราบต้องการดำเนินการเลยหรือไม่',
      handleConfirm: async () => {
        dispatch(loadingDialog())
        await dispatch(announceCourseBatchLearner(body))
        dispatch(stopLoadingDialog())
        dispatch(closeDialog())
        window.location.reload()
      },
    }),
  )
}

export const filterIneligible = (selected, rows) => {
  const intersecSelected = handleSelectedList(selected, rows)
  const filterWaitingAnnounceStatus = intersecSelected.filter(
    (item) =>
      item.statusLearner === 'WAITING_CONFIRM_IDENTITY' ||
      item.statusLearner === 'WAITING_ANNOUNCE' ||
      item.statusLearner === 'ELIGIBLE_ANNOUNCE',
  )
  return filterWaitingAnnounceStatus.map((item) => item.uuid)
}

export const handleIneligible = (uuid, selected, rows) => {
  store.dispatch(
    openDialog({
      type: 'mini',
      maxWidth: 'form',
      disableButton: true,
      content: (
        <ReasonDialog
          title="เหตุผลที่ไม่มีสิทธิ์เข้าเรียน"
          onSubmit={(remark) =>
            handleSubmitIneligible(uuid, selected, rows, remark)
          }
        />
      ),
    }),
  )
}

export const handleSubmitIneligible = async (uuid, selected, rows, remark) => {
  store.dispatch(loadingDialog())
  const body = {
    courseBatchUuid: uuid,
    courseLearner: filterIneligible(selected, rows),
    remark,
  }
  const response = await store.dispatch(postIneligibleLearner(body))
  store.dispatch(stopLoadingDialog())
  store.dispatch(closeDialog())
  if (_.isEmpty(response.error)) window.location.reload()
}
