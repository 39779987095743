import React, { useEffect } from 'react'
import Box from '@mui/material/Box'
import { useSelector, shallowEqual } from 'react-redux'

import Info from './Info'
import Setting from './Setting'
import Share from './Share'
import Notification from './Notification'
import TestingRound from './Setting/TestingRound'
import { BoxCard } from './Styled'
import { handlePersist } from './event'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { useDispatch } from 'react-redux'
import { setOnViewMode } from '../../../../../../redux/slices/eTestingForm'

const Index = () => {
  const dispatch = useDispatch()
  const typeOfETesting = useSelector(
    (state) => state.eTestingForm.typeOfETesting,
    shallowEqual,
  )
  const { uuid } = useParams()
  const props = { uuid }
  useEffect(async () => {
    dispatch(handlePersist(props))
    dispatch(setOnViewMode(true))
  }, [])

  return (
    <Box sx={{ display: 'grid', gap: 3 }}>
      <Info />
      {typeOfETesting === 'ONLINE_TEST' && (
        <>
          <Setting />
          <Notification />
        </>
      )}
      {typeOfETesting === 'STANDALONE' && (
        <>
          <Setting />
          <Share />
        </>
      )}
      {typeOfETesting === 'ASSESSMENT' && (
        <BoxCard>
          <TestingRound />
        </BoxCard>
      )}
    </Box>
  )
}

export default Index
