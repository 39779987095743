import Box from '@mui/material/Box'
import styled from '@mui/system/styled'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import React from 'react'
import Card from '@mui/material/Card'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'

export const BoxTooltipAlert = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
  alignItems: 'center',
}))

export const IconInfo = styled(InfoOutlinedIcon)(({ theme }) => ({
  color: theme?.palette?.warning?.main,
  cursor: 'pointer',
}))

export const BoxForm = styled(Box)(({ theme }) => ({
  margin: theme.spacing(3),
  marginTop: theme.spacing(0.5),
  marginBottom: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
  // justifyContent: 'space-between',
  [theme.breakpoints.down('sm')]: {
    display: 'block',
    flexDirection: 'unset',
    justifyContent: 'unset',
  },
}))

export const BoxFlex = styled(Box)(({ theme, isColumn, isAlignItems }) => ({
  display: 'flex',
  alignItems: isAlignItems ? 'flex-start' : '',
  flexDirection: isColumn ? 'column' : 'row',
  width: '100%',
  gap: isColumn ? 0 : theme.spacing(3),
  marginBottom: isColumn ? theme.spacing(2) : 0,

  [theme.breakpoints.down('sm')]: {
    display: 'block',
    flexDirection: 'unset',
    justifyContent: 'unset',
  },
}))

export const BoxFlexSelect = styled(Box)(({ theme }) => ({
  width: '35%',
  gap: theme.spacing(3),
  [theme.breakpoints.down('md')]: {
    width: '50%',
  },
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    marginBottom: theme.spacing(3),
  },
}))

export const BoxUpload = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  [theme.breakpoints.down('sm')]: {
    display: 'flex',
    flexWrap: 'wrap',
  },
}))

export const BoxAddChoice = styled(Box)(({ theme }) => ({
  width: '100px',
  display: 'flex',
  gap: theme.spacing(1),
  marginLeft: '37px',
  cursor: 'pointer',
  marginTop: theme.spacing(0),
}))

export const BoxAddQuestion = styled(Box)(({ theme }) => ({
  margin: theme.spacing(3),
}))

export const BoxQuestion = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(4),
}))

export const CardAddQuestion = styled(Card)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(3),
}))

export const InputImageUpload = styled('input')({
  display: 'none',
})

export const TooltipForm = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 192,
    wordBreak: 'break-word',
    backgroundColor: theme?.palette?.blue?.azure,
    '& .MuiTooltip-arrow': { color: theme?.palette?.blue?.azure },
    [theme.breakpoints.down('sm')]: { marginLeft: '10px !important' },
  },
  [theme.breakpoints.down('sm')]: { maxWidth: 180 },
}))

export const BoxTextTooltip = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
})

export const styledImageBoxProps = (theme, isLoading, isError) => ({
  borderWidth: '1px',
  borderStyle: isLoading || isError ? 'dashed' : 'solid',
  borderColor: isError
    ? theme?.palette?.error?.main
    : theme?.palette?.other?.outlineBorder,
  display: 'flex',
  alignItems: 'center',
  '& .MuiBox-root': {
    width: 60,
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    gap: isLoading ? theme.spacing(1.5) : 0,
    alignItems: 'center',
    '& > .MuiSvgIcon-root': {
      color: isError
        ? theme?.palette?.error?.main
        : theme?.palette?.text?.silver,
    },
    '& > .MuiTypography-root': {
      color: isError
        ? theme?.palette?.error?.main
        : theme?.palette?.text?.silver,
    },
    '& > .MuiLinearProgress-root': { width: 60 },
  },
})
