import { setInitialTable } from '../../../../redux/slices/table'
import { store } from '../../../../App'
import { permissionCostCenterHeadCells } from '../models'
import axios from 'axios'
import _ from 'lodash'
import { CRUDListFilterPath } from '../../../../utils/apiPath'
import { bodyCostCenter } from '../../../../modules/Expense/handler/fetchMasterDataOptions'
import { handleQuickSearch } from './handleQuickSearch'
import {
  startPageLoading,
  stopPageLoading,
} from '../../../../redux/slices/permission'

export const initialize = async () => {
  store.dispatch(startPageLoading())
  const { data } = store.getState().permission
  const { searchText } = store.getState().table.search
  const results = await axios({
    method: 'post',
    url: CRUDListFilterPath,
    data: { ...bodyCostCenter, quickSearch: searchText },
    headers: {
      'x-type': 'COST_CENTER',
    },
  }).then((res) => _.get(res, 'data.result', []))

  store.dispatch(
    setInitialTable({
      rows: _.orderBy(results, 'id'),
      selected: _.get(data, 'costCenter', []),
      headCells: permissionCostCenterHeadCells,
      placeholder: 'ค้นหา',
      searchKey: 'name',
      isShowPagination: false,
      handleSearch: (text) => handleQuickSearch(text),
    }),
  )
  store.dispatch(stopPageLoading())
}
