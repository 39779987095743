import React from 'react'
import _ from 'lodash'
import {
  setAddStaff,
  setChange,
  setDeleteStaff,
  setReduxValue,
} from '../../../redux/slices/permission'
import { closeDialog, openDialog } from '../../../redux/slices/dialog'
import { StaffAutocompleteInput } from '.'
import { store } from '../../../App'

export const handleInputChange = (e) => (dispatch) => {
  const key = e.target.name
  const value = e.target.value
  dispatch(setChange({ key, value }))
}

export const onView = (row) => {
  window.open('/manage/staff-profile/' + row.uuid)
}

export const onDelete = (index) => (dispatch) => {
  dispatch(
    openDialog({
      type: 'delete',
      title: 'ยืนยันลบข้อมูล',
      message: 'คุณต้องการลบต้องการลบพนักงานคนนี้ออกจากกลุ่มนี้หรือไม่',
      handleConfirm: () => {
        dispatch(setDeleteStaff(index))
        dispatch(closeDialog())
      },
    }),
  )
}

export const convertData = (list) => {
  return _.compact(list).map((item) => {
    const findDefaultArea = _.get(item, 'area', []).find(
      (a) => a.default === true,
    )
    return {
      ...item,
      uuid: _.get(item, 'uuid', ''),
      firstName: `${item.firstNameTH} ${item.lastNameTH}`,
      agentCode: _.get(item, 'agentCode', ''),
      department: _.get(findDefaultArea, 'department.initial', ''),
      station: _.get(findDefaultArea, 'station.station', ''),
    }
  })
}

export const handleSetSelectedProps = (list, permissions) => (dispatch) => {
  let getName = []
  let strPermission = ''
  permissions.map((permission) => {
    list.map((listItem) => {
      if (listItem === permission.uuid) {
        getName.push(permission.id)
      }
    })
  })
  strPermission = getName.join(',')
  dispatch(setChange({ key: 'permissionGroup', value: strPermission }))
  dispatch(setChange({ key: 'permission', value: list }))
}

export const onAddMemberClick = (staff, staffOptions) => (dispatch) => {
  let value = ''
  dispatch(
    openDialog({
      type: 'content',
      title: 'เพิ่มสมาชิก',
      content: (
        <StaffAutocompleteInput
          staff={staff}
          staffOptions={staffOptions}
          onChange={(e) => (value = e.target.id)}
        />
      ),
      handleConfirm: () => {
        if (!_.isEmpty(value)) dispatch(setAddStaff(value))
        dispatch(closeDialog())
      },
    }),
  )
}

export const handleChangeTab = (newValue) => {
  store.dispatch(setReduxValue({ key: 'tabActive', value: newValue }))
}
