import _ from 'lodash'
import {
  resetTablePage,
  setFilterProp,
  setInitialTable,
  setSearchText,
  setStatusFilter,
  startLoading,
  stopLoading,
  setFilterTotal,
  resetPage,
} from '../../../redux/slices/table'
import { downloadFile } from '../../../services/util'
import { initialState } from '../../../redux/slices/table/model'
import callAxios from '../../../utils/baseService'
import { QUESTION_STATUS, headCellQuestion } from '../../../constants/eLearning'
import {
  eLearningQuestionDownload,
  eLearningQuestionFilter,
} from '../../../utils/apiPath'
import { convertFormatDateTime, getQueryParams } from '../../../utils/lib'
import dayjs from 'dayjs'

export const onDownload = (selected, sort, order) => async (dispatch) => {
  dispatch(startLoading())
  const body = {
    order: order.toUpperCase(),
    sort: sort,
    type: 'CUSTOM',
    list: selected,
  }
  console.log(body)

  await dispatch(
    downloadFile({
      url: eLearningQuestionDownload,
      body: body,
      fileName: `รายการคำถามE-Learning.xlsx`,
    }),
  )

  dispatch(stopLoading())
}

export const fetchDataList = (props) => async (dispatch) => {
  const { method, table, page, status, filterProp, keyword, setInitial } = props
  const isInitial = method == 'initial'
  const realPage = page <= 0 ? 1 : +page
  const tableProps = isInitial ? initialState.table : table
  const filter = isInitial ? null : filterProp
  const title = isInitial ? '' : _.get(filter, 'name', keyword)
  const questionBy = isInitial ? '' : _.get(filter, 'questionByName', '')
  const uuid = isInitial ? '' : _.get(filter, 'uuid', '')
  const updatedBy = isInitial ? '' : _.get(filter, 'updatedBy', '')
  const batchUuid = getQueryParams('batch')
  const body = {
    limit: _.get(tableProps, 'limit', '20'),
    page: realPage,
    order: tableProps.order.toString().toUpperCase(),
    sort: _.get(tableProps, 'sort', 'updatedAt'),
    status: isInitial ? [] : status,
    name: method == 'filter' ? keyword : title,
    questionByName: method == 'filter' ? questionBy : '',
    uuid: batchUuid ? batchUuid : uuid,
    updatedBy: updatedBy,
  }

  dispatch(startLoading())
  const isCancel = await callAxios
    .post(eLearningQuestionFilter, body)
    .then((res) => {
      const row = _.get(res, 'data.result', []).map((item) => ({
        ...item,
        status: item.status == 'inprogress' ? 'error' : item.status,
        statusTH: _.get(item, 'statusTH', '-'),

        answerBy:
          _.get(item, 'answerBy.firstNameTH', '-') +
            ' ' +
            _.get(item, 'answerBy.lastNameTH', '-') || '-',
        questionBy:
          _.get(item, 'questionBy.userProfile.firstNameTH', '-') +
            ' ' +
            _.get(item, 'questionBy.userProfile.lastNameTH', '-') || '-',

        nameQuestion: _.get(item, 'eLearningCourse.name', '-') || '-',
        batch: formatDateDisplay(
          _.get(item, 'eLearningCourseBatch.periodStart', '-'),
        ),
        createdAt: convertFormatDateTime({
          value: item.createdAt,
          type: 'date',
        }),
      }))
      dispatch(
        setInitialTable({
          rows: row,
          allCount: _.get(res, 'data.totalCount', 0),
          headCells: headCellQuestion,
          placeholder: 'ค้นหาชื่อ',
          searchKey: 'name',
          status: status,
          handleSearch: (text) => dispatch(handleQuickSearch(tableProps, text)),
          onDownload: (selected, sort, order) =>
            dispatch(onDownload(selected, sort, order)),
        }),
      )
    })
    .catch((e) => {
      dispatch(
        setInitialTable({
          rows: [],
          allCount: 0,
          headCells: headCellQuestion,
        }),
      )
      return e.message?.includes('method')
    })

  setInitial && setInitial(false)
  if (!isCancel) dispatch(stopLoading())
}

export const onFilterClick = (table, filter) => (dispatch) => {
  dispatch(setSearchText(''))

  let status = []
  if (filter.statusChecked) {
    if (filter.status.activeChecked) {
      status.push(QUESTION_STATUS.SUCCESS)
    }
    if (filter.status.inActiveChecked) {
      status.push(QUESTION_STATUS.WAITING)
    }
  } else {
    status = []
  }
  dispatch(setStatusFilter(status))

  const filterProp = {
    questionByName: filter.questionByChecked ? filter.questionByName : '',
    name: filter.nameChecked ? filter.name : '',
    status: status,
    updatedBy: filter.updatedByChecked ? filter.updatedBy : '',
  }
  dispatch(setFilterProp(filterProp))
  dispatch(setFilterTotal(filter))
  dispatch(resetTablePage())
  dispatch(
    fetchDataList({
      method: 'filter',
      table,
      page: 1,
      status,
      filterProp,
      keyword: filterProp.name,
    }),
  )
}

export const handleQuickSearch = (table, keyword) => (dispatch) => {
  dispatch(setSearchText(keyword))
  dispatch(setFilterProp(null))
  dispatch(resetTablePage())
  if (table.page === 1) {
    dispatch(
      fetchDataList({
        method: 'search',
        table,
        page: 1,
        status: [],
        keyword,
        filterProp: { name: keyword },
      }),
    )
  } else {
    dispatch(resetPage())
    dispatch(setStatusFilter([]))
  }
}

export const formatDateDisplay = (date) =>
  dayjs(new Date(date)).format(window.__env__.REACT_APP_DATE_SHOW)
