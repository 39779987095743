import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import Divider from '@mui/material/Divider'
import Close from '@mui/icons-material/Close'
import NormalTable from '../../../../../../components/Table/NormalTable/NormalTable'

import { StyledDrawer, StyledHeadLabel, StyledContent } from './Styled'
import { fetchExamHistory, headCells } from './events'
import { useParams } from 'react-router-dom'
import { shallowEqual, useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { LoadingPage } from '../../../../../AgentProfile/View/Content/Exam/ExamDrawer/Styled'

const ExamDrawer = ({ isOpen, onCloseDrawer, type }) => {
  const dispatch = useDispatch()
  const { id: uuid } = useParams()
  const { isLoading2 } = useSelector(
    (state) => ({
      isLoading2: state.agentProfile.isLoading2,
    }),
    shallowEqual,
  )
  const [data, setData] = useState({
    result: [],
    totalCount: 0,
  })

  useEffect(() => {
    if (isOpen) dispatch(fetchExamHistory({ uuid, setData, type }))
  }, [isOpen])

  return (
    <StyledDrawer open={isOpen} onClose={onCloseDrawer}>
      <Box
        sx={{
          opacity: isLoading2 ? 0.4 : 'unset',
          pointerEvents: isLoading2 ? 'none' : 'unset',
        }}
      >
        <StyledContent>
          <StyledHeadLabel>
            <Box id="header">
              <Typography variant="h5">ประวัติการเปลี่ยนแปลง</Typography>
              <IconButton
                data-testid="btn-close-drawer"
                color="primary"
                component="span"
                onClick={onCloseDrawer}
              >
                <Close />
              </IconButton>
            </Box>
            <Divider />
          </StyledHeadLabel>
          <Box sx={{ m: 3, gap: 4 }}>
            <NormalTable
              headCells={headCells}
              listRows={data.result}
              totalRows={data.totalCount}
              showPagination
              handlePagination={(events) =>
                dispatch(fetchExamHistory({ uuid, setData, events }))
              }
            />
          </Box>
        </StyledContent>
      </Box>
      <LoadingPage isLoading={isLoading2} />
    </StyledDrawer>
  )
}

export default ExamDrawer
