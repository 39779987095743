import React, { useState, useEffect } from 'react'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import InputBase from '@mui/material/InputBase'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import CloseIcon from '@mui/icons-material/Close'
import Divider from '@mui/material/Divider'
import Checkbox from '@mui/material/Checkbox'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import {
  StyledDrawer,
  StyledFooter,
  StyledGroupBox,
  StyledHeadLabel,
} from '../../components/FilterDrawer/Styled'

export const defaultFilter = {
  skillTypeChecked: false,
  skillTypeText: '',
  status: {
    activeChecked: false,
    inActiveChecked: false,
    deletedChecked: false,
  },
  acquiredSkill: {},
  acquiredSkillChecked: false,
  statusChecked: false,
  createdByChecked: false,
  createdBy: '',
  updatedByChecked: false,
  updatedBy: '',
}

const FilterDrawer = ({ open, onClose, onFilterClick, acquiredSkillList }) => {
  const [filter, setFilter] = useState(defaultFilter)
  const [filterMemo, setFilterMemo] = useState()
  useEffect(() => {
    if (filterMemo) {
      setFilter(filterMemo)
      return
    }

    if (!filterMemo && acquiredSkillList.length) {
      let result = {}

      acquiredSkillList.filter((item) => {
        const title = item.acquiredSkill + 'Checked'
        result = {
          ...result,
          [title]: {
            status: false,
            uuid: item.uuid,
          },
        }
      })
      setFilter({
        ...defaultFilter,
        acquiredSkill: result,
      })
    }
  }, [open])

  const handleChangeChecked = ({ checked, key }) => {
    setFilter({
      ...filter,
      [key]: checked,
    })
  }

  const handleskillTypeTextChange = (e) => {
    setFilter({
      ...filter,
      skillTypeText: e.target.value,
    })
  }
  const handleCreatedByChange = (e) => {
    setFilter({
      ...filter,
      createdBy: e.target.value,
    })
  }
  const handleUpdatedByChange = (e) => {
    setFilter({
      ...filter,
      updatedBy: e.target.value,
    })
  }

  const handleChangeStatus = (event) => {
    if (event.target.name === 'activeChecked') {
      setFilter({
        ...filter,
        status: {
          activeChecked: event.target.checked,
          inActiveChecked: filter.status.inActiveChecked,
          deletedChecked: filter.status.deletedChecked,
        },
      })
    } else if (event.target.name === 'inActiveChecked') {
      setFilter({
        ...filter,
        status: {
          activeChecked: filter.status.activeChecked,
          inActiveChecked: event.target.checked,
          deletedChecked: filter.status.deletedChecked,
        },
      })
    } else if (event.target.name === 'deletedChecked') {
      setFilter({
        ...filter,
        status: {
          activeChecked: filter.status.activeChecked,
          inActiveChecked: filter.status.inActiveChecked,
          deletedChecked: event.target.checked,
        },
      })
    }
  }

  const onClearClick = () => {
    setFilter(defaultFilter)
  }

  const handleChangeAcquiredSkill = (event) => {
    const name = event.target.name
    const checked = event.target.checked
    const acquiredSkillChecked = filter?.acquiredSkill?.[name]
    setFilter({
      ...filter,
      acquiredSkill: {
        ...filter.acquiredSkill,
        [name]: {
          ...acquiredSkillChecked,
          status: checked,
        },
      },
    })
  }

  const FormacquiredSkillList = () => {
    if (acquiredSkillList.length) {
      return acquiredSkillList.map((item, index) => {
        const title = item.acquiredSkill + 'Checked'

        const checkedStatus = filter?.acquiredSkill?.[title]?.status || false

        return (
          <FormControlLabel
            key={`key-acquiredSkill-${index}`}
            label={item.acquiredSkill}
            control={
              <Checkbox
                data-testid={`checkbox-acquiredSkill-${index}`}
                name={title}
                checked={checkedStatus}
                onChange={handleChangeAcquiredSkill}
              />
            }
          />
        )
      })
    }
  }

  return (
    <StyledDrawer open={open} onClose={onClose}>
      <StyledHeadLabel>
        <Box>
          <Typography variant="h5">ตัวกรอง</Typography>
          <IconButton
            data-testid={`btn-close-drawer`}
            color="primary"
            component="span"
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Divider />
      </StyledHeadLabel>
      <StyledGroupBox>
        <FormGroup sx={{ width: '100%' }}>
          <FormControlLabel
            control={
              <Checkbox
                data-testid="checkbox-skillType-title"
                name="skillTypeChecked"
                checked={filter.skillTypeChecked}
                onChange={(e) => {
                  handleChangeChecked({
                    checked: e.target.checked,
                    key: 'skillTypeChecked',
                  })
                }}
              />
            }
            label="ประเภทของทักษะ"
            sx={!filter.skillTypeChecked ? { marginBottom: -1 } : {}}
          />
          {filter.skillTypeChecked && (
            <InputBase
              sx={{ borderRadius: 0 }}
              type="basic"
              disabled={!filter.skillTypeChecked}
              placeholder="ค้นหาประเภทของทักษะ"
              value={filter.skillTypeText}
              onChange={handleskillTypeTextChange}
            ></InputBase>
          )}
        </FormGroup>
      </StyledGroupBox>

      <StyledGroupBox sx={{ display: 'flex', flexDirection: 'column' }}>
        <FormControlLabel
          label="ระดับหลักสูตร"
          data-testid="checkbox-acquiredSkill-status"
          control={
            <Checkbox
              name="acquiredSkillChecked"
              checked={filter.acquiredSkillChecked}
              onChange={(e) => {
                handleChangeChecked({
                  checked: e.target.checked,
                  key: 'acquiredSkillChecked',
                })
              }}
            />
          }
        />
        {filter.acquiredSkillChecked && (
          <Box sx={{ display: 'flex', flexDirection: 'column', mx: 3 }}>
            {FormacquiredSkillList()}
          </Box>
        )}
      </StyledGroupBox>

      <StyledGroupBox sx={{ display: 'flex', flexDirection: 'column' }}>
        <FormControlLabel
          label="สถานะ"
          control={
            <Checkbox
              data-testid="checkbox-skillType-status"
              name="statusChecked"
              checked={filter.statusChecked}
              onChange={(e) => {
                handleChangeChecked({
                  checked: e.target.checked,
                  key: 'statusChecked',
                })
              }}
            />
          }
        />
        {filter.statusChecked && (
          <>
            <Box sx={{ display: 'flex', flexDirection: 'column', mx: 3 }}>
              <FormControlLabel
                label="เปิดใช้งาน"
                control={
                  <Checkbox
                    data-testid="checkbox-status-active"
                    name="activeChecked"
                    checked={filter.status.activeChecked}
                    onChange={handleChangeStatus}
                  />
                }
              />
              <FormControlLabel
                label="ปิดใช้งาน"
                control={
                  <Checkbox
                    data-testid="checkbox-status-deleted"
                    name="inActiveChecked"
                    checked={filter.status.inActiveChecked}
                    onChange={handleChangeStatus}
                  />
                }
              />
              <FormControlLabel
                label="ถูกลบแล้ว"
                control={
                  <Checkbox
                    data-testid="checkbox-status-inactive"
                    name="deletedChecked"
                    checked={filter.status.deletedChecked}
                    onChange={handleChangeStatus}
                  />
                }
              />
            </Box>
          </>
        )}
      </StyledGroupBox>
      <StyledGroupBox>
        <FormGroup sx={{ width: '100%' }}>
          <FormControlLabel
            control={
              <Checkbox
                data-testid="checkbox-distribution-abbreviation"
                name="createdByChecked"
                checked={filter.createdByChecked}
                onChange={(e) => {
                  handleChangeChecked({
                    checked: e.target.checked,
                    key: 'createdByChecked',
                  })
                }}
              />
            }
            label="สร้างโดย"
            sx={!filter.createdByChecked ? { marginBottom: -1 } : {}}
          />
          {filter.createdByChecked && (
            <InputBase
              sx={{ borderRadius: 0 }}
              type="basic"
              disabled={!filter.createdByChecked}
              placeholder="ค้นหาสร้างโดย"
              value={filter.createdBy}
              onChange={handleCreatedByChange}
            ></InputBase>
          )}
        </FormGroup>
      </StyledGroupBox>
      <StyledGroupBox>
        <FormGroup sx={{ width: '100%' }}>
          <FormControlLabel
            control={
              <Checkbox
                data-testid="checkbox-distribution-updatedBy"
                name="updatedByChecked"
                checked={filter.updatedByChecked}
                onChange={(e) => {
                  handleChangeChecked({
                    checked: e.target.checked,
                    key: 'updatedByChecked',
                  })
                }}
              />
            }
            label="แก้ไขล่าสุดโดย"
            sx={!filter.updatedByChecked ? { marginBottom: -1 } : {}}
          />
          {filter.updatedByChecked && (
            <InputBase
              sx={{ borderRadius: 0 }}
              type="basic"
              disabled={!filter.updatedByChecked}
              placeholder="ค้นหาแก้ไขล่าสุดโดย"
              value={filter.updatedBy}
              onChange={handleUpdatedByChange}
            ></InputBase>
          )}
        </FormGroup>
      </StyledGroupBox>
      <StyledFooter>
        <Button variant="outlined" onClick={onClearClick}>
          ล้าง
        </Button>
        <Button
          onClick={() => {
            onClose()
            onFilterClick(filter)
            setFilterMemo(filter)
          }}
          variant="contained"
        >
          ยืนยัน
        </Button>
      </StyledFooter>
    </StyledDrawer>
  )
}
export default FilterDrawer
