import { CS_USER_PROFILE_SETTING } from '../permission/csUserProfile'
import { PROSPECT_PROFILE_SETTING } from '../permission/prospectProfile'

export const prospectProfileCells = [
  {
    id: 'tempCode',
    disablePadding: false,
    label: 'Temp Code',
    width: '140px',
  },
  {
    id: 'firstNameTH',
    label: 'ชื่อ',
  },
  {
    id: 'lastNameTH',
    label: 'นามสกุล',
  },
  {
    id: 'mobileNo',
    label: 'เบอร์โทรศัพท์',
    width: '120px',
  },
  {
    id: 'email',
    label: 'อีเมล',
  },
  {
    id: 'unitNumber',
    label: 'Unit Number',
    width: '120px',
  },
  {
    id: 'managerFullCode',
    label: 'Manager Full Code',
  },
  {
    id: 'managerFullNameTH',
    label: 'Manager Name',
  },
  {
    id: 'actionManage',
    disablePadding: false,
    label: 'คำสั่ง',
    width: '90px',
    hideDelete: true,
    hideSortIcon: true,
    viewPath: `/manage/prospect-profile/view`,
    editPath: `/manage/prospect-profile/edit`,
    module: PROSPECT_PROFILE_SETTING,
  },
]

export const prospectProfileCourse = [
  {
    id: 'courseCode',
    label: 'รหัสหลักสูตร',
  },
  {
    id: 'englishName',
    label: 'หลักสูตร',
  },
  {
    id: 'nameForLearner',
    label: 'ชื่อหลักสูตร (สำหรับ Learner)',
  },
  {
    id: 'registerDate',
    label: 'วันที่สมัคร',
  },
  {
    id: 'betweenDate',
    label: 'วันที่จัดอบรม',
  },
  {
    id: 'hours',
    label: 'จำนวนชั่วโมง',
  },
  {
    id: 'result',
    label: 'สถานะ',
  },
  {
    id: 'certificateExpiryDate',
    label: 'ประกาศนียบัตรหมดอายุ',
  },
  {
    id: 'action',
    disablePadding: false,
    hideSortIcon: true,
    label: 'คำสั่ง',
    width: '90px',
    viewPath: `/manage-class/learner/detail`,
  },
]

export const csUserProfileCells = [
  {
    id: 'tempCode',
    disablePadding: false,
    label: 'Temp Code',
    width: '140px',
  },
  {
    id: 'firstNameTH',
    label: 'ชื่อ',
  },
  {
    id: 'lastNameTH',
    label: 'นามสกุล',
  },
  {
    id: 'mobileNo',
    label: 'เบอร์โทรศัพท์',
    width: '120px',
  },
  {
    id: 'email',
    label: 'อีเมล',
  },
  {
    id: 'unitNumber',
    label: 'Broker Number',
    width: '120px',
  },
  {
    id: 'managerFullCode',
    label: 'Manager Full Code',
  },
  {
    id: 'managerFullNameTH',
    label: 'Manager Name',
  },
  {
    id: 'actionManage',
    disablePadding: false,
    label: 'คำสั่ง',
    width: '90px',
    hideDelete: true,
    hideSortIcon: true,
    viewPath: `/manage/cs-user-profile/view`,
    editPath: `/manage/cs-user-profile/edit`,
    module: CS_USER_PROFILE_SETTING,
  },
]

export const agentUpdateProfileCells = [
  {
    id: 'id',
    disablePadding: false,
    label: 'ลำดับ',
    width: '90px',
    hideSortIcon: true,
  },
  {
    id: 'statusAgentUpdateProfile',
    label: 'สถานะ',
    hideSortIcon: true,
  },
  {
    id: 'startDate',
    label: 'วันเวลาที่เริ่ม',
    hideSortIcon: true,
  },
  {
    id: 'endDate',
    label: 'วันเวลาที่สิ้นสุด',
    hideSortIcon: true,
  },
  {
    id: 'total',
    label: 'ทั้งหมด',
    hideSortIcon: true,
    width: '120px',
  },
  {
    id: 'success',
    label: 'สำเร็จ',
    hideSortIcon: true,
    width: '120px',
  },
  {
    id: 'fail',
    label: 'ไม่สำเร็จ',
    hideSortIcon: true,
    width: '120px',
  },
  {
    id: 'actionManage',
    disablePadding: false,
    label: 'คำสั่ง',
    width: '90px',
    hideDelete: true,
    viewPath: `/manage/agent-update-profile`,
    hideSortIcon: true,
    hideEdit: true,
  },
]

export const agentUpdateProfileUuidCells = [
  {
    id: 'id',
    disablePadding: false,
    label: 'ลำดับ',
    width: '140px',
    hideSortIcon: true,
  },
  {
    id: 'agentCode',
    label: 'Agent Code',
    hideSortIcon: true,
  },
  {
    id: 'fullName',
    label: 'ชื่อ-นามสกุล',
    hideSortIcon: true,
  },
  {
    id: 'actionManageUpdateProfile',
    disablePadding: false,
    label: '',
    width: '90px',
    hideDelete: true,
    viewPath: `/manage/agent-update-profile`,
    hideSortIcon: true,
    hideEdit: true,
  },
]
