import React, { useEffect, useState, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import AddIcon from '@mui/icons-material/Add'
import useTheme from '@mui/system/useTheme'
import { DialogContext } from '../../context/dialog-context'
import Table from '../../components/Table'
import FilterDrawer from './FilterDrawer'
import Breadcrumbs from '../../components/Breadcrumbs'
import {
  filterJobTitle,
  deleteJobTitle,
  downloadJobTitle,
} from '../../utils/apiPath'
import { callAPI, handleDownload } from '../../utils/lib'
import _ from 'lodash'
import { handleFilterTotal } from '../../redux/slices/table/events'
import { OrderButton } from '../RoadMap/RoadMapList'
import TableOrder from '../RoadMap/TableOrder'

export const headCells = [
  {
    id: 'status',
    label: '',
    width: '20px',
  },
  {
    id: 'sequence',
    label: 'ลำดับ',
    disablePadding: false,
    width: '80px',
  },
  {
    id: 'jobTitle',
    label: 'Job Title',
  },
  {
    id: 'level',
    label: 'Level',
  },
  {
    id: 'action',
    disablePadding: false,
    label: 'คำสั่ง',
    width: '90px',
    hideSortIcon: true,
  },
]
export const headCellsOrder = [
  {
    id: 'sort',
    label: 'Sort',
    width: '80px',
  },
  {
    id: 'status',
    label: '',
    width: '20px',
  },
  {
    id: 'order',
    disablePadding: false,
    label: 'ลำดับ',
    width: '80px',
  },
  {
    id: 'jobTitle',
    label: 'Job Title',
    width: '380px',
  },
  {
    id: 'level',
    label: 'Level',
    width: '220px',
  },
]

export const breadcrumbList = [
  { title: 'Master Data', link: '/', pointer: false },
  { title: 'Job Title', link: '/', pointer: false },
]

export const onView = (row, history) => {
  history.push('/master-data/job-title/' + row.uuid)
}
export const onEdit = (row, history) => {
  history.push('/master-data/job-title/edit/' + row.uuid)
}

export const onDelete = async ({
  row,
  setDialog,
  setIsLoading,
  handleDelete,
}) => {
  setIsLoading(false)
  setDialog({
    open: true,
    content: 'คุณต้องการลบรายการนี้หรือไม่',
    variant: 'delete',
    onConfirmClick: () => handleDelete(row),
    onCancelClick: () =>
      setDialog({
        variant: 'delete',
        content: 'คุณต้องการลบรายการนี้หรือไม่',
        open: false,
      }),
  })
}

export const mainHandleDelete = async (row, setIsLoading, setDialog) => {
  const path = deleteJobTitle + `/${row.uuid}`
  setIsLoading && setIsLoading(true)
  callAPI({
    url: path,
    method: 'delete',
    onSuccess: () => {
      setDialog({
        variant: 'success',
        content: 'ถูกลบแล้ว',
        open: true,
        isLoading: false,
        onConfirmClick: () => window.location.reload(),
      })
    },
    onFinally: () => {
      setIsLoading && setIsLoading(false)
    },
  })
}

export const onFilterClick = async ({
  limit,
  order,
  sort,
  filter,
  setSearchText,
  setText,
  setStatus,
  setTablePage,
  fetchJobTitleList,
  setFilterTotal,
}) => {
  setSearchText('')
  setText('')
  const statusList = []
  if (filter.statusChecked) {
    if (filter.status.activeChecked) {
      statusList.push('ACTIVE')
    }
    if (filter.status.deletedChecked) {
      statusList.push('DELETED')
    }
  }
  setStatus(statusList)
  const jobTitleText = filter?.jobTitleChecked ? filter.jobTitleText : ''
  const levelText = filter?.levelChecked ? filter.levelText : ''
  const createdBy = filter?.createdByChecked ? filter.createdBy : ''
  const updatedBy = filter?.updatedByChecked ? filter.updatedBy : ''

  setSearchText(jobTitleText)
  setTablePage(0)
  fetchJobTitleList({
    method: 'filter',
    statusList,
    valueLimit: limit,
    valueOrder: order,
    valuePage: 1,
    valueSort: sort,
    search: jobTitleText,
    levelText: levelText,
    createdBy,
    updatedBy,
  })
  setFilterTotal(handleFilterTotal(filter))
}

export const handleAddClick = (history) => {
  history.push('/master-data/job-title/form')
}

export const handleQuickSearch = async ({
  limit,
  order,
  sort,
  valueText,
  setTablePage,
  fetchJobTitleList,
}) => {
  setTablePage(0)
  const statusList = ['ACTIVE', 'DELETED']
  fetchJobTitleList({
    method: 'search',
    statusList,
    valueLimit: limit,
    valueOrder: order,
    valuePage: 1,
    valueSort: sort,
    search: valueText,
    levelText: '',
  })
}

export const onDownload = async (selected, order, sort, setIsLoading) => {
  setIsLoading(true)
  const body = {
    jobTitle: '',
    level: '',
    order: order.toUpperCase(),
    sort: sort === '' ? 'sequence' : sort,
    type: 'ALL',
    status: [],
    list: selected,
    createdBy: '',
    updatedBy: '',
  }
  await handleDownload({
    url: downloadJobTitle,
    body: body,
    fileName: `รายการJob-Title.xlsx`,
  })
  setIsLoading(false)
}

const JobTitleList = () => {
  const theme = useTheme()
  const history = useHistory()
  const [isLoading, setIsLoading] = useState(false)
  const [isPageLoading, setIsPageLoading] = useState(false)
  const [limit, setLimit] = useState('100')
  const [order, setOrder] = useState('ASC')
  const [page, setPage] = useState(-1)
  const [sort, setSort] = useState('')
  const [jobTitleList, setJobTitleList] = useState([])
  const [allCount, setAllCount] = useState(0)
  const [searchText, setSearchText] = useState('')
  const [text, setText] = useState('')
  const [status, setStatus] = useState(['ACTIVE', 'DELETED'])
  const [isFilterDrawer, setIsFilterDrawer] = useState(false)
  const [tablePage, setTablePage] = useState(0)
  const [filterTotal, setFilterTotal] = useState(0)
  const [isOrderMode, setIsOrderMode] = useState(false)
  const [orderList, setOrderList] = useState([])

  const { setContext: setDialog } = useContext(DialogContext)
  useEffect(() => {
    setIsPageLoading(true)
    fetchJobTitleList({
      method: 'fetch',
      statusList: status,
      valueLimit: limit,
      valueOrder: order,
      valuePage: page,
      valueSort: sort,
      levelText: '',
      isUnlimit: false,
    })

    fetchJobTitleList({
      method: 'fetch',
      statusList: status,
      valueLimit: limit,
      valueOrder: order,
      valuePage: page,
      valueSort: sort,
      levelText: '',
      isUnlimit: true,
    })
  }, [limit, order, sort, page])

  const fetchJobTitleList = async ({
    method,
    statusList,
    valueLimit,
    valueOrder,
    valuePage,
    valueSort,
    search,
    levelText,
    isUnlimit,
    createdBy,
    updatedBy,
  }) => {
    const sortBy = valueSort === '' ? 'sequence' : valueSort
    const realPage = valuePage <= 0 ? 1 : +valuePage
    setIsPageLoading(true)

    const body = {
      jobTitle: method !== 'fetch' ? search : searchText,
      limit: isUnlimit ? -1 : valueLimit,
      order: valueOrder.toString().toUpperCase(),
      page: realPage,
      sort: sortBy,
      level: method !== 'fetch' ? levelText : '',
      status: statusList,
      createdBy: method === 'filter' ? createdBy : '',
      updatedBy: method === 'filter' ? updatedBy : '',
    }
    callAPI({
      url: filterJobTitle,
      method: 'POST',
      body: body,
      onSuccess: (res) => {
        const result = _.get(res, 'result', []).map((item) => {
          return {
            ...item,
            order: _.get(item, 'sequence', 1),
            level: _.get(item, 'level.level', ''),
          }
        })
        if (isUnlimit) {
          setOrderList(_.orderBy(result, ['sequence'], ['asc']))
        } else {
          setJobTitleList(result)
          setAllCount(res.totalCount)
        }
      },
      onFinally: () => {
        setIsPageLoading(false)
      },
    })
  }

  return (
    <Box sx={{ pb: 2 }}>
      <Box sx={isPageLoading ? { opacity: 0.4, pointerEvents: 'none' } : {}}>
        <Box
          sx={{
            mx: 3,
            justifyContent: 'space-between',
            display: 'flex',
          }}
        >
          <Typography variant="h4">Job Title</Typography>
        </Box>
        <Breadcrumbs sx={{ mx: 3, mb: 3 }} menuList={breadcrumbList} />

        <Button
          data-testid={`btn-add-job-title`}
          sx={{
            ml: 2,
            [theme.breakpoints.up('md')]: {
              position: 'absolute',
              mt: -10,
              mr: 3,
            },
            right: 0,
          }}
          startIcon={<AddIcon />}
          variant="contained"
          onClick={() => handleAddClick(history)}
        >
          เพิ่ม Job Title
        </Button>
        <Card sx={{ minWidth: 275, mx: 3, mt: 3 }}>
          {!isOrderMode && (
            <Table
              data={jobTitleList}
              headCells={headCells}
              onView={(row) => onView(row, history)}
              onEdit={(row) => onEdit(row, history)}
              onDelete={(row) => {
                onDelete({
                  row,
                  setIsLoading,
                  setDialog,
                  handleDelete: () =>
                    mainHandleDelete(row, setIsLoading, setDialog),
                })
              }}
              placeholder="ค้นหา Job Title"
              onDownload={(selected) =>
                onDownload(selected, order, sort, setIsLoading)
              }
              searchKey={'jobTitle'}
              setLimit={setLimit}
              order={order}
              setOrder={setOrder}
              page={page}
              setPage={setPage}
              sort={sort}
              setSort={setSort}
              allCount={allCount}
              handleSearch={(valueText) =>
                handleQuickSearch({
                  limit,
                  order,
                  sort,
                  valueText,
                  setTablePage,
                  fetchJobTitleList,
                })
              }
              setIsFilterDrawer={setIsFilterDrawer}
              searchText={searchText}
              setSearchText={setSearchText}
              tablePage={tablePage}
              setTablePage={setTablePage}
              text={text}
              setText={setText}
              customToolbar={<OrderButton setIsOrderMode={setIsOrderMode} />}
              filterTotal={filterTotal}
            />
          )}
          {isOrderMode && (
            <TableOrder
              onCancel={() => {
                setIsOrderMode(false)
                setOrderList(jobTitleList)
                fetchJobTitleList({
                  method: 'fetch',
                  statusList: status,
                  valueLimit: limit,
                  valueOrder: order,
                  valuePage: page,
                  valueSort: sort,
                  levelText: '',
                  isUnlimit: false,
                })
              }}
              headCellsOrder={headCellsOrder}
              orderList={orderList}
              setOrderList={setOrderList}
              setIsOrderMode={setIsOrderMode}
              setDialog={setDialog}
              type="jobTitle"
            />
          )}
        </Card>
      </Box>
      <CircularProgress
        disableShrink
        sx={{
          position: 'absolute',
          display: isPageLoading && isLoading ? 'unset' : 'none',
          top: '50%',
          left: '50%',
          color: 'primary.main',
        }}
      />
      <FilterDrawer
        open={isFilterDrawer}
        onClose={() => setIsFilterDrawer(false)}
        onFilterClick={(filter) =>
          onFilterClick({
            limit,
            order,
            sort,
            filter,
            setSearchText,
            setText,
            setStatus,
            setTablePage,
            fetchJobTitleList,
            setFilterTotal,
          })
        }
      />
    </Box>
  )
}
export default JobTitleList
