import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card'
import styled from '@mui/system/styled'
export const BoxForm = styled(Box)(({ theme, isGap, isBottom }) => ({
  margin: theme.spacing(3),
  display: 'flex',
  gap: isGap ? theme.spacing(0.5) : theme.spacing(2),
  flexDirection: 'column',
  marginBottom: isBottom ? theme.spacing(1) : theme.spacing(3),
}))

export const BoxFlex = styled(Box)(
  ({ theme, isSwitch, paddingLeft, isSelect, isIcon }) => ({
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    gap: theme.spacing(3),
    justifyContent: isSwitch ? 'space-between' : 'unset',
    paddingLeft: theme.spacing(paddingLeft),
    alignItems: isSelect || isIcon ? 'center' : 'none',
    [theme.breakpoints.down('sm')]: {
      display: isSwitch ? 'display' : 'block',
      flexDirection: isSelect ? 'column' : 'unset',
      gap: theme.spacing(2),
      alignItems: isSelect ? 'flex-start' : 'none',
    },
  }),
)

export const TypographyLabel = styled(Typography)(({ theme }) => ({
  color: theme?.palette?.text?.lightGray,
}))

export const BoxCard = styled(Card)(() => ({}))
