export const colorType = {
  ALL: {
    border: 'solid 1px #99C2FF',
    iconColor: '#99C2FF',
    titleDotColor: '#99C2FF',
  },
  AGENCY: {
    border: 'solid 1px #B1DFD2',
    iconColor: '#B1DFD2',
    titleDotColor: '#32A287',
    colorList: '#32A287',
    backgroundList: '#B1DFD2',
    nameColor: '#000',
    numberColor: '#FFFFFF',
  },
  BANCA: {
    border: 'solid 1px #FFA944',
    iconColor: '#FFCD92',
    titleDotColor: '#FFE1BE',
    colorList: '#FFE66D',
    backgroundList: '#FFF4C0',
    nameColor: '#000',
    numberColor: '#999999',
  },
  KTB: {
    border: 'solid 1px #FF89BE',
    iconColor: '#FFC6E0',
    titleDotColor: '#FF89BE',
    colorList: '#FF89BE',
    backgroundList: '#FFC6E0',
    nameColor: '#000',
    numberColor: '#FFFFFF',
  },
  OTHER: {
    border: 'solid 1px #B49CE4',
    iconColor: '#B49CE4',
    titleDotColor: '#CBBAED',
    colorList: '#B49CE4',
    backgroundList: '#E9DEFF',
    nameColor: '#000',
    numberColor: '#FFFFFF',
  },
}
