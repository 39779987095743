export const headCellsInventory = () => [
  {
    id: 'index',
    label: 'ลำดับ',
    disablePadding: false,
    hideSortIcon: true,
  },
  {
    id: 'productName',
    label: 'ชื่อทรัพย์สิน',
    disablePadding: false,
  },
  {
    id: 'category.name',
    label: 'หมวดหมู่',
    disablePadding: false,
  },
  {
    id: 'subCategory.name',
    label: 'หมวดหมู่ย่อย',
    disablePadding: false,
  },
  {
    id: 'total',
    label: 'คงเหลือ',
    disablePadding: false,
  },
  {
    id: 'available',
    label: 'พร้อมใช้งาน',
    disablePadding: false,
  },
  {
    id: 'stockValue',
    label: 'มูลค่ารวม (฿)',
    disablePadding: false,
  },
  {
    id: 'action',
    disablePadding: false,
    label: '',
    width: '90px',
    hideSortIcon: true,
    viewPath: `/inventory/product/stock/view`,
    hideDelete: true,
    hideEdit: true,
  },
]

export const headCellsRanking = () => [
  {
    id: 'index',
    label: 'ลำดับ',
    disablePadding: false,
    hideSortIcon: true,
    width: '10px',
    minWidth: '10px',
  },
  {
    id: 'productName',
    label: 'ชื่อทรัพย์สิน',
    disablePadding: false,
  },
  {
    id: 'quantity',
    label: 'จำนวน (ชิ้น)',
    disablePadding: false,
    width: '40px',
  },
  {
    id: 'quantityPrice',
    label: 'มูลค่า (บาท)',
    disablePadding: false,
    width: '40px',
  },
  {
    id: 'action',
    disablePadding: false,
    label: '',
    width: '5px',
    minWidth: '5px',
    hideSortIcon: true,
    viewPath: `/inventory/product/stock/view`,
    hideDelete: true,
    hideEdit: true,
  },
  {
    id: 'dropdownTable',
    disablePadding: false,
    label: '',
    width: '5px',
    minWidth: '5px',
    hideSortIcon: true,
  },
]

export const headCellsRankingStock = () => [
  {
    id: 'index',
    label: 'ลำดับ',
    disablePadding: false,
    hideSortIcon: true,
    width: '10px',
    minWidth: '10px',
  },
  {
    id: 'productName',
    label: 'ชื่อทรัพย์สิน',
    disablePadding: false,
  },
  {
    id: 'inventory',
    label: 'คลังทรัพย์สิน',
    disablePadding: false,
    width: '140px',
  },
  {
    id: 'quantity',
    label: 'จำนวน (ชิ้น)',
    disablePadding: false,
    width: '40px',
  },

  {
    id: 'actionInventoryRanking',
    disablePadding: false,
    label: '',
    width: '5px',
    minWidth: '5px',
    hideSortIcon: true,
    viewPath: `/inventory/product/stock/view`,
    hideDelete: true,
    hideEdit: true,
    type: 'stock',
  },
]

export const headCellsRankingName = () => [
  {
    id: 'index',
    label: 'ลำดับ',
    disablePadding: false,
    hideSortIcon: true,
    width: '10px',
    minWidth: '10px',
  },
  {
    id: 'name',
    label: 'ชื่อ',
    disablePadding: false,
  },
  {
    id: 'ticket',
    label: 'จำนวน Ticket',
    disablePadding: false,
    width: '40px',
  },
  {
    id: 'actionInventoryRanking',
    disablePadding: false,
    label: '',
    width: '5px',
    minWidth: '5px',
    hideSortIcon: true,
    viewPath: `/inventory/requisition`,
    hideDelete: true,
    hideEdit: true,
    type: 'withdraw',
  },
]

export const headCellsRequisition = () => {
  return [
    {
      id: 'index',
      label: 'ลำดับ',
      disablePadding: false,
      width: '10px',
      minWidth: '10px',
    },
    {
      id: 'id',
      label: 'Ticket ID',
      disablePadding: false,
      minWidth: 'fit-content',
    },
    {
      id: 'ticketType',
      label: 'ประเภท Ticket',
      disablePadding: false,
      minWidth: 'fit-content',
    },
    {
      id: 'trainingPlanCourse',
      label: 'ชื่อหลักสูตร',
      disablePadding: false,
      minWidth: '50px',
    },
    {
      id: 'trainingPlanDate',
      label: 'วันที่อบรม',
      disablePadding: false,
    },
    {
      id: 'createdBy',
      label: 'สร้างโดย',
      disablePadding: false,
      minWidth: '50px',
    },
    {
      id: 'createdAt',
      label: 'วันที่สร้าง',
      disablePadding: false,
    },
    {
      id: 'requisitionStatus',
      label: 'สถานะ',
      disablePadding: false,
    },
    {
      id: 'returnDate',
      label: 'กำหนดคืน',
      disablePadding: false,
    },
    {
      id: 'action',
      disablePadding: false,
      label: '',
      minWidth: '10px',
      hideSortIcon: true,
      hideDelete: true,
      hideEdit: true,
      viewPath: `/inventory/requisition/view`,
    },
  ]
}
