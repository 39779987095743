import { arrayStatus } from '../../model'

export const arrayType = [
  { label: 'หลักสูตรทั่วไป', name: 'generalChecked' },
  { label: 'หลักสูตรระดับสูง', name: 'oicChecked' },
]

export const arrayQuestion = [
  { label: 'รอตอบ', name: 'waitingChecked' },
  { label: 'ตอบแล้ว', name: 'answeredChecked' },
  { label: 'ไม่มีคำถาม', name: 'noAnswerChecked' },
]

export const arrayCheckbox = [
  {
    label: 'รหัสหลักสูตร',
    nameCheckBox: 'courseCodeChecked',
    nameInput: 'courseCode',
    placeholder: 'ค้นหารหัสหลักสูตร',
    type: 'text',
  },
  {
    label: 'ชื่อหลักสูตร',
    nameCheckBox: 'nameChecked',
    nameInput: 'name',
    placeholder: 'ค้นหาชื่อหลักสูตร',
    type: 'text',
  },
  {
    label: 'ชื่อหลักสูตร (สำหรับ Learner)',
    nameCheckBox: 'nameForLearnerChecked',
    nameInput: 'nameForLearner',
    placeholder: 'ค้นหาชื่อหลักสูตร (สำหรับ Learner)',
    type: 'text',
  },
  {
    label: 'ประเภท',
    nameCheckBox: 'courseTypeChecked',
    nameInput: 'courseType',
    type: 'checkbox',
    list: arrayType,
  },
  {
    label: 'คำถาม',
    nameCheckBox: 'questionChecked',
    nameInput: 'question',
    type: 'checkbox',
    list: arrayQuestion,
  },
  {
    label: 'สถานะ',
    nameCheckBox: 'statusChecked',
    nameInput: 'status',
    type: 'checkbox',
    list: arrayStatus,
  },
  {
    label: 'สร้างโดย',
    nameCheckBox: 'createdByChecked',
    nameInput: 'createdBy',
    placeholder: 'ค้นหาสร้างโดย',
    type: 'text',
  },
  {
    label: 'แก้ไขล่าสุดโดย',
    nameCheckBox: 'updatedByChecked',
    nameInput: 'updatedBy',
    placeholder: 'ค้นหาแก้ไขล่าสุดโดย',
    type: 'text',
  },
]

export const defaultFilter = {
  courseCodeChecked: false,
  courseCode: '',
  nameChecked: false,
  name: '',
  nameForLearnerChecked: false,
  nameForLearner: '',
  courseTypeChecked: false,
  courseType: {
    generalChecked: false,
    oicChecked: false,
  },
  questionChecked: false,
  question: {
    waitingChecked: false,
    answeredChecked: false,
    noAnswerChecked: false,
  },
  statusChecked: false,
  status: {
    activeChecked: false,
    inActiveChecked: false,
    deletedChecked: false,
  },
  createdByChecked: false,
  createdBy: '',
  updatedByChecked: false,
  updatedBy: '',
}
