import { fetchOptionsReportByKey } from '../../components/Report/handler/fetchOptionsReportByKey'

export const reportProspectProfileConst = {
  COLUMN_NAME: {
    NO: 'no',
    USER_ID: 'userId',
    TEMP_CODE: 'tempCode',
    PREFIX: 'prefix',
    FIRST_NAME: 'firstName',
    LAST_NAME: 'lastName',
    PREFIX_TH: 'prefixTH',
    FIRST_NAME_TH: 'firstNameTH',
    LAST_NAME_TH: 'lastNameTH',
    UNIT_NUMBER: 'unitNumber',
    UNIT_NAME: 'unitName',
    STATUS_PROSPECT: 'statusProspect',
    STATUS: 'status',
    ACCESS: 'access',
    ACCESS_DATE: 'accessDate',
    MOBILE_NO: 'mobileNo',
    EMAIL: 'email',
    DATE_OF_BIRTH: 'dateOfBirth',
    ID_CARD_NO: 'idCardNo',
    GENDER: 'gender',
    MANAGER_AGENT_CODE: 'managerAgentCode',
    MANAGER_NAME: 'managerName',
    MANAGER_RAM: 'managerRam',
    MANAGER_SRAM: 'managerSram',
    LAST_LOGIN: 'lastLogin',
    DEVICE: 'device',
    //filter column
    PROSPECT_TYPE: 'prospectType',
  },
  COLUMN_NAME_TH: {
    NO: 'ลำดับ',
    USER_ID: 'User ID',
    TEMP_CODE: 'Temp Code',
    PREFIX: 'Title Name EN',
    FIRST_NAME: 'First Name EN',
    LAST_NAME: 'Last Name EN',
    PREFIX_TH: 'Title Name TH',
    FIRST_NAME_TH: 'First Name TH',
    LAST_NAME_TH: 'Last Name TH',
    UNIT_NUMBER: 'Unit Number',
    UNIT_NAME: 'ชื่อ Unit',
    STATUS_PROSPECT: 'สถานะตัวแทน',
    STATUS: 'สถานะบัญชี',
    ACCESS: 'การให้เข้าถึงข้อมูล',
    ACCESS_DATE: 'วันที่การให้เข้าถึงข้อมูล',
    MOBILE_NO: 'เบอร์โทรศัพท์',
    EMAIL: 'อีเมล',
    DATE_OF_BIRTH: 'วัน/เดือน/ปี เกิด',
    ID_CARD_NO: 'เลขบัตรประชาชน',
    GENDER: 'เพศ',
    MANAGER_AGENT_CODE: 'Manager Agent Code',
    MANAGER_NAME: 'Manager Name',
    MANAGER_RAM: 'RAM',
    MANAGER_SRAM: 'SRAM',
    LAST_LOGIN: 'เข้าสู่ระบบล่าสุด',
    DEVICE: 'Device Login',
    //filter column
    PROSPECT_TYPE: 'ประเภท',
  },
  TYPE_OF_VALUE: {
    DATE_RANGE: 'DATE_RANGE',
    INPUT_NUMBER: 'INPUT_NUMBER',
    INPUT_TEXT: 'INPUT_TEXT',
    DROPDOWN_MULTI: 'DROPDOWN_MULTI',
    DROPDOWN_SINGLE: 'DROPDOWN_SINGLE',
  },
  statusProspectOptions: [
    { label: 'เป็นตัวแทนแล้ว', value: 'เป็นตัวแทนแล้ว' },
    { label: 'ยังไม่เป็นตัวแทน', value: 'ยังไม่เป็นตัวแทน' },
  ],
  statusOptions: [
    { label: 'ACTIVE', value: 'ACTIVE' },
    { label: 'INACTIVE', value: 'INACTIVE' },
  ],
  accessOptions: [
    { label: 'ยินยอม', value: 'ยินยอม' },
    { label: 'รอการยินยอม', value: 'รอการยินยอม' },
  ],
  prospectTypeOptions: [
    { label: 'Prospect', value: 'Prospect' },
    { label: 'CS', value: 'CS' },
  ],
  ramNameOptions: [
    { label: 'Broker Channel', value: 'Broker Channel' },
    { label: 'Krisana Arjtharit', value: 'Krisana Arjtharit' },
    { label: 'Krisanapong Saramoon', value: 'Krisanapong Saramoon' },
    { label: 'Adul Srivaree', value: 'Adul Srivaree' },
    { label: 'Sutuch Sompong', value: 'Sutuch Sompong' },
    { label: 'Siraphob Bearkra', value: 'Siraphob Bearkra' },
    { label: 'Pattarapon Inchan', value: 'Pattarapon Inchan' },
    { label: 'Pachara Tavakup', value: 'Pachara Tavakup' },
    { label: 'Ruttanin Siradechasawas', value: 'Ruttanin Siradechasawas' },
    { label: 'Sathaporn Rangsiropas', value: 'Sathaporn Rangsiropas' },
    { label: 'Atipong Apiromyanont', value: 'Atipong Apiromyanont' },
    { label: 'Direct Agency', value: 'Direct Agency' },
    { label: 'KP', value: 'KP' },
    { label: 'PB', value: 'PB' },
    { label: 'AB', value: 'AB' },
    { label: 'WE', value: 'WE' },
    { label: 'CE', value: 'CE' },
    { label: 'PS', value: 'PS' },
    { label: 'TP', value: 'TP' },
    { label: 'EC', value: 'EC' },
  ],
}

export const reportProspectProfileDefaultColumn = {
  defaultColumns: [
    {
      id: 1,
      name: reportProspectProfileConst.COLUMN_NAME.NO,
      value: reportProspectProfileConst.COLUMN_NAME.NO,
      label: reportProspectProfileConst.COLUMN_NAME_TH.NO,
      minWidth: 60,
      align: 'center',
    },
    {
      id: 2,
      name: reportProspectProfileConst.COLUMN_NAME.USER_ID,
      value: reportProspectProfileConst.COLUMN_NAME.USER_ID,
      label: reportProspectProfileConst.COLUMN_NAME_TH.USER_ID,
      minWidth: 160,
    },
    {
      id: 3,
      name: reportProspectProfileConst.COLUMN_NAME.TEMP_CODE,
      value: reportProspectProfileConst.COLUMN_NAME.TEMP_CODE,
      label: reportProspectProfileConst.COLUMN_NAME_TH.TEMP_CODE,
      minWidth: 160,
    },
    {
      id: 4,
      name: reportProspectProfileConst.COLUMN_NAME.PREFIX,
      value: reportProspectProfileConst.COLUMN_NAME.PREFIX,
      label: reportProspectProfileConst.COLUMN_NAME_TH.PREFIX,
      minWidth: 160,
    },
    {
      id: 5,
      name: reportProspectProfileConst.COLUMN_NAME.FIRST_NAME,
      value: reportProspectProfileConst.COLUMN_NAME.FIRST_NAME,
      label: reportProspectProfileConst.COLUMN_NAME_TH.FIRST_NAME,
      minWidth: 160,
    },
    {
      id: 6,
      name: reportProspectProfileConst.COLUMN_NAME.LAST_NAME,
      value: reportProspectProfileConst.COLUMN_NAME.LAST_NAME,
      label: reportProspectProfileConst.COLUMN_NAME_TH.LAST_NAME,
      minWidth: 160,
    },
    {
      id: 7,
      name: reportProspectProfileConst.COLUMN_NAME.PREFIX_TH,
      value: reportProspectProfileConst.COLUMN_NAME.PREFIX_TH,
      label: reportProspectProfileConst.COLUMN_NAME_TH.PREFIX_TH,
      minWidth: 160,
    },
    {
      id: 8,
      name: reportProspectProfileConst.COLUMN_NAME.FIRST_NAME_TH,
      value: reportProspectProfileConst.COLUMN_NAME.FIRST_NAME_TH,
      label: reportProspectProfileConst.COLUMN_NAME_TH.FIRST_NAME_TH,
      minWidth: 160,
    },
    {
      id: 9,
      name: reportProspectProfileConst.COLUMN_NAME.LAST_NAME_TH,
      value: reportProspectProfileConst.COLUMN_NAME.LAST_NAME_TH,
      label: reportProspectProfileConst.COLUMN_NAME_TH.LAST_NAME_TH,
      minWidth: 160,
    },
    {
      id: 10,
      name: reportProspectProfileConst.COLUMN_NAME.UNIT_NUMBER,
      value: reportProspectProfileConst.COLUMN_NAME.UNIT_NUMBER,
      label: reportProspectProfileConst.COLUMN_NAME_TH.UNIT_NUMBER,
      minWidth: 160,
    },
    {
      id: 11,
      name: reportProspectProfileConst.COLUMN_NAME.UNIT_NAME,
      value: reportProspectProfileConst.COLUMN_NAME.UNIT_NAME,
      label: reportProspectProfileConst.COLUMN_NAME_TH.UNIT_NAME,
      minWidth: 160,
    },
    {
      id: 12,
      name: reportProspectProfileConst.COLUMN_NAME.STATUS_PROSPECT,
      value: reportProspectProfileConst.COLUMN_NAME.STATUS_PROSPECT,
      label: reportProspectProfileConst.COLUMN_NAME_TH.STATUS_PROSPECT,
      minWidth: 160,
    },
    {
      id: 13,
      name: reportProspectProfileConst.COLUMN_NAME.STATUS,
      value: reportProspectProfileConst.COLUMN_NAME.STATUS,
      label: reportProspectProfileConst.COLUMN_NAME_TH.STATUS,
      minWidth: 160,
    },
    {
      id: 14,
      name: reportProspectProfileConst.COLUMN_NAME.ACCESS,
      value: reportProspectProfileConst.COLUMN_NAME.ACCESS,
      label: reportProspectProfileConst.COLUMN_NAME_TH.ACCESS,
      minWidth: 160,
    },
    {
      id: 15,
      name: reportProspectProfileConst.COLUMN_NAME.MOBILE_NO,
      value: reportProspectProfileConst.COLUMN_NAME.MOBILE_NO,
      label: reportProspectProfileConst.COLUMN_NAME_TH.MOBILE_NO,
      minWidth: 160,
    },
    {
      id: 16,
      name: reportProspectProfileConst.COLUMN_NAME.EMAIL,
      value: reportProspectProfileConst.COLUMN_NAME.EMAIL,
      label: reportProspectProfileConst.COLUMN_NAME_TH.EMAIL,
      minWidth: 160,
    },
    {
      id: 17,
      name: reportProspectProfileConst.COLUMN_NAME.DATE_OF_BIRTH,
      value: reportProspectProfileConst.COLUMN_NAME.DATE_OF_BIRTH,
      label: reportProspectProfileConst.COLUMN_NAME_TH.DATE_OF_BIRTH,
      minWidth: 160,
    },
    {
      id: 18,
      name: reportProspectProfileConst.COLUMN_NAME.ID_CARD_NO,
      value: reportProspectProfileConst.COLUMN_NAME.ID_CARD_NO,
      label: reportProspectProfileConst.COLUMN_NAME_TH.ID_CARD_NO,
      minWidth: 160,
    },
    {
      id: 19,
      name: reportProspectProfileConst.COLUMN_NAME.GENDER,
      value: reportProspectProfileConst.COLUMN_NAME.GENDER,
      label: reportProspectProfileConst.COLUMN_NAME_TH.GENDER,
      minWidth: 160,
    },
    {
      id: 20,
      name: reportProspectProfileConst.COLUMN_NAME.MANAGER_AGENT_CODE,
      value: reportProspectProfileConst.COLUMN_NAME.MANAGER_AGENT_CODE,
      label: reportProspectProfileConst.COLUMN_NAME_TH.MANAGER_AGENT_CODE,
      minWidth: 160,
    },
    {
      id: 21,
      name: reportProspectProfileConst.COLUMN_NAME.MANAGER_NAME,
      value: reportProspectProfileConst.COLUMN_NAME.MANAGER_NAME,
      label: reportProspectProfileConst.COLUMN_NAME_TH.MANAGER_NAME,
      minWidth: 160,
    },
    {
      id: 22,
      name: reportProspectProfileConst.COLUMN_NAME.MANAGER_RAM,
      value: reportProspectProfileConst.COLUMN_NAME.MANAGER_RAM,
      label: reportProspectProfileConst.COLUMN_NAME_TH.MANAGER_RAM,
      minWidth: 160,
    },
    {
      id: 23,
      name: reportProspectProfileConst.COLUMN_NAME.MANAGER_SRAM,
      value: reportProspectProfileConst.COLUMN_NAME.MANAGER_SRAM,
      label: reportProspectProfileConst.COLUMN_NAME_TH.MANAGER_SRAM,
      minWidth: 160,
    },
    {
      id: 24,
      name: reportProspectProfileConst.COLUMN_NAME.LAST_LOGIN,
      value: reportProspectProfileConst.COLUMN_NAME.LAST_LOGIN,
      label: reportProspectProfileConst.COLUMN_NAME_TH.LAST_LOGIN,
      minWidth: 160,
    },
    {
      id: 25,
      name: reportProspectProfileConst.COLUMN_NAME.DEVICE,
      value: reportProspectProfileConst.COLUMN_NAME.DEVICE,
      label: reportProspectProfileConst.COLUMN_NAME_TH.DEVICE,
      minWidth: 160,
    },
  ],
  filterColumnOptions: [
    {
      id: 1,
      name: reportProspectProfileConst.COLUMN_NAME.TEMP_CODE,
      value: reportProspectProfileConst.COLUMN_NAME.TEMP_CODE,
      label: reportProspectProfileConst.COLUMN_NAME_TH.TEMP_CODE,
      minWidth: 160,
      type: reportProspectProfileConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 2,
      name: reportProspectProfileConst.COLUMN_NAME.PREFIX,
      value: reportProspectProfileConst.COLUMN_NAME.PREFIX,
      label: reportProspectProfileConst.COLUMN_NAME_TH.PREFIX,
      minWidth: 160,
      type: reportProspectProfileConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 3,
      name: reportProspectProfileConst.COLUMN_NAME.FIRST_NAME,
      value: reportProspectProfileConst.COLUMN_NAME.FIRST_NAME,
      label: reportProspectProfileConst.COLUMN_NAME_TH.FIRST_NAME,
      minWidth: 160,
      type: reportProspectProfileConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 4,
      name: reportProspectProfileConst.COLUMN_NAME.LAST_NAME,
      value: reportProspectProfileConst.COLUMN_NAME.LAST_NAME,
      label: reportProspectProfileConst.COLUMN_NAME_TH.LAST_NAME,
      minWidth: 160,
      type: reportProspectProfileConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 5,
      name: reportProspectProfileConst.COLUMN_NAME.PREFIX_TH,
      value: reportProspectProfileConst.COLUMN_NAME.PREFIX_TH,
      label: reportProspectProfileConst.COLUMN_NAME_TH.PREFIX_TH,
      minWidth: 160,
      type: reportProspectProfileConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 6,
      name: reportProspectProfileConst.COLUMN_NAME.FIRST_NAME_TH,
      value: reportProspectProfileConst.COLUMN_NAME.FIRST_NAME_TH,
      label: reportProspectProfileConst.COLUMN_NAME_TH.FIRST_NAME_TH,
      minWidth: 160,
      type: reportProspectProfileConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 7,
      name: reportProspectProfileConst.COLUMN_NAME.LAST_NAME_TH,
      value: reportProspectProfileConst.COLUMN_NAME.LAST_NAME_TH,
      label: reportProspectProfileConst.COLUMN_NAME_TH.LAST_NAME_TH,
      minWidth: 160,
      type: reportProspectProfileConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 8,
      name: reportProspectProfileConst.COLUMN_NAME.UNIT_NUMBER,
      value: reportProspectProfileConst.COLUMN_NAME.UNIT_NUMBER,
      label: reportProspectProfileConst.COLUMN_NAME_TH.UNIT_NUMBER,
      minWidth: 160,
      type: reportProspectProfileConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 9,
      name: reportProspectProfileConst.COLUMN_NAME.UNIT_NAME,
      value: reportProspectProfileConst.COLUMN_NAME.UNIT_NAME,
      label: reportProspectProfileConst.COLUMN_NAME_TH.UNIT_NAME,
      minWidth: 160,
      type: reportProspectProfileConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 10,
      name: reportProspectProfileConst.COLUMN_NAME.STATUS_PROSPECT,
      value: reportProspectProfileConst.COLUMN_NAME.STATUS_PROSPECT,
      label: reportProspectProfileConst.COLUMN_NAME_TH.STATUS_PROSPECT,
      minWidth: 160,
      type: reportProspectProfileConst.TYPE_OF_VALUE.DROPDOWN_SINGLE,
      options: reportProspectProfileConst.statusProspectOptions,
    },
    {
      id: 11,
      name: reportProspectProfileConst.COLUMN_NAME.STATUS,
      value: reportProspectProfileConst.COLUMN_NAME.STATUS,
      label: reportProspectProfileConst.COLUMN_NAME_TH.STATUS,
      minWidth: 160,
      type: reportProspectProfileConst.TYPE_OF_VALUE.DROPDOWN_SINGLE,
      options: reportProspectProfileConst.statusOptions,
    },
    {
      id: 12,
      name: reportProspectProfileConst.COLUMN_NAME.ACCESS,
      value: reportProspectProfileConst.COLUMN_NAME.ACCESS,
      label: reportProspectProfileConst.COLUMN_NAME_TH.ACCESS,
      minWidth: 160,
      type: reportProspectProfileConst.TYPE_OF_VALUE.DROPDOWN_SINGLE,
      options: reportProspectProfileConst.accessOptions,
    },
    {
      id: 13,
      name: reportProspectProfileConst.COLUMN_NAME.ACCESS_DATE,
      value: reportProspectProfileConst.COLUMN_NAME.ACCESS_DATE,
      label: reportProspectProfileConst.COLUMN_NAME_TH.ACCESS_DATE,
      minWidth: 160,
      type: reportProspectProfileConst.TYPE_OF_VALUE.DATE_RANGE,
    },
    {
      id: 14,
      name: reportProspectProfileConst.COLUMN_NAME.MOBILE_NO,
      value: reportProspectProfileConst.COLUMN_NAME.MOBILE_NO,
      label: reportProspectProfileConst.COLUMN_NAME_TH.MOBILE_NO,
      minWidth: 160,
      type: reportProspectProfileConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 15,
      name: reportProspectProfileConst.COLUMN_NAME.EMAIL,
      value: reportProspectProfileConst.COLUMN_NAME.EMAIL,
      label: reportProspectProfileConst.COLUMN_NAME_TH.EMAIL,
      minWidth: 160,
      type: reportProspectProfileConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 16,
      name: reportProspectProfileConst.COLUMN_NAME.DATE_OF_BIRTH,
      value: reportProspectProfileConst.COLUMN_NAME.DATE_OF_BIRTH,
      label: reportProspectProfileConst.COLUMN_NAME_TH.DATE_OF_BIRTH,
      minWidth: 160,
      type: reportProspectProfileConst.TYPE_OF_VALUE.DATE_RANGE,
    },
    {
      id: 17,
      name: reportProspectProfileConst.COLUMN_NAME.ID_CARD_NO,
      value: reportProspectProfileConst.COLUMN_NAME.ID_CARD_NO,
      label: reportProspectProfileConst.COLUMN_NAME_TH.ID_CARD_NO,
      minWidth: 160,
      type: reportProspectProfileConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 18,
      name: reportProspectProfileConst.COLUMN_NAME.GENDER,
      value: reportProspectProfileConst.COLUMN_NAME.GENDER,
      label: reportProspectProfileConst.COLUMN_NAME_TH.GENDER,
      minWidth: 160,
      type: reportProspectProfileConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 19,
      name: reportProspectProfileConst.COLUMN_NAME.MANAGER_AGENT_CODE,
      value: reportProspectProfileConst.COLUMN_NAME.MANAGER_AGENT_CODE,
      label: reportProspectProfileConst.COLUMN_NAME_TH.MANAGER_AGENT_CODE,
      minWidth: 160,
      type: reportProspectProfileConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 20,
      name: reportProspectProfileConst.COLUMN_NAME.MANAGER_NAME,
      value: reportProspectProfileConst.COLUMN_NAME.MANAGER_NAME,
      label: reportProspectProfileConst.COLUMN_NAME_TH.MANAGER_NAME,
      minWidth: 160,
      type: reportProspectProfileConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 21,
      name: reportProspectProfileConst.COLUMN_NAME.MANAGER_RAM,
      value: reportProspectProfileConst.COLUMN_NAME.MANAGER_RAM,
      label: reportProspectProfileConst.COLUMN_NAME_TH.MANAGER_RAM,
      minWidth: 160,
      type: reportProspectProfileConst.TYPE_OF_VALUE.INPUT_TEXT,
      options: reportProspectProfileConst.ramNameOptions,
    },
    {
      id: 22,
      name: reportProspectProfileConst.COLUMN_NAME.MANAGER_SRAM,
      value: reportProspectProfileConst.COLUMN_NAME.MANAGER_SRAM,
      label: reportProspectProfileConst.COLUMN_NAME_TH.MANAGER_SRAM,
      minWidth: 160,
      type: reportProspectProfileConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 23,
      name: reportProspectProfileConst.COLUMN_NAME.PROSPECT_TYPE,
      value: reportProspectProfileConst.COLUMN_NAME.PROSPECT_TYPE,
      label: reportProspectProfileConst.COLUMN_NAME_TH.PROSPECT_TYPE,
      minWidth: 160,
      type: reportProspectProfileConst.TYPE_OF_VALUE.INPUT_TEXT,
      options: reportProspectProfileConst.prospectTypeOptions,
    },
    {
      id: 24,
      name: reportProspectProfileConst.COLUMN_NAME.LAST_LOGIN,
      value: reportProspectProfileConst.COLUMN_NAME.LAST_LOGIN,
      label: reportProspectProfileConst.COLUMN_NAME_TH.LAST_LOGIN,
      minWidth: 160,
      type: reportProspectProfileConst.TYPE_OF_VALUE.DATE_RANGE,
    },
  ],
}
