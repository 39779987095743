import {
  STUDENT_STATUS_TEXT,
  TEST_RESULT_STATUS_TEXT,
} from '../../../constants/eExamination'

export const arrayStatus = [
  {
    label: STUDENT_STATUS_TEXT.WAITING_CHECK,
    name: 'waitingCheckChecked',
  },
  {
    label: STUDENT_STATUS_TEXT.WAITING_ANNOUNCEMENT,
    name: 'waitingAnnounceChecked',
  },
  {
    label: STUDENT_STATUS_TEXT.ANNOUNCEMENT,
    name: 'announcementChecked',
  },
  {
    label: STUDENT_STATUS_TEXT.PERMISSION_DENIED,
    name: 'permissionDeniedChecked',
  },
  {
    label: STUDENT_STATUS_TEXT.CANCEL_BY_SELF,
    name: 'canceledSelfChecked',
  },
  {
    label: STUDENT_STATUS_TEXT.CANCEL_BY_ADMIN,
    name: 'canceledAdminChecked',
  },
  {
    label: STUDENT_STATUS_TEXT.CANCEL_BY_MANAGER,
    name: 'canceledMAChecked',
  },
]

export const arrayTestResult = [
  {
    label: TEST_RESULT_STATUS_TEXT.PASS,
    name: 'passChecked',
  },
  {
    label: TEST_RESULT_STATUS_TEXT.NOT_PASS,
    name: 'notPassChecked',
  },
  {
    label: TEST_RESULT_STATUS_TEXT.ABSENCE,
    name: 'absentChecked',
  },
]

export const arrayCheckbox = [
  {
    label: 'รหัส 6 หลัก',
    nameCheckBox: 'agentCodeChecked',
    nameInput: 'agentCode',
    placeholder: 'ค้นหา',
  },
  {
    label: 'ชื่อ',
    nameCheckBox: 'firstNameTHChecked',
    nameInput: 'firstNameTH',
    placeholder: 'ค้นหา',
  },
  {
    label: 'นามสกุล',
    nameCheckBox: 'lastNameTHChecked',
    nameInput: 'lastNameTH',
    placeholder: 'ค้นหา',
  },
  {
    label: 'ชื่อหน่วยงาน',
    nameCheckBox: 'unitNameChecked',
    nameInput: 'unitName',
    placeholder: 'ค้นหา',
  },
  {
    label: 'รหัสหน่วยงาน',
    nameCheckBox: 'unitCodeChecked',
    nameInput: 'unitCode',
    placeholder: 'ค้นหา',
  },
]

export const defaultFilter = {
  agentCodeChecked: false,
  agentCode: '',
  firstNameChecked: false,
  firstName: '',
  lastNameChecked: false,
  lastName: '',
  unitNameChecked: false,
  unitName: '',
  unitCodeChecked: false,
  unitCode: '',
  statusChecked: false,
  status: {
    waitingCheckChecked: false,
    waitingAnnounceChecked: false,
    announcementChecked: false,
    permissionDeniedChecked: false,
    canceledMAChecked: false,
    canceledAdminChecked: false,
    canceledSelfChecked: false,
  },
  testResultChecked: false,
  testResult: {
    passChecked: false,
    notPassChecked: false,
    absentChecked: false,
  },
}
