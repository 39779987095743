import * as yup from 'yup'
import _ from 'lodash'

export const validationSchema = yup.object({
  costCenter: yup
    .object()
    .nullable()
    .test('validate-cost-center', 'กรุณาเลือก Cost Center', (costCenter) => {
      return _.get(costCenter, 'value', null)
    }),
  staffProfile: yup
    .object()
    .nullable()
    .test('validate-staff', 'กรุณาเลือก Trainer/Staff', (staff, { parent }) => {
      if (!parent.staffRole || _.isUndefined(staff?.value)) return true
      return _.get(staff, 'value', null)
    }),
  staffRole: yup.object().nullable(),
  amountList: yup.array().of(
    yup.object().shape({
      accountCode: yup
        .object()
        .nullable()
        .test(
          'validate-account-code',
          'กรุณาเลือก Account Code',
          (accountCode) => {
            return _.get(accountCode, 'value', null)
          },
        ),
      amount: yup
        .string()
        .nullable()
        .test(
          'validate-amount-required',
          'กรุณาระบุภายใน 7 ตัวเลข',
          (value) => {
            return !_.isEmpty(value)
              ? Number(value) >= 0 && Number(value) <= 9999999
              : value
          },
        ),
    }),
  ),
})
