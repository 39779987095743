import { filterEtestingDashboardResult } from '../../../../../../utils/apiPath'
import { headerCells } from './model/headerCells'

export const crudConfig = {
  moduleId: 'ETESTING_DASHBOARD_QA',
  moduleSlug: 'e-testing-dashboard-qa',
  moduleTitleFull: 'DashboardQA',
  modulesTitleShort: 'DashboardQA',
  modulePath: '/e-testing/dashboard/view/:uuid',
  filterPath: filterEtestingDashboardResult,
  headerCells: headerCells,
}
