import { TIME_PERIOD } from '../../../../constants/expense'

export const timePeriodOptions = [
  {
    label: 'รายปี',
    value: TIME_PERIOD.YEARLY,
  },
  {
    label: 'กำหนดระยะเวลา',
    value: TIME_PERIOD.CUSTOM,
  },
]
