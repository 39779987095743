import React from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useFormikContext } from 'formik'
import _ from 'lodash'
import DeleteTwoTone from '@mui/icons-material/DeleteTwoTone'
import IconButton from '@mui/material/IconButton'
import Select from '../../../../../../components/Input/Select'
import TimePicker from '../../../../../../components/TimePicker/TimePicker'
import { StyledRowFlex } from '../../Styled'
import {
  convertTime,
  handleDelete,
  handleInputChange,
  handleTimeInputChange,
} from './events'

const SpeakerForm = (props) => {
  const { speaker, dIndex, sIndex } = props
  const isOld = !!_.get(speaker, 'responsibility.responsibility', false)
  const { externalSpeakerOption, responsibilityOption } = useSelector(
    (state) => ({
      externalSpeakerOption: state.manageClassForm.externalSpeakerOption,
      responsibilityOption: state.manageClassForm.responsibilityOption,
    }),
    shallowEqual,
  )
  const dispatch = useDispatch()
  const { errors } = useFormikContext()
  const errorText = `monthlyPlan.dates[${dIndex}].externalSpeakerPlan[${sIndex}]`

  if (isOld) return <></>

  return (
    <StyledRowFlex sx={{ alignItems: 'start' }}>
      <Select
        boxSx={{ width: '33%' }}
        dataTestid="select-speaker"
        name="staff"
        placeholder="กรุณาเลือก"
        type="basic"
        options={externalSpeakerOption[props.dIndex]}
        defaultValue=""
        textError={_.get(errors, `${errorText}.staff.uuid`, '')}
        handleChange={(e) => dispatch(handleInputChange(e, props))}
      />
      <Select
        boxSx={{ width: '33%' }}
        dataTestid="select-role"
        name="responsibility"
        placeholder="กรุณาเลือก"
        type="basic"
        options={responsibilityOption}
        defaultValue=""
        textError={_.get(errors, `${errorText}.responsibility.uuid`, '')}
        handleChange={(e) => dispatch(handleInputChange(e, props))}
      />
      <StyledRowFlex sx={{ width: '33%', alignItems: 'start' }}>
        <TimePicker
          dataTestId="select-startTime"
          value={convertTime(_.get(speaker, 'startTime', ''))}
          disabledStartTime={convertTime(_.get(speaker, 'startTime', ''))}
          disabledEndTime={convertTime(_.get(speaker, 'endTime', ''))}
          helperText={_.get(errors, `${errorText}.startTime`, '')}
          onChange={(e) =>
            dispatch(handleTimeInputChange(e, 'startTime', props))
          }
        />
        <TimePicker
          dataTestId="select-endTime"
          value={convertTime(_.get(speaker, 'endTime', ''))}
          disabledStartTime={convertTime(_.get(speaker, 'startTime', ''))}
          disabledEndTime={convertTime(_.get(speaker, 'endTime', ''))}
          helperText={_.get(errors, `${errorText}.startTime`, '')}
          onChange={(e) => dispatch(handleTimeInputChange(e, 'endTime', props))}
        />
        <IconButton
          data-testid="btn-delete"
          onClick={() => dispatch(handleDelete(props))}
        >
          <DeleteTwoTone />
        </IconButton>
      </StyledRowFlex>
    </StyledRowFlex>
  )
}
export default SpeakerForm
