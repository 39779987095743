import { hasAllDashboardPerms } from '../handler/hasAllDashboardPerms'

export const filterItems = () => {
  const filterArray = [
    {
      label: 'วันที่',
      nameCheckBox: 'startDateChecked',
      nameInput: 'startDate',
      placeholder: 'เลือกช่วงเวลา',
      type: 'daterange',
    },
    {
      label: 'คลังทรัพย์สิน',
      nameCheckBox: 'inventoryChecked',
      nameInput: 'inventory',
      placeholder: 'เลือกคลังทรัพย์สิน',
      type: 'select_dropdown',
    },
    {
      label: 'หลักสูตร',
      nameCheckBox: 'courseChecked',
      nameInput: 'course',
      placeholder: 'เลือกหลักสูตร',
      type: 'select_dropdown',
    },
  ]
  if (hasAllDashboardPerms()) {
    filterArray.splice(2, 0, {
      label: 'Station',
      nameCheckBox: 'stationChecked',
      nameInput: 'station',
      placeholder: 'เลือก Station',
      type: 'checkbox',
      list: [],
    })
  }

  return filterArray
}

export const filterReqItems = () => {
  const filterArray = [
    {
      label: 'ชื่อหลักสูตร',
      nameCheckBox: 'courseReqChecked',
      nameInput: 'courseReq',
      placeholder: 'เลือกชื่อหลักสูตร',
      type: 'select_dropdown',
    },
    {
      label: 'วันที่สร้าง',
      nameCheckBox: 'startDateChecked',
      nameInput: 'startDate',
      placeholder: 'เลือกช่วงเวลา',
      type: 'daterange',
    },
    {
      label: 'สถานะ',
      nameCheckBox: 'statusChecked',
      nameInput: 'status',
      type: 'checkbox',
      list: [
        { label: 'รอคืน', name: 'waitingReturn' },
        { label: 'อนุมัติรอคืน', name: 'approve' },
      ],
    },
    {
      label: 'ประเภท Ticket',
      nameCheckBox: 'ticketTypeChecked',
      nameInput: 'ticketType',
      type: 'checkbox',
      list: [
        { label: 'Monthly Plan', name: 'monthlyPlan' },
        { label: 'Other', name: 'other' },
      ],
    },
    {
      label: 'จำนวนวันที่กำหนดคืน',
      nameCheckBox: 'returnDateChecked',
      nameInput: 'returnDate',
      inputFrom: 'returnDateFrom',
      inputTo: 'returnDateTo',
      placeholder: 'ระบุจำนวน',
      type: 'number_between',
    },
  ]

  if (hasAllDashboardPerms()) {
    filterArray.splice(1, 0, {
      label: 'Station',
      nameCheckBox: 'stationChecked',
      nameInput: 'station',
      placeholder: 'เลือก Station',
      type: 'checkbox',
      list: [],
    })
  }

  return filterArray
}
