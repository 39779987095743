import React from 'react'
import InfoIcon from '@mui/icons-material/Info'
import Tooltip from '@mui/material/Tooltip'
import { Box } from '@mui/material'
import { REMARK_TEXT } from '../../../../constants/eConfig'
import { useTheme } from '@emotion/react'

const RemarkTooltip = ({
  content = REMARK_TEXT,
  iconStyle,
  tooltipStyle,
  boxSx,
  IconSize = '14px',
  wrapperSx,
}) => {
  const theme = useTheme()
  return (
    <Box
      display="inline-block"
      marginLeft="5px"
      position="relative"
      width="10px"
      height="9px"
      sx={wrapperSx}
    >
      <Box sx={boxSx}>
        <Tooltip
          title={
            <span style={{ color: 'white', ...tooltipStyle }}>{content}</span>
          }
          arrow
        >
          <InfoIcon
            style={{
              cursor: 'pointer',
              color: theme?.palette?.blue?.azure,
              fontSize: IconSize,
              ...iconStyle,
            }}
          />
        </Tooltip>
      </Box>
    </Box>
  )
}

export default RemarkTooltip
