import _ from 'lodash'
import { store } from '../../../../App'
import { getMonths } from './getMonths'
import {
  setReduxDashboard,
  startLoadingDashboard,
  stopLoadingDashboard,
} from '../../../../redux/slices/expense'
import { themeColor } from '../../../../utils/themeData'
import { fetchDashboardExpense } from '../../../../services/expense'
import { VIEW_MODE } from '../../../../constants/expense'
import dayjs from 'dayjs'

export const fetchDashboardData = async () => {
  store.dispatch(startLoadingDashboard())
  const { filters, timePeriod, yearBudget, dateRange, uuid, tabActive } =
    store.getState().expense.dashboard
  const viewMode = uuid
    ? VIEW_MODE.BY_DEPARTMENT
    : tabActive === 0
    ? VIEW_MODE.BY_ACADEMY
    : VIEW_MODE.BY_COST_CENTER

  const isByAcademyTab = _.isEqual(VIEW_MODE.BY_ACADEMY, viewMode)
  const isByDepartment = _.isEqual(VIEW_MODE.BY_DEPARTMENT, viewMode)

  let {
    summary,
    monthlySummary,
    budgetActualByCostCenter,
    costCenterName,
    latestUpdated,
  } = await store
    .dispatch(
      fetchDashboardExpense({
        ...filters,
        timePeriod,
        yearBudget: _.isNumber(yearBudget) ? yearBudget : dayjs().year(),
        costCenter:
          isByAcademyTab || isByDepartment
            ? []
            : _.get(filters, 'costCenter', []).map((cc) =>
                _.get(cc, 'uuid', ''),
              ),
        accountCode: isByAcademyTab
          ? []
          : _.get(filters, 'accountCode', []).map((ac) =>
              _.get(ac, 'uuid', ''),
            ),
        staffProfile: isByAcademyTab
          ? []
          : _.get(filters, 'staffProfile', []).map((sp) =>
              _.get(sp, 'uuid', ''),
            ),
        staffType: isByAcademyTab
          ? []
          : _.get(filters, 'staffType', []).map((st) => _.get(st, 'value', '')),
        categories: isByAcademyTab
          ? []
          : _.get(filters, 'category', []).map((ac) => _.get(ac, 'uuid', '')),
        uuid,
        viewMode,
        startDate: !_.isEmpty(dateRange)
          ? dayjs(_.get(dateRange, 'startDate')).format('YYYY-MM-DD')
          : dayjs().format('YYYY-MM-DD'),
        endDate: !_.isEmpty(dateRange)
          ? dayjs(_.get(dateRange, 'endDate')).format('YYYY-MM-DD')
          : dayjs().format('YYYY-MM-DD'),
      }),
    )
    .then((res) => _.get(res, 'payload.data', []))

  summary = _.map(summary, (sum) => {
    const { actual, budget, remaining, budgetExceeded } = sum
    return {
      ...sum,
      colorActual: budgetExceeded
        ? themeColor.error.main
        : themeColor.success.content,
      colorRemaining:
        remaining < 0 ? themeColor.error.main : themeColor.text.secondary,
      bgColor: budgetExceeded ? themeColor.error.main : '#0B4F1F',
      percent: budgetExceeded
        ? 100
        : !_.isNaN(actual / budget)
        ? (actual / budget) * 100
        : 0,
    }
  })

  const months = getMonths()

  monthlySummary = _.map(monthlySummary, (sum, idx) => {
    const { monthEN, monthTH } = months[idx]
    const {
      actual,
      budget,
      remaining,
      budgetExceeded,
      month: monthNumber,
    } = sum

    return {
      ...sum,
      monthNumber: monthNumber,
      month: `${monthEN} ${monthTH}`,
      colorActual: budgetExceeded
        ? themeColor.error.main
        : themeColor.success.content,
      colorRemaining:
        remaining < 0 ? themeColor.error.main : themeColor.text.secondary,
      bgColor: budgetExceeded ? themeColor.error.main : '#0B4F1F',
      percent: budgetExceeded
        ? 100
        : !_.isNaN(actual / budget)
        ? (actual / budget) * 100
        : 0,
    }
  })

  store.dispatch(
    setReduxDashboard({
      summary,
      summaryDisplay: summary,
      monthlySummary,
      budgetActualByCostCenter,
      costCenterName,
      latestUpdated,
      yearBudget: _.isNumber(yearBudget) ? yearBudget : dayjs().year(),
      startDate: !_.isEmpty(dateRange)
        ? _.get(dateRange, 'startDate')
        : new Date(),
      endDate: !_.isEmpty(dateRange) ? _.get(dateRange, 'endDate') : new Date(),
    }),
  )
  store.dispatch(stopLoadingDashboard())
}
