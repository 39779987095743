export const allFilterType = ['workType', 'taskName', 'status']

export const filterLabel = {
  assignDate: 'วันที่รับ assign งาน',
  workType: 'หมวดหมู่งาน',
  taskName: 'ชื่องาน',
  status: 'สถานะ',
}

export const statusLabel = {
  TO_DO: 'ยังไม่ได้ทำ',
  ON_WORKING: 'กำลังทำ',
  DONE: 'เสร็จสิ้น',
  CANCELED: 'ยกเลิก',
}

export const statusLabelColor = {
  TO_DO: '#ED6C02',
  ON_WORKING: '#FFB547',
  DONE: '#1CC54E',
  CANCELED: '#C91432',
}

export const taskNameNotiLabel = {
  //HF
  CREATED_MASTER_PLAN: 'อนุมัติแผนรายเดือน',
  REQUESTED_APPROVED_MONTHLY_PLAN: 'รายการอนุมัติแผนงาน',
  CANCELED_MASTER_PLAN: 'ยกเลิกขออนุมัติแผนรายเดือน',
  //HZ
  DISAPPROVED_MASTER_PLAN: 'ไม่อนุมัติแผนรายเดือน',
  DISAPPROVED_MONTHLY_PLAN: 'ไม่อนุมัติแผนงาน',
  DISAPPROVED_BUDGET: 'คำของบ Break/Lunch ถูกปฏิเสธ',
  APPROVED_MASTER_PLAN: 'อนุมัติแผนรายเดือน',
  APPROVED_MONTHLY_PLAN: 'อนุมัติแผนงาน',
  DISAPPROVED_BORROW_SPEAKER: 'ไม่อนุมัติวิทยากรสอนข้ามพื้นที่',
  APPROVED_BORROW_SPEAKER: 'อนุมัติวิทยากรสอนข้ามพื้นที่',
  CHANGE_SPEAKER: 'เปลี่ยนวิทยากรสอนข้ามพื้นที่',
  REQUEST_BORROW_SPEAKER_CREATED: 'อนุมัติคำขอยืมวิทยากรโซนอื่น',
  CANCEL_SPEAKER: 'ยกเลิกวิทยากรสอนข้ามพื้นที่',
  //Trainer
  ASSIGNMENT_TEST: 'ข้อสอบ Assessment',
  CHECK_EXAM: 'ตรวจข้อสอบ',
  CHECK_EXAM_STANDALONE: 'ตรวจข้อสอบ Standalone',
  EDITED_CLASS: 'แก้ไขคลาส',
  EDITED_MONTHLY_PLAN: 'แก้ไขแผนงาน',
  CANCELED_MONTHLY_PLAN: 'ยกเลิกแผนงาน',
  SENT_BORROW_LEARNER: 'สอนข้ามพื้นที่',
  ADDED_MONTHLY_PLAN: 'เพิ่มแผนงาน',
  //TO
  ANNOUNCE_STUDENT: 'ประกาศรายชื่อผู้มีสิทธิ์เรียน',
  SETTING_CLASS: 'ตั้งค่าคลาส',
  CHECK_DOCUMENT: 'ตรวจเอกสาร',
  ADDED_STUDENT: 'เพิ่มผู้เรียน',
  STUDENT_CANCELED_CLASS: 'ยกเลิกการสมัครเรียน',
  CLOSE_CLASS: 'ปิดคลาส',
  CLOSE_CLASS_FINISH: 'ปิดคลาสเสร็จสิ้น',
  //other
  REQUESTED_MEETING_ROOM: 'คำร้องขอจองห้องประชุม',
  //E-LEARNING
  CREATE_E_LEARNING_QUESTION: 'คำถาม',
  REQUEST_CREATED_MASTER_PLAN: 'กรุณาส่งแผนรายเดือน',
}

export const taskNameLabel = {
  REQUESTED_APPROVED_MONTHLY_PLAN: 'อนุมัติแผนงาน',
  CREATED_MASTER_PLAN: 'อนุมัติแผนรายเดือน',
  APPROVED_MONTHLY_PLAN: 'อนุมัติแผนงาน',
  APPROVED_MASTER_PLAN: 'อนุมัติแผนรายเดือน',
  CANCELED_MASTER_PLAN: 'ยกเลิกขออนุมัติแผนรายเดือน',
  DISAPPROVED_MONTHLY_PLAN: 'ไม่อนุมัติแผนงาน',
  EDITED_MONTHLY_PLAN: 'แก้ไขแผนงาน',
  CANCELED_MONTHLY_PLAN: 'ยกเลิกแผนงาน',
  ADDED_MONTHLY_PLAN: 'เพิ่มแผนงาน',
  DISAPPROVED_MASTER_PLAN: 'ไม่อนุมัติแผนรายเดือน',
  EDITED_MASTER_PLAN: 'แก้ไขแผนรายเดือน',
  REQUEST_BORROW_SPEAKER_CREATED: 'อนุมัติคำขอยืมวิทยากรโซนอื่น',
  APPROVED_BORROW_SPEAKER: 'อนุมัติวิทยากรสอนข้ามพื้นที่',
  DISAPPROVED_BORROW_SPEAKER: 'ไม่อนุมัติวิทยากรสอนข้ามพื้นที่',
  CANCEL_SPEAKER: 'ยกเลิกวิทยากรสอนข้ามพื้นที่',
  CHANGE_SPEAKER: 'เปลี่ยนวิทยากรสอนข้ามพื้นที่',
  DISAPPROVED_BUDGET: 'คำของบ Break/Lunch ถูกปฏิเสธ',
  DISAPPROVED_EDIT_BUDGET: 'ไม่อนุมัติแก้ไขงบ',
  CHECK_EXAM: 'ตรวจข้อสอบ',
  ASSIGNMENT_TEST: 'ข้อสอบ Assessment',
  CLOSE_CLASS: 'ปิดคลาส',
  CLOSE_CLASS_FINISH: 'ปิดคลาสเสร็จสิ้น',
  CHECK_EXAM_STANDALONE: 'ตรวจข้อสอบ Standalone',
  REQUESTED_MEETING_ROOM: 'คำร้องขอจองห้องประชุม',
  ANNOUNCE_STUDENT: 'ประกาศรายชื่อผู้มีสิทธิ์เรียน',
  CHECK_DOCUMENT: 'ตรวจเอกสาร',
  SETTING_CLASS: 'ตั้งค่าคลาส',
  ADMIN_CLOSE_CLASS: 'แอดมินปิดคลาส',
  EDITED_CLASS: 'แก้ไขคลาส',
  APPROVED_CLASS: 'อนุมัติคลาส',
  DISAPPROVED_CLASS: 'ไม่อนุมัติคลาส',
  ADDED_STUDENT: 'เพิ่มผู้เรียน',
  STUDENT_CANCELED_CLASS: 'ยกเลิกการสมัครเรียน',
  WAITING_ANNOUNCE: 'รอประกาศสิทธิ์',
  SENT_BORROW_LEARNER: 'สอนข้ามพื้นที่',
  CREATE_E_LEARNING_QUESTION: 'คำถาม',
  WAITING_ANNOUNCE_E_LEARNING: 'ประกาศรายชื่อผู้มีสิทธิ์เรียน',
  REQUEST_CREATED_MASTER_PLAN: 'กรุณาส่งแผนรายเดือน',
}

export const eventLabel = {}

export const workTypeLabel = {
  MONTHLY_PLAN: 'Monthly Plan',
  MANAGE_CLASS: 'Manage Class',
  E_TESTING: 'E-Testing',
  E_LEARNING: 'E-Learning',
}

export const allWorkTypeChecked = [
  'monthlyPlanChecked',
  'manageClassChecked',
  'eTestingChecked',
]

export const allStatusChecked = [
  'toDoChecked',
  'onWorkingChecked',
  'doneChecked',
  'cancelChecked',
]

export const allTaskNameChecked = [
  'approveMonthlyPlanChecked',
  'approveMasterPlanChecked',
  'requestSpeakerBorrowChecked',
  'disapproveBuggetChecked',
  'disapproveMonthlyPlanChecked',
  'disapproveMasterPlanChecked',
  'disapproveSpeakerBorrowChecked',
  'checkExamChecked',
  'assignmentTestChecked',
  'adminCloseClassChecked',
  'checkTestStandaloneChecked',
  'requestMeetingRoomChecked',
  'announceStudentChecked',
  'checkDocumentChecked',
  'settingClassChecked',
  'closeClassChecked',
  'questionChecked',
  'settingClassChecked',
]

export const filterDrawerLabel = {
  //status
  toDoChecked: {
    label: 'ยังไม่ได้ทำ',
    value: 'TO_DO',
  },
  onWorkingChecked: {
    label: 'กำลังทำ',
    value: 'ON_WORKING',
  },
  doneChecked: {
    label: 'เสร็จสิ้น',
    value: 'DONE',
  },
  cancelChecked: {
    label: 'ยกเลิก',
    value: 'CANCELED',
  },
  //taskName
  //HF
  approveMonthlyPlanChecked: {
    label: 'อนุมัติแผนงาน',
    value: ['REQUESTED_APPROVED_MONTHLY_PLAN'],
  },
  approveMasterPlanChecked: {
    label: 'อนุมัติแผนรายเดือน',
    value: ['CREATED_MASTER_PLAN'],
  },
  //HZ
  requestSpeakerBorrowChecked: {
    label: 'อนุมัติคำขอยืมวิทยากรโซนอื่น',
    value: 'REQUEST_BORROW_SPEAKER_CREATED',
  },
  disapproveBuggetChecked: {
    label: 'คำของบ Break/Lunch ถูกปฏิเสธ',
    value: 'DISAPPROVED_BUDGET',
  },
  disapproveMonthlyPlanChecked: {
    label: 'ไม่อนุมัติแผนงาน',
    value: 'DISAPPROVED_MONTHLY_PLAN',
  },
  disapproveMasterPlanChecked: {
    label: 'ไม่อนุมัติแผนรายเดือน',
    value: 'DISAPPROVED_MASTER_PLAN',
  },
  disapproveSpeakerBorrowChecked: {
    label: 'ไม่อนุมัติวิทยากรสอนข้ามพื้นที่',
    value: 'DISAPPROVED_BORROW_SPEAKER',
  },
  //Trainer
  checkExamChecked: {
    label: 'ตรวจข้อสอบ',
    value: 'CHECK_EXAM',
  },
  assignmentTestChecked: {
    label: 'ข้อสอบ Assessment',
    value: 'ASSIGNMENT_TEST',
  },
  adminCloseClassChecked: {
    label: 'ปิดคลาส',
    value: 'CLOSE_CLASS',
  },
  checkTestStandaloneChecked: {
    label: 'ตรวจข้อสอบ Standalone',
    value: 'CHECK_EXAM_STANDALONE',
  },
  //Admin
  requestMeetingRoomChecked: {
    label: 'คำร้องขอจองห้องประชุม',
    value: 'REQUESTED_MEETING_ROOM',
  },
  announceStudentChecked: {
    label: 'ประกาศรายชื่อผู้มีสิทธิ์เรียน',
    value: 'ANNOUNCE_STUDENT',
  },
  checkDocumentChecked: {
    label: 'ตรวจเอกสาร',
    value: 'CHECK_DOCUMENT',
  },
  settingClassChecked: {
    label: 'ตั้งค่าคลาส',
    value: 'SETTING_CLASS',
  },
  closeClassChecked: {
    label: 'ปิดคลาส',
    value: 'CLOSE_CLASS',
  },
  questionChecked: {
    label: 'คำถาม',
    value: 'QUESTION',
  },
  //workType
  monthlyPlanChecked: {
    label: 'Monthly Plan',
    value: 'MONTHLY_PLAN',
  },
  manageClassChecked: {
    label: 'Manage Class',
    value: 'MANAGE_CLASS',
  },
  eTestingChecked: {
    label: 'E-TESTING',
    value: 'E_TESTING',
  },
}
