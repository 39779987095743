import { crudConfig as initialCrudConfig } from './crudConfig'
import { useEffect } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import CFilterDrawer from '../FilterDrawer'
import { filterItems } from './model/filterItems'
import { defaultFilter } from './model/defaultFilter'
import { handleChange } from '../../../../../../components/CRUD/handler/handleChange'

import { initialConfig } from '../../../../../../components/CRUD/handler/initialConfig'
import _ from 'lodash'
import { mutateFilterPropToBody } from './mutation/filterPropToBody'
import { mutateFilterSateToProp } from './mutation/filterStateToProp'
import dayjs from 'dayjs'
import {
  LoadingCircular,
  StyledCard,
  StyledContainer,
} from '../../../../../Styled'
import { Card, Typography } from '@mui/material'
import { StyledContentBox, StyledToolbar } from '../Styled'
import { QuestionContent } from './components/QuestionContent'
import { useDispatch } from 'react-redux'
import { resetTable } from '../../../../../../redux/slices/table'
import { fetchDashboardDetail } from '../../handler/fetchDashboardDetail'
import { filterEtestingDashboardResult } from '../../../../../../utils/apiPath'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { handleFiltering } from '../../../Listing/handler/handleFiltering'
import { fetchOptionsDashboardFilter } from '../../handler/fetchOptionsDashboardFilter'
import { convertFormatDateTime } from '../../../../../../utils/lib'
import { handlePersistFilter } from '../../handler/handlePersistFilter'

export const QaTab = () => {
  const dispatch = useDispatch()
  const { uuid } = useParams()

  const {
    formData,
    crudConfig,
    isLoading,
    EtestDashboardTab,
    filterStateBelt,
  } = useSelector(
    (state) => ({
      sortDb: state.crud.sortDb,
      formData: state.crud.formData,
      crudConfig: state.crud.crudConfig,
      isLoading: state.crud.isLoading,
      EtestDashboardTab: state.crud.EtestDashboardTab,
      filterStateBelt: state.crud.filterStateBelt,
    }),
    shallowEqual,
  )

  useEffect(() => {
    dispatch(resetTable())
    initialConfig(initialCrudConfig)
    handlePersistFilter(defaultFilter, filterStateBelt)
    fetchOptionsDashboardFilter(filterItems, uuid)
  }, [])

  useEffect(async () => {
    if (!_.isUndefined(crudConfig) && EtestDashboardTab === 'qa') {
      await fetchDashboardDetail(
        mutateFilterPropToBody(uuid),
        filterEtestingDashboardResult,
        'formData',
        'sequence',
      )
    }
  }, [crudConfig])

  if (_.isUndefined(crudConfig)) {
    return <div />
  }
  const dataDateTime = _.get(formData, 'dataDateTime', undefined)

  console.log('render QaTab')

  return (
    <StyledContainer>
      <Card sx={{ minWidth: 275 }}>
        <StyledContentBox>
          <StyledToolbar sx={{}}>
            <Typography variant="h6">คำถาม/คำตอบ</Typography>
          </StyledToolbar>
          <Typography variant="body2">
            ข้อมูลเมื่อวันที่{' '}
            {convertFormatDateTime({ value: dataDateTime, type: 'dateTime' }) ??
              dayjs(new Date()).format('DD/MM/YYYY 04:00')}
          </Typography>
          <StyledCard
            id="list-table"
            sx={{ padding: '12px 16px 12px 16px', gap: '24px' }}
          >
            <QuestionContent />
          </StyledCard>
          <CFilterDrawer
            handleChange={handleChange}
            handleFiltering={() =>
              handleFiltering(mutateFilterSateToProp, mutateFilterPropToBody, {
                uuid: uuid,
                url: filterEtestingDashboardResult,
                isDashboardResultPage: true,
              })
            }
            filterItems={filterItems}
            defaultFilter={defaultFilter}
          />
        </StyledContentBox>
      </Card>

      <LoadingCircular isLoading={isLoading} />
    </StyledContainer>
  )
}
