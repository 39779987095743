import { tabsPermissionList } from '../../../modules/Permission/Form/models'

export const initialState = {
  error: null,
  isLoading: false,
  isPageLoading: false,
  data: {
    id: 0,
    uuid: '',
    groupName: '',
    status: '',
    createdAt: '',
    updatedAt: '',
    createdBy: '',
    updatedBy: '',
    permissionGroup: '',
    permission: [],
    staff: [],
    costCenter: [],
  },
  staffList: [],
  permissionList: [],
  tabActive: 0,
  tabsPermissionList: tabsPermissionList,
}
