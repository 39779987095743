import _ from 'lodash'
import {
  meetingRoom,
  meetingRoomDownload,
  meetingRoomFilter,
} from '../../utils/apiPath'
import {
  resetTablePage,
  setFilterProp,
  setFilterTotal,
  setInitialTable,
  setSearchText,
  startLoading,
  stopLoading,
} from '../../redux/slices/table'
import { downloadFile } from '../../services/util'
import { meetingRoomCells } from '../../constants/MasterData/headerCell'
import {
  closeDialog,
  loadingDialog,
  openDialog,
  stopLoadingDialog,
} from '../../redux/slices/dialog'
import { callAPI } from '../../utils/lib'
import callAxios from '../../../src/utils/baseService'
import {
  ROOM_FUNCTION,
  ROOM_TYPE,
} from '../../constants/MasterData/meetingRoom'

export const onDownload = (selected, sort, order) => async (dispatch) => {
  dispatch(startLoading())
  const body = {
    order: order.toUpperCase(),
    sort: sort === 'updatedAt' ? 'updatedAt' : sort,
    type: 'CUSTOM',
    meetingRoomUuidList: selected,
  }

  await dispatch(
    downloadFile({
      url: meetingRoomDownload,
      body: body,
      fileName: `รายการห้องประชุม.xlsx`,
    }),
  )
  dispatch(stopLoading())
}

export const onDelete = (selected, table, filterProp) => async (dispatch) => {
  dispatch(loadingDialog())
  await callAPI({
    method: 'delete',
    url: `${meetingRoom}/${selected.uuid}`,
  })

  dispatch(
    openDialog({
      type: 'success',
      title: 'สำเร็จ',
      message: 'ถูกลบแล้ว',
      isCloseDialog: false,
      handleConfirm: () => {
        dispatch(
          fetchDataList({
            method: 'fetch',
            table,
            quickSeach: '',
            manageProps: filterProp,
            page: 1,
          }),
        )
        dispatch(resetTablePage())
        dispatch(closeDialog())
      },
    }),
  )

  dispatch(stopLoadingDialog())
}

export const fetchDataList =
  ({
    method,
    table,
    quickSeach,
    manageProps,
    setInitial,
    filterProp,
    page,
    statusParam,
  }) =>
  async (dispatch) => {
    dispatch(startLoading())
    const {
      table: { limit, order, sort },
    } = table
    const stationUuid = _.get(filterProp, 'stationUuid', [])
    const roomName = _.get(filterProp, 'roomName', '')
    const roomFunction = _.get(filterProp, 'roomFunction', [])
    const roomType = _.get(filterProp, 'roomType', [])
    const roomLayout = _.get(filterProp, 'roomLayout', [])
    const createdBy = _.get(filterProp, 'createdBy', '')
    const updatedBy = _.get(filterProp, 'updatedBy', '')
    const realPage = page <= 0 ? 1 : +page
    const status = statusParam ? [statusParam] : _.get(filterProp, 'status', [])
    const body = {
      stationUuid: stationUuid,
      roomName: method === 'search' ? quickSeach : roomName,
      roomFunction: roomFunction,
      roomType: roomType,
      roomLayout: roomLayout,
      limit: limit,
      order: order.toString().toUpperCase(),
      page: realPage,
      sort: sort === 'updatedAt' ? 'updatedAt' : sort,
      status: status,
      createdBy: createdBy,
      updatedBy: updatedBy,
    }

    const isCancel = await callAxios
      .post(meetingRoomFilter, body)
      .then(({ data }) => {
        const results = data.result
        const rows = results.map((item) => {
          const station = _.get(item, 'station.station', '')
          const roomFunction = _.get(item, 'roomFunction', '')
          const roomType = _.get(item, 'roomType', '')
          const capacityClassroom = _.get(item, 'capacityClassroom', '')
          return {
            ...item,
            station: station,
            roomFunction: ROOM_FUNCTION[roomFunction],
            roomType: ROOM_TYPE[roomType],
            capacityClassroom: capacityClassroom.toLocaleString(),
          }
        })
        dispatch(
          setInitialTable({
            rows,
            allCount: data.totalCount,
            headCells: meetingRoomCells,
            placeholder: 'ค้นหาชื่อห้องประชุม',
            searchKey: 'category',
            handleSearch: (text) =>
              dispatch(handleQuickSearch({ table, text, manageProps })),
            onDownload: (selected, sort, order) =>
              dispatch(onDownload(selected, sort, order)),
            onDelete: (selected) =>
              dispatch(
                openDialog({
                  title: 'ยืนยันลบข้อมูล',
                  message: 'คุณต้องการลบรายการนี้หรือไม่',
                  colorDisagreeText: 'error',
                  colorAgreeText: 'error',
                  handleConfirm: () =>
                    dispatch(onDelete(selected, table, filterProp)),
                }),
              ),
          }),
        )
      })
      .catch((e) => {
        dispatch(
          setInitialTable({
            rows: [],
            allCount: 0,
            headCells: meetingRoomCells,
          }),
        )
        return e.message?.includes('method')
      })
    setInitial && setInitial(false)
    if (!isCancel) dispatch(stopLoading())
  }

export const onFilterClick =
  (table, filter, station, manageProps) => (dispatch) => {
    dispatch(setSearchText(''))
    const statusList = []
    const roomFunctionList = []
    const roomTypeList = []
    const stationList = []
    const roomLayoutList = []

    if (filter.stationChecked) {
      for (const key in filter.station) {
        if (!filter.station[key]) continue
        const item = station.find((e) => e.name === key)
        stationList.push(_.get(item, 'uuid', ''))
      }
    }

    if (filter.statusChecked) {
      if (filter.status?.activeChecked) {
        statusList.push('ACTIVE')
      }
      if (filter.status?.inActiveChecked) {
        statusList.push('INACTIVE')
      }
      if (filter.status?.deletedChecked) {
        statusList.push('DELETED')
      }
    }

    if (filter.roomFunctionChecked) {
      if (filter.roomFunction?.singleChecked) {
        roomFunctionList.push('SINGLE_ROOM')
      }
      if (filter.roomFunction?.connectingChecked) {
        roomFunctionList.push('CONNECTING_ROOM')
      }
      if (filter.roomFunction?.roomFunctionOtherChecked) {
        roomFunctionList.push('OTHER')
      }
    }

    if (filter.roomLayoutChecked) {
      if (filter.roomLayout?.classroomChecked) {
        roomLayoutList.push('CLASSROOM')
      }
      if (filter.roomLayout?.groupChecked) {
        roomLayoutList.push('GROUP')
      }
      if (filter.roomLayout?.uShapeChecked) {
        roomLayoutList.push('USHAPE')
      }
      if (filter.roomLayout?.theaterChecked) {
        roomLayoutList.push('THEATER')
      }
    }

    if (filter.roomTypeChecked) {
      if (filter.roomType?.classroomChecked) {
        roomTypeList.push('CLASSROOM')
      }
      if (filter.roomType?.computerRoomChecked) {
        roomTypeList.push('COMPUTER_ROOM')
      }
      if (filter.roomType?.meetingRoomChecked) {
        roomTypeList.push('MEETING_ROOM')
      }
      if (filter.roomType?.roomTypeOtherChecked) {
        roomTypeList.push('OTHER')
      }
    }

    const filterText = {
      stationUuid: filter.stationChecked ? stationList : [],
      roomName: filter.roomNameChecked ? filter.roomName : '',
      roomLayout: filter.roomLayoutChecked ? roomLayoutList : [],
      roomFunction: filter.roomFunctionChecked ? roomFunctionList : [],
      roomType: filter.roomTypeChecked ? roomTypeList : [],
      status: filter.statusChecked ? statusList : [],
      createdBy: filter.createdByChecked ? filter.createdBy : '',
      updatedBy: filter.updatedByChecked ? filter.updatedBy : '',
    }
    dispatch(setFilterProp(filterText))
    dispatch(setFilterTotal(filter))
    dispatch(resetTablePage())
    dispatch(
      fetchDataList({
        method: 'filter',
        table,
        manageProps,
        filterProp: filterText,
        page: 1,
      }),
    )
  }

export const handleQuickSearch =
  ({ table, text, manageProps }) =>
  (dispatch) => {
    dispatch(setSearchText(text))
    dispatch(setFilterProp(null))
    dispatch(resetTablePage())
    dispatch(
      fetchDataList({
        method: 'search',
        table,
        quickSeach: text,
        manageProps,
        page: 1,
      }),
    )
  }
