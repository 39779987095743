import React from 'react'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'

import SelectGroupChip from '../../../components/SelectGroupChip'
import CloneInput from './CloneInput'

const WorkingExperience = (props) => {
  const {
    formik,
    initialValues,
    onSelectGroupChipChange,
    experiencedOptions,
    productTypeOptions,
    instituteOptions,
    institute,
    checkPermission,
    validateForm,
    qualificationOption,
    IsNonAcademy,
  } = props

  return (
    <>
      <Card
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          minWidth: 275,
          pt: 2,
          px: 3,
        }}
      >
        <Typography variant="h6">ประสบการณ์</Typography>
        {!IsNonAcademy && window.__env__.ENABLE_SKILL_MAPPING && (
          <>
            <Box sx={{ display: 'flex', flexDirection: 'row', gap: 3, mt: 2 }}>
              <SelectGroupChip
                id="productType"
                name="productType"
                labelText="Skill Mapping"
                type="basic"
                disabled={!checkPermission ? true : false}
                textError={formik?.errors?.productType}
                placeholder={'โปรดระบุ'}
                options={productTypeOptions}
                onChange={(list) =>
                  onSelectGroupChipChange(list, 'productType')
                }
                value={initialValues?.productType}
              ></SelectGroupChip>
            </Box>
            <Divider />
          </>
        )}

        <Typography sx={{ mt: 2 }} variant="body2b">
          การศึกษาระดับสูงสุด
        </Typography>
        <Box sx={{ flexDirection: 'column', display: 'flex', mb: 2 }}>
          <CloneInput
            formik={formik}
            typeInput={'educational'}
            qualificationOption={qualificationOption}
          />
        </Box>
        {!IsNonAcademy && (
          <>
            <Divider />
            <Typography sx={{ mt: 2 }} variant="body2b">
              ประวัติการทำงาน
            </Typography>
            <Box sx={{ flexDirection: 'column', display: 'flex', mb: 2 }}>
              <CloneInput formik={formik} typeInput={'workHistory'} />
            </Box>
            <Divider />
            <Typography sx={{ mt: 2 }} variant="body2b">
              สรุปประสบการณ์การทำงาน
            </Typography>
            <Box sx={{ flexDirection: 'column', display: 'flex', mb: 2 }}>
              <CloneInput
                formik={formik}
                typeInput={'experienced'}
                experiencedOptions={experiencedOptions}
              />
            </Box>
            <Divider />
            <Typography sx={{ mt: 2 }} variant="body2b">
              ประสบการณ์ด้านฝึกอบรมและการพัฒนา
            </Typography>
            <Box sx={{ flexDirection: 'column', display: 'flex', mb: 2 }}>
              <CloneInput formik={formik} typeInput={'training'} />
            </Box>
            <Divider />
            <Typography sx={{ mt: 2 }} variant="body2b">
              ประสบการณ์ด้านการเป็นวิทยากรในธุรกิจประกันชีวิต
            </Typography>
            <Box sx={{ flexDirection: 'column', display: 'flex', mb: 2 }}>
              <CloneInput formik={formik} typeInput={'lecturer'} />
            </Box>
            <Divider />
            <Typography sx={{ mt: 2 }} variant="body2b">
              คุณวุฒิวิชาชีพ และ ใบอนุญาต (Certified Program)
            </Typography>
            <Box sx={{ flexDirection: 'column', display: 'flex', mb: 2 }}>
              <CloneInput
                formik={formik}
                typeInput={'institute'}
                instituteOptions={instituteOptions}
                institute={institute}
                validateForm={validateForm}
              />
            </Box>
          </>
        )}
      </Card>
    </>
  )
}
export default WorkingExperience
