import { listAPIFetchMasterDataOptions } from '../../../constants/report/listAPIFetchMasterDataOptions'
import { store } from '../../../App'
import _ from 'lodash'
import axios from 'axios'
import { getOptionsKey } from '../../../utils/lib'

export const fetchMasterDataOptions = async () => {
  const { columnSelected, columnName } = store.getState().reportComp

  const {
    method,
    url,
    body,
    headers = {},
  } = _.find(
    listAPIFetchMasterDataOptions,
    (item) => _.get(item, 'name') === _.get(columnSelected, 'masterDataName'),
  )

  return axios({ method: method, url: url, data: body, headers: headers }).then(
    (res) =>
      getOptionsKey({
        data:
          _.get(columnSelected, 'name') === columnName.DEPARTMENT
            ? _.get(res, 'data', [])
            : _.get(res, 'data.result', []),
        label:
          _.get(columnSelected, 'name') === columnName.ABBREVIATION_ROADMAP ||
          _.get(columnSelected, 'name') === columnName.ABBREVIATION_DISTRIBUTION
            ? 'abbreviation'
            : _.get(columnSelected, 'name') === columnName.TRAINER_NAME
            ? 'fullnameTHManageClass'
            : _.get(columnSelected, 'name') === columnName.STAFF_ID ||
              _.get(columnSelected, 'name') === columnName.HOLDER_STAFF_ID
            ? 'trainerStaffID'
            : _.get(columnSelected, 'name') === columnName.TRAINER_STAFF_ID
            ? 'trainerStaffID'
            : _.get(columnSelected, 'name') === columnName.TRAINER_ID
            ? 'agentCode'
            : _.get(columnSelected, 'name') === columnName.DEPARTMENT ||
              _.get(columnSelected, 'name') === columnName.AREA
            ? 'department'
            : _.get(columnSelected, 'name') === columnName.TRAINER_ID_LIST ||
              _.get(columnSelected, 'name') === columnName.STAFF_NAME_TH ||
              _.get(columnSelected, 'name') === columnName.HOLDER_NAME ||
              _.get(columnSelected, 'name') === columnName.PAYER ||
              _.get(columnSelected, 'name') === columnName.CREATED_BY ||
              _.get(columnSelected, 'name') === columnName.UPDATED_BY
            ? 'fullName'
            : _.get(columnSelected, 'name') === columnName.CATEGORY ||
              _.get(columnSelected, 'name') === columnName.SUB_CATEGORY ||
              _.get(columnSelected, 'name') === columnName.TAG
            ? 'name'
            : _.get(columnSelected, 'name') === columnName.STATION_NAME
            ? 'station'
            : _.get(columnSelected, 'name') === columnName.OLD_ACCOUNT_CODE
            ? 'oldAccountCode'
            : _.get(columnSelected, 'name') === columnName.NEW_ACCOUNT_CODE
            ? 'newAccountCode'
            : _.get(columnSelected, 'name') === columnName.DESCRIPTION
            ? 'description'
            : _.get(columnSelected, 'name') === columnName.COST_CENTER_NAME
            ? 'descriptionName'
            : _.get(columnSelected, 'masterDataName'),
        key:
          _.get(columnSelected, 'name') === columnName.COURSE_LEVEL_CONTROL
            ? 'value'
            : _.get(columnSelected, 'name') === columnName.LICENSE_CATEGORY
            ? 'category'
            : _.get(columnSelected, 'name') === columnName.LICENSE_NAME ||
              _.get(columnSelected, 'name') === columnName.LICENSE_DESCRIPTION
            ? 'description'
            : _.get(columnSelected, 'name') === columnName.CATEGORY ||
              _.get(columnSelected, 'name') === columnName.SUB_CATEGORY
            ? 'name'
            : _.get(columnSelected, 'name') === columnName.STATION_NAME
            ? 'station'
            : _.get(columnSelected, 'name') ===
              columnName.DISTRIBUTION_CHANNELS_FILTER
            ? 'distribution'
            : _.get(columnSelected, 'name') ===
              columnName.COST_CENTER_NAME_FILTER
            ? 'descriptionName'
            : _.get(columnSelected, 'name') === columnName.COST_CENTER_FILTER
            ? 'costCenter'
            : 'uuid',
      }),
  )
}
