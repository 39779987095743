import { store } from '../../../App'
import {
  setColumnSelected,
  setReduxValue,
} from '../../../redux/slices/reportComp'
import _ from 'lodash'
import { TYPE_OF_VALUE } from '../../../constants/reportCourseSetting/reportCourseSetting'
import { object } from 'yup'
import dayjs from 'dayjs'
import {
  validateValueTypeArray,
  validateValueTypeNumberRange,
  validateValueTypeObj,
  validateValueTypeString,
  validateValueTypeTime,
  validateValueTypeYMD,
} from '../model'
import { handleFilterColumnMixModule } from './handleFilterColumnMixModule'

export const handleAddFilter = () => async (dispatch) => {
  const { displayFilters, columnSelected, value, options, reportConfig } =
    store.getState().reportComp
  const { moduleId } = reportConfig

  let formattedValue = value
  let optionSelected = []
  let label = ''
  if (columnSelected.type === TYPE_OF_VALUE.DATE_RANGE) {
    const { startDate, endDate } = value
    label = `${dayjs(startDate).format('DD/MM/YYYY')} -  ${dayjs(
      endDate,
    ).format('DD/MM/YYYY')}`
  } else if (columnSelected.type === TYPE_OF_VALUE.INPUT_TIME) {
    const { hour, minute } = value
    label = `${hour || 0} ชั่วโมง ${minute || 0} นาที`
  } else if (columnSelected.type === TYPE_OF_VALUE.INPUT_YMD) {
    const { year, month, day } = value
    label = `${year || 0} ปี ${month || 0} เดือน ${day || 0} วัน`
  } else if (columnSelected.type === TYPE_OF_VALUE.INPUT_NUMBER_RANGE) {
    const { start, end } = value
    label = `${start} -  ${end}`
  } else if (
    columnSelected.type === TYPE_OF_VALUE.INPUT_NUMBER ||
    columnSelected.type === TYPE_OF_VALUE.INPUT_TEXT_BOX ||
    columnSelected.type === TYPE_OF_VALUE.INPUT_TEXT
  ) {
    label = value
    if (columnSelected.value == 'workDistance') {
      label = label + ' ปี'
    }
    if (
      ['trainerIdList', 'createdBy', 'updatedBy'].includes(
        columnSelected?.value,
      ) &&
      moduleId === 'REPORT_MIX_MODULE'
    ) {
      optionSelected = _.filter(options, (opt) => opt.value === value)
      label = _.get(optionSelected, '[0].label', '')
    }
  } else if (columnSelected.type === TYPE_OF_VALUE.DROPDOWN_SINGLE) {
    optionSelected = _.filter(options, (opt) => opt.value === value)
    label = _.get(optionSelected, '[0].label', '')
  } else if (columnSelected.type === TYPE_OF_VALUE.DROPDOWN_MULTI) {
    formattedValue = _.map(value, (val) => val.value)
    label = _.reduce(
      formattedValue,
      (acc, val, idx) => {
        let label = acc
        _.map(options, (opt) => {
          if (val === opt.value) {
            label += opt.label
            if (idx !== formattedValue.length - 1)
              label += [
                'courseNameEN',
                'courseNameTH',
                'courseNameForLearner',
              ].includes(columnSelected.value)
                ? ',&&'
                : ', '
          }
        })
        return label
      },
      '',
    )
  }

  const id =
    displayFilters.length > 0
      ? +displayFilters[displayFilters.length - 1].id + 1
      : 1
  let newFilter = {
    id: id,
    key: columnSelected.value,
    title: columnSelected.label,
    value: formattedValue,
    typeOfValue: columnSelected.type,
    label: label,
  }
  const validationResults = await validationFilter(
    newFilter,
    columnSelected.type,
  )

  if (_.isEmpty(validationResults.inner)) {
    if (columnSelected.type === TYPE_OF_VALUE.INPUT_TIME) {
      newFilter = {
        ...newFilter,
        value: {
          ...newFilter.value,
          hour: newFilter.value?.hour || 0,
          minute: newFilter.value?.minute || 0,
        },
      }
    }
    if (columnSelected.type === TYPE_OF_VALUE.INPUT_YMD) {
      newFilter = {
        ...newFilter,
        value: {
          ...newFilter.value,
          year: newFilter.value?.year || 0,
          month: newFilter.value?.month || 0,
          day: newFilter.value?.day || 0,
        },
      }
    }
    if (
      columnSelected.type === TYPE_OF_VALUE.DATE_RANGE &&
      ['AUDIT_LOG_BACKEND', 'AUDIT_LOG_LEARNER'].includes(moduleId)
    ) {
      const { startDate } = value
      await dispatch(
        setReduxValue({ key: 'year', value: +dayjs(startDate).format('YYYY') }),
      )
    }
    const newFilters = [...displayFilters, newFilter]
    await dispatch(setReduxValue({ key: 'displayFilters', value: newFilters }))
    await dispatch(setColumnSelected(null))
    await dispatch(setReduxValue({ key: 'options', value: [] }))
    if (
      moduleId === 'REPORT_MIX_MODULE' &&
      ['courseNameEN', 'courseNameTH', 'courseNameForLearner'].includes(
        columnSelected.value,
      )
    ) {
      await dispatch(
        handleFilterColumnMixModule(
          columnSelected.value,
          formattedValue.length,
        ),
      )
    }
  } else {
    dispatch(setReduxValue({ key: 'error', value: validationResults.message }))
  }
}

const validationFilter = async (filter, typeOfValue) => {
  let validateSchema = object().shape({
    value:
      typeOfValue !== TYPE_OF_VALUE.DROPDOWN_MULTI
        ? typeOfValue !== TYPE_OF_VALUE.DATE_RANGE
          ? typeOfValue !== TYPE_OF_VALUE.INPUT_NUMBER_RANGE
            ? typeOfValue !== TYPE_OF_VALUE.INPUT_TIME
              ? typeOfValue !== TYPE_OF_VALUE.INPUT_YMD
                ? validateValueTypeString
                : validateValueTypeYMD
              : validateValueTypeTime
            : validateValueTypeNumberRange
          : validateValueTypeObj
        : validateValueTypeArray,
  })

  return await validateSchema
    .validate(filter, { abortEarly: false })
    .catch((e) => e)
}
