import { createListenerMiddleware, isAnyOf } from '@reduxjs/toolkit'
import _ from 'lodash'
import {
  setIsFilter,
  setValue,
  setColumnSelected,
  setIsOpenFilterDrawer,
  setIsOpenManageColumnDrawer,
  setReduxValue,
  setFiltersData,
} from '.'
import {
  handleBodyFilters,
  handleColumnOptions,
  handleDisabledColumnOptions,
} from './event'

export const listenerMiddlewareReportComp = createListenerMiddleware()

listenerMiddlewareReportComp.startListening({
  matcher: isAnyOf(setIsOpenFilterDrawer, setIsOpenManageColumnDrawer),
  effect: async (action, listenerApi) => {
    const { isOpenFilterDrawer, filters, isOpenManageColumnDrawer, columns } =
      listenerApi.getState().reportComp
    listenerApi.dispatch(handleColumnOptions())
    if (isOpenFilterDrawer) {
      listenerApi.dispatch(
        setReduxValue({ key: 'displayFilters', value: filters }),
      )
    }

    if (isOpenManageColumnDrawer) {
      listenerApi.dispatch(
        setReduxValue({ key: 'displayColumns', value: columns }),
      )
      listenerApi.dispatch(handleDisabledColumnOptions())
      listenerApi.dispatch(
        setReduxValue({ key: 'tabActiveColDrawer', value: 0 }),
      )
    }
    listenerApi.dispatch(setColumnSelected(null))
  },
})

listenerMiddlewareReportComp.startListening({
  actionCreator: setColumnSelected,
  effect: async (action, listenerApi) => {
    const { columnSelected } = listenerApi.getState().reportComp
    let options = []
    if (_.get(columnSelected, 'options')) {
      options = _.get(columnSelected, 'options')
    } else if (_.get(columnSelected, 'fetchOptions')) {
      options = await columnSelected.fetchOptions()
    }

    options = _.filter(
      options,
      (opt) => !_.isEmpty(opt.label) && !_.isEmpty(opt.value),
    )

    listenerApi.dispatch(
      setReduxValue({
        key: 'options',
        value: options,
      }),
    )
    listenerApi.dispatch(setValue(null))
    listenerApi.dispatch(setReduxValue({ key: 'error', value: null }))
  },
})

listenerMiddlewareReportComp.startListening({
  matcher: isAnyOf(setValue, setColumnSelected),
  effect: async (action, listenerApi) => {
    const { value, columnSelected, isOpenFilterDrawer } =
      listenerApi.getState().reportComp

    let disabledAddBtn = true
    if (isOpenFilterDrawer) {
      disabledAddBtn =
        _.isNull(value) || value.length === 0 || _.isNil(columnSelected)
      if (columnSelected?.type === 'INPUT_NUMBER_RANGE') {
        disabledAddBtn =
          disabledAddBtn ||
          _.isNil(value?.start) ||
          _.isNil(value?.end) ||
          value?.start === '' ||
          value?.end === '' ||
          +value?.start > +value?.end
        if (
          !_.isNil(value?.start) &&
          !_.isNil(value?.end) &&
          value?.start !== '' &&
          value?.end !== '' &&
          +value?.start > +value?.end
        ) {
          listenerApi.dispatch(
            setReduxValue({ key: 'error', value: 'กรุณากรอกข้อมูลให้ถูกต้อง' }),
          )
        } else {
          listenerApi.dispatch(setReduxValue({ key: 'error', value: null }))
        }
      } else if (columnSelected?.type === 'INPUT_TIME') {
        disabledAddBtn =
          disabledAddBtn ||
          ((_.isNil(value?.hour) || value?.hour === '') &&
            (_.isNil(value?.minute) || value?.minute === ''))
      } else if (columnSelected?.type === 'INPUT_YMD') {
        disabledAddBtn =
          disabledAddBtn ||
          ((_.isNil(value?.year) || value?.year === '') &&
            (_.isNil(value?.month) || value?.month === '') &&
            (_.isNil(value?.day) || value?.day === ''))
      }
    }

    listenerApi.dispatch(
      setReduxValue({ key: 'disabledAddBtn', value: disabledAddBtn }),
    )
  },
})

listenerMiddlewareReportComp.startListening({
  actionCreator: setIsFilter,
  effect: async (action, listenerApi) => {
    const { isFilter } = listenerApi.getState().reportComp
    if (isFilter) {
      listenerApi.dispatch(setReduxValue({ key: 'tablePage', value: 0 }))
      listenerApi.dispatch(setReduxValue({ key: 'page', value: -1 }))
      listenerApi.dispatch(
        setReduxValue({
          key: 'rowsPerPage',
          value: { label: '100', value: 100 },
        }),
      )
      listenerApi.dispatch(setIsFilter(false))
    }
  },
})

listenerMiddlewareReportComp.startListening({
  actionCreator: setColumnSelected,
  effect: async (action, listenerApi) => {
    const {
      value,
      columnSelected,
      isOpenFilterDrawer,
      isOpenManageColumnDrawer,
      tabActiveColCard,
      firstColumnSelected,
      secondaryColumnSelected,
      newColumnName,
    } = listenerApi.getState().reportComp

    let disabledAddBtn = true
    if (isOpenFilterDrawer) {
      disabledAddBtn =
        _.isNull(value) || value.length === 0 || _.isNull(columnSelected)
    } else if (isOpenManageColumnDrawer) {
      disabledAddBtn =
        tabActiveColCard === 0
          ? _.isNull(columnSelected)
          : _.isNull(firstColumnSelected) ||
            _.isNull(secondaryColumnSelected) ||
            _.isNull(newColumnName)
    }

    listenerApi.dispatch(
      setReduxValue({ key: 'disabledAddBtn', value: disabledAddBtn }),
    )
  },
})

listenerMiddlewareReportComp.startListening({
  actionCreator: setReduxValue,
  effect: async (action, listenerApi) => {
    const {
      payload: { key },
    } = action

    if (
      key !== 'newColumnName' &&
      key !== 'firstColumnSelected' &&
      key !== 'secondaryColumnSelected'
    )
      return
    const { firstColumnSelected, secondaryColumnSelected, newColumnName } =
      listenerApi.getState().reportComp

    listenerApi.dispatch(
      setReduxValue({
        key: 'disabledAddBtn',
        value:
          _.isNull(firstColumnSelected) ||
          _.isNull(secondaryColumnSelected) ||
          _.isNull(newColumnName),
      }),
    )
  },
})

listenerMiddlewareReportComp.startListening({
  actionCreator: setReduxValue,
  effect: async (action, listenerApi) => {
    const {
      payload: { key },
    } = action
    if (key !== 'tabActiveColCard') return

    listenerApi.dispatch(setColumnSelected(null))
    listenerApi.dispatch(
      setReduxValue({
        key: 'newColumnName',
        value: null,
      }),
    )
    listenerApi.dispatch(
      setReduxValue({
        key: 'firstColumnSelected',
        value: null,
      }),
    )
    listenerApi.dispatch(
      setReduxValue({
        key: 'secondaryColumnSelected',
        value: null,
      }),
    )
  },
})

listenerMiddlewareReportComp.startListening({
  actionCreator: setFiltersData,
  effect: async (action, listenerApi) => {
    const bodyFilters = handleBodyFilters()
    listenerApi.dispatch(
      setReduxValue({ key: 'bodyFilters', value: bodyFilters }),
    )
  },
})
