import { useState, useEffect } from 'react'
import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import _ from 'lodash'
import dayjs from 'dayjs'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import { TYPE_OF_VALUE } from '../../../../constants/reportCourseSetting/reportCourseSetting'
import TextInput from '../../../Input/TextInput'
import DateRangeInput from '../../../DateRangeInput'
import SelectGroupChip from '../../../SelectGroupChip'
import Autocomplete from '../../../Input/Autocomplete'
import Select from '../../../Input/Select'
import { handleChangeFilterValue } from '../../handler/handleChangeFilterValue'
import { handleNumberInput } from '../../handler/handleNumberInput'

const ValueOfColumn = () => {
  const [limitDate, setLimitDate] = useState({ minDate: '', maxDate: '' })
  const dispatch = useDispatch()
  const { columnSelected, value, error, options, moduleId } = useSelector(
    (state) => ({
      columnSelected: state.reportComp.columnSelected,
      value: state.reportComp.value,
      error: state.reportComp.error,
      options: state.reportComp.options,
      moduleId: state.reportComp.reportConfig.moduleId,
    }),
    shallowEqual,
  )
  useEffect(() => {
    if (
      ['AUDIT_LOG_BACKEND', 'AUDIT_LOG_LEARNER'].includes(moduleId) &&
      columnSelected?.type === TYPE_OF_VALUE.DATE_RANGE
    ) {
      if (value?.startDate) {
        const minDate = dayjs(value.startDate)
          .startOf('year')
          .format('YYYY-MM-DD')
        const maxDate = dayjs(value.startDate)
          .endOf('year')
          .format('YYYY-MM-DD')
        setLimitDate({
          minDate: minDate,
          maxDate: maxDate,
        })
      } else {
        setLimitDate({
          minDate: '',
          maxDate: '',
        })
      }
    }
  }, [columnSelected, value])
  return (
    <Box sx={{ width: '50%' }}>
      {columnSelected?.type === TYPE_OF_VALUE.DATE_RANGE && (
        <DateRangeInput
          labelText="ข้อมูล"
          placeholder="เลือกวันที่"
          dateState={{
            startDate: !value?.startDate ? new Date() : value?.startDate,
            endDate: !value?.endDate ? new Date() : value?.endDate,
            display: false,
            key: 'selection',
          }}
          onChange={(selectedDates) => {
            const startDate = dayjs(
              _.get(selectedDates, 'startDate', ''),
            ).format('YYYY-MM-DD')
            const endDate = dayjs(_.get(selectedDates, 'endDate', '')).format(
              'YYYY-MM-DD',
            )
            dispatch(
              handleChangeFilterValue({
                startDate: startDate,
                endDate: endDate,
              }),
            )
          }}
          minDate={
            limitDate?.minDate ? new Date(limitDate?.minDate) : undefined
          }
          maxDate={
            limitDate?.maxDate ? new Date(limitDate?.maxDate) : undefined
          }
        />
      )}

      {columnSelected?.type === TYPE_OF_VALUE.INPUT_TIME && (
        <>
          <TextInput
            sxTextError={{ mb: 0 }}
            type="basic"
            name="value"
            labelText="ชั่วโมง"
            textError={error}
            isShowTextError={false}
            value={value?.hour || ''}
            inputProps={{
              type: 'text',
              onInput: (e) => {
                if (columnSelected?.type === TYPE_OF_VALUE.INPUT_TIME)
                  handleNumberInput(e)
              },
            }}
            onChange={(event) => {
              const hour = _.get(event, 'target.value', null)
              dispatch(handleChangeFilterValue({ ...value, hour }))
            }}
          />
          <TextInput
            sxTextError={{ mb: 0 }}
            type="basic"
            name="value"
            labelText="นาที"
            textError={error}
            isShowTextError={!_.isNull(error)}
            value={value?.minute || ''}
            inputProps={{
              type: 'text',
              onInput: (e) => {
                if (columnSelected?.type === TYPE_OF_VALUE.INPUT_TIME)
                  handleNumberInput(e)
              },
            }}
            onChange={(event) => {
              const minute = _.get(event, 'target.value', null)
              dispatch(handleChangeFilterValue({ ...value, minute }))
            }}
          />
        </>
      )}

      {(columnSelected?.type === TYPE_OF_VALUE.INPUT_NUMBER ||
        _.isNil(columnSelected)) && (
        <TextInput
          sxTextError={{ mb: 0 }}
          type="basic"
          name="value"
          labelText="ข้อมูล"
          disabled={_.isNil(columnSelected)}
          textError={error}
          isShowTextError={!_.isNull(error)}
          value={value || ''}
          inputProps={{
            type: 'text',
            onInput: (e) => {
              if (columnSelected?.type === TYPE_OF_VALUE.INPUT_NUMBER)
                handleNumberInput(e)
            },
          }}
          onChange={(event) => {
            const value = _.get(event, 'target.value', null)
            dispatch(handleChangeFilterValue(value))
          }}
        />
      )}

      {columnSelected?.type === TYPE_OF_VALUE.INPUT_NUMBER_RANGE && (
        <>
          <TextInput
            sxTextError={{ mb: 0 }}
            type="basic"
            name="value"
            labelText="ข้อมูล"
            textError={error}
            isShowTextError={false}
            value={value?.start || ''}
            inputProps={{
              type: 'text',
              onInput: (e) => {
                if (columnSelected?.type === TYPE_OF_VALUE.INPUT_NUMBER_RANGE)
                  handleNumberInput(e)
              },
            }}
            onChange={(event) => {
              const start = _.get(event, 'target.value', null)
              dispatch(handleChangeFilterValue({ ...value, start }))
            }}
          />
          <TextInput
            sxTextError={{ mb: 0 }}
            type="basic"
            name="value"
            labelText="ถึง"
            textError={error}
            isShowTextError={!_.isNull(error)}
            value={value?.end || ''}
            inputProps={{
              type: 'text',
              onInput: (e) => {
                if (columnSelected?.type === TYPE_OF_VALUE.INPUT_NUMBER_RANGE)
                  handleNumberInput(e)
              },
            }}
            onChange={(event) => {
              const end = _.get(event, 'target.value', null)
              dispatch(handleChangeFilterValue({ ...value, end }))
            }}
          />
        </>
      )}

      {columnSelected?.type === TYPE_OF_VALUE.INPUT_TEXT_BOX && (
        <TextInput
          sxTextError={{ mb: 0 }}
          type="basic"
          name="value"
          labelText="ข้อมูล"
          textError={error}
          isShowTextError={!_.isNull(error)}
          value={value || ''}
          inputProps={{
            type: 'text',
          }}
          onChange={(event) => {
            const value = _.get(event, 'target.value', null)
            dispatch(handleChangeFilterValue(value))
          }}
        />
      )}

      {columnSelected?.type === TYPE_OF_VALUE.INPUT_TEXT && (
        <Autocomplete
          id="value"
          name="value"
          labelText="ข้อมูล"
          options={options}
          defaultValue={{
            value: value,
            label:
              ['trainerIdList', 'createdBy', 'updatedBy'].includes(
                columnSelected?.value,
              ) && moduleId === 'REPORT_MIX_MODULE'
                ? _.filter(options, (opt) => opt.value === value)?.[0]?.label ||
                  ''
                : value,
          }}
          isHideSort={true}
          renderInput={(params) => (
            <TextField placeholder="กรุณาเลือก" {...params} />
          )}
          handleChange={(e, obj) => {
            dispatch(
              handleChangeFilterValue(
                ['trainerIdList', 'createdBy', 'updatedBy'].includes(
                  columnSelected?.value,
                ) && moduleId === 'REPORT_MIX_MODULE'
                  ? obj?.value || ''
                  : obj?.label || '',
              ),
            )
          }}
        />
      )}

      {columnSelected?.type === TYPE_OF_VALUE.DROPDOWN_MULTI && (
        <SelectGroupChip
          boxSx={{ mb: 3 }}
          id="value"
          name="value"
          labelText="ข้อมูล"
          placeholder="กรุณาเลือก"
          options={options}
          value={value}
          onChange={(list) => dispatch(handleChangeFilterValue(list))}
          textError={error}
          isOptionDisabled={columnSelected?.isOptionDisabled}
        />
      )}

      {columnSelected?.type === TYPE_OF_VALUE.DROPDOWN_SINGLE && (
        <Select
          dataTestid="select-value"
          id="value"
          name="value"
          placeholder="กรุณาเลือก"
          labelText="ข้อมูล"
          options={options}
          value={!_.isNil(value) ? value : ''}
          defaultValue={value}
          handleChange={(e) => {
            const value = _.get(e, 'target.value', '')
            dispatch(handleChangeFilterValue(value))
          }}
        />
      )}

      {columnSelected?.type === TYPE_OF_VALUE.INPUT_YMD && (
        <>
          <TextInput
            sxTextError={{ mb: 0 }}
            type="basic"
            name="value"
            labelText="ปี"
            textError={error}
            isShowTextError={false}
            value={value?.year || ''}
            inputProps={{
              type: 'text',
              onInput: (e) => {
                if (columnSelected?.type === TYPE_OF_VALUE.INPUT_YMD)
                  handleNumberInput(e)
              },
            }}
            onChange={(event) => {
              const year = _.get(event, 'target.value', null)
              dispatch(handleChangeFilterValue({ ...value, year }))
            }}
          />
          <TextInput
            sxTextError={{ mb: 0 }}
            type="basic"
            name="value"
            labelText="เดือน"
            textError={error}
            isShowTextError={!_.isNull(error)}
            value={value?.month || ''}
            inputProps={{
              type: 'text',
              onInput: (e) => {
                if (columnSelected?.type === TYPE_OF_VALUE.INPUT_YMD)
                  handleNumberInput(e)
              },
            }}
            onChange={(event) => {
              const month = _.get(event, 'target.value', null)
              dispatch(handleChangeFilterValue({ ...value, month }))
            }}
          />
          <TextInput
            sxTextError={{ mb: 0 }}
            type="basic"
            name="value"
            labelText="วัน"
            textError={error}
            isShowTextError={!_.isNull(error)}
            value={value?.day || ''}
            inputProps={{
              type: 'text',
              onInput: (e) => {
                if (columnSelected?.type === TYPE_OF_VALUE.INPUT_YMD)
                  handleNumberInput(e)
              },
            }}
            onChange={(event) => {
              const day = _.get(event, 'target.value', null)
              dispatch(handleChangeFilterValue({ ...value, day }))
            }}
          />
        </>
      )}
    </Box>
  )
}

export default ValueOfColumn
