import { Box } from '@mui/material'
import React, { useEffect } from 'react'
import Table from '../../../../components/redux/Table'
import { initialize } from '../handler/initialize'
import { useSelector, shallowEqual } from 'react-redux'

function PermissionCostCenterTable() {
  const { data } = useSelector(
    (state) => ({
      data: state.permission.data,
    }),
    shallowEqual,
  )

  useEffect(initialize, [data.costCenter])

  return (
    <Box mx={3}>
      <Table />
    </Box>
  )
}

export default PermissionCostCenterTable
