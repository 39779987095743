import { expense, expenseAll } from '../../utils/apiPath'
import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

export const getExpenseInMonthlyPlanAll = createAsyncThunk(
  'expense/getExpenseInMonthlyPlanAll',
  async ({ monthlyPlanId, monthlyPlanUuid }, { rejectWithValue }) => {
    try {
      const response = await axios.post(expenseAll, {
        monthlyPlanId: monthlyPlanId,
        monthlyPlan: monthlyPlanUuid,
      })
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const createExpense = createAsyncThunk(
  'expense/createExpense',
  async (bodyProps, { rejectWithValue }) => {
    try {
      const response = await axios.post(expense, bodyProps)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const updateExpense = createAsyncThunk(
  'expense/updateExpense',
  async (bodyProps, { rejectWithValue }) => {
    try {
      const response = await axios.put(expense, bodyProps)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const deleteExpense = createAsyncThunk(
  'expense/deleteExpense',
  async ({ uuidExpList }, { rejectWithValue }) => {
    try {
      const response = await axios.delete(`${expense}`, {
        data: { uuidExpList: uuidExpList },
      })
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const fetchDashboardExpense = createAsyncThunk(
  'expense/fetchDashboardExpense',
  async (bodyProps, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${expense}/dashboard`, bodyProps)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)
