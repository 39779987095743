import { Box } from '@mui/material'
import TabsOverview from './TabsOverview'
import { getDefaultFiltersDashboard } from '../handler/getDefaultFiltersDashboard'
import { useEffect } from 'react'
import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import {
  setFiltersDashboard,
  setReduxDashboardByKey,
} from 'src/redux/slices/monthlyPlan'
import FiltersSummary from '../components/FiltersSummary'
import Header from '../components/Header'
import FilterDrawer from '../components/FilterDrawer'
import { LoadingPage } from '../ClassDashboard/Styled'
import StaffDrawer from './components/StaffDrawer'
import HelperDrawer from './components/HelperDrawer'

const WorkloadDashboard = () => {
  const dispatch = useDispatch()
  const { isLoading } = useSelector(
    (state) => ({
      isLoading: state.monthlyPlan.dashboard.isLoading,
    }),
    shallowEqual,
  )

  useEffect(async () => {
    await dispatch(setReduxDashboardByKey({ key: 'name', value: 'workload' }))

    const defaultFilters = await getDefaultFiltersDashboard()
    dispatch(setFiltersDashboard(defaultFilters))
  }, [])

  return (
    <Box
      sx={{
        opacity: isLoading ? 0.4 : 'unset',
        pointerEvents: isLoading ? 'none' : 'unset',
      }}
      mx={3}
      display="flex"
      flexDirection="column"
      gap={3}
    >
      <Box display="flex" flexDirection="column" gap={1}>
        <Header />
        <FiltersSummary />
      </Box>
      <TabsOverview />
      <FilterDrawer />
      <StaffDrawer />
      <HelperDrawer />
      <LoadingPage isLoading={isLoading} />
    </Box>
  )
}

export default WorkloadDashboard
