import _ from 'lodash'
import {
  setChangeDocumentList,
  setFieldValue,
} from '../../../../../redux/slices/manageClassDocument'
import {
  putLearnerDocument,
  uploadLearnerDocument,
} from '../../../../../services/manageClass'
import {
  startLoading,
  stopLoading,
} from '../../../../../redux/slices/manageClassDocument'
import { closeDialog, openDialog } from '../../../../../redux/slices/dialog'

export const onUploadDocument =
  (
    e,
    indexDocument,
    fileName,
    editUser,
    document,
    documentList,
    classLearnerDocumentUuid,
    trainingPlanUuid,
    classLearnerUuid,
    round,
  ) =>
  async (dispatch) => {
    dispatch(startLoading())

    const body = new FormData()
    body.append('file', e.target.files[0])
    const fileData = await dispatch(
      uploadLearnerDocument({ body: body, name: fileName }),
    )

    const newDocument = {
      uuid: document.uuid ?? undefined,
      status: document.status ?? 'WAITING',
      title: _.get(fileData, 'payload.name', fileName),
      key: _.get(fileData, 'payload.key', 0),
      updatedBy: editUser,
      updatedAt: new Date(),
    }
    const newDocumentList = [...documentList]
    newDocumentList.splice(indexDocument, 1, newDocument)
    const res = await dispatch(
      putLearnerDocument({
        body: {
          learnerDocument: newDocumentList,
          classLearnerUuid: classLearnerUuid,
          classLearnerDocumentUuid: classLearnerDocumentUuid,
          trainingPlanUuid: trainingPlanUuid,
          round: round,
        },
      }),
    )
    if (
      res.payload?.message === 'failed' &&
      res.payload?.reason === 'canceled'
    ) {
      dispatch(
        openDialog({
          type: 'fail',
          title: 'ไม่สามารถดำเนินการได้ ',
          message: 'เนื่องถูกยกเลิกการสมัครแล้ว',
          handleConfirm: () => {
            dispatch(closeDialog())
          },
        }),
      )
    } else {
      dispatch(setFieldValue({ key: 'uploadDoc', value: newDocumentList }))
    }
    dispatch(stopLoading())
  }

export const handleDelete =
  (
    indexDocument,
    title,
    handleClose,
    editUser,
    document,
    documentList,
    classLearnerDocumentUuid,
    trainingPlanUuid,
    round,
    classLearnerUuid,
  ) =>
  async (dispatch) => {
    dispatch(startLoading())
    const newDocument = {
      uuid: document.uuid ?? undefined,
      status: document.status ?? 'WAITING',
      title: title,
      key: '',
      updatedBy: editUser,
      updatedAt: new Date(),
    }
    const newDocumentList = [...documentList]
    newDocumentList.splice(indexDocument, 1, newDocument)
    handleClose()
    await dispatch(
      putLearnerDocument({
        body: {
          learnerDocument: newDocumentList,
          classLearnerDocumentUuid: classLearnerDocumentUuid,
          trainingPlanUuid: trainingPlanUuid,
          round: round,
          classLearnerUuid: classLearnerUuid,
        },
      }),
    )
    dispatch(setFieldValue({ key: 'uploadDoc', value: newDocumentList }))

    dispatch(stopLoading())
  }

export const mapCheckedByStatus = (round, status) => {
  if (round < 0) return false
  if (round === 0) return true
  return status === 'REJECTED' ? true : false
}

export const handleChecked = (props) => (dispatch) => {
  dispatch(setChangeDocumentList(props))
}

export const findUuid = (list, uuid) => {
  if (!list.length) return false
  return list.find((item) => item === uuid)
}

export const mapColor = (label) => {
  switch (label) {
    case 'รอเอกสาร':
      return 'warning.dark'
    case 'รอตรวจสอบ':
      return 'warning.main'
    case 'ผ่านการตรวจสอบ':
      return 'success.main'
    case 'รอคอนเฟิร์มตัวแทน':
      return 'info.main'
    default:
      return 'error.main'
  }
}

export const labelText = (round, status) => {
  if (round === 0 && status !== 'ผ่านการตรวจสอบ') return `รอเอกสาร`
  return `เอกสาร (ครั้งที่ ${round})`
}

export const downloadImageDocument = async (title, url, type) => {
  const a = document.createElement('a')
  a.href = await toDataURL(url)
  a.download = `${title}.${type}`
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
}

export const toDataURL = async (url) => {
  const blob = await fetch(url).then((res) => res.blob())
  return URL.createObjectURL(blob)
}

export const downloadFilePDF = async (title, url, typeFile) => {
  fetch(url, {
    method: 'get',
    xhrFields: {
      responseType: 'blob',
    },
  })
    .then((res) => res.blob())
    .then((data) => {
      var a = document.createElement('a')
      var url = window.URL.createObjectURL(data)
      a.href = url
      a.download = `${title}.${_.isEmpty(typeFile) ? 'pdf' : typeFile}`
      document.body.append(a)
      a.click()
      a.remove()
      window.URL.revokeObjectURL(url)
    })
}

export function offsetDate(date, offset) {
  const d = new Date(date).getTime() + offset * 60 * 60 * 1000

  return new Date(d)
}
