import {
  CRUDListFilterPath,
  filterStaffProfile,
} from '../../../../utils/apiPath'
import {
  bodyAccountCode,
  bodyStaff,
} from '../../handler/fetchMasterDataOptions'
import { bodyExpenseCategory } from '../../../../modules/BudgetManagement/Form/handler/fetchMasterDataOptions'
import { expenseCostCenterOptions } from '../../../../utils/apiPath'

export const listAPIFetchOptions = [
  {
    method: 'get',
    url: expenseCostCenterOptions,
    headers: {
      key: 'costCenter',
    },
  },
  {
    body: bodyAccountCode,
    url: CRUDListFilterPath,
    method: 'post',
    headers: {
      'x-type': 'ACCOUNT_CODE',
      key: 'accountCode',
    },
  },
  {
    body: bodyStaff,
    url: filterStaffProfile,
    method: 'post',
    headers: {
      key: 'staffProfile',
    },
  },
  {
    body: bodyExpenseCategory,
    url: CRUDListFilterPath,
    method: 'post',
    headers: {
      'x-type': 'EXPENSE_CATEGORY',
      key: 'category',
    },
  },
]
