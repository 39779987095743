import SelectGroupChip from '../../../../../components/SelectGroupChip'
import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import _ from 'lodash'
import {
  filterAdminStaffOption,
  handleDefaultAdminStaff,
  handleMultiSelectChange as handleSelectAdminClassroom,
} from '../../../Setting/Form/DateCard/events'
import { checkAssignNee } from '../../../Setting/Form/events'
import { handleDefaultAdmin } from 'src/modules/ManageClass/Setting/Form/DateCard/OnlineRoomForm/events'
import { useMemo } from 'react'
import { handleMultiSelectChange as handleSelectAdminOnlineRoom } from '../../../Setting/Form/DateCard/OnlineRoomForm/events'

export const SelectSubAdmins = ({
  idxR,
  isClassroom = false,
  onlineRoom,
  idxD,
}) => {
  const dispatch = useDispatch()
  const { data, adminStaffOption } = useSelector(
    (state) => ({
      data: state.manageClassForm.data,
      adminStaffOption: state.manageClassForm.adminStaffOption,
    }),
    shallowEqual,
  )
  const listValueAdmin = checkAssignNee(
    _.get(data, 'assignNee'),
    adminStaffOption,
    _.get(data, 'assignNeeObj'),
  )

  const adminStaffPlan = isClassroom
    ? useMemo(
        () =>
          handleDefaultAdminStaff(
            _.get(data, `monthlyPlan.dates[${idxD}]`),
            listValueAdmin,
          ),
        [listValueAdmin],
      )
    : useMemo(() => handleDefaultAdmin(onlineRoom), [onlineRoom])

  const filteredOptions = isClassroom
    ? filterAdminStaffOption(adminStaffOption, listValueAdmin)
    : adminStaffOption

  return (
    <SelectGroupChip
      boxSx={{ mx: 1, width: 'auto' }}
      name="admin2"
      labelText="แอดมินรอง"
      isMenuPosition
      placeholder="กรุณาเลือก"
      options={filteredOptions}
      showTextError={false}
      value={adminStaffPlan}
      onChange={(list) => {
        if (isClassroom) {
          dispatch(handleSelectAdminClassroom(list, 'adminStaffPlan', idxD))
        } else {
          dispatch(
            handleSelectAdminOnlineRoom(list, 'adminStaffPlan', {
              oIndex: idxR,
              dIndex: idxD,
            }),
          )
        }
      }}
    />
  )
}
