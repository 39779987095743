import React from 'react'
import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import { Box } from '@mui/material'
import Header from './components/Header'
import Content from './components/Content'
import { useEffect } from 'react'
import LoadingPageCircular from '../../../components/CRUD/components/LoadingPageCircular'
import FilterDrawer from './components/FilterDrawer'
import { fetchMasterDataOptions } from './handler/fetchMasterDataOptions'
import { useParams, useHistory } from 'react-router-dom'
import {
  setFiltersDashboard,
  setReduxDashboardByKey,
} from 'src/redux/slices/expense'
import { validatePermission } from 'src/utils/lib'
import useLocalStorage from '@rehooks/local-storage'
import _ from 'lodash'
import { VIEW_MODE } from 'src/constants/expense'

const ExpenseDashboard = () => {
  const { uuid } = useParams()
  const dispatch = useDispatch()
  const history = useHistory()
  const { isLoading, filters } = useSelector(
    (state) => ({
      isLoading: state.expense.dashboard.isLoading,
      filters: state.expense.dashboard.filters,
    }),
    shallowEqual,
  )
  const [user] = useLocalStorage('user')

  const hasPermissionDashboard = validatePermission({
    user: user,
    moduleType: 'REPORT_EXPENSE',
    permission: ['DASHBOARD_ADMIN', 'DASHBOARD_HF', 'DASHBOARD_TSD'],
  })

  const hasPermissionByDepartment = validatePermission({
    user: user,
    moduleType: 'REPORT_EXPENSE',
    permission: ['DASHBOARD_BY_DEPARTMENT'],
  })

  useEffect(async () => {
    if (_.isUndefined(uuid)) {
      const costCenterUuid = _.get(user, 'costCenter', '')
      if (!hasPermissionDashboard && hasPermissionByDepartment) {
        history.push(`/expense/dashboard/${costCenterUuid}`)
      }
    }

    await dispatch(setReduxDashboardByKey({ key: 'uuid', value: uuid }))
    dispatch(
      setFiltersDashboard({
        ...filters,
        viewMode: VIEW_MODE.BY_DEPARTMENT,
        costCenterChecked: false,
        costCenter: [],
      }),
    )
  }, [uuid])

  useEffect(() => {
    fetchMasterDataOptions()
  }, [])

  return (
    <Box px={2} mx={3}>
      <Header />
      <Content />

      <FilterDrawer />
      <LoadingPageCircular isLoading={isLoading} />
    </Box>
  )
}

export default ExpenseDashboard
