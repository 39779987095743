import _ from 'lodash'
import { manageClassDownload } from '../../../../utils/apiPath'
import {
  resetTablePage,
  setFilterProp,
  setInitialTable,
  setSearchText,
  setOnQuickFilterByIndex,
  setFilterTotal,
  startLoading,
  stopLoading,
  resetOnQuickFilter,
  resetPage,
} from '../../../../redux/slices/table'
import { downloadFile } from '../../../../services/util'
import dayjs from 'dayjs'
import {
  headCells,
  defaultQuickFilter,
  otherFilter,
} from '../../../../constants/manageClass/table'
import { fetchDataFilter } from '../../../../services/manageClass'
import { filterBody } from '../../../../constants/manageClass/table'
import { setTotalAllClass } from '../../../../redux/slices/manageClass'

export const onDownload = (selected, sort, order) => async (dispatch) => {
  dispatch(startLoading())
  const body = {
    order: order.toUpperCase(),
    sort: sort,
    type: 'CUSTOM',
    list: selected,
  }
  await dispatch(
    downloadFile({
      url: manageClassDownload,
      body,
      fileName: `รายการคลาสทั้งหมด.xlsx`,
    }),
  )
  dispatch(stopLoading())
}

export const handleQuickSearch = (text) => (dispatch) => {
  dispatch(setSearchText(text))
  dispatch(setFilterProp({ course: text }))
  dispatch(resetOnQuickFilter())
  dispatch(resetTablePage())
}

export const handleQuickFilter =
  (item, index, filterProp) => async (dispatch) => {
    const quick = { ...item, clicked: !item.clicked }

    dispatch(setSearchText(''))
    dispatch(setOnQuickFilterByIndex({ item: quick, index: index }))
    if (item.clicked === false) {
      const statusList = []
      if (item.field === 'unassign') {
        statusList.push('UNASSIGN')
      }
      if (item.field === 'todo') {
        statusList.push('TODO')
      }
      if (item.field === 'inprogress') {
        statusList.push('INPROGRESS')
      }
      if (item.field === 'closed') {
        statusList.push('CLOSED')
      }
      if (item.field === 'canceled') {
        statusList.push('CANCELED')
      }
      const body = {
        ...filterBody,
        course: '',
        status: statusList,
      }

      if (filterProp === null) {
        dispatch(setFilterProp(body))
      } else {
        const oldStatus = _.get(filterProp, 'status', [])
        dispatch(
          setFilterProp({
            ...filterProp,
            course: '',
            status: [...oldStatus, ...statusList],
          }),
        )
      }
    } else {
      let convertField = ''
      if (item.field === 'unassign') {
        convertField = 'UNASSIGN'
      }
      if (item.field === 'todo') {
        convertField = 'TODO'
      }
      if (item.field === 'inprogress') {
        convertField = 'INPROGRESS'
      }
      if (item.field === 'closed') {
        convertField = 'CLOSED'
      }
      if (item.field === 'canceled') {
        convertField = 'CANCELED'
      }
      const filterStatus = filterProp.status.filter(
        (item) => item != convertField,
      )
      dispatch(
        setFilterProp({
          ...filterProp,
          course: '',
          status: filterStatus,
        }),
      )
    }
  }

export const onFilterClick = (props) => (dispatch) => {
  const { filter, tableFilter, listTrainingPlan } = props
  dispatch(setSearchText(''))
  const filterProp = _.get(tableFilter, 'filterProp', null)
  const quickFilter = { ..._.get(tableFilter, 'quickFilter', {}) }
  const {
    statusChecked,
    status,
    trainingPlatFormChecked,
    trainingPlatForm,
    courseChecked,
    course,
    nameForLearnerChecked,
    nameForLearner,
    assignNeeChecked,
    assignNee,
    checkDocuments,
    waitingExam,
    courseCodeChecked,
    courseCode,
    startDateChecked,
    startDate,
    finishDate,
    displayDate,
    startRegisterChecked,
    startRegister,
    displayDateRegister,
    finishRegister,
  } = filter
  let statusList = [..._.get(filterProp, 'status', [])]
  const trainingPlatFormList = []

  if (statusChecked) {
    if (status.closedChecked) statusList.push('CLOSED')
    if (status.unassignChecked) statusList.push('UNASSIGN')
    if (status.todoChecked) statusList.push('TODO')
    if (status.inProgressChecked) statusList.push('INPROGRESS')
    if (status.cancelChecked) statusList.push('CANCELED')
    if (!status.closedChecked) statusList = _.pull(statusList, 'CLOSED')
    if (!status.unassignChecked) statusList = _.pull(statusList, 'UNASSIGN')
    if (!status.todoChecked) statusList = _.pull(statusList, 'TODO')
    if (!status.inProgressChecked) statusList = _.pull(statusList, 'INPROGRESS')
    if (!status.cancelChecked) statusList = _.pull(statusList, 'CANCELED')
  } else {
    statusList = []
  }

  statusList = _.uniq(statusList)
  let quickList = [...statusList]

  if (checkDocuments) quickList.push('checkDocuments')
  if (waitingExam) quickList.push('waitingExam')
  if (!checkDocuments) quickList = _.pull(quickList, 'checkDocuments')
  if (!waitingExam) quickList = _.pull(quickList, 'waitingExam')

  for (const key in quickFilter) {
    const value = quickFilter[key]
    const quick = {
      ...value,
      clicked: quickList.some((item) => _.camelCase(item) == value.field),
    }
    dispatch(setOnQuickFilterByIndex({ item: quick, index: key }))
  }

  if (trainingPlatFormChecked) {
    listTrainingPlan.forEach((item) => {
      if (trainingPlatForm[item.name]) trainingPlatFormList.push(item.value)
    })
  }

  const isOnStartDate = startDateChecked && displayDate
  const isOnStartDateRegister = startRegisterChecked && displayDateRegister
  const body = {
    ...filterBody,
    status: statusList,
    trainingPlatForm: trainingPlatFormList,
    course: courseChecked ? course : '',
    nameForLearner: nameForLearnerChecked ? nameForLearner : '',
    assignNee: assignNeeChecked ? assignNee : '',
    checkDocuments: checkDocuments,
    waitingExam: waitingExam,
    courseCode: courseCodeChecked ? courseCode : '',
    startDate: isOnStartDate
      ? dayjs(startDate).format(window.__env__.REACT_APP_DATE_DB)
      : '',
    finishDate: isOnStartDate
      ? dayjs(finishDate).format(window.__env__.REACT_APP_DATE_DB)
      : '',
    startRegister: isOnStartDateRegister
      ? dayjs(startRegister).format(window.__env__.REACT_APP_DATE_DB)
      : '',
    finishRegister: isOnStartDateRegister
      ? dayjs(finishRegister).format(window.__env__.REACT_APP_DATE_DB)
      : '',
    updatedBy: filter.updatedByChecked ? filter.updatedBy : '',
  }

  dispatch(setFilterProp(body))
  dispatch(setFilterTotal(filter))
  dispatch(resetTablePage())
  dispatch(resetPage())
}

export const fetchDataList =
  (table, station, setInitial, isOpenVC = 0) =>
  async (dispatch) => {
    const filterProp = _.get(table, 'filter.filterProp', null)
    const other = _.get(table, 'filter.other', null)
    const tableQuickFilter = _.get(table, 'filter.quickFilter', [])
    const quickFilter = tableQuickFilter === null ? [] : tableQuickFilter
    const page = _.get(table, 'table.page', 1)
    const limit = _.get(table, 'table.limit', '10')
    const order = _.get(table, 'table.order', 'desc')
    let sort = _.get(table, 'table.sort', 'updatedAt')
    //convert sort body before fetch
    if (sort === 'myTrainingPlatForm') {
      sort = 'trainingPlatForm'
    } else if (sort === 'actionAdminManageClass') {
      sort = 'assignnee'
    } else if (sort === 'monthlyplanDate') {
      sort = 'startDate'
    } else if (sort === 'classStatus') {
      sort = 'status'
    }
    dispatch(startLoading())

    const statusList =
      filterProp === null ? [] : _.get(filterProp, 'status', [])
    const findCheckDocuments = !!quickFilter.find(
      (item) => item.field === 'checkDocuments' && item.clicked === true,
    )
    const findWaitingExam = !!quickFilter.find(
      (item) => item.field === 'waitingExam' && item.clicked === true,
    )
    const newDate = dayjs(new Date()).format(window.__env__.REACT_APP_DATE_DB)
    const startDate = _.get(filterProp, 'startDate', '')
    const finishDate = _.get(filterProp, 'finishDate', '')
    const startRegister = _.get(filterProp, 'startRegister', '')
    const finishRegister = _.get(filterProp, 'finishRegister', '')
    const realPage = page <= 0 ? 1 : +page
    const title = _.get(filterProp, 'course', '')
    const updatedBy = _.get(filterProp, 'updatedBy', '')

    const body = {
      courseCode: _.get(filterProp, 'courseCode', ''),
      course: title,
      nameForLearner: _.get(filterProp, 'nameForLearner', ''),
      assignNee: _.get(filterProp, 'assignNee', ''),
      startDate: startDate === 'Invalid Date' ? newDate : startDate,
      finishDate: finishDate === 'Invalid Date' ? newDate : finishDate,
      startRegister: startRegister === 'Invalid Date' ? newDate : startRegister,
      finishRegister:
        finishRegister === 'Invalid Date' ? newDate : finishRegister,
      checkDocuments:
        _.get(filterProp, 'checkDocuments', false) || findCheckDocuments,
      waitingExam: _.get(filterProp, 'waitingExam', false) || findWaitingExam,
      highClass:
        isOpenVC == 1 ? true : _.get(other, 'highClass.checked', false),
      myJob: _.get(other, 'myJob.checked', false),
      station:
        station !== null && station !== '' && station !== 'all'
          ? [station]
          : [],
      trainingPlatForm: _.get(filterProp, 'trainingPlatForm', []),
      status: statusList,
      limit: limit === '100' ? '10' : limit,
      page: realPage,
      order: order.toString().toUpperCase(),
      sort: sort,
      updatedBy: updatedBy,
    }
    let isCancel = false

    try {
      const fetch = await dispatch(fetchDataFilter(body))
      const meta = _.get(fetch, 'meta', {})
      isCancel =
        meta.requestStatus === 'rejected' && _.isUndefined(meta.response)
      const realQuickFilter =
        quickFilter.length > 0 ? quickFilter : defaultQuickFilter
      const quickFilterData = realQuickFilter.map((item) => {
        return {
          ...item,
          count: _.get(fetch.payload.countStatus, item.field, 0),
        }
      })
      const countStatus = _.get(fetch, 'payload.countStatus', '')
      const highClassCount = countStatus === '' ? 0 : countStatus.highClass
      const myJobCount = countStatus === '' ? 0 : countStatus.myJob
      let otherValue =
        other !== null
          ? {
              highClass: {
                ...other.highClass,
                count: highClassCount,
              },
              myJob: { ...other.myJob, count: myJobCount },
            }
          : otherFilter
      const convert = convertDataRow(fetch)

      otherValue = {
        highClass: {
          ...otherValue.highClass,
          count: highClassCount,
          checked: isOpenVC == 1 ? true : otherValue.highClass.checked,
        },
        myJob: { ...otherValue.myJob, count: myJobCount },
      }
      dispatch(setTotalAllClass(_.get(fetch, 'payload.totalAllClass', 0)))
      dispatch(
        setInitialTable({
          rows: convert,
          allCount: fetch.payload.totalCount,
          quickFilter: quickFilterData,
          headCells: headCells,
          placeholder: 'ค้นหาหลักสูตร',
          searchKey: 'name',
          other: otherValue,
          status: [],
          handleSearch: (text) => dispatch(handleQuickSearch(text)),
          onDownload: (selected, sort, order) =>
            dispatch(onDownload(selected, sort, order)),
          onQuickFilter: (item, index) =>
            dispatch(handleQuickFilter(item, index, filterProp)),
        }),
      )
    } catch (e) {
      dispatch(setInitialTable({ rows: [], allCount: 0, headCells }))
    }

    setInitial && setInitial(false)
    if (!isCancel) dispatch(stopLoading())
  }

export const getMonthlyPlanDateText = (item) => {
  const startDate = _.get(item, 'monthlyPlan.startDate', null)
  const finishDate = _.get(item, 'monthlyPlan.finishDate', null)
  const startText = dayjs(new Date(startDate)).format(
    window.__env__.REACT_APP_DATE_SHOW,
  )
  const finishText = dayjs(new Date(finishDate)).format(
    window.__env__.REACT_APP_DATE_SHOW,
  )
  const dateText =
    startDate === finishDate ? startText : `${startText} - ${finishText}`
  return dateText
}

const convertDataRow = (fetch) => {
  return fetch.payload.result.map((item) => {
    const trainingPlatForm = _.get(item, 'trainingPlatForm', [])
    const trainingPlatFormString = trainingPlatForm.map((trainingPlatform) => {
      return trainingPlatform.trainingPlatform
    })
    return {
      ...item,
      nameForLearner: _.get(item, 'nameForLearner', '-') || '-',
      monthlyplanDate: getMonthlyPlanDateText(item),
      myTrainingPlatForm: trainingPlatFormString,
      classStatus: item.status,
    }
  })
}
