import React, { useState, useEffect } from 'react'
import Box from '@mui/material/Box'
import Select, { components } from 'react-select'
import Typography from '@mui/material/Typography'
import CancelIcon from '@mui/icons-material/Cancel'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import useTheme from '@mui/system/useTheme'

const Option = (props) => {
  return (
    <components.Option {...props}>
      {props.children}
      {props?.data?.matchProductType && window.__env__.ENABLE_SKILL_MAPPING && (
        <CheckCircleOutlineIcon
          sx={{
            color: '#1CC54E',
            fontSize: '16px',
            verticalAlign: 'middle',
            marginLeft: '2px',
          }}
        />
      )}
    </components.Option>
  )
}

const MultiValue = (props) => {
  return (
    <components.MultiValue {...props}>
      {props.children}
      {props?.data?.matchProductType && window.__env__.ENABLE_SKILL_MAPPING && (
        <CheckCircleOutlineIcon
          sx={{
            color: '#1CC54E',
            fontSize: '16px',
            verticalAlign: 'middle',
            marginLeft: '2px',
          }}
        />
      )}
    </components.MultiValue>
  )
}

const SingleValue = (props) => {
  return (
    <components.SingleValue {...props}>{props.children}</components.SingleValue>
  )
}

const MultiValueRemove = (props) => {
  return (
    <components.MultiValueRemove {...props}>
      <CancelIcon />
    </components.MultiValueRemove>
  )
}
export default function MultipleSelectChip(props) {
  const {
    boxSx,
    menuSx,
    labelText,
    required,
    id,
    name,
    placeholder,
    disabled,
    textError,
    options,
    onChange,
    value,
    showTextError = true,
    isOptionDisabled,
    maxMenuHeight,
    isMenuPosition,
    variant,
    RightLabelContent = null,
  } = props
  const [selectedList, setSelectedList] = useState([])
  const theme = useTheme()
  useEffect(() => {
    if (value && value.length > 0) {
      const valueSelected = value.map((item) => {
        return {
          ...item,
          label: item.title || item.label,
          value: item.uuid || item.value,
        }
      })
      setSelectedList(valueSelected)
    } else {
      setSelectedList(value || [])
    }
  }, [value])

  const handleChange = (events) => {
    setSelectedList(events)
    onChange(events)
  }
  return (
    <Box
      sx={{ display: 'flex', flexDirection: 'column', width: '100%', ...boxSx }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <Typography
            variant={variant ?? 'body2'}
            color={disabled ? 'text.gray' : 'text.secondary'}
          >
            {labelText}
          </Typography>
          {required && (
            <Typography
              sx={{ lineHeight: 1, ml: 0.5 }}
              color={disabled ? 'text.gray' : 'error'}
            >
              *
            </Typography>
          )}
        </Box>
        {RightLabelContent && <RightLabelContent />}
      </Box>
      <Select
        menuPlacement="auto"
        data-testid="select-group-chip"
        id={id}
        name={name}
        isDisabled={disabled}
        closeMenuOnSelect={false}
        placeholder={placeholder}
        maxMenuHeight={maxMenuHeight ?? 300}
        components={{ MultiValueRemove, SingleValue, Option, MultiValue }}
        menuPosition={isMenuPosition ? 'fixed' : 'absolute'}
        styles={{
          dropdownIndicator: (base) => ({
            ...base,
            color: theme?.palette?.primary?.main,
          }),
          container: (base) => ({
            ...base,
            position: menuSx ? 'unset' : 'relative',
          }),
          placeholder: (base) => ({
            ...base,
            color: theme?.palette?.text?.silver,
            margin: 0,
          }),
          input: (base) => ({ ...base, margin: 0 }),
          control: (base) => {
            const activeBorder =
              base.borderColor == '#2684FF'
                ? theme?.palette?.primary?.main
                : theme?.palette?.text?.silver
            const borderColor =
              textError && textError.length > 0
                ? theme?.palette?.error?.main
                : activeBorder
            return {
              ...base,
              borderRadius: 0,
              borderColor,
              boxShadow: 'unset',
              ':hover': { borderColor },
            }
          },
          menu: (provided) => ({
            ...provided,
            zIndex: 3,
            ...menuSx,
          }),
          indicatorSeparator: () => ({ display: 'none' }),
          multiValueRemove: (base) => ({
            ...base,
            borderRadius: 16,
            color: theme?.palette?.other?.outlineBorder,
            '&:hover': {
              backgroundColor: theme?.palette?.action?.hover,
              color: theme?.palette?.action?.disabled,
              cursor: 'pointer',
            },
          }),
          multiValue: (base) => ({
            ...base,
            border: `1px solid ${theme?.palette?.text?.silver}`,
            borderRadius: 16,
            backgroundColor: theme?.palette?.text?.whtie,
          }),
          menuList: (base) => ({
            ...base,
            '& .css-1n7v3ny-option': {
              backgroundColor: theme?.palette?.action?.hover,
            },
            '& .css-1n7v3ny-option:active, .css-yt9ioa-option:active': {
              color: theme?.palette?.primary?.main,
              background: theme?.palette?.primary?.outlinedHoverBackground,
              '& .MuiSvgIcon-root': { color: theme?.palette?.primary?.main },
            },
          }),
        }}
        isMulti
        onChange={(events) => handleChange(events)}
        options={options}
        value={selectedList}
        isOptionDisabled={(option) => {
          if (isOptionDisabled) {
            if (isOptionDisabled === 'disabled') {
              return option.disabled
            } else {
              return selectedList.length >= Number(isOptionDisabled)
            }
          }
        }}
      />
      {showTextError && (
        <>
          {textError && textError.length > 0 && (
            <Typography variant="body2" color="error">
              {textError}
            </Typography>
          )}
        </>
      )}
    </Box>
  )
}
