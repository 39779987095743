import { CRUDListFilterPath } from '../../../utils/apiPath'
import { headerCells } from './Listing/model/headerCells'

export const crudConfig = {
  moduleId: 'INVENTORY_REQUISITION_MP_ALL',
  moduleSlug: 'Requisition',
  moduleTitleFull: 'การเบิก - คืนทรัพย์สิน',
  modulesTitleShort: 'รายการเบิก',
  modulePath: '/inventory/requisition',
  filterPath: CRUDListFilterPath,
  headerCells: headerCells,
  masterDataModuleId: [
    {
      key: 'warehouse',
      moduleId: 'INVENTORY_WAREHOUSE',
    },
    {
      key: 'responsibleUser',
      moduleId: 'INVENTORY_RESPONSIBLE_USER',
    },
  ],
}
