import React, { useEffect, useState, useContext } from 'react'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import { DialogContext } from '../../../../../../../context/dialog-context'
import Table from '../../../../../../../components/Table'
import FilterDrawer from './FilterDrawer'
import {
  productWarehouseMovementDownload,
  productWarehouseMovementFilter,
} from '../../../../../../../utils/apiPath'
import { handleDownload } from '../../../../../../../utils/lib'
import callAxios from '../../../../../../../utils/baseService'
import { handleFilterTotal } from '../../../../../../../redux/slices/table/events'
import _ from 'lodash'
import { headCells } from './events'
import { Card } from '@mui/material'
import DropdownButton from '../../../components/DropdownButton'
import {
  ProductMovementType,
  ProductMovementTypeText,
} from '../../../../../../../constants/stock'
import {
  convertFormatDateTime,
  convertNumberWithComma,
  convertFloatWithComma,
} from '../../../../../../../utils/lib'
import { useParams } from 'react-router-dom'
import dayjs from 'dayjs'

export const TableHistory = ({
  data,
  headCells,
  setLimit,
  order,
  setOrder,
  page,
  setPage,
  sort,
  setSort,
  allCount,
  handleQuickSearch,
  onDownload,
  setIsFilterDrawer,
  searchText,
  setSearchText,
  tablePage,
  setTablePage,
  text,
  setText,
  filterTotal,
}) => {
  return (
    <Card>
      <Table
        data={data}
        headCells={headCells}
        placeholder="ค้นหาด้วย ชื่อทรัพย์สิน, หมวดหมู่, หมวดหมู่ย่อย, จาก, ไป"
        searchKey={'title'}
        setLimit={setLimit}
        order={order}
        setOrder={setOrder}
        page={page}
        setPage={setPage}
        sort={sort}
        setSort={setSort}
        allCount={allCount}
        handleSearch={handleQuickSearch}
        onDownload={onDownload}
        setIsFilterDrawer={setIsFilterDrawer}
        searchText={searchText}
        setSearchText={setSearchText}
        tablePage={tablePage}
        setTablePage={setTablePage}
        text={text}
        setText={setText}
        filterTotal={filterTotal}
        onView={undefined}
        onEdit={undefined}
        onDelete={undefined}
        searchBoxSx={{
          minWidth: '430px',
        }}
        searchIconSx={{
          right: '10px',
        }}
      />
    </Card>
  )
}
const Index = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [limit, setLimit] = useState(100)
  const [order, setOrder] = useState('DESC')
  const [page, setPage] = useState(-1)
  const [sort, setSort] = useState('')
  const [dataList, setDataList] = useState([])
  const [allCount, setAllCount] = useState(0)
  const [searchText, setSearchText] = useState('')
  const [text, setText] = useState('')
  const [status, setStatus] = useState([])
  const [isFilterDrawer, setIsFilterDrawer] = useState(false)
  const [tablePage, setTablePage] = useState(0)
  const [filterTotal, setFilterTotal] = useState(0)
  const { setContext: setDialog } = useContext(DialogContext)
  const [filters, setFilters] = useState({})

  // const { product, warehouse } = props
  const { id: uuid } = useParams()

  useEffect(() => {
    fetchDataList(
      'fetch',
      status,
      limit,
      order,
      page,
      sort,
      searchText,
      filters,
    )
  }, [limit, order, sort, page])

  const onFilterClick = async (filter) => {
    setSearchText('')
    setText('')
    const statusList = []
    let list = []
    if (filter.statusChecked) {
      if (filter.status.importChecked) list.push(ProductMovementType.IMPORT)
      if (filter.status.transferInChecked)
        list.push(ProductMovementType.TRANSFER_IN)
      if (filter.status.transferOutChecked)
        list.push(ProductMovementType.TRANSFER_OUT)
      if (filter.status.changeHolderhecked)
        list.push(ProductMovementType.CHANGE_HOLDER)
      if (filter.status.returnChecked) list.push(ProductMovementType.RETURN)
      if (filter.status.withdrawChecked)
        list.push(ProductMovementType.REQUISITION)
      if (filter.status.reduceChecked) list.push(ProductMovementType.REDUCE)
      if (filter.status.editChecked) list.push(ProductMovementType.EDITED)
      if (filter.status.inActiveChecked) list.push(ProductMovementType.INACTIVE)
      if (filter.status.activeChecked) list.push(ProductMovementType.ACTIVE)
      if (filter.status.deletedChecked) list.push(ProductMovementType.DELETED)
    }
    const filtersList = {
      startMovementDate:
        filter?.dateChecked && dayjs(filter?.startDate).isValid()
          ? dayjs(filter?.startDate).format('YYYY-MM-DD')
          : '',
      endMovementDate:
        filter?.dateChecked && dayjs(filter?.endDate).isValid()
          ? dayjs(filter?.endDate).format('YYYY-MM-DD')
          : '',
      startTime: filter?.dateChecked ? filter?.dateStartTime : '',
      endTime: filter?.dateChecked ? filter?.dateEndTime : '',
      qtyFrom: filter?.qtyChecked ? filter?.qtyFrom : '',
      qtyTo: filter?.qtyChecked ? filter?.qtyTo : '',
      totalFrom: filter?.totalChecked ? filter?.totalFrom : '',
      totalTo: filter?.totalChecked ? filter?.totalTo : '',
      availableFrom: filter?.availableChecked ? filter?.availableFrom : '',
      availableTo: filter?.availableChecked ? filter?.availableTo : '',
      to: filter?.toFilterChecked ? filter?.toFilter : '',
      from: filter?.fromFilterChecked ? filter?.fromFilter : '',
      list: list,
    }
    setFilters(filtersList)
    setStatus(statusList)
    setTablePage(0)
    fetchDataList('filter', statusList, limit, order, 1, sort, '', filtersList)
    setFilterTotal(handleFilterTotal(filter))
  }

  const onDownload = async (selected) => {
    setIsLoading(true)
    const body = {
      title: '',
      order: order.toUpperCase(),
      sort: sort === '' ? 'createdAt' : sort,
      status: [],
      type: 'WAREHOUSE_MOVEMENT',
      list: selected,
    }
    await handleDownload({
      url: productWarehouseMovementDownload,
      body: body,
      fileName: `ประวัติการเคลื่อนไหว.xlsx`,
    })
    setIsLoading(false)
  }

  const handleQuickSearch = async (searchText) => {
    setTablePage(0)
    const statusList = []
    setSearchText(searchText)
    fetchDataList(
      'search',
      statusList,
      limit,
      order,
      1,
      sort,
      searchText,
      filters,
    )
  }

  const fetchDataList = async (
    method,
    statusList,
    limit,
    order,
    page,
    sort,
    search,
    filters = [],
  ) => {
    const sortBy = sort === '' ? 'createdAt' : sort
    const realPage = page <= 0 ? 1 : +page
    setIsLoading(true)
    const body = {
      limit: limit,
      page: realPage,
      order: order.toString().toUpperCase(),
      sort: sortBy,
      status: statusList,
      warehouseUuid: uuid,
      productUuid: null, //_.get(product, 'uuid', undefined),
      search: '',
      searchKeyword: search ?? '',
      startCreatedDate: '',
      endCreatedDate: '',
      startUpdatedDate: '',
      endUpdatedDate: '',
      createdBy: '',
      updatedBy: '',
      ...filters,
    }
    const isCancel = await callAxios
      .post(productWarehouseMovementFilter, body)
      .then((res) => {
        const data = res.data
        let result = _.get(data, 'result', [])
        result = result.map((item) => {
          return {
            ...item,
            form: _.get(item, 'movementType') === 'REDUCE' ? null : item.form,
            movementType: ProductMovementTypeText?.[item?.movementType]
              ? ProductMovementTypeText?.[item?.movementType]
              : item?.movementType ?? '-',
            createdAt: item?.createdAt
              ? convertFormatDateTime({
                  value: item?.createdAt,
                  type: 'dateTime',
                })
              : '-',
            quantity: item?.quantity
              ? convertNumberWithComma(item?.quantity)
              : item?.quality === 0
              ? 0
              : '-',
            stockValue: item?.stockValue
              ? convertFloatWithComma(item?.stockValue)
              : item?.stockValue === 0
              ? 0
              : '-',
            total: item?.total
              ? convertNumberWithComma(item?.total)
              : item?.total === 0
              ? 0
              : '-',
            available: item?.available
              ? convertNumberWithComma(item?.available)
              : item?.available === 0
              ? 0
              : '-',
            dropdownAction: (
              <DropdownButton
                isHistory={true}
                ticketId={item?.ticket?.uuid}
                item={item}
                productType={_.get(item, 'product.productType')}
                assetId={_.get(item, 'product.uuid')}
              />
            ),
          }
        })
        setDataList(filterDataList(result))
        setAllCount(_.get(data, 'totalCount', 0))
      })
      .catch((e) => {
        console.log(e)
      })
    if (!isCancel) setIsLoading(false)
    setIsLoading(false)
  }

  return (
    <Box sx={{ pb: 2 }}>
      <Box sx={isLoading && { opacity: 0.4, pointerEvents: 'none' }}>
        <TableHistory
          data={dataList}
          headCells={headCells}
          setLimit={setLimit}
          order={order}
          setOrder={setOrder}
          page={page}
          setPage={setPage}
          sort={sort}
          setSort={setSort}
          allCount={allCount}
          handleQuickSearch={handleQuickSearch}
          onDownload={onDownload}
          setIsFilterDrawer={setIsFilterDrawer}
          searchText={searchText}
          setSearchText={setSearchText}
          tablePage={tablePage}
          setTablePage={setTablePage}
          text={text}
          setText={setText}
          setIsLoading={setIsLoading}
          setDialog={setDialog}
          setDataList={setDataList}
          setAllCount={setAllCount}
          filterTotal={filterTotal}
        />
      </Box>
      <CircularProgress
        disableShrink
        sx={{
          position: 'absolute',
          display: isLoading ? 'unset' : 'none',
          top: '50%',
          left: '50%',
          color: 'primary.main',
        }}
      />
      <FilterDrawer
        open={isFilterDrawer}
        onClose={() => setIsFilterDrawer(false)}
        onFilterClick={onFilterClick}
      />
    </Box>
  )
}
export default Index

export const filterDataList = (list) => {
  if (!list.length) return []
  return list.map((item) => {
    return {
      ...item,
      levelOfLearner: _.get(item, 'levelOfLearner.title', '-'),
    }
  })
}
