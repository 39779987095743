import { Typography, TableRow, TableCell } from '@mui/material'
import { formattedNumber } from '../../../../../../modules/BudgetManagement/utils'

export const FinancialItem = ({ label, value = '' }) => {
  return (
    <TableRow
      sx={{
        '& .MuiTableCell-root': {
          borderBottom: 'none',
          py: 0,
          px: 0.5,
        },
      }}
    >
      <TableCell sx={{ width: 100 }}>
        <Typography variant="body1b" noWrap>
          {label}
        </Typography>
      </TableCell>
      <TableCell align="right">
        <Typography>{formattedNumber(value)}</Typography>
      </TableCell>
      <TableCell align="right" sx={{ width: '15%' }}>
        <Typography>บาท</Typography>
      </TableCell>
    </TableRow>
  )
}
