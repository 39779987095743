import { Typography } from '@mui/material'
import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { Row, Column } from '../Styled'
import DetailsFinancialBox from './DetailsFinancialBox'
import _ from 'lodash'
import { TIME_PERIOD } from 'src/constants/expense'
import dayjs from 'dayjs'

const SummarySection = () => {
  const {
    timePeriod,
    summaryDisplay,
    dateRange: { startDate, endDate },
  } = useSelector(
    (state) => ({
      timePeriod: state.expense.dashboard.timePeriod,
      summaryDisplay: state.expense.dashboard.summaryDisplay,
      dateRange: state.expense.dashboard.dateRange,
    }),
    shallowEqual,
  )

  return (
    <Column gap={1.5}>
      <Typography variant="h6">
        {timePeriod === TIME_PERIOD.YEARLY
          ? 'Full Year'
          : `${dayjs(startDate).format('DD/MM/YYYY')} - ${dayjs(endDate).format(
              'DD/MM/YYYY',
            )}`}
      </Typography>
      <Row gap={2}>
        {_.map(summaryDisplay, (sum, idx) => (
          <DetailsFinancialBox idx={idx} key={idx} {...sum} />
        ))}
      </Row>
    </Column>
  )
}

export default SummarySection
