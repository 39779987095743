import { Box, Divider } from '@mui/material'
import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { StyledCard } from '../Styled'
import SummarySection from '../SummarySection'
import MonthlySummarySection from '../MonthlySummarySection'
import TimePeriodSelector from '../../components/TimePeriodSelector'
import SummaryByCostCenterSection from '../../components/SummaryByCostCenterSection'
import { useParams } from 'react-router-dom'

const ContentByAcademy = () => {
  const { uuid } = useParams()
  const { tabActive, budgetActualByCostCenter } = useSelector(
    (state) => ({
      tabActive: state.expense.dashboard.tabActive,
      budgetActualByCostCenter:
        state.expense.dashboard.budgetActualByCostCenter,
    }),
    shallowEqual,
  )

  return (
    <Box display="flex" flexDirection="column" gap={3} my={3}>
      <StyledCard>
        <TimePeriodSelector />
        <SummarySection />
        <Divider />
        <MonthlySummarySection />
        {tabActive === 1 && !uuid && budgetActualByCostCenter?.length > 0 && (
          <SummaryByCostCenterSection />
        )}
      </StyledCard>
    </Box>
  )
}

export default ContentByAcademy
