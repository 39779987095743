import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import Breadcrumbs from '../../../components/Breadcrumbs'
import Table from '../../../components/redux/Table'
import HeaderList from './Header'
import { fetchExaminerListFilter, getOtherDocConfig } from './event'
import {
  StyledCard,
  StyledContainer,
  StyledContent,
  LoadingPage,
  StyledBoxLabelTitle,
} from '../Styled'
import ExaminerFilterDrawer from '../ExaminerFilterDrawer'
import HistoryFilterDrawer from '../HistoryFilterDrawer'
import ExaminerListDrawer from '../ExaminerListDrawer'
import { breadcrumbExaminerList } from '../model'
import { validateEPermission } from '../../../utils/lib'
import { EXAMINATION_SETTING, PERMISSION } from '../../../constants/examination'
import {
  setExaminerPermissions,
  setInitialExamList,
} from '../../../redux/slices/eExamination/list'
import { setSearchText } from '../../../redux/slices/table'
import TruncateMarkup from 'react-truncate-markup'

const Index = () => {
  const { id: uuid } = useParams()
  const dispatch = useDispatch()
  const { table, search, filter } = useSelector((state) => state.table)
  const {
    isOpenExaminerFilterDrawer,
    isOpenHistoryFilterDrawer,
    studentHistoryList,
    initialExaminerList,
    isEnableAnounce,
    refreshExaminerList,
    announce,
    examinerPermissions,
    scheduleStatus,
    examType,
    examDate,
    scheduleNameDetail,
  } = useSelector(
    (state) => ({
      isOpenExaminerFilterDrawer: state.eExamList.isOpenExaminerFilterDrawer,
      isOpenHistoryFilterDrawer: state.eExamList.isOpenHistoryFilterDrawer,
      studentHistoryList: state.eExamList.studentHistoryList,
      initialExaminerList: state.eExamList.initialExaminerList,
      isEnableAnounce: state.eExamList.announcement.isEnableAnounce,
      refreshExaminerList: state.eExamList.refreshExaminerList,
      announce: state.eExamList.announcement.announce,
      examinerPermissions: state.eExamList.examinerPermissions,
      scheduleStatus: state.eExamList.announcement.scheduleStatus,
      examType: state.eExamList.announcement.examType,
      examDate: state.eExamList.announcement.examDate,
      scheduleNameDetail: state.eExamList.announcement.scheduleNameDetail,
    }),
    shallowEqual,
  )

  const { limit, order, page, sort, isLoading } = table
  const { status, filterProp, isFilterDrawer } = filter
  const { searchText } = search
  const hasCreatePermission = validateEPermission({
    module: EXAMINATION_SETTING,
    permission: PERMISSION.CREATE,
  })
  const hasViewPermission = validateEPermission({
    module: EXAMINATION_SETTING,
    permission: PERMISSION.VIEW,
  })
  const hasDcPermission = validateEPermission({
    module: EXAMINATION_SETTING,
    permission: PERMISSION.DC,
  })
  useEffect(() => {
    dispatch(setInitialExamList(true))
    dispatch(setSearchText(''))
    dispatch(
      setExaminerPermissions({
        hideView: hasViewPermission ? false : true,
        hideEdit: hasDcPermission ? false : true,
        hideDelete: hasDcPermission ? false : true,
        hideEligibleExam: hasDcPermission ? false : true,
      }),
    )
    dispatch(
      fetchExaminerListFilter({
        uuid,
        method: 'initial',
        table,
        page,
        statusList: status,
        filterProp,
        keyword: searchText,
        examinerPermissions,
      }),
    )
    dispatch(getOtherDocConfig())
  }, [])

  useEffect(() => {
    if (!initialExaminerList || refreshExaminerList) {
      dispatch(
        fetchExaminerListFilter({
          uuid,
          method: 'fetch',
          table,
          page,
          statusList: status,
          filterProp,
          keyword: searchText,
          examinerPermissions,
        }),
      )
    }
  }, [limit, order, page, sort, refreshExaminerList])

  return (
    <StyledContainer>
      <StyledContent isLoading={isLoading}>
        <StyledBoxLabelTitle>
          <Box sx={{ display: 'flex', gap: 1 }}>
            <TruncateMarkup lines={1}>
              <Typography variant="h4">
                รายชื่อผู้สอบ ({examType}): {examDate}
              </Typography>
            </TruncateMarkup>
          </Box>
          <TruncateMarkup lines={1}>
            <Typography
              variant="body1b"
              sx={{ lineHeight: '20px', mb: '-6px', mt: '-10px' }}
            >
              สนามสอบ: {scheduleNameDetail}
            </Typography>
          </TruncateMarkup>
          <Breadcrumbs menuList={breadcrumbExaminerList} />
        </StyledBoxLabelTitle>

        <StyledCard id="listTable" sx={{ p: 3 }}>
          {(hasCreatePermission || hasViewPermission) && (
            <HeaderList
              examUuid={uuid}
              isEnableAnounce={isEnableAnounce}
              announce={announce}
              scheduleStatus={scheduleStatus}
            />
          )}
          <Box sx={{ border: '1px solid #DBE4F1', borderRadius: '8px', mt: 3 }}>
            <Table />
          </Box>
        </StyledCard>

        <ExaminerListDrawer
          open={isFilterDrawer}
          table={table}
          uuid={uuid}
          examinerPermissions={examinerPermissions}
        />

        <ExaminerFilterDrawer open={isOpenExaminerFilterDrawer} table={table} />

        {hasViewPermission && (
          <HistoryFilterDrawer
            open={isOpenHistoryFilterDrawer}
            historyList={studentHistoryList}
          />
        )}
      </StyledContent>

      <LoadingPage isLoading={isLoading} />
    </StyledContainer>
  )
}
export default Index
