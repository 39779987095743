import _ from 'lodash'
import { setChangeSetting } from '../../../../../../../../redux/slices/eTestingForm'

export const handleNumberInput = (e) => {
  const numVal = Math.abs(e.target.value).toFixed()

  if (_.startsWith(e.target.value, '0') || e.nativeEvent.data == '.') {
    e.target.value = null
  }

  e.target.value = numVal >= 0 ? e.target.value : null // min 0
  e.target.value = numVal <= 100 ? e.target.value : 100 // max 100

  return e.target.value
}

export const handlePercentChange = (e) => (dispatch) => {
  const targetVal = _.get(e, 'target.value', '')
  const value = _.isEmpty(targetVal) ? targetVal : Number(targetVal)
  const props = { value, key: 'criteriaPercent' }
  dispatch(setChangeSetting(props))
}
