import _ from 'lodash'
import { setDisplayFilter } from '../../../../redux/slices/expense'
import { store } from '../../../../App'
import { initialState } from '../../../../redux/slices/expense/model'

export const handleChange = ({ key, value }) => {
  const { displayFilters } = store.getState().expense.dashboard

  let objState = {
    ...displayFilters,
    [key]: value,
  }

  const keyWithOutChecked = key.replace('Checked', '')

  if (!value) {
    if (_.includes(key, 'Checked')) {
      objState = {
        ...objState,
        [keyWithOutChecked]: _.get(
          initialState,
          `dashboard.filters.${keyWithOutChecked}`,
          [],
        ),
      }
    }
  }

  store.dispatch(setDisplayFilter(objState))
}
