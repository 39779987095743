import axios from 'axios'
import _ from 'lodash'
import { store } from '../../../App'
import { enumStatus } from '../../../components/CRUD/enum/status'
import { setFieldValue } from '../../../redux/slices/crud'
import {
  CRUDListFilterPath,
  filterStaffProfile,
  responsibilityFilter,
} from '../../../utils/apiPath'
import { getOptionsCostCenter } from '../mutation/getOptionsCostCenter'
import { getOptionsAccountCode } from '../mutation/getOptionsAccountCode'
import { getOptionsStaffProfile } from '../mutation/getOptionsStaffProfile'
import { getOptionsStaffRole } from '../mutation/getOptionsStaffRole'

export const bodyCostCenter = {
  limit: -1,
  page: 1,
  order: 'ASC',
  sort: 'updatedAt',
  quickSearch: '',
  name: '',
  costCenter: '',
  groupCode: '',
  updateStartDate: '',
  updateFinishDate: '',
  createStartDate: '',
  createFinishDate: '',
  updatedBy: '',
  status: [enumStatus.ACTIVE],
  createdBy: '',
}

export const bodyAccountCode = {
  limit: -1,
  page: 1,
  order: 'ASC',
  sort: 'updatedAt',
  quickSearch: '',
  name: '',
  oldAccountCode: '',
  newAccountCode: '',
  category: '',
  updateStartDate: '',
  updateFinishDate: '',
  createStartDate: '',
  createFinishDate: '',
  updatedBy: '',
  status: [enumStatus.ACTIVE],
  createdBy: '',
}

export const bodyStaff = {
  firstName: '',
  lastName: '',
  email: '',
  username: '',
  agentCode: '',
  firstNameTH: '',
  lastNameTH: '',
  jobTitle: '',
  department: '',
  station: '',
  reportTo: '',
  position: '',
  limit: -1,
  order: 'ASC',
  page: 1,
  sort: 'id',
  status: [enumStatus.ACTIVE],
}

const bodyStaffRole = {
  responsibility: '',
  limit: 100,
  order: 'ASC',
  page: 1,
  sort: 'id',
  status: [enumStatus.ACTIVE],
}

const listAPIFetchOptions = [
  {
    body: bodyCostCenter,
    url: CRUDListFilterPath,
    method: 'post',
    headers: {
      'x-type': 'COST_CENTER',
      key: 'costCenter',
    },
  },
  {
    body: bodyAccountCode,
    url: CRUDListFilterPath,
    method: 'post',
    headers: {
      'x-type': 'ACCOUNT_CODE',
      key: 'accountCode',
    },
  },
  {
    body: bodyStaff,
    url: filterStaffProfile,
    method: 'post',
    headers: {
      key: 'staffProfile',
    },
  },
  {
    body: bodyStaffRole,
    url: responsibilityFilter,
    method: 'post',
    headers: {
      key: 'staffRole',
    },
  },
]

export const fetchMasterDataOptions = async () => {
  const masterData = await Promise.all(
    _.map(listAPIFetchOptions, (item) => {
      return axios({
        method: item.method,
        url: item?.url,
        data: item?.body,
        headers: item?.headers,
      })
    }),
  ).then((response) => {
    return _.reduce(
      response,
      (acc, item) => {
        const key = _.get(item, 'config.headers.key', undefined)

        let options = []
        switch (key) {
          case 'costCenter':
            options = getOptionsCostCenter(_.get(item, 'data.result', []))
            break
          case 'accountCode':
            options = getOptionsAccountCode(_.get(item, 'data.result', []))
            break
          case 'staffProfile':
            options = getOptionsStaffProfile(_.get(item, 'data.result', []))
            break
          case 'staffRole':
            options = getOptionsStaffRole(_.get(item, 'data.result', []))
            break
        }

        return {
          ...acc,
          [key]: { options: options, isLoading: false },
        }
      },
      {},
    )
  })

  store.dispatch(
    setFieldValue({
      key: 'masterData',
      value: masterData,
    }),
  )
}
