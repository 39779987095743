import _ from 'lodash'
import { speakerBorrow } from '../../utils/apiPath'
import { callAPI, validatePermission } from '../../utils/lib'
import { fetchStaffArea } from '../../modules/MonthlyPlan/api/Training'
import {
  setInitialData,
  setSpeakerType,
  setStaffList,
  startLoading,
  stopLoading,
} from '../../redux/slices/speaker'

export const fetchDataById = (publicId, speakerType) => (dispatch) => {
  dispatch(startLoading())
  dispatch(setSpeakerType(speakerType))
  callAPI({
    method: 'get',
    url: speakerBorrow + '/' + publicId,
    onSuccess: (data) => dispatch(handleFetchSuccess(data, speakerType)),
    onError: () => dispatch(stopLoading()),
  })
}

export const handleFetchSuccess = (data, speakerType) => async (dispatch) => {
  dispatch(setInitialData(data))
  if (speakerType === 'REQUESTER') {
    let staffList = await fetchStaffArea(data?.info?.course?.speakerProductType)
    dispatch(setStaffList(staffList))
  }
  dispatch(stopLoading())
}

export const checkEditData = (
  startDate,
  systemConfig,
  user,
  data,
  speakerType,
) => {
  if (speakerType === 'APPROVER') {
    if (
      !validatePermission({
        user: user,
        moduleType: 'MONTHLY_PLAN',
        permission: ['CREATE_EVERY_DAY'],
        data,
      }) &&
      _.get(user, 'uuid') != _.get(data, 'requester.uuid')
    ) {
      return false
    }
  } else {
    if (
      !validatePermission({
        user: user,
        moduleType: 'MONTHLY_PLAN',
        permission: ['CREATE_EVERY_DAY'],
        data,
      })
    ) {
      return false
    }
  }

  const status = _.get(data, 'status', '')
  if (status == 'APPROVE' || status == 'CANCEL') return false
  if (_.isEmpty(startDate || _.isEmpty(systemConfig))) return false
  const current = new Date()
  const currentDate = Number(current.getDate())
  const currentMonth = Number(current.getMonth())
  const currentYear = Number(current.getFullYear())

  const dataYear = Number(startDate.split('-')[0])
  const dataMonth = Number(startDate.split('-')[1])

  if (
    dataYear <= currentYear &&
    dataMonth < currentMonth + 1 &&
    currentDate >= systemConfig
  ) {
    return false
  }
  return true
}

export const handleClickEdit = (data) => {
  const obj = {
    uuid: data.monthlyPlan.uuid,
    year: +data.monthlyPlan.startDate.split('-')[0],
    month: +data.monthlyPlan.startDate.split('-')[1],
    areaUuid: data.monthlyPlan.area.uuid,
    stationUuid: data.monthlyPlan.area.station.uuid,
    departmentUuid: data.monthlyPlan.area.department?.uuid,
  }
  const calendarUrl = `${window.__env__.REACT_APP_APP_URL}/monthly-plan/calendar/${obj.uuid}?month=${obj.month}&year=${obj.year}&areaUuid=${obj.areaUuid}&stationUuid=${obj.stationUuid}&departmentUuid=${obj.departmentUuid}&drawer=1`
  window.open(calendarUrl, '_blank').focus()
}

export const findTextTrainingPlatform = (listTrainingPlatform) => {
  let result = ''

  if (listTrainingPlatform.length <= 0) return '-'

  listTrainingPlatform.map((item) => {
    const value = _.isEmpty(_.get(item, 'trainingPlatform', ''))
      ? _.get(item, 'label', '')
      : _.get(item, 'trainingPlatform', '')
    result += value + ' + '
  })

  return result.substring(0, result.length - 2)
}
