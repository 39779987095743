import { setReduxValue } from 'src/redux/slices/permission'
import { store } from '../../../../App'
import _ from 'lodash'

export const handleDisabledTab = () => {
  const {
    data: { permission },
    tabsPermissionList,
  } = store.getState().permission

  let selectedDashboardExpense = true
  if (
    _.some(permission, (item) =>
      [
        '2d12a23f-63a5-4034-82f2-116318723ee7',
        'a69a3179-1411-4085-9969-1935505ae60c',
      ].includes(item),
    )
  ) {
    selectedDashboardExpense = false
  }

  const updatedTabsPermissionList = _.cloneDeep(tabsPermissionList)
  _.set(updatedTabsPermissionList, `[1].disabled`, selectedDashboardExpense)

  store.dispatch(
    setReduxValue({
      key: 'tabsPermissionList',
      value: updatedTabsPermissionList,
    }),
  )
}
