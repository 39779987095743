import React, { useState } from 'react'
import _ from 'lodash'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { useParams } from 'react-router-dom'
import Typography from '@mui/material/Typography'
import Switch from '@mui/material/Switch'
import Card from '@mui/material/Card'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import ContentCopyOutlined from '@mui/icons-material/ContentCopyOutlined'
import QrCodeOutlined from '@mui/icons-material/QrCodeOutlined'
import { TooltipForm } from './Styled'
import { handleSwitchChange } from './events'
import * as Styled from './Styled'
import { QRCodeTemplate } from '../../../../../../../components/QRCodeTemplate'
import { useTheme } from '@mui/system'
import { downloadQRCode } from '../../../../../../../utils/lib'

const Share = () => {
  const { id: uuid } = useParams()
  const dispatch = useDispatch()
  const theme = useTheme()
  const { isDraft, isClone, isShare, shareLink, shareDate, status, etestName } =
    useSelector(
      (state) => ({
        isDraft: state.eTestingForm.isDraft,
        isClone: state.eTestingForm.isClone,
        isShare: state.eTestingForm.setting.isShare,
        shareLink: _.get(state.eTestingForm.setting, 'shareLink', ''),
        shareDate: _.get(state.eTestingForm.setting, 'shareDate', null),
        status: state.eTestingForm.setting.status,
        etestName: state.eTestingForm.information.name,
      }),
      shallowEqual,
    )
  const [staticShare] = useState(isShare)

  return (
    <Card sx={{ mt: 2, position: 'relative' }}>
      <QRCodeTemplate
        id="share-qr-code"
        isHidden={true}
        link={shareLink}
        title={'QR Code แบบทดสอบ'}
        subtitle={etestName}
        titleSx={{ mt: 0 }}
      />
      <Styled.BoxShare>
        <Typography variant="h6">แชร์แบบทดสอบ</Typography>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {status !== 'ACTIVE' && (
            <TooltipForm
              maxWidth={180}
              title={
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography variant="tooltip">
                    สามารถเปิดแชร์ได้เมื่อแบบทดสอบมี
                  </Typography>
                  <Typography variant="tooltip">สถานะเปิดใช้งาน</Typography>
                </Box>
              }
              arrow
              placement="bottom"
            >
              <Styled.IconAlertShare />
            </TooltipForm>
          )}
          <Switch
            disabled={!uuid || isDraft || isClone}
            data-testid="switch-setting"
            checked={isShare}
            onChange={(e) =>
              dispatch(handleSwitchChange(e, staticShare, shareDate))
            }
          />
        </Box>
      </Styled.BoxShare>
      {isShare && (
        <Styled.BoxQRCode>
          <QRCodeTemplate
            id="show-qr-code"
            boxSx={{
              border: `1px solid ${theme?.palette?.silver?.primary}`,
            }}
            size={106}
            link={shareLink}
            variantTitle={'body1b'}
            title="QR Code แบบทดสอบ"
            subtitle={etestName}
            titleSx={{ fontSize: 14 }}
          />
          <Styled.BoxInput>
            <Styled.InputUrl disabled value={'  ' + shareLink} />

            <Styled.BoxButton>
              <Button
                sx={{ width: 182 }}
                data-testid="btn-download"
                variant="outlined"
                size="m"
                startIcon={<QrCodeOutlined />}
                onClick={() =>
                  downloadQRCode(
                    'share-qr-code',
                    'QR Code แบบทดสอบ ' + etestName,
                  )
                }
              >
                <Typography variant="buttonm">ดาวน์โหลด QR Code</Typography>
              </Button>
              <Button
                sx={{ width: 124 }}
                data-testid="btn-copy"
                variant="outlined"
                size="m"
                startIcon={<ContentCopyOutlined />}
                onClick={() => navigator.clipboard?.writeText(shareLink)}
              >
                <Typography variant="buttonm">คัดลอกลิงก์</Typography>
              </Button>
            </Styled.BoxButton>
          </Styled.BoxInput>
        </Styled.BoxQRCode>
      )}
    </Card>
  )
}

export default Share
