import _ from 'lodash'
import { store } from '../../../../../App'
import dayjs from 'dayjs'
import { hasAllDashboardPerms } from '../../handler/hasAllDashboardPerms'

export const productListBody = (productType, bodyProps) => {
  const { filter } = store.getState().inventoryDashboard
  const stationBody =
    filter?.station == ''
      ? []
      : _.defaultTo(filter?.station, []).map((item) => item.uuid)
  // startDate
  // endDate
  return {
    limit: bodyProps.limit ?? 100,
    page: bodyProps.page,
    order: bodyProps.order,
    sort: bodyProps.sort,
    quickSearch: bodyProps.searchText,
    name: '',
    status: ['ACTIVE', 'INACTIVE'],
    search: '',
    availableFrom: '',
    availableTo: '',
    productType: [productType],
    category: '',
    subCategory: '',
    vendor: '',
    stockValueFrom: '',
    stockValueTo: '',
    createdBy: '',
    startCreatedDate: '',
    endCreatedDate: '',
    startUpdatedDate: '',
    endUpdatedDate: '',
    updatedBy: '',
    station: '',
    stationList: stationBody ?? [],
    dashboardCourseFilter: filter?.course ?? '',
    dashboardWarehouseFilter: filter?.inventory ?? '', //warehouse
    dashboardStartDate: _.isDate(filter?.startDate)
      ? dayjs(filter?.startDate).utc(true).toDate()
      : dayjs(new Date()).utc(true).startOf('month'),
    dashboardEndDate: _.isDate(filter?.endDate)
      ? dayjs(filter?.endDate).utc(true).toDate()
      : dayjs(new Date()).utc(true),
    warehouse: '',
    costCenter: '',
    description: '',
    returnYear: '',
    returnMonth: '',
    returnDay: '',
    totalFrom: '',
    totalTo: '',
    quantityAlertFrom: '',
    quantityAlertTo: '',
    unitPriceFrom: '',
    unitPriceTo: '',
    holder: '',
    openDateFinish: '',
    openDateStart: '',
  }
}

export const assetProductListBody = (holder, bodyProps) => {
  const { filter } = store.getState().inventoryDashboard
  const stationBody =
    filter?.station == ''
      ? []
      : _.defaultTo(filter?.station, []).map((item) => item.uuid)
  return {
    limit: bodyProps.limit ?? 100,
    page: bodyProps.page,
    order: bodyProps.order,
    sort: bodyProps.sort,
    quickSearch: bodyProps.searchText,
    name: '',
    status: ['ACTIVE', 'INACTIVE'],
    search: '',
    availableFrom: '',
    availableTo: '',
    productType: ['ASSET'],
    category: '',
    subCategory: '',
    vendor: '',
    stockValueFrom: '',
    stockValueTo: '',
    createdBy: '',
    startCreatedDate: '',
    endCreatedDate: '',
    startUpdatedDate: '',
    endUpdatedDate: '',
    updatedBy: '',
    station: '',
    stationList: stationBody ?? [],
    dashboardCourseFilter: filter?.course ?? '',
    dashboardWarehouseFilter: filter?.inventory ?? '', //warehouse
    dashboardStartDate: _.isDate(filter?.startDate)
      ? dayjs(filter?.startDate).utc(true).toDate()
      : dayjs(new Date()).utc(true).startOf('month'),
    dashboardEndDate: _.isDate(filter?.endDate)
      ? dayjs(filter?.endDate).utc(true).toDate()
      : dayjs(new Date()).utc(true),
    warehouse: '',
    costCenter: '',
    description: '',
    returnYear: '',
    returnMonth: '',
    returnDay: '',
    totalFrom: '',
    totalTo: '',
    quantityAlertFrom: '',
    quantityAlertTo: '',
    unitPriceFrom: '',
    unitPriceTo: '',
    holder: holder,
    openDateFinish: '',
    openDateStart: '',
    hasHolder: bodyProps?.hasHolder,
  }
}

export const inventoryRequisitionBody = (bodyProps, filterProps, newLimit) => {
  const { filter } = store.getState().inventoryDashboard
  let stationBody =
    filter?.station == ''
      ? []
      : _.defaultTo(filter?.station, []).map((item) => item.uuid)
  const isDrawer = bodyProps?.isDrawer ?? false
  if (isDrawer) {
    stationBody = filterProps?.station ?? []
  }
  if (!isDrawer && !hasAllDashboardPerms()) {
    const user = JSON.parse(localStorage.getItem('user'))
    const stationData = _.get(user, 'area[0].station')
    stationBody = [stationData.uuid]
  }
  return {
    limit: newLimit ?? '5',
    page: bodyProps.page ?? 1,
    order: bodyProps.order,
    sort: bodyProps.sort,
    quickSearch: bodyProps.searchText,
    name: '',
    search: '',
    status: filterProps?.status ?? [],
    dashboardCourseFilter: isDrawer
      ? filterProps?.courseReq
      : filter?.course ?? '',
    // dashboardCourseDrawerFilter: filterProps?.courseReq ?? '',
    dashboardWarehouseFilter: !isDrawer ? filter?.inventory : '',
    transferType: ['REQUISITION'],
    transferBy: ['ALL'],
    requisitionType: filterProps?.ticketType ?? [],
    station: stationBody ?? [], //filterProps?.station ?? [],
    returnDateFrom: filterProps?.returnDateFrom ?? '',
    returnDateTo: filterProps?.returnDateTo ?? '',
    startCreatedDate: filterProps?.startDate ?? '',
    endCreatedDate: filterProps?.endDate ?? '',
    startUpdatedDate: '',
    endUpdatedDate: '',
    product: '',
    qtyFrom: '',
    qtyTo: '',
    category: '',
    subCategory: '',
    createdBy: '',
    updatedBy: '',
    openDateFinish: '',
    openDateStart: '',
  }
}
