import { combineReducers } from '@reduxjs/toolkit'
import dialog from './dialog'
import dialog2 from './dialog2'
import table from './table'
import table2 from './table2'
import basicTable from './table/basicTable'
import eTestingForm from './eTestingForm'
import eTestingPreview from './eTestingPreview'
import eTestingDetail from './eTestingDetail'

import eEvaluationForm from './eEvaluationForm'
import eEvaluationPreview from './eEvaluationPreview'
import eEvaluationDetail from './eEvaluationDetail'
import eEvaluationFeedback from './eEvaluationForm/feedback'
import eCertificateForm from './eCertificateForm'
import apiToRcms from './apiToRcms'

import manageClass from './manageClass'
import manageClassForm from './manageClassForm'
import manageClassDetail from './manageClassDetail'
import manageClassCalendar from './manageClassCalendar'
import manageClassLearner from './manageClassLearner'
import tableAttendance from './tableAttendance'
import attendanceDialog from './manageClassAttendance'
import manageClassLearnerDocument from './manageClassDocument'
import manageClassLearnerDetail from './manageClassLearnerDetail'
import tableLeanerDetail from './tableLeanerDetail'
import manageClassCheckExam from './manageClassCheckExam'
import manageClassDocs from './manageClassDocs'
import masterDataLicenseForm from './masterData/licenseForm'
import masterDataMeetingRoomForm from './masterData/meetingRoomForm'
import masterDataExamination from './masterData/examination'
import manageContentHome from './manageContent/home'
import manageContentStation from './manageContent/station'
import prospectProfile from './manage/prospectProfile'
import agentProfile from './manage/agentProfile'
import permission from './permission'
import manageContentLogin from './manageContent/login'
import manageReport from './report'
import monthlyPlan from './monthlyPlan'
import speaker from './speaker'
import toDoList from './toDoList'
import reportCourseSettingDashboard from './reportCourseSetting/Dashboard'
import roomManagement from './roomManagement/Dashboard'
import roomManagementDrawer from './roomManagement/Drawer'
import roomManagementHistory from './roomManagementHistory'
import eLearningModuleForm from './eLearning/moduleForm'
import eLearningModuleDetail from './eLearning/moduleDetail'
import eLearningCourseForm from './eLearning/courseForm'
import eLearningPathForm from './eLearning/learningPathForm'
import eLearningPathDetail from './eLearning/learningPathDetail'
import eLearningPathPreview from './eLearning/learningPathPreview'
import manageContentELearningHome from './manageContent/elearning'
import eLearningCourseDetail from './eLearning/courseDetail'
import eLearningCourseBatchDetail from './eLearning/courseBatchDetail'
import eLearningPreview from './eLearning/preview'
import courseBatchLearner from './eLearning/courseBatchLearner'
import eLearningCourseBatchLearnerDetail from './eLearning/courseBatchLearnerDetail'
import eLearningQuestion from './eLearning/question'
import eLearningDashboard from './eLearning/dashboard'
import eLearningDashboardByCourse from './eLearning/dashboardByCourse'
import eTestingDashboard from './ETestingDashboard'
import eExamList from './eExamination/list'
import eExamForm from './eExamination/form'
import eExaminerForm from './eExaminer/form'
import eExaminerDetail from './eExaminer/detail'
import eConfiguration from './eConfig'
import stockList from './inventory/stock/list'
import crud from './crud'
import catalogForm from './learningPoint/catalog'
import settingPoint from './learningPoint/settingPoint'
import contentForm from './eContent/content'
import knowledgeBaseForm from './eContent/knowledgeBase'
import uploadCropper from './uploadCropper'
import uploadFile from './uploadFile'
import manageClassInventory from './manageClassInventory'
import reportComp from './reportComp'
import inventoryDashboard from './inventory/dashboard'
import expense from './expense'

const reducer = combineReducers({
  table,
  table2,
  basicTable,
  dialog,
  dialog2,
  eTestingForm,
  eTestingPreview,
  eTestingDetail,
  eEvaluationForm,
  eEvaluationFeedback,
  eEvaluationPreview,
  eEvaluationDetail,
  eCertificateForm,
  manageClass,
  manageClassForm,
  manageClassDetail,
  manageClassCalendar,
  tableAttendance,
  attendanceDialog,
  manageClassLearner,
  manageClassLearnerDocument,
  manageClassLearnerDetail,
  tableLeanerDetail,
  manageClassCheckExam,
  manageClassDocs,
  manageContentHome,
  manageContentStation,
  manageContentELearningHome,
  apiToRcms,
  // master data
  masterDataLicenseForm,
  masterDataMeetingRoomForm,
  masterDataExamination,
  //manage
  prospectProfile,
  agentProfile,
  permission,
  // manage Login
  manageContentLogin,
  manageReport,
  monthlyPlan,
  speaker,
  toDoList,
  //report
  reportCourseSettingDashboard,
  roomManagement,
  roomManagementHistory,
  roomManagementDrawer,
  // e-learning
  eLearningModuleForm,
  eLearningModuleDetail,
  eLearningCourseForm,
  eLearningCourseDetail,
  eLearningCourseBatchDetail,
  eLearningPreview,
  courseBatchLearner,
  eLearningCourseBatchLearnerDetail,
  eLearningQuestion,
  eLearningPathForm,
  eLearningPathDetail,
  eLearningPathPreview,
  eLearningDashboard,
  eLearningDashboardByCourse,
  //e-exam
  eExamList,
  eExamForm,
  eExaminerForm,
  eExaminerDetail,
  //e-config
  eConfiguration,
  //inventory - stock
  stockList,
  //learing-point
  catalogForm,
  settingPoint,
  //e-content
  contentForm,
  knowledgeBaseForm,
  crud,
  uploadCropper,
  uploadFile,
  manageClassInventory,
  reportComp,
  //e-testing
  eTestingDashboard,
  //inventory
  inventoryDashboard,
  //expense
  expense,
})

export default reducer
