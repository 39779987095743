import dayjs from 'dayjs'
import { TIME_PERIOD } from '../../../constants/expense'

export const initialState = {
  error: null,
  isLoading: false,
  dashboard: {
    isLoading: false,
    tabActive: 0,
    isOpenFilterDrawer: false,
    timePeriod: TIME_PERIOD.YEARLY,
    yearBudget: Number(dayjs().format('YYYY')),
    dateRange: { startDate: new Date(), endDate: new Date() },
    summary: [],
    summaryDisplay: [],
    monthlySummary: [],
    name: '',
    filters: {
      costCenterChecked: false,
      accountCodeChecked: false,
      staffTypeChecked: false,
      staffProfileChecked: false,
      categoryChecked: false,
      costCenter: [],
      accountCode: [],
      staffType: [],
      staffProfile: [],
      categories: [],
      filterTotal: 0,
    },
    displayFilter: {},
    filtersList: [],
    budgetActualByCostCenter: [],
    filtersSummary: [],
  },
}
