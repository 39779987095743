import React, { useState } from 'react'
import Button from '@mui/material/Button'
import InputBase from '@mui/material/InputBase'
import IconButton from '@mui/material/IconButton'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import CloseIcon from '@mui/icons-material/Close'
import Divider from '@mui/material/Divider'
import Checkbox from '@mui/material/Checkbox'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import {
  StyledDrawer,
  StyledFooter,
  StyledGroupBox,
  StyledHeadLabel,
} from '../../components/FilterDrawer/Styled'

const defaultFilter = {
  groupnameChecked: false,
  groupnameText: '',
  status: {
    activeChecked: false,
    inactiveChecked: false,
    deletedChecked: false,
  },
  statusChecked: false,
}
const FilterDrawer = ({ open, onClose, onFilterClick }) => {
  const [filter, setFilter] = useState(defaultFilter)

  const handleOnGroupTextChange = (e) => {
    setFilter({
      ...filter,
      groupnameText: e.target.value,
    })
  }
  const handleCreatedByChange = (e) => {
    setFilter({
      ...filter,
      createdBy: e.target.value,
    })
  }
  const handleUpdatedByChange = (e) => {
    setFilter({
      ...filter,
      updatedBy: e.target.value,
    })
  }

  const handleFilterStatusChecked = (e) => {
    setFilter({
      ...filter,
      status: {
        ...filter.status,
        [e.target.name]: !filter.status[e.target.name],
      },
    })
  }

  const onClearClick = () => {
    setFilter(defaultFilter)
  }

  const handleChangeChecked = ({ checked, key }) => {
    setFilter({
      ...filter,
      [key]: checked,
    })
  }

  return (
    <StyledDrawer open={open} onClose={onClose}>
      <StyledHeadLabel>
        <Box>
          <Typography variant="h5">ตัวกรอง</Typography>
          <IconButton
            data-testid={`btn-close-drawer`}
            color="primary"
            component="span"
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Divider />
      </StyledHeadLabel>
      <StyledGroupBox>
        <FormGroup sx={{ width: '100%' }}>
          <FormControlLabel
            control={
              <Checkbox
                data-testid={'checkbox-permissionGroup-name'}
                name="groupnameChecked"
                defaultChecked
                checked={filter.groupnameChecked}
                onChange={(e) => {
                  handleChangeChecked({
                    checked: e.target.checked,
                    key: 'groupnameChecked',
                  })
                }}
              />
            }
            label="ชื่อกลุ่ม"
            sx={!filter.groupnameChecked ? { mb: -1 } : {}}
          />
          {filter.groupnameChecked && (
            <InputBase
              sx={{ borderRadius: 0 }}
              type="basic"
              disabled={!filter.groupnameChecked}
              placeholder="ค้นหาชื่อกลุ่ม"
              value={filter.groupnameText}
              onChange={handleOnGroupTextChange}
            />
          )}
        </FormGroup>
      </StyledGroupBox>
      <StyledGroupBox sx={{ display: 'flex', flexDirection: 'column' }}>
        <FormControlLabel
          label="สถานะ"
          control={
            <Checkbox
              name="statusChecked"
              checked={filter.statusChecked}
              onChange={(e) => {
                handleChangeChecked({
                  checked: e.target.checked,
                  key: 'statusChecked',
                })
              }}
            />
          }
        />
        {filter.statusChecked && (
          <Box sx={{ display: 'flex', flexDirection: 'column', mx: 3 }}>
            <FormControlLabel
              label="เปิดใช้งาน"
              control={
                <Checkbox
                  name="activeChecked"
                  checked={filter.status.activeChecked}
                  onChange={handleFilterStatusChecked}
                />
              }
            />
            <FormControlLabel
              label="ถูกลบแล้ว"
              control={
                <Checkbox
                  name="deletedChecked"
                  checked={filter.status.deletedChecked}
                  onChange={handleFilterStatusChecked}
                />
              }
            />
          </Box>
        )}
      </StyledGroupBox>
      <StyledGroupBox>
        <FormGroup sx={{ width: '100%' }}>
          <FormControlLabel
            control={
              <Checkbox
                data-testid="checkbox-distribution-abbreviation"
                name="createdByChecked"
                checked={filter.createdByChecked}
                onChange={(e) => {
                  handleChangeChecked({
                    checked: e.target.checked,
                    key: 'createdByChecked',
                  })
                }}
              />
            }
            label="สร้างโดย"
            sx={!filter.createdByChecked ? { marginBottom: -1 } : {}}
          />
          {filter.createdByChecked && (
            <InputBase
              sx={{ borderRadius: 0 }}
              type="basic"
              disabled={!filter.createdByChecked}
              placeholder="ค้นหาสร้างโดย"
              value={filter.createdBy}
              onChange={handleCreatedByChange}
            ></InputBase>
          )}
        </FormGroup>
      </StyledGroupBox>
      <StyledGroupBox>
        <FormGroup sx={{ width: '100%' }}>
          <FormControlLabel
            control={
              <Checkbox
                data-testid="checkbox-distribution-updatedBy"
                name="updatedByChecked"
                checked={filter.updatedByChecked}
                onChange={(e) => {
                  handleChangeChecked({
                    checked: e.target.checked,
                    key: 'updatedByChecked',
                  })
                }}
              />
            }
            label="แก้ไขล่าสุดโดย"
            sx={!filter.updatedByChecked ? { marginBottom: -1 } : {}}
          />
          {filter.updatedByChecked && (
            <InputBase
              sx={{ borderRadius: 0 }}
              type="basic"
              disabled={!filter.updatedByChecked}
              placeholder="ค้นหาแก้ไขล่าสุดโดย"
              value={filter.updatedBy}
              onChange={handleUpdatedByChange}
            ></InputBase>
          )}
        </FormGroup>
      </StyledGroupBox>

      <StyledFooter>
        <Button sx={{ width: '45%' }} variant="outlined" onClick={onClearClick}>
          ล้าง
        </Button>
        <Button
          sx={{ width: '45%' }}
          onClick={() => onFilterClick(filter)}
          variant="contained"
        >
          ยืนยัน
        </Button>
      </StyledFooter>
    </StyledDrawer>
  )
}
export default FilterDrawer
