import { createAsyncThunk } from '@reduxjs/toolkit'
import _ from 'lodash'
import axios from 'axios'
import {
  distributionChannelFilter,
  filterCourse,
  filterProductType,
  manageContent,
  manageContentInfo,
  manageContentUpload,
  manageLocation,
  manageLocationUpload,
  manageLocationUploadBanner,
  manageContentLogin,
  manageLoginUploadBanner,
  manageElearning,
  manageELearningHistory,
  eLearningCourseFilter,
  eLearningLearningPathFilter,
} from '../../utils/apiPath'
import { MODULE_STATUS } from '../../constants/eLearning'
import callAxios from '../../utils/baseService'

export const getManageContentHome = createAsyncThunk(
  'manageClassDetail/getManageContentHome',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(manageContent)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const getManageContentELearningHome = createAsyncThunk(
  'manageClassDetail/getManageContentELearningHome',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(manageElearning)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const putManageContentELearningHome = createAsyncThunk(
  'manageClassDetail/putManageContentELearningHome',
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.put(manageElearning, body)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const getAllProductType = createAsyncThunk(
  'manageClassDetail/getAllProductType',
  async (params, { rejectWithValue }) => {
    try {
      const body = {
        limit: -1,
        page: 1,
        order: _.get(params, 'order', '') || 'DESC',
        sort: _.get(params, 'sort', '') || 'updatedAt',
        productType: '',
        status: ['ACTIVE'],
      }
      const response = await axios.post(filterProductType, body)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const getAllDistributionChannel = createAsyncThunk(
  'manageClassDetail/getAllDistributionChannel',
  async (_, { rejectWithValue }) => {
    try {
      const body = {
        abbreviation: '',
        distribution: '',
        level: [],
        limit: -1,
        order: 'ASC',
        page: 1,
        sort: 'id',
        status: ['ACTIVE'],
      }
      const response = await axios.post(distributionChannelFilter, body)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const getAllCourse = createAsyncThunk(
  'manageClassDetail/getAllCourse',
  async (_, { rejectWithValue }) => {
    try {
      const body = {
        courseCode: '',
        englishName: '',
        nameForLearner: '',
        numberOfVersions: -1,
        updatedBy: '',
        limit: -1,
        page: 1,
        order: 'DESC',
        sort: 'updatedAt',
        productType: '',
        status: ['ACTIVE'],
        createdBy: '',
      }
      const response = await axios.post(filterCourse, body)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const postUploadImage = createAsyncThunk(
  'manageClassDetail/postUploadImage',
  async ({ body, config }, { rejectWithValue }) => {
    try {
      const response = await axios.post(manageContentUpload, body, config)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const putManageContentHome = createAsyncThunk(
  'manageClassDetail/putManageContentHome',
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.put(manageContent, body)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const putManageContentInfo = createAsyncThunk(
  'manageClassDetail/putManageContentInfo',
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.put(manageContentInfo, body)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const getManageContentStation = createAsyncThunk(
  'manageClassDetail/getManageContentStation',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(manageLocation)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const putManageContentStation = createAsyncThunk(
  'manageClassDetail/putManageContentStation',
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.put(manageLocation, body)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const postUploadLocation = createAsyncThunk(
  'manageClassDetail/postUploadLocation',
  async ({ body, key, config }, { rejectWithValue }) => {
    try {
      const response = await axios.post(manageLocationUpload, body, config)
      return response
    } catch (err) {
      return rejectWithValue(key, err)
    }
  },
)

export const postUploadLocationBanner = createAsyncThunk(
  'manageClassDetail/postUploadLocationBanner',
  async ({ body, config }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        manageLocationUploadBanner,
        body,
        config,
      )
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const getManageContentLogin = createAsyncThunk(
  'manageContent/getManageContentLogin',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(manageContentLogin)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const putManageContentLogin = createAsyncThunk(
  'manageContent/putManageContentLogin',
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.put(manageContentLogin, body)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const postUploadLoginBanner = createAsyncThunk(
  'manageContent/postUploadLoginBanner',
  async ({ body, config }, { rejectWithValue }) => {
    try {
      const response = await axios.post(manageLoginUploadBanner, body, config)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const getHistory = createAsyncThunk(
  'eLearningModuleDetail/getModuleHistory',
  async (url, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${manageELearningHistory}${url}`)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const getAllElearningCourse = createAsyncThunk(
  'manageContent/getAllElearningCourse',
  async (_, { rejectWithValue }) => {
    try {
      const body = {
        courseCode: '',
        name: '',
        nameForLearner: '',
        courseType: [],
        numberOfVersions: -1,
        updatedBy: '',
        limit: -1,
        page: 1,
        order: 'DESC',
        sort: 'updatedAt',
        productType: '',
        status: [MODULE_STATUS.ACTIVE],
        createdBy: '',
      }
      const response = await axios.post(eLearningCourseFilter, body)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const getAllLearningPath = createAsyncThunk(
  'manageContent/getAllLearningPath',
  async (_, { rejectWithValue }) => {
    try {
      const body = {
        limit: '100',
        page: 1,
        order: 'DESC',
        sort: 'updatedAt',
        status: [MODULE_STATUS.ACTIVE],
        id: '',
        name: '',
        nameCourse: '',
        periodStart: '',
        periodEnd: '',
      }
      const response = await axios.post(eLearningLearningPathFilter, body)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const getAllElearningCollection = createAsyncThunk(
  'manageContent/getAllCollection',
  async (_, { rejectWithValue }) => {
    try {
      const body = {
        id: '',
        limit: '100',
        name: '',
        openDateFinish: '',
        openDateStart: '',
        order: 'DESC',
        page: 1,
        quickSearch: '',
        sort: 'updatedAt',
        status: [],
        createdBy: '',
        updatedBy: '',
      }
      const response = callAxios.post(`/crud/filter`, body, {
        'x-type': 'E_LEARNING_COLLECTION',
      })
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)
