import _ from 'lodash'
import {
  resetTablePage,
  setFilterProp,
  setInitialTable,
  setSearchText,
  setStatusFilter,
  startLoading,
  stopLoading,
  setFilterTotal,
  resetPage,
} from '../../../redux/slices/table'
import { downloadFile } from '../../../services/util'
import { initialState } from '../../../redux/slices/table/model'
import callAxios from '../../../utils/baseService'
import {
  FILE_TYPE,
  MODULE_STATUS,
  MODULE_TYPE,
  headCells,
} from '../../../constants/eLearning'
import {
  eLearningModuleDownload,
  eLearningModuleFilter,
} from '../../../utils/apiPath'
import { convertFormatDateTime } from '../../../utils/lib'
import {
  closeDialog,
  loadingDialog,
  openDialog,
  stopLoadingDialog,
} from '../../../redux/slices/dialog'
import { deleteModule } from '../../../services/eLearning/module/form'
import { DEFUALT_FILE_TYPE, DEFUALT_MODULE_TYPE } from './model'
import { MODULE_TYPE_EN } from './View/Content/model'

export const onDownload = (selected, sort, order) => async (dispatch) => {
  dispatch(startLoading())
  const body = {
    order: order.toUpperCase(),
    sort: sort,
    type: 'CUSTOM',
    list: selected,
  }

  await dispatch(
    downloadFile({
      url: eLearningModuleDownload,
      body: body,
      fileName: `รายการE-Learning.xlsx`,
    }),
  )
  dispatch(stopLoading())
}

export const handleDeleteModule = (row, table) => async (dispatch) => {
  dispatch(
    openDialog({
      title: 'ยืนยันลบโมดูล',
      message: 'คุณต้องการลบโมดูลใช่หรือไม่',
      colorDisagreeText: 'error',
      colorAgreeText: 'error',
      handleConfirm: () => dispatch(handleConfirmDelete(row.uuid, table)),
    }),
  )
}

export const handleConfirmDelete = (uuid, table) => async (dispatch) => {
  dispatch(startLoading())
  dispatch(loadingDialog())
  await dispatch(deleteModule(uuid))
  dispatch(
    fetchDataList({
      method: 'search',
      table,
      page: 1,
      status: [],
      keyword: '',
    }),
  )
  dispatch(stopLoading())
  dispatch(stopLoadingDialog())
  dispatch(closeDialog())
}

export const fetchDataList = (props) => async (dispatch) => {
  const { method, table, page, status, filterProp, keyword, setInitial } = props
  const isInitial = method == 'initial'
  const realPage = page <= 0 ? 1 : +page
  const tableProps = isInitial ? initialState.table : table
  const filter = isInitial ? null : filterProp
  const title = isInitial ? '' : _.get(filter, 'name', keyword)
  const body = {
    limit: _.get(tableProps, 'limit', '20'),
    page: realPage,
    order: tableProps.order.toString().toUpperCase(),
    sort: _.get(tableProps, 'sort', 'updatedAt'),
    status: isInitial ? [] : status,
    moduleType: _.get(
      filter,
      'moduleType',
      filterProp?.moduleType || DEFUALT_MODULE_TYPE,
    ),
    fileType: _.get(filter, 'fileType', []).map((type) => _.lowerCase(type)),
    name: method == 'filter' ? keyword : title,
    moduleCode: _.get(filter, 'moduleCode', ''),
    isInProgress: _.get(filter, 'isInProgress', false),
    createdBy: _.get(filter, 'createdBy', ''),
    updatedBy: _.get(filter, 'updatedBy', ''),
  }

  dispatch(startLoading())
  const isCancel = await callAxios
    .post(eLearningModuleFilter, body)
    .then((res) => {
      const row = _.get(res, 'data.result', []).map((item) => ({
        ...item,
        moduleType: MODULE_TYPE_EN[item.moduleType],
        status: item.isInProgress ? 'INPROGRESS' : item.status,
        createdAt: convertFormatDateTime({
          value: item.createdAt,
          type: 'date',
        }),
      }))
      dispatch(
        setInitialTable({
          rows: row,
          allCount: _.get(res, 'data.totalCount', 0),
          headCells: headCells,
          placeholder: 'ค้นหาชื่อโมดูล',
          searchKey: 'name',
          status: status,
          handleSearch: (text) => dispatch(handleQuickSearch(tableProps, text)),
          onDownload: (selected, sort, order) =>
            dispatch(onDownload(selected, sort, order)),
          onDelete: (row) => dispatch(handleDeleteModule(row, tableProps)),
        }),
      )
    })
    .catch((e) => {
      dispatch(setInitialTable({ rows: [], allCount: 0, headCells: headCells }))
      return e.message?.includes('method')
    })

  setInitial && setInitial(false)
  if (!isCancel) dispatch(stopLoading())
}

export const onFilterClick = (table, filter) => (dispatch) => {
  dispatch(setSearchText(''))

  let status = []
  if (filter.statusChecked) {
    if (filter.status.activeChecked) {
      status.push(MODULE_STATUS.ACTIVE)
    }
    if (filter.status.inActiveChecked) {
      status.push(MODULE_STATUS.INACTIVE)
    }
    if (filter.status.deletedChecked) {
      status.push(MODULE_STATUS.DELETED)
    }
  } else {
    status = []
  }
  dispatch(setStatusFilter(status))

  let typeList = []
  if (filter.moduleTypeChecked) {
    if (filter.moduleType.eEvaluationChecked) {
      typeList.push(MODULE_TYPE.E_EVALUATION)
    }
    if (filter.moduleType.eTestingChecked) {
      typeList.push(MODULE_TYPE.E_TESTING)
    }
    if (filter.moduleType.embedContentChecked) {
      typeList.push(MODULE_TYPE.EMBED_CONTENT)
    }
    if (filter.moduleType.linkToChecked) {
      typeList.push(MODULE_TYPE.LINK_TO)
    }
    if (filter.moduleType.uploadFileChecked) {
      typeList.push(MODULE_TYPE.UPLOAD_FILE)
    }
    if (filter.moduleType.scormFileChecked) {
      typeList.push(MODULE_TYPE.SCORM)
    }
  } else {
    typeList = DEFUALT_MODULE_TYPE
  }

  let fileType = []
  if (filter.moduleTypeChecked && filter.moduleType.uploadFileChecked) {
    if (filter.fileType.docChecked) {
      fileType.push(FILE_TYPE.DOC)
    }
    if (filter.fileType.docxChecked) {
      fileType.push(FILE_TYPE.DOCX)
    }
    if (filter.fileType.pptChecked) {
      fileType.push(FILE_TYPE.PPT)
    }
    if (filter.fileType.pptxChecked) {
      fileType.push(FILE_TYPE.PPTX)
    }
    if (filter.fileType.pdfChecked) {
      fileType.push(FILE_TYPE.PDF)
    }
    if (filter.fileType.zipChecked) {
      fileType.push(FILE_TYPE.ZIP)
    }
  } else {
    fileType = DEFUALT_FILE_TYPE
  }

  const filterProp = {
    moduleCode: filter.moduleCodeChecked ? filter.moduleCode : '',
    name: filter.nameChecked ? filter.name : '',
    moduleType: typeList,
    fileType: fileType,
    status: status,
    isInProgress: filter.statusChecked && filter.status.inProgressChecked,
    createdBy: filter.createdByChecked ? filter.createdBy : '',
    updatedBy: filter.updatedByChecked ? filter.updatedBy : '',
  }
  dispatch(setFilterProp(filterProp))
  dispatch(setFilterTotal(filter))
  dispatch(resetTablePage())
  dispatch(
    fetchDataList({
      method: 'filter',
      table,
      page: 1,
      status,
      filterProp,
      keyword: filterProp.name,
    }),
  )
}

export const handleQuickSearch = (table, keyword) => (dispatch) => {
  dispatch(setSearchText(keyword))
  dispatch(setFilterProp(null))
  dispatch(resetTablePage())
  if (table.page === 1) {
    dispatch(
      fetchDataList({
        method: 'search',
        table,
        page: 1,
        status: [],
        keyword,
      }),
    )
  } else {
    dispatch(resetPage())
    dispatch(setStatusFilter([]))
  }
}

export const getInitailTypeList = (moduleType) => {
  switch (moduleType) {
    case MODULE_TYPE.E_EVALUATION:
      return {
        typeList: [MODULE_TYPE.E_EVALUATION],
        checked: { eEvaluationChecked: true },
      }
    case MODULE_TYPE.E_TESTING:
      return {
        typeList: [MODULE_TYPE.E_TESTING],
        checked: { eTestingChecked: true },
      }
    case MODULE_TYPE.EMBED_CONTENT:
      return {
        typeList: [MODULE_TYPE.EMBED_CONTENT],
        checked: { embedContentChecked: true },
      }
    case MODULE_TYPE.LINK_TO:
      return {
        typeList: [MODULE_TYPE.LINK_TO],
        checked: { linkToChecked: true },
      }
    case MODULE_TYPE.UPLOAD_FILE:
      return {
        typeList: [MODULE_TYPE.UPLOAD_FILE],
        checked: { uploadFileChecked: true },
      }
    case MODULE_TYPE.SCORM:
      return {
        typeList: [MODULE_TYPE.SCORM],
        checked: { scormFileChecked: true },
      }
    default:
      return DEFUALT_FILE_TYPE
  }
}
