import { store } from '../../../../../App'
import { STATUS } from '../model/status'

export const mutateFilterSateToProp = () => {
  const { filterState } = store.getState().table
  let status = []
  if (filterState.statusChecked) {
    if (filterState.status.activeChecked) {
      status.push(STATUS.ACTIVE)
    }
    if (filterState.status.inActiveChecked) {
      status.push(STATUS.INACTIVE)
    }
    if (filterState.status.deletedChecked) {
      status.push(STATUS.DELETED)
    }
    if (filterState.status.draftChecked) {
      status.push(STATUS.DRAFT)
    }
  } else {
    status = []
  }

  return {
    name: filterState.nameChecked ? filterState.name : '',
    id: filterState.idChecked ? filterState.id : '',
    openDateStart: filterState.openDateChecked ? filterState.openDateStart : '',
    openDateFinish: filterState.openDateChecked
      ? filterState.openDateFinish
      : '',
    status: status,
    createdBy: filterState.createdByChecked ? filterState.createdBy : '',
    updatedBy: filterState.updatedByChecked ? filterState.updatedBy : '',
  }
}
