import { Box, IconButton, Typography } from '@mui/material'
import { useTheme } from '@mui/system'
import _ from 'lodash'
import { Clear } from '@mui/icons-material'
import { useSelector, shallowEqual } from 'react-redux'
import { useParams } from 'react-router-dom'
import { handleDeletedFilter } from '../../handler/handleDeletedFilter'

const FiltersSummary = () => {
  const theme = useTheme()
  const { uuid } = useParams()
  const { latestUpdated, filtersSummary, tabActive } = useSelector(
    (state) => ({
      latestUpdated: state.expense.dashboard.latestUpdated,
      filtersSummary: state.expense.dashboard.filtersSummary,
      tabActive: state.expense.dashboard.tabActive,
    }),
    shallowEqual,
  )

  return (
    <Box display="flex" flexDirection="column" gap={1}>
      {(tabActive !== 0 || uuid) && filtersSummary?.length > 0 && (
        <Box
          sx={{
            backgroundColor: theme.palette.common.white,
            borderRadius: 2,
            border: `1px solid ${theme.palette.blue.blueLine}`,
            width: 'fit-content',
            py: 0.75,
            px: 1.125,
            display: 'flex',
            gap: 0.75,
            flexWrap: 'wrap',
          }}
        >
          {_.map(filtersSummary, (filter, idx) => {
            return (
              <Box
                key={idx}
                sx={{
                  backgroundColor: 'rgba(219, 228, 241, 0.60)',
                  borderRadius: 1,
                  px: 1.25,
                  display: 'flex',
                  maxWidth: '100%',
                }}
              >
                <Box sx={{ width: 'auto', maxWidth: 250, pr: 1 }}>
                  <Typography variant="body2" noWrap>
                    {_.get(filter, 'textTitle')} :
                  </Typography>
                </Box>
                <Typography variant="body2b">
                  {_.join(_.get(filter, 'label', []), ', ')}
                </Typography>
                <IconButton
                  sx={{ ml: 1, p: 0 }}
                  onClick={() =>
                    handleDeletedFilter({
                      key: filter?.name,
                    })
                  }
                >
                  <Clear
                    sx={{
                      width: 16,
                      height: 16,
                      color: theme.palette.text.primary,
                    }}
                  />
                </IconButton>
              </Box>
            )
          })}
        </Box>
      )}

      <Typography variant="body2" color="action.active">
        ข้อมูลเมื่อวันที่ {latestUpdated}
      </Typography>
    </Box>
  )
}

export default FiltersSummary
