import React from 'react'
import _ from 'lodash'
import Divider from '@mui/material/Divider'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Switch from '@mui/material/Switch'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import TextInput from '../../../../../../../../components/Input/TextInput'

import * as Styled from '../../Styled'

import { handleChange, handleChangeNumber, handleBlurNumber } from '../../event'
import {
  BoxFlexInput,
  BoxTextInput,
  BoxDesktop,
  BoxMobile,
  BoxContent,
} from './Styled'

export const Input = ({ width }) => {
  const dispatch = useDispatch()
  const {
    limitTime: { minute },
    onViewMode,
    disableSetting,
  } = useSelector(
    (state) => ({
      limitTime: state.eTestingForm.setting.limitTime,
      onViewMode: state.eTestingForm.onViewMode,
      disableSetting: state.eTestingForm.disableSetting,
    }),
    shallowEqual,
  )

  return (
    <Box sx={{ width }}>
      <TextInput
        id="minute"
        name="minute"
        type="basic"
        endAdornmentText={'นาที'}
        value={minute}
        disabled={onViewMode || disableSetting}
        onBlur={(e) =>
          dispatch(
            handleBlurNumber({
              value: e.target.value,
              key: 'limitTime.minute',
            }),
          )
        }
        onChange={(e) =>
          dispatch(
            handleChangeNumber({
              max: 360,
              value: e.target.value,
              key: 'limitTime.minute',
            }),
          )
        }
        isShowTextError={false}
      />
    </Box>
  )
}

const Index = () => {
  const dispatch = useDispatch()
  const {
    limitTime: { isOpen },
    onViewMode,
    disableSetting,
  } = useSelector(
    (state) => ({
      limitTime: state.eTestingForm.setting.limitTime,
      onViewMode: state.eTestingForm.onViewMode,
      disableSetting: state.eTestingForm.disableSetting,
    }),
    shallowEqual,
  )

  return (
    <>
      <Styled.BoxForm isGap>
        <Typography variant="h6">เวลาในการทำข้อสอบ</Typography>
        <BoxContent>
          <BoxFlexInput isOpen={isOpen} paddingLeft={3}>
            <Typography variant="body1">จำกัดเวลาในการทำ</Typography>

            <BoxTextInput>
              {isOpen && (
                <BoxDesktop>
                  <Input width={100} />
                </BoxDesktop>
              )}

              <Switch
                checked={isOpen}
                disabled={onViewMode || disableSetting}
                onChange={(e) =>
                  dispatch(
                    handleChange({
                      value: _.get(e, 'target.checked', false),
                      key: 'limitTime.isOpen',
                    }),
                  )
                }
              />
            </BoxTextInput>
          </BoxFlexInput>
          {isOpen && (
            <BoxMobile>
              <Input width={'100%'} onViewMode={onViewMode} />
            </BoxMobile>
          )}
        </BoxContent>
        <Divider sx={{ mt: 2 }} />
      </Styled.BoxForm>
    </>
  )
}

export default Index
