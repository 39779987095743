import { uploadFileKey } from '../../utils/apiPath'

export const handleUploader = () => {
  const token = localStorage.getItem('token')
  return {
    insertImageAsBase64URI: false,
    imagesExtensions: ['jpg', 'png', 'jpeg', 'gif'],
    withCredentials: false,
    method: 'POST',
    url: `${window.__env__.REACT_APP_API_URL}${uploadFileKey}/editor`,
    headers: { Authorization: `${token}` },
    prepareData: (formData) => {
      var file = formData.getAll('files[0]')[0]
      formData.delete('files[0]')
      formData.append('file', file)
      formData.delete('path')
      formData.delete('source')
      return formData
    },
    queryBuild: (data) => data,
    isSuccess: (resp) => !resp.error,
    getMsg: (resp) => resp.msg,
    process: (resp) => resp,
    defaultHandlerSuccess: function (data) {
      if (data.key) {
        const file = `${window.__env__.REACT_APP_API_URL}/file/get${data.key}`
        this.selection.insertImage(file, null, 250)
      }
    },
  }
}
