import React, { useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { getEConfiguration } from '../../../../../services/eConfiguration'
import { MODULE_TYPE } from '../../../../../constants/eLearning'
import { getStateValue } from '../events'
import ETesting from './ETesting'
import EEvaluation from './EEvaluation'
import EmbedContent from './EmbedContent'
import LinkToWebsite from './LinkToWebsite'
import UploadFile from './UploadFile'
import Scorm from './Scorm'

const TypeContent = () => {
  const moduleType = useSelector(
    (state) => getStateValue(state, 'moduleType'),
    shallowEqual,
  )
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getEConfiguration({ isSetting: false }))
  }, [])

  switch (moduleType) {
    case MODULE_TYPE.E_TESTING:
      return <ETesting />
    case MODULE_TYPE.E_EVALUATION:
      return <EEvaluation />
    case MODULE_TYPE.EMBED_CONTENT:
      return <EmbedContent />
    case MODULE_TYPE.LINK_TO:
      return <LinkToWebsite />
    case MODULE_TYPE.UPLOAD_FILE:
      return <UploadFile />
    case MODULE_TYPE.SCORM:
      return <Scorm />
    default:
      return <></>
  }
}

export default TypeContent

export const TextLabel = ({ label }) => {
  return (
    <Box sx={{ display: 'flex', gap: 0.5 }}>
      <Typography variant="body2" color="text.secondary">
        {label}
      </Typography>
      <Typography variant="body2" color="error">
        *
      </Typography>
    </Box>
  )
}
