export const filterStatusItems = [
  {
    label: 'เปิดใช้งาน',
    name: 'activeChecked',
  },
  {
    label: 'ปิดใช้งาน',
    name: 'inActiveChecked',
  },
  {
    label: 'ถูกลบแล้ว',
    name: 'deletedChecked',
  },
  {
    label: 'ฉบับร่าง',
    name: 'draftChecked',
  },
]

export const filterItems = [
  {
    label: `ID`,
    nameCheckBox: 'idChecked',
    nameInput: 'id',
    placeholder: `ค้นหารหัส Collection`,
    type: 'text',
  },

  {
    label: `ชื่อ Collection`,
    nameCheckBox: 'nameChecked',
    nameInput: 'name',
    placeholder: `ค้นหาชื่อ Collection`,
    type: 'text',
  },
  {
    label: `ช่วงเวลา เปิด-ปิด รับสมัคร`,
    nameCheckBox: 'openDateChecked',
    nameInput: 'openDate',
    placeholder: `กรุณาใส่ วันที่สร้าง`,
    dateStateStart: 'openDateStart',
    dateStateFinish: 'openDateFinish',
    dateStateDisplay: 'openDisplayDateChecked',
    type: 'daterange',
  },
  {
    label: 'สร้างโดย',
    nameCheckBox: 'createdByChecked',
    nameInput: 'createdBy',
    placeholder: 'ค้นหาสร้างโดย',
    type: 'text',
  },
  {
    label: 'แก้ไขล่าสุดโดย',
    nameCheckBox: 'updatedByChecked',
    nameInput: 'updatedBy',
    placeholder: 'ค้นหาแก้ไขล่าสุดโดย',
    type: 'text',
  },
  {
    label: 'สถานะ',
    nameCheckBox: 'statusChecked',
    nameInput: 'status',
    type: 'checkbox',
    list: filterStatusItems,
  },
]
