import { store } from '../../../../App'
import {
  closeTableDrawer,
  setFieldValue,
} from '../../../../redux/slices/inventory/dashboard'
import { clearSelectDropdown } from '../components/FilterDrawer/event'
import { defaultFilter, defaultReqFilter } from '../model/defaultFilter'
import { filterItems, filterReqItems } from '../model/filterItems'
import { fetchOverview } from './fetchOverview'

export const initialize = async () => {
  // const user = JSON.parse(localStorage.getItem('user'))
  // const station = _.get(user, 'area[0].station')
  store.dispatch(closeTableDrawer())
  initFilter()
  initReqFilter()
  store.dispatch(
    setFieldValue({
      key: 'filter',
      value: {
        ...defaultFilter(),
        // station: station
        //   ? [
        //       {
        //         label: station.station,
        //         name: `${station.station.toLowerCase()}Checked`,
        //         uuid: station.uuid,
        //       },
        //     ]
        //   : [],
      },
    }),
  )
  store.dispatch(
    setFieldValue({
      key: 'overview',
      value: {},
    }),
  )
  await fetchOverview()
}

const initFilter = () => {
  clearSelectDropdown({ arrayCheckbox: filterItems() })
  store.dispatch(
    setFieldValue({
      key: 'filterMemo',
      value: defaultFilter(),
    }),
  )
  store.dispatch(
    setFieldValue({
      key: 'filtersDrawer',
      value: {
        filterTotal: 0,
        isFilterDate: false,
        isFilterStation: false,
        isOpen: false,
      },
    }),
  )
}

const initReqFilter = () => {
  clearSelectDropdown({ arrayCheckbox: filterReqItems() })
  store.dispatch(
    setFieldValue({
      key: 'filterReq',
      value: defaultReqFilter(),
    }),
  )
  store.dispatch(setFieldValue({ key: 'filterReqMemo', value: {} }))
  store.dispatch(
    setFieldValue({
      key: 'filtersReqDrawer',
      value: { filterTotal: false, isFilterStation: false },
    }),
  )
}
