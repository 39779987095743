export const headerUpload = {
  statusUpload: 'สถานะ',
  no: 'No',
  yearBudget: 'ปี Budget',
  costCenter: 'Cost Center',
  costCenterName: 'Cost Center Name',
  oldAccountCode: 'Account Code (Old)',
  newAccountCode: 'Account Code (New)',
  janBudget: 'Budget January',
  janRevised: 'Revised January',
  febBudget: 'Budget February',
  febRevised: 'Revised February',
  marBudget: 'Budget March',
  marRevised: 'Revised March',
  aprBudget: 'Budget April',
  aprRevised: 'Revised April',
  mayBudget: 'Budget May',
  mayRevised: 'Revised May',
  junBudget: 'Budget June',
  junRevised: 'Revised June',
  julBudget: 'Budget July',
  julRevised: 'Revised July',
  augBudget: 'Budget August',
  augRevised: 'Revised August',
  sepBudget: 'Budget September',
  sepRevised: 'Revised September',
  octBudget: 'Budget October',
  octRevised: 'Revised October',
  novBudget: 'Budget November',
  novRevised: 'Revised November',
  decBudget: 'Budget December',
  decRevised: 'Revised December',
}
