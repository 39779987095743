import { memo } from 'react'
import TextField from '@mui/material/TextField'
import Autocomplete from '../../../components/Input/Autocomplete'
import { useInputField } from '../hooks/useInputField'
import { setFieldValue } from '../../../redux/slices/crud'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import { Chip, Typography } from '@mui/material'

const CustomDropdown = memo(
  ({
    required,
    disabled,
    fieldName,
    labelName,
    handleExtraChange,
    boxSx = {},
    errorSx = { mb: 0 },
    sx = { width: '100%' },
    placeholder = 'กรุณาเลือก',
    masterDataName,
    isCustomOption = false,
    customOptions = { isLoading: false, options: [] },
    multiple,
    disableCloseOnSelect = false,
    disableClearable = false,
    addAllOption = false,
    groupBy,
    renderGroup,
    renderOption,
    noOptionsText,
    onFocus,
    customDefaultValue = null,
  }) => {
    const dispatch = useDispatch()
    const { value, isError, message } = useInputField(fieldName)

    const { crudConfig } = useSelector(
      (state) => ({ crudConfig: state.crud.crudConfig }),
      shallowEqual,
    )

    const { masterCategory: master } = useSelector(
      (state) => ({
        masterCategory:
          state.crud.masterData[masterDataName ? masterDataName : fieldName],
      }),
      shallowEqual,
    )

    function handleValueChange(event, value) {
      dispatch(setFieldValue({ key: `formData.${fieldName}`, value: value }))
      if (handleExtraChange) {
        handleExtraChange(event, value)
      }
    }

    if (_.isUndefined(master) && !isCustomOption) {
      return <div />
    }

    const getDefaultValue = () => {
      if (multiple) {
        return []
      }
      return customDefaultValue
    }

    const getOptions = () => {
      let options = [
        ...(isCustomOption ? customOptions.options : master.options),
      ]
      if (addAllOption) {
        options.unshift({ label: 'ทั้งหมด', value: 'all' })
      }

      return options
    }
    return (
      <Autocomplete
        boxSx={{ ...boxSx }}
        disabled={disabled}
        loading={isCustomOption ? customOptions.isLoading : master.isLoading}
        required={required}
        isHideSort={true}
        labelText={labelName}
        name={fieldName}
        defaultValue={
          _.isEmpty(value) || _.isNil(value) ? getDefaultValue() : value
        }
        filterSelectedOptions={true}
        disableCloseOnSelect={disableCloseOnSelect}
        disableClearable={disableClearable}
        isOptionEqualToValue={(option, selected) => {
          return option.label === selected.label
        }}
        id={`${crudConfig.moduleSlug}-${fieldName}`}
        options={getOptions()}
        sx={sx}
        errorSx={errorSx}
        textError={message}
        showTextError={isError}
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              inputProps={{
                ...params.inputProps,
                value: params.inputProps?.value,
                placeholder:
                  _.isEmpty(value) || _.isNil(value) ? placeholder : '',
                style: {
                  paddingLeft: '8px',
                },
              }}
            />
          )
        }}
        handleChange={handleValueChange}
        multiple={multiple}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              label={<Typography sx={{}}>{option.label}</Typography>}
              {...getTagProps({ index })}
              sx={{
                backgroundColor: '#FFFFFF',
                border: 1,
                borderColor: 'text.disabled',
              }}
              key={index}
            />
          ))
        }
        groupBy={groupBy}
        renderGroup={renderGroup}
        renderOption={renderOption}
        noOptionsText={noOptionsText}
        onFocus={onFocus}
      />
    )
  },
)

CustomDropdown.displayName = 'CustomDropdownComponent'

export { CustomDropdown }
