import { Tabs } from '@mui/material'
import { StyledTab } from './Styled'
import _ from 'lodash'

const BasicTabs = ({
  tabsList = [],
  tabActive = 0,
  handleChangeTab = () => {},
}) => {
  return (
    <Tabs
      sx={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
      }}
      variant="scrollable"
      scrollButtons="auto"
      value={tabActive}
      onChange={handleChangeTab}
    >
      {_.map(tabsList, (tab, idx) => (
        <StyledTab key={idx} label={tab?.label} disabled={tab?.disabled} />
      ))}
    </Tabs>
  )
}

export default BasicTabs
