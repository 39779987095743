import { setFiltersDashboard } from '../../../../redux/slices/expense'
import { store } from '../../../../App'

export const handleDeletedFilter = ({ key }) => {
  const { filters } = store.getState().expense.dashboard
  const keyWithOutChecked = key.replace('Checked', '')

  let objState = {
    ...filters,
    [key]: false,
    [keyWithOutChecked]: [],
  }

  store.dispatch(setFiltersDashboard(objState))
}
