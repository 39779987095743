import { Add } from '@mui/icons-material'
import { Box, Button } from '@mui/material'
import { useTheme } from '@mui/system'
import { handleOpenDrawer } from './handler/handleOpenDrawer'
import { ExpenseDrawer } from './Drawer'
import { ExpenseList } from './components/ExpenseList'
import { SummarySection } from './components/SummarySection'
import { useEffect } from 'react'
import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import { setFieldValue } from '../../redux/slices/crud'
import { initialConfig } from '../../components/CRUD/handler/initialConfig'
import { crudConfig as initialCrudConfig } from './crudConfig'
import { fetchMasterDataOptions } from './handler/fetchMasterDataOptions'
import { fetchExpense } from './handler/fetchExpense'
import { getBodyMutation } from './mutation/get'
import { StyledLoading } from './Drawer/Styled'
import _ from 'lodash'

export const Expense = ({ monthlyPlan, boxSx = {} }) => {
  const theme = useTheme()
  const dispatch = useDispatch()

  const { crudConfig, isLoading, calendarType } = useSelector(
    (state) => ({
      crudConfig: state.crud.crudConfig,
      isLoading: state.crud.isLoading,
      calendarType: state.crud.calendarType,
    }),
    shallowEqual,
  )

  useEffect(() => {
    fetchMasterDataOptions()
  }, [])

  useEffect(() => {
    dispatch(
      setFieldValue({
        key: 'monthlyPlan',
        value: monthlyPlan,
      }),
    )
    if (
      _.isUndefined(_.get(monthlyPlan, 'id', undefined)) &&
      _.isUndefined(_.get(monthlyPlan, 'uuid', undefined))
    )
      return

    fetchExpense()
  }, [monthlyPlan])

  const initializeCrud = () => {
    initialConfig(initialCrudConfig)
    dispatch(
      setFieldValue({
        key: 'formData',
        value: getBodyMutation(),
      }),
    )
    dispatch(
      setFieldValue({
        key: 'calendarType',
        value: calendarType ? calendarType : 'PERSONAL',
      }),
    )
  }

  useEffect(initializeCrud, [crudConfig])

  return (
    <Box
      sx={{ border: `1px solid ${theme.palette?.blue?.blueLine}`, ...boxSx }}
      minHeight={96}
      height="auto"
      position="relative"
      display="flex"
      flexDirection="column"
      p={1}
      gap={2}
      borderRadius={1}
    >
      {isLoading ? (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          sx={{
            width: '100%',
            height: 96,
          }}
        >
          <StyledLoading isLoading={isLoading} />
        </Box>
      ) : (
        <>
          <SummarySection />
          <Button
            sx={{ width: 130 }}
            variant="contained"
            startIcon={<Add />}
            size="medium"
            onClick={() => handleOpenDrawer()}
            disabled={_.isEmpty(monthlyPlan)}
          >
            สร้างชุดข้อมูล
          </Button>
          <ExpenseList />
          <ExpenseDrawer />
        </>
      )}
    </Box>
  )
}
