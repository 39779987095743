import _ from 'lodash'
import {
  startLoading,
  stopLoading,
  setInitialTable,
  resetPage,
  resetTablePage,
  setSearchText,
  clearFilter,
} from '../../../../../redux/slices/table2'
import {
  CRUDListFilterPath,
  getInventoryDashboard,
  inventoryDashboardRankSold,
  inventoryDashboardRankStock,
  inventoryDashboardRankWithdraw,
  inventoryDownload,
  productListFilter,
} from '../../../../../utils/apiPath'
import callAxios from '../../../../../utils/baseService'
import { store } from '../../../../../App'
import { mutateFilterPropToBody } from '../FilterDrawer/event'
import { mutateFilterPropToBody as mutateReq } from '../FilterReqDrawer/event'
import { downloadFile } from '../../../../../services/util'
import { enumProductType } from '../../../constant'
import {
  productListBody,
  assetProductListBody,
  inventoryRequisitionBody,
} from './model'
import {
  headCellsInventory,
  headCellsRanking,
  headCellsRankingName,
  headCellsRankingStock,
  headCellsRequisition,
} from './headcells'
import {
  convertFloatWithComma,
  convertFormatDateTime,
  convertNumberWithComma,
} from '../../../../../utils/lib'
import { setFieldValue } from 'src/redux/slices/crud'

export const fetchData =
  (courseType, header, tableProps) => async (dispatch) => {
    const { search, filter, table } = store.getState().table2
    const { filterReq } = store.getState().inventoryDashboard
    const { searchText } = search
    const { order, sort, page, limit } = table
    const realPage = page <= 0 ? 1 : +page

    const mutateBody = {
      ...mutateFilterPropToBody(filter),
      name: searchText,
    }

    const body = {
      limit: _.get(tableProps, 'limit', 10),
      page: realPage ?? 1,
      order: order,
      sort: sort,
      quickSearch: searchText,
      ...mutateBody,
      createdBy: _.get(filter, 'createdBy', ''),
      updatedBy: _.get(filter, 'updatedBy', ''),
    }

    let headCells = []
    switch (courseType) {
      case 'ranking':
        headCells = headCellsRanking()
        break
      case 'rankingName':
        headCells = headCellsRankingName()
        break
      case 'rankingStock':
        headCells = headCellsRankingStock()
        break
      case 'requisition':
        headCells = headCellsRequisition()
        break
      default:
        headCells = headCellsInventory()
    }
    const handleSearch = (text) => {
      dispatch(handleQuickSearch(table, text, courseType, header, tableProps))
    }

    const isCheckBox = () => {
      if (['requisition'].includes(courseType)) {
        return false
      } else return true
    }
    let fetchUrl = getInventoryDashboard
    let fetchBody = body
    let headers = undefined
    let bodyProps = {
      searchText: searchText ?? '',
      sort: sort ?? 'updatedAt',
      order: order.toUpperCase() ?? 'DESC',
      page: realPage ?? 1,
    }
    if (courseType === 'requisition') {
      switch (sort) {
        case 'trainingPlanCourse':
          bodyProps = { ...bodyProps, sort: 'trainingPlan.label' }
          break
        case 'trainingPlanDate':
          bodyProps = { ...bodyProps, sort: 'trainingPlan.startDate' }
          break
        case 'requisitionStatus':
          bodyProps = { ...bodyProps, sort: 'status' }
          break
        case 'ticketType':
          bodyProps = { ...bodyProps, sort: 'ticketType' }
          break
        default:
          break
      }
    }

    const { filter: filterDashboard } = store.getState().inventoryDashboard
    const rankingBody = {
      limit: limit ?? 100,
      sort: sort ?? 'updatedAt',
      order: order.toUpperCase() ?? 'DESC',
      page: realPage ?? 1,
      ...mutateFilterPropToBody(filterDashboard),
    }

    switch (header) {
      case 'Stock':
        fetchUrl = productListFilter
        fetchBody = productListBody(enumProductType.STOCK, {
          ...bodyProps,
          sort: bodyProps.sort ?? 'productName',
          order: bodyProps.order ?? 'ASC',
          limit: limit ?? 100,
        })
        break
      case 'Stock (SKU)':
        fetchUrl = productListFilter
        fetchBody = productListBody(enumProductType.STOCK_ASSET, {
          ...bodyProps,
          sort: bodyProps.sort ?? 'productName',
          order: bodyProps.order ?? 'ASC',
          limit: limit ?? 100,
        })
        break
      case 'Asset ที่ “มีผู้ถือครอง”':
        fetchUrl = productListFilter
        fetchBody = assetProductListBody('', {
          ...bodyProps,
          sort: bodyProps.sort ?? 'productName',
          order: bodyProps.order ?? 'ASC',
          limit: limit ?? 100,
          hasHolder: true,
        })
        break
      case 'Asset ที่ “ไม่มีผู้ถือครอง”':
        fetchUrl = productListFilter
        fetchBody = assetProductListBody('', {
          ...bodyProps,
          sort: bodyProps.sort ?? 'productName',
          order: bodyProps.order ?? 'ASC',
          limit: limit ?? 100,
          hasHolder: false,
        })
        break
      case 'รายการทรัพย์สินที่มีการเบิกมากที่สุด (Stock)':
        fetchUrl = inventoryDashboardRankSold
        fetchBody = rankingBody
        break
      case 'รายการทรัพย์สินใกล้หมด (Stock)':
        fetchUrl = inventoryDashboardRankStock
        fetchBody = rankingBody
        break
      case 'รายชื่อที่เบิกของมากที่สุด':
        fetchUrl = inventoryDashboardRankWithdraw
        fetchBody = rankingBody
        break
      case 'รายการทรัพย์สิน รอคืน':
        fetchUrl = CRUDListFilterPath
        fetchBody = {
          ...inventoryRequisitionBody(
            { ...bodyProps, isDrawer: true },
            mutateReq(filterReq),
            limit ?? '100',
          ),
          dashboardTableType: 'WAITING',
        }
        headers = {
          'x-type': 'INVENTORY_REQUISITION_MP_ALL',
        }
        break
      case 'รายการทรัพย์สิน ต้องคืน (เกินกำหนด)':
        fetchUrl = CRUDListFilterPath
        fetchBody = {
          ...inventoryRequisitionBody(
            { ...bodyProps, isDrawer: true },
            mutateReq(filterReq),
            limit ?? '100',
          ),
          dashboardTableType: 'OVERDUE',
        }
        headers = {
          'x-type': 'INVENTORY_REQUISITION_MP_ALL',
        }
        break
      default:
        break
    }

    dispatch(startLoading())
    const isCancel = await callAxios
      .post(fetchUrl, { ...fetchBody, isInventoryDashboard: true }, headers)
      .then((res) => {
        const row = _.get(res, 'data.result', []).map((item, index) => {
          return {
            ...item,
            index: (realPage - 1) * limit + 1 + index,
            total: item.total > 0 ? convertNumberWithComma(item.total) : '-',
            available:
              item.available > 0 ? convertNumberWithComma(item.available) : '-',
            stockValue:
              item.stockValue > 0
                ? convertFloatWithComma(item.stockValue)
                : '-',
            rowIndex:
              sort === 'rowIndex' && order.toUpperCase() === 'DESC'
                ? 100 - index
                : index + 1,
            createdAt: convertFormatDateTime({
              value: item.createdAt,
              type: 'date',
            }),
            trainingPlanCourse: _.get(item, 'trainingPlan.label') ?? '-',
            trainingPlanDate: convertFormatDateTime({
              value: _.get(item, 'trainingPlan.startDate'),
              type: 'date',
            }),
            ticketType:
              _.get(item, 'monthlyPlan') === null ? 'Other' : 'Monthly Plan',
            createdBy:
              typeof item.createdBy === 'object'
                ? item.createdBy.name
                : item.createdBy,
            status: courseType === 'inventory' ? 'ACTIVE' : item.status,
          }
        })
        dispatch(
          setFieldValue({
            key: 'countDrawer',
            value: _.get(res, 'data.dashboardDrawerCount')
              ? _.get(res, 'data.dashboardDrawerCount')
              : _.get(res, 'data.totalCount') ?? row.length,
          }),
        )
        dispatch(
          setInitialTable({
            rows: row,
            isCheckBox: isCheckBox(),
            allCount: _.get(res, 'data.totalCount') ?? row.length,
            headCells: headCells,
            searchKey: 'name',
            hideFilter: true,
            placeholder: 'ค้นหาชื่อทรัพย์สิน',
            rowsPerPage: 10,
            handleSearch: ['stock', 'inventory'].includes(courseType)
              ? (text) => handleSearch(text)
              : undefined,
            onDownload: ['stock', 'inventory'].includes(courseType)
              ? 'hidden'
              : undefined,
          }),
        )
      })
      .catch((e) => {
        dispatch(
          setInitialTable({
            rows: [],
            allCount: 0,
            headCells: headCells,
          }),
        )
        return e.message?.includes('method')
      })
    if (!isCancel) dispatch(stopLoading())
  }

export const handleQuickSearch =
  (table, keyword, courseType, header, tableProps) => (dispatch) => {
    dispatch(setSearchText(keyword))
    dispatch(clearFilter())
    dispatch(resetTablePage())
    if (table.page === 1) {
      dispatch(fetchData(courseType, header, tableProps))
    } else {
      dispatch(resetPage())
    }
  }

export const handleOnDownload = async () => {
  const { selected, sort, order } = store.getState().table2.table
  const { tableDrawer } = store.getState().inventoryDashboard
  store.dispatch(startLoading())
  const courseType = tableDrawer?.courseType
  let url = inventoryDownload
  let headers = undefined
  let title = 'Inventory'
  switch (courseType) {
    case 'ranking':
      url = 'report/inventory/rank/sold/download'
      title = `ทรัพย์สินที่มีการเบิกมากที่สุด (Stock)`
      break
    case 'rankingName':
      url = 'report/inventory/rank/withdraw/download'
      title = `รายชื่อที่เบิกของมากที่สุด`
      break
    case 'rankingStock':
      url = 'report/inventory/rank/stock/download'
      title = `ทรัพย์สินใกล้หมด (Stock)`
      break
    case 'requisition':
      break
    default:
      title = 'Stock'
      headers = { 'x-type': 'INVENTORY_PRODUCT_STOCK' }
      url = 'crud/download'
  }
  const { filter: filterDashboard } = store.getState().inventoryDashboard
  const body = {
    order: order.toUpperCase(),
    sort: sort,
    // type: 'CUSTOM',
    list: selected,
    ...mutateFilterPropToBody(filterDashboard),
  }
  await store.dispatch(
    downloadFile({
      url: url,
      body: body,
      fileName: `รายการ ${title}.xlsx`,
      headers,
    }),
  )
  store.dispatch(stopLoading())
}
