export const arrayFlagStatus = [
  {
    label: 'Auto',
    name: 'AutoChecked',
  },
  {
    label: 'Manual',
    name: 'ManualChecked',
  },
  {
    label: 'Admin',
    name: 'AdminChecked',
  },
]

export const arrayLicenseStatus = [
  {
    label: 'รอตรวจสอบ',
    name: 'WAITING',
  },
  {
    label: 'ปรับสถานะครบแล้ว',
    name: 'STATUS_SUCCESS',
  },
]

export const arrayRcmsStatus = [
  {
    label: 'ยังไม่มีสถานะ',
    name: 'NO_STATUS',
  },
  {
    label: 'สำเร็จ',
    name: 'SUCCESS',
  },
  {
    label: 'ไม่สำเร็จ',
    name: 'NOT_SUCCESS',
  },
  {
    label: 'ไม่ต้องส่ง',
    name: 'NOT_SEND',
  },
  {
    label: 'รอส่ง',
    name: 'WAIT_SEND',
  },
]

export const arrayCheckbox = {
  api_to_rcms: [
    {
      label: 'รหัสหลักสูตร',
      nameCheckBox: 'courseCodeChecked',
      nameInput: 'courseCode',
      placeholder: 'ค้นหา',
    },
    {
      label: 'ชื่อหลักสูตร EN',
      nameCheckBox: 'courseEnglishNameChecked',
      nameInput: 'courseEnglishName',
      placeholder: 'ค้นหา',
    },
    {
      label: 'ชื่อหลักสูตร (สำหรับ Learner)',
      nameCheckBox: 'nameForLearnerChecked',
      nameInput: 'nameForLearner',
      placeholder: 'ค้นหา',
    },
    {
      label: 'ชื่อใบอนุญาต',
      nameCheckBox: 'licenseDescriptionChecked',
      nameInput: 'licenseDescription',
      placeholder: 'ค้นหา',
    },
  ],
  api_to_rcms_filter: [
    {
      label: 'สร้างโดย',
      nameCheckBox: 'createdByChecked',
      nameInput: 'createdBy',
      placeholder: 'ค้นหา',
    },
    {
      label: 'วันที่สร้าง',
      nameCheckBox: 'createdAtChecked',
      nameInput: 'createdAt',
      placeholder: 'วันที่สร้าง',
      isDate2: true,
    },
    {
      label: 'แก้ไขล่าสุดโดย',
      nameCheckBox: 'updatedByChecked',
      nameInput: 'updatedBy',
      placeholder: 'ค้นหา',
      type: 'text',
    },
  ],
  api_to_rcms_date: [
    {
      label: 'วันสิ้นสุดอบรม',
      nameCheckBox: 'startDateChecked',
      nameInput: 'startDate',
      placeholder: 'วันเริ่มต้น - วันสิ้นสุด',
      isDate: true,
    },
  ],
}

export const defaultFilter = {
  api_to_rcms: {
    courseCodeChecked: false,
    courseCode: '',
    courseEnglishNameChecked: false,
    courseEnglishName: '',
    nameForLearnerChecked: false,
    nameForLearner: '',
    licenseDescriptionChecked: false,
    createdBy: '',
    createdByChecked: false,
    updatedByChecked: false,
    updatedBy: '',
    licenseDescription: '',
    flagChecked: false,
    flag: {
      AutoChecked: false,
      ManualChecked: false,
      AdminChecked: false,
    },
    licenseStatusChecked: false,
    licenseStatus: {
      WAITING: false,
      STATUS_SUCCESS: false,
    },
    rcmsStatusChecked: false,
    rcmsStatus: {
      NO_STATUS: false,
      SUCCESS: false,
      NOT_SUCCESS: false,
      WAIT_SEND: false,
    },
    createdAtChecked: false,
    createdAt: '',
    startDateChecked: false,
    startDate: '',
    toDate: '',
  },
}
