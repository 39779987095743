import {
  setPayloadKey,
  startLoading3,
  stopLoading3,
} from '../../../../../redux/slices/manage/prospectProfile'
import axios from 'axios'
import {
  prospectProfileCourseView,
  staffProfileCourseView,
} from '../../../../../utils/apiPath'

export const fetchCourse = (body) => async (dispatch) => {
  dispatch(startLoading3())
  const response = await axios.post(
    body?.type === 'NON_ACADEMY'
      ? staffProfileCourseView
      : prospectProfileCourseView,
    body,
  )
  dispatch(setPayloadKey({ key: 'course', data: response?.data?.course }))
  dispatch(stopLoading3())
}
