import React, { useMemo, useState } from 'react'

import _ from 'lodash'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import Button from '@mui/material/Button'
import History from '@mui/icons-material/History'
import IconButton from '@mui/material/IconButton'

import Academy from './Academy/index'
import Training from './Training/index'
import { LIST_TYPE_TRAINING } from '../../Text'
import TrainingOther from './TrainingOther/index'
import { themeColor } from '../../../../utils/themeData'
import { convertFormatDateTime } from '../../../../utils/lib'
import ViewImage from '../../../../components/Upload/BasicUpload'

import DropDownMenu from './DropDownMenu'
import EditInput from './EditInput'
import TitleEdit from './TitleEdit'
import useLocalStorage from '@rehooks/local-storage'
import HistoryDrawer from './History'

import Divider from '@mui/material/Divider'

export const findTypeDate = (typeDate) => {
  const findDate = LIST_TYPE_TRAINING.find((item) => {
    return item.value === typeDate
  })
  return findDate?.label ? findDate.label : ''
}

export const FormGroupLeaveDate = ({ listDate }) => {
  if (listDate.length) {
    return listDate.map((item, index) => {
      return (
        <Box key={index} sx={{ display: 'flex', mb: 1 }}>
          <Typography variant="body1" sx={{ width: 200, mr: 3 }}>
            {convertFormatDateTime({
              value: _.get(item, 'date', null),
              type: 'date',
            })}
          </Typography>
          <Typography variant="body1">{findTypeDate(item.typeTime)}</Typography>
        </Box>
      )
    })
  } else {
    return <></>
  }
}

export const onClickLink = (link) => {
  window.open(link)
}

export const FormGroupAcademyDate = ({
  listDate,
  convertTime,
  disableType,
}) => {
  if (listDate.length) {
    return listDate.map((item, index) => {
      return (
        <Box key={index} sx={{ display: 'flex', mb: 1 }}>
          <Typography variant="body1" sx={{ width: '30%', mr: 3 }}>
            {convertFormatDateTime({
              value: _.get(item, 'date', null),
              type: 'date',
            })}
          </Typography>
          <Typography variant="body1" sx={{ width: '30%', mr: 3 }}>
            {`${convertTime(item.startTime)} - ${convertTime(item.endTime)}`}
          </Typography>
          {(item.link || _.get(item, 'meetingRoom.roomName', '')) && (
            <Typography
              variant="body1"
              sx={{
                width: '30%',
                mr: 3,
                wordBreak: 'break-all',
                cursor: item.link ? 'pointer' : 'auto',
              }}
              onClick={() => item.link && onClickLink(item.link)}
            >
              {item.link || _.get(item, 'meetingRoom.roomName', '')}
            </Typography>
          )}

          {!disableType && findTypeDate(item.typeTime) && (
            <Typography sx={{ width: '30%', mr: 3 }} variant="body1">
              {findTypeDate(item.typeTime)}
            </Typography>
          )}
        </Box>
      )
    })
  } else {
    return <></>
  }
}

export const FormGroupLeaveStaff = ({ listStaff }) => {
  const [isShowAll, setShowAll] = useState(false)
  const listSaffDisplay = useMemo(
    () => (isShowAll ? listStaff : _.slice(listStaff, 0, 5)),
    [listStaff, isShowAll],
  )
  if (listSaffDisplay.length) {
    return (
      <>
        {listSaffDisplay.map((item, index) => {
          return (
            <Box
              key={index}
              sx={{ display: 'flex', alignItems: 'center', mt: 1 }}
            >
              {!_.isNil(item.image) ? (
                <ViewImage
                  value={item.image}
                  isShowDescription={false}
                  isPreview={true}
                  isShowTextError={false}
                  isStopPropagation={true}
                  style={{
                    width: '36px',
                    height: '36px',
                    marginRight: '8px',
                    borderRadius: '50%',
                  }}
                />
              ) : (
                <AccountCircleRoundedIcon sx={{ width: 36, height: 36 }} />
              )}
              <Typography variant="body1b" sx={{ pl: 3 }}>{`${_.get(
                item,
                'firstNameTH',
                '',
              )} ${_.get(item, 'lastNameTH', '')}`}</Typography>
            </Box>
          )
        })}
        {listStaff.length > 5 && (
          <MoreLessButton isShowAll={isShowAll} setShowAll={setShowAll} />
        )}
      </>
    )
  } else {
    return <></>
  }
}

export const MoreLessButton = ({ isShowAll, setShowAll }) => {
  if (!isShowAll) {
    return (
      <Button
        sx={{ mt: 2 }}
        data-testid="btn-more"
        size="s"
        onClick={() => setShowAll(true)}
        endIcon={<ExpandMoreIcon size="small" />}
      >
        ดูทั้งหมด
      </Button>
    )
  }

  return (
    <Button
      sx={{ mt: 2 }}
      data-testid="btn-less"
      size="s"
      onClick={() => setShowAll(false)}
      endIcon={<ExpandLessIcon size="small" />}
    >
      ดูน้อยลง
    </Button>
  )
}

export const BoxTrainer = ({
  item,
  convertTime,
  myArea,
  speakerProductType,
}) => {
  const [show, setShow] = useState(false)
  const [type, setType] = useState('update')

  const [user] = useLocalStorage('user')
  const userArea = _.get(user, 'area', []).map((a) => a.uuid)

  const checkUserArea = userArea.find((area) => area === item.staff.defaultArea)

  let check = false
  if (myArea || checkUserArea) {
    check = true
  }
  return (
    <Box>
      {show && <TitleEdit type={type} />}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          mb: 0,
          mt: 1,
        }}
      >
        {!_.isNil(item.staff.image) ? (
          <ViewImage
            value={item.staff.image}
            isShowDescription={false}
            isPreview={true}
            isShowTextError={false}
            isStopPropagation={true}
            style={{
              width: '36px',
              height: '36px',
              borderRadius: '50%',
            }}
          />
        ) : (
          <AccountCircleRoundedIcon sx={{ width: '36px', height: '36px' }} />
        )}
        <Box sx={{ width: 'calc(100% - 92px)' }}>
          <Typography variant="body1b" sx={{ pl: 3 }}>
            {`${_.get(item, 'staff.firstNameTH', '')} ${_.get(
              item,
              'staff.lastNameTH',
              '',
            )}`}
          </Typography>
          <Box sx={{ display: 'flex' }}>
            <Typography variant="body1" sx={{ pl: 3, width: 232, mr: 3 }}>
              {`${_.get(item, 'responsibility.responsibility', '-')}`}
            </Typography>
            <Typography variant="body1" sx={{ pl: 3, width: 232, mr: 3 }}>
              {convertTime(_.get(item, 'startTime', ''))} -{' '}
              {convertTime(_.get(item, 'endTime', ''))}
            </Typography>
          </Box>
        </Box>
        {check && (
          <DropDownMenu
            setShow={setShow}
            setType={setType}
            checkUserArea={checkUserArea}
          />
        )}
      </Box>
      {show && (
        <EditInput
          setShow={setShow}
          type={type}
          item={item}
          speakerProductType={speakerProductType}
        />
      )}
    </Box>
  )
}

export const TrainerList = ({
  speakers,
  convertTime,
  onClose,
  myArea,
  speakerProductType,
}) => {
  const [stateExpand, setStateExpand] = useState(false)

  return (
    <>
      {speakers.map((item, index) => {
        return (
          <>
            {index <= 4 && (
              <BoxTrainer
                item={item}
                convertTime={convertTime}
                onClose={onClose}
                myArea={myArea}
                speakerProductType={speakerProductType}
              />
            )}
            {index > 4 && stateExpand && (
              <BoxTrainer
                item={item}
                convertTime={convertTime}
                onClose={onClose}
                myArea={myArea}
                speakerProductType={speakerProductType}
              />
            )}
            {index + 1 < speakers.length && <Divider />}
          </>
        )
      })}

      {speakers.length >= 6 && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            pl: '12%',
            cursor: 'pointer',
            width: '30%',
          }}
          onClick={() => setStateExpand(!stateExpand)}
        >
          <Typography variant="body2b" color={themeColor.primary.main}>
            {stateExpand ? 'ดูน้อยลง' : `ดูอีก ${speakers.length - 5}`}
          </Typography>
          {stateExpand && <ExpandLessIcon />}
          {!stateExpand && <ExpandMoreIcon />}
        </Box>
      )}
    </>
  )
}

export const checkRoom = (obj, workType, isOnline, dataList, plans) => {
  let result = ''
  let text = isOnline ? 'Link ห้อง' : 'ห้อง'
  if (_.isEmpty(obj)) return <></>

  const checkApproved = _.get(dataList, 'assignNee', {})
  const meetingRoom = _.filter(
    _.get(dataList, `roomManagementMeetingRoom`, []),
    (item) => {
      return item.bookingDate === _.get(obj, `date`)
    },
  )

  if (workType === 'Training' || workType === 'Activity Support') {
    const listLink = _.get(plans, 'links', [])
    const listMeetingRoom = _.get(obj, 'meetingRooms', [])

    let value = _.get(obj, 'location.classRoomOther', '')
    result = value ? value : '-'

    if (_.size(meetingRoom) > 0) {
      let meetingRoomManagement = []
      for (const room of meetingRoom) {
        if (!_.isEmpty(room.meetingRoom)) {
          const roomName =
            _.get(room, 'meetingRoom.roomName', '') +
            ' (ชั้น' +
            _.get(room, 'meetingRoom.floor', '') +
            ')'
          if (!_.includes(meetingRoomManagement, roomName)) {
            meetingRoomManagement.push(roomName)
          }
        } else {
          const roomNameOther = _.get(room, 'meetingRoomOther', '')
          if (
            !_.includes(meetingRoomManagement, roomNameOther) &&
            !_.isEmpty(roomNameOther)
          ) {
            meetingRoomManagement.push(roomNameOther)
          }
        }
      }
      text = 'ห้อง'
      result = meetingRoomManagement.join(', ')
    } else {
      if (!_.isEmpty(checkApproved) && isOnline) result = listLink

      if (!isOnline) {
        if (
          (!_.isEmpty(checkApproved) && workType === 'Training') ||
          workType === 'Activity Support'
        ) {
          let arrayMeetingRoom = []
          if (listMeetingRoom.length) {
            _.forIn(listMeetingRoom, (item) => {
              arrayMeetingRoom.push(`${item.roomName}`)
            })
            result = arrayMeetingRoom.join(', ')
          }
        }
      }
    }
  }
  if (workType === 'Academy Activity') {
    result = _.get(obj, 'meetingRoom.roomName', '') || _.get(obj, 'link', '')
  }

  // onClickLink
  return _.isEmpty(result) ? (
    <></>
  ) : (
    <Box sx={{ wordWrap: 'break-word' }}>
      {!_.isEmpty(checkApproved) && isOnline && _.isArray(result) && (
        <>
          {result.map((item, index) => {
            return (
              <Box key={index} sx={{ mx: 1, wordBreak: 'break-all' }}>
                <Typography
                  variant="body1"
                  sx={{
                    cursor: isOnline ? 'pointer' : 'auto',
                    textAlign: 'center',
                  }}
                  onClick={() => isOnline && onClickLink(item.url)}
                >
                  {text} {item.url} {`(ความจุห้อง : ${item.capacity})`}
                </Typography>
              </Box>
            )
          })}
        </>
      )}

      {(_.isEmpty(checkApproved) || !isOnline || _.size(meetingRoom) > 0) && (
        <Typography
          variant="body1"
          sx={{
            cursor: isOnline ? 'pointer' : 'auto',
            textAlign: 'center',
            mx: 1,
          }}
          onClick={() => isOnline && onClickLink(result)}
        >
          {text} {result}
        </Typography>
      )}
    </Box>
  )
}

export const ClassRoom = ({
  dataList,
  convertTime,
  ignore,
  workType,
  onClose,
  myArea,
}) => {
  let classTime = ``
  const listDates = _.get(dataList, 'dates', [])
  const speakerProductType = _.get(dataList, 'course.speakerProductType', [])
  const [isOpen, setOpen] = useState(false)
  if (listDates.length === 1) {
    classTime = `${convertFormatDateTime({
      value: _.get(dataList, 'startDate', null),
      type: 'date',
    })} - ${convertFormatDateTime({
      value: _.get(dataList, 'finishDate', null),
      type: 'date',
    })}`
  }
  if (listDates.length) {
    return listDates.map((item, index) => {
      const isOnline = _.get(item, 'location.online', false)
      const plans = _.get(dataList, `plans[${index}]`, {})

      return (
        <Box
          key={index}
          sx={{ border: '1px solid rgba(219, 228, 241, 0.6)', mb: 1 }}
        >
          <Box sx={{ m: 1 }}>
            <Box
              sx={{
                backgroundColor: themeColor.blue.blueLine,
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Box sx={{ textAlign: 'center', width: 'calc(100% - 40px)' }}>
                  <Typography variant="body1b">
                    {classTime
                      ? classTime
                      : convertFormatDateTime({
                          value: _.get(item, 'date', null),
                          type: 'date',
                        })}
                  </Typography>
                  <Typography variant="body1b" sx={{ ml: 0.5 }}>
                    {findTypeDate(item.typeTime)}
                  </Typography>
                  <Typography variant="body1b" sx={{ ml: 0.5 }}>
                    {convertTime(item?.startTime)} -{' '}
                    {convertTime(item?.endTime)}
                  </Typography>
                  {!ignore &&
                    checkRoom(item, workType, isOnline, dataList, plans)}
                </Box>
                <Box sx={{ width: '49px' }}>
                  <IconButton onClick={() => setOpen(true)}>
                    <History />
                  </IconButton>
                  <HistoryDrawer
                    isOpen={isOpen}
                    onCloseDrawer={() => setOpen(false)}
                    planUuid={plans.uuid}
                    typeTime={findTypeDate(item.typeTime)}
                    classTime={convertFormatDateTime({
                      value: _.get(item, 'date', null),
                      type: 'date',
                    })}
                    startTime={convertTime(item.startTime)}
                    endTime={convertTime(item.endTime)}
                    checkRoom={checkRoom(
                      item,
                      workType,
                      isOnline,
                      dataList,
                      plans,
                    )}
                  />
                </Box>
              </Box>
            </Box>
            {_.get(item, 'speakers', []).length > 0 && (
              <TrainerList
                speakers={_.get(item, 'speakers', [])}
                convertTime={convertTime}
                onClose={onClose}
                myArea={myArea}
                speakerProductType={speakerProductType}
              />
            )}
          </Box>
        </Box>
      )
    })
  } else {
    return <></>
  }
}

export default function FormView({
  dataList,
  convertTime,
  options,
  state,
  setDialog,
  fetchEvent,
  setIsDrawer,
  onClose,
}) {
  const workType = _.get(dataList, 'workType.workType', '')
  const workArea = _.get(dataList, 'area.uuid', '')
  const area = options.area.filter(
    (a) => _.get(a, 'area.uuid', '') === _.get(state, 'area.value', ''),
  )
  const [user] = useLocalStorage('user')

  const myArea = workArea === _.get(user, 'defaultArea', '') ? true : false

  return (
    <Box sx={{ my: 4 }}>
      {workType === 'Holiday' && (
        <>
          <Box sx={{ display: 'flex' }}>
            <Typography variant="body1" sx={{ width: 200, mr: 3 }}>
              ประเภทวันหยุด
            </Typography>
            <Typography variant="body1">
              {dataList?.subWorkType === 'publicHoliday'
                ? 'Public Holiday'
                : 'Additional Public Holiday'}
            </Typography>
          </Box>

          <Box sx={{ display: 'flex' }}>
            <Typography
              variant="body1"
              sx={{ width: 200, minWidth: 200, mr: 3 }}
            >
              หมายเหตุ
            </Typography>
            <Typography variant="body1">{dataList?.remark || '-'}</Typography>
          </Box>
        </>
      )}

      {workType === 'Leave' && (
        <>
          <Typography variant="body1" sx={{ width: 200, mr: 3, mb: 2 }}>
            ผู้ลา
          </Typography>

          <FormGroupLeaveStaff listStaff={dataList.staffs || []} />

          <FormGroupLeaveDate listDate={dataList.plans} />
          <Box sx={{ display: 'flex', mt: 3 }}>
            <Typography
              variant="body1"
              sx={{ width: 200, minWidth: 200, mr: 3 }}
            >
              เหตุผลการลา
            </Typography>
            <Typography variant="body1">
              {dataList?.description || '-'}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex' }}>
            <Typography
              variant="body1"
              sx={{ width: 200, minWidth: 200, mr: 3 }}
            >
              หมายเหตุ
            </Typography>
            <Typography variant="body1">{dataList?.remark || '-'}</Typography>
          </Box>
        </>
      )}

      {workType === 'Academy Activity' && (
        <>
          <Academy
            convertTime={convertTime}
            dataList={dataList}
            workType={workType}
          />
        </>
      )}

      {workType === 'Training' && (
        <>
          <ClassRoom
            sx={{ my: 4 }}
            dataList={dataList}
            convertTime={convertTime}
            ignore={false}
            workType={workType}
            onClose={onClose}
            myArea={myArea}
          />
          <Training
            dataList={dataList}
            area={area}
            state={state}
            setDialog={setDialog}
            fetchEvent={fetchEvent}
            setIsDrawer={setIsDrawer}
          />
        </>
      )}

      {workType === 'Activity Support' && (
        <>
          <ClassRoom
            sx={{ my: 4 }}
            dataList={dataList}
            convertTime={convertTime}
            ignore={false}
            workType={workType}
            onClose={onClose}
            myArea={myArea}
          />
          <TrainingOther
            dataList={dataList}
            area={area}
            state={state}
            setDialog={setDialog}
            fetchEvent={fetchEvent}
            setIsDrawer={setIsDrawer}
          />
        </>
      )}
    </Box>
  )
}
