import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  IconButton,
  List,
  ListItem,
  Typography,
} from '@mui/material'
import { StyledDrawer, StyledHeadLabel, StyledFooter } from '../../../Styled'
import { Close } from '@mui/icons-material'
import { setIsOpenHelperDrawer } from '../../../../../redux/slices/monthlyPlan'
import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import { ToggleContent } from '../ToggleContent'

const HelperDrawer = () => {
  const dispatch = useDispatch()
  const { isOpenHelperDrawer } = useSelector(
    (state) => ({
      isOpenHelperDrawer: state.monthlyPlan.dashboard.isOpenHelperDrawer,
    }),
    shallowEqual,
  )

  const helper1List = [
    {
      label: 'Training Hours',
      text: 'จำนวนวันทั้งหมดของตารางงานประเภท Training (Classroom, Virtual Classroom)',
    },
    {
      label: 'Holidays',
      text: 'จำนวนวันทั้งหมดของตารางงานประเภท Holiday (Weekend, Public Holiday, Additional Public Holiday)',
    },
    {
      label: 'Activity Support',
      text: 'จำนวนวันทั้งหมดของตารางงานประเภท Activity Support (Classroom, Virtual Classroom)',
    },
    {
      label: 'Self Development',
      text: 'จำนวนวันทั้งหมดของตารางงานประเภท Academy Activity ที่เป็น External Training, Academy Training, Certified',
    },
    {
      label: 'Office Work',
      text: 'จำนวนวันทั้งหมดของตารางงานประเภท Academy Activity ที่เป็น Office Work',
    },
  ]

  const helper2List = [
    {
      label: 'Agency',
      text: 'จำนวนวันทั้งหมดของตารางงานประเภท Training (Classroom, Virtual Classroom) ที่เป็น Agency',
    },
    {
      label: 'Banc/KTB',
      text: 'จำนวนวันทั้งหมดของตารางงานประเภท Training (Classroom, Virtual Classroom) ที่เป็น Banc หรือ KTB รวมกัน',
    },
    {
      label: 'FSA/CS',
      text: 'จำนวนวันทั้งหมดของตารางงานประเภท Training (Classroom, Virtual Classroom) ที่เป็น Corporate Solutions (CS)',
    },
    {
      label: 'Activity Support',
      text: 'จำนวนวันทั้งหมดของตารางงานประเภท Activity Support (Classroom, Virtual Classroom)',
    },
    {
      label: 'Office Work',
      text: 'จำนวนวันทั้งหมดของตารางงานประเภท Academy Activity ที่เป็น Office Work',
    },
    {
      label: 'เดินทาง',
      text: 'จำนวนวันทั้งหมดของตารางงานประเภท Academy Activity ที่เป็น Travelling',
    },
    {
      label: 'Development',
      text: 'จำนวนวันทั้งหมดของตารางงานประเภท Academy Activity ที่เป็น External Training, Academy Training, Certified',
    },
    {
      label: 'T_Meeting/T_Other',
      text: 'จำนวนวันทั้งหมดของตารางงานประเภท Academy Activity ที่เป็น Meeting, Other Activity',
    },
    {
      label: 'Holidays',
      text: 'จำนวนวันทั้งหมดของตารางงานประเภท Holiday (Weekend, Public Holiday, Additional Public Holiday)',
    },
    {
      label: 'Day Off',
      text: 'จำนวนวันทั้งหมดของตารางงานประเภท Leave ที่เป็นประเภทย่อย "Day Off" เท่านั้น',
    },
  ]

  return (
    <StyledDrawer
      sx={{
        position: 'relative',
        '& .MuiPaper-root': {
          width: 900,
          borderTopLeftRadius: 16,
          borderBottomLeftRadius: 16,
        },
      }}
      open={isOpenHelperDrawer}
      onClose={() => dispatch(setIsOpenHelperDrawer(false))}
    >
      <StyledHeadLabel sx={{ mb: 1.25 }}>
        <Box>
          <Typography variant="h5">ประเภทงานที่แสดงในแต่ละกราฟ</Typography>
          <IconButton
            data-testid="btn-close-drawer"
            color="primary"
            component="span"
            onClick={() => dispatch(setIsOpenHelperDrawer(false))}
          >
            <Close />
          </IconButton>
        </Box>
        <Divider />
      </StyledHeadLabel>
      <Box mx={3} my={2}>
        <Card sx={{ mb: 2 }}>
          <CardContent
            sx={{ py: 0 }}
            style={{
              paddingBottom: 0,
            }}
          >
            <ToggleContent
              title={`กราฟที่มี 5 มุม ที่แท็บย่อย "ภาพรวม" และ "รายบุคคล Main Category"`}
              contentId="part1"
            >
              <Box mx={2}>
                <List sx={{ listStyleType: 'disc' }}>
                  {helper1List.map((item, index) => (
                    <ListItem
                      key={index}
                      sx={{ display: 'list-item', padding: 0 }}
                    >
                      <Box>
                        <Typography variant="body1b" color={'text.secondary'}>
                          {item.label}
                        </Typography>
                        <Typography variant="body1" color={'text.secondary'}>
                          {item.text}
                        </Typography>
                      </Box>
                    </ListItem>
                  ))}
                </List>
              </Box>
            </ToggleContent>
          </CardContent>
        </Card>

        <Card sx={{ mb: 2 }}>
          <CardContent
            sx={{ py: 0 }}
            style={{
              paddingBottom: 0,
            }}
          >
            <ToggleContent
              title={`กราฟที่มี 10 มุม ที่แท็บย่อย "รายบุคคล Sub Category"`}
              contentId="part1"
            >
              <Box mx={2}>
                <List sx={{ listStyleType: 'disc' }}>
                  {helper2List.map((item, index) => (
                    <ListItem
                      key={index}
                      sx={{ display: 'list-item', padding: 0 }}
                    >
                      <Box>
                        <Typography variant="body1b" color={'text.secondary'}>
                          {item.label}
                        </Typography>
                        <Typography variant="body1" color={'text.secondary'}>
                          {item.text}
                        </Typography>
                      </Box>
                    </ListItem>
                  ))}
                </List>
              </Box>
            </ToggleContent>
          </CardContent>
        </Card>
      </Box>

      <StyledFooter>
        <Typography
          variant="body2b"
          color="primary.main"
          pl={1}
          pt={2}
          textTransform={'uppercase'}
        >
          Note: จำนวนวันในตารางและกราฟของ Workload Dashboard จะปัดเศษทิ้งหมด
          และแสดงแค่เต็มวันเท่านั้น
        </Typography>
        <Button
          sx={{ ml: 'auto', maxWidth: 40 }}
          data-testid="btn-submit"
          variant="contained"
          onClick={() => dispatch(setIsOpenHelperDrawer(false))}
        >
          ปิด
        </Button>
      </StyledFooter>
    </StyledDrawer>
  )
}

export default HelperDrawer
