export const arrayRoomFunction = [
  {
    label: 'Single',
    name: 'singleChecked',
  },
  {
    label: 'Connecting',
    name: 'connectingChecked',
  },
  {
    label: 'Other',
    name: 'roomFunctionOtherChecked',
  },
]

export const arrayRoomType = [
  {
    label: 'Classroom',
    name: 'classroomChecked',
  },
  {
    label: 'Computer Room',
    name: 'computerRoomChecked',
  },
  {
    label: 'Meeting Room',
    name: 'meetingRoomChecked',
  },
  {
    label: 'Other',
    name: 'roomTypeOtherChecked',
  },
]

export const arrayRoomLayout = [
  {
    label: 'Classroom',
    name: 'classroomChecked',
  },
  {
    label: 'Group',
    name: 'groupChecked',
  },
  {
    label: 'U Shape',
    name: 'uShapeChecked',
  },
  {
    label: 'Theater',
    name: 'theaterChecked',
  },
]

export const arrayStatus = [
  {
    label: 'เปิดใช้งาน',
    name: 'activeChecked',
  },
  {
    label: 'ปิดใช้งาน',
    name: 'inActiveChecked',
  },
  {
    label: 'ถูกลบแล้ว',
    name: 'deletedChecked',
  },
]

export const arrayCheckbox = [
  {
    label: 'Station',
    nameCheckBox: 'stationChecked',
    nameInput: 'station',
    placeholder: 'ค้นหาชื่อห้อง',
    type: 'checkbox',
    list: [],
  },
  {
    label: 'ชื่อห้อง',
    nameCheckBox: 'roomNameChecked',
    nameInput: 'roomName',
    placeholder: 'ค้นหาชื่อห้อง',
    type: 'text',
  },
  {
    label: 'รูปแบบการจัดห้อง',
    nameCheckBox: 'roomLayoutChecked',
    nameInput: 'roomLayout',
    type: 'checkbox',
    list: arrayRoomLayout,
  },
  {
    label: 'ฟังก์ชันห้อง',
    nameCheckBox: 'roomFunctionChecked',
    nameInput: 'roomFunction',
    type: 'checkbox',
    list: arrayRoomFunction,
  },
  {
    label: 'ประเภทห้อง',
    nameCheckBox: 'roomTypeChecked',
    nameInput: 'roomType',
    type: 'checkbox',
    list: arrayRoomType,
  },
  {
    label: 'สถานะ',
    nameCheckBox: 'statusChecked',
    nameInput: 'status',
    type: 'checkbox',
    list: arrayStatus,
  },
  {
    label: 'สร้างโดย',
    nameCheckBox: 'createdByChecked',
    nameInput: 'createdBy',
    placeholder: 'ค้นหาสร้างโดย',
    type: 'text',
  },
  {
    label: 'แก้ไขล่าสุดโดย',
    nameCheckBox: 'updatedByChecked',
    nameInput: 'updatedBy',
    placeholder: 'ค้นหาแก้ไขล่าสุดโดย',
    type: 'text',
  },
]

export const defaultFilter = {
  stationChecked: false,
  roomNameChecked: false,
  roomLayoutChecked: false,
  roomFunctionChecked: false,
  roomTypeChecked: false,
  statusChecked: false,
  roomLayout: {
    classroomChecked: false,
    groupChecked: false,
    uShapeChecked: false,
    theaterChecked: false,
  },
  roomFunction: {
    singleChecked: false,
    connectingChecked: false,
    roomFunctionOtherChecked: false,
  },
  roomType: {
    classroomChecked: false,
    computerRoomChecked: false,
    meetingRoomChecked: false,
    roomTypeOtherChecked: false,
  },
  status: {
    activeChecked: false,
    inActiveChecked: false,
    deletedChecked: false,
  },
  createdByChecked: false,
  createdBy: '',
  updatedByChecked: false,
  updatedBy: '',
}
