import { Box, Button, Typography, Divider } from '@mui/material'
import { useTheme } from '@mui/system'
import _ from 'lodash'
import dayjs from 'dayjs'
import { typeTime as timeOptions } from '../../../../../constants/MasterData/form'
import { RoomAdminSelector } from './RoomAdminSelector'
import { handleFetchRoomForm } from 'src/modules/ManageClass/Setting/Form/DateCard/events'
import { Add } from '@mui/icons-material'
import { setAddOnlineRoom } from 'src/redux/slices/manageClassForm'
import { useDispatch } from 'react-redux'
import { SelectSubAdmins } from './SelectSubAdmins'
import { Fragment, useState } from 'react'
import { initOnlineRoom } from 'src/redux/slices/manageClassForm/model'

export const TrainingRoomsByDate = ({
  idxD,
  date,
  typeTime = 'FULL',
  startTime,
  endTime,
  onlineRoom = [],
  isClassroom,
}) => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const [newOnlineRoom, setNewOnlineRoom] = useState(onlineRoom)

  let meetingRoomsByDate = isClassroom
    ? handleFetchRoomForm(idxD)
    : newOnlineRoom

  return (
    <Box
      p={1}
      borderRadius={1}
      border="1px solid"
      borderColor={theme.palette.blue.blueLine}
      gap={1}
    >
      <Box
        sx={{ borderTopLeftRadius: 4, borderTopRightRadius: 4 }}
        backgroundColor={theme.palette.blue.blueLine}
        px={1}
        py={0.5}
        display="flex"
        justifyContent="center"
      >
        <Typography variant="body1b">
          {dayjs(date).format('DD/MM/YYYY')} {timeOptions[typeTime]} {startTime}{' '}
          - {endTime}
        </Typography>
      </Box>

      {isClassroom ? (
        <SelectSubAdmins idxD={idxD} isClassroom={isClassroom} />
      ) : (
        _.map(meetingRoomsByDate, (room, idxR) => {
          return (
            <Fragment key={idxR}>
              <RoomAdminSelector
                idxR={idxR}
                {...room}
                idxD={idxD}
                setNewOnlineRoom={setNewOnlineRoom}
              />
              <SelectSubAdmins
                onlineRoom={newOnlineRoom[idxR]}
                idxR={idxR}
                isClassroom={isClassroom}
                idxD={idxD}
              />
              <Divider sx={{ my: 2 }} />
            </Fragment>
          )
        })
      )}

      {!isClassroom && (
        <Button
          sx={{
            width: 90,
            px: 1.25,
            py: 0.5,
            my: 2,
            mx: 1,
          }}
          variant="outlined"
          size="s"
          startIcon={<Add />}
          onClick={() => {
            const newRoom = [...newOnlineRoom]
            newRoom.push({
              ...initOnlineRoom,
              no: newOnlineRoom.length + 1,
            })
            setNewOnlineRoom(newRoom)
            dispatch(setAddOnlineRoom({ index: idxD }))
          }}
        >
          ห้อง
        </Button>
      )}
    </Box>
  )
}
