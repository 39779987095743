import React from 'react'
import { Box } from '@mui/material'
import { Typography } from '@mui/material'

import TextInput from '../../../../components/Input/TextInput'
import { StyledCard, StyledFormWrapper } from '../../Styled'
import {
  handleNumberInput,
  handleNumberKeyDown,
  handlePasteFormat,
} from '../../../../utils/lib'
import { clearLeadingZeros } from '../event'
import _ from 'lodash'
import {
  SLA_TODO_LIST_E_TEST,
  SLA_TODO_LIST_MANAGE_CLASS,
  SLA_TODO_LIST_MONTHLY_PLAN,
} from '../../model'

const SLATodoList = ({ formik, body, setInputValue, isHide }) => {
  return (
    <StyledFormWrapper isHide={isHide}>
      <StyledCard>
        <Box m={3}>
          <Typography variant="h5" mb={2}>
            Monthly Plan
          </Typography>
          {SLA_TODO_LIST_MONTHLY_PLAN.map((item, index) => {
            return (
              <InputBox
                key={index}
                title={item.title}
                name={item.name}
                labelText={item.labelText}
                placeholder={item?.placeholder}
                unit={item?.unit}
                subText={item.subText ?? ''}
                formik={formik}
                body={body}
                setInputValue={setInputValue}
              />
            )
          })}
        </Box>
      </StyledCard>
      <StyledCard>
        <Box m={3}>
          <Typography variant="h5" mb={2}>
            Manage Class
          </Typography>
          {SLA_TODO_LIST_MANAGE_CLASS.map((item, index) => {
            return (
              <InputBox
                key={index}
                title={item.title}
                name={item.name}
                labelText={item.labelText}
                placeholder={item?.placeholder}
                unit={item?.unit}
                subText={item.subText ?? ''}
                formik={formik}
                body={body}
                setInputValue={setInputValue}
              />
            )
          })}
        </Box>
      </StyledCard>
      <StyledCard>
        <Box m={3}>
          <Typography variant="h5" mb={2}>
            E-Testing
          </Typography>
          {SLA_TODO_LIST_E_TEST.map((item, index) => {
            return (
              <InputBox
                key={index}
                title={item.title}
                name={item.name}
                labelText={item.labelText}
                placeholder={item?.placeholder}
                unit={item?.unit}
                subText={item.subText ?? ''}
                formik={formik}
                body={body}
                setInputValue={setInputValue}
              />
            )
          })}
        </Box>
      </StyledCard>
    </StyledFormWrapper>
  )
}

export default SLATodoList

export const handleInputChange = (props) => {
  const { key, e, setInputValue } = props
  const minValue = 1
  let maxValue = 99
  if (
    ['SLA_MONTHLY_PLAN_SEND_APPROVE_MASTER_PLAN'].includes(key) &&
    !_.isEmpty(e.target.value)
  ) {
    maxValue = 30
  }
  if (
    !_.isEmpty(e.target.value) &&
    (e.target.value > maxValue || e.target.value < minValue)
  ) {
    e.preventDefault()
    return
  }
  if (String(e.target.value).length >= 2) {
    e.target.value = e.target.value.substring(0, 2)
  }
  setInputValue(key, clearLeadingZeros(e.target.value))
}

export const InputBox = ({
  title,
  titleRequired = false,
  name,
  labelText,
  placeholder = 'Ex. 1 - 99',
  unit = 'วัน',
  subText = '',
  formik,
  body,
  setInputValue,
}) => {
  return (
    <Box>
      <Box sx={{ display: 'flex', flexDirection: 'row' }} mb={1}>
        <Typography variant="body1b">{title}</Typography>
        {titleRequired && (
          <Typography sx={{ lineHeight: 1, ml: 0.5 }} color={'error'}>
            *
          </Typography>
        )}
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          gap: '12px',
          width: 'auto',
        }}
        mb={3}
      >
        <TextInput
          id={`input-${name}`}
          name={`input-${name}`}
          labelText={labelText}
          placeholder={placeholder}
          type="basic"
          inputProps={{
            'data-testid': `test-${name}`,
            type: 'number',
            onInput: handleNumberInput,
            onKeyDown: (e) => {
              handleNumberKeyDown(e, ['.'])
            },
            onPaste: handlePasteFormat,
            maxLength: 2,
          }}
          textError={formik?.errors?.[name]}
          required
          // disabled={!hasPermissionCreate}
          value={body?.[name]}
          onChange={(e) => {
            handleInputChange({
              key: name,
              e,
              setInputValue,
            })
          }}
          onBlur={formik.handleBlur}
          onWheel={(e) => e.target instanceof HTMLElement && e.target.blur()}
          boxSx={{ maxWidth: '300px' }}
        />
        <Box display="flex" alignItems="center" gap={3}>
          <Typography>{unit}</Typography>
          {subText && (
            <Typography color={'success.main'} width={'100%'}>
              {subText}
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  )
}
