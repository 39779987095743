import React from 'react'
import _ from 'lodash'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Edit from '@mui/icons-material/Edit'
import RemoveRedEye from '@mui/icons-material/RemoveRedEye'
import HistoryTwoTone from '@mui/icons-material/HistoryTwoTone'
import Dot from '../../../../components/redux/Dot'
import { convertFormatDateTime } from '../../../../utils/lib'
import { themeColor } from '../../../../utils/themeData'
import { versionStatus } from '../../../../constants/eTesting'
import {
  RADIO_TYPE_ENUM,
  RADIO_TYPE_LABEL,
} from '../../../../constants/eLearning'
import { StyledHeaderContainer } from '../../Styled'
import {
  StyledButton,
  StyledChipTypography,
  StyledContentRow,
  StyledFooterText,
  StyledUl,
} from './Styled'
import { StyledDivider } from '../../../CourseSetting/ViewConditions/Styled'
import { Column, Row } from '../../../../components/Layout/Styled'

export const Header = ({
  isDeleted,
  onPreview,
  onOpenDrawer,
  onEdit,
  permission,
}) => {
  const hasEdit = _.get(permission, 'hasEdit', false)
  return (
    <StyledHeaderContainer id="view">
      {!isDeleted && (
        <StyledButton data-testid="btn-preview" onClick={onPreview}>
          <RemoveRedEye />
          ดูตัวอย่าง
        </StyledButton>
      )}
      <StyledButton data-testid="btn-history" onClick={onOpenDrawer}>
        <HistoryTwoTone />
        ประวัติการแก้ไข
      </StyledButton>
      {hasEdit && !isDeleted && (
        <StyledButton
          variant="contained"
          data-testid="btn-edit"
          onClick={onEdit}
        >
          <Edit />
          แก้ไข
        </StyledButton>
      )}
    </StyledHeaderContainer>
  )
}

export const ContentRow = ({ title, content, children }) => {
  return (
    <StyledContentRow>
      <Typography id="label" variant="body1b">
        {title}
      </Typography>
      {children ? (
        children
      ) : (
        <Typography sx={{ wordBreak: 'break-all' }} variant="body1">
          {content}
        </Typography>
      )}
    </StyledContentRow>
  )
}

export const FooterContent = ({ title, detail, field }) => {
  const name =
    _.get(detail, `${field}By.name`, '') || _.get(detail, `createdBy.name`, '')
  const dateTime =
    _.get(detail, `${field}At`, '') || _.get(detail, `createdAt`, '')
  const dateTimeFormatted = convertFormatDateTime({
    value: dateTime,
    type: 'dateTime',
  })

  return (
    <StyledFooterText variant="caption" color="text.lightGray">
      {title}โดย {name} {dateTimeFormatted}
    </StyledFooterText>
  )
}

export const ChipContent = ({ title, list, field }) => {
  return (
    <ContentRow title={title}>
      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
        {_.isEmpty(list)
          ? '-'
          : _.map(list, (item, index) => (
              <StyledChipTypography key={index} variant="chip">
                {_.get(item, 'label', _.get(item, field, '-'))}
              </StyledChipTypography>
            ))}
      </Box>
    </ContentRow>
  )
}

export const SpeakerProductTypeChipContent = ({ title, list }) => {
  return (
    <ContentRow title={title}>
      <Column>
        {list.length > 0
          ? _.map(list, (ele, ind) => {
              const data = _.map(ele, (item) => item.productType)
              return (
                <>
                  {ind > 0 && (
                    <StyledDivider textAlign="left" sx={{ mt: 0.5 }}>
                      <Typography color="text.lightGray">หรือ</Typography>
                    </StyledDivider>
                  )}
                  <Row sx={{ gap: 1 }}>
                    {_.map(data, (item, index) => (
                      <StyledChipTypography key={index} variant="chip">
                        {item}
                      </StyledChipTypography>
                    ))}
                  </Row>
                </>
              )
            })
          : '-'}
      </Column>
    </ContentRow>
  )
}

export const TypeContent = ({ type, children }) => {
  return (
    <ContentRow title="ประเภท">
      <Box>
        <Typography>{RADIO_TYPE_LABEL[type]}</Typography>
        {type !== RADIO_TYPE_ENUM.NONE && (
          <StyledUl>
            <li>{children}</li>
          </StyledUl>
        )}
      </Box>
    </ContentRow>
  )
}

export const CoverImageContent = ({ detail }) => {
  const coverImage = _.get(detail, 'coverImage', '')
  if (_.isEmpty(coverImage)) return <></>
  return (
    <ContentRow title="รูปภาพหน้าปก">
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Typography variant="body2" color="primary">
          {_.get(detail, 'fileName', '')}
        </Typography>
        <img
          src={`${window.__env__.REACT_APP_API_URL}/file/get${coverImage}`}
          width={125}
          height={82}
          style={{ objectFit: 'cover' }}
        />
      </Box>
    </ContentRow>
  )
}

export const StatusContent = ({ status }) => {
  const color = {
    ACTIVE: themeColor?.success?.main,
    INACTIVE: themeColor?.error?.main,
    DELETED: themeColor?.text?.white,
    DRAFT: themeColor?.text?.silver,
  }
  return (
    <ContentRow title="สถานะ">
      <Box sx={{ display: 'flex', gap: 1 }}>
        <Dot
          color={color[status] || themeColor?.error?.main}
          status={status}
          sx={{ mt: 1.5 }}
        />
        {versionStatus[status]}
      </Box>
    </ContentRow>
  )
}
