export const size = ['0.75em', false, '1.5em', '2.5em']
export const modulesProp = {
  toolbar: {
    container: [
      [{ size }],
      ['bold', 'italic', 'underline', { color: [] }, { background: [] }],
      [{ align: [] }],
      [
        { list: 'ordered' },
        { list: 'bullet' },
        { indent: '-1' },
        { indent: '+1' },
      ],
      ['link', 'image'],
      ['clean'],
    ],
  },
  clipboard: { matchVisual: false },
  imageResize: { modules: ['Resize', 'DisplaySize'] },
}

const toolbarButtons = [
  'undo',
  'redo',
  '|',
  'paragraph',
  '|',
  'bold',
  'italic',
  'underline',
  'brush',
  '|',
  'left',
  'center',
  'right',
  'justify',
  '|',
  'ul',
  'ol',
  'indent',
  'outdent',
  '|',
  'image',
  'link',
  '|',
  'hr',
  'eraser',
]

export const joditConfig = {
  toolbar: true,
  buttons: toolbarButtons,
  enableDragAndDropFileToEditor: true,
  removeButtons: '*', // Remove all buttons except those in `buttons`
  toolbarAdaptive: false, // Disable adaptive toolbar to prevent extra buttons appearing
  showCharsCounter: false, // Hide character counter
  showWordsCounter: false, // Hide word counter
  showPoweredByJodit: false, // Remove "Powered by Jodit" text
  showPlaceholder: false,
}
