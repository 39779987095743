import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import Tooltip from '@mui/material/Tooltip'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Typography from '@mui/material/Typography'
import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined'
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive'
import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined'
import ArrowForwardTwoToneIcon from '@mui/icons-material/ArrowForwardTwoTone'
import { themeColor } from '../../utils/themeData'
import {
  statusLabel,
  statusLabelColor,
  taskNameLabel,
  workTypeLabel,
  taskNameNotiLabel,
} from '../../constants/toDoList'
import * as dayjs from 'dayjs'
import { MONTH_LIST } from '../../../src/modules/MonthlyPlan/Text'
import { convertFormatDateTime } from '../../../src/utils/lib'
import { updateStatusToDoList } from '../../../src/services/toDolist'
import { handleTrainingDate } from '../../../src/modules/ManageClass/Setting/Form/events'
import { handleBatchDate } from './events'

const ToDoListBox = ({
  list,
  size = 'normal',
  hasPermissionAdminTO = false,
  isTO = null,
  today = null,
}) => {
  const dispatch = useDispatch()
  const [data, setData] = useState({
    requester: {
      label: '',
      value: '',
    },
    courseOrMonthText: {
      label: '',
      value: '',
    },
    stationOrCourseText: {
      label: '',
      value: '',
    },
    departmentOrDateText: {
      label: '',
      value: '',
    },
    dayDiff: '',
    isViewMore: false,
  })
  const event = _.get(list, 'event')

  useEffect(() => {
    fetchAllData()
  }, [])

  const fetchAllData = () => {
    if (size === 'normal') {
      const courseOrMonthText = getCourseOrMonth()
      const stationOrCourseText = getStationOrCourse()
      const departmentOrDateText = getDepartmentOrDate()
      const requester = getRequester()
      const dayDiff = getDateDiff()
      const isViewMore = getIsViewMore()

      setData((prevData) => ({
        ...prevData,
        courseOrMonthText,
        stationOrCourseText,
        departmentOrDateText,
        requester,
        dayDiff,
        isViewMore,
      }))
    }
  }

  const getDepartment = () => {
    switch (event) {
      case 'CREATED_MASTER_PLAN':
      case 'DISAPPROVED_MASTER_PLAN':
      case 'APPROVED_MASTER_PLAN':
      case 'CANCELED_MASTER_PLAN':
        return `(${_.get(
          list,
          'masterPlan.area.department.initial',
          '',
        )}) ${_.get(list, 'masterPlan.area.department.department', '')}`
      case 'DISAPPROVED_BUDGET':
      case 'DISAPPROVED_MONTHLY_PLAN':
      case 'REQUESTED_APPROVED_MONTHLY_PLAN':
      case 'REQUEST_BORROW_SPEAKER_CREATED':
      case 'CANCEL_SPEAKER':
        return `(${_.get(
          list,
          'monthlyPlan.area.department.initial',
          '',
        )}) ${_.get(list, 'monthlyPlan.area.department.department', '')}`
      case 'DISAPPROVED_BORROW_SPEAKER':
      case 'APPROVED_BORROW_SPEAKER':
      case 'CHANGE_SPEAKER':
        return `(${_.get(
          list,
          'speakerBorrow.approverDepartment.initial',
          '',
        )}) ${_.get(list, 'speakerBorrow.approverDepartment.department', '')}`
      case 'EDITED_CLASS':
        return convertFormatDateTime({
          value: _.get(list, 'monthlyPlan.startDate', ''),
          type: 'date',
        })
      case 'REQUEST_CREATED_MASTER_PLAN':
        return `(${_.get(list, 'area.department.initial', '')}) ${_.get(
          list,
          'area.department.department',
          '',
        )}`
      default:
        return `(${_.get(
          list,
          'monthlyPlan.area.department.initial',
          '',
        )}) ${_.get(list, 'monthlyPlan.area.department.department', '')}`
    }
  }

  const getStation = () => {
    switch (event) {
      case 'CREATED_MASTER_PLAN':
      case 'DISAPPROVED_MASTER_PLAN':
      case 'APPROVED_MASTER_PLAN':
      case 'CANCELED_MASTER_PLAN':
        return _.get(list, 'masterPlan.area.station.station', '')
      case 'REQUEST_BORROW_SPEAKER_CREATED':
      case 'CANCEL_SPEAKER':
      case 'DISAPPROVED_BUDGET':
      case 'EDITED_CLASS':
      case 'DISAPPROVED_MONTHLY_PLAN':
      case 'REQUESTED_APPROVED_MONTHLY_PLAN':
        return _.get(list, 'monthlyPlan.area.station.station', '')
      case 'DISAPPROVED_BORROW_SPEAKER':
      case 'APPROVED_BORROW_SPEAKER':
      case 'CHANGE_SPEAKER':
        return _.get(list, 'speakerBorrow.approverStation.station', '')
      default:
        return _.get(list, 'area')
          ? _.get(list, 'area.station.station', '')
          : _.get(list, 'monthlyPlan.area.station.station', '')
    }
  }

  const getDateDiff = () => {
    let createdAt = dayjs(_.get(list, 'createdAt', new Date())).subtract(
      7,
      'hour',
    )
    if (today && createdAt.format('YYYY-MM-DD') != today) {
      createdAt = createdAt.format('YYYY-MM-DD')
    }
    const currentDateTime = dayjs()
    const min = currentDateTime.diff(createdAt, 'm')
    const hour = currentDateTime.diff(createdAt, 'h')
    const day = currentDateTime.diff(createdAt, 'd')
    const month = currentDateTime.diff(createdAt, 'M')
    const year = currentDateTime.diff(createdAt, 'y')
    let timeText
    if (year > 0) {
      timeText = year + ' ปีที่แล้ว'
    } else if (month > 0) {
      timeText = month + ' เดือนที่แล้ว'
    } else if (day > 0) {
      timeText = day + ' วันที่แล้ว'
    } else if (hour > 0) {
      timeText = hour + ' ชั่วโมงที่แล้ว'
    } else if (min > 0) {
      timeText = min + ' นาทีที่แล้ว'
    } else {
      timeText = 'ไม่กี่วินาทีที่แล้ว'
    }

    return timeText
  }

  const getCourseOrMonth = () => {
    const month =
      _.get(
        list,
        'masterPlan.month',
        _.get(list, 'monthlyPlan.startDate', '').split('-')[1],
      ) - 1

    let course
    const trainingInfo = _.get(list, 'monthlyPlan.trainingInfo', [])
    const academiyCertifiedInfo = _.get(
      list,
      'monthlyPlan.academiyCertifiedInfo',
      [],
    )
    const academiyStaffTrainingInfo = _.get(
      list,
      'monthlyPlan.academiyStaffTrainingInfo',
      [],
    )
    const academiyTrainTheTrainerInfo = _.get(
      list,
      'monthlyPlan.academiyTrainTheTrainerInfo',
      [],
    )

    switch (event) {
      case 'REQUESTED_APPROVED_MONTHLY_PLAN':
      case 'CREATED_MASTER_PLAN':
      case 'CANCELED_MASTER_PLAN':
      case 'DISAPPROVED_MASTER_PLAN':
      case 'APPROVED_MASTER_PLAN':
      case 'DISAPPROVED_MONTHLY_PLAN':
      case 'DISAPPROVED_BUDGET':
      case 'APPROVED_MONTHLY_PLAN':
        return {
          label: 'เดือน :',
          value: _.get(MONTH_LIST, `[${month}].label`),
        }
      case 'REQUEST_CREATED_MASTER_PLAN': {
        const createdAtMonth = _.get(list, 'createdAt')
          ? dayjs(_.get(list, 'createdAt')).month()
          : ''
        return {
          label: 'เดือน :',
          value: _.get(MONTH_LIST, `[${createdAtMonth}].label`),
        }
      }
      case 'ANNOUNCE_STUDENT':
      case 'CHECK_DOCUMENT':
      case 'ASSIGNMENT_TEST':
      case 'CHECK_EXAM':
      case 'CLOSE_CLASS':
      case 'CLOSE_CLASS_FINISH':
      case 'SETTING_CLASS':
      case 'EDITED_CLASS':
      case 'STUDENT_CANCELED_CLASS':
      case 'ADDED_STUDENT':
      case 'CREATE_E_LEARNING_QUESTION':
      case 'WAITING_ANNOUNCE_E_LEARNING':
        return {
          label: '',
          value: '',
        }
      default:
        if (trainingInfo.length > 0) {
          course = _.get(list, 'monthlyPlan.trainingInfo[0].course')
        } else if (academiyCertifiedInfo.length > 0) {
          course = _.get(list, 'monthlyPlan.academiyCertifiedInfo[0].course')
        } else if (academiyStaffTrainingInfo.length > 0) {
          course = _.get(
            list,
            'monthlyPlan.academiyStaffTrainingInfo[0].course',
          )
        } else if (academiyTrainTheTrainerInfo.length > 0) {
          course = _.get(
            list,
            'monthlyPlan.academiyTrainTheTrainerInfo[0].course',
          )
        }

        return {
          label: 'หลักสูตร :',
          value: _.get(course, 'englishName', ''),
          nameForLearner: _.get(course, 'nameForLearner', '') || '',
        }
    }
  }

  const getStationOrCourse = () => {
    switch (event) {
      case 'ASSIGNMENT_TEST':
      case 'CLOSE_CLASS':
      case 'CLOSE_CLASS_FINISH':
      case 'ANNOUNCE_STUDENT':
      case 'CHECK_EXAM':
      case 'SETTING_CLASS':
      case 'ADDED_STUDENT':
      case 'STUDENT_CANCELED_CLASS':
      case 'EDITED_CLASS':
      case 'CHECK_DOCUMENT':
        return {
          label: 'หลักสูตร :',
          value: _.get(list, 'trainingPlan.course', ''),
          nameForLearner:
            _.get(
              list,
              'monthlyPlan.trainingInfo[0].course.nameForLearner',
              '',
            ) || '',
        }
      case 'CREATE_E_LEARNING_QUESTION':
      case 'WAITING_ANNOUNCE_E_LEARNING':
        return {
          label: 'หลักสูตร :',
          value: _.get(list, 'eLearningCourseBatch.eLearningCourse.name', ''),
          nameForLearner:
            _.get(list, 'eLearningCourseBatch.eLearningCourse.name', '') || '',
        }
      default:
        return {
          label: 'Station :',
          value: getStation(),
        }
    }
  }

  const getDepartmentOrDate = () => {
    switch (event) {
      case 'ASSIGNMENT_TEST':
      case 'CHECK_EXAM':
      case 'ANNOUNCE_STUDENT':
      case 'SETTING_CLASS':
      case 'CHECK_DOCUMENT':
      case 'CLOSE_CLASS':
      case 'CLOSE_CLASS_FINISH':
      case 'ADDED_STUDENT':
      case 'STUDENT_CANCELED_CLASS':
      case 'EDITED_CLASS':
        return {
          label: 'วันที่จัดอบรม :',
          value: handleTrainingDate(_.get(list, 'monthlyPlan'), true),
        }
      case 'CREATE_E_LEARNING_QUESTION':
      case 'WAITING_ANNOUNCE_E_LEARNING':
        return {
          label: 'วันที่จัดอบรม :',
          value: handleBatchDate(_.get(list, 'eLearningCourseBatch')),
        }
      default:
        return {
          label: 'Department :',
          value: getDepartment(),
        }
    }
  }

  const getRequester = () => {
    switch (event) {
      case 'DISAPPROVED_BORROW_SPEAKER':
      case 'APPROVED_BORROW_SPEAKER':
      case 'CHANGE_SPEAKER':
        return {
          label: 'โดย :',
          value: `${_.get(
            list,
            'speakerBorrow.approver.firstNameTH',
            '',
          )} ${_.get(list, 'speakerBorrow.approver.lastNameTH', '-')}`,
        }
      case 'REQUEST_BORROW_SPEAKER_CREATED':
      case 'CANCEL_SPEAKER':
      case 'SENT_BORROW_LEARNER':
        return {
          label: event === 'SENT_BORROW_LEARNER' ? 'ผู้ขอ :' : 'โดย :',
          value: `${_.get(list, 'speakerBorrow.requester.firstNameTH')} ${_.get(
            list,
            'speakerBorrow.requester.lastNameTH',
          )}`,
        }
      case 'STUDENT_CANCELED_CLASS':
      case 'REQUESTED_MEETING_ROOM':
      case 'CREATED_MASTER_PLAN':
      case 'DISAPPROVED_MASTER_PLAN':
      case 'APPROVED_MONTHLY_PLAN':
      case 'DISAPPROVED_MONTHLY_PLAN':
      case 'ADDED_STUDENT':
      case 'EDITED_CLASS':
      case 'DISAPPROVED_BUDGET':
      case 'CLOSE_CLASS_FINISH':
        return {
          label: 'โดย :',
          value: _.get(list, 'createdBy'),
        }
      case 'EDITED_MONTHLY_PLAN':
      case 'CANCELED_MONTHLY_PLAN':
      case 'ADDED_MONTHLY_PLAN':
        return {
          label: 'วันที่จัดอบรม :',
          value: handleTrainingDate(_.get(list, 'monthlyPlan'), true),
        }
      case 'ASSIGNMENT_TEST':
      case 'CHECK_EXAM':
      case 'CLOSE_CLASS':
      case 'ANNOUNCE_STUDENT':
      case 'SETTING_CLASS':
      case 'CHECK_DOCUMENT':
        return {
          label: 'รูปแบบ :',
          value: _.get(list, 'monthlyPlan.classroom.classroom', '-'),
        }
      case 'CREATE_E_LEARNING_QUESTION':
      case 'WAITING_ANNOUNCE_E_LEARNING':
        return { label: 'รูปแบบ :', value: 'E-Learning' }
      default:
        return {
          label: event === 'APPROVED_MASTER_PLAN' ? 'โดย :' : 'ผู้ขอ :',
          value: _.get(list, 'updatedBy'),
        }
    }
  }

  const GetModule = () => {
    const moduleData = _.get(list, 'module')
    let moduleBg = '#00008F1A'
    let moduleText = '#00008F'
    if (moduleData === 'MANAGE_CLASS') {
      moduleBg = '#fde3f3'
      moduleText = '#CB51D6'
    } else if (moduleData === 'E_TESTING') {
      moduleBg = '#ccf2d8'
      moduleText = '#04872A'
    } else if (moduleData === 'E_LEARNING') {
      moduleBg = '#02718029'
      moduleText = '#027180'
    }

    return (
      <Box
        sx={{
          display: 'inline',
          borderRadius: 26,
          backgroundColor: moduleBg,
          textAlign: 'center',
          mr: 2,
        }}
      >
        <Typography
          variant="tooltip"
          sx={{ color: moduleText, mx: 2, fontWeight: 700 }}
        >
          {workTypeLabel[_.get(list, 'module', '')]}
        </Typography>
      </Box>
    )
  }

  const onCardClick = async () => {
    const body = {
      uuid: _.get(list, 'uuid'),
      status: 'ON_WORKING',
    }

    let urlParams = {
      uuid: _.get(list, 'monthlyPlan.uuid'),
      year: _.get(list, 'monthlyPlan.startDate', '').split('-')[0],
      month: _.get(list, 'monthlyPlan.startDate', '').split('-')[1],
      areaUuid: _.get(list, 'monthlyPlan.area.uuid'),
      stationUuid: _.get(list, 'monthlyPlan.area.station.uuid'),
      departmentUuid: _.get(list, 'monthlyPlan.area.department.uuid'),
    }

    const courseTestAndEva = _.get(
      list,
      'trainingPlan.monthlyPlan.trainingInfo[0].course.courseTestAndEvaluate',
      [],
    )

    const courseTestAndEvaExam = _.find(
      courseTestAndEva,
      (item) => item.type !== 'Assessment',
    )
    const courseTestAndEvaAssessment = _.find(
      courseTestAndEva,
      (item) => item.type === 'Assessment',
    )
    const trainingUuid = _.get(list, 'trainingPlan.uuid', '')
    const eTestingVersionUuid = _.get(
      list,
      'eTestingAnswerRoundEntity.eTestingVersion.uuid',
      '',
    )
    const eLearningCourseBatchUuid = _.get(
      list,
      'eLearningCourseBatch.uuid',
      '',
    )

    await dispatch(updateStatusToDoList(body))
    switch (event) {
      case 'CREATED_MASTER_PLAN':
      case 'DISAPPROVED_MASTER_PLAN':
        if (!urlParams.uuid) {
          urlParams = {
            year: _.get(list, 'masterPlan.year'),
            month: _.get(list, 'masterPlan.month'),
            areaUuid: _.get(list, 'masterPlan.area.uuid'),
            stationUuid: _.get(list, 'masterPlan.area.station.uuid'),
            departmentUuid: _.get(list, 'masterPlan.area.department.uuid'),
          }
        }
        window.open(getMonthlyPlanLink(urlParams) + `&view=1`, '_blank').focus()
        break
      case 'DISAPPROVED_MONTHLY_PLAN':
      case 'DISAPPROVED_BUDGET':
      case 'REQUESTED_APPROVED_MONTHLY_PLAN':
      case 'CHANGE_SPEAKER':
      case 'SENT_BORROW_LEARNER':
        window
          .open(getMonthlyPlanLink(urlParams) + `&drawer=1`, '_blank')
          .focus()
        break
      case 'REQUEST_BORROW_SPEAKER_CREATED':
        window
          .open(
            '/monthly-plan/speaker-requester?borrowUuid=' +
              _.get(list, 'speakerBorrow.uuid', ''),
            '_blank',
          )
          .focus()
        break
      case 'ASSIGNMENT_TEST':
        window
          .open(
            `/manage-class/check-exam/${trainingUuid}/${_.get(
              courseTestAndEvaAssessment,
              'uuid',
              '',
            )}`,
            '_blank',
          )
          .focus()
        break
      case 'CHECK_EXAM':
        window
          .open(
            `/manage-class/check-exam/${trainingUuid}/${_.get(
              courseTestAndEvaExam,
              'uuid',
              '',
            )}`,
            '_blank',
          )
          .focus()
        break
      case 'CHECK_EXAM_STANDALONE':
        window
          .open(`/e-testing/check-exam/${eTestingVersionUuid}`, '_blank')
          .focus()
        break
      case 'CLOSE_CLASS':
      case 'CLOSE_CLASS_FINISH':
        window.open(`/manage-class/detail/${trainingUuid}`, '_blank').focus()
        break
      case 'EDITED_CLASS':
        window
          .open(`/manage-class/detail/${trainingUuid}?history=1`, '_blank')
          .focus()
        break
      case 'EDITED_MONTHLY_PLAN':
      case 'CANCELED_MONTHLY_PLAN':
        window
          .open(`/manage-class/detail/${trainingUuid}?drawer=1`, '_blank')
          .focus()
        break
      case 'ADDED_MONTHLY_PLAN':
        if (
          _.get(
            list,
            'monthlyPlan.trainingInfo[0].course.courseLevelControl',
            '',
          ) === 'ระดับสูง' &&
          _.get(
            list,
            'trainingPlan.trainingPlatForm[0].trainingPlatform',
            '',
          ) === 'Virtual Classroom'
        ) {
          window.open(`/manage-class?virtual=1`, '_blank').focus()
        } else {
          window.open(`/manage-class`, '_blank').focus()
        }
        break
      case 'SETTING_CLASS':
        window.open(`/manage-class/setting/${trainingUuid}`, '_blank').focus()
        break
      case 'ANNOUNCE_STUDENT':
      case 'STUDENT_CANCELED_CLASS':
        window.open(`/manage-class/learner/${trainingUuid}`, '_blank').focus()
        break
      case 'ADDED_STUDENT':
        window
          .open(`/manage-class/learner/${trainingUuid}?drawer=1`, '_blank')
          .focus()
        break
      case 'CHECK_DOCUMENT':
        window
          .open(
            `/manage-class/learner/application-documents/${trainingUuid}`,
            '_blank',
          )
          .focus()
        break
      case 'APPROVED_MASTER_PLAN':
        if (hasPermissionAdminTO) {
          window.open(`/manage-class?virtual=1`, '_blank').focus()
        } else {
          window.open(`/manage-class`, '_blank').focus()
        }
        break
      case 'CREATE_E_LEARNING_QUESTION':
        window
          .open(
            `/e-learning/question?batch=${eLearningCourseBatchUuid}`,
            '_blank',
          )
          .focus()
        break
      case 'WAITING_ANNOUNCE_E_LEARNING':
        window
          .open(
            `/e-learning/course/batch/view/learner/${eLearningCourseBatchUuid}`,
            '_blank',
          )
          .focus()
        break
      default:
        break
    }
  }

  const getMonthlyPlanLink = (urlParams) => {
    return `/monthly-plan/calendar/${urlParams.uuid}?month=${urlParams.month}&year=${urlParams.year}&areaUuid=${urlParams.areaUuid}&stationUuid=${urlParams.stationUuid}&departmentUuid=${urlParams.departmentUuid}`
  }

  const getIsViewMore = () => {
    switch (event) {
      case 'CHANGE_SPEAKER':
      case 'EDITED_CLASS':
      case 'EDITED_MONTHLY_PLAN':
      case 'CANCELED_MONTHLY_PLAN':
      case 'SENT_BORROW_LEARNER':
      case 'ADDED_STUDENT':
      case 'STUDENT_CANCELED_CLASS':
      case 'CLOSE_CLASS_FINISH':
      case 'ADDED_MONTHLY_PLAN':
      case 'CREATE_E_LEARNING_QUESTION':
      case 'WAITING_ANNOUNCE_E_LEARNING':
        return true
      case 'APPROVED_MASTER_PLAN':
        return isTO || hasPermissionAdminTO
      default:
        return false
    }
  }

  return (
    <>
      {size === 'normal' ? (
        <Box>
          <Card
            sx={{
              width: '100%',
              minHeight: 96,
              mx: 1,
              position: 'relative',
              display: 'flex',
              alignItems: 'center',
              backgroundColor:
                _.get(list, 'status', '') === 'TO_DO' ? '#fff8f0' : '#FFFFFF',
            }}
          >
            <Box
              sx={{
                width: '25%',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <Box sx={{ mr: 4, mx: 3 }}>
                {_.get(list, 'type', '') === 'TO_DO' ? (
                  <FactCheckOutlinedIcon
                    sx={{ minWidth: 36, minHeight: 36, color: '#999999' }}
                  />
                ) : (
                  <NotificationsActiveOutlinedIcon
                    sx={{ minWidth: 36, minHeight: 36, color: '#999999' }}
                  />
                )}
              </Box>
              <Box>
                {list?.isCheckSLA ? (
                  <>
                    {list?.type === 'TO_DO' && (
                      <Tooltip
                        slotProps={{
                          tooltip: {
                            sx: {
                              color: 'white',
                              backgroundColor: 'error.main',
                              '& .MuiTooltip-arrow': {
                                color: 'error.main',
                              },
                            },
                          },
                        }}
                        title={
                          <Typography variant="tooltip">{`เกินกำหนด`}</Typography>
                        }
                        arrow
                        placement="top"
                      >
                        <Typography variant="body1b" color="error.main">
                          {taskNameLabel[_.get(list, 'event', '')]}
                        </Typography>
                      </Tooltip>
                    )}
                    {list?.type === 'NOTIFICATION' && (
                      <Typography variant="body1b" color="error.main">
                        {taskNameLabel[_.get(list, 'event', '')]}{' '}
                        {`(เกินกำหนด)`}
                      </Typography>
                    )}
                  </>
                ) : (
                  <Typography variant="body1b">
                    {taskNameLabel[_.get(list, 'event', '')]}
                  </Typography>
                )}
                <br />
                <GetModule />
                <Typography variant="tooltip">
                  {dayjs(_.get(list, 'createdAt', new Date()))
                    .subtract(7, 'hour')
                    .format(
                      `${window.__env__.REACT_APP_DATE_SHOW} ${window.__env__.REACT_APP_DATE_TIME}`,
                    )}
                </Typography>
              </Box>
            </Box>

            <Box
              sx={{
                width: '45%',
                display: 'flex',
                flexDirection: 'row',
                pl: 5,
              }}
            >
              {event === 'CHECK_EXAM_STANDALONE' && (
                <Box sx={{ width: '100%' }}>
                  <Typography
                    variant="body2b"
                    sx={{ mr: 1, color: themeColor.grey.grey600 }}
                  >
                    {'ชื่อแบบทดสอบ: '}
                  </Typography>
                  <Typography variant="body2b">
                    {_.get(
                      list,
                      'eTestingAnswerRoundEntity.eTestingVersion.name',
                      '',
                    )}
                  </Typography>
                </Box>
              )}
              {event !== 'CHECK_EXAM_STANDALONE' && (
                <>
                  <Box sx={{ width: '50%' }}>
                    <Box
                      sx={{
                        display: 'inline-flex',
                        flexFlow: 'row nowrap',
                        width: '100%',
                      }}
                    >
                      <Typography
                        variant="body2b"
                        sx={{
                          mr: 1,
                          color: themeColor.grey.grey600,
                          whiteSpace: 'pre',
                        }}
                      >
                        {`${data.stationOrCourseText.label}`}
                      </Typography>

                      <Tooltip
                        title={
                          <Typography variant="tooltip">
                            {_.get(data, 'stationOrCourseText.value', '')}{' '}
                            {_.get(
                              data,
                              'stationOrCourseText.nameForLearner',
                              '',
                            )}
                          </Typography>
                        }
                        arrow
                        placement="top"
                      >
                        <Typography
                          variant="body2b"
                          sx={{
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            display: 'inline-block',
                          }}
                        >
                          {data.stationOrCourseText.value}{' '}
                          <Typography variant="span" color="primary">
                            {data.stationOrCourseText.nameForLearner}
                          </Typography>
                        </Typography>
                      </Tooltip>
                    </Box>
                    <Box
                      sx={{
                        display: 'inline-flex',
                        flexFlow: 'row nowrap',
                        width: '100%',
                      }}
                    >
                      <Typography
                        variant="tooltip"
                        sx={{
                          mr: 1,
                          color: themeColor.grey.grey600,
                          fontWeight: 700,
                          whiteSpace: 'pre',
                        }}
                      >
                        {data.departmentOrDateText.label}
                      </Typography>

                      <Tooltip
                        title={
                          <Typography variant="tooltip">
                            {_.get(data, 'departmentOrDateText.value', '')}
                          </Typography>
                        }
                        arrow
                        placement="top"
                      >
                        <Typography
                          variant="tooltip"
                          sx={{
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            display: 'inline-block',
                          }}
                        >
                          {data.departmentOrDateText.value}
                        </Typography>
                      </Tooltip>
                    </Box>
                  </Box>
                  <Box sx={{ width: '50%', pl: 2 }}>
                    <Box
                      sx={{
                        display: 'inline-flex',
                        flexFlow: 'row nowrap',
                        width: '100%',
                      }}
                    >
                      <Typography
                        variant="body2b"
                        sx={{
                          mr: 1,
                          color: themeColor.grey.grey600,
                          whiteSpace: 'pre',
                        }}
                      >
                        {data.courseOrMonthText.label}
                      </Typography>
                      <Tooltip
                        title={
                          <Typography variant="tooltip">
                            {_.get(data, 'courseOrMonthText.value', '')}{' '}
                            {_.get(
                              data,
                              'courseOrMonthText.nameForLearner',
                              '',
                            )}
                          </Typography>
                        }
                        arrow
                        placement="top"
                      >
                        <Typography
                          variant="body2b"
                          sx={{
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            display: 'inline-block',
                          }}
                        >
                          {data.courseOrMonthText.value}{' '}
                          <Typography variant="span" color="primary">
                            {data.courseOrMonthText.nameForLearner}
                          </Typography>
                        </Typography>
                      </Tooltip>
                    </Box>
                    <Box
                      sx={{
                        display: 'inline-flex',
                        flexFlow: 'row nowrap',
                        width: '100%',
                      }}
                    >
                      <Typography
                        variant="tooltip"
                        sx={{
                          mr: 1,
                          color: themeColor.grey.grey600,
                          fontWeight: 700,
                          whiteSpace: 'pre',
                        }}
                      >
                        {data.requester.label}
                      </Typography>
                      <Tooltip
                        title={
                          <Typography variant="tooltip">
                            {_.get(data, 'requester.value', '')}
                          </Typography>
                        }
                        arrow
                        placement="top"
                      >
                        <Typography
                          variant="tooltip"
                          sx={{
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            display: 'inline-block',
                          }}
                        >
                          {data.requester.value}
                        </Typography>
                      </Tooltip>
                    </Box>
                  </Box>
                </>
              )}
            </Box>

            <Box
              sx={{
                width: '30%',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                alignItems: 'center',
                pl: 3,
              }}
            >
              {_.get(list, 'event') === 'DISAPPROVED_BORROW_SPEAKER' &&
              _.get(
                list,
                'speakerBorrow.speakerBorrowResponsibility[0].remark',
                '',
              ) !== '' ? (
                <Box
                  sx={{
                    maxWidth: '100%',
                    mx: 3,
                    mr: 5,
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    color: themeColor.grey.grey600,
                  }}
                >
                  <Tooltip
                    title={
                      <Typography variant="tooltip">
                        {_.get(
                          list,
                          'speakerBorrow.speakerBorrowResponsibility[0].remark',
                          '',
                        )}
                      </Typography>
                    }
                    arrow
                    placement="top"
                  >
                    <Box>
                      <Typography variant="body2b">
                        {`สาเหตุการไม่อนุมัติ: `}
                        <Typography variant="body2b" sx={{ color: '#ED6C02' }}>
                          {_.get(
                            list,
                            'speakerBorrow.speakerBorrowResponsibility[0].remark',
                            '',
                          ).slice(0, 20)}
                        </Typography>
                      </Typography>
                    </Box>
                  </Tooltip>
                </Box>
              ) : (
                <>
                  <Box sx={{ width: '33%', textAlign: 'left', pl: 4 }}>
                    {_.get(list, 'type') === 'TO_DO' && (
                      <Typography
                        variant="body1"
                        sx={{ color: themeColor.primary.main }}
                      >
                        {data.dayDiff}
                      </Typography>
                    )}
                  </Box>
                  <Box sx={{ width: '33%', textAlign: 'left', pl: 4 }}>
                    {_.get(list, 'type') === 'TO_DO' && (
                      <>
                        <Box
                          sx={{
                            display: 'inline-block',
                            mr: 1,
                            width: 12,
                            height: 12,
                            borderRadius: 30,
                            backgroundColor:
                              statusLabelColor[_.get(list, 'status', '')],
                          }}
                        ></Box>
                        <Typography variant="body2b">
                          {statusLabel[_.get(list, 'status', '')]}
                        </Typography>
                      </>
                    )}
                  </Box>
                  <Box
                    sx={{
                      width: '33%',
                      display: 'flex',
                      alignItems: 'center',
                      pl: 4,
                    }}
                  >
                    {(_.get(list, 'type') === 'TO_DO' || data.isViewMore) && (
                      <Box
                        sx={{ display: 'flex', cursor: 'pointer' }}
                        onClick={() => onCardClick()}
                      >
                        <Typography
                          variant="buttons"
                          sx={{
                            mr: 1,
                            color: themeColor.primary.main,
                          }}
                        >
                          ดูเพิ่ม
                        </Typography>
                        <ArrowForwardTwoToneIcon
                          sx={{
                            minWidth: 16,
                            minHeight: 18,
                            color: themeColor.primary.main,
                          }}
                        />
                      </Box>
                    )}
                  </Box>
                </>
              )}
            </Box>
          </Card>
        </Box>
      ) : (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            mb: 2,
          }}
        >
          <Box
            sx={{
              width: 36,
              height: 36,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor:
                _.get(list, 'type', '') === 'TO_DO' ? '#7030A0' : '#FF9A58',
              borderRadius: 27,
              mr: 2,
            }}
          >
            {_.get(list, 'type', '') === 'TO_DO' ? (
              <FactCheckOutlinedIcon style={{ color: 'white' }} />
            ) : (
              <NotificationsActiveIcon style={{ color: 'white' }} />
            )}
          </Box>
          <Box>
            <Typography
              variant="body1b"
              sx={{ display: 'block', lineHeight: 1 }}
              color={list?.isCheckSLA ? 'error.main' : ''}
            >
              {taskNameNotiLabel[_.get(list, 'event', '')]}
            </Typography>
            <GetModule />
            <Typography variant="text">{`${_.get(
              list,
              'count',
              0,
            )} รายการ`}</Typography>
          </Box>
        </Box>
      )}
    </>
  )
}

export default ToDoListBox
