import { store } from '../../../../../App'
import { setFieldValue } from '../../../../../redux/slices/crud'
import {
  filterEtestingDashboardOverview,
  filterEtestingDashboardResult,
} from '../../../../../utils/apiPath'
import { callAPI } from '../../../../../utils/lib'
import { handleFetchError } from './handlerFetchError'
import { sortQuestion } from './sortQuestion'

export const fetchDashboardDetail = async (
  mutateFilterPropToBody,
  url,
  fieldName,
  sort = 'wrongDESC',
) => {
  startDialogLoading()
  try {
    return await fetchData(url, mutateFilterPropToBody, fieldName, sort)
  } catch (error) {
    console.log(error)
    handleFetchError(error)
  } finally {
    stopDialogLoading()
  }
}

const startDialogLoading = () => {
  store.dispatch(setFieldValue({ key: 'isLoading', value: true }))
}

const stopDialogLoading = () => {
  store.dispatch(setFieldValue({ key: 'isLoading', value: false }))
}

const fetchData = async (url, mutateFilterPropToBody, fieldName, sort) => {
  const { crudConfig } = store.getState().crud
  if (!crudConfig) return

  const response = await callAPI({
    url: url,
    method: 'post',
    body: mutateFilterPropToBody,
  })
  let key = 'formData'
  if (fieldName) {
    key = fieldName
  }

  store.dispatch(setFieldValue({ key: key, value: response }))
  if (url === filterEtestingDashboardResult) {
    sortQuestion(response.questionsDatas, sort)
  }
  if (url === filterEtestingDashboardOverview) {
    if (response.testName) {
      store.dispatch(
        setFieldValue({
          key: 'eTestName',
          value: response.testName,
        }),
      )
    }
    if (response.versionCode) {
      store.dispatch(
        setFieldValue({
          key: 'eTestVersion',
          value: response.versionCode,
        }),
      )
    }
  }
  return response
}

export const fetchLearnerResultScore = async (
  mutateFilterPropToBody,
  url,
  score,
) => {
  const { crudConfig } = store.getState().crud
  if (!crudConfig) return
  startDialogLoading()

  mutateFilterPropToBody = { ...mutateFilterPropToBody, score: score }
  try {
    const response = await callAPI({
      url: url,
      method: 'post',
      body: mutateFilterPropToBody,
    })
    store.dispatch(
      setFieldValue({
        key: 'learner.resultScore',
        value: response?.resultScore,
      }),
    )
  } catch (error) {
    console.log(error)
    handleFetchError(error)
  } finally {
    stopDialogLoading()
  }
}
