export const tabsPermissionList = [
  {
    label: 'Permission ทั่วไป',
  },
  {
    label: 'การเข้าถึง Cost Center',
    disabled: true,
  },
]

export const permissionCostCenterHeadCells = [
  {
    id: 'id',
    disablePadding: false,
    label: 'ID',
    width: '70px',
  },
  {
    id: 'groupCode',
    label: 'Group Code',
    disablePadding: false,
  },
  {
    id: 'costCenter',
    label: 'Cost Center ID',
    disablePadding: false,
  },
  {
    id: 'descriptionName',
    label: 'Cost Center Name',
    disablePadding: false,
  },
]
