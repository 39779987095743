import { PERMISSION_REPORT_E_EVALUATION } from '../report/ReportEEvaRawDataByCourse'

export const permissionMonthlyPlan = [
  {
    module: 'MONTHLY_PLAN',
    owner: 'PIC',
    permission: [
      'VIEW_ALL',
      'CREATE_ALL_EXC_HOLIDAY',
      'DELETE_ALL_EXC_HOLIDAY',
      'CREATE_ALL',
      'DELETE_ALL',
      'CREATE_LEAVE',
      'DELETE_LEAVE',
      'APPROVE_MP',
      'APPROVE_ZONE',
      'ASSISTS_ZONE',
    ],
  },
  {
    module: 'MONTHLY_PLAN',
    owner: 'ALL',
    permission: [
      'VIEW_ALL',
      'CREATE_ALL_EXC_HOLIDAY',
      'DELETE_ALL_EXC_HOLIDAY',
      'CREATE_ALL',
      'DELETE_ALL',
      'CREATE_LEAVE',
      'DELETE_LEAVE',
      'APPROVE_MP',
      'APPROVE_ZONE',
      'ASSISTS_ZONE',
    ],
  },
]
export const permissionViewMasterData = [
  {
    module: 'MASTER_DATA',
    owner: 'ALL',
    permission: ['VIEW'],
  },
]

export const permissionCreateMasterData = [
  {
    module: 'MASTER_DATA',
    owner: 'ALL',
    permission: ['CREATE'],
  },
]

export const permissionManagePermission = [
  {
    module: 'MANAGE_PERMISSION',
    owner: 'ALL',
    permission: ['CREATE', 'DELETE', 'VIEW'],
  },
]
export const permissionETestingMenu = [
  {
    module: 'E_TESTING_SETTING',
    owner: 'ALL',
    permission: ['MENU'],
  },
]

export const permissionETestingView = [
  {
    module: 'E_TESTING_SETTING',
    owner: 'ALL',
    permission: ['VIEW'],
  },
]

export const permissionETestingCreate = [
  {
    module: 'E_TESTING_SETTING',
    owner: 'ALL',
    permission: ['CREATE'],
  },
]

export const permissionETestingEdit = [
  {
    module: 'E_TESTING_SETTING',
    owner: 'ALL',
    permission: ['EDIT'],
  },
  {
    module: 'E_TESTING_SETTING',
    owner: 'PIC',
    permission: ['EDIT'],
  },
]

export const permissionEValuationMenu = [
  {
    module: 'E_EVALUATION_SETTING',
    owner: 'ALL',
    permission: ['MENU'],
  },
]

export const permissionEvaluationDashboardMenu = [
  {
    module: 'REPORT_E_EVALUATION',
    owner: 'ALL',
    permission: [PERMISSION_REPORT_E_EVALUATION.DASHBOARD],
  },
]

export const permissionReportEEvaluationByCourseMenu = [
  {
    module: 'REPORT_E_EVALUATION',
    owner: 'ALL',
    permission: [PERMISSION_REPORT_E_EVALUATION.REPORT_RAW_DATA_BY_COURSE],
  },
]
export const permissionReportEEvaluationByTrainerMenu = [
  {
    module: 'REPORT_E_EVALUATION',
    owner: 'ALL',
    permission: [PERMISSION_REPORT_E_EVALUATION.REPORT_RAW_DATA_BY_TRAINER],
  },
]

export const permissionEValuationView = [
  {
    module: 'E_EVALUATION_SETTING',
    owner: 'ALL',
    permission: ['VIEW'],
  },
]

export const permissionEValuationCreate = [
  {
    module: 'E_EVALUATION_SETTING',
    owner: 'ALL',
    permission: ['CREATE'],
  },
]

export const permissionEValuationEdit = [
  {
    module: 'E_EVALUATION_SETTING',
    owner: 'ALL',
    permission: ['EDIT'],
  },
  {
    module: 'E_EVALUATION_SETTING',
    owner: 'PIC',
    permission: ['EDIT'],
  },
]

export const permissionManageClassMenu = [
  {
    module: 'E_MANAGECLASS_SETTING',
    owner: 'ALL',
    permission: ['MENU'],
  },
]

export const permissionManageClassMenuClass = [
  {
    module: 'E_MANAGECLASS_SETTING',
    owner: 'ALL',
    permission: ['MENU_ALL_CLASS'],
  },
]

export const permissionManageClassMenuExam = [
  {
    module: 'E_MANAGECLASS_SETTING',
    owner: 'ALL',
    permission: ['MENU_EXAM'],
  },
]

export const permissionManageClassMenuOic = [
  {
    module: 'E_MANAGECLASS_SETTING',
    owner: 'ALL',
    permission: ['MENU_CHECK_OIC'],
  },
]

export const permissionManageClassEditClass = [
  {
    module: 'E_MANAGECLASS_SETTING',
    owner: 'ALL',
    permission: ['EDIT_ALL_CLASS'],
  },
  {
    module: 'E_MANAGECLASS_SETTING',
    owner: 'PIC',
    permission: ['EDIT_ALL_CLASS'],
  },
]

export const permissionManageClassEditExam = [
  {
    module: 'E_MANAGECLASS_SETTING',
    owner: 'ALL',
    permission: ['EDIT_EXAM'],
  },
  {
    module: 'E_MANAGECLASS_SETTING',
    owner: 'PIC',
    permission: ['EDIT_EXAM'],
  },
]

export const permissionManageClassEditOic = [
  {
    module: 'E_MANAGECLASS_SETTING',
    owner: 'ALL',
    permission: ['EDIT_CHECK_OIC'],
  },
]

export const permissionStandaloneViewExam = [
  {
    module: 'E_TESTING_EXAM',
    owner: 'ALL',
    permission: ['VIEW'],
  },
]

export const permissionStandaloneEditExam = [
  {
    module: 'E_TESTING_EXAM',
    owner: 'ALL',
    permission: ['EDIT_EXAM'],
  },
  {
    module: 'E_TESTING_EXAM',
    owner: 'PIC',
    permission: ['EDIT_EXAM'],
  },
]

export const permissionRoomManagementMenu = [
  {
    module: 'ROOMMANAGEMENT_SETTING',
    owner: 'ALL',
    permission: ['MENU'],
  },
]

export const permissionRoomManagementView = [
  {
    module: 'ROOMMANAGEMENT_SETTING',
    owner: 'ALL',
    permission: ['VIEW'],
  },
]

export const permissionRoomManagementEdit = [
  {
    module: 'ROOMMANAGEMENT_SETTING',
    owner: 'ALL',
    permission: ['EDIT'],
  },
  {
    module: 'ROOMMANAGEMENT_SETTING',
    owner: 'PIC',
    permission: ['EDIT'],
  },
]

export const permissionRoomManagementDelete = [
  {
    module: 'ROOMMANAGEMENT_SETTING',
    owner: 'ALL',
    permission: ['DELETE'],
  },
  {
    module: 'ROOMMANAGEMENT_SETTING',
    owner: 'PIC',
    permission: ['DELETE'],
  },
]

export const permissionELearningCourseCreate = [
  {
    module: 'E_LEARNING',
    owner: 'ALL',
    permission: ['HEAD_ADMIN'],
  },
]

export const permissionELearningCourseEdit = [
  {
    module: 'E_LEARNING',
    owner: 'ALL',
    permission: ['HEAD_ADMIN'],
  },
  {
    module: 'E_LEARNING',
    owner: 'PIC',
    permission: ['ADMIN'],
  },
]

export const permissionELearningCourseView = [
  {
    module: 'E_LEARNING',
    owner: 'ALL',
    permission: ['HEAD_ADMIN'],
  },
  {
    module: 'E_LEARNING',
    owner: 'PIC',
    permission: ['ADMIN'],
  },
  {
    module: 'E_LEARNING',
    owner: 'PIC',
    permission: ['TRAINER'],
  },
]

export const permissionEExaminationView = [
  {
    module: 'E_EXAMINATION_SETTING',
    owner: 'ALL',
    permission: ['VIEW'],
  },
]

export const permissionEExaminationDc = [
  {
    module: 'E_EXAMINATION_SETTING',
    owner: 'ALL',
    permission: ['DC'],
  },
]

export const permissionInventoryView = [
  {
    module: 'INVENTORY',
    owner: 'ALL',
    permission: ['ADMIN'],
  },
  {
    module: 'INVENTORY',
    owner: 'PIC',
    permission: ['CREATE'],
  },
]

export const permissionVendorView = [
  {
    module: 'INVENTORY',
    owner: 'ALL',
    permission: ['VM'],
  },
]

export const permissionEConfigView = [
  {
    module: 'CONFIGURATION',
    owner: 'ALL',
    permission: ['ADMIN'],
  },
]

export const permissionLearningPointCatalog = [
  { module: 'LEARNING_POINT', owner: 'ALL', permission: ['CATALOG'] },
]

export const permissionLearningPointSettingPoint = [
  { module: 'LEARNING_POINT', owner: 'ALL', permission: ['SETTING_POINT'] },
]

export const permissionLearningPointManagePoint = [
  { module: 'LEARNING_POINT', owner: 'ALL', permission: ['MANAGE_POINT'] },
]

export const permissionRequisitionView = [
  {
    module: 'INVENTORY',
    owner: 'ALL',
    permission: ['ADMIN'],
  },
  {
    module: 'INVENTORY',
    owner: 'PIC',
    permission: ['MP'],
  },
]

export const permissionReportCourseSetting = [
  {
    module: 'REPORT_COURSE_SETTING',
    owner: 'ALL',
    permission: ['REPORT'],
  },
]

export const permissionReportClass = [
  {
    module: 'REPORT_CLASS',
    owner: 'ALL',
    permission: ['REPORT'],
  },
]

export const permissionDashboardCourseSetting = [
  {
    module: 'REPORT_COURSE_SETTING',
    owner: 'ALL',
    permission: ['DASHBOARD'],
  },
]

export const permissionCourseSetting = [
  {
    module: 'COURSE_SETTING',
    owner: 'ALL',
    permission: ['CREATE', 'DELETE', 'VIEW'],
  },
]

export const permissionExpense = [
  {
    module: 'EXPENSE',
    owner: 'ALL',
    permission: ['ADMIN'],
  },
]

export const permissionEContentMenu = [
  { module: 'E_CONTENT_AND_KB', owner: 'ALL', permission: ['MENU'] },
]

export const permissionEContentView = [
  { module: 'E_CONTENT_AND_KB', owner: 'ALL', permission: ['VIEW'] },
]

export const permissionEContentForm = [
  { module: 'E_CONTENT_AND_KB', owner: 'ALL', permission: ['CREATE', 'EDIT'] },
  {
    module: 'E_CONTENT_AND_KB',
    owner: 'PIC',
    permission: ['CREATE_ECONTENT', 'EDIT_ECONTENT'],
  },
  {
    module: 'E_CONTENT_AND_KB',
    owner: 'PIC',
    permission: ['CREATE_KB', 'EDIT_KB'],
  },
]

export const permissionReportManageClassWorkloadTo = [
  {
    module: 'E_MANAGECLASS_SETTING',
    owner: 'ALL',
    permission: ['MENU'],
  },
]

export const permissionReportManageClassHF = [
  {
    module: 'E_MANAGECLASS_SETTING',
    owner: 'ALL',
    permission: ['MENU'],
  },
]

export const permissionReportManageClassByClass = [
  {
    module: 'E_MANAGECLASS_SETTING',
    owner: 'ALL',
    permission: ['MENU'],
  },
]

export const permissionReportManageClassByLearner = [
  {
    module: 'E_MANAGECLASS_SETTING',
    owner: 'ALL',
    permission: ['MENU'],
  },
]

export const permissionReportManageClassDashboard = [
  {
    module: 'E_MANAGECLASS_SETTING',
    owner: 'ALL',
    permission: ['MENU'],
  },
]

export const permissionManageClassEvaDashboard = [
  {
    module: 'E_MANAGECLASS_SETTING',
    owner: 'ALL',
    permission: ['DASHBOARD_EVALUATION'],
  },
]

export const permissionReportEExaminationResult = [
  {
    module: 'REPORT_E_EXAMINATION',
    owner: 'ALL',
    permission: ['REPORT_RESULT'],
  },
]

export const permissionReportEExaminationSchedule = [
  {
    module: 'REPORT_E_EXAMINATION',
    owner: 'ALL',
    permission: ['REPORT_SCHEDULE'],
  },
]

export const permissionReportEExaminationEnroll = [
  {
    module: 'REPORT_E_EXAMINATION',
    owner: 'ALL',
    permission: ['REPORT_ENROLL'],
  },
]

export const permissionReportETestingDashboard = [
  {
    module: 'REPORT_E_TESTING',
    owner: 'ALL',
    permission: ['DASHBOARD'],
  },
]
export const permissionReportETestingByClass = [
  {
    module: 'REPORT_E_TESTING',
    owner: 'ALL',
    permission: ['REPORT_BY_CLASS'],
  },
]
export const permissionReportETestingByLearner = [
  {
    module: 'REPORT_E_TESTING',
    owner: 'ALL',
    permission: ['REPORT_BY_LEARNER'],
  },
]

export const permissionDashboardEExamination = [
  { module: 'REPORT_E_EXAMINATION', owner: 'ALL', permission: ['DASHBOARD'] },
]

export const permissionReportDashboardMonthlyPlan = [
  {
    module: 'REPORT_MONTHLY_PLAN',
    owner: 'ALL',
    permission: ['REPORT_DASHBOARD'],
  },
]

export const permissionReportManageProfileAgent = [
  {
    module: 'REPORT_MANAGE_PROFILE',
    owner: 'ALL',
    permission: ['REPORT_AGENT'],
  },
]

export const permissionReportManageProfileProspect = [
  {
    module: 'REPORT_MANAGE_PROFILE',
    owner: 'ALL',
    permission: ['REPORT_PROSPECT'],
  },
]

export const permissionReportManageProfileStaff = [
  {
    module: 'REPORT_MANAGE_PROFILE',
    owner: 'ALL',
    permission: ['REPORT_STAFF'],
  },
]

export const permissionReportManageProfileAgentForTo = [
  {
    module: 'REPORT_MANAGE_PROFILE',
    owner: 'ALL',
    permission: ['REPORT_AGENT_TO'],
  },
]

export const permissionReportManageProfileAgentForTrainer = [
  {
    module: 'REPORT_MANAGE_PROFILE',
    owner: 'ALL',
    permission: ['REPORT_AGENT_TRAINER'],
  },
]

export const permissionDashboardManageProfile = [
  {
    module: 'REPORT_MANAGE_PROFILE',
    owner: 'ALL',
    permission: ['DASHBOARD'],
  },
]

export const permissionReportMixModule = [
  {
    module: 'REPORT_MIX_MODULE',
    owner: 'ALL',
    permission: ['REPORT'],
  },
]

export const permissionMixModuleDashboard = [
  {
    module: 'REPORT_MIX_MODULE',
    owner: 'ALL',
    permission: ['REPORT_DASHBOARD_MIX_MODULE'],
  },
]

export const permissionMixModuleMlMc = [
  {
    module: 'REPORT_MIX_MODULE',
    owner: 'ALL',
    permission: ['REPORT_MONTHLY_PLAN_MANAGE_CLASS'],
  },
]

export const permissionMixModuleExpenseMc = [
  {
    module: 'REPORT_MIX_MODULE',
    owner: 'ALL',
    permission: ['REPORT_EXPENSE_MANAGE_CLASS'],
  },
]

export const permissionReportRoomManagement = [
  {
    module: 'REPORT_ROOM_MANAGEMENT',
    owner: 'ALL',
    permission: ['DASHBOARD_REPORT'],
  },
  {
    module: 'REPORT_ROOM_MANAGEMENT',
    owner: 'PIC',
    permission: ['DASHBOARD_REPORT'],
  },
]

export const permissionReportExpense = [
  {
    module: 'REPORT_EXPENSE',
    owner: 'ALL',
    permission: ['REPORT_EXPENSE'],
  },
]

export const permissionDashboardExpense = [
  {
    module: 'REPORT_EXPENSE',
    owner: 'ALL',
    permission: ['DASHBOARD_ADMIN', 'DASHBOARD_HF', 'DASHBOARD_TSD'],
  },
  {
    module: 'REPORT_EXPENSE',
    owner: 'PIC',
    permission: ['DASHBOARD_BY_DEPARTMENT'],
  },
]

export const permissionAuditLog = [
  {
    module: 'AUDIT_LOG',
    owner: 'ALL',
    permission: ['AUDIT_LOG'],
  },
]

export const permissionViewNonAcademy = [
  {
    module: 'NON_ACADEMY_PROFILE_SETTING',
    owner: 'ALL',
    permission: ['VIEW', 'EDIT'],
  },
]

export const permissionManageNonAcademy = [
  {
    module: 'NON_ACADEMY_PROFILE_SETTING',
    owner: 'ALL',
    permission: ['EDIT'],
  },
]

export const permissionInventoryDashboard = [
  {
    module: 'INVENTORY',
    owner: 'ALL',
    permission: ['DASHBOARD'],
  },
  {
    module: 'INVENTORY',
    owner: 'PIC',
    permission: ['DASHBOARD'],
  },
]

export const permissionInventoryReport = [
  {
    module: 'INVENTORY',
    owner: 'ALL',
    permission: ['REPORT'],
  },
  {
    module: 'INVENTORY',
    owner: 'PIC',
    permission: ['REPORT'],
  },
]
