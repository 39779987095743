import _ from 'lodash'
import {
  setInitialTable,
  startLoading,
  stopLoading,
} from '../../../../../redux/slices/table/basicTable'
import { getLearningPathHistory } from '../../../../../services/eLearning/learningPath/detail'
import { StyledTable } from '../../../components/View/HistoryDrawer/Styled'
import { headCellsHistory } from '../../../model'

export const fetchDataHistory = (props) => async (dispatch) => {
  const { table, uuid } = props
  const realPage = table.page <= 0 ? 1 : +table.page
  const url = `?limit=${table.limit}&page=${realPage}&uuid=${uuid}`
  dispatch(startLoading())

  dispatch(
    setInitialTable({
      isCheckBox: false,
      rows: [],
      allCount: 0,
      headCells: headCellsHistory,
    }),
  )

  const result = await dispatch(getLearningPathHistory(url))
  const payload = _.get(result, 'payload', undefined)

  if (payload) {
    const rows = _.get(result, 'payload.data.result', []).map((row) => ({
      ...row,
      createdBy: _.get(row, 'createdBy.name', ''),
    }))
    dispatch(
      setInitialTable({
        isCheckBox: false,
        rows,
        allCount: _.get(result, 'payload.data.totalCount', 0),
        headCells: headCellsHistory,
        customStyle: StyledTable,
      }),
    )
  }
  dispatch(stopLoading())
}
