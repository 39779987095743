import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Table from '../../../components/redux/Table2'
import { setChangeRowsPerPage } from '../../../redux/slices/table2'
import { MINI_ROW_PAGE_PER_LIST } from '../../../constants/table'
import { StyledCard } from '../Equipment/Styled'
import { handleETestingTable } from './events'

const ETesting = ({ userId }) => {
  const dispatch = useDispatch()
  const {
    table: { isLoading, limit, order, page, sort },
  } = useSelector((state) => state.table2)

  useEffect(() => {
    dispatch(
      setChangeRowsPerPage({
        defaultRowsPerPage: 20,
        limit: 20,
        rowsPerPage: { label: '20', value: 20 },
      }),
    )
  }, [])
  useEffect(() => {
    dispatch(handleETestingTable({ userId }))
  }, [page, sort, limit, order, userId])

  return (
    <StyledCard isLoading={isLoading}>
      <Table rowsPerPageOptions={MINI_ROW_PAGE_PER_LIST} />
    </StyledCard>
  )
}

export default ETesting
