export const arrayStatus = [
  {
    label: 'เปิดใช้งาน',
    name: 'activeChecked',
  },
  {
    label: 'ปิดใช้งาน',
    name: 'inActiveChecked',
  },
  {
    label: 'ถูกลบแล้ว',
    name: 'deletedChecked',
  },
  {
    label: 'ไม่พร้อมใช้งาน',
    name: 'inProgressChecked',
  },
]

export const arrayFileType = [
  { label: '.doc', name: 'docChecked' },
  { label: '.docx', name: 'docxChecked' },
  { label: '.ppt', name: 'pptChecked' },
  { label: '.pptx', name: 'pptxChecked' },
  { label: '.pdf', name: 'pdfChecked' },
  { label: '.zip', name: 'zipChecked' },
]

export const arrayType = [
  { label: 'E-Testing', name: 'eTestingChecked' },
  { label: 'E-Evaluation', name: 'eEvaluationChecked' },
  { label: 'Embed Content', name: 'embedContentChecked' },
  { label: 'Link to another website', name: 'linkToChecked' },
  { label: 'Upload file', name: 'uploadFileChecked', list: arrayFileType },
  { label: 'Scorm file', name: 'scormFileChecked' },
]

export const arrayCheckbox = [
  {
    label: 'รหัสโมดูล',
    nameCheckBox: 'moduleCodeChecked',
    nameInput: 'moduleCode',
    placeholder: 'ค้นหารหัสโมดูล',
    type: 'text',
  },
  {
    label: 'ชื่อโมดูล',
    nameCheckBox: 'nameChecked',
    nameInput: 'name',
    placeholder: 'ค้นหาชื่อโมดูล',
    type: 'text',
  },
  {
    label: 'รูปแบบโมดูล',
    nameCheckBox: 'moduleTypeChecked',
    nameInput: 'moduleType',
    type: 'checkbox',
    list: arrayType,
  },
  {
    label: 'สถานะ',
    nameCheckBox: 'statusChecked',
    nameInput: 'status',
    type: 'checkbox',
    list: arrayStatus,
  },
  {
    label: 'สร้างโดย',
    nameCheckBox: 'createdByChecked',
    nameInput: 'createdBy',
    placeholder: 'ค้นหาสร้างโดย',
    type: 'text',
  },
  {
    label: 'แก้ไขล่าสุดโดย',
    nameCheckBox: 'updatedByChecked',
    nameInput: 'updatedBy',
    placeholder: 'ค้นหาแก้ไขล่าสุดโดย',
    type: 'text',
  },
]

export const defaultFilter = {
  moduleCodeChecked: false,
  moduleCode: '',
  nameChecked: false,
  name: '',
  moduleTypeChecked: false,
  moduleType: {
    eTestingChecked: false,
    eEvaluationChecked: false,
    embedContentChecked: false,
    linkToChecked: false,
    uploadFileChecked: false,
    scormFileChecked: false,
  },
  fileType: {
    docChecked: false,
    docxChecked: false,
    pttChecked: false,
    pttxChecked: false,
    pdfChecked: false,
    zipChecked: false,
  },
  statusChecked: false,
  status: {
    activeChecked: false,
    inActiveChecked: false,
    deletedChecked: false,
    inProgressChecked: false,
  },
  createdByChecked: false,
  createdBy: '',
  updatedByChecked: false,
  updatedBy: '',
}
