import { Box, IconButton, Typography } from '@mui/material'
import { handleInputNumber } from '../../../../../components/CRUD/handler/handleInputNumber'
import {
  handleNumberKeyDown,
  handlePasteFormat,
} from '../../../../../utils/lib'
import { regex } from '../../../../../components/CRUD/enum/regex'
import { CustomDropdown } from '../../../../../components/CRUD/components/CustomDropdown'
import { ContentRow } from './ContentRow'
import { LabelWithValue } from './LabelWithValue'
import { CustomInput } from '../../../../../components/CRUD/components/CustomInput'
import { DeleteTwoTone } from '@mui/icons-material'
import { useTheme } from '@mui/system'
import { handleDeleteAmount } from '../../../../../modules/Expense/handler/handleDeleteAmount'

export const AmountItem = ({ disabledDeleteBtn, idx }) => {
  const theme = useTheme()
  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={2}
      sx={{ background: theme.palette?.info?.background }}
      p={2}
      borderRadius={2}
    >
      <Box display="flex" justifyContent="space-between">
        <Typography variant="body1b">รายการที่ {idx + 1}</Typography>
        <IconButton
          disabled={disabledDeleteBtn}
          onClick={() => handleDeleteAmount(idx)}
        >
          <DeleteTwoTone
            sx={{
              color: disabledDeleteBtn
                ? theme.palette?.action?.disabled
                : theme.palette?.action?.active,
            }}
          />
        </IconButton>
      </Box>

      <ContentRow
        columnLeft={
          <CustomDropdown
            required
            fieldName={`amountList[${idx}].accountCode`}
            masterDataName="accountCode"
            labelName="Account Code (Old / New)"
            placeholder="เลือก Account Code"
          />
        }
        columnRight={
          <LabelWithValue
            label="Description"
            fieldName={`amountList[${idx}]accountCode.description`}
          />
        }
      />
      <CustomInput
        boxSx={{ maxWidth: '48%' }}
        required
        fieldName={`amountList[${idx}].amount`}
        labelName="Amount"
        inputProps={{
          'data-testid': 'input-amount',
          onInput: (e) => {
            handleInputNumber(e, regex.NUMBER)
          },
          onKeyDown: (e) => {
            handleNumberKeyDown(e, ['.'])
          },
          onPaste: handlePasteFormat,
        }}
      />
    </Box>
  )
}
