import {
  setInitialTable,
  setSearchText,
  startLoading,
  stopLoading,
  setFilterProp,
  resetTablePage,
  setStatusFilter,
  setSelected,
  setDefaultSort,
  setOrder,
  clearFilter,
} from '../../../../../redux/slices/table'
import { store } from '../../../../../App'
import { headerCells } from '../model/headerCells'
import { crudConfig } from '../crudConfig'
import { convertFormatDateTime } from '../../../../../utils/lib'
import _ from 'lodash'
import { ROW_PAGE_PER_LIST } from '../../../../../constants/table'
import { mutateFilterPropToBody } from '../mutation/filterPropToBody'
import callAxios from '../../../../../utils/baseService'
import { handleQuickSearch } from '../../../../../components/CRUD/handler/handleQuickSearch'
import { onDownload } from '../../../../../components/CRUD/handler/onDownload'
import { handleDeleteClick } from '../../../../../components/CRUD/handler/handleDeleteClick'
import { enumProductType } from '../../../constant'
import { STATUS } from '../model/status'
import { handleSetDataRows } from './handleSetDataRows'
import { filterItemsAsset } from '../model/filterItems'
import { setFieldValue } from '../../../../../redux/slices/crud'

const resetTable = () => {
  store.dispatch(setSearchText(''))
  store.dispatch(setFilterProp(null))
  store.dispatch(resetTablePage())
  store.dispatch(clearFilter())
  store.dispatch(setStatusFilter([]))
  store.dispatch(setSelected([]))
  store.dispatch(setDefaultSort({ state: 0, id: '', active: false }))
  store.dispatch(setOrder({ order: 'desc', sort: 'updatedAt' }))
  filterItemsAsset.map((item) => {
    if (item.type === 'select_dropdown') {
      store.dispatch(
        setFieldValue({
          key: `formData.${item.nameInput}-filter-dropdown`,
          value: '',
        }),
      )
    }
  })
}

export const handleChangeHeadCells = async (selectedTab) => {
  resetTable()
  const { table, search, filter: filterState } = store.getState().table
  const { page } = table
  const { status, filterProp } = filterState
  const { searchText } = search

  const realPage = page <= 0 ? 1 : +page
  const tableProps = table
  const filter = filterProp

  let moduleId = 'INVENTORY_PRODUCT'
  let headCells = headerCells()
  let productType = [enumProductType.STOCK, enumProductType.STOCK_ASSET]
  switch (selectedTab) {
    case 0:
      productType = [enumProductType.STOCK, enumProductType.STOCK_ASSET]
      moduleId += '_STOCK'
      break
    case 1:
      productType = [enumProductType.ASSET]
      moduleId += '_ASSET'
      break
    case 2:
      productType = [enumProductType.LICENSE]
      moduleId += '_LICENSE'
      break
  }

  store.dispatch(
    setFieldValue({
      key: 'crudConfig.moduleId',
      value: moduleId,
    }),
  )

  const body = {
    limit: _.get(tableProps, 'limit', ROW_PAGE_PER_LIST[0].value),
    page: realPage,
    order: tableProps.order.toString().toUpperCase(),
    sort: _.get(tableProps, 'sort', 'updatedAt'),
    quickSearch: searchText,
    ...mutateFilterPropToBody(),
    createdBy: _.get(filter, 'createdBy', ''),
    updatedBy: _.get(filter, 'updatedBy', ''),
    search: '',
    status: [STATUS.ACTIVE, STATUS.INACTIVE, STATUS.DELETED],
    startCreatedDate: '',
    endCreatedDate: '',
    startUpdatedDate: '',
    endUpdatedDate: '',
    productType,
  }

  store.dispatch(startLoading())
  const isCancel = await callAxios
    .post(crudConfig.filterPath, body, { 'x-type': moduleId })
    .then((res) => {
      const row = _.get(res, 'data.result', []).map((item) => ({
        ...item,
        categoryName: _.get(item, 'category.name', ''),
        stockCategoryName: _.get(item, 'stockCategory.name', ''),
        createdAt: convertFormatDateTime({
          value: item.createdAt,
          type: 'date',
        }),
      }))
      store.dispatch(
        setInitialTable({
          rows: row,
          allCount: _.get(res, 'data.totalCount', 0),
          headCells: headCells,
          placeholder: 'ค้นหาด้วยคีย์เวิร์ด',
          searchKey: 'name',
          status: status,
          handleSearch: (text) =>
            store.dispatch(
              handleQuickSearch(tableProps, text, mutateFilterPropToBody),
            ),
          onDownload: (selected, sort, order) =>
            store.dispatch(onDownload(selected, sort, order)),
          onDelete: (selected) => {
            handleDeleteClick(selected.uuid, mutateFilterPropToBody)
          },
        }),
      )
      handleSetDataRows()
    })
    .catch((e) => {
      store.dispatch(
        setInitialTable({
          rows: [],
          allCount: 0,
          headCells: crudConfig.headerCells(),
        }),
      )
      return e.message?.includes('method')
    })
  if (!isCancel) store.dispatch(stopLoading())
}
