import { setChangeSetting } from '../../../../../../../../redux/slices/eTestingForm'

export const handleQuestionResult = (props) => (dispatch) => {
  if (props.value === 'NOW') {
    dispatch(
      setChangeSetting({
        value: 'ONE',
        key: 'displayQuestion',
      }),
    )
  }
  dispatch(setChangeSetting(props))
}

export const handleSwitchIsOpen = (props) => (dispatch) => {
  if (props.result === 'NOW' && props.result) {
    dispatch(
      setChangeSetting({
        value: 'ONE',
        key: 'displayQuestion',
      }),
    )
  }
  dispatch(setChangeSetting(props))
}
