import React, { useEffect, useState, useContext } from 'react'
import Card from '@mui/material/Card'
import { useHistory } from 'react-router-dom'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import AddIcon from '@mui/icons-material/Add'
import useTheme from '@mui/system/useTheme'
import { useLocalStorage } from '@rehooks/local-storage'
import CircularProgress from '@mui/material/CircularProgress'
import { DialogContext } from '../../context/dialog-context'
import Table from '../../components/Table'
import Breadcrumbs from '../../components/Breadcrumbs'
import FilterDrawer from './FilterDrawer'
import {
  acquiredSkill,
  downloadAcquiredSkill,
  filterAcquiredSkill,
  filterSkillType,
} from '../../utils/apiPath'
import {
  callAPI,
  handleDownload,
  hasPermissionCreateMasterData,
} from '../../utils/lib'
import { handleFilterTotal } from '../../redux/slices/table/events'

export const headCells = [
  {
    id: 'status',
    label: '',
    width: '20px',
    hideSortIcon: true,
  },
  {
    id: 'id',
    disablePadding: false,
    label: 'ID',
    width: '60px',
  },
  {
    id: 'acquiredSkill',
    label: 'ระดับหลักสูตร',
  },
  {
    id: 'action',
    disablePadding: false,
    label: 'คำสั่ง',
    width: '90px',
    hideSortIcon: true,
    deletePermission: 'DELETE',
    deleteModuleType: 'MASTER_DATA',
  },
]

export const breadcrumbList = [
  { title: 'Master Data', link: '/', pointer: false },
  { title: 'ระดับหลักสูตร', link: '/', pointer: false },
]

const AcquiredSkillList = () => {
  const theme = useTheme()
  const [user] = useLocalStorage('user')
  const history = useHistory()
  const hasPermissionCreate = hasPermissionCreateMasterData({ user })
  const [isLoading, setIsLoading] = useState(false)
  const [isPageLoading, setIsPageLoading] = useState(false)
  const [limit, setLimit] = useState('100')
  const [order, setOrder] = useState('DESC')
  const [page, setPage] = useState(-1)
  const [sort, setSort] = useState('')
  const [stateAcquiredSkillList, setStateAcquiredSkillList] = useState([])
  const [allCount, setAllCount] = useState(0)
  const [searchText, setSearchText] = useState('')
  const [text, setText] = useState('')
  const [status, setStatus] = useState([])
  const [isFilterDrawer, setIsFilterDrawer] = useState(false)
  const [tablePage, setTablePage] = useState(0)
  const [filterTotal, setFilterTotal] = useState(0)
  const { context, setContext: setDialog } = useContext(DialogContext)
  const dialog = context.dialog

  useEffect(() => {
    setIsPageLoading(true)
    fetchAcquiredSkillList('fetch', status, limit, order, page, sort)
  }, [limit, order, sort, page])

  const fetchAcquiredSkillList = async (
    method,
    statusList,
    valueLimit,
    valueOrder,
    valuePage,
    valueSort,
    search,
    createdBy,
    updatedBy,
  ) => {
    const sortBy = valueSort === '' ? 'updatedAt' : valueSort
    const realPage = valuePage <= 0 ? 1 : +valuePage
    setIsPageLoading(true)
    const body = {
      acquiredSkill: method !== 'fetch' ? search : searchText,
      limit: valueLimit,
      order: valueOrder.toString().toUpperCase(),
      page: realPage,
      sort: sortBy,
      status: statusList,
      createdBy: method === 'filter' ? createdBy : '',
      updatedBy: method === 'filter' ? updatedBy : '',
    }
    await callAPI({
      url: filterAcquiredSkill,
      method: 'POST',
      body: body,
      onSuccess: (res) => {
        setStateAcquiredSkillList(res.result)
        setAllCount(res.totalCount)
      },
      onFinally: () => {
        setIsPageLoading(false)
      },
    })
  }

  return (
    <Box sx={{ pb: 2 }}>
      <Box
        sx={{
          opacity: isPageLoading ? 0.4 : 'unset',
          pointerEvents: isPageLoading ? 'none' : 'unset',
        }}
      >
        <Box
          sx={{
            mx: 3,
            justifyContent: 'space-between',
            display: 'flex',
          }}
        >
          <Typography variant="h4">ระดับหลักสูตร (Acquired Skill)</Typography>
        </Box>
        <Breadcrumbs sx={{ mx: 3, mb: 3 }} menuList={breadcrumbList} />
        <Button
          data-testid={'btn-add-acquired-skill'}
          sx={{
            ml: 3,
            [theme.breakpoints.up('md')]: {
              position: 'absolute',
              mt: -10,
              mr: 3,
            },
            right: 0,
          }}
          onClick={() => history.push('/master-data/acquired-skill/form')}
          disabled={!hasPermissionCreate}
          startIcon={<AddIcon />}
          variant="contained"
        >
          เพิ่มระดับหลักสูตร
        </Button>
        <Card sx={{ minWidth: 275, mx: 3, mt: 3 }}>
          <Table
            data={stateAcquiredSkillList}
            headCells={headCells}
            onView={(row) => onView(row, history)}
            onEdit={(row) => onEdit(row, history)}
            onDelete={(row) => onDelete(row, dialog, setDialog, setIsLoading)}
            placeholder="ค้นหาระดับหลักสูตร"
            searchKey={'acquiredSkill'}
            setLimit={setLimit}
            order={order}
            setOrder={setOrder}
            page={page}
            setPage={setPage}
            sort={sort}
            setSort={setSort}
            allCount={allCount}
            handleSearch={(valueText) =>
              handleQuickSearch(
                valueText,
                limit,
                order,
                sort,
                setTablePage,
                fetchAcquiredSkillList,
              )
            }
            onDownload={(selected) =>
              onDownload(selected, order, sort, setIsLoading)
            }
            setIsFilterDrawer={setIsFilterDrawer}
            searchText={searchText}
            setSearchText={setSearchText}
            tablePage={tablePage}
            setTablePage={setTablePage}
            text={text}
            setText={setText}
            filterTotal={filterTotal}
          />
        </Card>
      </Box>
      <CircularProgress
        disableShrink
        sx={{
          position: 'absolute',
          display: isPageLoading && isLoading ? 'unset' : 'none',
          top: '50%',
          left: '50%',
          color: 'primary.main',
        }}
      />
      <FilterDrawer
        open={isFilterDrawer}
        onClose={() => setIsFilterDrawer(false)}
        onFilterClick={(filter) =>
          onFilterClick({
            filter,
            limit,
            order,
            sort,
            setStatus,
            setTablePage,
            setText,
            setSearchText,
            fetchAcquiredSkillList,
            setFilterTotal,
          })
        }
      />
    </Box>
  )
}
export default AcquiredSkillList

export const onView = (row, history) => {
  history.push('/master-data/acquired-skill/' + row.uuid)
}
export const onEdit = (row, history) => {
  history.push('/master-data/acquired-skill/edit/' + row.uuid)
}
export const onFilterClick = (props) => {
  const {
    filter,
    limit,
    order,
    sort,
    setStatus,
    setTablePage,
    setText,
    setSearchText,
    fetchAcquiredSkillList,
    setFilterTotal,
  } = props
  setSearchText('')
  setText('')
  const statusList = []
  if (filter.statusChecked) {
    if (filter.status.activeChecked) {
      statusList.push('ACTIVE')
    }
    if (filter.status.inActiveChecked) {
      statusList.push('INACTIVE')
    }
    if (filter.status.deletedChecked) {
      statusList.push('DELETED')
    }
  }
  const createdBy = filter?.createdByChecked ? filter.createdBy : ''
  const updatedBy = filter?.updatedByChecked ? filter.updatedBy : ''
  setStatus(statusList)
  const acquiredSkillText = filter?.acquiredSkillChecked
    ? filter.acquiredSkillText
    : ''
  setSearchText(acquiredSkillText)
  setTablePage(0)
  fetchAcquiredSkillList(
    'filter',
    statusList,
    limit,
    order,
    1,
    sort,
    acquiredSkillText,
    createdBy,
    updatedBy,
  )
  setFilterTotal(handleFilterTotal(filter))
}
export const handleDelete = async (row, setIsLoading, setDialog) => {
  const path = acquiredSkill + `/${row.uuid}`
  setIsLoading(true)
  await callAPI({
    url: path,
    method: 'DELETE',
    onSuccess: () => {
      setDialog({
        variant: 'success',
        content: 'ถูกลบแล้ว',
        open: true,
        isLoading: false,
        onConfirmClick: () => window.location.reload(),
      })
    },
    onFinally: () => {
      setIsLoading(false)
    },
  })
}
export const onDelete = async (row, dialog, setDialog, setIsLoading) => {
  setIsLoading(true)
  const body = {
    skillType: '',
    acquiredSkills: [row.uuid],
    limit: -1,
    page: 1,
    order: 'ASC',
    sort: 'updatedAt',
    status: [],
  }
  await callAPI({
    url: filterSkillType,
    method: 'POST',
    body: body,
    onSuccess: (res) => {
      setIsLoading(false)
      const result = res.result
      let concatText = ''
      if (result.length > 0) {
        result.map((item, index) => {
          if (index === 0 && result.length > 1) {
            concatText += item.skillType + ', '
          } else {
            concatText += item.skillType
          }
        })
        setDialog({
          ...dialog,
          content: `ระดับหลักสูตรนี้ เกี่ยวข้องกับ ประเภทของทักษะ : ${concatText} จะถูกลบข้อมูลของระดับหลักสูตรนี้ออกเช่นกัน คุณต้องการลบรายการนี้หรือไม่`,
          variant: 'delete',
          open: true,
          onConfirmClick: () => handleDelete(row, setIsLoading, setDialog),
          onCancelClick: () =>
            setDialog({
              variant: 'delete',
              content: 'คุณต้องการลบรายการนี้หรือไม่',
              open: false,
            }),
        })
      } else {
        setDialog({
          ...dialog,
          content: 'คุณต้องการลบรายการนี้หรือไม่',
          variant: 'delete',
          open: true,
          onConfirmClick: () => handleDelete(row, setIsLoading, setDialog),
          onCancelClick: () =>
            setDialog &&
            setDialog({
              variant: 'delete',
              content: 'คุณต้องการลบรายการนี้หรือไม่',
              open: false,
            }),
        })
      }
    },
  })
}
export const onDownload = async (selected, order, sort, setIsLoading) => {
  setIsLoading(true)
  const body = {
    acquiredSkill: '',
    order: order.toUpperCase(),
    sort: sort === '' ? 'updatedAt' : sort,
    status: [],
    type: 'CUSTOM',
    list: selected,
  }
  await handleDownload({
    url: downloadAcquiredSkill,
    body: body,
    fileName: `รายการระดับหลักสูตร.xlsx`,
  })
  setIsLoading(false)
}
export const handleQuickSearch = async (
  valueText,
  limit,
  order,
  sort,
  setTablePage,
  fetchAcquiredSkillList,
) => {
  setTablePage(0)
  const statusList = []
  await fetchAcquiredSkillList(
    'search',
    statusList,
    limit,
    order,
    1,
    sort,
    valueText,
  )
}
