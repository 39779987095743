import React from 'react'
import Drawer from '@mui/material/Drawer'
import IconButton from '@mui/material/IconButton'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import CloseIcon from '@mui/icons-material/Close'
import useTheme from '@mui/system/useTheme'
import {
  convertFormatDateTime,
  eConfigHistoryEditPreview,
} from '../../../../utils/lib'

import { eConfigActionList } from '../../../../constants/eConfig'

const DetailDrawyer = (props) => {
  const { open, onClose, historyData } = props
  const theme = useTheme()
  let oldValue, newValue
  oldValue = eConfigHistoryEditPreview(
    historyData?.data?.key,
    historyData?.data?.old,
  )
  newValue = eConfigHistoryEditPreview(
    historyData?.data?.key,
    historyData?.data?.new,
  )
  return (
    <Drawer
      // sx={{ height: '100vh', maxWidth: '550px' }}
      PaperProps={{
        sx: { maxWidth: '550px' },
      }}
      anchor={'right'}
      open={open}
      onClose={onClose}
    >
      <Box
        sx={{
          my: 2,
          ml: 3,
          mr: 1,
          justifyContent: 'space-between',
          alignItems: 'center',
          display: 'flex',
          width: 'auto',
          flexDirection: 'row',
          minWidth: '350px',
        }}
      >
        <Typography variant="h5">รายละเอียดการแก้ไข</Typography>
        <IconButton
          sx={{ p: 0 }}
          data-testid={`btn-close-drawer`}
          color="primary"
          component="span"
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      </Box>
      <Divider />

      <Box
        sx={{
          mx: theme.spacing(3),
          mt: theme.spacing(3),
        }}
      >
        <Box
          sx={{
            mb: 1.5,
            display: 'flex',
            flexDirection: 'row',
            [theme.breakpoints.down('sm')]: {
              flexDirection: 'column',
            },
          }}
        >
          <Typography sx={{ minWidth: 100 }} variant="">
            รายการ:
          </Typography>
          <Typography variant="body1">
            แก้ไขตั้งค่าหัวข้อ{' '}
            {historyData?.data?.key
              ? eConfigActionList[historyData.data.key]
              : ''}
          </Typography>
        </Box>
        <Box
          sx={{
            mb: 1.5,
            display: 'flex',
            flexDirection: 'row',
            [theme.breakpoints.down('sm')]: {
              flexDirection: 'column',
            },
          }}
        >
          <Typography sx={{ minWidth: 100 }} variant="">
            ข้อมูลเดิม:
          </Typography>
          <Typography variant="body1">
            <Box
              sx={{
                'white-space': 'normal',
              }}
              dangerouslySetInnerHTML={{ __html: oldValue }}
            />
          </Typography>
        </Box>
        <Box
          sx={{
            mb: 1.5,
            display: 'flex',
            flexDirection: 'row',
            [theme.breakpoints.down('sm')]: {
              flexDirection: 'column',
            },
          }}
        >
          <Typography sx={{ minWidth: 100 }} variant="">
            แก้ไขเป็น:
          </Typography>
          <Typography variant="body1">
            <Box
              sx={{
                'white-space': 'normal',
              }}
              dangerouslySetInnerHTML={{ __html: newValue }}
            />
          </Typography>
        </Box>
        <Box
          sx={{
            mb: 1.5,
            display: 'flex',
            flexDirection: 'row',
            [theme.breakpoints.down('sm')]: {
              flexDirection: 'column',
            },
          }}
        >
          <Typography sx={{ minWidth: 100 }} variant="">
            ผู้แก้ไข:
          </Typography>
          <Typography variant="body1">{historyData.author}</Typography>
        </Box>
        <Box
          sx={{
            mb: 1.5,
            display: 'flex',
            flexDirection: 'row',
            [theme.breakpoints.down('sm')]: {
              flexDirection: 'column',
            },
          }}
        >
          <Typography sx={{ minWidth: 100 }} variant="">
            วันที่แก้ไข:
          </Typography>
          <Typography variant="body1">
            {historyData.createdAt
              ? convertFormatDateTime({
                  value: historyData.createdAt,
                  type: 'dateTime',
                })
              : '-'}
          </Typography>
        </Box>
      </Box>
    </Drawer>
  )
}
export default DetailDrawyer
