import React, { useEffect, useState, useContext } from 'react'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import { DialogContext } from '../../../../../../../context/dialog-context'
import Table from '../../../../../../../components/Table'
import FilterDrawer from './FilterDrawer'
import {
  productWarehouseMovementFilter,
  productWarehouseMovementHistoryDownload,
} from '../../../../../../../utils/apiPath'
import { handleDownload } from '../../../../../../../utils/lib'
import callAxios from '../../../../../../../utils/baseService'
import { handleFilterTotal } from '../../../../../../../redux/slices/table/events'
import _ from 'lodash'
import { headCells } from './events'
import { Card } from '@mui/material'
import DropdownButton from '../../../components/DropdownButton'
import { store } from '../../../../../../../App'
import {
  PRODUCT_TYPE,
  ProductMovementType,
} from '../../../../../../../constants/stock'
import { convertFormatDateTime } from '../../../../../../../utils/lib'
import dayjs from 'dayjs'

export const TableHistory = ({
  data,
  headCells,
  setLimit,
  order,
  setOrder,
  page,
  setPage,
  sort,
  setSort,
  allCount,
  handleQuickSearch,
  onDownload,
  setIsFilterDrawer,
  searchText,
  setSearchText,
  tablePage,
  setTablePage,
  text,
  setText,
  filterTotal,
}) => {
  return (
    <Card>
      <Table
        data={data}
        headCells={headCells}
        placeholder="ค้นหาด้วย รายการ, จาก, ไป"
        searchKey={'title'}
        setLimit={setLimit}
        order={order}
        setOrder={setOrder}
        page={page}
        setPage={setPage}
        sort={sort}
        setSort={setSort}
        allCount={allCount}
        handleSearch={handleQuickSearch}
        onDownload={onDownload}
        setIsFilterDrawer={setIsFilterDrawer}
        searchText={searchText}
        setSearchText={setSearchText}
        tablePage={tablePage}
        setTablePage={setTablePage}
        text={text}
        setText={setText}
        filterTotal={filterTotal}
        onView={undefined}
        onEdit={undefined}
        onDelete={undefined}
      />
    </Card>
  )
}
const Index = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [limit, setLimit] = useState(100)
  const [order, setOrder] = useState('DESC')
  const [page, setPage] = useState(-1)
  const [sort, setSort] = useState('')
  const [dataList, setDataList] = useState([])
  const [allCount, setAllCount] = useState(0)
  const [searchText, setSearchText] = useState('')
  const [text, setText] = useState('')
  const [status, setStatus] = useState([])
  const [isFilterDrawer, setIsFilterDrawer] = useState(false)
  const [tablePage, setTablePage] = useState(0)
  const [filterTotal, setFilterTotal] = useState(0)
  const { setContext: setDialog } = useContext(DialogContext)
  const [filters, setFilters] = useState({})

  useEffect(() => {
    fetchDataList('fetch', status, limit, order, page, sort, '', filters)
  }, [limit, order, sort, page])

  const onFilterClick = async (filter) => {
    setSearchText('')
    setText('')
    const statusList = []
    let list = []
    if (filter.eventChecked) {
      if (filter.event.importChecked) list.push(ProductMovementType.IMPORT)
      if (filter.event.transferInChecked)
        list.push(ProductMovementType.TRANSFER_IN)
      if (filter.event.transferOutChecked)
        list.push(ProductMovementType.TRANSFER_OUT)
      if (filter.event.returnChecked) list.push(ProductMovementType.RETURN)
      if (filter.event.withdrawChecked)
        list.push(ProductMovementType.REQUISITION)
      if (filter.event.reduceChecked) list.push(ProductMovementType.REDUCE)
      if (filter.event.editChecked) list.push(ProductMovementType.EDITED)
      if (filter.event.inActiveChecked) list.push(ProductMovementType.INACTIVE)
      if (filter.event.activeChecked) list.push(ProductMovementType.ACTIVE)
      if (filter.event.deletedChecked) list.push(ProductMovementType.DELETED)
      if (filter.event.changeHolderChecked)
        list.push(ProductMovementType.CHANGE_HOLDER)
    }
    const filtersList = {
      startCreatedDate:
        filter?.dateChecked && dayjs(filter?.startDate).isValid()
          ? dayjs(filter?.startDate).format('YYYY-MM-DD')
          : '',
      endCreatedDate:
        filter?.dateChecked && dayjs(filter?.endDate).isValid()
          ? dayjs(filter?.endDate).format('YYYY-MM-DD')
          : '',
      startTime: filter?.dateChecked ? filter?.dateStartTime : '',
      endTime: filter?.dateChecked ? filter?.dateEndTime : '',
      qtyFrom: filter?.qtyChecked ? filter?.qtyFrom : '',
      qtyTo: filter?.qtyChecked ? filter?.qtyTo : '',
      stockValueFrom: filter?.stockValueChecked ? filter?.stockValueFrom : '',
      stockValueTo: filter?.stockValueChecked ? filter?.stockValueTo : '',
      totalFrom: filter?.totalChecked ? filter?.totalFrom : '',
      totalTo: filter?.totalChecked ? filter?.totalTo : '',
      from: filter?.fromChecked ? filter?.from : '',
      to: filter?.toChecked ? filter?.to : '',
      list: list,
    }
    setFilters(filtersList)
    setStatus(statusList)
    setTablePage(0)
    fetchDataList('filter', statusList, limit, order, 1, sort, '', filtersList)
    setFilterTotal(handleFilterTotal(filter))
  }

  const onDownload = async (selected) => {
    setIsLoading(true)
    const body = {
      order: order.toUpperCase(),
      sort: sort === '' ? 'createdAt' : sort,
      list: selected,
    }
    await handleDownload({
      url: productWarehouseMovementHistoryDownload,
      body: body,
      fileName: `ประวัติการเคลื่อนไหว รหัสทรัพย์สิน.xlsx`,
    })
    setIsLoading(false)
  }

  const handleQuickSearch = async (text) => {
    setTablePage(0)
    const statusList = []
    fetchDataList('search', statusList, limit, order, 1, sort, text, filters)
  }

  const fetchDataList = async (
    method,
    statusList,
    limit,
    order,
    page,
    sort,
    search,
    filters = [],
  ) => {
    const sortBy = sort === '' ? 'createdAt' : sort
    const realPage = page <= 0 ? 1 : +page
    setIsLoading(true)
    const body = {
      limit: limit,
      page: realPage,
      order: order.toString().toUpperCase(),
      sort: sortBy,
      status: statusList,
      productUuid: _.get(product, 'uuid', undefined),
      search: search ?? '',
      startCreatedDate: '',
      endCreatedDate: '',
      startUpdatedDate: '',
      endUpdatedDate: '',
      createdBy: '',
      updatedBy: '',
      skuWarehouseUuid: skuUuid,
      ...filters,
    }
    const isCancel = await callAxios
      .post(productWarehouseMovementFilter, body)
      .then((res) => {
        const data = res.data
        let result = _.get(data, 'result', [])
        result = result.map((item) => {
          return {
            ...item,
            movementType: _.get(item, 'movementType') ?? '-',
            remark: item?.remark ?? '-',
            createdAt: item?.createdAt
              ? convertFormatDateTime({
                  value: item?.createdAt,
                  type: 'dateTime',
                })
              : '-',
            dropdownAction: item?.ticket ? (
              <DropdownButton
                isHistory={true}
                ticketId={item?.ticket?.uuid}
                item={item}
              />
            ) : (
              ''
            ),
          }
        })
        setDataList(filterDataList(result))
        setAllCount(_.get(data, 'totalCount', 0))
      })
      .catch((e) => {
        console.log(e)
      })
    if (!isCancel) setIsLoading(false)
    setIsLoading(false)
  }

  let headCellsData = headCells
  const { formData, assetHistoryDrawerInfo } = store.getState().crud
  let skuUuid = _.get(assetHistoryDrawerInfo, 'uuid')
  let product = _.get(assetHistoryDrawerInfo, 'product', '')
  if (assetHistoryDrawerInfo?.productWarehouse) {
    product = _.get(assetHistoryDrawerInfo, 'productWarehouse.product', '')
  }
  const productType = _.get(formData, 'productType', PRODUCT_TYPE.STOCK)
  if ([PRODUCT_TYPE.ASSET].includes(productType)) {
    headCellsData = headCells.filter((item) => item.id !== 'total')
  }
  return (
    <Box sx={{ pb: 2 }}>
      <Box sx={isLoading && { opacity: 0.4, pointerEvents: 'none' }}>
        <TableHistory
          data={dataList}
          headCells={headCellsData}
          setLimit={setLimit}
          order={order}
          setOrder={setOrder}
          page={page}
          setPage={setPage}
          sort={sort}
          setSort={setSort}
          allCount={allCount}
          handleQuickSearch={handleQuickSearch}
          onDownload={onDownload}
          setIsFilterDrawer={setIsFilterDrawer}
          searchText={searchText}
          setSearchText={setSearchText}
          tablePage={tablePage}
          setTablePage={setTablePage}
          text={text}
          setText={setText}
          setIsLoading={setIsLoading}
          setDialog={setDialog}
          setDataList={setDataList}
          setAllCount={setAllCount}
          filterTotal={filterTotal}
        />
      </Box>
      <CircularProgress
        disableShrink
        sx={{
          position: 'absolute',
          display: isLoading ? 'unset' : 'none',
          top: '50%',
          left: '50%',
          color: 'primary.main',
        }}
      />
      <FilterDrawer
        open={isFilterDrawer}
        onClose={() => setIsFilterDrawer(false)}
        onFilterClick={onFilterClick}
      />
    </Box>
  )
}
export default Index

export const filterDataList = (list) => {
  if (!list.length) return []
  return list.map((item) => {
    return {
      ...item,
      levelOfLearner: _.get(item, 'levelOfLearner.title', '-'),
    }
  })
}
