import React from 'react'
import { resetForm } from '../../../../../redux/slices/manageClassLearner'
import { openDialog } from '../../../../../redux/slices/dialog'
import DeleteDialog from '../../../../../modules/ManageClass/Learner/DeleteDialog'
import _ from 'lodash'

export const handleIsCanDelete = (row) => {
  const status = _.get(row, 'status', '')
  const statusUser = _.get(row, 'userProfile.status', '')

  return (
    status != 'CANCEL' &&
    status != 'CANCELED' &&
    status != 'CANCELED_MA' &&
    statusUser != 'DELETED'
  )
}

export const handleDeleteDialog = (uuid) => (dispatch) => {
  dispatch(resetForm())
  dispatch(
    openDialog({
      title: 'ลบผู้เรียนออกจากรายการ',
      type: 'mini',
      maxWidth: 'form',
      disableButton: true,
      content: <DeleteDialog uuid={uuid} />,
    }),
  )
}
