import { fetchMasterDataOptions } from '../../components/Report/handler/fetchMasterDataOptions'
import { fetchOptionsReportByKey } from '../../components/Report/handler/fetchOptionsReportByKey'

export const reportMixModuleMlMcConst = {
  COLUMN_NAME: {
    NO: 'no',
    COURSE_CODE: 'courseCode',
    COURSE_NAME_EN: 'courseNameEN',
    COURSE_NAME_TH: 'courseNameTH',
    COURSE_NAME_LEARNER: 'courseNameForLearner',
    TRAINING_PLATFORM: 'trainingPlatform',
    EXPECT_NUMBER_PEOPLE: 'expectNumberPeople',
    START_CLASS_DATE: 'startClassDate',
    END_CLASS_DATE: 'endClassDate',
    TRAINING_DATE: 'traningDate',
    COUNT_STUDENT: 'countStudent',
    COUNT_SEAT_EMPTY: 'countSeatEmpty',
    COUNT_NOT_ATTENDANCE: 'countNotAttendance',
    COUNT_ATTENDANCE: 'countAttendance',
    COUNT_PASS: 'countPass',
    COUNT_NOT_PASS: 'countNotPass',
    HOURS: 'hours',
    STATUS_CLASS: 'statusClass',
    START_CLASS_DATE_TIME: 'startClassDateTime',
    END_CLASS_DATE_TIME: 'endClassDateTime',
    CLOSE_CLASS_DATE_TIME: 'closeClassDateTime',
    DIFF_CLASS_DATE_TIME: 'diffClassDateTime',
    STATION_NAME: 'station',
    STATION: 'station',
    ROOM_NAME: 'roomName',
    ROOM_NAME_OTHER: 'roomNameOther',
    EXPENSE_SUMMARY: 'expenseSummary',
    TRAINER_ID_1: 'trainerId1',
    TRAINER_ID_2: 'trainerId2',
    TRAINER_ID_3: 'trainerId3',
    TRAINER_ID_4: 'trainerId4',
    TRAINER_ID_5: 'trainerId5',
    TRAINER_ID_6: 'trainerId6',
    TRAINER_ID_7: 'trainerId7',
    TRAINER_ID_8: 'trainerId8',
    TRAINER_ID_9: 'trainerId9',
    TRAINER_ID_10: 'trainerId10',
    TRAINER_ID_11: 'trainerId11',
    TRAINER_ID_12: 'trainerId12',
    TRAINER_ID_13: 'trainerId13',
    TRAINER_ID_14: 'trainerId14',
    TRAINER_ID_15: 'trainerId15',
    TRAINER_ID_16: 'trainerId16',
    TRAINER_ID_17: 'trainerId17',
    TRAINER_ID_18: 'trainerId18',
    TRAINER_ID_19: 'trainerId19',
    TRAINER_ID_20: 'trainerId20',
    TRAINER_NAME_1: 'trainerName1',
    TRAINER_NAME_2: 'trainerName2',
    TRAINER_NAME_3: 'trainerName3',
    TRAINER_NAME_4: 'trainerName4',
    TRAINER_NAME_5: 'trainerName5',
    TRAINER_NAME_6: 'trainerName6',
    TRAINER_NAME_7: 'trainerName7',
    TRAINER_NAME_8: 'trainerName8',
    TRAINER_NAME_9: 'trainerName9',
    TRAINER_NAME_10: 'trainerName10',
    TRAINER_NAME_11: 'trainerName11',
    TRAINER_NAME_12: 'trainerName12',
    TRAINER_NAME_13: 'trainerName13',
    TRAINER_NAME_14: 'trainerName14',
    TRAINER_NAME_15: 'trainerName15',
    TRAINER_NAME_16: 'trainerName16',
    TRAINER_NAME_17: 'trainerName17',
    TRAINER_NAME_18: 'trainerName18',
    TRAINER_NAME_19: 'trainerName19',
    TRAINER_NAME_20: 'trainerName20',
    TRAINER_ROLE_1: 'trainerRole1',
    TRAINER_ROLE_2: 'trainerRole2',
    TRAINER_ROLE_3: 'trainerRole3',
    TRAINER_ROLE_4: 'trainerRole4',
    TRAINER_ROLE_5: 'trainerRole5',
    TRAINER_ROLE_6: 'trainerRole6',
    TRAINER_ROLE_7: 'trainerRole7',
    TRAINER_ROLE_8: 'trainerRole8',
    TRAINER_ROLE_9: 'trainerRole9',
    TRAINER_ROLE_10: 'trainerRole10',
    TRAINER_ROLE_11: 'trainerRole11',
    TRAINER_ROLE_12: 'trainerRole12',
    TRAINER_ROLE_13: 'trainerRole13',
    TRAINER_ROLE_14: 'trainerRole14',
    TRAINER_ROLE_15: 'trainerRole15',
    TRAINER_ROLE_16: 'trainerRole16',
    TRAINER_ROLE_17: 'trainerRole17',
    TRAINER_ROLE_18: 'trainerRole18',
    TRAINER_ROLE_19: 'trainerRole19',
    TRAINER_ROLE_20: 'trainerRole20',
    ADMIN_ID: 'adminId',
    ADMIN_NAME: 'adminName',
    ASSIGNEE_ID_1: 'assigneeId1',
    ASSIGNEE_ID_2: 'assigneeId2',
    ASSIGNEE_ID_3: 'assigneeId3',
    ASSIGNEE_ID_4: 'assigneeId4',
    ASSIGNEE_ID_5: 'assigneeId5',
    ASSIGNEE_ID_6: 'assigneeId6',
    ASSIGNEE_ID_7: 'assigneeId7',
    ASSIGNEE_ID_8: 'assigneeId8',
    ASSIGNEE_ID_9: 'assigneeId9',
    ASSIGNEE_ID_10: 'assigneeId10',
    ASSIGNEE_NAME_1: 'assigneeName1',
    ASSIGNEE_NAME_2: 'assigneeName2',
    ASSIGNEE_NAME_3: 'assigneeName3',
    ASSIGNEE_NAME_4: 'assigneeName4',
    ASSIGNEE_NAME_5: 'assigneeName5',
    ASSIGNEE_NAME_6: 'assigneeName6',
    ASSIGNEE_NAME_7: 'assigneeName7',
    ASSIGNEE_NAME_8: 'assigneeName8',
    ASSIGNEE_NAME_9: 'assigneeName9',
    ASSIGNEE_NAME_10: 'assigneeName10',
  },
  COLUMN_NAME_TH: {
    NO: 'ลำดับ',
    COURSE_CODE: 'รหัสหลักสูตร',
    COURSE_NAME_EN: 'ชื่อหลักสูตร EN',
    COURSE_NAME_TH: 'ชื่อหลักสูตร TH',
    COURSE_NAME_LEARNER: 'ชื่อหลักสูตรสำหรับ Learner',
    TRAINING_PLATFORM: 'รูปแบบการสอน',
    EXPECT_NUMBER_PEOPLE: 'จำนวนที่คาดหวัง',
    START_CLASS_DATE: 'Training Period',
    END_CLASS_DATE: 'Training Period End',
    TRAINING_DATE: 'Training Date',
    COUNT_STUDENT: 'จำนวนสมัคร',
    COUNT_SEAT_EMPTY: 'จำนวนที่ว่าง',
    COUNT_NOT_ATTENDANCE: 'จำนวนคนไม่เข้าอบรม',
    COUNT_ATTENDANCE: 'จำนวนผู้เข้าเรียน',
    COUNT_PASS: 'ผ่าน',
    COUNT_NOT_PASS: 'ไม่ผ่าน',
    HOURS: 'เวลาจัดสอนทั้งหมด',
    STATUS_CLASS: 'สถานะคลาส',
    START_CLASS_DATE_TIME: 'วันเวลาเริ่มคลาส',
    END_CLASS_DATE_TIME: 'วันเวลาสิ้นสุดคลาส',
    CLOSE_CLASS_DATE_TIME: 'วันเวลาปิดคลาส',
    DIFF_CLASS_DATE_TIME: 'วันเวลา Diff คลาส',
    // STATION_NAME: 'Station',
    STATION: 'Station',
    ROOM_NAME: 'ห้องประชุม',
    ROOM_NAME_OTHER: 'สถานที่ Other',
    EXPENSE_SUMMARY: 'Expense summary by class',
    TRAINER_ID_1: 'Trainer ID (1)',
    TRAINER_ID_2: 'Trainer ID (2)',
    TRAINER_ID_3: 'Trainer ID (3)',
    TRAINER_ID_4: 'Trainer ID (4)',
    TRAINER_ID_5: 'Trainer ID (5)',
    TRAINER_ID_6: 'Trainer ID (6)',
    TRAINER_ID_7: 'Trainer ID (7)',
    TRAINER_ID_8: 'Trainer ID (8)',
    TRAINER_ID_9: 'Trainer ID (9)',
    TRAINER_ID_10: 'Trainer ID (10)',
    TRAINER_ID_11: 'Trainer ID (11)',
    TRAINER_ID_12: 'Trainer ID (12)',
    TRAINER_ID_13: 'Trainer ID (13)',
    TRAINER_ID_14: 'Trainer ID (14)',
    TRAINER_ID_15: 'Trainer ID (15)',
    TRAINER_ID_16: 'Trainer ID (16)',
    TRAINER_ID_17: 'Trainer ID (17)',
    TRAINER_ID_18: 'Trainer ID (18)',
    TRAINER_ID_19: 'Trainer ID (19)',
    TRAINER_ID_20: 'Trainer ID (20)',
    TRAINER_NAME_1: 'Trainer Name (1)',
    TRAINER_NAME_2: 'Trainer Name (2)',
    TRAINER_NAME_3: 'Trainer Name (3)',
    TRAINER_NAME_4: 'Trainer Name (4)',
    TRAINER_NAME_5: 'Trainer Name (5)',
    TRAINER_NAME_6: 'Trainer Name (6)',
    TRAINER_NAME_7: 'Trainer Name (7)',
    TRAINER_NAME_8: 'Trainer Name (8)',
    TRAINER_NAME_9: 'Trainer Name (9)',
    TRAINER_NAME_10: 'Trainer Name (10)',
    TRAINER_NAME_11: 'Trainer Name (11)',
    TRAINER_NAME_12: 'Trainer Name (12)',
    TRAINER_NAME_13: 'Trainer Name (13)',
    TRAINER_NAME_14: 'Trainer Name (14)',
    TRAINER_NAME_15: 'Trainer Name (15)',
    TRAINER_NAME_16: 'Trainer Name (16)',
    TRAINER_NAME_17: 'Trainer Name (17)',
    TRAINER_NAME_18: 'Trainer Name (18)',
    TRAINER_NAME_19: 'Trainer Name (19)',
    TRAINER_NAME_20: 'Trainer Name (20)',
    TRAINER_ROLE_1: 'Trainer Role (1)',
    TRAINER_ROLE_2: 'Trainer Role (2)',
    TRAINER_ROLE_3: 'Trainer Role (3)',
    TRAINER_ROLE_4: 'Trainer Role (4)',
    TRAINER_ROLE_5: 'Trainer Role (5)',
    TRAINER_ROLE_6: 'Trainer Role (6)',
    TRAINER_ROLE_7: 'Trainer Role (7)',
    TRAINER_ROLE_8: 'Trainer Role (8)',
    TRAINER_ROLE_9: 'Trainer Role (9)',
    TRAINER_ROLE_10: 'Trainer Role (10)',
    TRAINER_ROLE_11: 'Trainer Role (11)',
    TRAINER_ROLE_12: 'Trainer Role (12)',
    TRAINER_ROLE_13: 'Trainer Role (13)',
    TRAINER_ROLE_14: 'Trainer Role (14)',
    TRAINER_ROLE_15: 'Trainer Role (15)',
    TRAINER_ROLE_16: 'Trainer Role (16)',
    TRAINER_ROLE_17: 'Trainer Role (17)',
    TRAINER_ROLE_18: 'Trainer Role (18)',
    TRAINER_ROLE_19: 'Trainer Role (19)',
    TRAINER_ROLE_20: 'Trainer Role (20)',
    ADMIN_ID: 'Admin หลัก ID',
    ADMIN_NAME: 'Admin หลัก Name',
    ASSIGNEE_ID_1: 'Admin รอง ID (1)',
    ASSIGNEE_ID_2: 'Admin รอง ID (2)',
    ASSIGNEE_ID_3: 'Admin รอง ID (3)',
    ASSIGNEE_ID_4: 'Admin รอง ID (4)',
    ASSIGNEE_ID_5: 'Admin รอง ID (5)',
    ASSIGNEE_ID_6: 'Admin รอง ID (6)',
    ASSIGNEE_ID_7: 'Admin รอง ID (7)',
    ASSIGNEE_ID_8: 'Admin รอง ID (8)',
    ASSIGNEE_ID_9: 'Admin รอง ID (9)',
    ASSIGNEE_ID_10: 'Admin รอง ID (10)',
    ASSIGNEE_NAME_1: 'Admin รอง Name (1)',
    ASSIGNEE_NAME_2: 'Admin รอง Name (2)',
    ASSIGNEE_NAME_3: 'Admin รอง Name (3)',
    ASSIGNEE_NAME_4: 'Admin รอง Name (4)',
    ASSIGNEE_NAME_5: 'Admin รอง Name (5)',
    ASSIGNEE_NAME_6: 'Admin รอง Name (6)',
    ASSIGNEE_NAME_7: 'Admin รอง Name (7)',
    ASSIGNEE_NAME_8: 'Admin รอง Name (8)',
    ASSIGNEE_NAME_9: 'Admin รอง Name (9)',
    ASSIGNEE_NAME_10: 'Admin รอง Name (10)',
  },
  TYPE_OF_VALUE: {
    DATE_RANGE: 'DATE_RANGE',
    INPUT_NUMBER: 'INPUT_NUMBER',
    INPUT_TEXT: 'INPUT_TEXT',
    DROPDOWN_MULTI: 'DROPDOWN_MULTI',
    DROPDOWN_SINGLE: 'DROPDOWN_SINGLE',
    INPUT_NUMBER_RANGE: 'INPUT_NUMBER_RANGE',
    INPUT_TEXT_BOX: 'INPUT_TEXT_BOX',
  },
  trainingPlatformOptions: [
    { label: 'Classroom', value: 'Classroom' },
    { label: 'Virtual Classroom', value: 'Virtual Classroom' },
    // { label: 'E-Learning', value: 'E-Learning' },
  ],
  statusOptions: [
    {
      label: 'Unassign',
      value: 'UNASSIGN',
    },
    {
      label: 'Todo',
      value: 'TODO',
    },
    {
      label: 'Open register',
      value: 'OPENREGISTRATION',
    },
    {
      label: 'Closed register',
      value: 'CLOSEREGISTRATION',
    },
    {
      label: 'In progress',
      value: 'INPROGRESS',
    },
    {
      label: 'Start class',
      value: 'STARTCLASS',
    },

    {
      label: 'End class',
      value: 'ENDCLASS',
    },

    {
      label: 'Closed class',
      value: 'CLOSED',
    },

    {
      label: 'Cancelled',
      value: 'CANCELED',
    },
  ],
}

export const reportMixModuleMlMcDefaultColumn = {
  defaultColumns: [
    {
      id: 1,
      name: reportMixModuleMlMcConst.COLUMN_NAME.NO,
      value: reportMixModuleMlMcConst.COLUMN_NAME.NO,
      label: reportMixModuleMlMcConst.COLUMN_NAME_TH.NO,
      minWidth: 60,
      align: 'center',
    },
    {
      id: 2,
      name: reportMixModuleMlMcConst.COLUMN_NAME.COURSE_CODE,
      value: reportMixModuleMlMcConst.COLUMN_NAME.COURSE_CODE,
      label: reportMixModuleMlMcConst.COLUMN_NAME_TH.COURSE_CODE,
      minWidth: 160,
    },
    {
      id: 3,
      name: reportMixModuleMlMcConst.COLUMN_NAME.COURSE_NAME_EN,
      value: reportMixModuleMlMcConst.COLUMN_NAME.COURSE_NAME_EN,
      label: reportMixModuleMlMcConst.COLUMN_NAME_TH.COURSE_NAME_EN,
      minWidth: 210,
    },
    {
      id: 4,
      name: reportMixModuleMlMcConst.COLUMN_NAME.COURSE_NAME_TH,
      value: reportMixModuleMlMcConst.COLUMN_NAME.COURSE_NAME_TH,
      label: reportMixModuleMlMcConst.COLUMN_NAME_TH.COURSE_NAME_TH,
      minWidth: 210,
    },
    {
      id: 5,
      name: reportMixModuleMlMcConst.COLUMN_NAME.COURSE_NAME_LEARNER,
      value: reportMixModuleMlMcConst.COLUMN_NAME.COURSE_NAME_LEARNER,
      label: reportMixModuleMlMcConst.COLUMN_NAME_TH.COURSE_NAME_LEARNER,
      minWidth: 210,
    },
    {
      id: 6,
      name: reportMixModuleMlMcConst.COLUMN_NAME.TRAINING_PLATFORM,
      value: reportMixModuleMlMcConst.COLUMN_NAME.TRAINING_PLATFORM,
      label: reportMixModuleMlMcConst.COLUMN_NAME_TH.TRAINING_PLATFORM,
      minWidth: 160,
    },
    {
      id: 7,
      name: reportMixModuleMlMcConst.COLUMN_NAME.EXPECT_NUMBER_PEOPLE,
      value: reportMixModuleMlMcConst.COLUMN_NAME.EXPECT_NUMBER_PEOPLE,
      label: reportMixModuleMlMcConst.COLUMN_NAME_TH.EXPECT_NUMBER_PEOPLE,
      minWidth: 160,
    },
    {
      id: 8,
      name: reportMixModuleMlMcConst.COLUMN_NAME.START_CLASS_DATE,
      value: reportMixModuleMlMcConst.COLUMN_NAME.START_CLASS_DATE,
      label: reportMixModuleMlMcConst.COLUMN_NAME_TH.START_CLASS_DATE,
      minWidth: 160,
    },
    {
      id: 9,
      name: reportMixModuleMlMcConst.COLUMN_NAME.TRAINING_DATE,
      value: reportMixModuleMlMcConst.COLUMN_NAME.TRAINING_DATE,
      label: reportMixModuleMlMcConst.COLUMN_NAME_TH.TRAINING_DATE,
      minWidth: 160,
    },
    {
      id: 10,
      name: reportMixModuleMlMcConst.COLUMN_NAME.COUNT_STUDENT,
      value: reportMixModuleMlMcConst.COLUMN_NAME.COUNT_STUDENT,
      label: reportMixModuleMlMcConst.COLUMN_NAME_TH.COUNT_STUDENT,
      minWidth: 160,
    },
    {
      id: 11,
      name: reportMixModuleMlMcConst.COLUMN_NAME.COUNT_SEAT_EMPTY,
      value: reportMixModuleMlMcConst.COLUMN_NAME.COUNT_SEAT_EMPTY,
      label: reportMixModuleMlMcConst.COLUMN_NAME_TH.COUNT_SEAT_EMPTY,
      minWidth: 160,
    },
    {
      id: 12,
      name: reportMixModuleMlMcConst.COLUMN_NAME.COUNT_NOT_ATTENDANCE,
      value: reportMixModuleMlMcConst.COLUMN_NAME.COUNT_NOT_ATTENDANCE,
      label: reportMixModuleMlMcConst.COLUMN_NAME_TH.COUNT_NOT_ATTENDANCE,
      minWidth: 160,
    },
    {
      id: 13,
      name: reportMixModuleMlMcConst.COLUMN_NAME.COUNT_ATTENDANCE,
      value: reportMixModuleMlMcConst.COLUMN_NAME.COUNT_ATTENDANCE,
      label: reportMixModuleMlMcConst.COLUMN_NAME_TH.COUNT_ATTENDANCE,
      minWidth: 160,
    },
    {
      id: 14,
      name: reportMixModuleMlMcConst.COLUMN_NAME.COUNT_PASS,
      value: reportMixModuleMlMcConst.COLUMN_NAME.COUNT_PASS,
      label: reportMixModuleMlMcConst.COLUMN_NAME_TH.COUNT_PASS,
      minWidth: 100,
    },
    {
      id: 15,
      name: reportMixModuleMlMcConst.COLUMN_NAME.COUNT_NOT_PASS,
      value: reportMixModuleMlMcConst.COLUMN_NAME.COUNT_NOT_PASS,
      label: reportMixModuleMlMcConst.COLUMN_NAME_TH.COUNT_NOT_PASS,
      minWidth: 100,
    },
    {
      id: 16,
      name: reportMixModuleMlMcConst.COLUMN_NAME.HOURS,
      value: reportMixModuleMlMcConst.COLUMN_NAME.HOURS,
      label: reportMixModuleMlMcConst.COLUMN_NAME_TH.HOURS,
      minWidth: 160,
    },
    {
      id: 17,
      name: reportMixModuleMlMcConst.COLUMN_NAME.STATUS_CLASS,
      value: reportMixModuleMlMcConst.COLUMN_NAME.STATUS_CLASS,
      label: reportMixModuleMlMcConst.COLUMN_NAME_TH.STATUS_CLASS,
      minWidth: 160,
    },
    {
      id: 18,
      name: reportMixModuleMlMcConst.COLUMN_NAME.START_CLASS_DATE_TIME,
      value: reportMixModuleMlMcConst.COLUMN_NAME.START_CLASS_DATE_TIME,
      label: reportMixModuleMlMcConst.COLUMN_NAME_TH.START_CLASS_DATE_TIME,
      minWidth: 160,
    },
    {
      id: 19,
      name: reportMixModuleMlMcConst.COLUMN_NAME.END_CLASS_DATE_TIME,
      value: reportMixModuleMlMcConst.COLUMN_NAME.END_CLASS_DATE_TIME,
      label: reportMixModuleMlMcConst.COLUMN_NAME_TH.END_CLASS_DATE_TIME,
      minWidth: 160,
    },
    {
      id: 20,
      name: reportMixModuleMlMcConst.COLUMN_NAME.CLOSE_CLASS_DATE_TIME,
      value: reportMixModuleMlMcConst.COLUMN_NAME.CLOSE_CLASS_DATE_TIME,
      label: reportMixModuleMlMcConst.COLUMN_NAME_TH.CLOSE_CLASS_DATE_TIME,
      minWidth: 160,
    },
    {
      id: 21,
      name: reportMixModuleMlMcConst.COLUMN_NAME.DIFF_CLASS_DATE_TIME,
      value: reportMixModuleMlMcConst.COLUMN_NAME.DIFF_CLASS_DATE_TIME,
      label: reportMixModuleMlMcConst.COLUMN_NAME_TH.DIFF_CLASS_DATE_TIME,
      minWidth: 160,
    },
    {
      id: 22,
      name: reportMixModuleMlMcConst.COLUMN_NAME.STATION,
      value: reportMixModuleMlMcConst.COLUMN_NAME.STATION,
      label: reportMixModuleMlMcConst.COLUMN_NAME_TH.STATION,
      minWidth: 160,
    },
    {
      id: 23,
      name: reportMixModuleMlMcConst.COLUMN_NAME.ROOM_NAME,
      value: reportMixModuleMlMcConst.COLUMN_NAME.ROOM_NAME,
      label: reportMixModuleMlMcConst.COLUMN_NAME_TH.ROOM_NAME,
      minWidth: 160,
    },
    {
      id: 24,
      name: reportMixModuleMlMcConst.COLUMN_NAME.ROOM_NAME_OTHER,
      value: reportMixModuleMlMcConst.COLUMN_NAME.ROOM_NAME_OTHER,
      label: reportMixModuleMlMcConst.COLUMN_NAME_TH.ROOM_NAME_OTHER,
      minWidth: 160,
    },
    {
      id: 25,
      name: reportMixModuleMlMcConst.COLUMN_NAME.EXPENSE_SUMMARY,
      value: reportMixModuleMlMcConst.COLUMN_NAME.EXPENSE_SUMMARY,
      label: reportMixModuleMlMcConst.COLUMN_NAME_TH.EXPENSE_SUMMARY,
      minWidth: 160,
    },
    {
      id: 26,
      name: reportMixModuleMlMcConst.COLUMN_NAME.TRAINER_ID_1,
      value: reportMixModuleMlMcConst.COLUMN_NAME.TRAINER_ID_1,
      label: reportMixModuleMlMcConst.COLUMN_NAME_TH.TRAINER_ID_1,
      minWidth: 160,
    },
    {
      id: 27,
      name: reportMixModuleMlMcConst.COLUMN_NAME.TRAINER_ID_2,
      value: reportMixModuleMlMcConst.COLUMN_NAME.TRAINER_ID_2,
      label: reportMixModuleMlMcConst.COLUMN_NAME_TH.TRAINER_ID_2,
      minWidth: 160,
    },
    {
      id: 28,
      name: reportMixModuleMlMcConst.COLUMN_NAME.TRAINER_ID_3,
      value: reportMixModuleMlMcConst.COLUMN_NAME.TRAINER_ID_3,
      label: reportMixModuleMlMcConst.COLUMN_NAME_TH.TRAINER_ID_3,
      minWidth: 160,
    },
    {
      id: 29,
      name: reportMixModuleMlMcConst.COLUMN_NAME.TRAINER_ID_4,
      value: reportMixModuleMlMcConst.COLUMN_NAME.TRAINER_ID_4,
      label: reportMixModuleMlMcConst.COLUMN_NAME_TH.TRAINER_ID_4,
      minWidth: 160,
    },
    {
      id: 30,
      name: reportMixModuleMlMcConst.COLUMN_NAME.TRAINER_ID_5,
      value: reportMixModuleMlMcConst.COLUMN_NAME.TRAINER_ID_5,
      label: reportMixModuleMlMcConst.COLUMN_NAME_TH.TRAINER_ID_5,
      minWidth: 160,
    },
    {
      id: 31,
      name: reportMixModuleMlMcConst.COLUMN_NAME.TRAINER_ID_6,
      value: reportMixModuleMlMcConst.COLUMN_NAME.TRAINER_ID_6,
      label: reportMixModuleMlMcConst.COLUMN_NAME_TH.TRAINER_ID_6,
      minWidth: 160,
    },
    {
      id: 32,
      name: reportMixModuleMlMcConst.COLUMN_NAME.TRAINER_ID_7,
      value: reportMixModuleMlMcConst.COLUMN_NAME.TRAINER_ID_7,
      label: reportMixModuleMlMcConst.COLUMN_NAME_TH.TRAINER_ID_7,
      minWidth: 160,
    },
    {
      id: 33,
      name: reportMixModuleMlMcConst.COLUMN_NAME.TRAINER_ID_8,
      value: reportMixModuleMlMcConst.COLUMN_NAME.TRAINER_ID_8,
      label: reportMixModuleMlMcConst.COLUMN_NAME_TH.TRAINER_ID_8,
      minWidth: 160,
    },
    {
      id: 34,
      name: reportMixModuleMlMcConst.COLUMN_NAME.TRAINER_ID_9,
      value: reportMixModuleMlMcConst.COLUMN_NAME.TRAINER_ID_9,
      label: reportMixModuleMlMcConst.COLUMN_NAME_TH.TRAINER_ID_9,
      minWidth: 160,
    },
    {
      id: 35,
      name: reportMixModuleMlMcConst.COLUMN_NAME.TRAINER_ID_10,
      value: reportMixModuleMlMcConst.COLUMN_NAME.TRAINER_ID_10,
      label: reportMixModuleMlMcConst.COLUMN_NAME_TH.TRAINER_ID_10,
      minWidth: 60,
      align: 'center',
    },
    {
      id: 36,
      name: reportMixModuleMlMcConst.COLUMN_NAME.TRAINER_ID_11,
      value: reportMixModuleMlMcConst.COLUMN_NAME.TRAINER_ID_11,
      label: reportMixModuleMlMcConst.COLUMN_NAME_TH.TRAINER_ID_11,
      minWidth: 160,
    },
    {
      id: 37,
      name: reportMixModuleMlMcConst.COLUMN_NAME.TRAINER_ID_12,
      value: reportMixModuleMlMcConst.COLUMN_NAME.TRAINER_ID_12,
      label: reportMixModuleMlMcConst.COLUMN_NAME_TH.TRAINER_ID_12,
      minWidth: 210,
    },
    {
      id: 38,
      name: reportMixModuleMlMcConst.COLUMN_NAME.TRAINER_ID_13,
      value: reportMixModuleMlMcConst.COLUMN_NAME.TRAINER_ID_13,
      label: reportMixModuleMlMcConst.COLUMN_NAME_TH.TRAINER_ID_13,
      minWidth: 160,
    },
    {
      id: 39,
      name: reportMixModuleMlMcConst.COLUMN_NAME.TRAINER_ID_14,
      value: reportMixModuleMlMcConst.COLUMN_NAME.TRAINER_ID_14,
      label: reportMixModuleMlMcConst.COLUMN_NAME_TH.TRAINER_ID_14,
      minWidth: 160,
    },
    {
      id: 40,
      name: reportMixModuleMlMcConst.COLUMN_NAME.TRAINER_ID_15,
      value: reportMixModuleMlMcConst.COLUMN_NAME.TRAINER_ID_15,
      label: reportMixModuleMlMcConst.COLUMN_NAME_TH.TRAINER_ID_15,
      minWidth: 160,
    },
    {
      id: 41,
      name: reportMixModuleMlMcConst.COLUMN_NAME.TRAINER_ID_16,
      value: reportMixModuleMlMcConst.COLUMN_NAME.TRAINER_ID_16,
      label: reportMixModuleMlMcConst.COLUMN_NAME_TH.TRAINER_ID_16,
      minWidth: 160,
    },
    {
      id: 42,
      name: reportMixModuleMlMcConst.COLUMN_NAME.TRAINER_ID_17,
      value: reportMixModuleMlMcConst.COLUMN_NAME.TRAINER_ID_17,
      label: reportMixModuleMlMcConst.COLUMN_NAME_TH.TRAINER_ID_17,
      minWidth: 160,
    },
    {
      id: 43,
      name: reportMixModuleMlMcConst.COLUMN_NAME.TRAINER_ID_18,
      value: reportMixModuleMlMcConst.COLUMN_NAME.TRAINER_ID_18,
      label: reportMixModuleMlMcConst.COLUMN_NAME_TH.TRAINER_ID_18,
      minWidth: 160,
    },
    {
      id: 44,
      name: reportMixModuleMlMcConst.COLUMN_NAME.TRAINER_ID_19,
      value: reportMixModuleMlMcConst.COLUMN_NAME.TRAINER_ID_19,
      label: reportMixModuleMlMcConst.COLUMN_NAME_TH.TRAINER_ID_19,
      minWidth: 160,
    },
    {
      id: 45,
      name: reportMixModuleMlMcConst.COLUMN_NAME.TRAINER_ID_20,
      value: reportMixModuleMlMcConst.COLUMN_NAME.TRAINER_ID_20,
      label: reportMixModuleMlMcConst.COLUMN_NAME_TH.TRAINER_ID_20,
      minWidth: 160,
    },
    {
      id: 46,
      name: reportMixModuleMlMcConst.COLUMN_NAME.TRAINER_NAME_1,
      value: reportMixModuleMlMcConst.COLUMN_NAME.TRAINER_NAME_1,
      label: reportMixModuleMlMcConst.COLUMN_NAME_TH.TRAINER_NAME_1,
      minWidth: 160,
    },
    {
      id: 47,
      name: reportMixModuleMlMcConst.COLUMN_NAME.TRAINER_NAME_2,
      value: reportMixModuleMlMcConst.COLUMN_NAME.TRAINER_NAME_2,
      label: reportMixModuleMlMcConst.COLUMN_NAME_TH.TRAINER_NAME_2,
      minWidth: 160,
    },
    {
      id: 48,
      name: reportMixModuleMlMcConst.COLUMN_NAME.TRAINER_NAME_3,
      value: reportMixModuleMlMcConst.COLUMN_NAME.TRAINER_NAME_3,
      label: reportMixModuleMlMcConst.COLUMN_NAME_TH.TRAINER_NAME_3,
      minWidth: 160,
    },
    {
      id: 49,
      name: reportMixModuleMlMcConst.COLUMN_NAME.TRAINER_NAME_4,
      value: reportMixModuleMlMcConst.COLUMN_NAME.TRAINER_NAME_4,
      label: reportMixModuleMlMcConst.COLUMN_NAME_TH.TRAINER_NAME_4,
      minWidth: 160,
    },
    {
      id: 50,
      name: reportMixModuleMlMcConst.COLUMN_NAME.TRAINER_NAME_5,
      value: reportMixModuleMlMcConst.COLUMN_NAME.TRAINER_NAME_5,
      label: reportMixModuleMlMcConst.COLUMN_NAME_TH.TRAINER_NAME_5,
      minWidth: 160,
    },
    {
      id: 51,
      name: reportMixModuleMlMcConst.COLUMN_NAME.TRAINER_NAME_6,
      value: reportMixModuleMlMcConst.COLUMN_NAME.TRAINER_NAME_6,
      label: reportMixModuleMlMcConst.COLUMN_NAME_TH.TRAINER_NAME_6,
      minWidth: 160,
    },
    {
      id: 52,
      name: reportMixModuleMlMcConst.COLUMN_NAME.TRAINER_NAME_7,
      value: reportMixModuleMlMcConst.COLUMN_NAME.TRAINER_NAME_7,
      label: reportMixModuleMlMcConst.COLUMN_NAME_TH.TRAINER_NAME_7,
      minWidth: 160,
    },
    {
      id: 53,
      name: reportMixModuleMlMcConst.COLUMN_NAME.TRAINER_NAME_8,
      value: reportMixModuleMlMcConst.COLUMN_NAME.TRAINER_NAME_8,
      label: reportMixModuleMlMcConst.COLUMN_NAME_TH.TRAINER_NAME_8,
      minWidth: 160,
    },
    {
      id: 54,
      name: reportMixModuleMlMcConst.COLUMN_NAME.TRAINER_NAME_9,
      value: reportMixModuleMlMcConst.COLUMN_NAME.TRAINER_NAME_9,
      label: reportMixModuleMlMcConst.COLUMN_NAME_TH.TRAINER_NAME_9,
      minWidth: 160,
    },
    {
      id: 55,
      name: reportMixModuleMlMcConst.COLUMN_NAME.TRAINER_NAME_10,
      value: reportMixModuleMlMcConst.COLUMN_NAME.TRAINER_NAME_10,
      label: reportMixModuleMlMcConst.COLUMN_NAME_TH.TRAINER_NAME_10,
      minWidth: 160,
    },
    {
      id: 56,
      name: reportMixModuleMlMcConst.COLUMN_NAME.TRAINER_NAME_11,
      value: reportMixModuleMlMcConst.COLUMN_NAME.TRAINER_NAME_11,
      label: reportMixModuleMlMcConst.COLUMN_NAME_TH.TRAINER_NAME_11,
      minWidth: 160,
    },
    {
      id: 57,
      name: reportMixModuleMlMcConst.COLUMN_NAME.TRAINER_NAME_12,
      value: reportMixModuleMlMcConst.COLUMN_NAME.TRAINER_NAME_12,
      label: reportMixModuleMlMcConst.COLUMN_NAME_TH.TRAINER_NAME_12,
      minWidth: 160,
    },
    {
      id: 58,
      name: reportMixModuleMlMcConst.COLUMN_NAME.TRAINER_NAME_13,
      value: reportMixModuleMlMcConst.COLUMN_NAME.TRAINER_NAME_13,
      label: reportMixModuleMlMcConst.COLUMN_NAME_TH.TRAINER_NAME_13,
      minWidth: 160,
    },
    {
      id: 59,
      name: reportMixModuleMlMcConst.COLUMN_NAME.TRAINER_NAME_14,
      value: reportMixModuleMlMcConst.COLUMN_NAME.TRAINER_NAME_14,
      label: reportMixModuleMlMcConst.COLUMN_NAME_TH.TRAINER_NAME_14,
      minWidth: 160,
    },
    {
      id: 60,
      name: reportMixModuleMlMcConst.COLUMN_NAME.TRAINER_NAME_15,
      value: reportMixModuleMlMcConst.COLUMN_NAME.TRAINER_NAME_15,
      label: reportMixModuleMlMcConst.COLUMN_NAME_TH.TRAINER_NAME_15,
      minWidth: 160,
    },
    {
      id: 61,
      name: reportMixModuleMlMcConst.COLUMN_NAME.TRAINER_NAME_16,
      value: reportMixModuleMlMcConst.COLUMN_NAME.TRAINER_NAME_16,
      label: reportMixModuleMlMcConst.COLUMN_NAME_TH.TRAINER_NAME_16,
      minWidth: 160,
    },
    {
      id: 62,
      name: reportMixModuleMlMcConst.COLUMN_NAME.TRAINER_NAME_17,
      value: reportMixModuleMlMcConst.COLUMN_NAME.TRAINER_NAME_17,
      label: reportMixModuleMlMcConst.COLUMN_NAME_TH.TRAINER_NAME_17,
      minWidth: 160,
    },
    {
      id: 63,
      name: reportMixModuleMlMcConst.COLUMN_NAME.TRAINER_NAME_18,
      value: reportMixModuleMlMcConst.COLUMN_NAME.TRAINER_NAME_18,
      label: reportMixModuleMlMcConst.COLUMN_NAME_TH.TRAINER_NAME_18,
      minWidth: 160,
    },
    {
      id: 64,
      name: reportMixModuleMlMcConst.COLUMN_NAME.TRAINER_NAME_19,
      value: reportMixModuleMlMcConst.COLUMN_NAME.TRAINER_NAME_19,
      label: reportMixModuleMlMcConst.COLUMN_NAME_TH.TRAINER_NAME_19,
      minWidth: 160,
    },
    {
      id: 65,
      name: reportMixModuleMlMcConst.COLUMN_NAME.TRAINER_NAME_20,
      value: reportMixModuleMlMcConst.COLUMN_NAME.TRAINER_NAME_20,
      label: reportMixModuleMlMcConst.COLUMN_NAME_TH.TRAINER_NAME_20,
      minWidth: 160,
    },
    {
      id: 66,
      name: reportMixModuleMlMcConst.COLUMN_NAME.TRAINER_ROLE_1,
      value: reportMixModuleMlMcConst.COLUMN_NAME.TRAINER_ROLE_1,
      label: reportMixModuleMlMcConst.COLUMN_NAME_TH.TRAINER_ROLE_1,
      minWidth: 160,
    },
    {
      id: 67,
      name: reportMixModuleMlMcConst.COLUMN_NAME.TRAINER_ROLE_2,
      value: reportMixModuleMlMcConst.COLUMN_NAME.TRAINER_ROLE_2,
      label: reportMixModuleMlMcConst.COLUMN_NAME_TH.TRAINER_ROLE_2,
      minWidth: 160,
    },
    {
      id: 68,
      name: reportMixModuleMlMcConst.COLUMN_NAME.TRAINER_ROLE_3,
      value: reportMixModuleMlMcConst.COLUMN_NAME.TRAINER_ROLE_3,
      label: reportMixModuleMlMcConst.COLUMN_NAME_TH.TRAINER_ROLE_3,
      minWidth: 160,
    },
    {
      id: 69,
      name: reportMixModuleMlMcConst.COLUMN_NAME.TRAINER_ROLE_4,
      value: reportMixModuleMlMcConst.COLUMN_NAME.TRAINER_ROLE_4,
      label: reportMixModuleMlMcConst.COLUMN_NAME_TH.TRAINER_ROLE_4,
      minWidth: 160,
    },
    {
      id: 70,
      name: reportMixModuleMlMcConst.COLUMN_NAME.TRAINER_ROLE_5,
      value: reportMixModuleMlMcConst.COLUMN_NAME.TRAINER_ROLE_5,
      label: reportMixModuleMlMcConst.COLUMN_NAME_TH.TRAINER_ROLE_5,
      minWidth: 60,
    },
    {
      id: 71,
      name: reportMixModuleMlMcConst.COLUMN_NAME.TRAINER_ROLE_6,
      value: reportMixModuleMlMcConst.COLUMN_NAME.TRAINER_ROLE_6,
      label: reportMixModuleMlMcConst.COLUMN_NAME_TH.TRAINER_ROLE_6,
      minWidth: 160,
    },
    {
      id: 72,
      name: reportMixModuleMlMcConst.COLUMN_NAME.TRAINER_ROLE_7,
      value: reportMixModuleMlMcConst.COLUMN_NAME.TRAINER_ROLE_7,
      label: reportMixModuleMlMcConst.COLUMN_NAME_TH.TRAINER_ROLE_7,
      minWidth: 210,
    },
    {
      id: 73,
      name: reportMixModuleMlMcConst.COLUMN_NAME.TRAINER_ROLE_8,
      value: reportMixModuleMlMcConst.COLUMN_NAME.TRAINER_ROLE_8,
      label: reportMixModuleMlMcConst.COLUMN_NAME_TH.TRAINER_ROLE_8,
      minWidth: 160,
    },
    {
      id: 74,
      name: reportMixModuleMlMcConst.COLUMN_NAME.TRAINER_ROLE_9,
      value: reportMixModuleMlMcConst.COLUMN_NAME.TRAINER_ROLE_9,
      label: reportMixModuleMlMcConst.COLUMN_NAME_TH.TRAINER_ROLE_9,
      minWidth: 160,
    },
    {
      id: 75,
      name: reportMixModuleMlMcConst.COLUMN_NAME.TRAINER_ROLE_10,
      value: reportMixModuleMlMcConst.COLUMN_NAME.TRAINER_ROLE_10,
      label: reportMixModuleMlMcConst.COLUMN_NAME_TH.TRAINER_ROLE_10,
      minWidth: 160,
    },
    {
      id: 76,
      name: reportMixModuleMlMcConst.COLUMN_NAME.TRAINER_ROLE_11,
      value: reportMixModuleMlMcConst.COLUMN_NAME.TRAINER_ROLE_11,
      label: reportMixModuleMlMcConst.COLUMN_NAME_TH.TRAINER_ROLE_11,
      minWidth: 160,
    },
    {
      id: 77,
      name: reportMixModuleMlMcConst.COLUMN_NAME.TRAINER_ROLE_12,
      value: reportMixModuleMlMcConst.COLUMN_NAME.TRAINER_ROLE_12,
      label: reportMixModuleMlMcConst.COLUMN_NAME_TH.TRAINER_ROLE_12,
      minWidth: 160,
    },
    {
      id: 78,
      name: reportMixModuleMlMcConst.COLUMN_NAME.TRAINER_ROLE_13,
      value: reportMixModuleMlMcConst.COLUMN_NAME.TRAINER_ROLE_13,
      label: reportMixModuleMlMcConst.COLUMN_NAME_TH.TRAINER_ROLE_13,
      minWidth: 160,
    },
    {
      id: 79,
      name: reportMixModuleMlMcConst.COLUMN_NAME.TRAINER_ROLE_14,
      value: reportMixModuleMlMcConst.COLUMN_NAME.TRAINER_ROLE_14,
      label: reportMixModuleMlMcConst.COLUMN_NAME_TH.TRAINER_ROLE_14,
      minWidth: 160,
    },
    {
      id: 80,
      name: reportMixModuleMlMcConst.COLUMN_NAME.TRAINER_ROLE_15,
      value: reportMixModuleMlMcConst.COLUMN_NAME.TRAINER_ROLE_15,
      label: reportMixModuleMlMcConst.COLUMN_NAME_TH.TRAINER_ROLE_15,
      minWidth: 160,
    },
    {
      id: 81,
      name: reportMixModuleMlMcConst.COLUMN_NAME.TRAINER_ROLE_16,
      value: reportMixModuleMlMcConst.COLUMN_NAME.TRAINER_ROLE_16,
      label: reportMixModuleMlMcConst.COLUMN_NAME_TH.TRAINER_ROLE_16,
      minWidth: 160,
    },
    {
      id: 82,
      name: reportMixModuleMlMcConst.COLUMN_NAME.TRAINER_ROLE_17,
      value: reportMixModuleMlMcConst.COLUMN_NAME.TRAINER_ROLE_17,
      label: reportMixModuleMlMcConst.COLUMN_NAME_TH.TRAINER_ROLE_17,
      minWidth: 160,
    },
    {
      id: 83,
      name: reportMixModuleMlMcConst.COLUMN_NAME.TRAINER_ROLE_18,
      value: reportMixModuleMlMcConst.COLUMN_NAME.TRAINER_ROLE_18,
      label: reportMixModuleMlMcConst.COLUMN_NAME_TH.TRAINER_ROLE_18,
      minWidth: 160,
    },
    {
      id: 84,
      name: reportMixModuleMlMcConst.COLUMN_NAME.TRAINER_ROLE_19,
      value: reportMixModuleMlMcConst.COLUMN_NAME.TRAINER_ROLE_19,
      label: reportMixModuleMlMcConst.COLUMN_NAME_TH.TRAINER_ROLE_19,
      minWidth: 160,
    },
    {
      id: 85,
      name: reportMixModuleMlMcConst.COLUMN_NAME.TRAINER_ROLE_20,
      value: reportMixModuleMlMcConst.COLUMN_NAME.TRAINER_ROLE_20,
      label: reportMixModuleMlMcConst.COLUMN_NAME_TH.TRAINER_ROLE_20,
      minWidth: 160,
    },
    {
      id: 86,
      name: reportMixModuleMlMcConst.COLUMN_NAME.ADMIN_ID,
      value: reportMixModuleMlMcConst.COLUMN_NAME.ADMIN_ID,
      label: reportMixModuleMlMcConst.COLUMN_NAME_TH.ADMIN_ID,
      minWidth: 160,
    },
    {
      id: 87,
      name: reportMixModuleMlMcConst.COLUMN_NAME.ADMIN_NAME,
      value: reportMixModuleMlMcConst.COLUMN_NAME.ADMIN_NAME,
      label: reportMixModuleMlMcConst.COLUMN_NAME_TH.ADMIN_NAME,
      minWidth: 160,
    },
    {
      id: 88,
      name: reportMixModuleMlMcConst.COLUMN_NAME.ASSIGNEE_ID_1,
      value: reportMixModuleMlMcConst.COLUMN_NAME.ASSIGNEE_ID_1,
      label: reportMixModuleMlMcConst.COLUMN_NAME_TH.ASSIGNEE_ID_1,
      minWidth: 160,
    },
    {
      id: 89,
      name: reportMixModuleMlMcConst.COLUMN_NAME.ASSIGNEE_ID_2,
      value: reportMixModuleMlMcConst.COLUMN_NAME.ASSIGNEE_ID_2,
      label: reportMixModuleMlMcConst.COLUMN_NAME_TH.ASSIGNEE_ID_2,
      minWidth: 160,
    },
    {
      id: 90,
      name: reportMixModuleMlMcConst.COLUMN_NAME.ASSIGNEE_ID_3,
      value: reportMixModuleMlMcConst.COLUMN_NAME.ASSIGNEE_ID_3,
      label: reportMixModuleMlMcConst.COLUMN_NAME_TH.ASSIGNEE_ID_3,
      minWidth: 160,
    },
    {
      id: 91,
      name: reportMixModuleMlMcConst.COLUMN_NAME.ASSIGNEE_ID_4,
      value: reportMixModuleMlMcConst.COLUMN_NAME.ASSIGNEE_ID_4,
      label: reportMixModuleMlMcConst.COLUMN_NAME_TH.ASSIGNEE_ID_4,
      minWidth: 160,
    },
    {
      id: 92,
      name: reportMixModuleMlMcConst.COLUMN_NAME.ASSIGNEE_ID_5,
      value: reportMixModuleMlMcConst.COLUMN_NAME.ASSIGNEE_ID_5,
      label: reportMixModuleMlMcConst.COLUMN_NAME_TH.ASSIGNEE_ID_5,
      minWidth: 160,
    },
    {
      id: 93,
      name: reportMixModuleMlMcConst.COLUMN_NAME.ASSIGNEE_ID_6,
      value: reportMixModuleMlMcConst.COLUMN_NAME.ASSIGNEE_ID_6,
      label: reportMixModuleMlMcConst.COLUMN_NAME_TH.ASSIGNEE_ID_6,
      minWidth: 160,
    },
    {
      id: 94,
      name: reportMixModuleMlMcConst.COLUMN_NAME.ASSIGNEE_ID_7,
      value: reportMixModuleMlMcConst.COLUMN_NAME.ASSIGNEE_ID_7,
      label: reportMixModuleMlMcConst.COLUMN_NAME_TH.ASSIGNEE_ID_7,
      minWidth: 160,
    },
    {
      id: 95,
      name: reportMixModuleMlMcConst.COLUMN_NAME.ASSIGNEE_ID_8,
      value: reportMixModuleMlMcConst.COLUMN_NAME.ASSIGNEE_ID_8,
      label: reportMixModuleMlMcConst.COLUMN_NAME_TH.ASSIGNEE_ID_8,
      minWidth: 160,
    },
    {
      id: 96,
      name: reportMixModuleMlMcConst.COLUMN_NAME.ASSIGNEE_ID_9,
      value: reportMixModuleMlMcConst.COLUMN_NAME.ASSIGNEE_ID_9,
      label: reportMixModuleMlMcConst.COLUMN_NAME_TH.ASSIGNEE_ID_9,
      minWidth: 160,
    },
    {
      id: 97,
      name: reportMixModuleMlMcConst.COLUMN_NAME.ASSIGNEE_ID_10,
      value: reportMixModuleMlMcConst.COLUMN_NAME.ASSIGNEE_ID_10,
      label: reportMixModuleMlMcConst.COLUMN_NAME_TH.ASSIGNEE_ID_10,
      minWidth: 160,
    },
    {
      id: 98,
      name: reportMixModuleMlMcConst.COLUMN_NAME.ASSIGNEE_NAME_1,
      value: reportMixModuleMlMcConst.COLUMN_NAME.ASSIGNEE_NAME_1,
      label: reportMixModuleMlMcConst.COLUMN_NAME_TH.ASSIGNEE_NAME_1,
      minWidth: 160,
    },
    {
      id: 99,
      name: reportMixModuleMlMcConst.COLUMN_NAME.ASSIGNEE_NAME_2,
      value: reportMixModuleMlMcConst.COLUMN_NAME.ASSIGNEE_NAME_2,
      label: reportMixModuleMlMcConst.COLUMN_NAME_TH.ASSIGNEE_NAME_2,
      minWidth: 160,
    },
    {
      id: 100,
      name: reportMixModuleMlMcConst.COLUMN_NAME.ASSIGNEE_NAME_3,
      value: reportMixModuleMlMcConst.COLUMN_NAME.ASSIGNEE_NAME_3,
      label: reportMixModuleMlMcConst.COLUMN_NAME_TH.ASSIGNEE_NAME_3,
      minWidth: 160,
    },
    {
      id: 101,
      name: reportMixModuleMlMcConst.COLUMN_NAME.ASSIGNEE_NAME_4,
      value: reportMixModuleMlMcConst.COLUMN_NAME.ASSIGNEE_NAME_4,
      label: reportMixModuleMlMcConst.COLUMN_NAME_TH.ASSIGNEE_NAME_4,
      minWidth: 160,
    },
    {
      id: 102,
      name: reportMixModuleMlMcConst.COLUMN_NAME.ASSIGNEE_NAME_5,
      value: reportMixModuleMlMcConst.COLUMN_NAME.ASSIGNEE_NAME_5,
      label: reportMixModuleMlMcConst.COLUMN_NAME_TH.ASSIGNEE_NAME_5,
      minWidth: 160,
    },
    {
      id: 103,
      name: reportMixModuleMlMcConst.COLUMN_NAME.ASSIGNEE_NAME_6,
      value: reportMixModuleMlMcConst.COLUMN_NAME.ASSIGNEE_NAME_6,
      label: reportMixModuleMlMcConst.COLUMN_NAME_TH.ASSIGNEE_NAME_6,
      minWidth: 160,
    },
    {
      id: 104,
      name: reportMixModuleMlMcConst.COLUMN_NAME.ASSIGNEE_NAME_7,
      value: reportMixModuleMlMcConst.COLUMN_NAME.ASSIGNEE_NAME_7,
      label: reportMixModuleMlMcConst.COLUMN_NAME_TH.ASSIGNEE_NAME_7,
      minWidth: 160,
    },
    {
      id: 105,
      name: reportMixModuleMlMcConst.COLUMN_NAME.ASSIGNEE_NAME_8,
      value: reportMixModuleMlMcConst.COLUMN_NAME.ASSIGNEE_NAME_8,
      label: reportMixModuleMlMcConst.COLUMN_NAME_TH.ASSIGNEE_NAME_8,
      minWidth: 160,
    },
    {
      id: 106,
      name: reportMixModuleMlMcConst.COLUMN_NAME.ASSIGNEE_NAME_9,
      value: reportMixModuleMlMcConst.COLUMN_NAME.ASSIGNEE_NAME_9,
      label: reportMixModuleMlMcConst.COLUMN_NAME_TH.ASSIGNEE_NAME_9,
      minWidth: 160,
    },
    {
      id: 107,
      name: reportMixModuleMlMcConst.COLUMN_NAME.ASSIGNEE_NAME_10,
      value: reportMixModuleMlMcConst.COLUMN_NAME.ASSIGNEE_NAME_10,
      label: reportMixModuleMlMcConst.COLUMN_NAME_TH.ASSIGNEE_NAME_10,
      minWidth: 160,
    },
  ],
  filterColumnOptions: [
    {
      id: 3,
      name: reportMixModuleMlMcConst.COLUMN_NAME.COURSE_CODE,
      value: reportMixModuleMlMcConst.COLUMN_NAME.COURSE_CODE,
      label: reportMixModuleMlMcConst.COLUMN_NAME_TH.COURSE_CODE,
      minWidth: 160,
      type: reportMixModuleMlMcConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 4,
      name: reportMixModuleMlMcConst.COLUMN_NAME.COURSE_NAME_EN,
      value: reportMixModuleMlMcConst.COLUMN_NAME.COURSE_NAME_EN,
      label: reportMixModuleMlMcConst.COLUMN_NAME_TH.COURSE_NAME_EN,
      minWidth: 160,
      type: reportMixModuleMlMcConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 4,
      name: reportMixModuleMlMcConst.COLUMN_NAME.COURSE_NAME_TH,
      value: reportMixModuleMlMcConst.COLUMN_NAME.COURSE_NAME_TH,
      label: reportMixModuleMlMcConst.COLUMN_NAME_TH.COURSE_NAME_TH,
      minWidth: 160,
      type: reportMixModuleMlMcConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 4,
      name: reportMixModuleMlMcConst.COLUMN_NAME.COURSE_NAME_LEARNER,
      value: reportMixModuleMlMcConst.COLUMN_NAME.COURSE_NAME_LEARNER,
      label: reportMixModuleMlMcConst.COLUMN_NAME_TH.COURSE_NAME_LEARNER,
      minWidth: 160,
      type: reportMixModuleMlMcConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 4,
      name: reportMixModuleMlMcConst.COLUMN_NAME.TRAINING_PLATFORM,
      value: reportMixModuleMlMcConst.COLUMN_NAME.TRAINING_PLATFORM,
      label: reportMixModuleMlMcConst.COLUMN_NAME_TH.TRAINING_PLATFORM,
      minWidth: 160,
      type: reportMixModuleMlMcConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      // masterDataName: reportMixModuleMlMcConst.COLUMN_NAME.TRAINING_PLATFORM,
      // fetchOptions: () => fetchMasterDataOptions(),
      options: reportMixModuleMlMcConst.trainingPlatformOptions,
    },
    {
      id: 4,
      name: reportMixModuleMlMcConst.COLUMN_NAME.EXPECT_NUMBER_PEOPLE,
      value: reportMixModuleMlMcConst.COLUMN_NAME.EXPECT_NUMBER_PEOPLE,
      label: reportMixModuleMlMcConst.COLUMN_NAME_TH.EXPECT_NUMBER_PEOPLE,
      minWidth: 160,
      type: reportMixModuleMlMcConst.TYPE_OF_VALUE.INPUT_NUMBER,
    },
    {
      id: 4,
      name: reportMixModuleMlMcConst.COLUMN_NAME.TRAINING_DATE,
      value: reportMixModuleMlMcConst.COLUMN_NAME.TRAINING_DATE,
      label: reportMixModuleMlMcConst.COLUMN_NAME_TH.TRAINING_DATE,
      minWidth: 160,
      type: reportMixModuleMlMcConst.TYPE_OF_VALUE.DATE_RANGE,
    },
    {
      id: 4,
      name: reportMixModuleMlMcConst.COLUMN_NAME.COUNT_STUDENT,
      value: reportMixModuleMlMcConst.COLUMN_NAME.COUNT_STUDENT,
      label: reportMixModuleMlMcConst.COLUMN_NAME_TH.COUNT_STUDENT,
      minWidth: 160,
      type: reportMixModuleMlMcConst.TYPE_OF_VALUE.INPUT_NUMBER,
    },
    {
      id: 4,
      name: reportMixModuleMlMcConst.COLUMN_NAME.COUNT_SEAT_EMPTY,
      value: reportMixModuleMlMcConst.COLUMN_NAME.COUNT_SEAT_EMPTY,
      label: reportMixModuleMlMcConst.COLUMN_NAME_TH.COUNT_SEAT_EMPTY,
      minWidth: 160,
      type: reportMixModuleMlMcConst.TYPE_OF_VALUE.INPUT_NUMBER,
    },
    {
      id: 4,
      name: reportMixModuleMlMcConst.COLUMN_NAME.COUNT_NOT_ATTENDANCE,
      value: reportMixModuleMlMcConst.COLUMN_NAME.COUNT_NOT_ATTENDANCE,
      label: reportMixModuleMlMcConst.COLUMN_NAME_TH.COUNT_NOT_ATTENDANCE,
      minWidth: 160,
      type: reportMixModuleMlMcConst.TYPE_OF_VALUE.INPUT_NUMBER,
    },
    {
      id: 4,
      name: reportMixModuleMlMcConst.COLUMN_NAME.COUNT_ATTENDANCE,
      value: reportMixModuleMlMcConst.COLUMN_NAME.COUNT_ATTENDANCE,
      label: reportMixModuleMlMcConst.COLUMN_NAME_TH.COUNT_ATTENDANCE,
      minWidth: 160,
      type: reportMixModuleMlMcConst.TYPE_OF_VALUE.INPUT_NUMBER,
    },
    {
      id: 4,
      name: reportMixModuleMlMcConst.COLUMN_NAME.COUNT_PASS,
      value: reportMixModuleMlMcConst.COLUMN_NAME.COUNT_PASS,
      label: reportMixModuleMlMcConst.COLUMN_NAME_TH.COUNT_PASS,
      minWidth: 160,
      type: reportMixModuleMlMcConst.TYPE_OF_VALUE.INPUT_NUMBER,
    },
    {
      id: 4,
      name: reportMixModuleMlMcConst.COLUMN_NAME.COUNT_NOT_PASS,
      value: reportMixModuleMlMcConst.COLUMN_NAME.COUNT_NOT_PASS,
      label: reportMixModuleMlMcConst.COLUMN_NAME_TH.COUNT_NOT_PASS,
      minWidth: 160,
      type: reportMixModuleMlMcConst.TYPE_OF_VALUE.INPUT_NUMBER,
    },
    {
      id: 4,
      name: reportMixModuleMlMcConst.COLUMN_NAME.HOURS,
      value: reportMixModuleMlMcConst.COLUMN_NAME.HOURS,
      label: reportMixModuleMlMcConst.COLUMN_NAME_TH.HOURS,
      minWidth: 160,
      type: reportMixModuleMlMcConst.TYPE_OF_VALUE.INPUT_NUMBER,
    },
    {
      id: 4,
      name: reportMixModuleMlMcConst.COLUMN_NAME.STATUS_CLASS,
      value: reportMixModuleMlMcConst.COLUMN_NAME.STATUS_CLASS,
      label: reportMixModuleMlMcConst.COLUMN_NAME_TH.STATUS_CLASS,
      minWidth: 160,
      type: reportMixModuleMlMcConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      options: reportMixModuleMlMcConst.statusOptions,
    },
    {
      id: 4,
      name: reportMixModuleMlMcConst.COLUMN_NAME.START_CLASS_DATE_TIME,
      value: reportMixModuleMlMcConst.COLUMN_NAME.START_CLASS_DATE_TIME,
      label: reportMixModuleMlMcConst.COLUMN_NAME_TH.START_CLASS_DATE_TIME,
      minWidth: 160,
      type: reportMixModuleMlMcConst.TYPE_OF_VALUE.DATE_RANGE,
    },
    {
      id: 4,
      name: reportMixModuleMlMcConst.COLUMN_NAME.END_CLASS_DATE_TIME,
      value: reportMixModuleMlMcConst.COLUMN_NAME.END_CLASS_DATE_TIME,
      label: reportMixModuleMlMcConst.COLUMN_NAME_TH.END_CLASS_DATE_TIME,
      minWidth: 160,
      type: reportMixModuleMlMcConst.TYPE_OF_VALUE.DATE_RANGE,
    },
    {
      id: 4,
      name: reportMixModuleMlMcConst.COLUMN_NAME.CLOSE_CLASS_DATE_TIME,
      value: reportMixModuleMlMcConst.COLUMN_NAME.CLOSE_CLASS_DATE_TIME,
      label: reportMixModuleMlMcConst.COLUMN_NAME_TH.CLOSE_CLASS_DATE_TIME,
      minWidth: 160,
      type: reportMixModuleMlMcConst.TYPE_OF_VALUE.DATE_RANGE,
    },
    {
      id: 4,
      name: reportMixModuleMlMcConst.COLUMN_NAME.DIFF_CLASS_DATE_TIME,
      value: reportMixModuleMlMcConst.COLUMN_NAME.DIFF_CLASS_DATE_TIME,
      label: reportMixModuleMlMcConst.COLUMN_NAME_TH.DIFF_CLASS_DATE_TIME,
      minWidth: 160,
      type: reportMixModuleMlMcConst.TYPE_OF_VALUE.INPUT_TEXT_BOX,
    },
    {
      id: 16,
      name: reportMixModuleMlMcConst.COLUMN_NAME.STATION_NAME,
      value: reportMixModuleMlMcConst.COLUMN_NAME.STATION,
      label: reportMixModuleMlMcConst.COLUMN_NAME_TH.STATION,
      minWidth: 160,
      type: reportMixModuleMlMcConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      masterDataName: reportMixModuleMlMcConst.COLUMN_NAME.STATION,
      fetchOptions: () => fetchMasterDataOptions(),
    },
    {
      id: 16,
      name: reportMixModuleMlMcConst.COLUMN_NAME.ROOM_NAME,
      value: reportMixModuleMlMcConst.COLUMN_NAME.ROOM_NAME,
      label: reportMixModuleMlMcConst.COLUMN_NAME_TH.ROOM_NAME,
      minWidth: 160,
      type: reportMixModuleMlMcConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 16,
      name: reportMixModuleMlMcConst.COLUMN_NAME.ROOM_NAME_OTHER,
      value: reportMixModuleMlMcConst.COLUMN_NAME.ROOM_NAME_OTHER,
      label: reportMixModuleMlMcConst.COLUMN_NAME_TH.ROOM_NAME_OTHER,
      minWidth: 160,
      type: reportMixModuleMlMcConst.TYPE_OF_VALUE.INPUT_TEXT,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 16,
      name: reportMixModuleMlMcConst.COLUMN_NAME.EXPENSE_SUMMARY,
      value: reportMixModuleMlMcConst.COLUMN_NAME.EXPENSE_SUMMARY,
      label: reportMixModuleMlMcConst.COLUMN_NAME_TH.EXPENSE_SUMMARY,
      minWidth: 160,
      type: reportMixModuleMlMcConst.TYPE_OF_VALUE.INPUT_NUMBER_RANGE,
    },
    {
      id: 16,
      name: reportMixModuleMlMcConst.COLUMN_NAME.TRAINER_ID_1,
      value: reportMixModuleMlMcConst.COLUMN_NAME.TRAINER_ID_1,
      label: reportMixModuleMlMcConst.COLUMN_NAME_TH.TRAINER_ID_1,
      minWidth: 160,
      type: reportMixModuleMlMcConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 16,
      name: reportMixModuleMlMcConst.COLUMN_NAME.TRAINER_NAME_1,
      value: reportMixModuleMlMcConst.COLUMN_NAME.TRAINER_NAME_1,
      label: reportMixModuleMlMcConst.COLUMN_NAME_TH.TRAINER_NAME_1,
      minWidth: 160,
      type: reportMixModuleMlMcConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 16,
      name: reportMixModuleMlMcConst.COLUMN_NAME.TRAINER_ROLE_1,
      value: reportMixModuleMlMcConst.COLUMN_NAME.TRAINER_ROLE_1,
      label: reportMixModuleMlMcConst.COLUMN_NAME_TH.TRAINER_ROLE_1,
      minWidth: 160,
      type: reportMixModuleMlMcConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 16,
      name: reportMixModuleMlMcConst.COLUMN_NAME.ADMIN_ID,
      value: reportMixModuleMlMcConst.COLUMN_NAME.ADMIN_ID,
      label: reportMixModuleMlMcConst.COLUMN_NAME_TH.ADMIN_ID,
      minWidth: 160,
      type: reportMixModuleMlMcConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 16,
      name: reportMixModuleMlMcConst.COLUMN_NAME.ADMIN_NAME,
      value: reportMixModuleMlMcConst.COLUMN_NAME.ADMIN_NAME,
      label: reportMixModuleMlMcConst.COLUMN_NAME_TH.ADMIN_NAME,
      minWidth: 160,
      type: reportMixModuleMlMcConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 16,
      name: reportMixModuleMlMcConst.COLUMN_NAME.ASSIGNEE_ID_1,
      value: reportMixModuleMlMcConst.COLUMN_NAME.ASSIGNEE_ID_1,
      label: reportMixModuleMlMcConst.COLUMN_NAME_TH.ASSIGNEE_ID_1,
      minWidth: 160,
      type: reportMixModuleMlMcConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
    {
      id: 16,
      name: reportMixModuleMlMcConst.COLUMN_NAME.ASSIGNEE_NAME_1,
      value: reportMixModuleMlMcConst.COLUMN_NAME.ASSIGNEE_NAME_1,
      label: reportMixModuleMlMcConst.COLUMN_NAME_TH.ASSIGNEE_NAME_1,
      minWidth: 160,
      type: reportMixModuleMlMcConst.TYPE_OF_VALUE.DROPDOWN_MULTI,
      fetchOptions: () => fetchOptionsReportByKey(),
    },
  ],
}
