import React, { useState } from 'react'
import { Box, IconButton, Typography } from '@mui/material'
import { ExpandMore, ExpandLess } from '@mui/icons-material'
import { StyledType } from './Styled'
import TruncatedText from '../../../../../modules/MixModule/Dashboard/components/TruncatedText'

export const ToggleContent = ({
  title,
  type,
  contentId,
  alwaysOpen,
  hideIcon,
  children,
  BoxSx = {},
  isTruncated = false,
  isPrinting = false,
  alignItems = 'center',
}) => {
  const [isOpen, setIsOpen] = useState(false)

  const handleToggle = () => {
    setIsOpen((prev) => !prev)
  }

  return (
    <Box sx={{ ...BoxSx }}>
      <Box
        display="flex"
        justifyContent="start"
        alignItems={alignItems}
        sx={{ padding: '8px' }}
        gap={2}
      >
        {isTruncated ? (
          <>
            <TruncatedText
              text={title}
              readMoreTxt={'ดูเพิ่มเติม'}
              maxWidth={'72vw'}
              titleVariant={'h6'}
              maxLines={3}
              isPrinting={isPrinting}
              dialogTitle={'คำถาม'}
            />
          </>
        ) : (
          <>
            <Typography variant="body1b">{title}</Typography>
          </>
        )}
        {type && <StyledType>{type}</StyledType>}
        {!hideIcon && (
          <IconButton
            size="small"
            onClick={handleToggle}
            sx={{
              cursor: alwaysOpen ? '' : 'pointer',
              background: '#4976BA14',
            }}
          >
            {isOpen ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        )}
      </Box>
      {(isOpen || alwaysOpen) && (
        <Box id={contentId} padding="8px">
          {children}
        </Box>
      )}
    </Box>
  )
}
