import { Box } from '@mui/material'
import { CustomDropdown } from '../../../../../components/CRUD/components/CustomDropdown'
import { ContentRow } from './ContentRow'
import { LabelWithValue } from './LabelWithValue'

import { AmountList } from './AmountList'

export const Content = () => {
  return (
    <Box display="flex" flexDirection="column" p={3} gap={3}>
      <ContentRow
        columnLeft={
          <CustomDropdown
            fieldName="staffProfile"
            labelName="Trainer/Staff"
            placeholder="เลือก Trainer/Staff"
          />
        }
        columnRight={
          <CustomDropdown
            fieldName="staffRole"
            labelName="Role"
            placeholder="เลือก Role"
          />
        }
      />
      <ContentRow
        columnLeft={
          <CustomDropdown
            required
            fieldName="costCenter"
            labelName="Cost Center"
            placeholder="เลือก Cost Center"
          />
        }
        columnRight={
          <LabelWithValue
            label="Cost Center Name"
            fieldName="costCenter.descriptionName"
          />
        }
      />
      <AmountList />
    </Box>
  )
}
