import { store } from '../../../../App'
import { openDialog } from '../../../../redux/slices/dialog'
import { setFieldValue } from '../../../../redux/slices/crud'
import {
  agencyDrawerEnum,
  filtersDrawerEnum,
  overviewEnum,
} from '../enums/drawerEnum'
import { checkUpdateStatus } from './checkUpdateStatus'
import { fetchOverview } from './fetchOverview'

export const initialize = async () => {
  await checkUpdateStatus()
  const { updateStatus } = store.getState().crud.manageProfileDashboard
  if (updateStatus === 'SUCCESS') {
    store.dispatch(
      setFieldValue({
        key: 'manageProfileDashboard.filtersDrawer',
        value: filtersDrawerEnum,
      }),
    )
    store.dispatch(
      setFieldValue({
        key: 'manageProfileDashboard.overview',
        value: overviewEnum,
      }),
    )
    store.dispatch(
      setFieldValue({
        key: 'manageProfileDashboard.agencyDrawer',
        value: agencyDrawerEnum,
      }),
    )
    await fetchOverview()
  } else if (updateStatus === 'INPROGRESS') {
    store.dispatch(
      setFieldValue({
        key: 'isLoading',
        value: false,
      }),
    )
    store.dispatch(
      openDialog({
        type: 'confirm',
        title: 'กำลังดำเนินการ',
        message: 'ระบบกำลังอัปเดตข้อมูล กรุณารอสักครู่',
        hideCancel: true,
        handleConfirm: () => window.location.reload(),
        handleCancel: () => window.location.reload(),
      }),
    )
  } else if (updateStatus === 'FAILED') {
    store.dispatch(
      setFieldValue({
        key: 'isLoading',
        value: false,
      }),
    )
    store.dispatch(
      openDialog({
        type: 'fail',
        title: 'ระบบอัปเดตข้อมูลผิดพลาด',
        message: 'กรุณาอัปเดตข้อมูลอีกครั้ง',
        hideCancel: true,
        handleConfirm: () => window.location.reload(),
        handleCancel: () => window.location.reload(),
      }),
    )
  }
}
