import axios from 'axios'
import dayjs from 'dayjs'
import _ from 'lodash'
import {
  setChangeInformation,
  setPayloadKey,
  startLoading,
  stopLoading,
} from '../../../redux/slices/manage/prospectProfile'

import { prospectProfileView } from '../../../utils/apiPath'
import { getExaminationQualification } from '../../../services/eExamination/form'
import { getOptionsKey } from '../../../utils/lib'

export const fetchData =
  (uuid, courseSearch, examSearch, type) => async (dispatch) => {
    dispatch(startLoading())

    const body = {
      prospectUuid: uuid,
      courselimit: 100,
      coursePage: 1,
      courseSearch: _.defaultTo(courseSearch, ''),
      examLimit: 100,
      examPage: 1,
      examSearch: _.defaultTo(examSearch, ''),
      type: type,
      isEdit: true,
    }
    try {
      const response = await axios.post(prospectProfileView, body)
      const qualificationList = await dispatch(getExaminationQualification())
      const qualificationOption = getOptionsKey({
        data: _.get(qualificationList, 'payload.data.result') ?? [],
        label: 'qualificationName',
        key: 'uuid',
      })
      const result = response?.data?.result
      dispatch(setPayloadKey({ key: 'contact', data: result?.contact }))
      dispatch(setPayloadKey({ key: 'institution', data: result?.institution }))
      dispatch(
        setPayloadKey({
          key: 'qualificationOption',
          data: qualificationOption,
        }),
      )
      dispatch(
        setPayloadKey({ key: 'qualification', data: result?.qualification }),
      )
      dispatch(setPayloadKey({ key: 'between', data: result?.between }))
      dispatch(setPayloadKey({ key: 'branch', data: result?.branch }))
      dispatch(setPayloadKey({ key: 'gpa', data: result?.gpa }))
      dispatch(setPayloadKey({ key: 'info', data: result?.info }))
      dispatch(setPayloadKey({ key: 'info2', data: result?.info2 }))
      dispatch(setPayloadKey({ key: 'updated', data: result?.updated }))
      dispatch(setPayloadKey({ key: 'work', data: result?.work }))
      dispatch(
        setPayloadKey({
          key: 'division',
          data: _.get(result, 'work.division', ''),
        }),
      )
      dispatch(
        setPayloadKey({
          key: 'fullName',
          data: `${_.get(result, 'info.firstNameTH', '')} ${_.get(
            result,
            'info.lastNameTH',
            '',
          )}`,
        }),
      )
    } catch (err) {
      console.log('err', err)
    }

    dispatch(stopLoading())
  }

export const formatDateDisplay = (date) => {
  if (!dayjs(date).isValid()) return null
  return dayjs(new Date(date)).format(window.__env__.REACT_APP_DATE_SHOW)
}

export const handleChange = (props) => (dispatch) => {
  dispatch(setChangeInformation(props))
}
