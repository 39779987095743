import { Box, Divider, Typography } from '@mui/material'
import React from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import { ChevronRight } from '@mui/icons-material'
import { StyledButton } from '../Styled'
import DoughnutChart from '../../../../../components/Chart/DoughnutChart'
import _ from 'lodash'
import { TEST_RESULT_STATUS } from '../../../../../constants/eExamination'
import ScheduleInfoCard from '../ModuleCard/ScheduleInfoCard'
import { handleClickOpenDrawer } from '../../handler/handleClickOpenDrawer'
import {
  examinerAssociation,
  examinerCompany,
  examinerEnrollAssociation,
  examinerEnrollCompany,
  examinerEnrollOtherModel,
  examinerOtherModel,
} from '../../models/examiner'
import RemarkTooltip from '../../../../../modules/EConfiguration/components/RemarkTooltip'

const index = () => {
  const examinerEnrollComp = examinerEnrollCompany()
  const examinerEnrollAssoc = examinerEnrollAssociation()
  const examinerEnrollOther = examinerEnrollOtherModel()
  const colorArrayEnroll = _.defaultTo(examinerEnrollComp, [])
    .filter((item) => item.value !== 'ENROLL')
    .map((item) => item.dotColor)
  const allExaminees = [
    ...examinerEnrollComp,
    ...examinerEnrollAssoc,
    ...examinerEnrollOther,
  ]
  const resultEnroll = allExaminees.reduce((acc, curr) => {
    acc[curr.value] = (acc[curr.value] || 0) + curr.number
    return acc
  }, {})
  const finalResultEnroll = [
    _.get(resultEnroll, 'EXAMINEES', 0),
    _.get(resultEnroll, 'ABSENCE', 0),
  ]
  const totalModuleEnroll = _.sum(finalResultEnroll)

  const examinerComp = examinerCompany()
  const colorArray = _.defaultTo(examinerComp, []).map((item) => item.dotColor)
  const examinerAssoc = examinerAssociation()
  const examinerOther = examinerOtherModel()
  const allSchedules = [...examinerComp, ...examinerAssoc, ...examinerOther]
  const result = allSchedules.reduce((acc, curr) => {
    acc[curr.value] = (acc[curr.value] || 0) + curr.number
    return acc
  }, {})
  const finalResult = [
    result[TEST_RESULT_STATUS.PASS],
    result[TEST_RESULT_STATUS.NOT_PASS],
    result[TEST_RESULT_STATUS.ABSENCE],
  ]
  const totalModule = _.sum(finalResult)
  return (
    <Box>
      <Card>
        <CardContent>
          <Box display="flex" alignItems="center" gap={2}>
            <Typography variant="h6">
              ผู้สมัครสอบ <RemarkTooltip />
            </Typography>
            <StyledButton
              color="text.lightGray"
              size="small"
              background="#4976BA14"
              marginLeft={''}
              onClick={() => handleClickOpenDrawer('examiner')}
            >
              <ChevronRight fontSize="small" />
            </StyledButton>
          </Box>
          <Box display="flex" gap={2} mt={3} alignItems="center">
            <DoughnutChart
              dataSet={{
                data: finalResultEnroll,
                backgroundColor: colorArrayEnroll,
              }}
              height={224}
              label="ทั้งหมด"
              value={_.get(resultEnroll, 'ENROLL', 0)}
              eleId={'examinerEnrollDougnutChart'}
            />
            <Box display="flex" flexDirection="row" gap={1}>
              <ScheduleInfoCard
                dataList={_.defaultTo(examinerEnrollComp, [])}
                title="สนามสอบบริษัท"
                totalModule={totalModuleEnroll}
              />
              <ScheduleInfoCard
                dataList={_.defaultTo(examinerEnrollAssoc, [])}
                title="สนามสอบสมาคม"
                totalModule={totalModuleEnroll}
              />
              <ScheduleInfoCard
                dataList={_.defaultTo(examinerEnrollOther, [])}
                title="สนามสอบอื่นๆ"
                totalModule={totalModuleEnroll}
              />
            </Box>
          </Box>
          <Box my={2}>
            <Divider />
          </Box>
          <Box display="flex" gap={2} mt={3} alignItems="center">
            <DoughnutChart
              dataSet={{
                data: finalResult,
                backgroundColor: colorArray,
              }}
              height={224}
              label="ทั้งหมด"
              value={totalModule}
              eleId={'examinerDougnutChart'}
            />
            <Box display="flex" flexDirection="row" gap={1}>
              <ScheduleInfoCard
                dataList={_.defaultTo(examinerComp, [])}
                title=""
                totalModule={totalModule}
              />
              <ScheduleInfoCard
                dataList={_.defaultTo(examinerAssoc, [])}
                title=""
                totalModule={totalModule}
              />
              <ScheduleInfoCard
                dataList={_.defaultTo(examinerOther, [])}
                title=""
                totalModule={totalModule}
              />
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Box>
  )
}

export default index
