import React, { useMemo, useRef, useState } from 'react'
import { Form, Formik } from 'formik'
import _ from 'lodash'

import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Editor from '../../../../components/Editor/BasicEditor'
import Close from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'

import { StyledDrawer, StyledFooterDrawer, StyledWrapper } from './Styled'
import { handleCloseDrawer, handleInitialData, handleSubmit } from './events'
import { Divider } from '@mui/material'
import { StyledButton, StyledHeadLabel } from './Styled'
import HistoryTwoTone from '@mui/icons-material/HistoryTwoTone'
import HistoryDrawer from './History'
import { schema } from './schema'

const AnswerDrawer = () => {
  const dispatch = useDispatch()
  const [isOpen, setOpen] = useState(false)
  const { isDrawerOpen, detailQuestion } = useSelector(
    (state) => ({
      isDrawerOpen: state.eLearningQuestion.isDrawerOpen,
      detailQuestion: state.eLearningQuestion.result,
    }),
    shallowEqual,
  )
  const eLearningModule = _.get(
    detailQuestion,
    'eLearningLessonRefModule.eLearningModule',
    {},
  )

  const initData = useMemo(
    () => handleInitialData(detailQuestion),
    [detailQuestion],
  )

  const subEditorRef = useRef(null)

  return (
    <StyledDrawer
      anchor="right"
      open={isDrawerOpen}
      onClose={() => dispatch(handleCloseDrawer())}
    >
      <Formik
        initialValues={initData}
        validationSchema={schema}
        validateOnBlur={false}
        validateOnChange={false}
        onSubmit={(values) => dispatch(handleSubmit(values))}
      >
        {({ setFieldValue, values, errors }) => {
          const isAnsDisable = values.answer ? false : true
          return (
            <Form>
              <StyledHeadLabel>
                <Box sx={{ p: 2 }}>
                  <Typography variant="h6">{'คำถาม'}</Typography>
                  <IconButton
                    data-testid="btn-close-drawer"
                    color="primary"
                    component="span"
                    onClick={() => dispatch(handleCloseDrawer())}
                  >
                    <Close />
                  </IconButton>
                </Box>
              </StyledHeadLabel>
              <Divider />
              <StyledWrapper>
                <Box>
                  <Typography variant="body1b">
                    {'ผู้ถาม: ' + values.questionBy}{' '}
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="body2">
                    หลักสูตร:{' '}
                    {_.get(detailQuestion, 'eLearningCourse.name', '')}
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="body2">
                    บทเรียน: {_.get(eLearningModule, 'name', '')}
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="body2">{'คำถาม'}</Typography>
                </Box>
                <Box>
                  <Typography variant="body1b">{values.question}</Typography>
                </Box>

                <Editor
                  id="answer"
                  name="answer"
                  labelText="คำตอบ"
                  required
                  value={values.answer}
                  textError={errors.answer}
                  editorRef={subEditorRef}
                  onBlur={() => {
                    setFieldValue('answer', subEditorRef.current.getContent())
                  }}
                />
                <StyledFooterDrawer>
                  <Button variant="contained" size="m" type="submit">
                    ส่งคำตอบ
                  </Button>
                  <StyledButton
                    data-testid="btn-history"
                    onClick={() => setOpen(true)}
                    disabled={isAnsDisable}
                  >
                    <HistoryTwoTone />
                    ประวัติการแก้ไข
                  </StyledButton>
                </StyledFooterDrawer>
              </StyledWrapper>
              <HistoryDrawer
                isOpen={isOpen}
                value={values}
                onCloseDrawer={() => setOpen(false)}
              />
            </Form>
          )
        }}
      </Formik>
    </StyledDrawer>
  )
}

export default AnswerDrawer
