import React, { useEffect } from 'react'
import { useLocalStorage } from '@rehooks/local-storage'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import Drawer from '@mui/material/Drawer'
import IconButton from '@mui/material/IconButton'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import CloseIcon from '@mui/icons-material/Close'
import Divider from '@mui/material/Divider'
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone'
import { resetInitialData, setInitialData } from '../../redux/slices/speaker'
import { checkEditData, fetchDataById, handleClickEdit } from './events'
import View from './View'
import { StyledDrawerHeader, StyledDrawerWrapper } from './Styled'

const ViewDrawer = (props) => {
  const {
    open,
    onClose,
    isDrawer,
    detail,
    stateForm,
    speakerType,
    handleFetchData,
    isSpeakerPage,
  } = props
  const [user] = useLocalStorage('user')
  const publicId = _.get(isDrawer, 'publicId', '')
  const dispatch = useDispatch()
  const { data, isLoading } = useSelector(
    (state) => ({
      data: state.speaker.data,
      isLoading: state.speaker.isLoading,
    }),
    shallowEqual,
  )
  const systemConfig = _.get(data, 'systemConfig.value', null)
  const startDate = _.get(data, 'monthlyPlan.startDate', '')

  useEffect(() => {
    if (publicId) dispatch(fetchDataById(publicId, speakerType))
    if (detail) dispatch(setInitialData(detail))
    if (!open) dispatch(resetInitialData())
  }, [open])

  return (
    <Drawer
      sx={{ height: '100vh' }}
      anchor={'right'}
      open={open}
      onClose={onClose}
    >
      <StyledDrawerWrapper isLoading={isLoading}>
        <StyledDrawerHeader>
          <Typography variant="h5">
            {speakerType === 'REQUESTER'
              ? 'รายละเอียดคำขอ'
              : 'รายละเอียดการยืม'}
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
            {isSpeakerPage &&
              checkEditData(
                startDate,
                systemConfig,
                user,
                data,
                speakerType,
              ) && (
                <IconButton
                  data-testid="btn-edit-drawer"
                  color="primary"
                  component="span"
                  onClick={() => handleClickEdit(data)}
                >
                  <EditTwoToneIcon color="action" />
                </IconButton>
              )}

            <IconButton
              data-testid="btn-close-drawer"
              color="primary"
              component="span"
              onClick={onClose}
            >
              <CloseIcon color="action" />
            </IconButton>
          </Box>
        </StyledDrawerHeader>
        <Divider />

        {data && (
          <View
            isSpeakerPage={isSpeakerPage}
            stateForm={stateForm}
            handleFetchData={handleFetchData}
            onClose={onClose}
          />
        )}
      </StyledDrawerWrapper>
    </Drawer>
  )
}

export default ViewDrawer
