import HistoryTwoTone from '@mui/icons-material/HistoryTwoTone'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import Typography from '@mui/material/Typography'

import React, { useEffect, useState } from 'react'
import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import NormalTable from '../../../../../components/Table/NormalTable/NormalTable'
import { agentProfileCourse2 } from '../../../../../constants/manage/agentProfile'
import _ from 'lodash'
import ExamDrawer from './ExamDrawer'
import SearchCustom from '../../../Search'
import {
  startLoading,
  stopLoading,
  setPagination,
  setSearch,
} from '../../../../../redux/slices/manage/prospectProfile'
import { fetchExam } from './events'
import { useParams } from 'react-router-dom'
import { onSort } from '../../../../../utils/lib'

const ExamSection = ({ type }) => {
  const { id: uuid } = useParams()
  const [head, setHead] = useState([])
  const dispatch = useDispatch()
  const [isOpen, setIsOpen] = useState(false)

  const { search, pagination, data } = useSelector(
    (state) => ({
      search: state.prospectProfile.search,
      pagination: state.prospectProfile.pagination,
      data: state.prospectProfile.initialState,
    }),
    shallowEqual,
  )

  useEffect(() => {
    for (const agentProfile of agentProfileCourse2) {
      delete agentProfile['order']
    }
    setHead(agentProfileCourse2)
  }, [])

  const {
    courseSearch,
    examSearch,
    orderExamBy,
    sortExamBy,
    handleExamSearch,
  } = search

  const onRequestSort = (valueId, headCell) => {
    const { head, order } = onSort(valueId, headCell)
    const sort = _.isEmpty(order) ? 'registerDateTime' : valueId
    const orderBy = _.isEmpty(order) ? 'DESC' : String(order).toUpperCase()
    dispatch(
      setSearch({
        key: 'orderExamBy',
        value: orderBy,
      }),
    )
    dispatch(
      setSearch({
        key: 'sortExamBy',
        value: sort,
      }),
    )
    dispatch(
      fetchExam({
        ...pagination,
        prospectUuid: uuid,
        order: orderBy,
        sort: sort,
        examSearch: _.defaultTo(examSearch, ''),
        courseSearch: _.defaultTo(courseSearch, ''),
        type,
        staffUuid: uuid,
      }),
    )
    setHead(head)
  }

  useEffect(() => {
    dispatch(
      fetchExam({
        ...pagination,
        prospectUuid: uuid,
        order: orderExamBy ?? 'DESC',
        sort: sortExamBy ?? 'registerDateTime',
        examSearch: _.defaultTo(examSearch, ''),
        courseSearch: _.defaultTo(courseSearch, ''),
        type,
        staffUuid: uuid,
      }),
    )
  }, [pagination, handleExamSearch])

  return (
    <>
      <Card sx={{ pb: 3 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', m: 3 }}>
          <Box sx={{ display: 'flex', gap: '24px' }}>
            <Typography variant="h6">ประวัติการสอบ</Typography>
            <SearchCustom searchKey="examSearch" />
          </Box>

          <Button
            variant="outlined"
            size="m"
            onClick={() => setIsOpen(true)}
            data-testid="btn-open-drawer"
          >
            <HistoryTwoTone color="primary.main" />
          </Button>
        </Box>
        <NormalTable
          headCells={head}
          listRows={_.get(data, 'exam.result', [])}
          totalRows={_.get(data, 'exam.totalCount', 0)}
          showPagination
          isHiddenOptions
          startLoading={startLoading}
          stopLoading={stopLoading}
          onRequestSort={onRequestSort}
          handlePagination={(events) =>
            dispatch(
              setPagination({
                key: 'examPage',
                value: events.page,
              }),
            )
          }
        />
      </Card>
      <ExamDrawer
        isOpen={isOpen}
        onCloseDrawer={() => setIsOpen(false)}
        type={type}
      />
    </>
  )
}

export default ExamSection
