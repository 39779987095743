export const arrayResult = [
  { label: 'ผ่าน', name: 'passChecked' },
  { label: 'ไม่ผ่าน', name: 'failChecked' },
]

export const arrayStatus = [
  { label: 'ประกาศสิทธิ์แล้ว', name: 'eligibleChecked' },
  { label: 'รอประกาศสิทธิ์', name: 'waitAnnounceChecked' },
  { label: 'รอเอกสาร', name: 'waitDocumentChecked' },
  { label: 'รอตรวจสอบเอกสาร', name: 'waitCheckChecked' },
  { label: 'รอคอนเฟิร์มตัวแทน', name: 'waitingConfirm' },
  { label: 'เอกสารไม่ผ่าน', name: 'rejectedChecked' },
  { label: 'ยกเลิกการสมัคร', name: 'cancelChecked' },
  { label: 'ถูกยกเลิกโดยแอดมิน', name: 'canceledChecked' },
  { label: 'ถูกยกเลิกโดยผู้จัดการ', name: 'canceledMAChecked' },
  { label: 'ไม่มีสิทธิ์เข้าเรียน', name: 'ineligibleChecked' },
  { label: 'ยกเลิกคลาส', name: 'cancelClassChecked' },
  { label: 'ขาดเรียน', name: 'absentChecked' },
]

export const arrayCheckbox = {
  CR: [
    {
      label: 'ID ผู้สมัคร',
      nameCheckBox: 'codeIdChecked',
      nameInput: 'codeId',
      placeholder: 'ค้นหา ID ผู้สมัคร',
      type: 'text',
    },
    {
      label: 'ชื่อผู้สมัคร',
      nameCheckBox: 'nameChecked',
      nameInput: 'name',
      placeholder: 'ค้นหาชื่อผู้สมัคร',
      type: 'text',
    },
    {
      label: 'การเข้าเรียน',
      nameCheckBox: 'attendanceChecked',
      nameInput: 'attendance',
      placeholder: 'ค้นหาจากจำนวนที่เข้าเรียน',
      type: 'text',
    },
    {
      label: 'ผลการอบรม',
      nameCheckBox: 'trainingResultChecked',
      nameInput: 'trainingResult',
      type: 'checkbox',
      list: arrayResult,
    },
    {
      label: 'สถานะ',
      nameCheckBox: 'statusChecked',
      nameInput: 'status',
      type: 'checkbox',
      list: arrayStatus,
    },
  ],
  VC: [
    {
      label: 'ID ผู้สมัคร',
      nameCheckBox: 'codeIdChecked',
      nameInput: 'codeId',
      placeholder: 'ค้นหา ID ผู้สมัคร',
      type: 'text',
    },
    {
      label: 'ชื่อผู้สมัคร',
      nameCheckBox: 'nameChecked',
      nameInput: 'name',
      placeholder: 'ค้นหาชื่อผู้สมัคร',
      type: 'text',
    },
    {
      label: 'การเข้าเรียน',
      nameCheckBox: 'attendanceChecked',
      nameInput: 'attendance',
      placeholder: 'ค้นหาจากจำนวนที่เข้าเรียน',
      type: 'text',
    },
    {
      label: 'ผลการอบรม',
      nameCheckBox: 'trainingResultChecked',
      nameInput: 'trainingResult',
      type: 'checkbox',
      list: arrayResult,
    },
    {
      label: 'สถานะ',
      nameCheckBox: 'statusChecked',
      nameInput: 'status',
      type: 'checkbox',
      list: arrayStatus,
    },
    {
      label: 'ห้องเรียน',
      nameCheckBox: 'roomChecked',
      nameInput: 'room',
      type: 'selects',
    },
  ],
}

export const defaultFilter = {
  CR: {
    codeIdChecked: false,
    codeId: '',
    nameChecked: false,
    name: '',
    attendanceChecked: false,
    attendance: '',
    trainingResultChecked: false,
    trainingResult: {
      passChecked: false,
      failChecked: false,
    },
    statusChecked: false,
    status: {
      eligibleChecked: false,
      waitAnnounceChecked: false,
      waitDocumentChecked: false,
      waitCheckChecked: false,
      rejectedChecked: false,
      cancelChecked: false,
      canceledChecked: false,
      canceledMAChecked: false,
      absentChecked: false,
    },
  },
  VC: {
    codeIdChecked: false,
    codeId: '',
    nameChecked: false,
    name: '',
    attendanceChecked: false,
    attendance: '',
    roomChecked: false,
    date: '',
    room: [],
    trainingResultChecked: false,
    trainingResult: {
      passChecked: false,
      failChecked: false,
    },
    statusChecked: false,
    status: {
      eligibleChecked: false,
      waitAnnounceChecked: false,
      waitDocumentChecked: false,
      waitCheckChecked: false,
      rejectedChecked: false,
      cancelChecked: false,
      canceledChecked: false,
      canceledMAChecked: false,
      absentChecked: false,
    },
  },
}
