import _ from 'lodash'
import { setReduxValue } from '../../../redux/slices/reportComp'
import { handleSaveFilters } from './handleSaveFilters'

export const fetchDefaultStation = (user, moduleId) => async (dispatch) => {
  const permissionList = _.get(user, 'permission', [])
  const hasPermissionALL = _.find(permissionList, (item) => {
    return (
      item.owner === 'ALL' &&
      item.permission === 'REPORT' &&
      item.module === 'INVENTORY'
    )
  })

  const filter = []
  let id = 1

  if (!hasPermissionALL) {
    if (moduleId === 'REPORT_INVENTORY_RAW_DATA_REQUISITION_PIC') {
      let label = ''
      const station = []
      const defaultStation = _.get(user, 'area', []).find(
        (ele) => ele.station.uuid === _.get(user, 'stationUuid', ''),
      )
      station.push(defaultStation.station.uuid)
      label = defaultStation.station.station
      filter.push({
        id: id,
        key: 'stationAssetWarehouse',
        label: label,
        title: 'Station คลังทรัพย์สิน',
        typeOfValue: 'DROPDOWN_MULTI',
        value: station,
        cantDel: true,
      })
    } else {
      let label = ''
      const station = []
      const defaultStation = _.get(user, 'area', []).find(
        (ele) => ele.station.uuid === _.get(user, 'stationUuid', ''),
      )
      station.push(defaultStation.station.uuid)
      label = defaultStation.station.station
      filter.push({
        id: id,
        key: 'warehouseStation',
        label: label,
        title:
          moduleId === 'REPORT_INVENTORY_RAW_DATA_STOCK_PIC'
            ? 'Station ประจำ'
            : 'Station',
        typeOfValue: 'DROPDOWN_MULTI',
        value: station,
        cantDel: true,
      })
    }
  }

  if (filter.length > 0) {
    await dispatch(
      setReduxValue({
        key: 'displayFilters',
        value: filter,
      }),
    )
    dispatch(handleSaveFilters())
  }
}
