import dayjs from 'dayjs'
import _ from 'lodash'
import {
  closeDialog,
  loadingDialog,
} from '../../../../../../redux/slices/dialog'
import { resetForm } from '../../../../../../redux/slices/eLearning/courseBatchLearner/index'
import { postUploadCourseBatch } from '../../../../../../services/eLearning/course/detail'
import { handleBatchBody } from '../UploadBatch/events'

export const handleCloseDialog = () => (dispatch) => {
  dispatch(resetForm())
  dispatch(closeDialog())
}

export const handleSubmitAdd = (uuid) => async (dispatch) => {
  dispatch(loadingDialog())
  const body = handleBatchBody(uuid, true)
  const response = await dispatch(postUploadCourseBatch(body))
  if (_.isEmpty(response.error)) {
    dispatch(closeDialog())
    window.location.reload()
  }
}

export const handleConvertDate = (value) => {
  return dayjs(new Date(value)).format(window.__env__.REACT_APP_DATE_SHOW)
}

export const handleStaff = (list) => {
  if (_.isEmpty(list)) return ''
  return typeof list === 'string'
    ? list
    : _.join(
        list.map((staff) =>
          typeof staff === 'object' ? staff.agentCode : staff,
        ),
        ', ',
      )
}

export const isSuccess = (status) => status == 'SUCCESS'
