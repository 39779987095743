import { shallowEqual, useSelector } from 'react-redux'
import {
  Box,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  Typography,
} from '@mui/material'
import { useEffect, useRef } from 'react'
import {
  Cancel,
  CheckCircle,
  ChevronLeft,
  ChevronRight,
  CopyAll,
} from '@mui/icons-material'
import _ from 'lodash'
import { useDispatch } from 'react-redux'
import { setSelected } from '../events'
import { useReactToPrint } from 'react-to-print'
import { TooltipDialog } from '../../Styled'
import { sortQuestion } from '../../../handler/sortQuestion'
import './style.css'

export const QuestionContent = ({ isOverviewPage }) => {
  const dispatch = useDispatch()
  const contentRef = useRef()
  const reactToPrintFn = useReactToPrint({
    content: () => contentRef.current,
    pageStyle: `@media print {
      @page {
        margin: 10px;
        @page {
          size: A4 landscape;
        }
      }
    }`,
  })
  const { formData, selected } = useSelector(
    (state) => ({
      selected: state.crud.selected,
      formData: state.crud.formData,
    }),
    shallowEqual,
  )
  const questionsDatas = _.get(formData, 'questionsDatas', [])
  const subTitle = _.get(questionsDatas[selected], `subTitle`, '')
  const questionType = _.get(questionsDatas[selected], 'questionType')

  let optionsArray = []
  const allCount = questionsDatas?.length
  for (let i = 0; i < allCount; i++) {
    optionsArray.push(i)
  }

  const checkIcon = (data) => {
    return _.get(data, `isCorrectAnswer`) === true ? (
      <CheckCircle color="success" />
    ) : (
      <Cancel color="error" />
    )
  }

  useEffect(() => {
    sortQuestion(questionsDatas, 'sequence')
    dispatch(setSelected(0))
  }, [])

  return (
    <div
      ref={contentRef}
      style={{
        display: 'grid',
        gap: '24px',
      }}
    >
      {!isOverviewPage && (
        <Box sx={{ display: 'flex', gap: '16px' }}>
          <Typography variant="h6" fontSize="20px" sx={{ p: '4px' }}>
            ทั้งหมด
          </Typography>
          <FormControl>
            <Select
              labelId="select-label"
              id="simple-select"
              value={selected ?? 0}
              onChange={async (e) => dispatch(setSelected(e.target.value))}
            >
              {optionsArray.map((value) => {
                return (
                  <MenuItem value={value} key={value}>
                    {value + 1}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
          <Typography sx={{ padding: '6px' }}>/ {allCount}</Typography>
          <IconButton
            data-testid={`btn-previous-${selected}`}
            disabled={selected === 0}
            color="primary"
            onClick={() => dispatch(setSelected(selected - 1))}
          >
            <ChevronLeft />
          </IconButton>
          <IconButton
            data-testid={`btn-next-${selected}`}
            disabled={selected === allCount - 1 || allCount === 0}
            color="primary"
            onClick={() => dispatch(setSelected(selected + 1))}
          >
            <ChevronRight />
          </IconButton>
        </Box>
      )}
      <Box sx={{ display: 'flex', gap: '16px' }}>
        <Typography color="#999999" sx={{ padding: '4px' }}>
          คำถาม
        </Typography>
        <TooltipDialog title="คัดลอกคำถาม">
          {!isOverviewPage && (
            <IconButton
              data-testid={`btn-next-${selected}`}
              disabled={selected === allCount}
              color="#999999"
              onClick={reactToPrintFn}
            >
              <CopyAll />
            </IconButton>
          )}
        </TooltipDialog>
      </Box>
      <Box sx={{ display: 'flex', gap: '8px', padding: '4px' }}>
        <Typography variant="h6" fontSize="20px" sx={{ height: '100%' }}>
          Q{selected + 1}.
        </Typography>
        <Typography variant="h6" fontSize="20px">
          {['FILL_IN_BLANK_CHOICE', 'FILL_IN_BLANK_DRAG'].includes(
            questionType,
          ) ? (
            <div
              className="QContentDragDrop"
              style={{ margin: '0', whiteSpace: 'normal' }}
              dangerouslySetInnerHTML={{ __html: subTitle }}
            />
          ) : (
            _.get(questionsDatas[selected], `title`, '')
          )}
        </Typography>
      </Box>
      <Box sx={{ display: 'grid', padding: '4px', gap: '8px' }}>
        {questionType !== 'FREE_TEXT' && (
          <Typography color="#999999">คำตอบ</Typography>
        )}
        {_.get(questionsDatas[selected], `answers`, []).map((data, i) => {
          switch (questionType) {
            case 'SINGLE':
            case 'MULTIPLE':
            case 'TRUE_FALSE':
              return (
                <Box sx={{ display: 'flex', gap: '4px' }} key={i}>
                  {checkIcon(data)}
                  <Typography fontWeight="bold">{data.title}</Typography>
                </Box>
              )
            case 'SEQUENCE':
              return (
                <Box sx={{ display: 'flex', gap: '4px' }} key={i}>
                  <Typography fontWeight="bold" color="#4040AB">
                    {i + 1}.{' '}
                  </Typography>
                  <Typography fontWeight="bold">{data.title}</Typography>
                </Box>
              )
            case 'MATCHING': {
              const isRight =
                !_.isEmpty(data.question) && !_.isEmpty(data.title)
              return (
                <Box sx={{ display: 'flex', gap: '4px' }} key={i}>
                  {isRight ? (
                    <CheckCircle color="success" />
                  ) : (
                    <Cancel color="error" />
                  )}
                  {!_.isEmpty(data.question) && (
                    <Typography fontWeight="bold">{data.question}</Typography>
                  )}
                  {isRight && (
                    <Typography fontWeight="bold" color="#4040AB">
                      คู่กับ
                    </Typography>
                  )}
                  {!_.isEmpty(data.title) && (
                    <Typography fontWeight="bold">{data.title}</Typography>
                  )}
                </Box>
              )
            }
            case 'FILL_IN_BLANK_DRAG': {
              const findAnswer = _.get(
                questionsDatas[selected],
                `answerData`,
                [],
              ).find((d) => {
                return d.target === data.target
              })
              return (
                <Box sx={{ display: 'flex', gap: '4px' }} key={i}>
                  <Typography fontWeight="bold">{data.title}</Typography>
                  <Typography fontWeight="bold" color="#4040AB">
                    ตอบ
                  </Typography>
                  <Typography fontWeight="bold">{findAnswer.title}</Typography>
                </Box>
              )
            }
            case 'FILL_IN_BLANK_CHOICE':
              return (
                <>
                  <Typography fontWeight="bold">{data.title}</Typography>
                  {data.subAnswers.map((d, i) => {
                    return (
                      <Box sx={{ display: 'flex', gap: '4px' }} key={i}>
                        {_.get(d, `isCorrect`) === true ? (
                          <CheckCircle color="success" />
                        ) : (
                          <Cancel color="error" />
                        )}
                        <Typography
                          fontWeight="bold"
                          color="#4040AB"
                        ></Typography>
                        <Typography fontWeight="bold">{d.title}</Typography>
                      </Box>
                    )
                  })}
                </>
              )
            default:
              break
          }
        })}
        {questionType === 'FILL_IN_BLANK_DRAG' && (
          <Typography color="#999999">ตัวเลือกหลอก</Typography>
        )}
        {questionType === 'FILL_IN_BLANK_DRAG' &&
          _.get(questionsDatas[selected], `answerData`, [])
            .filter((d) => {
              return d.target !== null
            })
            .map((data, i) => {
              return (
                <Typography fontWeight="bold" key={i}>
                  &bull; {data.title}
                </Typography>
              )
            })}
      </Box>
    </div>
  )
}
