import React, { useState, useEffect } from 'react'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import InputBase from '@mui/material/InputBase'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import CloseIcon from '@mui/icons-material/Close'
import Divider from '@mui/material/Divider'
import Checkbox from '@mui/material/Checkbox'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import {
  StyledDrawer,
  StyledFooter,
  StyledGroupBox,
  StyledHeadLabel,
} from '../../components/FilterDrawer/Styled'

export const defaultFilter = {
  productTypeChecked: false,
  productTypeText: '',
  status: {
    activeChecked: false,
    inActiveChecked: false,
    deletedChecked: false,
  },
  statusChecked: false,
  createdByChecked: false,
  createdBy: '',
  updatedByChecked: false,
  updatedBy: '',
}

export const handleOnProductTextChange = (e, setFilter) => {
  setFilter((prevState) => ({
    ...prevState,
    productTypeText: e.target.value,
  }))
}

const handleCreatedByChange = (e, setFilter) => {
  setFilter((prevState) => ({
    ...prevState,
    createdBy: e.target.value,
  }))
}
const handleUpdatedByChange = (e, setFilter) => {
  setFilter((prevState) => ({
    ...prevState,
    updatedBy: e.target.value,
  }))
}

export const handleChangeStatus = (event, setFilter, filter) => {
  if (event.target.name === 'activeChecked') {
    setFilter((prevState) => ({
      ...prevState,
      status: {
        activeChecked: event.target.checked,
        inActiveChecked: filter.status.inActiveChecked,
        deletedChecked: filter.status.deletedChecked,
      },
    }))
  } else if (event.target.name === 'inActiveChecked') {
    setFilter((prevState) => ({
      ...prevState,
      status: {
        activeChecked: filter.status.activeChecked,
        inActiveChecked: event.target.checked,
        deletedChecked: filter.status.deletedChecked,
      },
    }))
  } else if (event.target.name === 'deletedChecked') {
    setFilter((prevState) => ({
      ...prevState,
      status: {
        activeChecked: filter.status.activeChecked,
        inActiveChecked: filter.status.inActiveChecked,
        deletedChecked: event.target.checked,
      },
    }))
  }
}

export const onClearClick = (setFilter) => {
  setFilter({
    productTypeChecked: false,
    productTypeText: '',
    status: {
      activeChecked: false,
      inActiveChecked: false,
      deletedChecked: false,
    },
    statusChecked: false,
  })
}

export const handleChangeChecked = ({ checked, key, setFilter }) => {
  setFilter((prevState) => ({
    ...prevState,
    [key]: checked,
  }))
}

const FilterDrawer = ({ open, onClose, onFilterClick }) => {
  const [filter, setFilter] = useState(defaultFilter)
  const [filterMemo, setFilterMemo] = useState()

  useEffect(() => {
    if (filterMemo) {
      setFilter(filterMemo)
      return
    }
    setFilter(defaultFilter)
  }, [open])

  return (
    <StyledDrawer open={open} onClose={onClose}>
      <StyledHeadLabel>
        <Box>
          <Typography variant="h5">ตัวกรอง</Typography>
          <IconButton
            data-testid={`btn-close-drawer`}
            color="primary"
            component="span"
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Divider />
      </StyledHeadLabel>
      <StyledGroupBox>
        <FormGroup sx={{ width: '100%' }}>
          <FormControlLabel
            control={
              <Checkbox
                inputProps={{
                  'data-testid': 'productTypeChecked',
                }}
                name="productTypeChecked"
                checked={filter.productTypeChecked}
                onChange={(e) => {
                  handleChangeChecked({
                    checked: e.target.checked,
                    key: 'productTypeChecked',
                    setFilter,
                  })
                }}
              />
            }
            label="ทักษะที่ได้จากหลักสูตร"
            sx={!filter.productTypeChecked ? { marginBottom: -1 } : {}}
          />
          {filter.productTypeChecked && (
            <InputBase
              inputProps={{
                'data-testid': 'productTypeText',
              }}
              type="basic"
              disabled={!filter.productTypeChecked}
              placeholder="ค้นหาทักษะที่ได้จากหลักสูตร"
              value={filter.productTypeText}
              onChange={(e) => handleOnProductTextChange(e, setFilter)}
            ></InputBase>
          )}
        </FormGroup>
      </StyledGroupBox>
      <StyledGroupBox sx={{ display: 'flex', flexDirection: 'column' }}>
        <FormControlLabel
          label="สถานะ"
          control={
            <Checkbox
              inputProps={{
                'data-testid': 'statusChecked',
              }}
              name="statusChecked"
              checked={filter.statusChecked}
              onChange={(e) => {
                handleChangeChecked({
                  checked: e.target.checked,
                  key: 'statusChecked',
                  setFilter,
                })
              }}
            />
          }
        />
        {filter.statusChecked && (
          <Box sx={{ display: 'flex', flexDirection: 'column', mx: 3 }}>
            <FormControlLabel
              label="เปิดใช้งาน"
              control={
                <Checkbox
                  inputProps={{
                    'data-testid': 'activeChecked',
                  }}
                  name="activeChecked"
                  checked={filter.status.activeChecked}
                  onChange={(event) =>
                    handleChangeStatus(event, setFilter, filter)
                  }
                />
              }
            />
            <FormControlLabel
              label="ปิดใช้งาน"
              control={
                <Checkbox
                  inputProps={{
                    'data-testid': 'inActiveChecked',
                  }}
                  name="inActiveChecked"
                  checked={filter.status.inActiveChecked}
                  onChange={(event) =>
                    handleChangeStatus(event, setFilter, filter)
                  }
                />
              }
            />
            <FormControlLabel
              label="ถูกลบแล้ว"
              control={
                <Checkbox
                  inputProps={{
                    'data-testid': 'deletedChecked',
                  }}
                  name="deletedChecked"
                  checked={filter.status.deletedChecked}
                  onChange={(event) =>
                    handleChangeStatus(event, setFilter, filter)
                  }
                />
              }
            />
          </Box>
        )}
      </StyledGroupBox>
      <StyledGroupBox>
        <FormGroup sx={{ width: '100%' }}>
          <FormControlLabel
            control={
              <Checkbox
                data-testid="checkbox-distribution-abbreviation"
                name="createdByChecked"
                checked={filter.createdByChecked}
                onChange={(e) => {
                  handleChangeChecked({
                    checked: e.target.checked,
                    key: 'createdByChecked',
                    setFilter,
                  })
                }}
              />
            }
            label="สร้างโดย"
            sx={!filter.createdByChecked ? { marginBottom: -1 } : {}}
          />
          {filter.createdByChecked && (
            <InputBase
              sx={{ borderRadius: 0 }}
              type="basic"
              disabled={!filter.createdByChecked}
              placeholder="ค้นหาสร้างโดย"
              value={filter.createdBy}
              onChange={(e) => handleCreatedByChange(e, setFilter)}
            ></InputBase>
          )}
        </FormGroup>
      </StyledGroupBox>
      <StyledGroupBox>
        <FormGroup sx={{ width: '100%' }}>
          <FormControlLabel
            control={
              <Checkbox
                data-testid="checkbox-distribution-updatedBy"
                name="updatedByChecked"
                checked={filter.updatedByChecked}
                onChange={(e) => {
                  handleChangeChecked({
                    checked: e.target.checked,
                    key: 'updatedByChecked',
                    setFilter,
                  })
                }}
              />
            }
            label="แก้ไขล่าสุดโดย"
            sx={!filter.updatedByChecked ? { marginBottom: -1 } : {}}
          />
          {filter.updatedByChecked && (
            <InputBase
              sx={{ borderRadius: 0 }}
              type="basic"
              disabled={!filter.updatedByChecked}
              placeholder="ค้นหาแก้ไขล่าสุดโดย"
              value={filter.updatedBy}
              onChange={(e) => handleUpdatedByChange(e, setFilter)}
            ></InputBase>
          )}
        </FormGroup>
      </StyledGroupBox>
      <StyledFooter>
        <Button
          data-testid="btn-clear"
          variant="outlined"
          onClick={() => onClearClick(setFilter)}
        >
          ล้าง
        </Button>
        <Button
          data-testid="btn-confirm-filter"
          onClick={() => {
            onClose()
            onFilterClick(filter)
            setFilterMemo(filter)
          }}
          variant="contained"
        >
          ยืนยัน
        </Button>
      </StyledFooter>
    </StyledDrawer>
  )
}
export default FilterDrawer
