import _ from 'lodash'
import { setFieldValue } from '../../../../../redux/slices/inventory/dashboard'
import { setFieldValue as setFieldValueCRUD } from '../../../../../redux/slices/crud'
import { toggleFiltersDrawer } from '../../handler/handleClickOpenDrawer'
import dayjs from 'dayjs'
import { fetchOverview } from '../../handler/fetchOverview'
// import { defaultFilter } from './model'
import { callAPI } from '../../../../../utils/lib'
import { CRUDListFilterPath, stationFilter } from '../../../../../utils/apiPath'
import { store } from '../../../../../App'
import { hasAllDashboardPerms } from '../../handler/hasAllDashboardPerms'
// import { getTrainingPlan } from '../../../../../services/inventory'

export const handleFiltering = (filter, station) => (dispatch) => {
  const stationList = []
  const user = JSON.parse(localStorage.getItem('user'))
  const stationUser = _.get(user, 'area[0].station')
  const isFilterDate =
    filter.startDateChecked && filter.startDate && filter.endDate ? true : false
  const isFilterCourse = filter.courseChecked && filter.course
  const isFilterInventory = filter.inventoryChecked && filter.inventory
  if (filter.stationChecked) {
    for (const key in filter.station) {
      if (!filter.station[key]) continue
      const item = station.find((e) => e.name === key)
      stationList.push(item)
    }
  }
  const isFilterStation = filter.stationChecked && stationList.length > 0
  let countFilterTotal = 0
  if (isFilterDate) ++countFilterTotal
  if (isFilterCourse) ++countFilterTotal
  if (isFilterStation) ++countFilterTotal
  if (isFilterInventory) ++countFilterTotal

  dispatch(
    setFieldValue({
      key: 'filtersDrawer',
      value: {
        filterTotal: countFilterTotal,
        isFilterDate: isFilterDate,
        isFilterStation: isFilterStation,
      },
    }),
  )
  dispatch(
    setFieldValue({
      key: 'filter',
      value: {
        ...filter,
        // station: isFilterStation ? stationList : [],
        inventory: isFilterInventory ? filter.inventory : '',
        course: isFilterCourse ? filter.course : '',
        startDate: isFilterDate ? filter.startDate : '',
        endDate: isFilterDate ? filter.endDate : '',
        station: hasAllDashboardPerms()
          ? isFilterStation
            ? stationList
            : []
          : [
              {
                label: stationUser.station,
                uuid: stationUser.uuid,
              },
            ],
      },
    }),
  )
  toggleFiltersDrawer(false)
  fetchOverview(station)
}

// export const handleChange = ({
//   key,
//   value,
//   filter,
//   setFilter,
//   listKey,
//   isCheck,
// }) => {
//   let objState = {}
//   if (!_.isNil(listKey)) {
//     objState = {
//       ...filter,
//       [listKey]: {
//         ...filter[listKey],
//         [key]: value,
//       },
//     }
//   } else if (isCheck) {
//     const subKey = key.replace('Checked', '')
//     objState = {
//       ...filter,
//       [key]: value,
//       [subKey]: defaultFilter[subKey],
//     }
//   } else {
//     objState = {
//       ...filter,
//       [key]: value,
//     }
//   }
//   setFilter(objState)
// }

export const handleChange = ({ key, value, filter, setFilter, listKey }) => {
  let objState = {
    ...filter,
    [key]: value,
  }
  if (!_.isNil(listKey)) {
    objState = {
      ...filter,
      [listKey]: {
        ...filter[listKey],
        [key]: value,
      },
    }
  }
  setFilter(objState)
}

export const handleSelectDate = (selectedDates, filter, setFilter) => {
  let objState = {
    ...filter,
    startDate: selectedDates.startDate,
    endDate: selectedDates.endDate,
    displayDate: selectedDates.display,
  }
  setFilter(objState)
}

export const handleFetchStation = async (setStation) => {
  const status = ['ACTIVE']
  const body = {
    station: '',
    limit: -1,
    order: 'DESC',
    page: 1,
    sort: 'updatedAt',
    status: status,
    createdBy: '',
    updatedBy: '',
  }
  await callAPI({
    method: 'post',
    url: stationFilter,
    body,
    onSuccess: (data) => {
      const stationList = _.get(data, 'result', []).map((item) => ({
        label: item.station,
        name: _.lowerCase(item.station).replace(' ', '') + 'Checked',
        uuid: item.uuid,
      }))
      setStation(stationList)
    },
  })
}

export const getCourseOptions = async () => {
  const response = await callAPI({
    method: 'post',
    url: `/report/options-by-key/inventory/report-raw-data-requisition`,
    body: {
      columnSelected: 'courseNameEN',
      moduleId: 'REPORT_INVENTORY_RAW_DATA_REQUISITION',
    },
  })
  const result = _.map(_.get(response, 'results', []), (item) => {
    return {
      ...item,
      label: item?.label ?? '',
      value: item?.value,
    }
  })
  return result

  // const user = JSON.parse(localStorage.getItem('user'))
  // const staffStationUuid = _.get(user, 'stationUuid', '')
  // const hasPermissionAdmin = validatePermission({
  //   user,
  //   moduleType: INVENTORY_SETTING,
  //   permission: [STOCK_PERMISSION.ADMIN],
  // })
  // let trainingPlans = await store
  //   .dispatch(getTrainingPlan())
  //   .then((res) => res.payload)

  // // if (!hasPermissionAdmin) {
  // //   trainingPlans = _.defaultTo(trainingPlans, []).filter((item) => {
  // //     return staffStationUuid === item?.stationUuid
  // //   })
  // // }
  // return (trainingPlans ?? []).map((item) => {
  //   return {
  //     ...item,
  //     uuid: item.monthlyPlanUuid,
  //     value: item.monthlyPlanUuid,
  //   }
  // })
}

export const getWarehouseOptions = async () => {
  const url = CRUDListFilterPath
  const headers = { 'x-type': 'INVENTORY_WAREHOUSE' }
  let body = {
    limit: '-1',
    page: 1,
    order: 'DESC',
    sort: 'updatedAt',
    quickSearch: '',
    name: '',
    status: ['ACTIVE'],
    createdBy: '',
    updatedBy: '',
    stockCategoryName: '',
    search: '',
    productType: [],
    startCreatedDate: '',
    endCreatedDate: '',
    startUpdatedDate: '',
    endUpdatedDate: '',
  }

  const response = await callAPI({
    method: 'post',
    url,
    headers,
    body,
  })

  let result = _.map(_.get(response, 'result', []), (item) => {
    return {
      ...item,
      label: item?.warehouseName ?? '',
      value: item?.uuid,
    }
  })
  return result
}

export const fetchFilterOptions = async () => {
  const courseList = await getCourseOptions()
  const warehouseList = await getWarehouseOptions()
  store.dispatch(
    setFieldValue({
      key: 'filterList',
      value: {
        course: courseList,
        inventory: warehouseList,
      },
    }),
  )
}

export const mutateFilterStateToProp = (filterState) => {
  return {
    startDate: filterState.startDateChecked
      ? filterState.startDate
      : dayjs(new Date()).utc(true).startOf('month'),
    endDate: filterState.startDateChecked
      ? filterState.endDate
      : dayjs(new Date()).utc(true),
    inventory: filterState.inventoryChecked ? filterState.inventory : '',
    station: filterState.stationChecked ? filterState.station : '',
    course: filterState.courseChecked ? filterState.course : '',
  }
}

export const mutateFilterPropToBody = (filterProp) => {
  const stationBody =
    filterProp?.station == ''
      ? []
      : _.defaultTo(filterProp?.station, []).map((item) => item.uuid)
  return {
    startDate: _.isDate(filterProp?.startDate)
      ? dayjs(filterProp?.startDate).utc(true).toDate()
      : dayjs(new Date()).utc(true).startOf('month'),
    endDate: _.isDate(filterProp?.endDate)
      ? dayjs(filterProp?.endDate).utc(true).toDate()
      : dayjs(new Date()).utc(true),
    inventory: _.get(filterProp, 'inventory', ''),
    station: _.defaultTo(stationBody, []), //stationBody
    course: _.get(filterProp, 'course', ''),
  }
}

export const clearSelectDropdown = async ({ arrayCheckbox }) => {
  console.log('inside', arrayCheckbox)
  await arrayCheckbox.map((item) => {
    if (['select_dropdown'].includes(item.type)) {
      store.dispatch(
        setFieldValueCRUD({
          key: `formData.${item.nameInput}-filter-dropdown`,
          value: '',
        }),
      )
    }
  })
}
