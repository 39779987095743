import React, { createRef, useEffect, useMemo, useRef, useState } from 'react'
import _ from 'lodash'
import dayjs from 'dayjs'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import { useLocalStorage } from '@rehooks/local-storage'
import { styled } from '@mui/material/styles'

import { MONTH_LIST } from './Text'
import Calendar from '../../components/Calendar/All.js'
import CircularProgress from '@mui/material/CircularProgress'
import Workload from '../../components/Calendar/Workload'
import AlertDialog from '../../components/AlertDialog'
import Header from './Header'
import HeaderCalendar from './HeaderCalendar'
import DrawerAll from './Drawer/Index'
import * as API from './api/Index'

import {
  validatePermission,
  getQueryParams,
  getStaffStationOption,
} from '../../utils/lib'
import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'

import { useParams } from 'react-router-dom'
import {
  fetchDataAll,
  fetchEvents,
  getApproveList,
  handleConfirm,
  SelectWork,
  filterWorkType,
} from '../MonthlyPlan/handle'

const CardCalendar = styled(Card)((props) => ({
  minWidth: 275,
  marginLeft: props.theme.spacing(3),
  marginRight: props.theme.spacing(3),
  paddingBottom: props.theme.spacing(2),
  zIndex: 5,
  height: `calc(100vh - ${props.height + 95}px)`,
  overflow: 'scroll',
}))

const Index = ({ isTest }) => {
  const { id: uuid } = useParams()
  const objOnLink = {
    month: getQueryParams('month'),
    year: getQueryParams('year'),
    areaUuid: getQueryParams('areaUuid'),
    stationUuid: getQueryParams('stationUuid'),
    departmentUuid: getQueryParams('departmentUuid'),
    drawer: Number(getQueryParams('drawer')),
    view: Number(getQueryParams('view')),
  }
  const [state, setState] = useState({
    calendarWeekends: true,
    calendarEvents: [],
    calendarAllEvents: [],
    work: { value: '', label: '' },
    activity: { value: '', label: '' },
    area: { value: '', label: '' },
    leave: { value: '', label: '' },
    data: {
      month: uuid ? Number(_.get(objOnLink, 'month', '')) - 1 : dayjs().month(),
      year: uuid ? Number(_.get(objOnLink, 'year', '')) : dayjs().year(),
    },
    filter: { courses: [], staffs: [], workTypes: [] },
    filterWorkload: { courses: [], staffs: [], workTypes: [] },
    personalPlan: { detail: [], summary: [], threshold: 0, personalPlan: [] },
    courseList: [],
    staffList: [],
    approveList: [],
    isFirstInitial: true,
    isAllStation: false,
    isUpdateSuccess: false,
  })
  const [height, setHeight] = useState(0)
  const [headerVisible, setHeaderVisible] = useState(true)
  const ref = useRef(null)
  // Calendar Mode
  const [calendarType, setCalendarType] = useState('PERSONAL')
  const [user] = useLocalStorage('user')
  const [stateDrawer, setStateDrawer] = useState(false)

  const [options, setOptions] = useState({
    work: [],
    activity: [],
    area: [],
    leave: [],
    staffProfile: [],
    meetingRoom: [],
    monthlyPlanStaff: [],
    staff: [],
    staffWorkload: [],
    responsibility: [],
    classroom: [],
    distributionChannel: [],
    speakers: [],
    course: [],
    masterPlan: {},
    personalPlan: { summary: [], personalPlan: [], detail: [], threshold: 0 },
    allStaffs: [],
  })
  const [isLoading, setIsLoading] = useState(false)
  const [isDrawer, setIsDrawer] = useState({
    type: '',
    status: false,
    publicId: '',
  })

  const [newEvent, setNewEvent] = useState({})
  const calendarComponentRef = createRef()
  const [dialog, setDialog] = useState({
    open: false,
    title: 'เพิ่มตารางงาน',
    variant: 'select',
  })
  const [confirmDialog, setConfirmDialog] = useState({
    open: false,
    variant: 'save',
    title: '',
    content: '',
    isLoading: false,
    value: { reason: '' },
    error: { reason: '' },
    onConfirmClick: null,
    onCancelClick: null,
  })
  const [approveDrawer, setApproveDrawer] = useState({
    open: false,
    count: 0,
    page: 0,
    limit: 100,
  })
  const [historyDrawer, setHistoryDrawer] = useState({
    open: false,
    count: 0,
    page: 0,
    limit: 100,
    startDate: uuid
      ? dayjs()
          .month(Number(_.get(objOnLink, 'month', '')) - 1)
          .year(Number(_.get(objOnLink, 'year', '')))
          .startOf('month')
          .format(window.__env__.REACT_APP_DATE_DB)
      : dayjs().startOf('month').format(window.__env__.REACT_APP_DATE_DB),
    finishDate: uuid
      ? dayjs()
          .month(Number(_.get(objOnLink, 'month', '')) - 1)
          .year(Number(_.get(objOnLink, 'year', '')))
          .endOf('month')
          .format(window.__env__.REACT_APP_DATE_DB)
      : dayjs().endOf('month').format(window.__env__.REACT_APP_DATE_DB),
  })
  const [isOpenWorkload, setIsOpenWorkload] = useState(true)
  const masterPlanStatus = _.get(state, 'masterPlan.status', '')

  const [canEditPreviousPlan, setCanEditPreviousPlan] = useState({
    allowEdit: false,
    isShow: false,
    title: '',
    subTitle: '',
  })
  const [isFirstFetchData, setIsFirstFetchData] = useState(false)

  useMemo(() => {
    // fetch only master data
    fetchDataAll({
      state,
      setState,
      setOptions,
      setIsLoading,
      user,
      uuid,
      objOnLink,
    })
  }, [])

  useEffect(() => {
    handleFetchEvents(true)
  }, [state.filter, state.filterWorkload])

  useEffect(() => {
    handleFetchEvents(false)
  }, [
    state.data,
    state.area.value,
    state.area.valueStation,
    calendarType,
    state.isAllStation,
  ])

  const handleCloseConfirmDialog = () => {
    setConfirmDialog((prevState) => ({
      ...prevState,
      open: false,
      value: { reason: '' },
      error: { reason: '' },
    }))
  }

  const handleFilterWorkType = () => {
    const hasPermissionHF = validatePermission({
      user,
      moduleType: 'MONTHLY_PLAN',
      permission: ['APPROVE_MP'],
    })
    let listWorkTypes = options.work
    if (hasPermissionHF) {
      listWorkTypes = filterWorkType(options.work)
    }
    const workTypes = listWorkTypes.map((d) => {
      return d.uuid
    })

    const workTypesUUIDList = options.work.map((d) => {
      return d.uuid
    })

    return calendarType === 'PERSONAL' ? workTypesUUIDList : workTypes
  }

  const handleFetchEvents = async (isFilter) => {
    setIsLoading(true)
    const area = _.get(state, 'area.value', '')

    if (area === '') return
    const body = {
      area: state.isAllStation ? '' : area,
      month: state.data.month + 1,
      year: state.data.year,
      staffs: [],
      workTypes: [],
    }
    const masterPlanBody = {
      params: {
        ...body,
        staffId: user?.uuid,
      },
    }

    const data = await Promise.all([
      getApproveList({
        state,
        approveDrawer,
        setApproveDrawer,
        setState,
      }),
      API.fetchMasterPlan(masterPlanBody),
      API.fetchStaffProfile(),
      API.fetchStaffList({
        isYear: state.data.year,
        isMonth: state.data.month + 1,
        area: state.area.value,
        type: 'PERSONAL', //workload
      }),
    ])

    let monthlyPlanStaffList = {
      all: state.filter.staffs,
      personal: _.get(data, '[3]', []).map((item) => item.value),
      option: options.monthlyPlanStaff,
      staffInArea: options.staffProfile,
      couseSetting: state.filter.courses,
    }

    if (isFirstFetchData) {
      const dataStaffList = await Promise.all([
        API.fetchStaffList({
          isYear: state.data.year,
          isMonth: state.data.month + 1,
          area: state.area.value,
          type: 'ALL', //calendar
        }),
        API.getMonthlyPlanFilterStaff({
          area: state.area.value,
        }),
        API.getStaffInArea(state.area.value),
        API.fetchCourseSettingList({
          isYear: state.data.year,
          isMonth: state.data.month + 1,
          area: state.area.value,
        }),
      ])

      monthlyPlanStaffList = {
        ...monthlyPlanStaffList,
        all: _.get(dataStaffList, '[0]', []).map((item) => item.value),
        option: _.get(dataStaffList, '[1]', []),
        staffInArea: getStaffStationOption(dataStaffList[2]),
        couseSetting: _.get(dataStaffList, '[3]', []).map(
          (item) => item.courseCode,
        ),
      }
    } else {
      setIsFirstFetchData(true)
    }

    const approveList = _.get(data, '[0]', [])
    const masterPlan = _.get(data, '[1]', [])
    const monthlyPlanStaff = monthlyPlanStaffList.option
    const courses = monthlyPlanStaffList.couseSetting
    const allStaffs = _.get(data, '[2]', [])
    const staffs = monthlyPlanStaffList.all
    const filterStaff = isFilter
      ? state.filter.staffs
      : monthlyPlanStaffList.all

    const filterWorkloadStaff = isFilter
      ? state.filterWorkload.staffs
      : monthlyPlanStaffList.personal
    state.filter.staffs = filterStaff
    state.filterWorkload.staffs = filterWorkloadStaff
    const filterName = calendarType === 'PERSONAL' ? 'filterWorkload' : 'filter'
    const handleType = state.handleType
    state[filterName]['workTypes'] =
      isFilter || handleType === 'actions'
        ? state[filterName]['workTypes']
        : handleFilterWorkType()

    const fetchCalendarEvents = [
      fetchEvents({
        state,
        isAllPlan: false,
        options,
        courses,
        staffs,
        calendarType,
      }),
      fetchEvents({
        state,
        isAllPlan: true,
        options,
        courses,
        staffs,
        calendarType,
      }),
      API.fetchData(
        body,
        'personal',
        'post',
        state.isFirstInitial,
        state.filterWorkload,
        state.isAllStation,
        area,
      ),
    ]
    const dataEvent = await Promise.all(fetchCalendarEvents)
    const calendarEvents = dataEvent[0]
    const calendarAllEvents = dataEvent[1]
    const personalPlan = dataEvent[2]
    const staffProfileOption = monthlyPlanStaffList.staffInArea
    const allStaffsOption = getStaffStationOption(allStaffs)
    setOptions((prevState) => ({
      ...prevState,
      monthlyPlanStaff,
      staffProfile: staffProfileOption,
      allStaffs: allStaffsOption,
    }))
    setState((prev) => ({
      ...prev,
      area: {
        ...prev.area,
        valueAreaList: 'all',
        valueStationList: 'all',
      },
      approveList: _.has(approveList, 'isAxiosError')
        ? []
        : _.get(approveList, 'result', []),
      personalPlan: _.has(personalPlan, 'isAxiosError')
        ? {
            detail: [],
            summary: [],
            threshold: 0,
            personalPlan: [],
          }
        : personalPlan,
      masterPlan: _.has(masterPlan, 'isAxiosError') ? {} : masterPlan,
      calendarEvents,
      canEditPreviousPlan: getCanEditPreviousPlan(masterPlan, state),
      calendarAllEvents,
      isUpdateSuccess: false,
      handleType: isFilter
        ? 'filter'
        : handleType === 'actions'
        ? 'actions'
        : 'fetch',
    }))
    setApproveDrawer((prev) => ({
      ...prev,
      count: _.get(approveList, 'totalCount', 0),
    }))
    setIsLoading(false)
    setTimeout(() => {
      setHeight(ref?.current?.clientHeight)
    })
    setCanEditPreviousPlan(getCanEditPreviousPlan(masterPlan, state))
    if (uuid && !stateDrawer && !!_.get(objOnLink, 'drawer', 0)) {
      setIsDrawer({
        status: true,
        type: 'view',
        publicId: uuid,
      })
      setStateDrawer(true)
    }
  }

  const allowEditPlan = true

  const handleClickCalendar = (date, staff) => {
    if (!allowEditPlan) alertCanNotEditPlan(confirmDialog, setConfirmDialog)
    else if (
      validatePermission({
        user: user,
        moduleType: 'MONTHLY_PLAN',
        permission: [
          'CREATE_ALL',
          'CREATE_ALL_EXC_HOLIDAY',
          'CREATE_LEAVE',
          'APPROVE_ZONE',
        ],
        data: state,
      }) &&
      masterPlanStatus !== 'WAITING'
    ) {
      setNewEvent({
        title: 'New Event Click',
        start: date,
        end: date,
        allDay: true,
        uuidStaff: staff.uuid,
        productTypeStaff: staff?.productType || [],
      })
      setDialog({ ...dialog, open: true })
    }
  }

  const handleSelectCalendar = (calendar) => {
    const { start, end } = calendar
    if (!allowEditPlan) alertCanNotEditPlan(confirmDialog, setConfirmDialog)
    else if (
      validatePermission({
        user: user,
        moduleType: 'MONTHLY_PLAN',
        permission: [
          'CREATE_ALL',
          'CREATE_ALL_EXC_HOLIDAY',
          'CREATE_LEAVE',
          'APPROVE_ZONE',
          'ASSISTS_ZONE',
        ],
        data: state,
      }) &&
      masterPlanStatus !== 'WAITING'
    ) {
      setNewEvent({
        title: 'New Event Select',
        start: start,
        end: dayjs(end).add(-1, 'day'),
        allDay: true,
      })
      setDialog({ ...dialog, open: true })
    }
  }

  const handleClickEdit = (publicId, work) => {
    setIsDrawer({ status: true, type: 'view', publicId, work })
  }

  return (
    <>
      {headerVisible && (
        <Box ref={ref}>
          <Header
            isLoading={isLoading}
            options={options}
            state={state}
            setState={setState}
            setIsDrawer={setIsDrawer}
            confirmDialog={confirmDialog}
            setConfirmDialog={setConfirmDialog}
            handleCloseConfirmDialog={handleCloseConfirmDialog}
            handleFetchEvents={handleFetchEvents}
            historyDrawer={historyDrawer}
            setHistoryDrawer={setHistoryDrawer}
            approveDrawer={approveDrawer}
            setApproveDrawer={setApproveDrawer}
            fetchApproveList={() =>
              getApproveList({
                state,
                approveDrawer,
                setApproveDrawer,
                setState,
              })
            }
          />

          {canEditPreviousPlan.isShow ? (
            <Box
              sx={{
                mx: 3,
                mb: 2,
                mt: 0,
                ml: '20px',
              }}
            >
              <Alert severity="warning">
                <AlertTitle>{canEditPreviousPlan.title}</AlertTitle>
                {canEditPreviousPlan.subTitle}
              </Alert>
            </Box>
          ) : (
            <></>
          )}
        </Box>
      )}

      <Box
        sx={
          isLoading
            ? {
                opacity: 0.4,
                pointerEvents: 'none',
                width: '100%',
                zIndex: 5,
              }
            : { width: '100%', zIndex: 5 }
        }
      >
        <>
          <CardCalendar height={height}>
            <HeaderCalendar
              calendarComponentRef={calendarComponentRef}
              setState={setState}
              state={state}
              setDialog={setDialog}
              setNewEvent={setNewEvent}
              options={options}
              setCalendarType={setCalendarType}
              calendarType={calendarType}
              setConfirmDialog={setConfirmDialog}
              setHeaderVisible={setHeaderVisible}
              headerVisible={headerVisible}
              setHeight={setHeight}
              setHistoryDrawer={setHistoryDrawer}
              handleFetchEvents={() => {
                handleFetchEvents(false)
              }}
              allowEditPlan={allowEditPlan}
            />
            <Box sx={{ mx: 2 }}>
              {calendarType === 'ALL' && (
                <Calendar
                  state={state}
                  isTest={isTest}
                  handleClick={handleClickCalendar}
                  handleSelect={handleSelectCalendar}
                  handleClickEdit={handleClickEdit}
                  calendarComponentRef={calendarComponentRef}
                  calendarType={calendarType}
                />
              )}
              {calendarType === 'PERSONAL' && (
                <Workload
                  state={state}
                  handleClick={handleClickCalendar}
                  handleClickEdit={handleClickEdit}
                  isOpenWorkload={isOpenWorkload}
                  setIsOpenWorkload={setIsOpenWorkload}
                  isShowDetail={true}
                />
              )}
            </Box>
          </CardCalendar>
          {calendarType === 'ALL' && (
            <Card sx={{ m: 3 }}>
              <Box sx={{ p: 2 }}>
                <Workload
                  state={state}
                  handleClick={handleClickCalendar}
                  handleSelect={handleSelectCalendar}
                  handleClickEdit={handleClickEdit}
                  calendarComponentRef={calendarComponentRef}
                  isOpenWorkload={isOpenWorkload}
                  setIsOpenWorkload={setIsOpenWorkload}
                />
              </Box>
            </Card>
          )}
        </>
        <DrawerAll
          open={isDrawer.status}
          setIsDrawer={setIsDrawer}
          isDrawer={isDrawer}
          setNewEvent={setNewEvent}
          state={state}
          setState={setState}
          newEvent={newEvent}
          options={options}
          setOptions={setOptions}
          handleFetchEvents={handleFetchEvents}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          fetchApproveList={() =>
            getApproveList({ state, approveDrawer, setState, setApproveDrawer })
          }
        />
        <AlertDialog
          open={dialog.open}
          setOpen={setDialog}
          handleClose={() => setDialog({ ...dialog, open: false })}
          title={dialog.title}
          content={
            <SelectWork
              setState={setState}
              state={state}
              optionsWork={options.work.filter(
                (item) => item.canCreate === true,
              )}
              optionsActivity={options.activity}
            />
          }
          variant={dialog.variant}
          onCancelClick={() => setDialog({ ...dialog, open: false })}
          onConfirmClick={() => handleConfirm({ setIsDrawer, setDialog })}
          isLoading={isLoading}
        />
        <AlertDialog
          open={confirmDialog.open}
          setOpen={setConfirmDialog}
          handleClose={handleCloseConfirmDialog}
          title={confirmDialog.title}
          variant={confirmDialog.variant}
          content={confirmDialog.content}
          onCancelClick={handleCloseConfirmDialog}
          onConfirmClick={confirmDialog.onConfirmClick}
          agreeText={confirmDialog.agreeText}
          isLoading={confirmDialog.isLoading}
        />
      </Box>
      <CircularProgress
        disableShrink
        sx={{
          position: 'absolute',
          display: isLoading ? 'unset' : 'none',
          top: '50%',
          left: '50%',
          color: 'primary.main',
        }}
      />
    </>
  )
}
export default Index

export const getCanEditPreviousPlan = (data, state) => {
  let systemConfig = data.systemConfig
  let result = { allowEdit: false, isShow: false, title: '', subTitle: '' }
  const currentDay = parseInt(dayjs().format('D'))
  const currentMonth = parseInt(dayjs().format('M'))
  const currentYear = parseInt(dayjs().format('YYYY'))

  if (systemConfig) {
    if (
      state.data.year == currentYear &&
      state.data.month + 1 == currentMonth - 1 &&
      currentDay <= systemConfig.value
    ) {
      let dateEditRemain = systemConfig.value - currentDay
      let dateCanEdit = systemConfig.value
      const currentLastDay = dayjs().endOf('month').get('date')
      if (systemConfig.value > currentLastDay) {
        dateCanEdit = currentLastDay
        dateEditRemain = currentLastDay - currentDay
      }
      result = {
        allowEdit: systemConfig.value - currentDay <= 0 ? false : true,
        isShow: true,
        title: 'กำลังจะปิดแก้ไขแผนงาน',
        subTitle: `ระบบจะปิดการแก้ไขในอีก ${dateEditRemain} วัน คือวันที่ ${dateCanEdit} ${
          MONTH_LIST[currentMonth - 1].label
        } ${currentYear}`,
        value: systemConfig.value,
      }
    } else if (
      state.data.year > currentYear ||
      (state.data.year >= currentYear && state.data.month + 1 >= currentMonth)
    ) {
      result = {
        allowEdit: true,
        isShow: false,
        title: '',
        subTitle: '',
        value: systemConfig.value,
      }
    } else {
      result = {
        allowEdit: false,
        isShow: false,
        title: 'ปิดแก้ไขแผนงาน',
        subTitle: 'ระบบปิดการแก้ไข',
        value: systemConfig.value,
      }
    }
  }
  return result
}

export const alertCanNotEditPlan = (confirmDialog, setConfirmDialog) => {
  setConfirmDialog({
    ...confirmDialog,
    open: true,
    variant: 'fail',
    title: 'ไม่สามารถแก้ไขได้',
    content: 'เนื่องจากแผนได้ถูกปิดการแก้ไขแล้ว',
    onConfirmClick: () => {
      setConfirmDialog((prev) => ({ ...prev, open: false }))
    },
    agreeText: 'ตกลง',
  })
}
