import React, { useEffect } from 'react'
import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import Close from '@mui/icons-material/Close'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import {
  StyledDrawer,
  StyledHeadLabel,
  StyledCard,
  LoadingCircular,
} from './Styled'
import {
  closeTableDrawer,
  setFieldValue,
} from '../../../../../redux/slices/inventory/dashboard'

import Table2 from '../../../../../components/redux/Table2'
import { fetchData, handleOnDownload } from './events'
import {
  resetRowsPerPage,
  resetTablePage,
  setDefaultSort,
  setOrder,
  setSearchText,
  setSelected,
} from '../../../../../redux/slices/table2'
import { StyledDownloadButton } from '../../../../../components/redux/Table/Styled'
import { Row } from '../Styled'
import FilterReqDrawer from '../FilterReqDrawer'
import { Button } from '@mui/material'
import { FilterListOutlined } from '@mui/icons-material'
import SearchTable from '../SearchTable'
import { StationFilterBox } from '../StationFilterBox'

const TableDrawer = () => {
  const dispatch = useDispatch()

  const { open, header, courseType, onDownload, filterTotal, countDrawer } =
    useSelector(
      (state) => ({
        open: state.inventoryDashboard?.tableDrawer?.isOpen,
        header: state.inventoryDashboard?.tableDrawer?.header,
        courseType: state.inventoryDashboard?.tableDrawer?.courseType,
        onDownload: state.inventoryDashboard?.tableDrawer?.onDownload,
        filterTotal: state.inventoryDashboard?.filtersReqDrawer?.filterTotal,
        countDrawer: state.crud?.countDrawer,
      }),
      shallowEqual,
    )
  const { table } = useSelector((state) => state.table2, shallowEqual)
  const { limit, page, isLoading, allCount, selected, sort, order } = table

  useEffect(() => {
    open && dispatch(fetchData(courseType, header))
    if (!open) {
      dispatch(resetRowsPerPage())
      resetTable()
    }
  }, [open, page, limit, sort, order])

  const resetTable = async () => {
    dispatch(setSearchText(''))
    // dispatch(setFilterProp(null))
    dispatch(resetTablePage())
    dispatch(setSelected([]))
    dispatch(setDefaultSort({ state: 0, id: '', active: false }))
    dispatch(setOrder({ order: 'desc', sort: 'updatedAt' }))
  }

  return (
    <StyledDrawer
      open={open}
      onClose={() => dispatch(closeTableDrawer())}
      sx={{ width: !open && '0px' }}
      courseType={courseType}
      isLoading={isLoading}
    >
      <StyledHeadLabel>
        <Box>
          <Typography variant="h5">{header}</Typography>
          <IconButton
            data-testid="btn-close-drawer"
            color="primary"
            component="span"
            onClick={() => dispatch(closeTableDrawer())}
          >
            <Close />
          </IconButton>
        </Box>
        <Divider />
      </StyledHeadLabel>
      <Row sx={{ justifyContent: 'space-between', gap: '12px' }}>
        <Box
          sx={{
            height: '34px',
            backgroundColor: '#F1F4FA',
            borderRadius: '16px',
            padding: '2px',
            width: `fit-content`,
            margin: `16px 0px 16px 22px`,
          }}
        >
          <Typography
            variant="body2b"
            fontSize="18px"
            sx={{ padding: '2px 6px', color: '#00008F' }}
          >
            จำนวน {courseType === 'inventory' ? countDrawer : allCount ?? 0}{' '}
            {courseType === 'requisition' ? 'Tickets' : 'รายการ'}
          </Typography>
        </Box>
        {courseType === 'requisition' && (
          // <Box
          //   sx={{
          //     height: '34px',
          //     backgroundColor: '#F1F4FA',
          //     borderRadius: '16px',
          //     padding: '4px',
          //     width: `fit-content`,
          //     marginRight: `auto`,
          //   }}
          // >
          //   <Typography
          //     variant="body2b"
          //     fontSize="18px"
          //     sx={{ padding: '3px 6px', color: '#00008F' }}
          //   >
          //     ทุก Station
          //   </Typography>

          // </Box>
          <StationFilterBox />
        )}
        {onDownload !== 'hidden' && courseType !== 'requisition' && (
          <StyledDownloadButton
            data-testid="btn-download"
            disabled={!selected.length > 0}
            onClick={() => handleOnDownload()}
            sx={{ margin: '16px 22px 16px 0px' }}
          >
            ดาวน์โหลด
          </StyledDownloadButton>
        )}
        {courseType === 'requisition' && (
          <>
            <SearchTable placeholder="ค้นหา" />
            <Button
              data-testid={'btn-filter'}
              sx={{ height: 40, py: 1, px: '22px' }}
              variant="text"
              startIcon={<FilterListOutlined />}
              onClick={() => {
                dispatch(
                  setFieldValue({
                    key: 'filtersReqDrawer.isOpen',
                    value: true,
                  }),
                )
              }}
            >
              ตัวกรอง {filterTotal ? `(${filterTotal})` : ''}
            </Button>
          </>
        )}
      </Row>
      <StyledCard isLoading={isLoading} sx={{ margin: '0 22px 10px 22px' }}>
        <Table2 />
      </StyledCard>
      <FilterReqDrawer />
      <Box
        sx={{
          height: '30vh',
          display: isLoading ? 'block' : 'none',
        }}
      />
      <LoadingCircular isLoading={isLoading} />
    </StyledDrawer>
  )
}

export default TableDrawer
