import {
  COLUMN_NAME,
  COLUMN_NAME_TH,
  TYPE_OF_VALUE,
} from 'src/constants/report/expense'
import _ from 'lodash'

export const generateFilterData = ({
  id,
  key,
  list,
  value,
  label,
  type = TYPE_OF_VALUE.DROPDOWN_MULTI,
  keyLabel = 'label',
  name,
}) => {
  return {
    id: id,
    key: _.get(COLUMN_NAME, `${key}`),
    title: _.get(COLUMN_NAME_TH, `${key}`),
    textTitle:
      key !== 'OLD_ACCOUNT_CODE'
        ? _.get(COLUMN_NAME_TH, `${key}`)
        : 'Account Code (Old/New)',
    value: list
      ? key === 'STAFF_NAME'
        ? list.map((a) => a?.fullName)
        : key === 'STAFF_TYPE'
        ? list.map((a) => a?.value)
        : key === 'CATEGORY'
        ? list.map((a) => a?.name)
        : list.map((a) => a?.uuid)
      : value,
    typeOfValue: type,
    label: list ? list.map((a) => _.get(a, `${keyLabel}`)) : label,
    name: name,
    oldAccLabel:
      key === 'OLD_ACCOUNT_CODE'
        ? list.map((a) => _.get(a, `${_.get(COLUMN_NAME, `${key}`)}`))
        : undefined,
  }
}
