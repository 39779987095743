import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import styled from '@mui/system/styled'
import { isSuccess } from './events'

export const StyledDialog = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginLeft: theme.spacing(3),
  marginRight: theme.spacing(2),
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
  gap: theme.spacing(2),
  width: 820,
  overflow: 'auto',
  p: { span: { color: theme?.palette?.success?.main } },
}))

export const StyledTable = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  border: '1px solid',
  borderColor: theme.palette?.blue?.blueLine,
  borderRadius: theme.spacing(1),
  width: 1300,
  maxHeight: 576,
  overflowY: 'auto',
  '::-webkit-scrollbar': { display: 'none' },
}))

export const StyledTableHead = styled(Box)(({ theme }) => ({
  height: 56,
  width: '100%',
  display: 'flex',
  borderBottom: '1px solid',
  borderColor: theme.palette?.blue?.blueLine,
  padding: theme.spacing(2),
  gap: theme.spacing(2),
  '& > .MuiTypography-root': {
    fontWeight: 500,
    fontSize: 18,
    lineHeight: '24px',
  },
}))

export const StyledTableData = styled(Box)(({ theme, status }) => ({
  minHeight: 52,
  width: '100%',
  display: 'flex',
  borderBottom: '1px solid',
  borderColor: theme.palette?.blue?.blueLine,
  padding: theme.spacing(2),
  gap: theme.spacing(2),
  background: isSuccess(status)
    ? theme?.palette?.success?.outlinedHoverBackground
    : 'transparent',
  '& > .MuiTypography-root': { fontSize: 18, lineHeight: '20px' },
  '& > #status': {
    color: isSuccess(status)
      ? theme?.palette?.success?.main
      : theme?.palette?.error?.main,
  },
}))

export const StyledMiniRow = styled(Typography)(() => ({ width: 104 }))
export const StyledMediumRow = styled(Typography)(() => ({ width: 145 }))
export const StyledLargeRow = styled(Typography)(() => ({ maxWidth: 260 }))
