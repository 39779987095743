export const arrayStatus = [
  {
    label: 'ฉบับร่าง',
    name: 'draftChecked',
  },
  {
    label: 'เปิดใช้งาน',
    name: 'activeChecked',
  },
  {
    label: 'ปิดใช้งาน',
    name: 'inActiveChecked',
  },
  {
    label: 'ถูกลบแล้ว',
    name: 'deletedChecked',
  },
]

export const headCellsHistory = [
  {
    id: 'createdBy',
    label: 'ผู้ดำเนินการ',
    disablePadding: false,
    hideSortIcon: true,
  },
  {
    id: 'event',
    label: 'รายการ',
    disablePadding: false,
    hideSortIcon: true,
  },
  {
    id: 'createdAt',
    label: 'วันเวลา',
    disablePadding: false,
    hideSortIcon: true,
  },
]
