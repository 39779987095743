import React, { useEffect, useState } from 'react'

import Breadcrumbs from '../../../../../../components/Breadcrumbs'
import { checkScrollWidth } from '../../handler/checkScrollWidth'
import { BoxHeader, BoxLabelTitle } from './Styled'
import { StyledViewTitle } from '../../../../Styled'
import { shallowEqual, useSelector } from 'react-redux'

import { useParams } from 'react-router-dom'
import { breadcrumb } from '../../model/breadcrumb'
import RemarkTooltip from '../../../../../../modules/EConfiguration/components/RemarkTooltip'
import { Box } from '@mui/material'

const Header = () => {
  const { uuid } = useParams()
  const [isScrolled, setScroll] = useState(false)
  const { eTestName, eTestVersion } = useSelector(
    (state) => ({
      eTestName: state.crud.eTestName,
      eTestVersion: state.crud.eTestVersion,
    }),
    shallowEqual,
  )
  useEffect(() => {
    window.addEventListener('scroll', () => checkScrollWidth(setScroll))
    return () => {
      window.removeEventListener('scroll', () => checkScrollWidth(setScroll))
      setScroll(false)
    }
  }, [])

  return (
    <BoxHeader isScrolled={isScrolled}>
      <BoxLabelTitle>
        <Box display="flex" alignItems="center" maxWidth="85vw">
          <StyledViewTitle variant="h4">
            {uuid && `รายละเอียดแบบทดสอบ : ${eTestName}`}
          </StyledViewTitle>
          <RemarkTooltip wrapperSx={{ mb: '15px' }} />
        </Box>
        {!isScrolled && <Breadcrumbs menuList={breadcrumb(eTestVersion)} />}
      </BoxLabelTitle>
    </BoxHeader>
  )
}

export default Header
