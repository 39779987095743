import React, { useState, useEffect } from 'react'
import { Formik, Form } from 'formik'

import Box from '@mui/material/Box'
import { useLocalStorage } from '@rehooks/local-storage'
import _ from 'lodash'

import AlertDialog from '../../components/AlertDialog'
import {
  getOptionsKey,
  convertJoinDate,
  validatePermission,
} from '../../utils/lib'

import General from './Form/GeneralFrom'
import Working from './Form/WorkingFrom'
import Contact from './Form/ContactFrom'
import Personal from './Form/PersonalFrom'
import WorkingExperience from './Form/WorkingExperienceFrom'
import Equipment from './Equipment'

const StaffProfileForm = (props) => {
  const [user] = useLocalStorage('user')
  const [stationOptions, setStationOptions] = useState([])
  const [defaultStaffSelected, setDefaultStaffSelected] = useState('')
  const [ageWork, setAgeWork] = useState('-')
  const [filterJobTitle, setFilterJobTitle] = useState([])

  const {
    setIsNonAcademy,
    IsNonAcademy,
    isEditForm,
    dialog,
    setDialog,
    handleClose,
    formik,
    isLoading,
    initialValues,
    handleUploadFile,
    theme,
    level,
    staff,
    area,
    costCenter,
    experienced,
    institute,
    productType,
    isPageLoading,
    jobTitle,
    qualificationList,
  } = props

  const [options, setOptions] = useState({
    levelOptions: [],
    staffOptions: [],
    jobTitleOptions: [],
    departmentOptions: [],
    productTypeOptions: [],
    instituteOptions: [],
    qualificationOption: [],
  })

  const [areaStationMap, setAreaStationMap] = useState(new Map())

  useEffect(() => {
    const levelOptions = getOptionsKey({
      data: level,
      key: 'uuid',
      label: 'level',
    })
    const staffOptions = getOptionsKey({
      data: staff,
      key: 'uuid',
      label: 'fullnameTH',
    })

    const jobTitleOptions = getOptionsKey({
      data: filterJobTitle,
      label: 'jobTitle',
      key: 'uuid',
    })

    const productTypeOptions = getOptionsKey({
      data: productType,
      label: 'productType',
      key: 'uuid',
    })

    const instituteOptions = getOptionsKey({
      data: institute,
      label: 'qualification',
      key: 'uuid',
    })

    const experiencedOptions = getOptionsKey({
      data: experienced,
      label: 'experienced',
      key: 'uuid',
    })

    const departmentOptions = getOptionsKey({
      data: area,
      label: 'department',
      key: 'uuid',
    })

    const qualificationOption = getOptionsKey({
      data: qualificationList,
      label: 'qualificationName',
      key: 'uuid',
    })

    setOptions({
      levelOptions,
      staffOptions,
      jobTitleOptions,
      productTypeOptions,
      instituteOptions,
      experiencedOptions,
      departmentOptions,
      qualificationOption,
    })

    const newMap = new Map()
    area.forEach((areaItem) => {
      newMap.set(
        areaItem.uuid,
        areaItem.area.map((station) => ({
          ...station,
          stationName: station.station.station,
        })),
      )
    })
    setAreaStationMap(newMap)
  }, [
    level,
    staff,
    filterJobTitle,
    area,
    jobTitle,
    productType,
    institute,
    experienced,
    qualificationList,
  ])

  const getPermissionStaffProfile = validatePermission({
    user: user,
    moduleType: 'MANAGE_PROFILE',
    permission: ['CREATE'],
  })
  const getPermissionNonAcademyProfile = validatePermission({
    user: user,
    moduleType: 'NON_ACADEMY_PROFILE_SETTING',
    permission: ['EDIT'],
  })

  useEffect(() => {
    if (isEditForm) {
      setupStationOptions()
      setAgeWork(convertJoinDate(initialValues.joinDate))
    } else if (IsNonAcademy) {
      formik.setFieldValue('joinDate', new Date().toISOString())
      formik.setFieldValue('status', 'ACTIVE')
    }
  }, [initialValues])

  useEffect(() => {
    if (IsNonAcademy) {
      if (!formik.values.joinDate)
        formik.setFieldValue('joinDate', new Date().toISOString())
      if (!formik.values.status) formik.setFieldValue('status', 'ACTIVE')
    }
  }, [IsNonAcademy])

  useEffect(() => {
    if (initialValues.level) {
      getJobTitleOptions(initialValues.level, level)
    }
  }, [initialValues, level])

  useEffect(() => {
    if (areaStationMap?.size > 0) {
      _.get(formik, 'values.area', []).forEach((_, index) =>
        onClickStation(null, index),
      )
    }
  }, [areaStationMap])

  const setupStationOptions = () => {
    initialValues.area.forEach((item, index) => {
      const stationOptions = areaStationMap.get(item.department.uuid) || []
      formik.setFieldValue(`area[${index}].stationOptions`, stationOptions)
    })
  }

  const staffTypeOptions = [
    { label: 'วิทยากรภายใน', value: 'INTERNAL' },
    { label: 'วิทยากรภายนอก', value: 'EXTERNAL' },
    { label: 'Staff', value: 'STAFF' },
  ]

  const costCenterOptions =
    costCenter &&
    costCenter.map((item) => {
      return {
        label:
          _.get(item, 'costCenter', '') +
          ' ' +
          _.get(item, 'descriptionName', ''),
        value: _.get(item, 'uuid', ''),
      }
    })

  const onChangeStation = (e, index) => {
    formik.setFieldValue(`area[${index}].uuid`, e.target.value)
  }

  const onClickStation = (e, index) => {
    const findStation = []
    area.map((item) => {
      if (item.uuid === formik.values.area?.[index]?.department?.uuid) {
        item.area.map((itemArea) => {
          if (itemArea.station?.status === 'ACTIVE') {
            itemArea.stationName = itemArea.station.station
            findStation.push(itemArea)
          }
        })
      }
    })
    const listOptions =
      findStation &&
      getOptionsKey({
        data: findStation,
        label: 'stationName',
        key: 'uuid',
      })
    const areaList = formik.values.area.map((item) => {
      return { label: item?.station?.station, value: item?.uuid }
    })

    const diff = _.differenceBy(listOptions, areaList, 'value')
    const findCurrentStation = listOptions.find(
      (item) => item.value === formik.values.area?.[index]?.uuid,
    )
    if (findCurrentStation) {
      diff.push(0, {
        label: findCurrentStation.label,
        value: findCurrentStation.value,
      })
    }
    const filterDepartment = formik.values.area.filter(
      (item, filterIndex) =>
        item?.department?.uuid ===
          formik.values.area?.[index]?.department?.uuid &&
        item?.department?.uuid !== '' &&
        filterIndex !== index,
    )

    if (filterDepartment.length === 0) {
      formik.setFieldValue(`area[${index}].stationOptions`, listOptions)
    } else {
      formik.setFieldValue(`area[${index}].stationOptions`, diff)
    }
  }

  const onChangeDepartment = (e, index) => {
    const findStation = []
    const department = {}

    area.map((item) => {
      if (item.uuid === e.target.value) {
        item.area.map((itemArea) => {
          itemArea.stationName = itemArea?.station?.station
          findStation.push(itemArea)
        })
        department.department = item.department
        department.initial = item.initial
        department.status = item.status
      }
    })
    formik.setFieldValue(`area[${index}].department`, {
      uuid: e.target.value,
      department: department.department,
      initial: department.initial,
      status: department.status,
    })
    const listOptions =
      findStation &&
      getOptionsKey({
        data: findStation,
        label: 'stationName',
        key: 'uuid',
      })
    const areaList = formik.values.area.map((item) => {
      return { label: item.station.station, value: item.uuid }
    })
    const diff = _.differenceBy(listOptions, areaList, 'value')
    const filterDepartment = formik.values.area.filter(
      (item) =>
        item.department.uuid === e.target.value && item.department.uuid !== '',
    )
    if (filterDepartment.length === 0) {
      formik.setFieldValue(`area[${index}].stationOptions`, listOptions)
    } else {
      formik.setFieldValue(`area[${index}].stationOptions`, diff)
    }
    formik.setFieldValue(`area[${index}].uuid`, '')
    setStationOptions(listOptions)
  }

  const onChangeJobTitle = (e) => {
    filterJobTitle.forEach((item) => {
      if (item.uuid === e.target.value) {
        formik.setFieldValue('jobTitles', item)
      }
    })
  }

  const onChangeStaffType = async (staffType) => {
    if (_.isEmpty(staffType)) return
    formik.setFieldValue('position', staffType)
    setDefaultStaffSelected(staffType)
  }

  const onDateChange = (value, name) => {
    if (value === null || value == 'Invalid Date') {
      formik.setFieldValue(name, null)
      return
    }

    const dateISO = new Date(value)
    if (name === 'joinDate') {
      setAgeWork(convertJoinDate(value))
    }
    formik.setFieldValue(name, dateISO.toISOString())
  }

  const getJobTitleOptions = (uuid) => {
    if (level.length === 0) return
    const defaultJobTitle = _.get(formik, 'values.jobTitles.uuid', '')
    const findLabel = level.find((levelValue) => levelValue.uuid === uuid)
    const findLevel = jobTitle.filter(
      (job) =>
        job?.level?.level === findLabel?.level || job.uuid === defaultJobTitle,
    )
    setFilterJobTitle(findLevel)
  }

  const onSelectChange = (e, key) => {
    let value = ''
    if (key === 'reportTo') {
      value = options.staffOptions.find((item) => item.value === e.target.id)
    } else if (key === 'level') {
      value = e.target.value
      getJobTitleOptions(value)
      formik.setFieldValue('jobTitles.uuid', '')
    } else {
      value = e.target.value
    }
    if (key === 'reportTo') {
      formik.setFieldValue('reportTo', value)
    } else {
      formik.setFieldValue(key, value)
    }
  }

  const onSelectPrefix = (value, key) => {
    const prefixENtoTH = {
      Mr: 'นาย',
      Ms: 'นางสาว',
      Mrs: 'นาง',
    }
    const prefixTHtoEN = {
      นาย: 'Mr',
      นางสาว: 'Ms',
      นาง: 'Mrs',
    }
    formik.setFieldValue(key, value)
    if (key === 'prefix') {
      formik.setFieldValue('prefixTH', prefixENtoTH[value])
    } else {
      formik.setFieldValue('prefix', prefixTHtoEN[value])
    }
  }

  const handleChangeTH = ({ value, key }) => {
    const regex = /^[0-9ก-๏\s\][{}~`<>|,?'";:!@#$%^&*)(+=._-]+$/g
    if (regex.test(value) || _.isEmpty(value)) {
      formik.setFieldValue(key, value)
    }
  }

  const handleChangeEN = ({ value, key }) => {
    const regex = /^[0-9a-zA-Z\s\][{}~`<>|,?'";:!@#$%^&*)(+=._-]+$/g
    if (regex.test(value) || _.isEmpty(value)) {
      formik.setFieldValue(key, value)
    }
  }

  const onSelectGroupChipChange = (list, key) => {
    let skillList = []
    if (list?.length) {
      skillList = list.map((item) => {
        return { uuid: item.value }
      })
    }
    formik.setFieldValue(key, skillList)
  }

  return (
    <>
      <Formik
        onSubmit={formik.handleSubmit}
        render={() => (
          <Form>
            <Box sx={{ display: 'flex', gap: 3, flexDirection: 'row', mx: 3 }}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                  minWidth: 275,
                  gap: 3,
                }}
              >
                <General
                  formik={formik}
                  checkPermission={
                    getPermissionStaffProfile || getPermissionNonAcademyProfile
                  }
                  handleUploadFile={handleUploadFile}
                  onSelectChange={onSelectPrefix}
                  handleChangeTH={handleChangeTH}
                  handleChangeEN={handleChangeEN}
                  staffTypeOptions={staffTypeOptions}
                  theme={theme}
                  setDialog={setDialog}
                  IsNonAcademy={IsNonAcademy}
                  setIsNonAcademy={setIsNonAcademy}
                  isEditForm={isEditForm}
                />
                {!IsNonAcademy && (
                  <Working
                    formik={formik}
                    theme={theme}
                    checkPermission={getPermissionStaffProfile}
                    onChangeDepartment={onChangeDepartment}
                    onChangeJobTitle={onChangeJobTitle}
                    onChangeStation={onChangeStation}
                    onClickStation={onClickStation}
                    onChangeStaffType={onChangeStaffType}
                    onSelectChange={onSelectChange}
                    onDateChange={onDateChange}
                    initialValues={initialValues}
                    ageWork={ageWork}
                    defaultStaffSelected={defaultStaffSelected}
                    levelOptions={options.levelOptions}
                    staffOptions={options.staffOptions}
                    stationOptions={stationOptions}
                    costCenterOptions={costCenterOptions}
                    departmentOptions={options.departmentOptions}
                    jobTitleOptions={options.jobTitleOptions}
                    isPageLoading={isPageLoading}
                  />
                )}

                <Contact
                  IsNonAcademy={IsNonAcademy}
                  formik={formik}
                  theme={theme}
                  checkPermission={
                    getPermissionStaffProfile || getPermissionNonAcademyProfile
                  }
                  onSelectChange={onSelectChange}
                />
                <Personal
                  IsNonAcademy={IsNonAcademy}
                  formik={formik}
                  theme={theme}
                  checkPermission={
                    getPermissionStaffProfile || getPermissionNonAcademyProfile
                  }
                  onDateChange={onDateChange}
                  onSelectChange={onSelectChange}
                />
                {isEditForm && !IsNonAcademy && <Equipment />}
                <WorkingExperience
                  IsNonAcademy={IsNonAcademy}
                  formik={formik}
                  theme={theme}
                  checkPermission={getPermissionStaffProfile}
                  onSelectGroupChipChange={onSelectGroupChipChange}
                  onSelectChange={onSelectChange}
                  initialValues={initialValues}
                  productTypeOptions={options.productTypeOptions}
                  experiencedOptions={options.experiencedOptions}
                  instituteOptions={options.instituteOptions}
                  institute={institute}
                  qualificationOption={options.qualificationOption}
                />
              </Box>
            </Box>
          </Form>
        )}
      />
      <AlertDialog
        open={dialog.open}
        setOpen={setDialog}
        handleClose={handleClose}
        title={dialog.title}
        content={dialog.content}
        variant={dialog.variant}
        onConfirmClick={dialog.onConfirmClick}
        onCancelClick={dialog.onCancelClick}
        isLoading={isLoading}
      />
    </>
  )
}
export default StaffProfileForm
