import React, { useState, useEffect } from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import { useParams } from 'react-router-dom'
import useTheme from '@mui/system/useTheme'
import EditIcon from '@mui/icons-material/Edit'
import Typography from '@mui/material/Typography'
import CircularProgress from '@mui/material/CircularProgress'
import RestoreTwoToneIcon from '@mui/icons-material/RestoreTwoTone'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import { useLocalStorage } from '@rehooks/local-storage'
import _ from 'lodash'

import FullView from './FullView/Index'
import HistoryDrawer from '../../components/Drawer/History'

import {
  downloadStaffProfileById,
  staffProfile,
  historyStaffProfile,
} from '../../utils/apiPath'
import { callAPI } from '../../utils/lib'
import Breadcrumbs from '../../components/Breadcrumbs'
import { validatePermission } from '../../utils/lib'
import ETesting from './ETesting'
import Footer from './Footer'
import CourseSection from '../ProspectProfile/View/Content/Course'
import ExamSection from '../ProspectProfile/View/Content/Exam'
import { useHistory } from 'react-router-dom'

export const onDownload = (uuid, setIsPageLoading) => {
  setIsPageLoading(true)
  callAPI({
    method: 'post',
    url: downloadStaffProfileById,
    body: { uuid },
    responseType: 'blob',
    onSuccess: (data) => {
      const url = window.URL.createObjectURL(new Blob([data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `staff-profile.pdf`)
      document.body.appendChild(link)
      link.click()
    },
    onFinally: () => {
      setIsPageLoading(false)
    },
  })
}

const StaffProfileView = ({ NonAcademy }) => {
  const history = useHistory()
  const url = !NonAcademy ? 'staff-profile' : 'non-academy-profile'
  const text = !NonAcademy ? 'ข้อมูลพนักงาน' : 'ข้อมูล Non Academy'
  const [user] = useLocalStorage('user')
  const { id: uuid } = useParams()
  const theme = useTheme()
  const [initialValues, setInitialValues] = useState({})
  const [isPageLoading, setIsPageLoading] = useState(true)
  const [historyList, setHistoryList] = useState([])
  const [limit, setLimit] = useState(100)
  const [page, setPage] = useState(0)
  const [isHistoryDrawer, setIsHistoryDrawer] = useState(false)
  const [allCount, setAllCount] = useState(0)
  const breadcrumbList = [
    { title: 'Manage', link: '/', pointer: false },
    {
      title: text,
      link: `/manage/${url}`,
      pointer: true,
    },
    { title: `รายละเอียด${text}`, link: '/', pointer: false },
  ]

  const getPermissionStaffProfile = validatePermission({
    user: user,
    moduleType: 'MANAGE_PROFILE',
    permission: ['CREATE'],
  })
  const getPermissionNonAcademyProfile = validatePermission({
    user: user,
    moduleType: 'NON_ACADEMY_PROFILE_SETTING',
    permission: ['EDIT'],
  })

  const fetchStaffProfilById = async (uuid) => {
    callAPI({
      method: 'get',
      url: staffProfile + `/${uuid}/view`,
      onSuccess: (data) => {
        let formatPermission = []
        const permissionGroupList = _.get(data, 'permissionGroup', [])
        if (permissionGroupList.length) {
          permissionGroupList.map((item) => {
            if (item.groupName) {
              formatPermission.push(item.groupName)
            }
          })
        }

        setInitialValues({
          ...data,
          permission: formatPermission.join(', '),
        })
      },
      onFinally: () => {
        setIsPageLoading(false)
      },
    })
  }

  const fetchHistory = async (uuid) => {
    const realPage = page === 0 ? 1 : page
    const url = `${historyStaffProfile}?limit=${limit}&page=${realPage}&uuid=${uuid}`
    await callAPI({
      url: url,
      method: 'GET',
      onSuccess: (res) => {
        setHistoryList(res.result)
        setAllCount(res.totalCount)
      },
    })
  }

  const onHistoryClick = () => {
    setIsHistoryDrawer(!isHistoryDrawer)
  }

  useEffect(() => {
    setIsPageLoading(true)
    fetchStaffProfilById(uuid)
    fetchHistory(uuid)
  }, [limit, page])

  return (
    <Box sx={isPageLoading && { pb: 2, opacity: 0.4, pointerEvents: 'none' }}>
      <Box>
        <Box
          sx={{
            ml: 3,
            justifyContent: 'space-between',
            display: 'flex',
          }}
        >
          <Typography
            variant="h4"
            sx={{
              'word-wrap': 'break-word',
              'white-space': 'pre-wrap',
              width: '75%',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            รายละเอียด{text} : {_.get(initialValues, 'firstNameTH', '') || ''}{' '}
            {_.get(initialValues, 'lastNameTH', '') || ''}
          </Typography>
        </Box>
        <Breadcrumbs sx={{ mx: 3, mb: 3 }} menuList={breadcrumbList} />
        {getPermissionStaffProfile ||
        user.uuid === initialValues.uuid ||
        NonAcademy ? (
          <Box
            sx={{
              ml: 3,
              [theme.breakpoints.up('md')]: {
                position: 'absolute',
                mt: -10,
                mr: 3,
              },
              right: 0,
              flexDirection: 'row',
              display: 'flex',
            }}
          >
            {!NonAcademy && (
              <Button
                data-testid="btn-download"
                sx={{ mr: 3 }}
                variant="outlined"
                startIcon={<FileDownloadOutlinedIcon />}
                onClick={() => onDownload(initialValues.uuid, setIsPageLoading)}
              >
                ดาวน์โหลด
              </Button>
            )}
            <Button
              data-testid="btn-history"
              sx={initialValues?.status !== 'DELETED' ? { mr: 3 } : {}}
              startIcon={<RestoreTwoToneIcon />}
              variant="outlined"
              onClick={onHistoryClick}
            >
              ประวัติการแก้ไข
            </Button>
            {(!NonAcademy || getPermissionNonAcademyProfile) && (
              <Button
                data-testid="btn-edit"
                startIcon={<EditIcon />}
                variant="contained"
                onClick={() => {
                  history.push(`/manage/${url}/edit/` + initialValues.uuid)
                }}
              >
                แก้ไข
              </Button>
            )}
          </Box>
        ) : (
          <></>
        )}
      </Box>

      <Box sx={{ m: 3, display: 'flex', flexDirection: 'column', gap: '24px' }}>
        <FullView
          NonAcademy={NonAcademy}
          initialValues={initialValues}
          user={user}
          checkPermission={getPermissionStaffProfile}
          isPageLoading={isPageLoading}
        />
        <CourseSection type="NON_ACADEMY" />
        <ExamSection type="NON_ACADEMY" />
        {initialValues?.user?.uuid && (
          <ETesting userId={initialValues?.user?.uuid} />
        )}
        <Footer historyList={historyList} />
      </Box>

      <CircularProgress
        disableShrink
        sx={{
          position: 'absolute',
          display: isPageLoading ? 'unset' : 'none',
          top: '50%',
          left: '50%',
          color: 'primary.main',
        }}
      />
      <HistoryDrawer
        open={isHistoryDrawer}
        onClose={() => setIsHistoryDrawer(false)}
        historyList={historyList}
        page={page}
        setPage={setPage}
        limit={limit}
        setLimit={setLimit}
        allCount={allCount}
      />
    </Box>
  )
}
export default StaffProfileView
