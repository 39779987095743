import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import DeleteTwoTone from '@mui/icons-material/DeleteTwoTone'
import EditTwoTone from '@mui/icons-material/EditTwoTone'
import RemoveRedEyeTwoTone from '@mui/icons-material/RemoveRedEyeTwoTone'
import PersonSearchIcon from '@mui/icons-material/PersonSearch'

import IconButton from '@mui/material/IconButton'

import { StyledBox } from '../Styled'
import {
  setOnEdit,
  setOnView,
  setSearchText,
} from '../../../../../redux/slices/table'
import {
  setIsOpenExaminerFilterDrawer,
  setSelectedExaminer,
} from '../../../../../redux/slices/eExamination/list'

const Action = ({ row, rowIndex, minWidth, condition }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { onDelete } = useSelector((state) => state.table)
  const viewUrl = `${condition.viewPath}/${row.uuid}`
  const editUrl = `${condition.editPath}/${row.uuid}`
  const examinerUrl = `${condition.examinerPath}/${row.uuid}`
  const handleEditExaminer = async () => {
    dispatch(setIsOpenExaminerFilterDrawer(true))
    dispatch(setSelectedExaminer(row.uuid))
  }
  return (
    <StyledBox minWidth={minWidth}>
      {!condition.hideView && (
        <IconButton
          data-testid={`btn-view-${rowIndex}`}
          color="primary"
          component="span"
          onClick={() => dispatch(setOnView({ history, viewUrl }))}
        >
          <RemoveRedEyeTwoTone color="action" />
        </IconButton>
      )}

      {!condition.hideEdit &&
        row.status !== 'INACTIVE' &&
        row.userProfile?.status !== 'DELETED' &&
        !condition.isExaminer && (
          <IconButton
            data-testid={`btn-edit-${rowIndex}`}
            color="primary"
            component="span"
            onClick={() => dispatch(setOnEdit({ history, editUrl }))}
          >
            <EditTwoTone color="action" />
          </IconButton>
        )}
      {!condition.hideEdit &&
        row.status !== 'INACTIVE' &&
        row.userProfile?.status !== 'DELETED' &&
        condition.isExaminer && (
          <IconButton
            data-testid={`btn-edit-${rowIndex}`}
            color="primary"
            component="span"
            onClick={handleEditExaminer}
          >
            <EditTwoTone color="action" />
          </IconButton>
        )}

      {!condition.hideView && condition.examinerPath && (
        <IconButton
          data-testid={`btn-examiner-${rowIndex}`}
          color="primary"
          component="span"
          onClick={() => {
            dispatch(setSearchText(''))
            history.push(examinerUrl)
          }}
        >
          <PersonSearchIcon color="action" />
        </IconButton>
      )}

      {!condition.hideDelete &&
        row.status !== 'INACTIVE' &&
        row.userProfile?.status !== 'DELETED' && (
          <IconButton
            data-testid={`btn-delete-${rowIndex}`}
            color="primary"
            component="span"
            onClick={() => onDelete(row, rowIndex)}
          >
            <DeleteTwoTone color="action" />
          </IconButton>
        )}
    </StyledBox>
  )
}

export default Action
