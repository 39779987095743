import { Box, Typography } from '@mui/material'
import React from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import { ChevronRight } from '@mui/icons-material'
import { StyledButton } from '../Styled'
import ModuleCard from '../ModuleCard/ModuleCard'
// import { handleClickClassDrawer } from '../../handler/handleClickClassDrawer'
// import { overviewEnum } from '../../enums/overviewEnum'
import { shallowEqual, useSelector } from 'react-redux'
import _ from 'lodash'
import { handleClickOpenDrawer } from '../../handler/handleClickOpenDrawer'
import { overviewDataEnum } from '../../enums/drawerEnum'
import RemarkTooltip from '../../../../../modules/EConfiguration/components/RemarkTooltip'

const index = () => {
  const { overviewDataCount } = useSelector(
    (state) => ({
      overviewDataCount: state.crud?.eExamDashboard?.overviewDataCount,
    }),
    shallowEqual,
  )
  const overviewsDataDemo = [
    {
      label: 'สนามสอบบริษัททั้งหมด',
      labelVariant: 'body2',
      background: '',
      dotColor: '',
      number: _.get(overviewDataCount, overviewDataEnum.EXAM_STATION, 0),
      unit: 'สนาม',
    },
    {
      label: 'สนามสอบสมาคมทั้งหมด',
      labelVariant: 'body2',
      background: '',
      dotColor: '',
      number: _.get(overviewDataCount, overviewDataEnum.EXAM_ASSOCIATION, 0),
      unit: 'สนาม',
    },
  ]
  return (
    <Box display="flex">
      <Card sx={{ minWidth: 275 }}>
        <CardContent sx={{ position: 'relative' }}>
          <Box display="flex" alignItems="center" gap={2}>
            <Typography variant="h6">
              สนามสอบ
              <RemarkTooltip />
            </Typography>
            <StyledButton
              color="text.lightGray"
              size="small"
              background="#4976BA14"
              marginLeft={''}
              onClick={() => handleClickOpenDrawer('examinationField')}
            >
              <ChevronRight fontSize="small" />
            </StyledButton>
          </Box>

          <Box>
            <ModuleCard
              label={'ทั้งหมด'}
              labelVariant={'body2'}
              background={''}
              dotColor={''}
              value={_.get(
                overviewDataCount,
                overviewDataEnum.TOTAL_EXAM_FIELD,
                0,
              )}
              unit={'สนาม'}
              percent={``}
              border="none"
              boxSx={{
                padding: 0,
                mt: 2,
              }}
            />
          </Box>

          <Box display="flex" gap={2} mt={3}>
            {_.defaultTo(overviewsDataDemo, []).map((ele, index) => {
              return (
                <ModuleCard
                  key={index}
                  label={ele.label}
                  labelVariant={'body2'}
                  background={ele.background}
                  dotColor={ele.dotColor}
                  value={ele.number}
                  unit={ele.unit}
                  percent={``}
                />
              )
            })}
          </Box>
        </CardContent>
      </Card>
      <Box position={'relative'}>
        <img
          width={200}
          height={200}
          src="/image/pie_chart_dashbord.svg"
          style={{
            position: 'absolute',
            bottom: '-15%',
          }}
        />
      </Box>
    </Box>
  )
}

export default index
