import React, { useEffect, useState, useContext } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card'
import { useHistory } from 'react-router-dom'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import AddIcon from '@mui/icons-material/Add'
import { useLocalStorage } from '@rehooks/local-storage'
import useTheme from '@mui/system/useTheme'
import { DialogContext } from '../../context/dialog-context'
import Table from '../../components/Table'
import FilterDrawer from './FilterDrawer'
import Breadcrumbs from '../../components/Breadcrumbs'
import {
  levelOfLearner,
  downloadLevelOfLearner,
  filterLevelOfLearner,
  distributionFilter,
} from '../../utils/apiPath'
import {
  callAPI,
  handleDownload,
  hasPermissionCreateMasterData,
} from '../../utils/lib'
import { handleFilterTotal } from '../../redux/slices/table/events'

export const headCells = [
  {
    id: 'status',
    label: '',
    width: '20px',
    hideSortIcon: true,
  },
  {
    id: 'id',
    disablePadding: false,
    label: 'ID',
    width: '80px',
  },
  {
    id: 'title',
    label: 'ระดับของผู้เรียน',
  },
  {
    id: 'action',
    disablePadding: false,
    label: 'คำสั่ง',
    width: '90px',
    hideSortIcon: true,
    deletePermission: 'DELETE',
    deleteModuleType: 'MASTER_DATA',
  },
]

export const breadcrumbList = [
  { title: 'Master Data', link: '/', pointer: false },
  { title: 'ระดับของผู้เรียน', link: '/', pointer: false },
]

export const handleAddClick = (history) => {
  history.push('/master-data/level-of-learner/form')
}

export const onView = (row, history) => {
  history.push('/master-data/level-of-learner/' + row.uuid)
}
export const onEdit = (row, history) => {
  history.push('/master-data/level-of-learner/edit/' + row.uuid)
}

export const onDelete = async (
  row,
  i,
  setIsLoading,
  setDialog,
  handleDelete,
  handleDeleteSuccess,
  setDataList,
  setAllCount,
  dataList,
  allCount,
) => {
  const body = {
    abbreviation: '',
    distribution: '',
    level: [row.uuid],
    limit: -1,
    page: 1,
    order: 'ASC',
    sort: 'updatedAt',
    status: [],
  }
  setIsLoading(true)
  callAPI({
    url: distributionFilter,
    method: 'post',
    body: body,
    onSuccess: (res) => {
      handleDeleteSuccess(
        setIsLoading,
        res,
        setDialog,
        handleDelete,
        row,
        i,
        setDataList,
        setAllCount,
        dataList,
        allCount,
      )
    },
  })
}

export const handleDeleteSuccess = (
  setIsLoading,
  res,
  setDialog,
  handleDelete,
  row,
) => {
  setIsLoading(false)
  const result = res.result
  let concatText = ''
  if (result.length > 0) {
    result.map((item) => {
      concatText += item.distribution + ', '
    })

    concatText = concatText.substring(0, concatText.length - 2)
    setDialog({
      content: `ระดับของผู้เรียนนี้ เกี่ยวข้องกับ ช่องทางการจำหน่าย : ${concatText} จะถูกลบข้อมูลของระดับของผู้เรียนนี้ออกเช่นกัน คุณต้องการลบรายการนี้หรือไม่`,
      variant: 'delete',
      open: true,
      onConfirmClick: () => handleDelete(row, setIsLoading, setDialog),
      onCancelClick: () =>
        setDialog({
          variant: 'delete',
          content: ' ',
          open: false,
        }),
    })
  } else {
    setDialog({
      content: 'คุณต้องการลบรายการนี้หรือไม่',
      variant: 'delete',
      open: true,
      onConfirmClick: () => handleDelete(row, setIsLoading, setDialog),
      onCancelClick: () =>
        setDialog({
          variant: 'delete',
          content: 'คุณต้องการลบรายการนี้หรือไม่',
          open: false,
        }),
    })
  }
}

export const handleDelete = async (row, setIsLoading, setDialog) => {
  const path = levelOfLearner + `/${row.uuid}`
  setIsLoading(true)
  callAPI({
    url: path,
    method: 'delete',
    onSuccess: () => {
      setDialog({
        variant: 'success',
        content: 'ถูกลบแล้ว',
        open: true,
        isLoading: false,
        onConfirmClick: () => window.location.reload(),
      })
    },
    onFinally: () => {
      setIsLoading(false)
    },
  })
}

export const TableLevel = ({
  data,
  headCells,
  onView,
  onEdit,
  onDelete,
  setLimit,
  order,
  setOrder,
  page,
  setPage,
  sort,
  setSort,
  allCount,
  handleQuickSearch,
  onDownload,
  setIsFilterDrawer,
  searchText,
  setSearchText,
  tablePage,
  setTablePage,
  text,
  setText,
  setIsLoading,
  setDialog,
  handleDelete,
  handleDeleteSuccess,
  setDataList,
  setAllCount,
  filterTotal,
}) => {
  const history = useHistory()
  return (
    <Card sx={{ minWidth: 275, mx: 3, mt: 3 }}>
      <Table
        data={data}
        headCells={headCells}
        onView={(row) => onView(row, history)}
        onEdit={(row) => onEdit(row, history)}
        onDelete={(row, i) => {
          onDelete(
            row,
            i,
            setIsLoading,
            setDialog,
            handleDelete,
            handleDeleteSuccess,
            setDataList,
            setAllCount,
            data,
            allCount,
          )
        }}
        placeholder="ค้นหาระดับของผู้เรียน"
        searchKey={'title'}
        setLimit={setLimit}
        order={order}
        setOrder={setOrder}
        page={page}
        setPage={setPage}
        sort={sort}
        setSort={setSort}
        allCount={allCount}
        handleSearch={handleQuickSearch}
        onDownload={onDownload}
        setIsFilterDrawer={setIsFilterDrawer}
        searchText={searchText}
        setSearchText={setSearchText}
        tablePage={tablePage}
        setTablePage={setTablePage}
        text={text}
        setText={setText}
        filterTotal={filterTotal}
      />
    </Card>
  )
}
const LevelOfLearnerList = () => {
  const theme = useTheme()
  const [user] = useLocalStorage('user')
  const history = useHistory()
  const hasPermissionCreate = hasPermissionCreateMasterData({ user })
  const [isLoading, setIsLoading] = useState(true)
  const [limit, setLimit] = useState('100')
  const [order, setOrder] = useState('DESC')
  const [page, setPage] = useState(-1)
  const [sort, setSort] = useState('')
  const [dataList, setDataList] = useState([])
  const [allCount, setAllCount] = useState(0)
  const [searchText, setSearchText] = useState('')
  const [text, setText] = useState('')
  const [status, setStatus] = useState([])
  const [isFilterDrawer, setIsFilterDrawer] = useState(false)
  const [tablePage, setTablePage] = useState(0)
  const [filterTotal, setFilterTotal] = useState(0)
  const { setContext: setDialog } = useContext(DialogContext)

  useEffect(() => {
    fetchDataList('fetch', status, limit, order, page, sort)
  }, [limit, order, sort, page])

  const onFilterClick = async (filter) => {
    setSearchText('')
    setText('')
    const statusList = []
    if (filter.statusChecked) {
      if (filter.status.activeChecked) {
        statusList.push('ACTIVE')
      }
      if (filter.status.inActiveChecked) {
        statusList.push('INACTIVE')
      }
      if (filter.status.deletedChecked) {
        statusList.push('DELETED')
      }
    }
    setStatus(statusList)
    const levelOfLearnerText = filter?.levelOfLearnerChecked
      ? filter.levelOfLearnerText
      : ''
    const createdBy = filter?.createdByChecked ? filter.createdBy : ''
    const updatedBy = filter?.updatedByChecked ? filter.updatedBy : ''
    setSearchText(levelOfLearnerText)
    setTablePage(0)
    fetchDataList(
      'filter',
      statusList,
      limit,
      order,
      1,
      sort,
      levelOfLearnerText,
      createdBy,
      updatedBy,
    )
    setFilterTotal(handleFilterTotal(filter))
  }

  const onDownload = async (selected) => {
    setIsLoading(true)
    const body = {
      title: '',
      order: order.toUpperCase(),
      sort: sort === '' ? 'updatedAt' : sort,
      status: [],
      type: 'CUSTOM',
      list: selected,
      createdBy: '',
      updatedBy: '',
    }
    await handleDownload({
      url: downloadLevelOfLearner,
      body: body,
      fileName: `รายการระดับของผู้เรียน.xlsx`,
    })
    setIsLoading(false)
  }

  const handleQuickSearch = async (text) => {
    setTablePage(0)
    const statusList = []
    fetchDataList('search', statusList, limit, order, 1, sort, text)
  }

  const fetchDataList = async (
    method,
    statusList,
    limit,
    order,
    page,
    sort,
    search,
    createdBy,
    updatedBy,
  ) => {
    const sortBy = sort === '' ? 'updatedAt' : sort
    const realPage = page <= 0 ? 1 : +page
    setIsLoading(true)
    const body = {
      limit: limit,
      page: realPage,
      order: order.toString().toUpperCase(),
      sort: sortBy,
      title: method !== 'fetch' ? search : searchText,
      status: statusList,
      createdBy: method === 'filter' ? createdBy : '',
      updatedBy: method === 'filter' ? updatedBy : '',
    }
    callAPI({
      method: 'post',
      url: filterLevelOfLearner,
      body: body,
      onSuccess: (data) => {
        setDataList(data.result)
        setAllCount(data.totalCount)
      },
      onFinally: () => {
        setIsLoading(false)
      },
    })
  }

  return (
    <Box sx={{ pb: 2 }}>
      <Box sx={isLoading && { opacity: 0.4, pointerEvents: 'none' }}>
        <Box
          sx={{
            mx: 3,
            justifyContent: 'space-between',
            display: 'flex',
          }}
        >
          <Typography variant="h4">
            ระดับของผู้เรียน (Level of Learner)
          </Typography>
        </Box>
        <Breadcrumbs sx={{ mx: 3, mb: 3 }} menuList={breadcrumbList} />
        <Button
          data-testid={`btn-add-level`}
          sx={{
            ml: 3,
            [theme.breakpoints.up('md')]: {
              position: 'absolute',
              mt: -10,
              mr: 3,
            },
            right: 0,
          }}
          disabled={!hasPermissionCreate}
          startIcon={<AddIcon />}
          variant="contained"
          onClick={() => handleAddClick(history)}
        >
          เพิ่มระดับของผู้เรียน
        </Button>
        <TableLevel
          data={dataList}
          headCells={headCells}
          onView={(row) => onView(row, history)}
          onEdit={(row) => onEdit(row, history)}
          onDelete={onDelete}
          setLimit={setLimit}
          order={order}
          setOrder={setOrder}
          page={page}
          setPage={setPage}
          sort={sort}
          setSort={setSort}
          allCount={allCount}
          handleQuickSearch={handleQuickSearch}
          onDownload={onDownload}
          setIsFilterDrawer={setIsFilterDrawer}
          searchText={searchText}
          setSearchText={setSearchText}
          tablePage={tablePage}
          setTablePage={setTablePage}
          text={text}
          setText={setText}
          setIsLoading={setIsLoading}
          setDialog={setDialog}
          handleDelete={handleDelete}
          handleDeleteSuccess={handleDeleteSuccess}
          setDataList={setDataList}
          setAllCount={setAllCount}
          filterTotal={filterTotal}
        />
      </Box>
      <CircularProgress
        disableShrink
        sx={{
          position: 'absolute',
          display: isLoading ? 'unset' : 'none',
          top: '50%',
          left: '50%',
          color: 'primary.main',
        }}
      />
      <FilterDrawer
        open={isFilterDrawer}
        onClose={() => setIsFilterDrawer(false)}
        onFilterClick={onFilterClick}
      />
    </Box>
  )
}
export default LevelOfLearnerList
