import { store } from '../../../../App'
import { handleCheckStatusReportUpdated } from '../../../../components/Report/handler/handleCheckStatusReportUpdated'
import { setFieldValue } from '../../../../redux/slices/crud'

export const checkUpdateStatus = async () => {
  store.dispatch(
    setFieldValue({
      key: 'isLoading',
      value: true,
    }),
  )
  const res = await handleCheckStatusReportUpdated('REPORT_MANAGE_PROFILE')
  store.dispatch(
    setFieldValue({
      key: 'manageProfileDashboard.updateStatus',
      value: res?.status,
    }),
  )
  store.dispatch(
    setFieldValue({
      key: 'manageProfileDashboard.latestUpdated',
      value: res?.latestUpdated,
    }),
  )
}
