export const breadcrumbList = [
  { title: 'Configuration', link: '/', pointer: false },
]

export const eConfigTabEnum = {
  GENERAL: 'GENERAL',
  E_LEARNING: 'E_LEARNING',
  EEXAM: 'EEXAM',
  TERMS: 'TERMS',
  SLA_TODO_LIST: 'SLA_TODO_LIST',
}

export const configTabs = [
  { label: 'หลักสูตรทั่วไป', key: 'GENERAL' },
  { label: 'หลักสูตรออนไลน์ (E-Learning)', key: 'E_LEARNING' },
  { label: 'E-Exam', key: 'EEXAM' },
  { label: 'เงื่อนไขและข้อตกลง', key: 'TERMS' },
  { label: 'SLA Todo List & Notification', key: 'SLA_TODO_LIST' },
]

export const FileTypes = [
  { label: 'MB', value: 'MB' },
  { label: 'GB', value: 'GB' },
]

export const SLA_TODO_LIST_MONTHLY_PLAN = [
  {
    title: 'อนุมัติแผนรายเดือน',
    name: 'SLA_MONTHLY_PLAN_APPROVE_MASTER_PLAN',
    labelText: 'แจ้งเตือนเมื่อเกินกำหนด',
    subText: '**นับจาก Head Zone Submit แผนรายเดือน',
  },
  {
    title: 'อนุมัติแผนงาน',
    name: 'SLA_MONTHLY_PLAN_APPROVE_PLAN',
    labelText: 'แจ้งเตือนเมื่อเกินกำหนด',
    subText:
      '**นับจาก Head Zone แก้ไขงานหรือสร้างงานใหม่ในหลักสูตรที่ต้องขออนุมัติ',
  },
  {
    title: 'ไม่อนุมัติแผนรายเดือน',
    name: 'SLA_MONTHLY_PLAN_NOT_APPROVE_MASTER_PLAN',
    labelText: 'แจ้งเตือนเมื่อเกินกำหนด',
    subText: '**นับจาก Head Field ไม่อนุมัติแผนรายเดือน',
  },
  {
    title: 'ไม่อนุมัติแผนงาน',
    name: 'SLA_MONTHLY_PLAN_REJECT_APPROVE_PLAN',
    labelText: 'แจ้งเตือนเมื่อเกินกำหนด',
    subText: '**นับจาก Head Field ไม่อนุมัติแผนงาน',
  },
  {
    title: 'คำของบ break/lunch ถูกปฏิเสธ',
    name: 'SLA_MONTHLY_PLAN_REJECT_BUDGET',
    labelText: 'แจ้งเตือนเมื่อเกินกำหนด',
    subText: '**นับจาก Head Field ไม่อนุมัติงบ',
  },
  {
    title: 'อนุมัติคำขอยืมวิทยากรโซนอื่น',
    name: 'SLA_MONTHLY_PLAN_APPROVE_SPEAKER_BORROW',
    labelText: 'แจ้งเตือนเมื่อเกินกำหนด',
    subText: '**นับจาก Head Zone อื่นส่งคำขอยืมวิทยากรเพื่อไปสอนข้ามพื้นที่',
  },
  {
    title: 'ส่งแผนรายเดือน',
    name: 'SLA_MONTHLY_PLAN_SEND_APPROVE_MASTER_PLAN',
    labelText: 'ทุกวันที่',
    placeholder: 'Ex. 1 - 30',
    unit: 'ของทุกเดือน  *เดือนที่มีน้อยกว่า 30 จะส่งวันสุดท้ายของเดือน',
    subText: '',
  },
]

export const SLA_TODO_LIST_MANAGE_CLASS = [
  {
    title: 'ข้อสอบ Assessment',
    name: 'SLA_MANAGE_CLASS_E_TEST_ASSESSMENT',
    labelText: 'แจ้งเตือนเมื่อเกินกำหนด',
    subText: '**นับจาก Head Zone Submit แผนรายเดือน',
  },
  {
    title: 'ตรวจข้อสอบ',
    name: 'SLA_MANAGE_CLASS_E_TEST_CHECK',
    labelText: 'แจ้งเตือนเมื่อเกินกำหนด',
    subText:
      '**นับจาก ในวันแรกของการอบรม ระบบจะส่ง Todo List ข้อสอบ Assessment หาวิทยากรที่เกี่ยวข้อง ในช่วงเวลา 01:00 น. แบบอัตโนมัติ',
  },
  {
    title: 'ปิดคลาส',
    name: 'SLA_MANAGE_CLASS_CLOSE_CLASS',
    labelText: 'แจ้งเตือนเมื่อเกินกำหนด',
    subText:
      '**นับจาก หลังวันที่อบรมวันสุดท้าย 1 วัน  เวลา 01:00 น. ระบบจะทำการส่ง Todo List ปิดคลาส ไปหา Trainer ที่เกี่ยวข้องแบบอัตโนมัติ',
  },
  {
    title: 'ประกาศรายชื่อผู้มีสิทธิ์เรียน',
    name: 'SLA_MANAGE_CLASS_ANNOUNCE_LEARNER',
    labelText: 'แจ้งเตือนเมื่อเกินกำหนด',
    subText: '**นับจาก จาก HF อนุมัติแผนงาน',
  },
  {
    title: 'ตั้งค่าคลาส',
    name: 'SLA_MANAGE_CLASS_SETTING',
    labelText: 'แจ้งเตือนเมื่อเกินกำหนด',
    subText: '**นับจาก จาก HF อนุมัติแผนงาน',
  },
  {
    title: 'ตรวจเอกสาร',
    name: 'SLA_MANAGE_CLASS_CHECK_DOCUMENT',
    labelText: 'แจ้งเตือนเมื่อเกินกำหนด',
    subText: '**วันนับจาก ตัวแทนส่งเอกสารมากลับมา',
  },
]

export const SLA_TODO_LIST_E_TEST = [
  {
    title: 'ตรวจข้อสอบ Standalone',
    name: 'SLA_E_TEST_CHECK_STANDALONE',
    labelText: 'แจ้งเตือนเมื่อเกินกำหนด',
    subText:
      '**วันนับจาก ทุกวันเวลา 1.00 น. ระบบ จะหาว่ามี ข้อสอบแบบ Standalone รอตรวจสอบหรือไม่ถ้ามีจะส่ง Event หา trainer ที่ข้องแบบอัตโนมัติ',
  },
]
