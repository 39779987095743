import { CarHeader } from './CardHeader'
import { StyledCard, StyledColumn, StyledRow } from '../../../Styled'
import TextInput from '../../../../../components/Input/TextInput'
import Select from '../../../../../components/Input/Select'
import { TextField, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { getErrorMessage, handleChange } from '../../event'
import _ from 'lodash'
import { academyOptions } from '../../../../../constants/roomManagement'
import Autocomplete from '../../../../../components/Input/Autocomplete'
import {
  changeBookingOperator,
  changeToAcademy,
  changeToNonAcademy,
  setBookingBy,
} from '../../../../../redux/slices/roomManagement/Drawer'
import { enumRequestType } from '../../../../../modules/RoomManagement/View/Content/CalendarCard/constanst'

export const InformationOperatorCard = () => {
  const [isShowContent, setIsShowContent] = useState(true)
  const dispatch = useDispatch()
  const {
    academy,
    bookForOther,
    bookByName,
    remark,
    staffList,
    mobileNoOther,
    emailOther,
    bookForUuid,
    formError,
    bookForName,
    requestType,
  } = useSelector(
    (state) => ({
      stationList: state.roomManagement.stationList,
      academy: state.roomManagementDrawer.bookingInformation.academy,
      bookForUuid: state.roomManagementDrawer.bookingInformation.bookForUuid,
      bookForName: state.roomManagementDrawer.bookingInformation.bookForName,
      bookForOther: state.roomManagementDrawer.bookingInformation.bookForOther,
      bookByName: state.roomManagementDrawer.bookingInformation.bookByName,
      remark: state.roomManagementDrawer.bookingInformation.remark,
      staffList: state.roomManagementDrawer.staffList,
      mobileNoOther:
        state.roomManagementDrawer.bookingInformation.mobileNoOther,
      emailOther: state.roomManagementDrawer.bookingInformation.emailOther,
      formError: state.roomManagementDrawer.formError,
      requestType: state.roomManagementDrawer.bookingInformation.requestType,
    }),
    shallowEqual,
  )
  const user = localStorage.getItem('user')
  const objUser = JSON.parse(user)
  const isMonthlyPlan = requestType === enumRequestType.MONTHLY_PLAN

  useEffect(() => {
    if (_.isEmpty(bookByName)) {
      const defaultAreaUuid = _.get(objUser, 'defaultArea', '')
      const defaultArea = _.find(
        _.get(objUser, 'area', []),
        (ele) => ele?.uuid === defaultAreaUuid,
      )
      const stationNameBy = _.get(defaultArea, 'station.station', '')
      const firstNameTHBy = _.get(objUser, 'firstNameTH', '')
      const lastNameTHBy = _.get(objUser, 'lastNameTH', '')
      const bookByNameLocal = `${stationNameBy} - ${firstNameTHBy} ${lastNameTHBy}`

      dispatch(
        setBookingBy({
          bookByName: bookByNameLocal,
        }),
      )
    }
  }, [])

  return (
    <StyledCard sx={{ display: 'flex', flexDirection: 'column', gap: 3, p: 3 }}>
      <CarHeader
        no={3}
        title="ข้อมูลผู้จอง"
        isShowContent={isShowContent}
        setIsShowContent={setIsShowContent}
      />
      {isShowContent && (
        <StyledColumn>
          <StyledRow sx={{ gap: 3, alignItems: 'stretch' }}>
            {!isMonthlyPlan && (
              <Select
                boxSx={{ width: 200 }}
                dataTestid="select-academy"
                id="academy"
                name="academy"
                required
                labelText={'หน่วยงาน'}
                placeholder="กรุณาเลือก"
                options={academyOptions}
                value={academy}
                handleChange={(e) => {
                  const value = _.get(e, 'target.value', '')
                  dispatch(
                    value === 'ACADEMY'
                      ? changeToAcademy()
                      : changeToNonAcademy(),
                  )
                }}
              />
            )}
            {academy === 'NON_ACADEMY' ? (
              <Autocomplete
                name="bookForOther"
                labelText={isMonthlyPlan ? 'ผู้สร้างแผน' : 'จองให้'}
                required
                options={[
                  ..._.filter(
                    staffList,
                    (item) => item.type === 'NON_ACADEMY',
                  ).map((item) => ({
                    label:
                      item.agentCode +
                      ' ' +
                      item?.firstNameTH +
                      ' ' +
                      item?.lastNameTH,
                    value: item.uuid,
                  })),
                ]}
                defaultValue={{ value: bookForOther, label: bookForName }}
                value={bookForOther}
                renderInput={(params) => (
                  <TextField
                    placeholder="กรุณาเลือก"
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      'data-testid': 'select-staff',
                    }}
                  />
                )}
                handleChange={(e, value) => {
                  const uuid = _.get(value, 'value', '')
                  const staffSelected = _.find(
                    staffList,
                    (staff) => staff.uuid === uuid,
                  )

                  const mobile = _.get(staffSelected, 'tel', '')
                  const email = _.get(staffSelected, 'email', '')

                  const agentCode = _.get(staffSelected, 'agentCode', '')
                  const firstNameTHBookForFor = _.get(
                    staffSelected,
                    'firstNameTH',
                    '',
                  )
                  const lastNameTHBookForFor = _.get(
                    staffSelected,
                    'lastNameTH',
                    '',
                  )

                  const bookForName = staffSelected
                    ? `${agentCode} - ${firstNameTHBookForFor} ${lastNameTHBookForFor}`
                    : _.get(value, 'label', '')

                  dispatch(
                    changeBookingOperator({
                      bookForUuid: uuid,
                      mobileNoOther: !_.isEmpty(mobile) ? mobile : '',
                      emailOther: email,
                      bookForName,
                    }),
                  )
                }}
              />
            ) : (
              <Autocomplete
                name="bookForUuid"
                labelText={isMonthlyPlan ? 'ผู้สร้างแผน' : 'จองให้'}
                required
                options={[
                  ..._.filter(staffList, (item) => item.type === 'STAFF').map(
                    (item) => ({
                      label: item.label,
                      value: item.uuid,
                    }),
                  ),
                ]}
                defaultValue={{ value: bookForUuid, label: bookForName }}
                value={bookForUuid}
                disabled={isMonthlyPlan}
                renderInput={(params) => (
                  <TextField
                    placeholder="กรุณาเลือก"
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      'data-testid': 'select-staff',
                    }}
                  />
                )}
                handleChange={(e, value) => {
                  const uuid = _.get(value, 'value', '')
                  const staffSelected = _.find(
                    staffList,
                    (staff) => staff?.uuid === uuid,
                  )

                  const mobile = _.get(staffSelected, 'tel', '')
                  const email = _.get(staffSelected, 'email', '')

                  const stationNameFor = _.get(
                    staffSelected,
                    'area[0].station.station',
                    '',
                  )
                  const firstNameTHBookForFor = _.get(
                    staffSelected,
                    'firstNameTH',
                    '',
                  )
                  const lastNameTHBookForFor = _.get(
                    staffSelected,
                    'lastNameTH',
                    '',
                  )

                  const bookForName = staffSelected
                    ? `${stationNameFor} - ${firstNameTHBookForFor} ${lastNameTHBookForFor}`
                    : _.get(value, 'label', '')

                  dispatch(
                    changeBookingOperator({
                      bookForUuid: uuid,
                      mobileNoOther: mobile,
                      emailOther: email,
                      bookForName,
                    }),
                  )
                }}
              />
            )}
          </StyledRow>
          <StyledRow sx={{ gap: 3, alignItems: 'stretch' }}>
            <TextInput
              boxSx={{ width: 200 }}
              sxTextError={{ mb: 3 }}
              type="basic"
              name="mobileNoOther"
              required
              disabled={academy === 'ACADEMY'}
              labelText={'เบอร์โทร'}
              placeholder={'กรุณากรอกเบอร์โทร'}
              value={mobileNoOther}
              textError={getErrorMessage(formError, 'mobileNoOther')}
              onChange={(e) => {
                const value = _.get(e, 'target.value', '')
                dispatch(
                  handleChange({
                    key: 'mobileNoOther',
                    value: value,
                  }),
                )
              }}
            />
            <TextInput
              sxTextError={{ mb: 3 }}
              type="basic"
              name="emailOther"
              required
              disabled={academy === 'ACADEMY'}
              labelText={'Email'}
              placeholder={'กรุณากรอก Email'}
              value={emailOther}
              textError={getErrorMessage(formError, 'emailOther')}
              onChange={(e) => {
                const value = _.get(e, 'target.value', '')
                dispatch(
                  handleChange({
                    key: 'emailOther',
                    value: value,
                  }),
                )
              }}
            />
          </StyledRow>
          <StyledColumn sx={{ mb: 3 }}>
            <Typography variant="body2" color="text.gray">
              จองโดย
            </Typography>
            {requestType === enumRequestType.MONTHLY_PLAN ? (
              <Typography variant="body1" color="text.primary">
                {bookByName}
              </Typography>
            ) : (
              <Typography variant="body1" color="text.primary">
                {bookByName}
              </Typography>
            )}
          </StyledColumn>
          <TextInput
            type="basic"
            name="remark"
            labelText={'หมายเหตุ'}
            placeholder="กรุณากรอกหมายเหตุ"
            rows={3}
            multiline={true}
            value={remark}
            onChange={(e) => {
              const value = _.get(e, 'target.value', '')
              dispatch(
                handleChange({
                  key: 'remark',
                  value: value,
                }),
              )
            }}
            textError={getErrorMessage(formError, 'remark')}
          />
        </StyledColumn>
      )}
    </StyledCard>
  )
}
