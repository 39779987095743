import { Box, Card, CardContent } from '@mui/material'
import { ToggleContent } from '../ToggleContent'
import { DataCard } from '../ModuleCard/DataCard'
import _ from 'lodash'
import { overallData } from '../../models/overall'
import { handleClickOpenDrawer } from '../../handler/handleClickOpenDrawer'
import { shallowEqual, useSelector } from 'react-redux'
import { colorType } from '../../constants/colors'

const AllTypes = () => {
  const { overview } = useSelector(
    (state) => ({
      overview: state.crud.mixModuleDashboard?.overview,
    }),
    shallowEqual,
  )
  const dataCount = {
    allClass: _.get(overview, 'allClassCount', 0),
    enroll: _.get(overview, 'allLearnerEnroll', 0),
    attendee: _.get(overview, 'allAttendee', 0),
    budget: _.get(overview, 'allExpenseSummary', 0),
  }
  const data = overallData({
    data: dataCount,
    ...colorType.ALL,
  })
  const totalModule = _.get(data[1], 'number', 0)

  return (
    <Box mb={3}>
      <Card>
        <CardContent>
          <ToggleContent
            title={'สถิติรวมทุกประเภท'}
            type={''}
            contentId="allTypes"
            hideIcon={true}
            alwaysOpen={true}
          >
            <DataCard
              dataList={data}
              totalModule={totalModule}
              border={'none'}
              padding={0}
              handleClickButton={(ele) => {
                handleClickOpenDrawer('classDrawerAll', ele)
              }}
            />
          </ToggleContent>
        </CardContent>
      </Card>
    </Box>
  )
}

export default AllTypes
