import { store } from '../../../../App'
import {
  resetTablePage,
  setFilterProp,
  setSearchText,
} from '../../../../redux/slices/table'
import { initialize } from './initialize'

export const handleQuickSearch = (searchText) => {
  store.dispatch(setSearchText(searchText))
  store.dispatch(setFilterProp(null))
  store.dispatch(resetTablePage())
  initialize()
}
