import { createListenerMiddleware, isAnyOf } from '@reduxjs/toolkit'
import {
  setFiltersDashboard,
  setIsOpenClassDrawer,
  setIsOpenFilterDrawer,
  setIsOpenStaffDrawer,
  setReduxDashboardByKey,
  setReduxDashboard,
  setTabActiveReport,
  setInitialReportMP,
} from '.'
import { fetchClassList } from '../../../modules/MonthlyPlan/ClassDashboard/handler/fetchClassList'
import { handleFilterDisplay } from '../../../modules/MonthlyPlan/ClassDashboard/handler/handleFilterDisplay'
import { fetchOverview } from 'src/modules/MonthlyPlan/handler/fetchOverview'
import { fetchStaffByType } from '../../../modules/MonthlyPlan/WorkloadDashboard/handler/fetchStaffByType'
import { headerStaff } from 'src/modules/MonthlyPlan/WorkloadDashboard/model'
import { setInitialTable, setSelected } from '../table'
import _ from 'lodash'
import { downloadStaffList } from '../../../modules/MonthlyPlan/WorkloadDashboard/handler/downloadStaffList'
import { handleFilterReport } from 'src/modules/MonthlyPlan/Report/events'
import { setReportConfig } from '../reportComp'
import { getDefaultDataWorkload } from 'src/modules/MonthlyPlan/handler/getDefaultDataWorkload'
import { defaultDataYearly } from 'src/modules/MonthlyPlan/Report/model'

export const listenerMiddlewareMonthlyPlan = createListenerMiddleware()

listenerMiddlewareMonthlyPlan.startListening({
  actionCreator: setIsOpenClassDrawer,
  effect: async (action, listenerApi) => {
    const { isOpenClassDrawer } = listenerApi.getState().monthlyPlan.dashboard
    if (isOpenClassDrawer) fetchClassList()
  },
})

listenerMiddlewareMonthlyPlan.startListening({
  matcher: isAnyOf(setIsOpenFilterDrawer, setReduxDashboardByKey),
  effect: async (action, listenerApi) => {
    const {
      payload: { key },
      type,
    } = action
    const { isOpenFilterDrawer, filters } =
      listenerApi.getState().monthlyPlan.dashboard

    if (type === 'monthlyPlan/setIsOpenFilterDrawer' && isOpenFilterDrawer) {
      listenerApi.dispatch(
        setReduxDashboardByKey({
          key: 'displayFilters',
          value: filters,
        }),
      )
    }

    if (
      (type === 'monthlyPlan/setIsOpenFilterDrawer' && !isOpenFilterDrawer) ||
      (type === 'monthlyPlan/setReduxDashboardByKey' &&
        key === 'displayFilters')
    )
      handleFilterDisplay()
  },
})

listenerMiddlewareMonthlyPlan.startListening({
  actionCreator: setFiltersDashboard,
  effect: async (action, listenerApi) => {
    const { filters } = listenerApi.getState().monthlyPlan.dashboard

    listenerApi.dispatch(
      setReduxDashboardByKey({
        key: 'displayFilters',
        value: filters,
      }),
    )
    fetchOverview()
  },
})

listenerMiddlewareMonthlyPlan.startListening({
  actionCreator: setReduxDashboardByKey,
  effect: async (action, listenerApi) => {
    const {
      payload: { key, value },
      type,
    } = action

    if (
      type === 'monthlyPlan/setReduxDashboardByKey' &&
      key === 'tabOverviewActive'
    ) {
      fetchOverview()
      if (value === 1) {
        listenerApi.dispatch(
          setReduxDashboardByKey({
            key: 'tabSumWorkloadActive',
            value: 0,
          }),
        )
      }
    }
  },
})

listenerMiddlewareMonthlyPlan.startListening({
  actionCreator: setReduxDashboardByKey,
  effect: async (action, listenerApi) => {
    const {
      payload: { key },
      type,
    } = action

    if (
      type === 'monthlyPlan/setReduxDashboardByKey' &&
      key === 'tabSumWorkloadActive'
    ) {
      listenerApi.dispatch(
        setReduxDashboardByKey({
          key: 'searchText',
          value: '',
        }),
      )
      listenerApi.dispatch(
        setReduxDashboardByKey({
          key: 'isExpand',
          value: false,
        }),
      )
      fetchOverview()
    }
  },
})

listenerMiddlewareMonthlyPlan.startListening({
  actionCreator: setIsOpenStaffDrawer,
  effect: async (action, listenerApi) => {
    const {
      dashboard: { isOpenStaffDrawer, staffType },
      dashboard,
    } = listenerApi.getState().monthlyPlan

    if (isOpenStaffDrawer) {
      const response = await fetchStaffByType(staffType)
      const results = _.get(response, 'results', []).map((res, i) => {
        return { ...res, no: i + 1 }
      })
      listenerApi.dispatch(
        setInitialTable({
          rows: results,
          allCount: _.get(response, 'count', 0),
          headCells: headerStaff,
          hideFilter: true,
          isCheckBox: false,
        }),
      )
      listenerApi.dispatch(
        setReduxDashboard({
          ...dashboard,
          searchText: '',
          table: {
            ...dashboard.table,
            onDownload: () => downloadStaffList(),
            onSearch: () =>
              fetchStaffByType().then((res) => {
                const results = _.get(res, 'results', []).map((res, i) => {
                  return { ...res, no: i + 1 }
                })
                listenerApi.dispatch(
                  setInitialTable({
                    rows: results,
                    allCount: _.get(res, 'count', 0),
                    headCells: headerStaff,
                    hideFilter: true,
                    isCheckBox: false,
                  }),
                )
              }),
          },
        }),
      )

      listenerApi.dispatch(setSelected([]))
    }
  },
})

listenerMiddlewareMonthlyPlan.startListening({
  actionCreator: setTabActiveReport,
  effect: async (action, listenerApi) => {
    const { tabActive } = listenerApi.getState().monthlyPlan.report
    const { reportConfig, defaultColumns } = listenerApi.getState().reportComp

    const moduleId = tabActive === 1 ? 'MP_WORKLOAD' : 'MP_YEARLY'
    const isWorkload = moduleId === 'MP_WORKLOAD'
    const defaultData = isWorkload
      ? await getDefaultDataWorkload()
      : defaultDataYearly

    listenerApi.dispatch(
      setInitialReportMP({
        headCellsReport: defaultColumns,
        reportsData: defaultData,
      }),
    )

    listenerApi.dispatch(
      setReportConfig({
        reportConfig: {
          ...reportConfig,
          moduleId: moduleId,
          modulePath: isWorkload ? '/monthly-plan' : '/monthly-plan/yearly',
        },
      }),
    )
    handleFilterReport()
  },
})
