import React, { Fragment, useEffect } from 'react'
import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import _ from 'lodash'
import Typography from '@mui/material/Typography'
import { convertTime } from '../../../modules/MonthlyPlan/Drawer/View/Index'
import { approveStatus } from '../../../constants/monthlyPlan/speaker'
import Dot from '../../../components/Table/Dot'
import BorrowApprovement from './BorrowApprovement'
import {
  StyledBorrowContainer,
  StyledBoxDot,
  StyledDivider,
  StyledHeader,
  StyledFlexRow,
  StyledRow,
  StyledFlexColumn,
} from './Styled'
import { handleInitialList } from './events'
import { Box } from '@mui/material'

const BorrowList = () => {
  const dispatch = useDispatch()
  const { data, speakerType, staffList } = useSelector(
    (state) => ({
      data: state.speaker.data,
      speakerType: state.speaker.speakerType,
      staffList: state.speaker.staffList,
    }),
    shallowEqual,
  )
  const borrowList = _.get(data, 'speakerBorrowResponsibility', []).filter(
    (item) => item.status !== approveStatus.DELETED,
  )
  const isDecided =
    data.status === approveStatus.APPROVE ||
    data.status === approveStatus.REJECT

  useEffect(() => {
    dispatch(handleInitialList(speakerType, borrowList))
  }, [speakerType, borrowList])

  return (
    <StyledBorrowContainer>
      <StyledHeader>
        <Box sx={{ display: 'flex', gap: 1 }}>
          {isDecided && <Typography width={20} />}
          <Typography width={50}>ลำดับ</Typography>
        </Box>
        <Typography width={300}>Department</Typography>
        <Typography width={100}>Station</Typography>
        <Typography width={120}>หน้าที่</Typography>
        <Typography width={70}>จำนวน</Typography>
        <Typography width={100}>เวลา</Typography>
      </StyledHeader>

      {borrowList.map((item, index) => (
        <Fragment key={index}>
          <BorrowRow item={item} data={data} index={index} />
          {data.status === approveStatus.WAITING &&
            speakerType === 'REQUESTER' && (
              <BorrowApprovement
                data={item}
                index={index}
                staffList={staffList}
              />
            )}
        </Fragment>
      ))}
    </StyledBorrowContainer>
  )
}

export default BorrowList

export const BorrowRow = ({ item, index, data }) => {
  const department = data?.department?.label
    ? `${_.get(data, 'department.label', '')}`
    : `(${_.get(data, 'approverDepartment.initial', '')}) ${_.get(
        data,
        'approverDepartment.department',
        '',
      )}`
  const stationUuid = data?.station
    ? _.get(data, 'station', '')
    : _.get(data, 'approverStation.station', '')
  const stationObj = _.get(data, 'department.station', []).find(
    (station) => station.uuid === stationUuid,
  )
  const station =
    _.get(stationObj, 'label', null) ||
    _.get(stationObj, 'station', null) ||
    _.get(data, 'approverStation.station', '')
  const time =
    !_.get(item, 'startTime', null) || !_.get(item, 'endTime', null)
      ? ''
      : `${convertTime(item.startTime)} - ${convertTime(item.endTime)}`
  const isRejected = item.status === approveStatus.REJECT
  const isDecided = item.status === approveStatus.APPROVE || isRejected
  const responsibility = _.get(
    item,
    'responsibility.responsibility',
    _.get(item, 'responsibility.label', ''),
  )

  return (
    <>
      <StyledDivider />
      <StyledRow isRejected={isRejected}>
        <Box sx={{ display: 'flex', gap: 1 }}>
          {isDecided && (
            <StyledBoxDot>
              <Dot status={item.status} />
            </StyledBoxDot>
          )}
          <Typography width={50}>{index + 1}</Typography>
        </Box>
        <Typography width={300}>{department}</Typography>
        <Typography width={100}>{station}</Typography>
        <Typography width={120}>{responsibility}</Typography>
        <Typography width={70}>{_.get(item, 'numSpeaker', '')}</Typography>
        <Typography width={100}>{time}</Typography>
      </StyledRow>
      <StyledFlexColumn isDecided={isDecided}>
        <Typography variant="body1b">Skill ที่ต้องการ</Typography>
        <Typography>{_.get(item, 'skillSpeaker', '') || '-'}</Typography>
      </StyledFlexColumn>
      <StyledFlexColumn isDecided={isDecided}>
        <Typography variant="body1b">จุดประสงค์ของการยืม</Typography>
        <Typography>{_.get(data, 'purpose', '') || '-'}</Typography>
      </StyledFlexColumn>
      <StyledFlexColumn isDecided={isDecided}>
        <Typography variant="body1b">หมายเหตุการยืม</Typography>
        <Typography>{_.get(item, 'remark', '') || '-'}</Typography>
      </StyledFlexColumn>
      {isRejected && (
        <StyledFlexRow>
          <Typography variant="body2" color="error">
            หมายเหตุ
          </Typography>
          <Typography sx={{ wordBreak: 'break-word' }} variant="body2">
            {_.get(item, 'remarkReject', '')}
          </Typography>
        </StyledFlexRow>
      )}
    </>
  )
}
