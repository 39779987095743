import {
  setInitialTable,
  startLoading,
  stopLoading,
  clearFilter,
  resetPage,
  resetTablePage,
  setSearchText,
  setStatusFilter,
  setSelected,
  setDefaultSort,
  setOrder,
} from '../../../../../redux/slices/table'
import { store } from '../../../../../App'
import { headerCells } from '../model/headerCells'
import { crudConfig } from '../../crudConfig'
import { convertFormatDateTime } from '../../../../../utils/lib'
import _ from 'lodash'
import { ROW_PAGE_PER_LIST } from '../../../../../constants/table'
import { mutateFilterPropToBody } from '../mutation/filterPropToBody'
import callAxios from '../../../../../utils/baseService'
import { handleQuickSearch } from '../../../../../components/CRUD/handler/handleQuickSearch'
import { onDownload } from '../../../../../components/CRUD/handler/onDownload'
import { handleDeleteClick } from '../../../../../components/CRUD/handler/handleDeleteClick'
import { transferByEnum } from '../enum/transferTypeEnum'
import { countOtherWaitingApprove } from './countOtherWaitingApprove'
import { setFieldValue } from '../../../../../redux/slices/crud'

export const handleChangeHeadCells = async () => {
  resetTable()
  const { table, search, filter: filterState } = store.getState().table
  const { tabActive } = store.getState().crud
  const { page } = table
  const { status, filterProp } = filterState
  const { searchText } = search

  const realPage = page <= 0 ? 1 : +page
  const tableProps = table
  const filter = filterProp

  let moduleId = 'INVENTORY_TRANSFER'
  let headCells = await headerCells()
  let transferBy = [transferByEnum.MY_TRANSFER]
  switch (tabActive) {
    case 0:
      transferBy = [transferByEnum.MY_TRANSFER]
      moduleId += '_MY'
      break
    case 1:
      transferBy = [transferByEnum.TRANSFER_OTHER]
      moduleId += '_OTHER'
      break
  }

  store.dispatch(
    setFieldValue({
      key: 'crudConfig.moduleId',
      value: moduleId,
    }),
  )

  const body = {
    limit: _.get(tableProps, 'limit', ROW_PAGE_PER_LIST[0].value),
    page: realPage,
    order: tableProps.order.toString().toUpperCase(),
    sort: _.get(tableProps, 'sort', 'updatedAt'),
    quickSearch: searchText,
    ...mutateFilterPropToBody(),
    createdBy: _.get(filter, 'createdBy', ''),
    updatedBy: _.get(filter, 'updatedBy', ''),
    search: '',
    status: [],
    startCreatedDate: '',
    endCreatedDate: '',
    startUpdatedDate: '',
    endUpdatedDate: '',
    transferType: ['TRANSFER'],
    transferBy,
  }

  store.dispatch(startLoading())
  const isCancel = await callAxios
    .post(crudConfig.filterPath, body, { 'x-type': moduleId })
    .then((res) => {
      const row = _.get(res, 'data.result', []).map((item) => ({
        ...item,
        categoryName: _.get(item, 'category.name', ''),
        stockCategoryName: _.get(item, 'stockCategory.name', ''),
        createdAt: convertFormatDateTime({
          value: item.createdAt,
          type: 'date',
        }),
      }))
      store.dispatch(
        setInitialTable({
          rows: row,
          allCount: _.get(res, 'data.totalCount', 0),
          headCells: headCells,
          placeholder: 'ค้นหา',
          searchKey: 'name',
          status: status,
          handleSearch: (text) =>
            store.dispatch(
              handleQuickSearch(tableProps, text, mutateFilterPropToBody),
            ),
          onDownload: (selected, sort, order) =>
            store.dispatch(onDownload(selected, sort, order)),
          onDelete: (selected) => {
            handleDeleteClick(selected.uuid, mutateFilterPropToBody)
          },
        }),
      )
    })
    .catch((e) => {
      store.dispatch(
        setInitialTable({
          rows: [],
          allCount: 0,
          headCells: crudConfig.headerCells(),
        }),
      )
      return e.message?.includes('method')
    })
  if (!isCancel) store.dispatch(stopLoading())
  countOtherWaitingApprove()
}

const resetTable = () => {
  store.dispatch(setSearchText(''))
  store.dispatch(clearFilter())
  store.dispatch(resetTablePage())
  store.dispatch(resetPage())
  store.dispatch(setStatusFilter([]))
  store.dispatch(setSelected([]))
  store.dispatch(setDefaultSort({ state: 0, id: '', active: false }))
  store.dispatch(setOrder({ order: 'desc', sort: 'updatedAt' }))
}
