import Select from '../../../../../components/Input/Select'
import { timePeriodOptions } from '../../models/timePeriodOptions'
import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import _ from 'lodash'
import { updateDashboardByKey } from '../../handler/updateDashboardByKey'
import { Row } from '../Styled'
import Autocomplete from '../../../../../components/Input/Autocomplete'
import { yearBudgetOptions } from '../../../../../modules/BudgetManagement/Form/model/yearBudgetOptions'
import { TextField, Box, Button } from '@mui/material'
import DateRangeInput from '../../../../../components/DateRangeInput'
import { setIsOpenFilterDrawer } from '../../../../../redux/slices/expense'
import { FilterListOutlined } from '@mui/icons-material'
import dayjs from 'dayjs'
import { TIME_PERIOD } from '../../../../../constants/expense'
import { useParams } from 'react-router-dom'
import { fetchDashboardData } from '../../handler/fetchDashboardData'

const TimePeriodSelector = () => {
  const { uuid } = useParams()
  const dispatch = useDispatch()
  const { timePeriod, yearBudget, dateRange, tabActive, filterTotal } =
    useSelector(
      (state) => ({
        timePeriod: state.expense.dashboard.timePeriod,
        yearBudget: state.expense.dashboard.yearBudget,
        dateRange: state.expense.dashboard.dateRange,
        tabActive: state.expense.dashboard.tabActive,
        filterTotal: state.expense.dashboard.filterTotal,
      }),
      shallowEqual,
    )

  return (
    <Row justifyContent="space-between">
      <Row gap={1.5}>
        <Select
          boxSx={{ width: 150 }}
          textErrorSx={{ mb: 0 }}
          dataTestid="select-timePeriod"
          id="timePeriod"
          name="timePeriod"
          options={timePeriodOptions}
          value={!_.isNil(timePeriod) ? timePeriod : ''}
          defaultValue={timePeriod}
          handleChange={(e) => {
            const value = _.get(e, 'target.value', '')
            updateDashboardByKey({ key: 'timePeriod', value })
          }}
        />
        <Box width={250}>
          {timePeriod === TIME_PERIOD.YEARLY ? (
            <Autocomplete
              boxSx={{ width: 150 }}
              errorSx={{ mb: 0 }}
              id="yearBudget"
              name="yearBudget"
              isHideSort
              options={yearBudgetOptions()}
              value={yearBudget}
              defaultValue={{
                value: yearBudget,
                label: yearBudget,
              }}
              getOptionLabel={(option) => option.label.toString()}
              renderInput={(params) => (
                <TextField placeholder={'กรุณาเลือก'} {...params} />
              )}
              handleChange={(e, element) =>
                updateDashboardByKey({
                  key: 'yearBudget',
                  value: _.get(element, 'value', ''),
                })
              }
            />
          ) : (
            <DateRangeInput
              placeholder="เลือกวันที่"
              dateState={{
                startDate: !dateRange?.startDate
                  ? new Date()
                  :  new Date(dateRange?.startDate),
                endDate: !dateRange?.endDate ? new Date() :  new Date(dateRange?.endDate),
                display:
                  dateRange?.display || !_.isUndefined(dateRange?.startDate)
                    ? true
                    : false,
                key: 'selection',
              }}
              onChange={(selectedDates) => {
                const startDate = dayjs(
                  _.get(selectedDates, 'startDate', ''),
                ).format('YYYY-MM-DD')
                const endDate = dayjs(
                  _.get(selectedDates, 'endDate', ''),
                ).format('YYYY-MM-DD')

                updateDashboardByKey({
                  key: 'dateRange',
                  value: { startDate, endDate },
                })
              }}
              isClearable
              handleClear={() => {
                updateDashboardByKey({
                  key: 'dateRange',
                  value: {},
                })
                fetchDashboardData()
              }}
              minDate={
                dateRange?.startDate
                  ? new Date(
                      dayjs(dateRange?.startDate)
                        .startOf('year')
                        .format('YYYY-MM-DD'),
                    )
                  : undefined
              }
              maxDate={
                dateRange?.startDate
                  ? new Date(
                      dayjs(dateRange?.startDate)
                        .endOf('year')
                        .format('YYYY-MM-DD'),
                    )
                  : undefined
              }
            />
          )}
        </Box>
      </Row>
      {(tabActive !== 0 || uuid) && (
        <Button
          data-testid={'btn-filter'}
          sx={{ height: 40, p: 1 }}
          variant="text"
          startIcon={<FilterListOutlined />}
          onClick={() => dispatch(setIsOpenFilterDrawer(true))}
        >
          ตัวกรอง {filterTotal > 0 && `(${filterTotal})`}
        </Button>
      )}
    </Row>
  )
}

export default TimePeriodSelector
