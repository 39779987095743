import React from 'react'
import _ from 'lodash'
import { read } from 'xlsx'
import {
  setFormType,
  setProcess,
  setProcessAgent,
} from '../../../../../../../redux/slices/manageClassLearner'

import {
  setUploadForm,
  resetForm,
  resetFile,
} from '../../../../../../../redux/slices/eLearning/courseBatchLearner'
import {
  loadingDialog,
  openDialog,
  setDisableButton,
  setEnableButton,
  stopLoadingDialog,
} from '../../../../../../../redux/slices/dialog'
import {
  postAddCourseBatchLearner,
  postUploadCourseBatchLearner,
  postCourseBatchLearnerAgentCheck,
} from '../../../../../../../services/eLearning/course/form'
import CheckDialog from './CheckDialog'
import { fetchDataList } from '../event'
import { closeDialog } from '../../../../../../../redux/slices/dialog'

export const handleRadioChange = (e) => (dispatch) => {
  dispatch(setFormType(e.target.value))
  dispatch(resetForm())
}

export const handleEnableButton = (props) => (dispatch) => {
  const {
    formType,
    userType,
    userProfile,
    staffProfile,
    remark,
    status,
    file,
  } = props
  const profileStaff = _.get(staffProfile, '[0]', {})
  const profileUser = _.get(userProfile, '[0]', {})
  const hasProfile = userType != '' && (profileUser || profileStaff)
  if (status == 'STARTCLASS' || status == 'ENDCLASS') {
    if (formType == 'import') {
      if (userType != '' && file && remark != '') {
        dispatch(setEnableButton())
      } else dispatch(setDisableButton())
    } else {
      if (hasProfile && remark != '') {
        dispatch(setEnableButton())
      } else dispatch(setDisableButton())
    }
  } else {
    if (formType == 'import') {
      if (userType != '' && file) dispatch(setEnableButton())
      else dispatch(setDisableButton())
    } else {
      if (hasProfile) dispatch(setEnableButton())
      else dispatch(setDisableButton())
    }
  }
}

export const handleSuccess = (isSuccess, uuid) => async (dispatch) => {
  if (isSuccess)
    await dispatch(
      openDialog({
        type: 'success',
        title: 'สำเร็จ',
        message: 'ข้อมูลถูกบันทึกเรียบร้อยแล้ว',
        handleConfirm: () => {
          dispatch(closeDialog())
          dispatch(fetchDataList('create', '', 1, null, '', uuid))
          dispatch(resetFile())
          dispatch(resetForm())
        },
      }),
    )
}

export const handleAgent = (props) => async (dispatch) => {
  const { uuid, userType, file, remark } = props
  const data = await file?.arrayBuffer()
  const workbook = read(data)
  const sheetLearner = _.get(workbook.Sheets, 'Learner-List', undefined)
  const agentPreview = []
  let total = 0
  for (let i = 1; i <= 50; i++) {
    const agent_code = _.get(sheetLearner, `A${i + 1}.v`, null)
    if (!_.isEmpty(agent_code) || Number.isInteger(agent_code)) {
      total++
    }
  }
  dispatch(setProcessAgent({ processAgent: true, total: total }))

  let NumberPeople = 0
  for (let i = 1; i <= 50; i++) {
    const agent_code = _.get(sheetLearner, `A${i + 1}.v`, null)
    if (!_.isEmpty(agent_code) || Number.isInteger(agent_code)) {
      const body = {
        courseBatchUuid: uuid,
        agentCode: [agent_code],
        agentType: userType,
        userType: ['LB', 'AGENT'].includes(userType) ? 'AGENT' : userType,
        remark,
        isPrerequisite: false,
        numberPeople: NumberPeople,
      }

      const responseAgent = await dispatch(
        postCourseBatchLearnerAgentCheck(body),
      )

      const result = _.get(responseAgent, 'payload.data.result[0]', {})

      const getDuplicate = _.filter(
        agentPreview,
        (item) => item.code === agent_code,
      )

      if (!_.isEmpty(getDuplicate)) {
        agentPreview.push({
          uuid: '',
          code: agent_code,
          status: 'DUPLICATE',
          index: i,
        })
      } else {
        agentPreview.push({ ...result, index: i })
        const status = _.get(result, 'status')
        if (status === 'SUCCESS') {
          NumberPeople++
        }
      }

      dispatch(setProcess(i))
    }
  }

  const STATUS = _.isEmpty(
    _.filter(agentPreview, (item) => item.status !== 'SUCCESS'),
  )

  dispatch(
    setUploadForm({
      status: STATUS ? 'SUCCESS' : 'ERROR',
      result: _.orderBy(agentPreview, ['index'], ['asc']),
      remark: remark,
      courseBatch: uuid,
      userType: ['L', 'A'].includes(userType) ? 'AGENT' : userType,
      agentType: userType,
    }),
  )
  dispatch(setProcessAgent({ processAgent: false, total: total }))

  dispatch(
    openDialog({
      type: 'mini',
      disableButton: true,
      content: <CheckDialog uuid={uuid} />,
    }),
  )

  dispatch(stopLoadingDialog())
}

export const handleSubmitAdd = (props) => async (dispatch) => {
  const {
    uuid,
    userType,
    file,
    userProfile,
    staffProfile,
    remark,
    formType,
    prerequisite,
  } = props
  dispatch(loadingDialog())
  if (formType == 'import') {
    if (['LB', 'AGENT'].includes(userType)) {
      return dispatch(handleAgent(props))
    }
    const formData = new FormData()
    formData.append('courseBatchUuid', uuid)
    formData.append('userType', userType)
    formData.append('remark', remark)
    formData.append('file', file)
    formData.append('isPrerequisite', !prerequisite)
    const response = {}
    await dispatch(postUploadCourseBatchLearner(formData))
    dispatch(resetFile())
    if (_.isNil(response.error)) {
      dispatch(
        openDialog({
          type: 'mini',
          disableButton: true,
          content: <CheckDialog uuid={uuid} />,
        }),
      )
    }
  } else {
    const body = {
      courseBatchUuid: uuid,
      userProfile: _.map(userProfile, (item) => item.uuid),
      staffProfile: _.map(staffProfile, (item) => item.uuid),
      userType,
      remark,
      isPrerequisite: !prerequisite,
    }
    if (userType === 'NON_ACADEMY') {
      body['userType'] = 'STAFF'
    }
    const response = await dispatch(postAddCourseBatchLearner(body))
    if (_.isEmpty(response.error)) {
      dispatch(handleSuccess(true, uuid))
    }
  }
}
