import React, { useMemo } from 'react'
import { Form, Formik } from 'formik'
import _ from 'lodash'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import SelectGroupChip from '../../../../../../../components/SelectGroupChip'
import { setOpenDrawer } from '../../../../../../../redux/slices/eLearning/courseForm'
import { COURSE_TYPE } from '../../../../../../../constants/eLearning'
import { StyledDrawer, StyledFooterDrawer, StyledWrapper } from './Styled'
import { getStateValue, handleInclude } from '../../../events'
import { schema } from './schema'
import { handleSubmit } from './events'

const AddDrawer = () => {
  const dispatch = useDispatch()
  const {
    isDrawerOpen,
    assignees,
    courseType,
    speakerList,
    adminStaffList,
    speakerProductType,
  } = useSelector(
    (state) => ({
      isDrawerOpen: state.eLearningCourseForm.isDrawerOpen,
      assignees: getStateValue('setting.assignees', []),
      courseType: getStateValue('courseType'),
      speakerList: state.eLearningCourseForm.speakerList,
      adminStaffList: state.eLearningCourseForm.adminStaffList,
      speakerProductType: getStateValue('speakerProductType', []),
    }),
    shallowEqual,
  )
  const initAssignees = useMemo(
    () => ({
      speaker: assignees.filter((item) => item.type === 'SPEAKER'),
      admin: assignees.filter((item) => item.type === 'ADMIN'),
      courseType,
    }),
    [assignees],
  )
  const isOIC = courseType === COURSE_TYPE.OIC

  return (
    <StyledDrawer
      anchor="right"
      open={isDrawerOpen}
      onClose={() => dispatch(setOpenDrawer(false))}
    >
      <Formik
        initialValues={initAssignees}
        validationSchema={schema}
        validateOnBlur={false}
        validateOnChange={false}
        onSubmit={(values) => dispatch(handleSubmit(values))}
      >
        {({ values, errors, setFieldValue, resetForm }) => {
          const speaker = _.get(values, 'speaker', [])
          const admin = _.get(values, 'admin', [])
          let speakerOption = speakerList.filter(
            (option) => !handleInclude(admin, option),
          )
          const adminOption = adminStaffList.filter(
            (option) => !handleInclude(speaker, option),
          )
          speakerOption = speakerOption.map((ele) => {
            if (!speakerProductType?.length > 0) {
              return {
                ...ele,
                matchProductType: true,
              }
            }
            const staffProductType = _.get(ele, 'productType', [])
            const checkMatchProductType = _.map(speakerProductType, (item) => {
              const matchProductType = _.intersectionBy(
                item,
                staffProductType,
                'uuid',
              )
              return item.length === matchProductType.length
            })
            return {
              ...ele,
              matchProductType: !window.__env__.ENABLE_SKILL_MAPPING
                ? true
                : _.some(checkMatchProductType),
            }
          })

          return (
            <Form>
              <Box sx={{ p: 2 }}>
                <Typography variant="h6">จัดการผู้รับผิดชอบ</Typography>
              </Box>

              <StyledWrapper>
                <SelectGroupChip
                  required={isOIC}
                  id="speaker"
                  name="speaker"
                  labelText="วิทยากรบรรยาย"
                  placeholder="เลือกวิทยากรบรรยาย"
                  options={speakerOption}
                  textError={_.get(errors, 'speaker', '')}
                  isShowTextError={_.get(errors, 'speaker', '')}
                  value={speaker}
                  onChange={(list) => setFieldValue('speaker', list)}
                />
                <SelectGroupChip
                  required={isOIC}
                  id="admin"
                  name="admin"
                  labelText="Admin"
                  placeholder="เลือก Admin"
                  options={adminOption}
                  textError={_.get(errors, 'admin', '')}
                  isShowTextError={_.get(errors, 'admin', '')}
                  value={admin}
                  onChange={(list) => setFieldValue('admin', list)}
                />
              </StyledWrapper>

              <StyledFooterDrawer>
                <Button
                  variant="outlined"
                  size="m"
                  onClick={() => {
                    resetForm()
                    dispatch(setOpenDrawer(false))
                  }}
                >
                  ยกเลิก
                </Button>
                <Button variant="contained" size="m" type="submit">
                  บันทึก
                </Button>
              </StyledFooterDrawer>
            </Form>
          )
        }}
      </Formik>
    </StyledDrawer>
  )
}

export default AddDrawer
