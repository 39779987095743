export const FORM_TAB = {
  overview: 'overview',
  testerList: 'testerList',
  testList: 'testList',
  qa: 'qa',
  setting: 'setting',
}

export const FORM_TAB_TH = {
  overview: 'ภาพรวม',
  testerList: 'รายชื่อผู้ทำข้อสอบ',
  testList: 'รายละเอียดรายข้อ',
  qa: 'คำถาม/คำตอบ',
  setting: 'การตั้งค่า',
}
