import { Box, Typography, Table } from '@mui/material'
import { useTheme } from '@mui/system'
import _ from 'lodash'
import { FinancialItem } from './FinancialItem'
import { shallowEqual, useSelector } from 'react-redux'

export const FinancialSummary = () => {
  const theme = useTheme()
  const { budgetSummary } = useSelector(
    (state) => ({ budgetSummary: state.crud.formData.budgetSummary }),
    shallowEqual,
  )

  return (
    <Box display="flex" gap={3}>
      {_.map(budgetSummary, (item, idx) => (
        <Box
          key={idx}
          sx={{ border: `1px solid ${theme.palette?.other?.divider}` }}
          p={2}
          width={310}
        >
          <Typography variant="body1b">{item.title}</Typography>
          <Table
            sx={{
              width: '100%',
              tableLayout: 'fixed',
            }}
          >
            {_.map(item.summaryList, (item, idx) => (
              <FinancialItem key={idx} {...item} />
            ))}
          </Table>
        </Box>
      ))}
    </Box>
  )
}
