import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { COURSE_TYPE } from '../../../../../constants/eLearning'
import { getStateValue } from '../events'
import Period from './Period'
import Assignee from './Assignee'
import Course from './Course'
import Prerequisite from './Prerequisite'
import ECertification from './ECertification'
import CollectHours from './CollectHours'
import SellCourse from './SellCourse'

const SettingTab = () => {
  const courseType = useSelector(
    () => getStateValue('courseType'),
    shallowEqual,
  )
  return (
    <>
      <Period />
      <Course />
      <Assignee />
      <Prerequisite />
      <ECertification />
      <CollectHours />
      {courseType === COURSE_TYPE.OIC && <SellCourse />}
    </>
  )
}
export default SettingTab
