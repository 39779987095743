import React from 'react'
import useTheme from '@mui/system/useTheme'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { onDownload } from './events'
import { useDispatch } from 'react-redux'
import _ from 'lodash'

const ActionResult = ({ row, startLoading, stopLoading }) => {
  const theme = useTheme()
  const dispatch = useDispatch()

  const status = _.get(myClassStatus, row.status, 'inactive')

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
      <Typography color={checkColorStatus(status, theme)}>
        {row?.result}
      </Typography>
      {row?.certificate && (
        <Button
          variant="outlined"
          data-testid="btn-download"
          sx={{ minWidth: '10px', p: '7px 11px' }}
          onClick={() =>
            dispatch(onDownload(row?.uuid, startLoading, stopLoading))
          }
        >
          <img src="/icon/certificate-icon.svg" />
        </Button>
      )}
    </Box>
  )
}

const myClassStatus = {
  WAITING_DOCUMENT: 'waiting',
  WAITING_CHECK: 'waiting',
  WAITING_CLASS_TO_START: 'inactive',
  CLASS_IN_PROGRESS: 'in_progress',
  CLASS_CLOSED_PASS: 'active',
  CLASS_CLOSED_NOT_PASS: 'cancel',
  INELIGIBLE: 'cancel',
  CANCEL: 'cancel',
  CANCELED: 'cancel',
  CANCELED_MA: 'cancel',
  TIME_OUT: 'cancel',
}

const checkColorStatus = (status, theme) => {
  const statusText = status?.toString().toLowerCase()

  const colorList = {
    active: theme?.palette?.success?.main,
    inactive: theme?.palette?.text?.silver,
    deleted: theme?.palette?.text?.white,
    waiting: theme?.palette?.warning?.light,
    waiting_for_active: theme?.palette?.warning?.light,
    approve: theme?.palette?.success?.main,
    reject: theme?.palette?.error?.main,
    draft: theme?.palette?.silver?.primary,
    cancel: theme?.palette?.error?.main,
    in_progress: theme?.palette?.primary?.main,
  }
  return colorList[`${statusText}`]
}

export default ActionResult
