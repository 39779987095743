import React, { useMemo } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import CheckCircle from '@mui/icons-material/CheckCircle'
import ErrorOutline from '@mui/icons-material/ErrorOutline'
import { useParams } from 'react-router-dom'
import _ from 'lodash'
import {
  StyledCancelButton,
  StyledDialogActions,
  StyledLoadingButton,
} from '../../../../../../components/redux/PopUpDialog/Styled'
import {
  handleCloseDialog,
  handleConvertDate,
  handleStaff,
  handleSubmitAdd,
  isSuccess,
} from './events'
import {
  StyledDialog,
  StyledLargeRow,
  StyledMediumRow,
  StyledMiniRow,
  StyledTable,
  StyledTableData,
  StyledTableHead,
} from './Styled'

export const CheckDialog = () => {
  const { id: uuid } = useParams()
  const { isLoading, uploadResult } = useSelector(
    (state) => ({
      isLoading: state.dialog.isLoading,
      uploadResult: state.eLearningCourseBatchDetail.uploadResult,
    }),
    shallowEqual,
  )
  const dispatch = useDispatch()
  const status = _.get(uploadResult, 'status', '')
  const result = _.get(uploadResult, 'result', [])
  const isEmpty = _.isEmpty(result)
  const successCount = useMemo(() => {
    const successList = result.filter((item) => isSuccess(item.status))
    return successList.length
  }, [result])

  return (
    <>
      {isEmpty ? (
        <EmptyHeader />
      ) : (
        <>
          {isSuccess(status) ? <SuccessHeader /> : <WarningHeader />}
          <StyledDialog>
            <StyledTable>
              <StyledTableHead>
                <StyledMiniRow>วันเวลาเปิดรับสมัคร</StyledMiniRow>
                <StyledMiniRow>วันเวลาปิดรับสมัคร</StyledMiniRow>
                <StyledMediumRow>ช่วงเวลารอบอบรม</StyledMediumRow>
                <StyledMediumRow>วิทยากรบรรยาย</StyledMediumRow>
                <StyledMediumRow>วิทยากรตอบคำถาม</StyledMediumRow>
                <StyledMediumRow>Admin</StyledMediumRow>
                <StyledMiniRow>เปิด/ปิดการรับสมัคร</StyledMiniRow>
                <StyledLargeRow>สถานะการเพิ่มรอบอบรม</StyledLargeRow>
              </StyledTableHead>
              {result.map((item, index) => {
                const openRegisterDate = handleConvertDate(
                  item.openRegisterDate,
                )
                const endRegisterDate = handleConvertDate(item.endRegisterDate)
                const periodStart = handleConvertDate(item.periodStart)
                const periodEnd = handleConvertDate(item.periodEnd)
                const trainer = handleStaff(_.get(item, 'TRAINER', ''))
                const trainerQuestion = handleStaff(
                  _.get(item, 'TRAINERQUESTION', ''),
                )
                const admin = handleStaff(_.get(item, 'ADMIN', ''))
                return (
                  <StyledTableData status={item.status} key={index}>
                    <StyledMiniRow>
                      {openRegisterDate} {_.get(item, 'openRegisterTime', '')}
                    </StyledMiniRow>
                    <StyledMiniRow>
                      {endRegisterDate} {_.get(item, 'endRegisterTime', '')}
                    </StyledMiniRow>
                    <StyledMediumRow>
                      {periodStart} - {periodEnd}
                    </StyledMediumRow>
                    <StyledMediumRow>{trainer}</StyledMediumRow>
                    <StyledMediumRow>{trainerQuestion}</StyledMediumRow>
                    <StyledMediumRow>{admin}</StyledMediumRow>
                    <StyledMiniRow>
                      {item.onShelfStatus === 'ACTIVE' ? 'เปิด' : 'ปิด'}
                    </StyledMiniRow>
                    <StyledLargeRow id="status">
                      {isSuccess(item.remark) ? 'สำเร็จ' : item.remark}
                    </StyledLargeRow>
                  </StyledTableData>
                )
              })}
            </StyledTable>
          </StyledDialog>
        </>
      )}
      <StyledDialogActions sx={{ mt: 2.5 }}>
        {!isLoading && (
          <StyledCancelButton
            data-testid="btn-disagree"
            onClick={() => dispatch(handleCloseDialog())}
            variant="outlined"
            endIcon=""
          >
            ยกเลิกดำเนินการ
          </StyledCancelButton>
        )}
        {!isEmpty && successCount === result.length && (
          <StyledLoadingButton
            data-testid="btn-agree"
            loading={isLoading}
            onClick={() => dispatch(handleSubmitAdd(uuid))}
            color="primary"
            endIcon=""
          >
            บันทึก
          </StyledLoadingButton>
        )}
      </StyledDialogActions>
    </>
  )
}

export default CheckDialog

export const EmptyHeader = () => {
  return (
    <Box id="dialog-header">
      <ErrorOutline color="error" />
      <Typography variant="h6">ไม่พบข้อมูล</Typography>
    </Box>
  )
}

export const WarningHeader = () => {
  return (
    <Box id="dialog-header">
      <ErrorOutline color="warning" />
      <Typography variant="h6">กรุณาตรวจสอบรอบอบรม</Typography>
    </Box>
  )
}

export const SuccessHeader = () => {
  return (
    <Box id="dialog-header">
      <CheckCircle color="primary" />
      <Typography variant="h6">สามารถเพิ่มรอบอบรมได้ทั้งหมด</Typography>
    </Box>
  )
}
