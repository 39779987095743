import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import DeleteTwoTone from '@mui/icons-material/DeleteTwoTone'
import EditTwoTone from '@mui/icons-material/EditTwoTone'
import RemoveRedEyeTwoTone from '@mui/icons-material/RemoveRedEyeTwoTone'

import IconButton from '@mui/material/IconButton'

import { StyledBox } from '../Styled'
import { setOnEdit, setOnView } from '../../../../../redux/slices/table2'
import { store } from '../../../../../App'
import dayjs from 'dayjs'
import _ from 'lodash'

const ActionInventoryRanking = ({ row, rowIndex, minWidth, condition }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { onDelete } = useSelector((state) => state.table2)
  let viewUrl = `${condition.viewPath}/${row.uuid}`
  const editUrl = `${condition.editPath}/${row.uuid}`
  const { filter: filterDashboard } = store.getState().inventoryDashboard
  if (condition?.type === 'stock') {
    viewUrl = `${condition.viewPath}/${row?.productUuid}`
  } else if (condition?.type === 'withdraw') {
    const startCreated = _.isDate(filterDashboard?.startDate)
      ? dayjs(filterDashboard?.startDate).utc(true).format('YYYY-MM-DD')
      : dayjs().startOf('month').format('YYYY-MM-DD')
    const endCreated = _.isDate(filterDashboard?.endDate)
      ? dayjs(filterDashboard?.endDate).utc(true).format('YYYY-MM-DD')
      : dayjs().format('YYYY-MM-DD')
    viewUrl = `${condition.viewPath}?createdBy=${row?.name}&startDate=${startCreated}&endDate=${endCreated}`
  }
  return (
    <StyledBox minWidth={minWidth}>
      {!condition.hideView && (
        <IconButton
          data-testid={`btn-view-${rowIndex}`}
          color="primary"
          component="span"
          onClick={() => dispatch(setOnView({ history, viewUrl }))}
        >
          <RemoveRedEyeTwoTone color="action" />
        </IconButton>
      )}

      {!condition.hideEdit && row.status !== 'DELETED' && (
        <IconButton
          data-testid={`btn-edit-${rowIndex}`}
          color="primary"
          component="span"
          onClick={() => dispatch(setOnEdit({ history, editUrl }))}
        >
          <EditTwoTone color="action" />
        </IconButton>
      )}
      {!condition.hideDelete && row.status !== 'DELETED' && (
        <IconButton
          data-testid={`btn-delete-${rowIndex}`}
          color="primary"
          component="span"
          onClick={() => onDelete(row, rowIndex)}
        >
          <DeleteTwoTone color="action" />
        </IconButton>
      )}
    </StyledBox>
  )
}

export default ActionInventoryRanking
