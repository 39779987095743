import { breadcrumbReportRawData } from './model'
import dayjs from 'dayjs'

export const reportConfig = {
  moduleId: 'REPORT_INVENTORY_RAW_DATA_STOCK',
  modulePath: '/inventory/report-raw-data-stock',
  breadcrumb: breadcrumbReportRawData(),
  downloadName: `Report Raw Data Stock ${dayjs().format('DDMMYYYY')}.xlsx`,
}

export const reportConfigPic = {
  moduleId: 'REPORT_INVENTORY_RAW_DATA_STOCK_PIC',
  modulePath: '/inventory/report-raw-data-stock',
  breadcrumb: breadcrumbReportRawData(),
  downloadName: `Report Raw Data Stock ${dayjs().format('DDMMYYYY')}.xlsx`,
}
