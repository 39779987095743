export const arrayStatus = [
  {
    label: 'ยังไม่ตอบ',
    name: 'inActiveChecked',
  },
  {
    label: 'ตอบแล้ว',
    name: 'activeChecked',
  },
]

export const arrayCheckbox = [
  {
    label: 'ชื่อหลักสูตร',
    nameCheckBox: 'nameChecked',
    nameInput: 'name',
    placeholder: 'ค้นหาชื่อหลักสูตร',
    type: 'text',
  },
  {
    label: 'ชื่อผู้ถาม',
    nameCheckBox: 'questionByChecked',
    nameInput: 'questionByName',
    placeholder: 'ค้นหาชื่อผู้ถาม',
    type: 'text',
  },
  {
    label: 'แก้ไขล่าสุดโดย',
    nameCheckBox: 'updatedByChecked',
    nameInput: 'updatedBy',
    placeholder: 'ค้นหาแก้ไขล่าสุดโดย',
    type: 'text',
  },
  {
    label: 'สถานะ',
    nameCheckBox: 'statusChecked',
    nameInput: 'status',
    type: 'checkbox',
    list: arrayStatus,
  },
]

export const defaultFilter = {
  questionByChecked: false,
  questionByName: '',
  nameChecked: false,
  name: '',

  statusChecked: false,
  status: {
    activeChecked: false,
    inActiveChecked: false,
  },
  updatedByChecked: false,
  updatedBy: '',
}
