import React, { useState, useEffect } from 'react'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Card from '@mui/material/Card'
import Button from '@mui/material/Button'
import { useParams } from 'react-router-dom'
import useTheme from '@mui/system/useTheme'
import EditIcon from '@mui/icons-material/Edit'
import Typography from '@mui/material/Typography'
import CircularProgress from '@mui/material/CircularProgress'
import RestoreTwoToneIcon from '@mui/icons-material/RestoreTwoTone'

import HistoryDrawer from '../../components/Drawer/History'
import Breadcrumbs from '../../components/Breadcrumbs'
import { permission, historyPermissionGroup } from '../../utils/apiPath'
import { callAPI } from '../../utils/lib'

export const FormGrouPermission = ({ listPermission }) => {
  if (listPermission.length) {
    return listPermission.map((item, index) => {
      return (
        <Typography
          key={index}
        >{`(${item.id}) ${item.module} | ${item.permission} | ${item.owner} | ${item.remark}`}</Typography>
      )
    })
  } else {
    return <Typography>-</Typography>
  }
}

export const FormGroupCostCenter = ({ listCostCenter }) => {
  if (listCostCenter.length) {
    return listCostCenter.map((item, index) => {
      return (
        <Typography
          key={index}
        >{`(${item.id}) ${item?.groupCode} | ${item.costCenter} | ${item.descriptionName}`}</Typography>
      )
    })
  } else {
    return <Typography>-</Typography>
  }
}

const PermissionView = () => {
  const { id: uuid } = useParams()
  const theme = useTheme()
  const [satePermission, setStatePermission] = useState({
    permission: [],
    costCenter: [],
  })
  const [historyList, setHistoryList] = useState([])
  const [limit, setLimit] = useState(100)
  const [page, setPage] = useState(0)
  const [isHistoryDrawer, setIsHistoryDrawer] = useState(false)
  const [allCount, setAllCount] = useState(0)
  const [staffMember, setstaffMember] = useState('-')
  const breadcrumbList = [
    { title: 'Manage', link: '/', pointer: false },
    {
      title: 'สิทธิ์การใช้งาน',
      link: '/manage/permission',
      pointer: true,
    },
    { title: 'รายละเอียดกลุ่ม', link: '/', pointer: false },
  ]

  const fetchPermissionById = async (uuid) => {
    await callAPI({
      url: permission + `/${uuid}/view`,
      method: 'GET',
      onSuccess: (res) => {
        if (res.staff.length) setstaffMember(res.staff.length)
        setStatePermission(res)
      },
    })
  }

  const fetchHistory = async (uuid) => {
    const realPage = page === 0 ? 1 : page + 1
    const url = `${historyPermissionGroup}?limit=${limit}&page=${realPage}&uuid=${uuid}`
    await callAPI({
      url: url,
      method: 'GET',
      onSuccess: (res) => {
        setHistoryList(res.result)
        setAllCount(res.totalCount)
      },
    })
  }
  const onHistoryClick = () => {
    setIsHistoryDrawer(!isHistoryDrawer)
  }

  useEffect(() => {
    setIsPageLoading(true)
    fetchPermissionById(uuid)
    setIsPageLoading(false)
  }, [])

  useEffect(() => {
    fetchHistory(uuid)
  }, [limit, page])

  const [isPageLoading, setIsPageLoading] = useState(false)

  return (
    <Box sx={{ pb: 2 }}>
      <Box
        sx={{
          opacity: isPageLoading ? 0.4 : 'unset',
          pointerEvents: isPageLoading ? 'none' : 'unset',
        }}
      >
        <Box
          sx={{
            mx: 3,
            justifyContent: 'space-between',
            display: 'flex',
          }}
        >
          <Typography
            variant="h4"
            sx={{
              'word-wrap': 'break-word',
              'white-space': 'pre-wrap',
              width: '75%',
            }}
          >
            รายละเอียดกลุ่ม : {satePermission.groupName}
          </Typography>
        </Box>
        <Breadcrumbs sx={{ mx: 3, mb: 3 }} menuList={breadcrumbList} />
        <Box
          sx={{
            ml: 3,
            [theme.breakpoints.up('md')]: {
              position: 'absolute',
              mt: -10,
              mr: 3,
            },
            right: 0,
            flexDirection: 'row',
            display: 'flex',
          }}
        >
          <Button
            sx={satePermission.status !== 'DELETED' ? { mr: 3 } : {}}
            startIcon={<RestoreTwoToneIcon />}
            variant="outlined"
            onClick={onHistoryClick}
          >
            ประวัติการแก้ไข
          </Button>

          {satePermission.status !== 'DELETED' && (
            <Button
              startIcon={<EditIcon />}
              variant="contained"
              onClick={() => {
                window.location.href =
                  '/manage/permission/edit/' + satePermission.uuid
              }}
            >
              แก้ไข
            </Button>
          )}
        </Box>
      </Box>
      <Card sx={{ minWidth: 275, mx: 3, mt: 3, pt: 2 }}>
        <Box
          sx={{
            mx: 3,
            mb: 2,
            display: 'flex',
            flexDirection: 'row',
            [theme.breakpoints.down('sm')]: {
              flexDirection: 'column',
            },
          }}
        >
          <Typography sx={{ minWidth: 330 }} variant="body1b">
            ID
          </Typography>
          <Typography variant="body1">{satePermission.id}</Typography>
        </Box>

        <Box
          sx={{
            mx: 3,
            mb: 2,
            display: 'flex',
            flexDirection: 'row',
            [theme.breakpoints.down('sm')]: {
              flexDirection: 'column',
            },
          }}
        >
          <Typography sx={{ minWidth: 330 }} variant="body1b">
            ชื่อกลุ่ม
          </Typography>
          <Typography variant="body1">{satePermission.groupName}</Typography>
        </Box>

        <Box
          sx={{
            mx: 3,
            mb: 2,
            display: 'flex',
            flexDirection: 'row',
            [theme.breakpoints.down('sm')]: {
              flexDirection: 'column',
            },
          }}
        >
          <Typography sx={{ minWidth: 330 }} variant="body1b">
            permission
          </Typography>
          <Typography variant="body1">
            <FormGrouPermission
              listPermission={
                satePermission.permission.sort((a, b) => a.id - b.id) || []
              }
            />
          </Typography>
        </Box>
        <Box
          sx={{
            mx: 3,
            mb: 2,
            display: 'flex',
            flexDirection: 'row',
            [theme.breakpoints.down('sm')]: {
              flexDirection: 'column',
            },
          }}
        >
          <Typography sx={{ minWidth: 330 }} variant="body1b">
            Cost Center
          </Typography>
          <Typography variant="body1">
            <FormGroupCostCenter
              listCostCenter={satePermission.costCenter.sort(
                (a, b) => a.id - b.id,
              )}
            />
          </Typography>
        </Box>
        <Box
          sx={{
            mx: 3,
            mb: 2,
            display: 'flex',
            flexDirection: 'row',
            [theme.breakpoints.down('sm')]: {
              flexDirection: 'column',
            },
          }}
        >
          <Typography sx={{ minWidth: 330 }} variant="body1b">
            สมาชิกกลุ่ม
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <Typography variant="body1">{staffMember}</Typography>
          </Box>
        </Box>
        <Divider sx={{ mx: 3 }} />
        <Box
          sx={{
            mx: 3,
            my: 2,
            display: 'flex',
            flexDirection: 'row-reverse',
            alignItems: 'right',
          }}
        >
          <Typography variant="caption" color="text.lightGray">
            แก้ไขล่าสุดโดย{' '}
            {historyList && historyList.length > 0 && historyList[0].author}{' '}
            {historyList && historyList.length > 0 && historyList[0].createdAt}
          </Typography>
        </Box>
      </Card>
      <CircularProgress
        disableShrink
        sx={{
          position: 'absolute',
          display: isPageLoading ? 'unset' : 'none',
          top: '50%',
          left: '50%',
          color: 'primary.main',
        }}
      />
      <HistoryDrawer
        open={isHistoryDrawer}
        onClose={() => setIsHistoryDrawer(false)}
        historyList={historyList}
        page={page}
        setPage={setPage}
        limit={limit}
        setLimit={setLimit}
        allCount={allCount}
      />
    </Box>
  )
}
export default PermissionView
