import _ from 'lodash'
import { mapStepSizeChart } from '../../../utils/lib'
export const validateVideo = (url) => {
  if (!url) return 'กรุณกรอก url'
  let text =
    'Link ไม่ตรงกับ Format Link วิดีโอต้องเป็น Youtube หรือ Vimeo เท่านั้น'
  if (url.length < 8) return text
  const value = url.slice(8).split('/')[0]
  console.log('value', value)
  if (
    value === 'vimeo.com' ||
    value === 'www.youtube.com' ||
    value === 'youtu.be' ||
    value === 'player.vimeo.com'
  )
    return ''
  return text
}

export const addIdInDefaultQuestions = (item) => {
  if (!item.length) return []
  return [
    {
      ...item[0],
      questions: addIdInArray(item[0].questions),
    },
  ]
}
export const addFieldQuestions = (list) => {
  if (!list.length) return []
  let listSubQuestions = []
  const listQuestions = list.map((item, index) => {
    const questionType = _.get(item, 'questionType', 'SINGLE')
    const subQuestions = _.get(item, 'subQuestions', [])
    if (questionType === 'SINGLE' && subQuestions.length > 0) {
      listSubQuestions.push({
        sequence: index + 1,
        value: item.subQuestions.map((itemSub, indexSub) => {
          const subQuestionType = _.get(itemSub, 'questionType', 'SINGLE')
          return addIdQuestions(itemSub, subQuestionType, indexSub)
        }),
      })
    }
    return addIdQuestions(
      questionType === 'SINGLE' ? { ...item, subQuestions: [] } : item,
      questionType,
      index,
    )
  })

  return { listQuestions, listSubQuestions }
}

export const addIdQuestions = (item, questionType, index) => {
  const newItem = {
    ...item,
    sequence: index + 1,
    setNextQuestion: _.get(item, 'setNextQuestion', false), // SINGLE
    setTypeOfAnswers: _.get(item, 'setTypeOfAnswers', false), // MULTIPLE
    typeOfAnswers: _.get(item, 'typeOfAnswers', 'EQUAL'), // MULTIPLE
    numberOfAnswers: _.get(item, 'numberOfAnswers', 0), // MULTIPLE
    isSuggestion: _.get(item, 'isSuggestion', false), // RATING
  }

  if (questionType !== 'RATING' && questionType !== 'FREE_TEXT') {
    return {
      ...newItem,
      answers: addIdInArray(newItem.answers),
    }
  } else if (questionType === 'RATING') {
    return {
      ...newItem,
      choices: addIdInArray(newItem.choices),
      questions: addIdInArray(newItem.questions),
    }
  } else {
    return newItem
  }
}

export const addIdInArray = (list) => {
  if (!list.length) return []
  return list.map((item, index) => {
    return {
      ...item,
      id: `id-${index + 1}`,
    }
  })
}

export const resetValueAnswer = ({ itemQuestion, value, isSubQuestions }) => {
  if (!itemQuestion.answers) {
    let defaultObj = {
      id: 'id-1',
      title: '',
      sequence: 1,
      mediaUrl: '',
      answerType: 'CHOICE',
    }
    if (!isSubQuestions && value === 'SINGLE') {
      defaultObj = {
        ...defaultObj,
        nextQuestionType: 'NONE',
        nextQuestionSequence: 0,
        nextSubQuestionSequence: 0,
      }
    }
    return [defaultObj]
  }
  const result = itemQuestion.answers.map((item) => {
    let newItem = {
      id: item.id,
      title: item.title,
      sequence: item.sequence,
      mediaUrl: item.mediaUrl,
      answerType: item.answerType,
    }
    if (!isSubQuestions && value === 'SINGLE') {
      newItem = {
        ...newItem,
        nextQuestionType: 'NONE',
        nextQuestionSequence: 0,
        nextSubQuestionSequence: 0,
      }
    }
    return newItem
  })
  return result
}

export const setFieldValueByQuestionType = (
  itemQuestion,
  value,
  isSubQuestions,
) => {
  let result = { ...itemQuestion }
  if (result.questionType === 'SINGLE') delete result.subQuestions

  if (value === 'SINGLE' || value === 'MULTIPLE' || value === 'RANKING') {
    // remove field RATING not subQuestion
    if (result.questionType === 'RATING') {
      delete result.questions
      delete result.choices
    }
    result.setNextQuestion = false // SINGLE
    result.setTypeOfAnswers = false // MULTIPLE
    result.typeOfAnswers = 'EQUAL' // MULTIPLE
    result.numberOfAnswers = 0 // MULTIPLE

    result.answers = resetValueAnswer({
      itemQuestion: result,
      value,
      isSubQuestions,
    })
  } else {
    delete result.answers

    if (value === 'RATING') {
      const objValue = {
        id: `id-1`,
        sequence: 1,
        title: '',
      }
      result.isSuggestion = false // RATING
      result.questions = [objValue]
      result.choices = [objValue]
    }
  }

  return { ...result, questionType: value }
}

export const addSequenceInArray = (list, isNext) => {
  if (!list.length) return []
  return list.map((item) => {
    return !isNext
      ? {
          ...item,
          sequence: item.sequence,
        }
      : {
          sequence: item.sequence,
          value: 1,
        }
  })
}

export const dataCartQuestions = (list = []) => {
  if (!list.length) return []

  const res = list.map((item) => {
    return {
      title: item.title,
      questionType: item.questionType,
      sequence: item.sequence,
      totalLearnerAnswerQuestion: _.get(item, 'totalLearnerAnswerQuestion', 0),
      ...setFiledDataQuestions(item),
    }
  })
  return res
}

export const dataCartSubQuestions = (list = []) => {
  if (!list.length) return []

  let nextSubQuestions = []
  let subQuestions = []
  list.map((item) => {
    if (setFiledNextQuestions(_.get(item, 'subQuestions', [])).length > 0) {
      nextSubQuestions.push({
        sequence: item.sequence,
        value: setFiledNextQuestions(_.get(item, 'subQuestions', [])),
      })
    }
    if (dataCartQuestions(_.get(item, 'subQuestions', [])).length) {
      subQuestions.push({
        sequence: item.sequence,
        value: dataCartQuestions(_.get(item, 'subQuestions', [])),
      })
    }
  })

  return { subQuestions, nextSubQuestions }
}

export const setFiledDataQuestions = (item) => {
  const questionType = item.questionType

  let listLabel = []
  let listData = []
  let otherAnswer = []

  switch (questionType) {
    case 'SINGLE':
    case 'MULTIPLE':
      return mapDataAnswers(
        _.get(item, 'answers', []),
        _.get(item, 'highestScore', 0),
      )
    case 'FREE_TEXT':
      otherAnswer = item.freetextAnswer
      return { listLabel, listData, otherAnswer }
    case 'RATING':
      return {
        isSuggestion: item.isSubQuestions,
        suggestionAnswer: item.suggestionAnswer,
        questions: setFiledRating(
          item.questions,
          _.get(item, 'highestScore', 0),
        ),
        highestScore: _.get(item, 'highestScore', 0),
      }
  }
}

export const mapDataAnswers = (itemAnswers, highestScore) => {
  if (!itemAnswers.length) {
    return {
      listLabel: [],
      listData: [],
      otherAnswer: [],
    }
  }
  let listLabel = []
  let listData = []
  let otherAnswer = []
  itemAnswers.map((item) => {
    const answerType = item.answerType
    listLabel.push(answerType === 'OTHER' ? 'อื่นๆ' : item.title)
    listData.push(item.answerCount)
    if (answerType === 'OTHER') {
      otherAnswer = item.otherAnswer
    }
  })
  return {
    listLabel,
    listData: [...listData, mapStepSizeChart(false, highestScore)],
    otherAnswer,
    highestScore,
  }
}

export const setFiledRating = (questions, highestScore) => {
  const result = questions.map((item) => {
    let listLabel = []
    let listData = []
    item.choices.map((itemChoice) => {
      listLabel.push(itemChoice.title)
      // listData.push(index + 1 * 5)
      listData.push(itemChoice.answerCount)
    })
    return {
      title: item.title,
      listLabel,
      listData: [...listData, mapStepSizeChart(false, highestScore)],
      sequence: item.sequence,
    }
  })

  return result
}

export const setFiledNextQuestions = (questions = []) => {
  let result = []
  questions.map((item) => {
    if (item.questionType === 'RATING') {
      result.push({
        sequence: item.sequence,
        value: 1,
      })
    }
  })
  return result
}

export const setFiledTypeDefault = (listData) => {
  const question = _.get(listData, 'questions', [])

  if (!question.length) {
    return {
      questions: [],
      sequence: 1,
      suggestionAnswer: [],
      isSuggestion: false,
    }
  }
  const resultMap = question.map((item) => {
    let listLabel = []
    let listData = []
    item.choices.map((itemChoice) => {
      listLabel.push(itemChoice.title)
      // listData.push(index2 + 1 * 5)
      listData.push(itemChoice.answerCount)
    })
    return {
      listLabel,
      listData: [
        ...listData,
        mapStepSizeChart(false, _.get(item, 'highestScore', 0)),
      ],
      title: item.title,
      sequence: item.sequence,
      highestScore: _.get(item, 'highestScore', 0),
    }
  })
  const result = {
    questions: resultMap,
    sequence: 1,
    suggestionAnswer: _.get(listData, 'suggestionAnswer', []),
    isSuggestion: _.get(listData, 'isSuggestion', false),
  }
  return result
}

export const setFiledTypeLecturer = (listData) => {
  const listStaff = _.get(listData, 'staff', [])
  const isPersonal = _.get(listData, 'isPersonal', false)

  if (!listStaff.length) {
    return {
      staff: [],
    }
  }
  const resultMap = listStaff.map((item) => {
    const questions = item.questions.map((itemQuestion) => {
      let listLabel = []
      let listData = []
      itemQuestion.choices.map((itemChoice) => {
        listLabel.push(itemChoice.title)
        // listData.push(index2 + 1 * 5)
        listData.push(itemChoice.answerCount)
      })
      return {
        listLabel,
        listData: [
          ...listData,
          mapStepSizeChart(false, _.get(itemQuestion, 'highestScore', 0)),
        ],
        title: itemQuestion.title,
        sequence: itemQuestion.sequence,
      }
    })
    return {
      isPersonal,
      questions,
      firstNameTH: _.get(item, 'firstNameTH', ''),
      lastNameTH: _.get(item, 'lastNameTH', ''),
      sequence: item.sequence,
      suggestionAnswer: _.get(listData, 'suggestionAnswer', []),
      isSuggestion: _.get(listData, 'isSuggestion', false),
      highestScore: _.get(item, 'highestScore', 0),
    }
  })

  return {
    staff: resultMap,
  }
}
