import { Box, Divider } from '@mui/material'
import { LabelWithValue } from '../../LabelWithValue'
import _ from 'lodash'
import { useTheme } from '@mui/system'

export const Content = ({
  costCenterId,
  costCenterName,
  staffProfileLabel,
  staffRoleLabel,
  totalAmount,
  amountList,
}) => {
  const theme = useTheme()
  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <LabelWithValue label="Cost Center" value={costCenterId} />
      <LabelWithValue label="Cost Center Name" value={costCenterName} />
      <LabelWithValue label="Trainer/Staff" value={staffProfileLabel} />
      <LabelWithValue label="Role" value={staffRoleLabel} />
      <Divider />
      <LabelWithValue
        variant="body1b"
        label="Total"
        value={totalAmount}
        unit="บาท"
      />
      <Box display="flex" flexDirection="column" gap={2}>
        {_.map(amountList, (item) => {
          return (
            <Box
              borderRadius={2}
              p={2}
              display="flex"
              flexDirection="column"
              gap={2}
              sx={{ backgroundColor: theme.palette?.common?.white }}
            >
              <LabelWithValue
                label="Account Code"
                value={item.accountCodeLabel}
                info={item.accountCode?.description}
              />
              <LabelWithValue
                label="Amount"
                value={item.amount?.toLocaleString()}
                unit="บาท"
              />
            </Box>
          )
        })}
      </Box>
    </Box>
  )
}
