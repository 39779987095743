import React from 'react'
import Typography from '@mui/material/Typography'
import ChevronRight from '@mui/icons-material/ChevronRight'
import {
  Column,
  Container,
  Row,
  // Dot,
  // Row,
  // Column,
  StyledButton,
  StyledName,
  StyledNumber,
  // SubLableBox,
} from '../Styled'
import { Box } from '@mui/material'
import _ from 'lodash'
import dayjs from 'dayjs'
import { useTheme } from '@emotion/react'
import { useHistory } from 'react-router-dom'
import { setOnView } from '../../../../../redux/slices/table2'
import { store } from '../../../../../App'

const RankingCard = ({
  title,
  background,
  border,
  boxSx = {},
  minWidth = 200,
  list = [],
  header1 = 'สนามสอบบริษัท',
  header2 = 'จำนวนรอบ',
  handleClickButton,
  unit,
  header3,
  type,
}) => {
  const theme = useTheme()
  return (
    <Container
      background={background}
      border={!_.isEmpty(border) ? border : 'solid 1px #DBE4F1'}
      minWidth={minWidth}
      sx={boxSx}
    >
      <Box
        display="flex"
        alignItems="center"
        gap={2}
        justifyContent="space-between"
      >
        <Typography variant="body2" color="text.secondary">
          {title}
        </Typography>
        {handleClickButton && (
          <StyledButton
            color="text.lightGray"
            size="small"
            background="#4976BA14"
            sx={{ marginRight: '4px' }}
            onClick={handleClickButton}
          >
            <ChevronRight fontSize="small" />
          </StyledButton>
        )}
      </Box>
      <Box>
        <Row sx={{ mb: 1 }}>
          <Typography
            variant="body2"
            color="text.lightGray"
            sx={{ width: 24, ml: 0.5, mr: 0.75, textAlign: 'center' }}
          >
            #
          </Typography>
          <Typography
            variant="body2"
            color="text.lightGray"
            sx={{ width: header3 ? '40%' : '70%', ml: 0.5, mr: 0.75 }}
          >
            {header1}
          </Typography>
          {header3 && (
            <Typography
              variant="body2"
              color="text.lightGray"
              sx={{ width: '40%' }}
            >
              {header3}
            </Typography>
          )}
          <Typography
            variant="body2"
            color="text.lightGray"
            sx={{ ml: 'auto', mr: '50px' }}
          >
            {header2}
          </Typography>
        </Row>
        <Column sx={{ gap: 1 }}>
          {list.length > 0 ? (
            list.map((ele, ind) => {
              return (
                <List
                  key={ind}
                  number={ind + 1}
                  data={ele}
                  color={theme.palette.primary.main}
                  background="#F1F4FA"
                  unit={unit}
                  type={type}
                />
              )
            })
          ) : (
            <Typography
              variant="body2"
              color="text.lightGray"
              sx={{ margin: '98px', textAlign: 'center' }}
            >
              ไม่พบข้อมูล
            </Typography>
          )}
        </Column>
      </Box>
    </Container>
  )
}

export default RankingCard

const List = ({ number, data, color, background, nameColor, unit, type }) => {
  const history = useHistory()
  const { filter: filterDashboard } = store.getState().inventoryDashboard
  return (
    <Row sx={{ p: '4px', borderRadius: '16px', background: background }}>
      <StyledNumber variant="caption" color="text.white" background={color}>
        {number}
      </StyledNumber>
      <StyledName
        variant="body2b"
        color={nameColor || color}
        sx={{ width: data.inventory ? '40%' : '60%', ml: 0.5, mr: 0.75 }}
      >
        {unit === 'ticket' ? data.prefix + ' ' + data.name : data.productName}
      </StyledName>
      {data.inventory && (
        <StyledName
          variant="body2b"
          color={nameColor || color}
          sx={{ width: '40%' }}
        >
          {data.inventory}
        </StyledName>
      )}
      <StyledName
        ml="auto"
        variant="body2b"
        color={nameColor || color}
        sx={{ textAlign: 'right', mr: '12px' }}
      >
        {unit === 'จำนวนมูลค่า'
          ? `${data.quantity} ชิ้น : ${data.price} บาท`
          : unit === 'ticket'
          ? `${data.ticket}`
          : `${data.quantity} ${unit}`}
      </StyledName>
      <StyledButton
        color="text.lightGray"
        size="small"
        marginLeft="0"
        onClick={() => {
          let viewUrl = ''
          if (unit !== 'ticket') {
            viewUrl = `/inventory/product/stock/view/${data.uuid}`
            if (type === 'rankingStock') {
              viewUrl = `/inventory/product/stock/view/${data?.productUuid}`
            }
          } else {
            const startCreated = _.isDate(filterDashboard?.startDate)
              ? dayjs(filterDashboard?.startDate).utc(true).format('YYYY-MM-DD')
              : dayjs().startOf('month').format('YYYY-MM-DD')
            const endCreated = _.isDate(filterDashboard?.endDate)
              ? dayjs(filterDashboard?.endDate).utc(true).format('YYYY-MM-DD')
              : dayjs().format('YYYY-MM-DD')
            viewUrl = `/inventory/requisition/?createdBy=${data.name}&startDate=${startCreated}&endDate=${endCreated}`
          }
          store.dispatch(setOnView({ history, viewUrl }))
        }}
      >
        <ChevronRight fontSize="small" />
      </StyledButton>
    </Row>
  )
}
