import { Card, Box, Typography } from '@mui/material'
import { tabsSummaryWorkloadList } from '../model'
import BasicTabs from '../../../../components/BasicTabs'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { handleChangeTab } from '../events'
import DetailsTable from '../DetailsTable'
import RadarChartBox from '../components/RadarChartBox'
import InputForCompareChart from '../components/InputForCompareChart'
import _ from 'lodash'
import InfoIcon from '@mui/icons-material/Info'
import { setIsOpenHelperDrawer } from '../../../../redux/slices/monthlyPlan'
import { useTheme } from '@emotion/react'

const SummaryWorkloadOverview = () => {
  const dispatch = useDispatch()
  const theme = useTheme()
  const classPage = window.location.pathname.includes('class')
  const { tabSumWorkloadActive, workTypeData } = useSelector(
    (state) => ({
      tabSumWorkloadActive: state.monthlyPlan.dashboard.tabSumWorkloadActive,
      workTypeData: state.monthlyPlan.dashboard.workTypeData,
    }),
    shallowEqual,
  )

  return (
    <Card
      sx={{
        p: 2,
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        mb: 2,
        position: 'relative',
      }}
    >
      <BasicTabs
        tabsList={tabsSummaryWorkloadList}
        tabActive={tabSumWorkloadActive}
        handleChangeTab={(e, newValue) =>
          handleChangeTab(e, newValue, 'tabSumWorkloadActive')
        }
      />
      {!classPage && (
        <Box
          display="flex"
          alignItems="center"
          onClick={() => dispatch(setIsOpenHelperDrawer(true))}
          position="absolute"
          right="0"
          top="20px"
        >
          <Typography
            variant="subtitle2"
            color={'primary.main'}
            sx={{
              cursor: 'pointer',
              mr: 2,
              display: 'flex',
              alignItems: 'center',
            }}
          >
            ช่วยเหลือ{' '}
            <InfoIcon
              style={{
                fontSize: 18,
                color: theme?.palette?.primary?.main,
              }}
            />
          </Typography>
        </Box>
      )}
      <DetailsTable />
      {tabSumWorkloadActive === 0 && (
        <Box display="flex" gap={2}>
          <RadarChartBox
            idx={1}
            workTypeData={workTypeData}
            workTypeListAVG={workTypeData.workTypeListAVG}
          />
          <InputForCompareChart />
        </Box>
      )}
      {tabSumWorkloadActive === 1 && (
        <Box display="flex" flexWrap="wrap" gap={2}>
          {_.map(
            _.get(workTypeData, 'staffWorkTypeDataList', []),
            (item, idx) => {
              return (
                <RadarChartBox
                  key={idx}
                  workTypeData={item}
                  idx={idx + 1}
                  workTypeListAVG={workTypeData.workTypeListAVG}
                />
              )
            },
          )}
        </Box>
      )}
      {tabSumWorkloadActive === 2 && (
        <Box display="flex" flexWrap="wrap" gap={2}>
          {_.map(
            _.get(workTypeData, 'staffWorkTypeDataList', []),
            (item, idx) => {
              return (
                <RadarChartBox
                  key={idx}
                  workTypeData={item}
                  idx={idx + 1}
                  workTypeListAVG={workTypeData.workTypeListAVG}
                />
              )
            },
          )}
        </Box>
      )}
    </Card>
  )
}
export default SummaryWorkloadOverview
