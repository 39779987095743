import { createListenerMiddleware, isAnyOf } from '@reduxjs/toolkit'
import {
  setFiltersDashboard,
  setIsOpenFilterDrawer,
  setReduxDashboardByKey,
  setTabActive,
} from '.'
import { fetchDashboardData } from '../../../modules/Expense/Dashboard/handler/fetchDashboardData'
import _ from 'lodash'
import {
  filtersWithCostCenter,
  filtersWithoutCostCenter,
} from '../../../modules/Expense/Dashboard/models/filtersList'
import { COLUMN_NAME } from 'src/constants/report/expense'
import { TIME_PERIOD } from 'src/constants/expense'
import dayjs from 'dayjs'
import { generateFilterData } from './events'

export const listenerMiddlewareExpense = createListenerMiddleware()

listenerMiddlewareExpense.startListening({
  actionCreator: setIsOpenFilterDrawer,
  effect: async (action, listenerApi) => {
    const { type } = action
    const { isOpenFilterDrawer, filters, uuid } =
      listenerApi.getState().expense.dashboard
    let defaultFilters = filtersWithCostCenter
    if (uuid) {
      defaultFilters = filtersWithoutCostCenter
    }
    if (type === 'expense/setIsOpenFilterDrawer' && isOpenFilterDrawer) {
      listenerApi.dispatch(
        setReduxDashboardByKey({
          key: 'displayFilters',
          value: filters,
        }),
      )
      listenerApi.dispatch(
        setReduxDashboardByKey({
          key: 'filtersList',
          value: defaultFilters,
        }),
      )
    }
  },
})

listenerMiddlewareExpense.startListening({
  matcher: isAnyOf(setFiltersDashboard, setReduxDashboardByKey, setTabActive),
  effect: async (action) => {
    const {
      payload: { key, value },
      type,
    } = action

    if (
      (type === 'expense/setReduxDashboardByKey' &&
        (key === 'timePeriod' ||
          (key === 'yearBudget' && _.isNumber(value)) ||
          (key === 'dateRange' &&
            !_.isEmpty(value?.startDate) &&
            !_.isEmpty(value?.endDate)))) ||
      type === 'expense/setFiltersDashboard' ||
      type === 'expense/setTabActive'
    ) {
      fetchDashboardData()
    }
  },
})

listenerMiddlewareExpense.startListening({
  matcher: isAnyOf(setFiltersDashboard, setReduxDashboardByKey),
  effect: async (action, listenerApi) => {
    const {
      payload: { key },
      type,
    } = action

    if (
      !(type === 'expense/setReduxDashboardByKey' && key === 'dateRange') &&
      type !== 'expense/setFiltersDashboard'
    )
      return

    const {
      filters: {
        accountCode,
        accountCodeChecked,
        category,
        categoryChecked,
        costCenter,
        costCenterChecked,
        staffProfile,
        staffProfileChecked,
        staffType,
        staffTypeChecked,
      },
    } = listenerApi.getState().expense.dashboard

    const filters = []
    let id = 1
    if (accountCodeChecked && !_.isEmpty(accountCode)) {
      filters.push(
        generateFilterData({
          id: id,
          key: 'OLD_ACCOUNT_CODE',
          list: accountCode,
          name: 'accountCodeChecked',
        }),
      )
      id++
    }
    if (categoryChecked && !_.isEmpty(category)) {
      filters.push(
        generateFilterData({
          id: id,
          key: 'CATEGORY',
          list: category,
          name: 'categoryChecked',
        }),
      )
      id++
    }
    if (costCenterChecked && !_.isEmpty(costCenter)) {
      filters.push(
        generateFilterData({
          id: id,
          key: 'COST_CENTER',
          list: costCenter,
          keyLabel: 'costCenter',
          name: 'costCenterChecked',
        }),
      )
      id++
    }
    if (staffProfileChecked && !_.isEmpty(staffProfile)) {
      filters.push(
        generateFilterData({
          id: id,
          key: 'STAFF_NAME',
          list: staffProfile,
          name: 'staffProfileChecked',
        }),
      )
      id++
    }
    if (staffTypeChecked && !_.isEmpty(staffType)) {
      filters.push(
        generateFilterData({
          id: id,
          key: 'STAFF_TYPE',
          list: staffType,
          name: 'staffTypeChecked',
        }),
      )
      id++
    }

    const filteredFilters = _.filter(
      filters,
      (filter) => !_.isEqual(COLUMN_NAME.TRAINING_DATE, filter.key),
    )

    listenerApi.dispatch(
      setReduxDashboardByKey({
        key: 'filterTotal',
        value: filteredFilters.length,
      }),
    )
    listenerApi.dispatch(
      setReduxDashboardByKey({
        key: 'filtersSummary',
        value: filteredFilters,
      }),
    )
  },
})

listenerMiddlewareExpense.startListening({
  actionCreator: setReduxDashboardByKey,
  effect: async (action, listenerApi) => {
    const {
      payload: { key, value },
    } = action

    if (key !== 'timePeriod') return

    const { summary } = listenerApi.getOriginalState().expense.dashboard
    let newSummary = []
    if (value === TIME_PERIOD.CUSTOM) {
      const updatedSummaryArr = [...summary]
      newSummary = updatedSummaryArr.splice(0, 1)
    } else {
      newSummary = summary
    }
    listenerApi.dispatch(
      setReduxDashboardByKey({
        key: 'summaryDisplay',
        value: newSummary,
      }),
    )
  },
})

listenerMiddlewareExpense.startListening({
  actionCreator: setTabActive,
  effect: async (action, listenerApi) => {
    const { timePeriod, yearBudget, dateRange } =
      listenerApi.getState().expense.dashboard

    if (
      timePeriod === TIME_PERIOD.YEARLY &&
      (yearBudget === '' || _.isNaN(yearBudget))
    ) {
      listenerApi.dispatch(
        setReduxDashboardByKey({
          key: 'yearBudget',
          value: Number(dayjs().format('YYYY')),
        }),
      )
    } else if (timePeriod === TIME_PERIOD.CUSTOM && _.isEmpty(dateRange)) {
      listenerApi.dispatch(
        setReduxDashboardByKey({
          key: 'dateRange',
          value: {
            startDate: dayjs().format('YYYY-MM-DD'),
            endDate: dayjs().format('YYYY-MM-DD'),
            display: true,
          },
        }),
      )
    }
  },
})
