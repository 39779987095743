import React from 'react'
import _ from 'lodash'
import { BoxButton, BoxCard, CardGroup } from '../Prerequisite/Styled'
import SelectGroupChip from '../../../components/SelectGroupChip'
import { Button, IconButton, Typography } from '@mui/material'
import { DeleteTwoTone } from '@mui/icons-material'

export const onSelectGroupChipChange = (
  list,
  inputName,
  ind,
  speakerProductType,
  formik,
) => {
  let data = [...speakerProductType]
  data[ind] = list
  formik.setFieldValue(inputName, data)
}

export const handleAddSubGroup = (
  inputName,
  ind,
  speakerProductType,
  formik,
) => {
  let data = [...speakerProductType]
  data.splice(ind + 1, 0, [])
  formik.setFieldValue(inputName, data)
}

export const handleDeleteSubGroup = (
  inputName,
  ind,
  speakerProductType,
  formik,
) => {
  let data = [...speakerProductType]
  data.splice(ind, 1)
  formik.setFieldValue(inputName, data)
}

const Index = ({ formik, productTypeOptions }) => {
  return (
    <CardGroup sx={{ overflow: 'visible', mb: 3, mt: 0 }}>
      {formik.values.speakerProductType.map((group, ind) => {
        return (
          <BoxCard key={ind}>
            <SelectGroupChip
              id="speakerProductType"
              name="speakerProductType"
              textError={formik?.errors?.speakerProductType?.[ind]}
              placeholder={'กรุณาเลือก'}
              options={productTypeOptions}
              onChange={(list) =>
                onSelectGroupChipChange(
                  list,
                  'speakerProductType',
                  ind,
                  formik.values.speakerProductType,
                  formik,
                )
              }
              value={group}
            />

            <BoxButton sx={{ mt: 0 }}>
              <Button
                disabled={_.some(
                  formik.values.speakerProductType,
                  (e) => e.length < 1,
                )}
                id={`btn-or-${ind}`}
                name={`btn-or-${ind}`}
                variant="outlined"
                size="m"
                onClick={() =>
                  handleAddSubGroup(
                    'speakerProductType',
                    ind,
                    formik.values.speakerProductType,
                    formik,
                  )
                }
              >
                <Typography variant="buttonl">หรือ</Typography>
              </Button>
              <IconButton
                disabled={formik.values.speakerProductType.length <= 1}
                onClick={() =>
                  handleDeleteSubGroup(
                    'speakerProductType',
                    ind,
                    formik.values.speakerProductType,
                    formik,
                  )
                }
              >
                <DeleteTwoTone
                  color={
                    formik.values.speakerProductType.length <= 1
                      ? 'action.disabled'
                      : 'action.active'
                  }
                />
              </IconButton>
            </BoxButton>
          </BoxCard>
        )
      })}
    </CardGroup>
  )
}
export default Index
