import React from 'react'
import Card from '@mui/material/Card'
import Typography from '@mui/material/Typography'

const Footer = ({ historyList }) => {
  return (
    <Card sx={{ padding: '24px', display: 'flex', justifyContent: 'flex-end' }}>
      <Typography variant="caption" color="text.lightGray">
        แก้ไขล่าสุดโดย{' '}
        {historyList && historyList.length > 0 && historyList[0].author}{' '}
        {historyList && historyList.length > 0 && historyList[0].createdAt}
      </Typography>
    </Card>
  )
}

export default Footer
