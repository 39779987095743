import axios from 'axios'
import { getOptionsCostCenter } from '../../../../modules/Expense/mutation/getOptionsCostCenter'
import { getOptionsAccountCode } from '../../../../modules/Expense/mutation/getOptionsAccountCode'
import { getOptionsStaffProfile } from '../../../../modules/Expense/mutation/getOptionsStaffProfile'
import _ from 'lodash'
import { setFieldValue } from '../../../../redux/slices/crud'
import { store } from '../../../../App'
import { listAPIFetchOptions } from '../models/listAPIFetchOptions'
import { getOptionsExpenseCategory } from '../../../../modules/BudgetManagement/Form/mutation/getOptionsExpenseCategory'
import { staffTypeOptions } from '../models/staffTypeOptions'

export const fetchMasterDataOptions = async () => {
  const masterData = await Promise.all(
    _.map(listAPIFetchOptions, (item) => {
      return axios({
        method: item.method,
        url: item?.url,
        data: item?.body,
        headers: item?.headers,
      })
    }),
  ).then((response) => {
    return _.reduce(
      response,
      (acc, res) => {
        const key = res?.config?.headers?.key
        let options = []
        switch (key) {
          case 'costCenter':
            options = getOptionsCostCenter(
              _.get(res, 'data.costCenterOptions', []),
            )
            break
          case 'accountCode':
            options = getOptionsAccountCode(_.get(res, 'data.result', []))
            break
          case 'staffProfile':
            options = getOptionsStaffProfile(_.get(res, 'data.result', []))
            break
          case 'category':
            options = getOptionsExpenseCategory(_.get(res, 'data.result', []))
            break
        }
        return {
          ...acc,
          [key]: { options: options, isLoading: false },
        }
      },
      {},
    )
  })

  store.dispatch(
    setFieldValue({
      key: 'masterData',
      value: { ...masterData, staffType: { options: staffTypeOptions } },
    }),
  )
}
