export const filtersWithCostCenter = [
  {
    label: 'Cost Center / Department ที่แสดง',
    nameCheckBox: 'costCenterChecked',
    nameInput: 'costCenter',
    type: 'select',
    placeholder: 'เลือก Cost Center',
  },
  {
    label: 'Account Code (Old/New)',
    nameCheckBox: 'accountCodeChecked',
    nameInput: 'accountCode',
    type: 'select',
    placeholder: 'เลือก Account Code',
  },
  {
    label: 'Staff type',
    nameCheckBox: 'staffTypeChecked',
    nameInput: 'staffType',
    type: 'select',
    placeholder: 'เลือก Staff type',
  },
  {
    label: 'Staff profile',
    nameCheckBox: 'staffProfileChecked',
    nameInput: 'staffProfile',
    type: 'select',
    placeholder: 'เลือก Staff profile',
  },
  {
    label: 'Categories',
    nameCheckBox: 'categoryChecked',
    nameInput: 'category',
    type: 'select',
    placeholder: 'เลือก Categories',
  },
]

export const filtersWithoutCostCenter = [
  {
    label: 'Account Code (Old/New)',
    nameCheckBox: 'accountCodeChecked',
    nameInput: 'accountCode',
    type: 'select',
    placeholder: 'เลือก Account Code',
  },
  {
    label: 'Staff type',
    nameCheckBox: 'staffTypeChecked',
    nameInput: 'staffType',
    type: 'select',
    placeholder: 'เลือก Staff type',
  },
  {
    label: 'Staff profile',
    nameCheckBox: 'staffProfileChecked',
    nameInput: 'staffProfile',
    type: 'select',
    placeholder: 'เลือก Staff profile',
  },
  {
    label: 'Categories',
    nameCheckBox: 'categoryChecked',
    nameInput: 'category',
    type: 'select',
    placeholder: 'เลือก Categories',
  },
]
