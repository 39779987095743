import React, { useState, useEffect } from 'react'
import { shallowEqual, useDispatch } from 'react-redux'
import Close from '@mui/icons-material/Close'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import Divider from '@mui/material/Divider'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormGroup from '@mui/material/FormGroup'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import {
  StyledDrawer,
  StyledFooter,
  StyledGroupBox,
  StyledHeadLabel,
} from '../../../../../components/FilterDrawer/Styled'
import TextInput from '../../../../../components/Input/TextInput'
import * as events from './event'
import _ from 'lodash'
import DateRangeInput from '../../../../../components/DateRangeInput'
import { useSelector } from 'react-redux'
import { toggleFiltersReqDrawer } from '../../handler/handleClickOpenDrawer'
import { CustomDropdown } from '../../../../../components/CRUD/components/CustomDropdown'
import { filterReqItems } from '../../model/filterItems'
import { defaultReqFilter } from '../../model/defaultFilter'
import { setFieldValue } from '../../../../../redux/slices/inventory/dashboard'
import { StyledList } from './styled'
import { handleInputNumber } from '../../../../../components/CRUD/handler/handleInputNumber'
import { regex } from '../../../../../components/CRUD/enum/regex'
import {
  handleNumberKeyDown,
  handlePasteFormat,
} from '../../../../../utils/lib'
import { store } from '../../../../../App'
import { TooltipDialog } from '../../../../../modules/ETesting/Dashboard/View/components/Styled'
import { clearSelectDropdown } from '../FilterDrawer/event'

export const ListCheckBox = ({
  listArray,
  stateFilter,
  setFilter,
  // handleChange,
  station,
}) => {
  const { filterList } = store.getState().inventoryDashboard
  return listArray.map((item, index) => {
    let list = item?.list ?? []
    if (filterList?.[item.nameInput]) {
      list = filterList?.[item.nameInput]
    }
    return (
      <StyledGroupBox key={index}>
        <FormGroup>
          <FormControlLabel
            label={item.label}
            control={
              <Checkbox
                inputProps={{
                  'data-testid': `check-${item.nameCheckBox}`,
                }}
                name={item.nameCheckBox}
                checked={stateFilter[item.nameCheckBox]}
                onChange={(e) => {
                  events.handleChange({
                    value: e.target.checked,
                    key: e.target.name,
                    filter: stateFilter,
                    setFilter,
                  })
                }}
              />
            }
            sx={!stateFilter[item.nameCheckBox] ? { marginBottom: -1 } : {}}
          />

          {stateFilter[item.nameCheckBox] && (
            <>
              {item.type === 'daterange' && (
                <DateRangeInput
                  placeholder={item.placeholder}
                  dateState={{
                    startDate: stateFilter.startDate || new Date(),
                    endDate: stateFilter.endDate || new Date(),
                    display: stateFilter.displayDate,
                    key: 'selection',
                  }}
                  onChange={(selectedDates) => {
                    events.handleSelectDate(
                      selectedDates,
                      stateFilter,
                      setFilter,
                    )
                  }}
                />
              )}
              {item.type == 'checkbox' && (
                <StyledList>
                  <ListResult
                    filter={stateFilter}
                    setFilter={setFilter}
                    arrayResult={
                      item.nameInput == 'station' ? station : item.list
                    }
                    listKeys={item.nameInput}
                  />
                </StyledList>
              )}

              {item.type === 'select_dropdown' && (
                <CustomDropdown
                  id={item.nameInput}
                  name={item.nameInput}
                  labelName=""
                  fieldName={`${item.nameInput}-filter-dropdown`}
                  placeholder={item.placeholder}
                  isCustomOption={true}
                  customOptions={{ isLoading: false, options: list }}
                  handleExtraChange={(event, option) => {
                    setFilter({
                      ...stateFilter,
                      [item.nameInput]: `${option?.value ?? ''}`,
                    })
                  }}
                  renderOption={
                    item?.nameInput == 'courseReq'
                      ? (propsOption, option) => {
                          return (
                            <TooltipDialog title={option.label} placement="top">
                              <Box
                                {...propsOption}
                                key={option.value}
                                id={option.value}
                              >
                                <Typography
                                  variant="body1"
                                  id={option.value}
                                  style={{
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                  }}
                                >
                                  {option.label}
                                </Typography>
                              </Box>
                            </TooltipDialog>
                          )
                        }
                      : undefined
                  }
                />
              )}
              {item.type === 'number_between' && (
                <Box display="flex" gap={2}>
                  <TextInput
                    id={`${item.inputFrom}`}
                    name={`${item.inputFrom}`}
                    labelText="ตั้งแต่"
                    type="basic"
                    placeholder={item.placeholder}
                    inputProps={{
                      'data-testid': `input-${item.inputFrom}`,
                      onInput: (e) => {
                        handleInputNumber(e, regex.NUMBER)
                      },
                      onKeyDown: (e) => {
                        handleNumberKeyDown(e, ['.'])
                      },
                      onPaste: handlePasteFormat,
                    }}
                    disabled={!stateFilter[item.nameCheckBox]}
                    value={stateFilter[`${item.inputFrom}`]}
                    onChange={(e) => {
                      events.handleChange({
                        key: e.target.name,
                        value: e.target.value,
                        filter: stateFilter,
                        setFilter,
                      })
                    }}
                    isShowTextError={false}
                  />
                  <TextInput
                    id={`${item.inputTo}`}
                    name={`${item.inputTo}`}
                    labelText="ถึง"
                    type="basic"
                    placeholder={item.placeholder}
                    inputProps={{
                      'data-testid': `input-${item.inputTo}`,
                      onInput: (e) => {
                        handleInputNumber(e, regex.NUMBER)
                      },
                      onKeyDown: (e) => {
                        handleNumberKeyDown(e, ['.'])
                      },
                      onPaste: handlePasteFormat,
                    }}
                    disabled={!stateFilter[item.nameCheckBox]}
                    // boxSx={{ mx: 3 }}
                    value={stateFilter[`${item.inputTo}`]}
                    onChange={(e) => {
                      events.handleChange({
                        key: e.target.name,
                        value: e.target.value,
                        filter: stateFilter,
                        setFilter,
                      })
                    }}
                    isShowTextError={false}
                  />
                </Box>
              )}
            </>
          )}
        </FormGroup>
      </StyledGroupBox>
    )
  })
}

export const ListResult = ({ filter, setFilter, arrayResult, listKeys }) => {
  return arrayResult.map((item, index) => {
    return (
      <FormControlLabel
        key={index}
        label={item.label}
        control={
          <Checkbox
            inputProps={{ 'data-testid': `check-${item.name}` }}
            name={item.name}
            checked={_.get(filter[listKeys], item.name, false)}
            onChange={(e) => {
              events.handleChange({
                value: e.target.checked,
                key: e.target.name,
                listKey: listKeys,
                filter,
                setFilter,
              })
            }}
          />
        }
      />
    )
  })
}

const FilterReqDrawer = () => {
  const [filter, setFilter] = useState(defaultReqFilter())
  const [station, setStation] = useState([])
  const dispatch = useDispatch()
  const { open, filterMemo } = useSelector(
    (state) => ({
      filterMemo: state.inventoryDashboard?.filterReqMemo,
      open: state.inventoryDashboard?.filtersReqDrawer?.isOpen,
    }),
    shallowEqual,
  )

  useEffect(() => {
    if (!_.isEmpty(filterMemo)) {
      setFilter(filterMemo)
      return
    }
    setFilter(defaultReqFilter())
  }, [open])

  useEffect(() => {
    events.handleFetchStation(setStation)
    events.fetchFilterOptions()
  }, [])

  return (
    <StyledDrawer open={open} onClose={() => toggleFiltersReqDrawer(false)}>
      <StyledHeadLabel>
        <Box>
          <Typography variant="h5">ตัวกรอง</Typography>
          <IconButton
            data-testid="btn-close-drawer"
            color="primary"
            component="span"
            onClick={() => toggleFiltersReqDrawer(false)}
          >
            <Close />
          </IconButton>
        </Box>
        <Divider />
      </StyledHeadLabel>

      <ListCheckBox
        listArray={filterReqItems()}
        stateFilter={filter}
        setFilter={setFilter}
        station={station}
        handleChange={() => {}}
      />
      <StyledFooter>
        <Button
          data-testid="btn-clear"
          variant="outlined"
          onClick={async () => {
            // await events.handleChange({
            //   value: false,
            //   key: 'startDateChecked',
            //   filter,
            //   setFilter,
            //   isCheck: true,
            // })
            clearSelectDropdown({ arrayCheckbox: filterReqItems() })
            setFilter(defaultReqFilter())
          }}
        >
          ล้าง
        </Button>
        <Button
          data-testid="btn-submit"
          onClick={() => {
            dispatch(events.handleReqFiltering(filter, station))
            dispatch(setFieldValue({ key: 'filterReqMemo', value: filter }))
          }}
          variant="contained"
        >
          ยืนยัน
        </Button>
      </StyledFooter>
    </StyledDrawer>
  )
}
export default FilterReqDrawer
