import { Box, Typography } from '@mui/material'
import React from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import RankingCard from '../ModuleCard/RankingCard'
import { shallowEqual, useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { openTableDrawer } from '../../../../../redux/slices/inventory/dashboard'
import RemarkTooltip from '../../../../../modules/EConfiguration/components/RemarkTooltip'

const Ranking = () => {
  const { stock, sold, withdraw } = useSelector(
    (state) => ({
      stock: state.inventoryDashboard?.overview?.ranking?.stock,
      sold: state.inventoryDashboard?.overview?.ranking?.sold,
      withdraw: state.inventoryDashboard?.overview?.ranking?.withdraw,
    }),
    shallowEqual,
  )

  const dispatch = useDispatch()

  return (
    <Box>
      <Card>
        <CardContent>
          <Box display="flex" alignItems="center" gap={2}>
            <Typography variant="h6">
              การจัดอันดับ <RemarkTooltip />
            </Typography>
          </Box>

          <Box display="grid" gap={2} mt={3} gridTemplateColumns="1fr 1fr">
            <Box>
              <RankingCard
                title="รายการทรัพย์สินที่มีการเบิกมากที่สุด (Stock)"
                type="latestCourse"
                list={stock}
                header1="ชื่อทรัพย์สิน"
                header2="จำนวน : มูลค่า"
                unit="จำนวนมูลค่า"
                boxSx={{ height: '100%' }}
                handleClickButton={() => {
                  dispatch(
                    openTableDrawer({
                      header: 'รายการทรัพย์สินที่มีการเบิกมากที่สุด (Stock)',
                      drawerType: 'tableInTable',
                      courseType: 'ranking',
                    }),
                  )
                }}
              />
            </Box>
            <Box>
              <RankingCard
                title="รายการทรัพย์สินใกล้หมด (Stock)"
                type="rankingStock"
                list={sold}
                header1="ชื่อทรัพย์สิน"
                header2="จำนวน"
                header3="คลังทรัพย์สิน"
                unit="ชิ้น"
                handleClickButton={() => {
                  dispatch(
                    openTableDrawer({
                      header: 'รายการทรัพย์สินใกล้หมด (Stock)',
                      drawerType: 'tableInTable',
                      courseType: 'rankingStock',
                    }),
                  )
                }}
              />
            </Box>
            <Box>
              <RankingCard
                title="รายชื่อที่เบิกของมากที่สุด"
                type="topCourse"
                list={withdraw}
                header1="ชื่อ"
                header2="จำนวน Ticket"
                unit="ticket"
                handleClickButton={() =>
                  dispatch(
                    openTableDrawer({
                      header: 'รายชื่อที่เบิกของมากที่สุด',
                      drawerType: 'tableInTable',
                      courseType: 'rankingName',
                    }),
                  )
                }
              />
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Box>
  )
}

export default Ranking
