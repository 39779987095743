import HorizontalBarChart from 'src/components/Chart/HorizontalBarChart'
import { Row } from '../Styled'
import { Typography } from '@mui/material'
import _ from 'lodash'
import { useTheme } from '@mui/system'

export const StatusRow = ({ data, elementId, keyPC = 'AllYear' }) => {
  const theme = useTheme()

  return (
    <Row gap={2} alignItems="center" justifyContent="space-between">
      <Typography variant="body1b">
        {_.get(data, `percent${keyPC}`, 0)}%
      </Typography>
      <HorizontalBarChart
        width="80%"
        height={32}
        labels={['']}
        dataSet={{
          backgroundColor: _.get(data, `budgetExceeded${keyPC}`, false)
            ? theme.palette.error.main
            : '#0B4F1F',
          data: _.get(data, `percent${keyPC}`, 0),
        }}
        eleId={elementId}
        borderRadius={10}
        barThickness={32}
      />
    </Row>
  )
}
