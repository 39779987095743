import { createSelector, createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'
import {
  getAcquiredSkillList,
  getBatchDetail,
  getECertificationList,
  getELearningList,
  getLicenseList,
  getModuleList,
  getAdminList,
  getTrainerList,
  getTrainerQuestion,
  postGetAllCourseBatch,
} from '../../../../services/eLearning/course/form'
import { getDetail } from '../../../../services/eLearning/course/detail'
import {
  getAllCourse,
  getAllDistributionChannel,
  getAllProductType,
} from '../../../../services/manageContent'
import { getLearningPointList } from '../../../../services/learningPoint/settingPoint'
import { FORM_TAB } from '../../../../modules/ELearning/Course/Form/model'
import { COURSE_TYPE } from '../../../../constants/eLearning'
import { RESPONSIBLE_TYPE, initialState, initialValues } from './model'
import { handleCourseObject, handleOptionObj } from './events'

const eLearningCourseForm = createSlice({
  name: 'eLearningCourseForm',
  initialState,
  reducers: {
    setInitialCreateForm: (state, { payload }) => {
      state.onGoing = false
      state.isLoading = false
      state.isOptionLoading = false
      state.isSettingLoading = false
      state.isUploadLoading = false
      state.isUploadSuccess = false
      state.isDisableButton = true
      state.levelOfLearnerOption = []
      state.uploadError = null
      const isOIC = payload.courseType === COURSE_TYPE.OIC
      const setting = _.get(initialState.body, 'setting', {})
      const sellCourse = isOIC
        ? { isSell: false, cost: null, description: null }
        : undefined
      state.body = {
        ...initialState.body,
        ...payload,
        setting: { ...setting, sellCourse },
      }
    },
    setInitialMore: (state, { payload }) => {
      state.table = {
        ...state.table,
        ...payload,
      }
    },
    startLoading: (state) => {
      state.isLoading = true
    },
    stopLoading: (state) => {
      state.isLoading = false
    },
    setEditData: (state, { payload }) => {
      const { keyField, value } = payload
      state.data[keyField] = value
    },
    startOptionLoading: (state) => {
      state.isOptionLoading = true
    },
    stopOptionLoading: (state) => {
      state.isOptionLoading = false
    },
    startECertLoading: (state) => {
      state.isECertLoading = true
    },
    stopECertLoading: (state) => {
      state.isECertLoading = false
    },
    startUploadLoading: (state) => {
      state.isUploadLoading = true
    },
    stopUploadLoading: (state) => {
      state.isUploadLoading = false
    },
    setUploadSuccess: (state) => {
      state.isUploadSuccess = true
    },
    setOpenDrawer: (state, { payload }) => {
      state.isDrawerOpen = payload
    },
    setUploadError: (state, { payload }) => {
      state.uploadError = payload
    },
    setDisableButton: (state, { payload }) => {
      state.isDisableButton = payload
    },
    setBody: (state, { payload }) => {
      state.body = payload
    },
    setChange: (state, { payload }) => {
      const { key, value } = payload
      state[key] = value
    },
    setFieldValue: (state, { payload }) => {
      const { key, value } = payload
      _.set(state.body, key, value)

      if (key === 'setting.distribution') {
        const distribution = _.get(state.body, 'setting.distribution', []).map(
          (item) =>
            state.distributionOption.find(
              (option) => option.value === item.value,
            ),
        )
        let option = []
        _.forEach(distribution, (dis) => (option = _.concat(option, dis.level)))
        state.levelOfLearnerOption = _.uniqBy(option, 'value')
        state.body.levelOfLearner = []
      }
    },
    setBatchData: (state, { payload }) => {
      state.batchData = payload
    },
    resetBatchData: (state) => {
      state.batchData = {}
    },
    setCropperData: (state, { payload }) => {
      const { key, value } = payload
      state.cropper[key] = value
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getModuleList.fulfilled, (state, { payload }) => {
        state.moduleOption = _.get(payload.data, 'result', []).map(
          (module) => ({
            ...module,
            label: `${_.get(module, 'moduleCode', '')} ${_.get(
              module,
              'name',
              '',
            )}`,
            value: _.get(module, 'uuid', ''),
          }),
        )
      })
      .addCase(getModuleList.rejected, (state, action) => {
        state.error = action.meta?.message
      })
      .addCase(getAllDistributionChannel.fulfilled, (state, { payload }) => {
        state.distributionOption = _.get(payload.data, 'result', []).map(
          (distribution) => ({
            label: _.get(distribution, 'distribution', ''),
            value: _.get(distribution, 'uuid', ''),
            uuid: _.get(distribution, 'uuid', ''),
            level: _.get(distribution, 'level', []).map((levelOfLearner) => ({
              label: _.get(levelOfLearner, 'title', ''),
              value: _.get(levelOfLearner, 'uuid', ''),
              uuid: _.get(levelOfLearner, 'uuid', ''),
            })),
          }),
        )
      })
      .addCase(getAllDistributionChannel.rejected, (state, action) => {
        state.error = action.meta?.message
      })
      .addCase(getLicenseList.fulfilled, (state, { payload }) => {
        state.licenseList = _.get(payload.data, 'result', [])
      })
      .addCase(getLicenseList.rejected, (state, action) => {
        state.error = action.meta?.message
      })
      .addCase(getAllCourse.fulfilled, (state, { payload }) => {
        const result = handleCourseObject(payload)
        state.allCourseList = _.uniqBy(
          _.concat(state.allCourseList, result),
          'uuid',
        )
      })
      .addCase(getAllCourse.rejected, (state, action) => {
        state.error = action.meta?.message
      })
      .addCase(getELearningList.fulfilled, (state, { payload }) => {
        const result = handleCourseObject(payload)
        state.allCourseList = _.uniqBy(
          _.concat(state.allCourseList, result),
          'uuid',
        )
      })
      .addCase(getELearningList.rejected, (state, action) => {
        state.error = action.meta?.message
      })
      .addCase(getECertificationList.fulfilled, (state, { payload }) => {
        state.eCertificateList = _.get(payload.data, 'result', [])
      })
      .addCase(getECertificationList.rejected, (state, action) => {
        state.error = action.meta?.message
      })
      .addCase(getAdminList.fulfilled, (state, { payload }) => {
        state.adminStaffList = handleOptionObj(payload, RESPONSIBLE_TYPE.ADMIN)
      })
      .addCase(getAdminList.rejected, (state, action) => {
        state.error = action.meta?.message
      })
      .addCase(getTrainerList.fulfilled, (state, { payload }) => {
        state.speakerList = handleOptionObj(payload, RESPONSIBLE_TYPE.SPEAKER)
      })
      .addCase(getTrainerList.rejected, (state, action) => {
        state.error = action.meta?.message
      })
      .addCase(getTrainerQuestion.fulfilled, (state, { payload }) => {
        state.trainerQuestionList = handleOptionObj(
          payload,
          RESPONSIBLE_TYPE.TRAINERQUESTION,
        )
      })
      .addCase(getTrainerQuestion.rejected, (state, action) => {
        state.error = action.meta?.message
      })
      .addCase(getAcquiredSkillList.fulfilled, (state, { payload }) => {
        state.acquireSkillList = _.get(payload.data, 'result', []).map(
          (item) => ({
            label: `${item?.skillType?.skillType || ''}: ${
              item?.acquiredSkill
            }`,
            value: _.get(item, 'uuid', ''),
          }),
        )
      })
      .addCase(getAcquiredSkillList.rejected, (state, action) => {
        state.error = action.meta?.message
      })
      .addCase(getAllProductType.fulfilled, (state, { payload }) => {
        state.productTypeList = _.get(payload.data, 'result', []).map(
          (item) => ({
            label: item.productType,
            value: _.get(item, 'uuid', ''),
            uuid: _.get(item, 'uuid', ''),
          }),
        )
      })
      .addCase(getAllProductType.rejected, (state, action) => {
        state.error = action.meta?.message
      })
      .addCase(getDetail.fulfilled, (state, { payload }) => {
        state.body = payload.data
        state.body.courseAccessExpiration =
          payload.data.courseAccessExpiration ||
          initialValues.courseAccessExpiration
        state.oldPeriodStart = payload.data.periodStart
        state.oldDueDate = payload.data.dueDate
        state.tab = FORM_TAB.course
        state.onGoing = false // new flow no on-going

        const distribution = _.get(
          payload.data,
          'setting.distribution',
          [],
        ).map((item) =>
          state.distributionOption.find(
            (option) => option.value === item.value,
          ),
        )
        let option = []
        _.forEach(
          distribution,
          (dis) => (option = _.concat(option, _.get(dis, 'level', []))),
        )
        state.levelOfLearnerOption = _.uniqBy(option, 'value')
      })
      .addCase(getDetail.rejected, (state, action) => {
        state.error = action.meta?.message
      })
      .addCase(getBatchDetail.fulfilled, (state, { payload }) => {
        state.batchData = payload
        state.isLoading = false
      })
      .addCase(getBatchDetail.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getBatchDetail.rejected, (state, action) => {
        state.error = action.meta?.message
      })
      .addCase(getLearningPointList.fulfilled, (state, { payload }) => {
        state.learningPointList = payload.data.result.map((item) => ({
          value: item.uuid,
          label: item.name,
        }))
      })
      .addCase(getLearningPointList.rejected, (state, action) => {
        state.error = action.meta?.message
      })
      .addCase(postGetAllCourseBatch.fulfilled, (state, { payload }) => {
        state.batchDisableDate = payload.result.map((batch) => ({
          startDate: batch.periodStart,
          finishDate: batch.periodEnd,
        }))
      })
      .addCase(postGetAllCourseBatch.rejected, (state, action) => {
        state.error = action.meta?.message
      })
  },
})

export const fieldSelector = (keyField) =>
  createSelector(
    (state) => state.eLearningCourseForm,
    (state) => _.get(state.body, keyField, []).map((item) => item.id),
  )

export const {
  setInitialCreateForm,
  startLoading,
  stopLoading,
  startOptionLoading,
  stopOptionLoading,
  startECertLoading,
  stopECertLoading,
  startUploadLoading,
  stopUploadLoading,
  setOpenDrawer,
  setUploadSuccess,
  setUploadError,
  setDisableButton,
  setBody,
  setChange,
  setFieldValue,
  setBatchData,
  resetBatchData,
  setInitialMore,
  setCropperData,
  setEditData,
} = eLearningCourseForm.actions

export default eLearningCourseForm.reducer
