import { setFieldValue } from '../../../redux/slices/eConfig'
import { openDialog, closeDialog } from '../../../redux/slices/dialog'
import { postEConfiguration } from '../../../services/eConfiguration'
import { objectList } from '../../../redux/slices/eConfig/event'

export const handleChangeInput = (key, value) => (dispatch) => {
  dispatch(setFieldValue({ key, value }))
}

export const handleConfirmConfig = (props) => async (dispatch) => {
  const { setDialog, values, openSuccessDialog, fetchHistory, fetchEConfig } =
    props
  const data = Object.keys(values).map((key) => {
    let value = values[key]
    if (key === 'CC_EMAIL_ANNOUNCEMENT_EXAMINATION') {
      const emailStr = values[key].toString()
      value = emailStr
    } else if (key === 'EL_TIME_TODO_LIST_APPROVED_TRAINING_APPICATION') {
      value = values[key].replace(':undefined', '')
    } else if (objectList.includes(key)) {
      value = JSON.stringify(values[key])
    }
    return {
      key: key,
      value: value,
    }
  })
  const body = {
    data: data,
  }
  setDialog({
    variant: 'save',
    content: '',
    open: true,
    isLoading: true,
  })
  const response = await dispatch(postEConfiguration(body))
  if (!response?.error) {
    setDialog({
      variant: 'save',
      content: '',
      open: false,
      isLoading: false,
    })
    dispatch(
      openSuccessDialog({
        handleConfirm: () => {
          dispatch(closeDialog())
        },
      }),
    )
    fetchEConfig()
    fetchHistory()
  } else {
    dispatch(
      openDialog({
        type: 'fail',
        title: 'ผิดพลาด',
        message: 'ระบบขัดข้อง',
        isLoading: false,
        handleConfirm: () => {
          dispatch(closeDialog())
        },
      }),
    )
  }
}

export const clearLeadingZeros = (value) => {
  return value.replace(/^0+(\d)/, '$1')
}
