import { Box, Typography } from '@mui/material'
import React from 'react'
import { breadcrumb } from '../../models/breadcrumb'
import { breadcrumbByDepartment } from '../../models/breadcrumbByDepartment'
import Breadcrumbs from '../../../../../components/Breadcrumbs'
import BasicTabs from 'src/components/BasicTabs'
import { tabsList } from '../../models/tabsList'
import { shallowEqual, useSelector } from 'react-redux'
import { handleChangeTab } from '../../handler/handleChangeTab'
import { useParams } from 'react-router-dom'
import FiltersSummary from './FiltersSummary'
import { validatePermission } from 'src/utils/lib'
import useLocalStorage from '@rehooks/local-storage'
import RemarkTooltip from '../../../../../modules/EConfiguration/components/RemarkTooltip'

const Header = () => {
  const { uuid } = useParams()

  const { tabActive, costCenterName } = useSelector(
    (state) => ({
      tabActive: state.expense.dashboard.tabActive,
      costCenterName: state.expense.dashboard.costCenterName,
    }),
    shallowEqual,
  )
  const [user] = useLocalStorage('user')

  const hasPermissionDashboard = validatePermission({
    user: user,
    moduleType: 'REPORT_EXPENSE',
    permission: ['DASHBOARD_ADMIN', 'DASHBOARD_HF', 'DASHBOARD_TSD'],
  })

  return (
    <Box display="flex" flexDirection="column" gap={2.5}>
      <Box>
        <Typography variant="h4">
          {uuid ? costCenterName : 'Expense Dashboard'} <RemarkTooltip />
        </Typography>
        <Breadcrumbs
          menuList={
            uuid
              ? breadcrumbByDepartment(costCenterName, hasPermissionDashboard)
              : breadcrumb
          }
        />
      </Box>
      <FiltersSummary />
      {!uuid && (
        <BasicTabs
          tabActive={tabActive}
          tabsList={tabsList}
          handleChangeTab={handleChangeTab}
        />
      )}
    </Box>
  )
}

export default Header
