import React, { useEffect, useState } from 'react'

import Box from '@mui/material/Box'
import _ from 'lodash'
import Typography from '@mui/material/Typography'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import useTheme from '@mui/system/useTheme'
import TextField from '@mui/material/TextField'
import dayjs from 'dayjs'
import { handleDateBetween, checkTrainingPlatform } from './Handle'
import { fetchCourse } from '../../api/Training'
import Autocomplete from '../../../../components/Input/Autocomplete'
import Select from '../../../../components/Input/Select'
import TextInput from '../../../../components/Input/TextInput'
import DatePickerForm from '../../../../components/Input/DatePickerTraining'
import AlertDialog from '../../../../components/AlertDialog'
import SubInfoInput from '../../../../components/SubInfoInput'
import { WORK_WORK_LIST } from '../../Text'
import TrainingOther, { DataList, ShowTrainingPlatform } from './Form'

export default function Training({
  type,
  state,
  workType,
  stateForm,
  setStateForm,
  validateForm,
  setValidateForm,
  options,
  optionSubWorkType,
  uuidStaff,
  productTypeStaff,
}) {
  const isTraining = workType === WORK_WORK_LIST[3]
  const [course, setCourse] = useState([])
  const [loading, setLoading] = useState(false)
  const [optionClassroom, setOptionClassroom] = useState([])
  const [dialog, setDialog] = useState({
    variant: 'fail',
    title: 'ผิดพลาด',
    content: 'กรุณาระบุหน้าที่ของวิทยากร',
    open: false,
  })
  const courseDefault = course.find(
    (item) => item.value === _.get(stateForm, 'course.uuid', ''),
  )
  const nameForLearner = _.get(courseDefault, 'nameForLearner', '')

  useEffect(() => {
    if (_.get(validateForm, 'speaker', false)) {
      setDialog({ ...dialog, open: true })
      setValidateForm((prevState) => ({
        ...prevState,
        training: {},
      }))
    }
  }, [validateForm])

  useEffect(() => {
    const checkDate = handleDateBetween({
      stateForm,
      setStateForm,
      course,
      uuidStaff,
      productTypeStaff,
      type,
    })
    if (checkDate && workType === WORK_WORK_LIST[3]) {
      fetchCourse({
        setCourse,
        setLoading,
        setStateForm,
        startDate: stateForm.startDate,
        finishDate: stateForm.finishDate,
      })
    }
  }, [stateForm.startDate, stateForm.finishDate])

  useEffect(() => {
    if (_.get(options, 'classroom', []).length && !loading)
      checkTrainingPlatform({
        stateForm,
        course,
        options: _.get(options, 'classroom', []),
        setOptionClassroom,
        isFirst: true,
      })
  }, [loading, options.classroom, course])

  const theme = useTheme()

  return (
    <Box
      sx={{
        opacity: loading ? 0.4 : 1,
        pointerEvents: loading ? 'none' : '',
        display: 'flex',
        flexDirection: 'column',
        gap: 3,
      }}
    >
      <Select
        disabled={true}
        showTextError={false}
        id="workType"
        name="workType"
        labelText="ประเภทตารางงาน"
        type="basic"
        textError={_.get(validateForm, 'workType.workType', '')}
        required
        options={options.work}
        placeholder="กรุณาเลือก"
        value={_.get(stateForm, 'workType.workType', '')}
      />
      {workType === WORK_WORK_LIST[4] && (
        <TrainingOther
          validateForm={validateForm}
          options={options}
          stateForm={stateForm}
          setLoading={setLoading}
          handleChangeForm={handleChangeForm}
          course={course}
          setStateForm={setStateForm}
          type={type}
          setValidateForm={setValidateForm}
          setOptionClassroom={setOptionClassroom}
        />
      )}
      <DatePickerForm
        state={stateForm}
        stateData={state}
        validate={validateForm}
        handleChangeForm={handleChangeForm}
        setStateForm={setStateForm}
        type={type}
        setValidateForm={setValidateForm}
        options={options}
      />

      {workType === WORK_WORK_LIST[3] && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 3,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: 3,
              width: '100%',
            }}
          >
            {!loading && (
              <Box sx={{ width: '50%' }}>
                <Autocomplete
                  id="course"
                  name="course"
                  labelText="หลักสูตร"
                  type="basic"
                  required
                  isHideSort={true}
                  disabled={
                    _.get(stateForm, 'dateBetween', []).length < 1
                      ? true
                      : course.length
                      ? false
                      : true
                  }
                  options={
                    course.length
                      ? course.sort(
                          (a, b) => -b.englishName.localeCompare(a.englishName),
                        )
                      : []
                  }
                  defaultValue={
                    course.length ? courseDefault : { label: '', value: '' }
                  }
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => (
                    <TextField placeholder="กรุณาเลือก" {...params} />
                  )}
                  handleChange={(event) => {
                    handleChangeForm({
                      key: 'course',
                      value: _.get(event, 'target.id', ''),
                      course,
                      options,
                      stateForm,
                      setStateForm,
                      type,
                      setValidateForm,
                    })
                    checkTrainingPlatform({
                      stateForm,
                      value: _.get(event, 'target.id', ''),
                      course,
                      options: _.get(options, 'classroom', []),
                      setOptionClassroom,
                    })
                  }}
                  value={_.get(stateForm, 'course.uuid', '')}
                  textError={_.get(validateForm, 'course', '')}
                  showTextError={
                    _.get(validateForm, 'course', '') ? true : false
                  }
                  subComponent={<SubInfoInput label={nameForLearner} />}
                />
              </Box>
            )}
            <ShowTrainingPlatform
              value={_.get(stateForm, 'course.uuid', '')}
              course={course}
              handleChangeForm={handleChangeForm}
              breakMealBudget={_.get(stateForm, 'course.breakMealBudget', 0)}
              stateForm={stateForm}
              options={options}
              setStateForm={setStateForm}
              type={type}
              setValidateForm={setValidateForm}
            />
          </Box>
          <Box>
            <TextInput
              id="subject"
              name="subject"
              type="basic"
              labelText="หัวข้อ"
              placeholder="ระบุหัวข้อ"
              multiline={true}
              rows={3}
              onChange={(event) =>
                handleChangeForm({
                  key: 'subject',
                  value: event.target.value,
                  course,
                  options,
                  stateForm,
                  setStateForm,
                  type,
                  setValidateForm,
                })
              }
              value={_.get(stateForm, 'subject', '')}
              textError={_.get(validateForm, 'subject', '')}
            />
          </Box>
          <Box>
            <TextInput
              id="remark"
              name="remark"
              type="basic"
              labelText="หมายเหตุ"
              placeholder="หมายเหตุ"
              multiline={true}
              rows={3}
              onChange={(event) =>
                handleChangeForm({
                  key: 'remark',
                  value: event.target.value,
                  course,
                  options,
                  stateForm,
                  setStateForm,
                  type,
                  setValidateForm,
                })
              }
              value={_.get(stateForm, 'remark', '')}
              textError={_.get(validateForm, 'remark', '')}
            />
          </Box>
        </Box>
      )}
      {_.get(stateForm, 'dateBetween', []).length > 1 && (
        <>
          <FormControl component="fieldset" sx={{ width: '100%' }}>
            <FormLabel component="legend" sx={{ display: 'flex' }}>
              ช่วงเวลาการอบรม
              <Typography sx={{ lineHeight: 1, ml: 0.5 }} color={'error'}>
                *
              </Typography>
            </FormLabel>
            <RadioGroup
              aria-label="gender"
              defaultValue={_.get(stateForm, 'typeDate', 'DUPLICATE')}
              name="radio-buttons-group"
              onChange={(event) =>
                handleChangeForm({
                  key: 'typeDate',
                  value: event.target.value,
                  course,
                  options,
                  stateForm,
                  setStateForm,
                  type,
                  setValidateForm,
                  setLoading,
                })
              }
            >
              <FormControlLabel
                value="DUPLICATE"
                control={<Radio />}
                label="เหมือนกันทุกวัน"
              />
              <FormControlLabel
                value="CUSTOM"
                control={<Radio />}
                label="กำหนดเอง"
              />
            </RadioGroup>
          </FormControl>
        </>
      )}
      {_.get(stateForm, 'dateBetween', []).length > 0 && (
        <DataList
          type={type}
          state={state}
          options={options}
          course={course}
          loading={loading}
          setLoading={setLoading}
          validateForm={validateForm}
          setValidateForm={setValidateForm}
          optionSubWorkType={optionSubWorkType}
          stateForm={stateForm}
          setStateForm={setStateForm}
        />
      )}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <Box sx={{ width: '100%' }}>
          <Typography variant="body2b">Department</Typography>
          <Typography variant="body1">
            {_.get(stateForm, 'area.departments', '')}
          </Typography>
        </Box>
        <Box sx={{ width: '100%', ml: 3 }}>
          <Typography variant="body2b">Station</Typography>
          <Typography variant="body1">
            {_.get(stateForm, 'area.station', '')}
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: 3,
        }}
      >
        <TextInput
          required={isTraining}
          sx={{ width: '100%' }}
          id="expectNumberPeople"
          labelText="จำนวนคนที่คาดหวัง"
          placeholder="ระบุจำนวน"
          maxlength={6}
          type="basic"
          onChange={(event) =>
            handleChangeForm({
              key: 'expectNumberPeople',
              value: event.target.value.replace(/[^\d]/g, ''),
              course,
              options,
              stateForm,
              setStateForm,
              type,
              setValidateForm,
            })
          }
          value={_.get(stateForm, 'expectNumberPeople', 0)}
          textError={_.get(validateForm, 'expectNumberPeople', '')}
        />
        <Select
          id="classroom"
          name="classroom"
          labelText="รูปแบบการจัดห้องเรียน"
          type="basic"
          required={isTraining}
          options={optionClassroom}
          placeholder="เลือกรูปแบบการจัดห้องเรียน"
          handleChange={(event) =>
            handleChangeForm({
              key: 'classroom',
              value: event.target.value,
              course,
              options,
              stateForm,
              setStateForm,
              type,
              setValidateForm,
            })
          }
          value={_.get(stateForm, 'classroom.uuid', '')}
          textError={_.get(validateForm, 'classroom', '')}
        />
      </Box>
      <>
        <Typography variant="body2b" sx={{ mb: -3 }}>
          ต้องการเตรียมอาหาร Break / Lunch หรือไม่{' '}
          <strong color={'error'}>*</strong>
        </Typography>
        <Typography variant="body2" sx={{ mb: -3 }}>
          ค่าใช้จ่ายที่อนุมัติต่อคนของหลักสูตรนี้{' '}
          {_.get(stateForm, 'course.breakMealBudget', 0)} บาท
        </Typography>
        <FormControl component="fieldset" sx={{ width: '100%' }}>
          <RadioGroup
            aria-label="gender"
            defaultValue={_.get(stateForm, 'break', 'false')}
            name="radio-buttons-group"
            onChange={(event) =>
              handleChangeForm({
                key: 'break',
                value: event.target.value === 'true' ? true : false,
                course,
                options,
                stateForm,
                setStateForm,
                type,
                setValidateForm,
              })
            }
          >
            <FormControlLabel
              value="false"
              control={<Radio />}
              label="ไม่ต้องการ"
              disabled={
                _.get(stateForm, 'workType.workType', '') === 'Activity Support'
                  ? false
                  : _.get(stateForm, 'dateBetween', []).length > 0 &&
                    !_.isEmpty(_.get(stateForm, 'course', {}))
                  ? false
                  : true
              }
            />
            <FormControlLabel
              value="true"
              control={<Radio />}
              label="ต้องการ"
              disabled={
                _.get(stateForm, 'workType.workType', '') === 'Activity Support'
                  ? false
                  : _.get(stateForm, 'dateBetween', []).length > 0 &&
                    !_.isEmpty(_.get(stateForm, 'course', {}))
                  ? false
                  : true
              }
            />
          </RadioGroup>
        </FormControl>
      </>
      {stateForm.break === true && (
        <>
          <Box>
            <TextInput
              sx={{ width: '100%' }}
              id="cost"
              name="cost"
              labelText="ค่าใช้จ่ายต่อหัว"
              type="basic"
              required
              maxlength={6}
              value={_.get(stateForm, 'cost', '')}
              endAdornmentText={'บาท'}
              onChange={(event) => {
                handleChangeForm({
                  key: 'cost',
                  value: event.target.value
                    ? Number(event.target.value.replace(/[^\d]/g, ''))
                    : '',
                  course,
                  options,
                  stateForm,
                  setStateForm,
                  type,
                  setValidateForm,
                })
              }}
              textError={_.get(validateForm, 'cost', '')}
              isShowTextError={
                _.isEmpty(_.get(validateForm, 'cost', '')) ? false : true
              }
              dialogRight={
                stateForm.cost > _.get(stateForm, 'course.breakMealBudget', 0)
                  ? {
                      color: theme?.palette?.warning?.main,
                      text: 'เกินงบ ต้องขออนุมัติ',
                    }
                  : null
              }
            />

            <Typography variant="body2">
              สามารถแก้ไขค่าใช้จ่ายได้ แต่หากเกินงบที่กำหนด จะต้องขออนุมัติ
            </Typography>
          </Box>

          {stateForm.cost > _.get(stateForm, 'course.breakMealBudget', 0) && (
            <TextInput
              required
              id="remarkCost"
              name="remarkCost"
              type="basic"
              labelText="หมายเหตุ"
              placeholder="หมายเหตุ"
              multiline={true}
              rows={3}
              onChange={(event) =>
                handleChangeForm({
                  key: 'remarkCost',
                  value: event.target.value,
                  course,
                  options,
                  stateForm,
                  setStateForm,
                  type,
                  setValidateForm,
                })
              }
              value={_.get(stateForm, 'remarkCost', '')}
              textError={_.get(validateForm, 'remarkCost', '')}
            />
          )}
        </>
      )}
      <AlertDialog
        open={dialog.open}
        setOpen={setDialog}
        handleClose={() => setDialog({ ...dialog, open: false })}
        title={dialog.title}
        variant={dialog.variant}
        onCancelClick={() => setDialog({ ...dialog, open: false })}
        onConfirmClick={() => setDialog({ ...dialog, open: false })}
        content={dialog.content}
      />
    </Box>
  )
}

export const handleChangeForm = ({
  key,
  value,
  course,
  options,
  stateForm,
  setStateForm,
  type,
  setValidateForm,
  setLoading,
}) => {
  setLoading && setLoading(true)
  const onlineClass = options.classroom.find((item) => item.label === 'Online')

  let isOnline = false
  if (key === 'course') {
    const courseObject = course.filter((a) => a.value === value)

    value = {
      id: _.get(courseObject, '[0]id', ''),
      uuid: _.get(courseObject, '[0]value', ''),
      courseCode: _.get(courseObject, '[0]courseCode', ''),
      breakMealBudget: _.get(courseObject, '[0]breakMealBudget', 0),
      englishName: _.get(courseObject, '[0]englishName', ''),
      nameForLearner: _.get(courseObject, '[0]nameForLearner', ''),
      speakerProductType: _.get(courseObject, '[0]speakerProductType', ''),
    }
    isOnline =
      _.get(courseObject, '[0].trainingPlatform', [])[0].trainingPlatform ===
      'Virtual Classroom'
  }
  if (key === 'classroom') {
    const classroomObject = options.classroom.filter((a) => a.value === value)

    value = {
      id: _.get(classroomObject, '[0]id', ''),
      uuid: _.get(classroomObject, '[0]value', ''),
      classroom: _.get(classroomObject, '[0]label', '')
        .replace(/\s+/g, '')
        .toUpperCase(),
    }
  }

  if (key === 'distributionChannel') {
    const distributionChannel = options.distributionChannel.find((item) => {
      return item.uuid === value
    })

    value = {
      id: _.get(distributionChannel, 'id', ''),
      uuid: _.get(distributionChannel, 'value', ''),
    }
  }

  if (key === 'typeDate') {
    setStateForm((prevState) => ({
      ...prevState,
      training: {
        ...prevState.training,
        dates:
          type !== 'edit'
            ? stateForm.dates.map((item) => {
                return {
                  ...item,
                  startTime: '08:30',
                  endTime: '17:00',
                  location: {
                    ...item.location,
                    classRoomOther: '',
                  },
                }
              })
            : stateForm.dates.map((item, index) => {
                let newItem = item
                if (index + 1 > stateForm.plans.length && value === 'CUSTOM') {
                  newItem = {
                    ...item,
                    meetingRooms: [],
                    links: [],
                  }
                }
                if (
                  index + 1 > stateForm.plans.length &&
                  value === 'DUPLICATE'
                ) {
                  newItem = {
                    ...stateForm.dates[0],
                    date: stateForm.dateBetween[index],
                  }
                }
                return newItem
              }),
        [key]: value,
      },
    }))
  } else {
    if (key === 'trainingPlatform' && value.length == 1) {
      isOnline =
        _.get(value, '[0].trainingPlatform', []) === 'Virtual Classroom'
    }
    const workType = _.get(stateForm, 'workType.workType', 'Training')
    let isOnlineClass = false
    if (
      workType === 'Training' &&
      key === 'course' &&
      !_.isEmpty(onlineClass) &&
      isOnline
    ) {
      isOnlineClass = true
    } else {
      isOnlineClass = key === 'trainingPlatform' && isOnline
    }

    const onlineClassObj = isOnlineClass
      ? {
          id: onlineClass.id,
          uuid: onlineClass.value,
        }
      : {}
    if (key === 'startDate' || key === 'finishDate') {
      const newDate = dayjs(value).format(window.__env__.REACT_APP_DATE_DB)
      setStateForm((prevState) => ({
        ...prevState,
        training: { ...prevState.training, [key]: newDate },
      }))
    } else {
      let newValue = {
        [key]: value,
      }
      if (key === 'break') {
        newValue = {
          ...newValue,
          remarkCost: '',
          cost: 0,
        }
      }

      if (key === 'course' || key === 'trainingPlatform') {
        newValue = {
          ...newValue,
          cost: _.get(value, 'breakMealBudget', 0),
          remarkCost: '',
        }

        setStateForm((prevState) => ({
          ...prevState,
          training: {
            ...prevState.training,
            ...newValue,
            classroom: isOnlineClass ? onlineClassObj : {},
          },
        }))
      } else {
        setStateForm((prevState) => ({
          ...prevState,
          training: {
            ...prevState.training,
            ...newValue,
          },
        }))
      }
    }
  }

  let objValidate = { [key]: '' }
  if (key === 'startDate' || key === 'finishDate')
    objValidate = {
      startDate: '',
      finishDate: '',
    }

  setValidateForm((prevState) => ({
    ...prevState,
    training: { ...prevState.training, ...objValidate },
  }))

  setLoading &&
    setTimeout(() => {
      setLoading(false)
    }, 500)
  return key
}
