import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import DeleteTwoTone from '@mui/icons-material/DeleteTwoTone'
import EditTwoTone from '@mui/icons-material/EditTwoTone'
import RemoveRedEyeTwoTone from '@mui/icons-material/RemoveRedEyeTwoTone'

import IconButton from '@mui/material/IconButton'

import { StyledBox } from './Styled'
import { setOnEdit, setOnView } from '../../../../redux/slices/table'
import _ from 'lodash'

const Action = ({ row, rowIndex, minWidth, condition }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { onDelete } = useSelector((state) => state.table)
  const viewFullPath = _.get(row, 'viewPath', undefined)
  const viewId = _.get(condition, 'viewId', undefined)
  const viewAction = _.get(condition, 'viewAction', undefined)
  let viewUrl = !_.isUndefined(viewId)
    ? `${condition.viewPath}/${row[viewId]}`
    : _.isUndefined(viewFullPath)
    ? `${condition.viewPath}/${row.uuid}`
    : viewFullPath
  const editUrl = `${condition.editPath}/${row.uuid}`
  if (condition?.isRowViewUrl) {
    viewUrl = `${row?.viewUrl}`
  }

  return (
    <StyledBox minWidth={minWidth}>
      {!condition.hideView && (
        <IconButton
          data-testid={`btn-view-${rowIndex}`}
          color="primary"
          component="span"
          onClick={() => {
            if (viewAction) {
              dispatch(viewAction)
            } else dispatch(setOnView({ history, viewUrl }))
          }}
        >
          <RemoveRedEyeTwoTone color="action" />
        </IconButton>
      )}
      {!condition.hideEdit && row.status !== 'DELETED' && (
        <IconButton
          data-testid={`btn-edit-${rowIndex}`}
          color="primary"
          component="span"
          onClick={() => dispatch(setOnEdit({ history, editUrl }))}
        >
          <EditTwoTone color="action" />
        </IconButton>
      )}
      {!condition.hideDelete && row.status !== 'DELETED' && (
        <IconButton
          data-testid={`btn-delete-${rowIndex}`}
          color="primary"
          component="span"
          onClick={() => onDelete(row, rowIndex)}
        >
          <DeleteTwoTone color="action" />
        </IconButton>
      )}
    </StyledBox>
  )
}

export default Action
