export const arrayStatus = [
  {
    label: 'Active',
    name: 'activeChecked',
  },
  {
    label: 'Terminate',
    name: 'inActiveChecked',
  },
]

export const arrayCategory = [
  {
    label: 'A: Agent',
    name: 'aChecked',
  },
  {
    label: 'L: LB',
    name: 'lChecked',
  },
]

export const arrayCheckboxAgent = [
  {
    label: 'รหัส Agent',
    nameCheckBox: 'agentCodeChecked',
    nameInput: 'agentCode',
    placeholder: 'ค้นหา',
    type: 'text',
  },
  {
    label: 'ชื่อ',
    nameCheckBox: 'firstNameTHChecked',
    nameInput: 'firstNameTH',
    placeholder: 'ค้นหา',
    type: 'text',
  },
  {
    label: 'นามสกุล',
    nameCheckBox: 'lastNameTHChecked',
    nameInput: 'lastNameTH',
    placeholder: 'ค้นหา',
    type: 'text',
  },
  {
    label: 'ประเภท Agent',
    nameCheckBox: 'agentCategoryChecked',
    nameInput: 'agentCategory',
    type: 'agentCategory',
  },
  {
    label: 'Unit Number',
    nameCheckBox: 'unitNumberChecked',
    nameInput: 'unitNumber',
    placeholder: 'ค้นหา',
    type: 'text',
  },
  {
    label: 'คะแนนสะสม',
    nameCheckBox: 'pointChecked',
    nameInput: 'point',
    placeholder: 'ค้นหา',
    type: 'text',
  },
  {
    label: 'สถานะการทำงาน',
    nameCheckBox: 'statusChecked',
    nameInput: 'status',
    type: 'checkbox',
  },
]

export const defaultFilter = {
  agentCodeChecked: false,
  firstNameChecked: false,
  lastNameChecked: false,
  statusChecked: false,
  firstNameTHChecked: false,
  lastNameTHChecked: false,
  agentCategoryChecked: false,
  unitNumberChecked: false,
  pointChecked: false,
  status: {
    activeChecked: false,
    inActiveChecked: false,
  },
  agentCategory: {
    aChecked: false,
    lChecked: false,
  },
  createdByChecked: false,
  createdBy: '',
  updatedByChecked: false,
  updatedBy: '',
}

export const arrayCheckboxCs = [
  {
    label: 'รหัส Agent',
    nameCheckBox: 'agentCodeChecked',
    nameInput: 'agentCode',
    placeholder: 'ค้นหา',
    type: 'text',
  },
  {
    label: 'ชื่อ',
    nameCheckBox: 'firstNameTHChecked',
    nameInput: 'firstNameTH',
    placeholder: 'ค้นหา',
    type: 'text',
  },
  {
    label: 'นามสกุล',
    nameCheckBox: 'lastNameTHChecked',
    nameInput: 'lastNameTH',
    placeholder: 'ค้นหา',
    type: 'text',
  },
  {
    label: 'First Name',
    nameCheckBox: 'firstNameChecked',
    nameInput: 'firstName',
    placeholder: 'ค้นหา',
    type: 'text',
  },
  {
    label: 'Last Name',
    nameCheckBox: 'lastNameChecked',
    nameInput: 'lastName',
    placeholder: 'ค้นหา',
    type: 'text',
  },
  {
    label: 'Broker Number',
    nameCheckBox: 'unitNumberChecked',
    nameInput: 'unitNumber',
    placeholder: 'ค้นหา',
    type: 'text',
  },
  {
    label: 'คะแนนสะสม',
    nameCheckBox: 'pointChecked',
    nameInput: 'point',
    placeholder: 'ค้นหา',
    type: 'text',
  },
  {
    label: 'สถานะการทำงาน',
    nameCheckBox: 'statusChecked',
    nameInput: 'status',
    type: 'checkbox',
  },
  {
    label: 'แก้ไขล่าสุดโดย',
    nameCheckBox: 'updatedByChecked',
    nameInput: 'updatedBy',
    placeholder: 'ค้นหาแก้ไขล่าสุดโดย',
    type: 'text',
  },
]
