import { store } from '../../../../App'
import { setReduxDashboardByKey } from '../../../../redux/slices/expense'

export const updateDashboardByKey = ({ key, value }) => {
  store.dispatch(
    setReduxDashboardByKey({
      key: key,
      value: value,
    }),
  )
}
