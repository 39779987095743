import { createSlice } from '@reduxjs/toolkit'
import { initialState } from './model'

const expense = createSlice({
  name: 'expense',
  initialState,
  reducers: {
    startLoading: (state) => {
      state.isLoading = true
    },
    stopLoading: (state) => {
      state.isLoading = false
    },
    setTabActive: (state, { payload }) => {
      state.dashboard.tabActive = payload
    },
    setReduxDashboard: (state, { payload }) => {
      state.dashboard = { ...state.dashboard, ...payload }
    },
    setReduxDashboardByKey: (state, { payload }) => {
      const { key, value } = payload
      state.dashboard[key] = value
    },
    setIsOpenFilterDrawer: (state, { payload }) => {
      state.dashboard.isOpenFilterDrawer = payload
    },
    setReduxReport: (state, { payload }) => {
      const { key, value } = payload
      state.report[key] = value
    },
    //dashboard
    startLoadingDashboard: (state) => {
      state.dashboard.isLoading = true
    },
    stopLoadingDashboard: (state) => {
      state.dashboard.isLoading = false
    },
    setFiltersDashboard: (state, { payload }) => {
      state.dashboard.filters = payload
    },
    setFiltersListDisplay: (state, { payload }) => {
      state.dashboard.filtersList = payload
    },
    setDisplayFilter: (state, { payload }) => {
      state.dashboard.displayFilters = payload
    },
  },
})

export const {
  startLoading,
  stopLoading,
  setTabActive,
  setReduxReport,
  startLoadingDashboard,
  stopLoadingDashboard,
  setReduxDashboard,
  setFiltersDashboard,
  setReduxDashboardByKey,
  setFiltersListDisplay,
  setIsOpenFilterDrawer,
  setDisplayFilter,
} = expense.actions

export default expense.reducer
