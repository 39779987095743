import { createListenerMiddleware, isAnyOf } from '@reduxjs/toolkit'
import { setChange, setReduxValue } from '.'
import { handleDisabledTab } from '../../../modules/Permission/Form/handler/handleDisabledTab'
import { setSelected } from '../table'

export const listenerMiddlewarePermission = createListenerMiddleware()

listenerMiddlewarePermission.startListening({
  matcher: isAnyOf(setChange, setReduxValue),
  effect: async (action) => {
    const {
      payload: { key },
      type,
    } = action

    if (
      !(type === 'permission/setReduxValue' && key === 'tabActive') &&
      key !== 'permission'
    )
      return

    handleDisabledTab()
  },
})

listenerMiddlewarePermission.startListening({
  actionCreator: setSelected,
  effect: async (action, listenerApi) => {
    const { payload } = action
    listenerApi.dispatch(setChange({ key: 'costCenter', value: payload }))
  },
})
