export const formatData = (field, isColor) => {
  let color = ''
  let text = ''
  switch (field) {
    case 'PASS_CHECKED':
      text = 'ผ่านการตรวจสอบ'
      color = 'success.main'
      break
    case 'WAITING_DOCUMENT':
      text = 'รอเอกสาร'
      color = 'warning.dark'
      break
    case 'WAITING_CHECK':
      text = 'รอตรวจสอบ'
      color = 'warning.main'
      break
    case 'INELIGIBLE':
      text = 'ไม่มีสิทธิ์เข้าเรียน'
      color = 'error.main'
      break
    case 'REJECTED':
      text = 'เอกสารไม่ผ่าน'
      color = 'error.main'
      break
    case 'WAITING_CONFIRM':
      text = 'รอคอนเฟิร์มตัวแทน'
      color = 'info.main'
      break
    case 'CANCELED_DOCUMENT':
      text = 'ถูกยกเลิกโดยแอดมิน'
      color = 'text.gray'
      break
    case 'CANCELED_DOCUMENT_BY_USER':
      text = 'ยกเลิกการสมัครด้วยตนเอง'
      color = 'text.gray'
      break
    case 'CANCELED_DOCUMENT_BY_ADMIN':
      text = 'ถูกยกเลิกโดยแอดมิน'
      color = 'text.gray'
      break
    case 'CANCELED_DOCUMENT_BY_MA':
      text = 'ถูกยกเลิกโดยผู้จัดการ'
      color = 'text.gray'
      break
  }
  return isColor ? color : text
}
