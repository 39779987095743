import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import {
  eLearningCourse,
  eLearningCourseHistory,
  eLearningCourseBatchHistory,
  eLearningCourseBatchLearnerHistory,
  eLearningQuestionHistory,
  eLearningCourseBatchCompleted,
  eLearningCourseBatchTemplate,
  eLearningCourseBatchUpload,
} from '../../../utils/apiPath'

export const getDetail = createAsyncThunk(
  'eLearningCourseDetail/getDetail',
  async (uuid, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${eLearningCourse}/${uuid}`)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const getCourseHistory = createAsyncThunk(
  'eLearningCourseDetail/getCourseHistory',
  async (url, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${eLearningCourseHistory}${url}`)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const getCourseBatchHistory = createAsyncThunk(
  'eLearningCourseDetail/getCourseBatchHistory',
  async (url, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${eLearningCourseBatchHistory}${url}`)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const getCourseBatchLearnerHistory = createAsyncThunk(
  'eLearningCourseDetail/getCourseBatchLearnerHistory',
  async (url, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${eLearningCourseBatchLearnerHistory}${url}`,
      )
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const getCourseBatchQuestionHistory = createAsyncThunk(
  'eLearningCourseDetail/getCourseBatchHistory',
  async (url, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${eLearningQuestionHistory}${url}`)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const putCompleteCourseBatch = createAsyncThunk(
  'eLearningCourseDetail/putCompleteCourseBatch',
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.put(eLearningCourseBatchCompleted, body)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const downloadBatchTemplate = createAsyncThunk(
  'eLearningCourseDetail/downloadBatchTemplate',
  async (_, { rejectWithValue }) => {
    try {
      return await axios
        .get(eLearningCourseBatchTemplate, { responseType: 'blob' })
        .then((data) => {
          const url = window.URL.createObjectURL(new Blob([data.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `Template_E_Learning_Batch.xlsx`)
          document.body.appendChild(link)
          link.click()
        })
        .catch((err) => {
          rejectWithValue([], err)
        })
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)

export const postUploadCourseBatch = createAsyncThunk(
  'eLearningCourseDetail/postUploadCourseBatch',
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.post(eLearningCourseBatchUpload, body)
      return response
    } catch (err) {
      return rejectWithValue([], err)
    }
  },
)