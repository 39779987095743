import reducers from '../slices/reducer'

import { configureStore } from '@reduxjs/toolkit'
import { persistReducer, persistStore } from 'reduxjs-toolkit-persist'
import localforage from 'localforage'
import autoMergeLevel1 from 'reduxjs-toolkit-persist/lib/stateReconciler/autoMergeLevel1'
import { listenerMiddleware } from '../slices/manageClassDetail/listener'
import { listenerMiddleware1 } from '../slices/roomManagement/Drawer/listener'
import { listenerMiddleware as listenerMiddlewareListener } from '../slices/roomManagement/Dashboard/listener'
import { listenerMiddlewareELearning } from '../slices/eLearning/preview/listener'
import { listenerMiddlewareExamination } from '../slices/eExamination/list/listener'
import { listenerMiddlewareReportCourseSettingDashboard } from '../slices/reportCourseSetting/Dashboard/listener'
import { listenerMiddlewareMasterDataRoom } from '../slices/masterData/meetingRoomForm/listener'
import { listenerMiddlewareInventProductStock } from '../slices/inventory/product/stock/listener'
import { listenerMiddlewareBudgetManagement } from '../slices/budgetManagement/listener'
import { listenerMiddlewareInventRequisition } from '../slices/inventory/requisition/listener'
import { listenerMiddlewareReportComp } from '../slices/reportComp/listener'
import { listenerMiddlewareELearningDashboardByCourse } from '../slices/eLearning/dashboardByCourse/listener'
import { listenerMiddlewareMonthlyPlan } from '../slices/monthlyPlan/listener'
import { listenerMiddlewareExpense } from '../slices/expense/listener'
import { listenerMiddlewarePermission } from '../slices/permission/listener'

const persistConfig = {
  key: 'root',
  storage: localforage,
  stateReconciler: autoMergeLevel1,
  whitelist: [
    'eEvaluationForm',
    'eTestingForm',
    'manageContentHome',
    'manageContentStation',
    'manageContentLogin',
    'manageContentELearningHome',
    'permission',
    // 'eLearningModuleForm',
    // 'eLearningCourseForm',
    // 'eExamList',
  ],
}

const _persistedReducer = persistReducer(persistConfig, reducers)

export const setupStore = (preloadedState) => {
  return configureStore({
    reducer: _persistedReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        immutableCheck: false,
        serializableCheck: false,
      }).concat([
        listenerMiddlewareListener.middleware,
        listenerMiddleware.middleware,
        listenerMiddleware1.middleware,
        listenerMiddlewareELearning.middleware,
        listenerMiddlewareExamination.middleware,
        listenerMiddlewareReportCourseSettingDashboard.middleware,
        listenerMiddlewareMasterDataRoom.middleware,
        listenerMiddlewareInventProductStock.middleware,
        listenerMiddlewareBudgetManagement.middleware,
        listenerMiddlewareInventRequisition.middleware,
        listenerMiddlewareReportComp.middleware,
        listenerMiddlewareELearningDashboardByCourse.middleware,
        listenerMiddlewareMonthlyPlan.middleware,
        listenerMiddlewareExpense.middleware,
        listenerMiddlewarePermission.middleware,
      ]),
    preloadedState,
  })
}

export const setupPersistor = (store) => {
  return persistStore(store)
}
