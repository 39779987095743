import callAxios from '../../../../utils/baseService'
import { store } from '../../../../App'
import { setFieldValue } from '../../../../redux/slices/inventory/dashboard'
import {
  CRUDListFilterPath,
  getInventoryDashboard,
} from '../../../../utils/apiPath'
import { mutateFilterPropToBody } from '../components/FilterDrawer/event'
import { fetchCourse } from '../components/WaitingTable/events'
import { inventoryRequisitionBody } from '../components/TableDrawer/model'

export const fetchOverview = async (station) => {
  const { filter } = store.getState().inventoryDashboard
  const body = {
    ...mutateFilterPropToBody(filter, station),
  }
  store.dispatch(
    setFieldValue({
      key: 'isLoading',
      value: true,
    }),
  )

  await callAxios
    .post(getInventoryDashboard, body)
    .then(({ data }) => {
      store.dispatch(
        setFieldValue({
          key: 'overview',
          value: data,
        }),
      )
    })
    .catch((err) => {
      console.log(err)
    })

  store.dispatch(
    setFieldValue({
      key: 'isLoading',
      value: false,
    }),
  )

  const { waitingTable, dueTable } = store.getState().inventoryDashboard
  let bodyPropsWaiting = {
    searchText: waitingTable?.searchText ?? '',
    sort: waitingTable?.sort ?? 'updatedAt',
    order: waitingTable?.order ?? 'DESC',
    page: 1,
  }
  store.dispatch(
    fetchCourse(
      CRUDListFilterPath,
      inventoryRequisitionBody(bodyPropsWaiting),
      {
        'x-type': 'INVENTORY_REQUISITION_MP_ALL',
      },
      'waitingTable.data',
    ),
  )

  let bodyPropsDue = {
    searchText: dueTable?.searchText ?? '',
    sort: dueTable?.sort ?? 'updatedAt',
    order: dueTable?.order ?? 'DESC',
    page: 1,
  }
  store.dispatch(
    fetchCourse(
      CRUDListFilterPath,
      inventoryRequisitionBody(bodyPropsDue),
      {
        'x-type': 'INVENTORY_REQUISITION_MP_ALL',
      },
      'dueTable.data',
    ),
  )
}
