import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import Typography from '@mui/material/Typography'
import ArrowBack from '@mui/icons-material/ArrowBack'
import { useTheme } from '@mui/material'
import { formatIdCard } from '../../../../../../../../utils/lib'
import { Row } from '../../../../../../../../components/Layout/Styled'
import { Column } from '../../../../../../../../components/Preview/Layout/Styled'
import Dot from '../../../../../../../../components/redux/Dot'
import { learnerStatus } from '../../../../../../../../constants/manageClass'
import { ImageDisplay } from '../../../../../../../../modules/ELearning/Course/Learner/AttendanceTab/FaceHistory'
import { checkColorStatus } from '../../event'
import { FailTooltip } from '..'
import { StyledImgDashedBox } from './Styled'
import { handleApproveLearner, handleNotApprove } from './events'

const RekognitionDialog = ({ isOpen, handleClose, data }) => {
  const [selected, setSelect] = useState(null)
  const isNotSelected = _.isEmpty(selected)
  const theme = useTheme()
  const learnerRekognition = _.get(data, 'learnerRekognition', null)
  const status = _.get(data, 'status', false)
  const isDisable = status === 'CANCELED' || status === 'CANCEL'
  const idCardImageKey = _.get(
    learnerRekognition,
    'eLearningIdCardEnroll.idCardImageKey',
    '',
  )
  const faceImageKey = _.get(learnerRekognition, 'faceImageKey', '')
  const isFaceNotMatch = _.get(learnerRekognition, 'isFaceNotMatch', false)
  const isChecked = _.get(data, 'isChecked', false)
  const color = isFaceNotMatch ? 'error.main' : 'success.main'
  let prefixTH = _.get(data, 'prefixTH', '-')
  prefixTH = prefixTH === '-' ? '' : `${prefixTH} `

  useEffect(() => {
    if (isOpen) setSelect(null)
  }, [isOpen])

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      sx={{ '& .MuiPaper-root': { maxWidth: '100%' } }}
    >
      <Column sx={{ p: 2, gap: 2 }}>
        <Row pb={2}>
          <Typography variant="h6">รูปยืนยันตัวตน</Typography>
        </Row>

        <Row sx={{ gap: 4 }}>
          <RowContent
            label="ชื่อ-นามสกุล"
            data={`${prefixTH}${_.get(data, 'firstNameTH', '')} ${_.get(
              data,
              'lastNameTH',
              '',
            )}`}
          />
          <RowContent
            label="เลขบัตรประชาชน"
            data={formatIdCard(_.get(data, 'userProfile.idCardNo', '-'))}
          />
        </Row>
        <Row sx={{ gap: 4 }}>
          <RowContent label="รหัสตัวแทน" data={_.get(data, 'codeId', '-')} />
          <RowContent label="สถานะ">
            <Row sx={{ gap: 1, width: 'auto' }}>
              <Dot status={status} color={checkColorStatus(status, theme)} />
              <Typography color={isDisable ? 'text.gray' : 'text.primary'}>
                {learnerStatus[status]}
              </Typography>
            </Row>
          </RowContent>
        </Row>

        {isNotSelected ? (
          <>
            <Row sx={{ gap: 4 }}>
              <ImagePreviewDisplay
                icon="ic_address_card"
                label="รูปบัตรประจำตัวประชาชน / ใบขับขี่"
                imageKey={idCardImageKey}
                setSelect={setSelect}
              />
              <ImagePreviewDisplay
                icon="ic_person"
                label="รูปหน้าตรงเพื่อยืนยันตัวตน"
                imageKey={faceImageKey}
                setSelect={setSelect}
              />
            </Row>
            <Row sx={{ justifyContent: 'center', gap: 1 }}>
              <Typography
                variant="h6"
                color={color}
                sx={{ textAlign: 'center' }}
              >
                {_.get(learnerRekognition, 'percent', '')}%
              </Typography>
              <FailTooltip data={data} />
            </Row>
          </>
        ) : (
          <Row sx={{ gap: 4 }}>
            <ImagePreviewDisplay {...selected} selected />
          </Row>
        )}

        <Row sx={{ justifyContent: 'space-between' }}>
          {isNotSelected ? (
            <div />
          ) : (
            <Button
              size="m"
              startIcon={<ArrowBack />}
              onClick={() => setSelect(null)}
            >
              ย้อนกลับ
            </Button>
          )}
          <Row sx={{ width: 'auto', gap: 1.5 }}>
            {status === 'WAITING_ANNOUNCE' && (
              <>
                <Button
                  variant="outlined"
                  color="error"
                  onClick={() => handleNotApprove(data)}
                >
                  ไม่อนุมัติ
                </Button>
                {!isChecked && (
                  <Button
                    variant="outlined"
                    onClick={() =>
                      handleApproveLearner(_.get(data, 'uuid', ''), handleClose)
                    }
                  >
                    อนุมัติ
                  </Button>
                )}
              </>
            )}
            <Button variant="contained" onClick={handleClose}>
              ปิด
            </Button>
          </Row>
        </Row>
      </Column>
    </Dialog>
  )
}

export default RekognitionDialog

export const RowContent = ({ label, data, children }) => {
  return (
    <Row gap={2}>
      <Typography width={90} variant="body2" color="text.secondary">
        {label}
      </Typography>
      {children ? children : <Typography variant="body2b">{data}</Typography>}
    </Row>
  )
}

export const ImagePreviewDisplay = (props) => {
  const { icon, label, imageKey, selected, setSelect } = props
  return (
    <Column gap={2}>
      <Row sx={{ gap: 1, height: 44 }}>
        <img src={`/icon/${icon}.svg`} />
        <Typography variant="h6">{label}</Typography>
      </Row>

      <StyledImgDashedBox selected={selected} onClick={() => setSelect(props)}>
        <ImageDisplay imageKey={imageKey} />
      </StyledImgDashedBox>
    </Column>
  )
}
