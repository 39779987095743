import React from 'react'
import {
  resetTablePage,
  setFilterProp,
  setInitialTable,
  setSearchText,
  setFilterTotal,
  startLoading,
  stopLoading,
} from '../../../redux/slices/table'
import _ from 'lodash'
import { headOicCells } from '../../../constants/manageClass/table'
import { downloadFile, downloadGetFile } from '../../../services/util'
import * as service from '../../../services/manageClass/index'
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined'

import {
  manageClassOicDownload,
  manageClassOicFilter,
  manageClassOicTemplateDownload,
} from '../../../utils/apiPath'
import CheckDialog from './CheckDialog'
import {
  closeDialog,
  openDialog,
  openErrorDialog,
} from '../../../redux/slices/dialog'
import { postDeletedOicExam } from '../../../services/manageClass/form'
import { initialState } from '../../../redux/slices/table/model'
import callAxios from '../../../../src/utils/baseService'

export const onDownload = (selected, sort, order) => async (dispatch) => {
  dispatch(startLoading())
  const body = {
    order: order.toUpperCase(),
    sort: sort,
    type: 'CUSTOM',
    list: selected,
  }
  await dispatch(
    downloadFile({
      url: manageClassOicDownload,
      body: body,
      fileName: `OicExamDownload.xlsx`,
    }),
  )
  dispatch(stopLoading())
}

export const downloadTemplate = () => async (dispatch) => {
  dispatch(startLoading())
  await dispatch(
    downloadGetFile({
      url: manageClassOicTemplateDownload,
      fileName: `OicExamTemplateDownload.xlsx`,
    }),
  )
  dispatch(stopLoading())
}

export const fetchDataList =
  (method, table, page, filterProp, keyword, setInitial) =>
  async (dispatch) => {
    dispatch(startLoading())
    const isInitial = method === 'initial'
    const tableProps = isInitial ? initialState.table : table
    const filter = isInitial ? null : filterProp
    const sortBy = tableProps.sort
    const realPage = page <= 0 ? 1 : +page
    const FNAME = isInitial ? '' : _.get(filter, 'FNAME', keyword)

    const body = {
      TITLE: _.get(filter, 'TITLE', ''),
      FNAME: method !== 'fetch' ? keyword : FNAME,
      LNAME: _.get(filter, 'LNAME', ''),
      startExamDate: _.get(filter, 'startExamDate', ''),
      finishExamDate: _.get(filter, 'finishExamDate', ''),
      CID: _.get(filter, 'CID', ''),
      TYPE: _.get(filter, 'TYPE', ''),
      createdBy: _.get(filter, 'createdBy', ''),
      updatedBy: _.get(filter, 'updatedBy', ''),
      limit: tableProps.limit,
      order: tableProps.order.toString().toUpperCase(),
      page: realPage,
      sort: sortBy,
    }

    const isCancel = await callAxios
      .post(manageClassOicFilter, body)
      .then(({ data }) => {
        data.result = _.map(data.result, (item) => {
          return {
            ...item,
            createdBy: item.createdBy.name,
          }
        })
        dispatch(
          setInitialTable({
            rows: data.result,
            allCount: data.totalCount,
            headCells: headOicCells,
            placeholder: 'ค้นหาชื่อผู้สอบ',
            searchKey: 'FNAME',
            handleSearch: (text) =>
              dispatch(handleQuickSearch(tableProps, text)),
            onDownload: (selected, sort, order) =>
              dispatch(onDownload(selected, sort, order)),
            onDeleted: (selected) =>
              dispatch(
                openDialog({
                  icon: <ErrorOutlineOutlinedIcon color="error" />,
                  title: 'ลบรายชื่อ',
                  message: 'คุณต้องการลบรายชื่อใช่หรือไม่',
                  colorDisagreeText: 'error',
                  colorAgreeText: 'error',
                  agreeText: 'ลบ',
                  handleConfirm: () =>
                    dispatch(onDeleted(selected, tableProps)),
                }),
              ),
            onDelete: (selected) =>
              dispatch(
                openDialog({
                  icon: <ErrorOutlineOutlinedIcon color="error" />,
                  title: 'ลบรายชื่อ',
                  message: 'คุณต้องการลบรายชื่อใช่หรือไม่',
                  colorDisagreeText: 'error',
                  colorAgreeText: 'error',
                  agreeText: 'ลบ',
                  handleConfirm: () =>
                    dispatch(onDeleted([selected.uuid], tableProps)),
                }),
              ),
          }),
        )
      })
      .catch((e) => {
        dispatch(
          setInitialTable({
            rows: [],
            allCount: 0,
            headCells: headOicCells,
          }),
        )
        return e.message?.includes('method')
      })

    setInitial && setInitial(false)
    if (!isCancel) dispatch(stopLoading())
  }

export const onDeleted = (selected, table) => async (dispatch) => {
  dispatch(startLoading())
  const body = {
    list: selected,
  }
  await dispatch(postDeletedOicExam(body))
  dispatch(fetchDataList('search', table, 1, null, ''))
  dispatch(stopLoading())
  dispatch(closeDialog())
}

export const onFilterClick = (table, filter) => (dispatch) => {
  dispatch(setSearchText(''))

  const filterText = {
    TITLE: filter.titleChecked ? filter.TITLE : '',
    FNAME: filter.fNameChecked ? filter.FNAME : '',
    LNAME: filter.lNameChecked ? filter.LNAME : '',
    startExamDate: filter.examDateChecked ? filter.startExamDate : '',
    finishExamDate: filter.examDateChecked ? filter.finishExamDate : '',
    startImportDate: filter.importDateChecked ? filter.startImportDate : '',
    finishImportDate: filter.importDateChecked ? filter.finishImportDate : '',
    CID: filter.cidChecked ? filter.CID : '',
    TYPE: filter.typeChecked ? filter.TYPE : '',
    createdBy: filter.createdByChecked ? filter.createdBy : '',
    updatedBy: filter.updatedByChecked ? filter.updatedBy : '',
  }
  dispatch(setFilterProp(filterText))
  dispatch(setFilterTotal(filter))
  dispatch(resetTablePage())
  dispatch(fetchDataList('filter', table, 1, filterText, filterText.FNAME))
}

export const handleQuickSearch = (table, text) => (dispatch) => {
  dispatch(setSearchText(text))
  dispatch(setFilterProp(null))
  dispatch(resetTablePage())
  dispatch(fetchDataList('search', table, 1, null, text))
}

export const handleInputFile = (e, fetchData) => async (dispatch) => {
  dispatch(startLoading())
  const body = new FormData()
  if (e.target.files.length === 0) return
  body.append('file', e.target.files[0])
  const getNameImage = _.get(e, 'target.files[0].name', '')

  if (getNameImage) {
    const mimetype = ['xlsx', 'xls', 'csv']
    const splitName = getNameImage.split('.')
    if (!mimetype.includes(splitName[splitName.length - 1].toLowerCase())) {
      dispatch(
        openErrorDialog({
          message:
            'ไม่สามารถ Import file ได้ เนื่องจากนามสกุลไฟล์ไม่ถูกต้อง กรุณาดาวน์โหลด Template ที่มีให้ และใช้งานตามที่กำหนดไว้',
          title: 'นามสกุลไฟล์ไม่ถูกต้อง',
        }),
      )
      document.getElementById('btn-add-file').value = null
      dispatch(stopLoading())
      return null
    }
    const sizeFile = _.get(e, 'target.files[0].size', 0)
    if (sizeFile > 2000000) {
      dispatch(
        openErrorDialog({
          message: 'ขนาดไฟล์มีขนาดใหญ่เกิน 2 MB กรุณาลองใหม่อีกครั้ง',
          title: 'ขนาดไฟล์ใหญ่เกินไป',
        }),
      )
      dispatch(stopLoading())
      return null
    }
  }

  const response = await dispatch(service.uploadFileOicExam(body))
  if (!response?.error) {
    const uploadResult = _.get(response, 'payload.data', {})
    dispatch(
      openDialog({
        type: 'mini',
        maxWidth: 'table',
        disableButton: true,
        content: (
          <CheckDialog uploadResult={uploadResult} fetchData={fetchData} />
        ),
      }),
    )
  }
  _.set(document.getElementById('btn-add-file'), 'value', null)
  dispatch(stopLoading())
}
