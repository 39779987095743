export const path = {
  levelOfLearner: '/master-data/level-of-learner',
  level: '/master-data/level',
  staffProfile: '/manage/staff-profile',
  nonAcademyProfile: '/manage/non-academy-profile',
  agentProfile: '/manage/agent-profile',
  csAgentProfile: '/manage/cs-agent-profile',
  prospectProfile: '/manage/prospect-profile',
  csUserProfile: '/manage/cs-user-profile',
  eTesting: '/e-testing',
  eEvaluation: '/e-evaluation',
  eCertification: '/e-certification',
  eLearning: '/e-learning',
  manageClass: '/manage-class',
  apiToRcms: '/api-to-rcms',
  apiToRcmsPreview: '/api-to-rcms/preview',
  eLearningModule: '/e-learning/module',
  eLearningCourse: '/e-learning/course',
  eLearningCourseLearner: '/e-learning/course/learner',
  eLearningCourseBatch: '/e-learning/course/batch',
  eLearningQuestion: '/e-learning/question',
  eLearningLearningPath: '/e-learning/learning-path',
  eExamination: '/e-examination',
  inventory: '/inventory',
  catalog: '/learning-point/catalog',
  settingPoint: '/learning-point/setting-point',
  eContent: '/e-content',
}

export const IgnorePath = [
  { url: '/e-certification/background', method: 'put' },
  { url: '/e-certification/filter', method: 'post' },
  { url: '/e-certification/background/filter', method: 'post' },
  { url: '/e-certification/version/filter', method: 'post' },
  { url: '/e-evaluation/list', method: 'get' },
  { url: '/e-testing/list', method: 'get' },
  { url: '/e-testing/typelist', method: 'get' },
  { url: '/e-certification/background', method: 'post' },
  { url: '/monthlyPlan/masterplan', method: 'post' },
  { url: '/monthlyPlan/masterplan', method: 'post' },
  { url: '/monthlyPlan/masterplan', method: 'post' },
  { url: '/flow/masterPlan/recall/', method: 'put' },
  { url: '/flow/masterPlan/reject/', method: 'put' },
  { url: '/flow/masterPlan/approve/', method: 'put' },
  { url: '/manage-content/info', method: 'put' },
  { url: '/crud', method: 'post' },
  { url: '/file/e-content', method: 'post' },
  { url: '/e-learning/module/uploadFile/check', method: 'get' },
  { url: '/e-learning/create-multipart', method: 'post' },
  { url: '/e-learning/complete-multipart', method: 'post' },
  { url: '/e-learning/upload-part', method: 'post' },
]
