import { Typography } from '@mui/material'
import { StyledCard } from '../../../Styled'
import { LabelWithValue } from './LabelWithValue'

export const Information = () => {
  return (
    <StyledCard sx={{ gap: 1.5 }}>
      <Typography variant="h6">ข้อมูลพื้นฐาน</Typography>
      <LabelWithValue label="ID" fieldName="id" />
      <LabelWithValue label="ปี Budget" fieldName="yearBudget" />
      <LabelWithValue label="Cost Center" fieldName="costCenterId" />
      <LabelWithValue label="Cost Center Name" fieldName="costCenterName" />
      <LabelWithValue
        label="Account Code (Old Code / New Code)"
        fieldName="accountCodeLabel"
      />
      <LabelWithValue label="Description" fieldName="accountCode.description" />
      <LabelWithValue
        label="Categories"
        fieldName="accountCode.category.name"
      />
      <LabelWithValue
        label="Definition TH"
        fieldName="accountCode.definitionTH"
        flexDirection="column"
      />
      <LabelWithValue
        label="Definition EN"
        fieldName="accountCode.definitionENG"
        flexDirection="column"
      />
      <LabelWithValue label="สถานะ" fieldName="status" />
      <LabelWithValue
        label="หมายเหตุ"
        fieldName="remark"
        flexDirection="column"
      />
    </StyledCard>
  )
}
