import React from 'react'
import Tab from '@mui/material/Tab'
import Typography from '@mui/material/Typography'
import { FORM_TAB, FORM_TAB_TH } from './model'
import { StyledTab } from './Styled'
import { shallowEqual, useDispatch } from 'react-redux'
import { handleChangeTab } from '../../handler/handleChangeTab'
import { useSelector } from 'react-redux'

const TabMenu = () => {
  const dispatch = useDispatch()
  const EtestDashboardTab = useSelector(
    (state) => state.crud.EtestDashboardTab,
    shallowEqual,
  )

  return (
    <StyledTab
      value={EtestDashboardTab || FORM_TAB.overview}
      textColor="primary"
      indicatorColor="primary"
      onChange={(_, value) => dispatch(handleChangeTab(value))}
    >
      <Tab
        value={FORM_TAB.overview}
        label={
          <Typography
            variant={
              EtestDashboardTab === FORM_TAB.overview ? 'body1b' : 'body1'
            }
            color="primary"
          >
            {FORM_TAB_TH.overview}
          </Typography>
        }
      />
      <Tab
        value={FORM_TAB.testerList}
        label={
          <Typography
            variant={
              EtestDashboardTab === FORM_TAB.testerList ? 'body1b' : 'body1'
            }
            color="primary"
          >
            {FORM_TAB_TH.testerList}
          </Typography>
        }
      />
      <Tab
        value={FORM_TAB.testList}
        label={
          <Typography
            variant={
              EtestDashboardTab === FORM_TAB.testList ? 'body1b' : 'body1'
            }
            color="primary"
          >
            {FORM_TAB_TH.testList}
          </Typography>
        }
      />
      <Tab
        value={FORM_TAB.qa}
        label={
          <Typography
            variant={EtestDashboardTab === FORM_TAB.qa ? 'body1b' : 'body1'}
            color="primary"
          >
            {FORM_TAB_TH.qa}
          </Typography>
        }
      />
      <Tab
        value={FORM_TAB.setting}
        label={
          <Typography
            variant={
              EtestDashboardTab === FORM_TAB.setting ? 'body1b' : 'body1'
            }
            color="primary"
          >
            {FORM_TAB_TH.setting}
          </Typography>
        }
      />
    </StyledTab>
  )
}
export default TabMenu
