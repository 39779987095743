import React, { useEffect } from 'react'
import { Box, Button, Typography } from '@mui/material'
import { FilterListOutlined } from '@mui/icons-material'
import LearnerStatusChart from './LearnerStatusChart'
import ModuleList from './ModuleList'
import LearnerStatusChartByType from './LearnerStatusChartByType'
import FilterDrawer from './FilterDrawer'
import LearnerDrawer from './LearnerDrawer'
import {
  setIsOpenFilterDrawer,
  setReduxValue,
} from '../../../../../../redux/slices/eLearning/dashboardByCourse'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { fetchDashboardByCourse, fetchCourseBatch } from './events'
import { useParams } from 'react-router-dom'
import _ from 'lodash'
import { COURSE_TYPE } from '../../../../../../constants/eLearning'
import {
  fetchLearnerList,
  summaryFilterTotal,
} from '../../../../../../redux/slices/eLearning/dashboardByCourse/events'
import { onClearClick } from './FilterDrawer/events'
import RemarkTooltip from '../../../../../../modules/EConfiguration/components/RemarkTooltip'

const Dashboard = () => {
  const dispatch = useDispatch()
  const { id: uuid } = useParams()
  const { courseUuid, courseType, filterTotal } = useSelector(
    (state) => ({
      courseUuid: state.eLearningDashboardByCourse.courseUuid,
      courseType: state.eLearningCourseDetail.result.courseType,
      filterTotal: state.eLearningDashboardByCourse.filterTotal,
    }),
    shallowEqual,
  )

  useEffect(() => {
    dispatch(setReduxValue({ key: 'courseUuid', value: uuid }))
    onClearClick()
    return () => dispatch(setReduxValue({ key: 'courseUuid', value: '' }))
  }, [])

  useEffect(() => {
    if (_.isUndefined(courseUuid)) return
    summaryFilterTotal()
    fetchDashboardByCourse()
    fetchLearnerList()
    if (courseType === COURSE_TYPE.OIC) fetchCourseBatch()
  }, [courseUuid, courseType])

  return (
    <>
      <Box display="flex" flexDirection="column" width="100%" gap={2}>
        <Box display="flex" ml="auto">
          <RemarkTooltip wrapperSx={{ mr: 2, mt: 1 }} />
          <Button
            data-testid={'btn-filter'}
            sx={{ height: 40, p: 1, ml: 'auto' }}
            variant="text"
            startIcon={<FilterListOutlined />}
            onClick={() => dispatch(setIsOpenFilterDrawer(true))}
          >
            <Typography variant="text">
              ตัวกรอง {filterTotal > 0 ? `(${filterTotal})` : ''}
            </Typography>
          </Button>
        </Box>

        <Box display="flex" width="100%" gap={2}>
          <LearnerStatusChart />
          <ModuleList />
        </Box>

        <LearnerStatusChartByType />
        <FilterDrawer />
        <LearnerDrawer />
      </Box>
    </>
  )
}

export default Dashboard
