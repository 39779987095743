export const TIME_PERIOD = {
  YEARLY: 'YEARLY',
  CUSTOM: 'CUSTOM',
}

export const VIEW_MODE = {
  BY_ACADEMY: 'BY_ACADEMY',
  BY_COST_CENTER: 'BY_COST_CENTER',
  BY_DEPARTMENT: 'BY_DEPARTMENT',
}
