import _ from 'lodash'
import {
  defaultColumns,
  filterColumnOptions,
  COLUMN_NAME,
  COLUMN_NAME_TH,
} from './courseSetting'
import { reportClassConst, reportClassDefaultColumn } from './ReportClass'
import {
  reportExamEnrollConst,
  reportExamEnrollDefaultColumn,
} from './ReportExamEnroll'
import {
  reportExportByLearnerConst,
  reportExportByLearnerDefaultColumn,
} from './ReportExportByLearner'
import { reportForHFConst, reportForHFDefaultColumn } from './ReportForHF'
import {
  reportEExamResultConst,
  reportEExamResultDefaultColumn,
} from './ReportEExamResult'
import {
  reportWorkloadTOConst,
  reportWorkloadTODefaultColumn,
} from './ReportWorkloadTO'
import {
  reportEExamScheduleConst,
  reportEExamScheduleDefaultColumn,
} from './ReportEExamSchedule'
import {
  defaultColumns as defaultColumns_MP,
  filterColumnOptions as filterColumnOptions_MP,
  COLUMN_NAME as COLUMN_NAME_MP,
  COLUMN_NAME_TH as COLUMN_NAME_TH_MP,
} from './monthlyPlan'
import {
  reportETestingRawDataByClassConst,
  reportETestingRawDataByClassDefaultColumn,
} from './ReportETestingRawDataByClass'
import {
  reportETestingRawDataByLearnerConst,
  reportETestingRawDataByLearnerDefaultColumn,
} from './ReportETestingRawDataByLearner'
import {
  reportAgentProfileConst,
  reportAgentProfileDefaultColumn,
} from './ReportManageAgentProfile'
import {
  reportProspectProfileConst,
  reportProspectProfileDefaultColumn,
} from './ReportManageProspectProfile'
import {
  reportStaffProfileConst,
  reportStaffProfileDefaultColumn,
} from './ReportManageStaffProfile'
import {
  reportEEvaRawDataByCourseConst,
  reportEEvaRawDataByCourseDefaultColumn,
} from './ReportEEvaRawDataByCourse'
import {
  reportEEvaRawDataByTrainerConst,
  reportEEvaRawDataByTrainerDefaultColumn,
} from './ReportEEvaRawDataByTrainer'
import { reportRcmsConst, reportRcmsDefaultColumn } from './ReportRcms'
import {
  reportMixModuleConst,
  reportMixModuleDefaultColumn,
} from './ReportMixModule'
import {
  reportAgentProfileForToConst,
  reportAgentProfileForToDefaultColumn,
} from './ReportManageAgentProfileForTo'
import {
  reportAgentProfileForTrainerConst,
  reportAgentProfileForTrainerDefaultColumn,
} from './ReportManageAgentProfileForTrainer'
import {
  reportEContentConst,
  reportEContentDefaultColumn,
} from './ReportEContent'
import {
  reportRoomManagementConst,
  reportRoomManagementDefaultColumn,
} from './ReportRoomManagement'
import {
  auditLogConst,
  auditLogBackendDefaultColumn,
  auditLogLearnerDefaultColumn,
} from './AuditLog'
import {
  defaultColumns as defaultColumns_EP,
  filterColumnOptions as filterColumnOptions_EP,
  COLUMN_NAME as COLUMN_NAME_EP,
  COLUMN_NAME_TH as COLUMN_NAME_TH_EP,
} from './expense'
import {
  reportMixModuleMlMcConst,
  reportMixModuleMlMcDefaultColumn,
} from './ReportMixModuleMLMC'
import {
  reportMixModuleMcExpenseConst,
  reportMixModuleMcExpenseDefaultColumn,
} from './ReportMixModuleMcExpense'
import {
  reportAssetLicenseConst,
  reportAssetLicenseDefaultColumn,
  reportRequisitionConst,
  reportRequisitionDefaultColumn,
  reportStockConst,
  reportStockDefaultColumn,
} from './ReportInventory'

export const REPORTS_CONST = {
  COURSE_SETTING: {
    defaultColumns,
    filterColumnOptions: filterColumnOptions,
    COLUMN_NAME,
    COLUMN_NAME_TH,
  },
  EXPORT_BY_CLASS: {
    defaultColumns: reportClassDefaultColumn.defaultColumns,
    filterColumnOptions: reportClassDefaultColumn.filterColumnOptions,
    COLUMN_NAME: reportClassConst.COLUMN_NAME,
    COLUMN_NAME_TH: reportClassConst.COLUMN_NAME_TH,
  },
  REPORT_INVENTORY_RAW_DATA_REQUISITION: {
    defaultColumns: reportRequisitionDefaultColumn.defaultColumns,
    filterColumnOptions: reportRequisitionDefaultColumn.filterColumnOptions,
    COLUMN_NAME: reportRequisitionConst.COLUMN_NAME,
    COLUMN_NAME_TH: reportRequisitionConst.COLUMN_NAME_TH,
  },
  REPORT_INVENTORY_RAW_DATA_REQUISITION_PIC: {
    defaultColumns: reportRequisitionDefaultColumn.defaultColumns,
    filterColumnOptions: _.filter(
      reportRequisitionDefaultColumn.filterColumnOptions,
      (e) => e.name !== 'stationAssetWarehouse',
    ),
    COLUMN_NAME: reportRequisitionConst.COLUMN_NAME,
    COLUMN_NAME_TH: reportRequisitionConst.COLUMN_NAME_TH,
  },
  REPORT_INVENTORY_RAW_DATA_ASSET_LICENSE: {
    defaultColumns: reportAssetLicenseDefaultColumn.defaultColumns,
    filterColumnOptions: reportAssetLicenseDefaultColumn.filterColumnOptions,
    COLUMN_NAME: reportAssetLicenseConst.COLUMN_NAME,
    COLUMN_NAME_TH: reportAssetLicenseConst.COLUMN_NAME_TH,
  },
  REPORT_INVENTORY_RAW_DATA_ASSET_LICENSE_PIC: {
    defaultColumns: reportAssetLicenseDefaultColumn.defaultColumns,
    filterColumnOptions: _.filter(
      reportAssetLicenseDefaultColumn.filterColumnOptions,
      (e) => e.name !== 'warehouseStation',
    ),
    COLUMN_NAME: reportAssetLicenseConst.COLUMN_NAME,
    COLUMN_NAME_TH: reportAssetLicenseConst.COLUMN_NAME_TH,
  },
  REPORT_INVENTORY_RAW_DATA_STOCK: {
    defaultColumns: reportStockDefaultColumn.defaultColumns,
    filterColumnOptions: reportStockDefaultColumn.filterColumnOptions,
    COLUMN_NAME: reportStockConst.COLUMN_NAME,
    COLUMN_NAME_TH: reportStockConst.COLUMN_NAME_TH,
  },
  REPORT_INVENTORY_RAW_DATA_STOCK_PIC: {
    defaultColumns: reportStockDefaultColumn.defaultColumns,
    filterColumnOptions: _.filter(
      reportStockDefaultColumn.filterColumnOptions,
      (e) => e.name !== 'warehouseStation',
    ),
    COLUMN_NAME: reportStockConst.COLUMN_NAME,
    COLUMN_NAME_TH: reportStockConst.COLUMN_NAME_TH,
  },
  EXPORT_BY_LEARNER: {
    defaultColumns: reportExportByLearnerDefaultColumn.defaultColumns,
    filterColumnOptions: reportExportByLearnerDefaultColumn.filterColumnOptions,
    COLUMN_NAME: reportExportByLearnerConst.COLUMN_NAME,
    COLUMN_NAME_TH: reportExportByLearnerConst.COLUMN_NAME_TH,
  },
  REPORT_FOR_HF: {
    defaultColumns: reportForHFDefaultColumn.defaultColumns,
    filterColumnOptions: reportForHFDefaultColumn.filterColumnOptions,
    COLUMN_NAME: reportForHFConst.COLUMN_NAME,
    COLUMN_NAME_TH: reportForHFConst.COLUMN_NAME_TH,
  },
  REPORT_WORKLOAD_TO: {
    defaultColumns: reportWorkloadTODefaultColumn.defaultColumns,
    filterColumnOptions: reportWorkloadTODefaultColumn.filterColumnOptions,
    COLUMN_NAME: reportWorkloadTOConst.COLUMN_NAME,
    COLUMN_NAME_TH: reportWorkloadTOConst.COLUMN_NAME_TH,
  },
  REPORT_E_EXAM_RESULT: {
    defaultColumns: reportEExamResultDefaultColumn.defaultColumns,
    filterColumnOptions: reportEExamResultDefaultColumn.filterColumnOptions,
    COLUMN_NAME: reportEExamResultConst.COLUMN_NAME,
    COLUMN_NAME_TH: reportEExamResultConst.COLUMN_NAME_TH,
  },
  REPORT_E_EXAM_ENROLL: {
    defaultColumns: reportExamEnrollDefaultColumn.defaultColumns,
    filterColumnOptions: reportExamEnrollDefaultColumn.filterColumnOptions,
    COLUMN_NAME: reportExamEnrollConst.COLUMN_NAME,
    COLUMN_NAME_TH: reportExamEnrollConst.COLUMN_NAME_TH,
  },
  REPORT_E_EXAM_SCHEDULE: {
    defaultColumns: reportEExamScheduleDefaultColumn.defaultColumns,
    filterColumnOptions: reportEExamScheduleDefaultColumn.filterColumnOptions,
    COLUMN_NAME: reportEExamScheduleConst.COLUMN_NAME,
    COLUMN_NAME_TH: reportEExamScheduleConst.COLUMN_NAME_TH,
  },
  MP_WORKLOAD: {
    defaultColumns: defaultColumns_MP,
    filterColumnOptions: filterColumnOptions_MP,
    COLUMN_NAME: COLUMN_NAME_MP,
    COLUMN_NAME_TH: COLUMN_NAME_TH_MP,
  },
  MP_YEARLY: {
    defaultColumns: defaultColumns_MP,
    filterColumnOptions: filterColumnOptions_MP,
    COLUMN_NAME: COLUMN_NAME_MP,
    COLUMN_NAME_TH: COLUMN_NAME_TH_MP,
  },
  REPORT_E_TESTING_RAW_DATA_BY_CLASS: {
    defaultColumns: reportETestingRawDataByClassDefaultColumn.defaultColumns,
    filterColumnOptions:
      reportETestingRawDataByClassDefaultColumn.filterColumnOptions,
    COLUMN_NAME: reportETestingRawDataByClassConst.COLUMN_NAME,
    COLUMN_NAME_TH: reportETestingRawDataByClassConst.COLUMN_NAME_TH,
  },
  REPORT_E_TESTING_RAW_DATA_BY_LEARNER: {
    defaultColumns: reportETestingRawDataByLearnerDefaultColumn.defaultColumns,
    filterColumnOptions:
      reportETestingRawDataByLearnerDefaultColumn.filterColumnOptions,
    COLUMN_NAME: reportETestingRawDataByLearnerConst.COLUMN_NAME,
    COLUMN_NAME_TH: reportETestingRawDataByLearnerConst.COLUMN_NAME_TH,
  },
  REPORT_AGENT_PROFILE: {
    defaultColumns: reportAgentProfileDefaultColumn.defaultColumns,
    filterColumnOptions: reportAgentProfileDefaultColumn.filterColumnOptions,
    COLUMN_NAME: reportAgentProfileConst.COLUMN_NAME,
    COLUMN_NAME_TH: reportAgentProfileConst.COLUMN_NAME_TH,
  },
  REPORT_AGENT_PROFILE_TO: {
    defaultColumns: reportAgentProfileForToDefaultColumn.defaultColumns,
    filterColumnOptions:
      reportAgentProfileForToDefaultColumn.filterColumnOptions,
    COLUMN_NAME: reportAgentProfileForToConst.COLUMN_NAME,
    COLUMN_NAME_TH: reportAgentProfileForToConst.COLUMN_NAME_TH,
  },
  REPORT_AGENT_PROFILE_TRAINER: {
    defaultColumns: reportAgentProfileForTrainerDefaultColumn.defaultColumns,
    filterColumnOptions:
      reportAgentProfileForTrainerDefaultColumn.filterColumnOptions,
    COLUMN_NAME: reportAgentProfileForTrainerConst.COLUMN_NAME,
    COLUMN_NAME_TH: reportAgentProfileForTrainerConst.COLUMN_NAME_TH,
  },
  REPORT_PROSPECT_PROFILE: {
    defaultColumns: reportProspectProfileDefaultColumn.defaultColumns,
    filterColumnOptions: reportProspectProfileDefaultColumn.filterColumnOptions,
    COLUMN_NAME: reportProspectProfileConst.COLUMN_NAME,
    COLUMN_NAME_TH: reportProspectProfileConst.COLUMN_NAME_TH,
  },
  REPORT_STAFF_PROFILE: {
    defaultColumns: reportStaffProfileDefaultColumn.defaultColumns,
    filterColumnOptions: reportStaffProfileDefaultColumn.filterColumnOptions,
    COLUMN_NAME: reportStaffProfileConst.COLUMN_NAME,
    COLUMN_NAME_TH: reportStaffProfileConst.COLUMN_NAME_TH,
  },
  REPORT_E_EVA_RAW_DATA_BY_COURSE: {
    defaultColumns: reportEEvaRawDataByCourseDefaultColumn.defaultColumns,
    filterColumnOptions:
      reportEEvaRawDataByCourseDefaultColumn.filterColumnOptions,
    COLUMN_NAME: reportEEvaRawDataByCourseConst.COLUMN_NAME,
    COLUMN_NAME_TH: reportEEvaRawDataByCourseConst.COLUMN_NAME_TH,
  },
  REPORT_E_EVA_RAW_DATA_BY_TRAINER: {
    defaultColumns: reportEEvaRawDataByTrainerDefaultColumn.defaultColumns,
    filterColumnOptions:
      reportEEvaRawDataByTrainerDefaultColumn.filterColumnOptions,
    COLUMN_NAME: reportEEvaRawDataByTrainerConst.COLUMN_NAME,
    COLUMN_NAME_TH: reportEEvaRawDataByTrainerConst.COLUMN_NAME_TH,
  },
  REPORT_RCMS: {
    defaultColumns: reportRcmsDefaultColumn.defaultColumns,
    filterColumnOptions: reportRcmsDefaultColumn.filterColumnOptions,
    COLUMN_NAME: reportRcmsConst.COLUMN_NAME,
    COLUMN_NAME_TH: reportRcmsConst.COLUMN_NAME_TH,
  },
  REPORT_MIX_MODULE: {
    defaultColumns: reportMixModuleDefaultColumn.defaultColumns,
    filterColumnOptions: reportMixModuleDefaultColumn.filterColumnOptions,
    COLUMN_NAME: reportMixModuleConst.COLUMN_NAME,
    COLUMN_NAME_TH: reportMixModuleConst.COLUMN_NAME_TH,
  },
  REPORT_E_CONTENT: {
    defaultColumns: reportEContentDefaultColumn.defaultColumns,
    filterColumnOptions: reportEContentDefaultColumn.filterColumnOptions,
    COLUMN_NAME: reportEContentConst.COLUMN_NAME,
    COLUMN_NAME_TH: reportEContentConst.COLUMN_NAME_TH,
  },
  REPORT_ROOM_MANAGEMENT_ALL: {
    defaultColumns: reportRoomManagementDefaultColumn.defaultColumns,
    filterColumnOptions: reportRoomManagementDefaultColumn.filterColumnOptions,
    COLUMN_NAME: reportRoomManagementConst.COLUMN_NAME,
    COLUMN_NAME_TH: reportRoomManagementConst.COLUMN_NAME_TH,
  },
  REPORT_ROOM_MANAGEMENT_PIC: {
    defaultColumns: reportRoomManagementDefaultColumn.defaultColumns,
    filterColumnOptions: _.filter(
      reportRoomManagementDefaultColumn.filterColumnOptions,
      (e) => e.name !== 'stationName',
    ),
    COLUMN_NAME: reportRoomManagementConst.COLUMN_NAME,
    COLUMN_NAME_TH: reportRoomManagementConst.COLUMN_NAME_TH,
  },
  AUDIT_LOG_BACKEND: {
    defaultColumns: auditLogBackendDefaultColumn.defaultColumns,
    filterColumnOptions: auditLogBackendDefaultColumn.filterColumnOptions,
    COLUMN_NAME: auditLogConst.COLUMN_NAME,
    COLUMN_NAME_TH: auditLogConst.COLUMN_NAME_TH,
  },
  AUDIT_LOG_LEARNER: {
    defaultColumns: auditLogLearnerDefaultColumn.defaultColumns,
    filterColumnOptions: auditLogLearnerDefaultColumn.filterColumnOptions,
    COLUMN_NAME: auditLogConst.COLUMN_NAME,
    COLUMN_NAME_TH: auditLogConst.COLUMN_NAME_TH,
  },
  REPORT_EXPENSE: {
    defaultColumns: defaultColumns_EP,
    filterColumnOptions: filterColumnOptions_EP,
    COLUMN_NAME: COLUMN_NAME_EP,
    COLUMN_NAME_TH: COLUMN_NAME_TH_EP,
  },
  REPORT_MIX_MODULE_ML_MC: {
    defaultColumns: reportMixModuleMlMcDefaultColumn.defaultColumns,
    filterColumnOptions: reportMixModuleMlMcDefaultColumn.filterColumnOptions,
    COLUMN_NAME: reportMixModuleMlMcConst.COLUMN_NAME,
    COLUMN_NAME_TH: reportMixModuleMlMcConst.COLUMN_NAME_TH,
  },
  REPORT_MIX_MODULE_MC_EXPENSE: {
    defaultColumns: reportMixModuleMcExpenseDefaultColumn.defaultColumns,
    filterColumnOptions:
      reportMixModuleMcExpenseDefaultColumn.filterColumnOptions,
    COLUMN_NAME: reportMixModuleMcExpenseConst.COLUMN_NAME,
    COLUMN_NAME_TH: reportMixModuleMcExpenseConst.COLUMN_NAME_TH,
  },
}
