import { defaultStateForm } from '../Index'
import dayjs from 'dayjs'
import _ from 'lodash'
import {
  fetchStaff,
  fetchResponsibility,
  fetchClassroom,
  fetchDistributionChannel,
  fetchTrainingPlatform,
} from '../../api/Training'
import { WORK_WORK_LIST } from '../../Text'
import { checkChangeDate } from '../Handle'

export const handleApiTraining = async ({
  setOptions,
  area,
  setLoading,
  workType,
  date,
}) => {
  setLoading(true)
  if (workType === WORK_WORK_LIST[3]) {
    const dataApi = await Promise.all([
      fetchStaff({ area, date }),
      fetchResponsibility(),
      fetchClassroom(),
    ])
    setOptions((prevState) => ({
      ...prevState,
      staff: dataApi[0],
      responsibility: dataApi[1],
      classroom: dataApi[2],
    }))
  } else if (workType === WORK_WORK_LIST[4]) {
    const dataApi = await Promise.all([
      fetchStaff({ area, date }),
      fetchResponsibility(),
      fetchClassroom(),
      fetchDistributionChannel(),
      fetchTrainingPlatform(),
    ])

    setOptions((prevState) => ({
      ...prevState,
      staff: dataApi[0],
      responsibility: dataApi[1],
      classroom: dataApi[2],
      distributionChannel: dataApi[3],
      trainingPlatform: dataApi[4],
    }))
  } else {
    const dataApi = await Promise.all([fetchStaff({ area, date })])
    setOptions((prevState) => ({
      ...prevState,
      staff: dataApi[0],
    }))
  }
  setLoading(false)

  return area
}

export const handleDateBetween = ({
  stateForm,
  setStateForm,
  course,
  uuidStaff,
  productTypeStaff,
  type,
}) => {
  let dateArray = []
  let dateObject = []
  const sDate = stateForm.startDate
  const fDate = stateForm.finishDate
  const checkDate = checkChangeDate(sDate, fDate)
  if (!checkDate) {
    setStateForm((prevState) => ({
      ...prevState,
      training: {
        ...prevState.training,
        dateBetween: dateArray,
        dates: dateObject,
      },
    }))
    return false
  }

  let startDate = dayjs(stateForm.startDate)
  let stopDate = dayjs(stateForm.finishDate)

  let currentDate = new Date(startDate)
  while (currentDate < stopDate) {
    dateArray = [
      ...dateArray,
      dayjs(currentDate).format(window.__env__.REACT_APP_DATE_DB),
    ]
    currentDate = new Date(currentDate.getTime() + 86400000)
    stopDate = dayjs(stateForm.finishDate)
  }
  dateArray = [
    ...dateArray,
    dayjs(stopDate).format(window.__env__.REACT_APP_DATE_DB),
  ]
  dateArray = _.sortedUniq(dateArray)

  const typeDate = _.get(stateForm, 'typeDate', 'DUPLICATE')

  dateArray.forEach((d, i) => {
    const dateState = _.get(stateForm, `dates[${i}].date`, '')

    if (
      dayjs(dateState).format('YYYY/MM/DD') === dayjs(d).format('YYYY/MM/DD')
    ) {
      dateObject = [...dateObject, stateForm.dates[i]]
    } else {
      const defaultSpeakers =
        type === 'add' && uuidStaff != null
          ? [
              {
                staff: {
                  id: 1,
                  uuid: uuidStaff,
                  productType: productTypeStaff,
                },
                responsibility: {},
                startTime: '08:30',
                endTime: '17:00',
              },
            ]
          : []

      const defaultEmpty = {
        typeTime: _.get(stateForm, 'dates[0].typeTime', 'FULL'),
        startTime: _.get(stateForm, 'dates[0].startTime', '08:30'),
        endTime: _.get(stateForm, 'dates[0].endTime', '17:00'),
        location: _.get(stateForm, 'dates[0].location', {
          classRoomOther: '',
          online: false,
          onsite: false,
          other: false,
        }),
        speakerBorrow: [],
      }
      if (typeDate === 'DUPLICATE') {
        dateObject = [
          ...dateObject,
          {
            ...stateForm.dates[0],
            ...defaultEmpty,
            date: d,
            speakers: _.get(stateForm, 'dates[0].speakers', []).length
              ? _.get(stateForm, 'dates[0].speakers', [])
              : defaultSpeakers,
          },
        ]
      } else {
        dateObject = [
          ...dateObject,
          {
            ...defaultStateForm.training.dates[0],
            ...defaultEmpty,
            date: d,
            speakers: [],
            speakerBorrow: [],
            location: checkLocation({ stateForm, rowIndex: i, course }),
          },
        ]
      }
    }
  })

  if (dateArray.length === 1 && dateArray[0] === 'Invalid Date') dateArray = []

  setStateForm((prevState) => ({
    ...prevState,
    training: {
      ...prevState.training,
      dateBetween: dateArray,
      dates: _.uniqBy(dateObject, 'date'),
    },
  }))
  return true
}

export const handleChangeFormDates = ({
  key,
  index,
  value,
  stateForm,
  setStateForm,
}) => {
  const typeDate = stateForm.typeDate
  let newArray = stateForm.dates
  newArray[index] = { ...newArray[index], [key]: value }

  let newValue = {}

  if (key === 'typeTime' && value === 'MORNING') {
    newArray[index] = {
      ...newArray[index],
      startTime: '08:30',
      endTime: '12:00',
    }
    newValue = {
      [key]: value,
      startTime: '08:30',
      endTime: '12:00',
    }
  } else if (key === 'typeTime' && value === 'AFTERNOON') {
    newArray[index] = {
      ...newArray[index],
      startTime: '13:00',
      endTime: '17:00',
    }
    newValue = {
      [key]: value,
      startTime: '13:00',
      endTime: '17:00',
    }
  } else if (key === 'typeTime' && value === 'FULL') {
    newArray[index] = {
      ...newArray[index],
      startTime: '08:30',
      endTime: '17:00',
    }
    newValue = {
      [key]: value,
      startTime: '08:30',
      endTime: '17:00',
    }
  } else if (key === 'startTime' || key === 'endTime') {
    newArray[index] = {
      ...newArray[index],
      [key]: value,
    }
    newValue = {
      [key]: value,
    }
  }

  if (typeDate === 'DUPLICATE') {
    newArray = stateForm.dates.map((item) => {
      return {
        ...item,
        ...newValue,
      }
    })
  }

  setStateForm((prevState) => ({
    ...prevState,
    training: { ...prevState.training, dates: newArray },
  }))

  return value
}

export const handleChangeFormSpeakers = ({
  index,
  indexStaff,
  key,
  value,
  stateForm,
  setStateForm,
  validateForm,
  setValidateForm,
  options = [],
}) => {
  const typeDate = _.get(stateForm, 'typeDate', '')

  let dataObj = null
  if (key === 'staff') {
    const staffObj = options.staff.filter((a) => a.value === value)

    dataObj = {
      id: _.get(staffObj, '[0]id', ''),
      uuid: _.get(staffObj, '[0]value', ''),
      productType: _.get(staffObj, '[0]productType', ''),
    }
  }
  if (key === 'responsibility') {
    const responsibilityObj = options.responsibility.filter(
      (a) => a.value === value,
    )
    dataObj = {
      id: _.get(responsibilityObj, '[0]id', ''),
      uuid: _.get(responsibilityObj, '[0]value', ''),
      responsibility: _.get(responsibilityObj, '[0]label', ''),
    }
  }

  let listDates = _.get(stateForm, 'dates', [])
  let newValidateDate = _.get(validateForm, 'dates', [])
  let newDates = []

  if (typeDate === 'DUPLICATE') {
    newDates = listDates.map((item) => {
      return {
        ...item,
        speakers: item.speakers.map((itemS, indexS) => {
          if (indexStaff === indexS) {
            return {
              ...itemS,
              [key]: dataObj ? dataObj : value,
            }
          }
          return itemS
        }),
      }
    })
    newValidateDate = newValidateDate.map((item) => {
      return {
        ...item,
        speakers: item.speakers.filter((itemSK, indexSK) => {
          if (indexStaff === indexSK) {
            return {
              ...itemSK,
              [key]: '',
            }
          }
          return itemSK
        }),
      }
    })
  } else {
    let newArrayValidate = _.get(validateForm, `dates[${index}].speakers`, [])

    newArrayValidate[indexStaff] = {
      ...newArrayValidate[indexStaff],
      [key]: '',
    }

    newValidateDate[index] = {
      ...newValidateDate[index],
      speakers: newArrayValidate,
    }
    newDates = listDates.map((itemD, indexD) => {
      if (index === indexD)
        return {
          ...itemD,
          speakers: itemD.speakers.map((itemS, indexS) => {
            if (indexStaff === indexS) {
              return {
                ...itemS,
                [key]: dataObj ? dataObj : value,
              }
            }
            return itemS
          }),
        }
      return itemD
    })
  }

  setValidateForm((prevState) => ({
    ...prevState,
    training: { ...prevState.training, dates: newValidateDate },
  }))

  setStateForm((prevState) => ({
    ...prevState,
    training: { ...prevState.training, dates: newDates },
  }))

  return key
}

export const handleChangeStaffList = ({
  type,
  index = 0,
  indexStaff = 0,
  stateForm,
  setStateForm,
}) => {
  const typeTime = stateForm.typeDate || 'DUPLICATE'

  let newArray = [..._.get(stateForm, `dates[${index}].speakers`, [])]
  let newDates = {}
  newDates = stateForm.dates

  if (type === 'add') {
    if (typeTime !== 'DUPLICATE') {
      newDates[index] = {
        ...newDates[index],
        speakers: [
          ...newArray,
          {
            staff: {},
            responsibility: {},
            startTime: _.get(newDates, `${[index]}.startTime`, '')
              ? _.get(newDates, `${[index]}.startTime`, '')
              : '',
            endTime: _.get(newDates, `${[index]}.endTime`, '')
              ? _.get(newDates, `${[index]}.endTime`, '')
              : '',
          },
        ],
      }
    } else {
      newDates = stateForm.dates.map((item) => {
        return {
          ...item,
          speakers: [
            ...item.speakers,
            {
              staff: {},
              responsibility: {},
              startTime: _.get(item, 'startTime', '')
                ? _.get(item, 'startTime', '')
                : '',
              endTime: _.get(item, 'endTime', '')
                ? _.get(item, 'endTime', '')
                : '',
            },
          ],
        }
      })
    }
  } else if (type === 'delete') {
    if (typeTime !== 'DUPLICATE') {
      newArray.splice(indexStaff, 1)
      newDates[index] = {
        ...newDates[index],
        speakers: newArray,
      }
    } else {
      newDates = stateForm.dates.map((item) => {
        const speakers = [..._.get(item, 'speakers', [])]

        speakers.splice(indexStaff, 1)

        return { ...item, speakers }
      })
    }
  }

  setStateForm((prevState) => ({
    ...prevState,
    training: { ...prevState.training, dates: newDates },
  }))

  return type
}

export const handleSubmitDialog = (
  expandDate,
  value,
  type,
  rowIndex,
  typeDate,
  setStateForm,
) => {
  const dateKey = value?.startDate || 'DUPLICATE'
  let newExpandDates = []

  if (type === 'add') {
    newExpandDates = expandDate.filter((item) => {
      if (item.date === dateKey && typeDate === 'CUSTOM') {
        item.speakerBorrow = [...item.speakerBorrow, value]
      }

      if (typeDate !== 'CUSTOM') {
        item.speakerBorrow = [...item.speakerBorrow, value]
      }
      return { ...item }
    })
  }
  if (type === 'editForm') {
    newExpandDates = expandDate.filter((item) => {
      if (item.date === dateKey && typeDate === 'CUSTOM') {
        const mapData = item.speakerBorrow.map((itemSB, index) => {
          if (index === rowIndex) {
            itemSB = value
          }
          return itemSB
        })
        item.speakerBorrow = mapData
      }

      if (typeDate !== 'CUSTOM') {
        const mapData = item.speakerBorrow.map((itemSB, index) => {
          if (index === rowIndex) {
            itemSB = value
          }
          return itemSB
        })
        item.speakerBorrow = mapData
      }
      return { ...item }
    })
  }

  if (type === 'delete') {
    newExpandDates = expandDate.filter((item) => {
      const speakerBorrow = item?.speakerBorrow || []
      if (
        item.date === dateKey &&
        typeDate === 'CUSTOM' &&
        speakerBorrow.length
      ) {
        speakerBorrow.splice(rowIndex, 1)
      }
      if (typeDate !== 'CUSTOM' && speakerBorrow.length) {
        speakerBorrow.splice(rowIndex, 1)
      }
      return { ...item }
    })
  }

  if (type === 'CANCEL') {
    newExpandDates = expandDate.filter((item) => {
      const mapData = item.speakerBorrow.map((itemSB, index) => {
        if (index === rowIndex && typeDate === 'CUSTOM') {
          itemSB = { ...value, status: 'CANCEL' }
        }

        if (index === rowIndex && typeDate !== 'CUSTOM') {
          itemSB = { ...value, status: 'CANCEL' }
        }
        return itemSB
      })
      item.speakerBorrow = mapData
      return { ...item }
    })
  }

  setStateForm((prevState) => ({
    ...prevState,
    training: {
      ...prevState.training,
      dates: newExpandDates,
    },
  }))

  return type
}

export const checkLocation = ({ stateForm, rowIndex, course }) => {
  const type = _.get(stateForm, 'workType.workType', '')
  let trainingPlatform = []
  if (type === 'Training') {
    const uuidCourse = _.get(stateForm, 'course.uuid', '')
    const findCourse = course.find((item) => {
      return item.uuid === uuidCourse
    })

    trainingPlatform = !_.isEmpty(findCourse)
      ? _.get(findCourse, 'trainingPlatform', [])
      : []
  }
  if (type === 'Activity Support') {
    trainingPlatform = _.get(stateForm, 'trainingPlatform', [])
  }

  let isHybrid = false
  let result = {}
  let location = {
    online: false,
    onsite: false,
    other: false,
  }
  if (trainingPlatform.length) {
    const checkTrue = trainingPlatform.find((item) => {
      return item.isOnline
    })
    const checkFalse = trainingPlatform.find((item) => {
      return !item.isOnline
    })

    if (
      trainingPlatform.length > 1 &&
      !_.isEmpty(checkTrue) &&
      !_.isEmpty(checkFalse)
    )
      isHybrid = true

    location = {
      online: isHybrid ? true : _.isEmpty(checkTrue) ? false : true,
      onsite: isHybrid ? true : _.isEmpty(checkFalse) ? false : true,
      other: isHybrid ? true : _.isEmpty(checkFalse) ? false : true,
    }

    const typeDate = _.get(stateForm, 'typeDate', 'DUPLICATE')
    const listDate = _.get(stateForm, 'dates', [])
    if (typeDate === 'DUPLICATE') {
      listDate.map((item) => {
        result = {
          ...item.location,
          ...location,
        }
      })
    } else {
      listDate.filter((item, index) => {
        if (index === rowIndex) {
          result = { ...item.location, ...location }
        }
      })
    }

    return result
  }
}

export const LIST_CHECK_TP = {
  work: {
    activitySupport: 'Activity Support',
    training: 'Training',
  },
  training: {
    classroom: 'Classroom',
    online: 'Online',
    virtualClassroom: 'Virtual Classroom',
  },
}

export const checkTrainingPlatform = ({
  stateForm,
  value,
  course,
  options,
  trainingPlatform,
  setOptionClassroom,
  isFirst,
}) => {
  const workType = _.get(stateForm, 'workType.workType', '')
  let classroom = 0
  let vClassroom = 0
  let listTrainingPlatform = []
  if (workType === LIST_CHECK_TP.work.training) {
    const uuidCourse = !isFirst ? value : _.get(stateForm, 'course.uuid', '')
    const filterItem = course.find((a) => a.value === uuidCourse)
    listTrainingPlatform = _.isEmpty(filterItem)
      ? _.get(stateForm, 'course.trainingPlatform', [])
      : _.get(filterItem, 'trainingPlatform', [])
  } else if (workType === LIST_CHECK_TP.work.activitySupport) {
    listTrainingPlatform = !isFirst
      ? trainingPlatform
      : _.get(stateForm, 'trainingPlatform', [])
  }

  listTrainingPlatform.forEach((item) => {
    if (item.trainingPlatform === LIST_CHECK_TP.training.classroom)
      classroom = classroom + 1
    else if (item.trainingPlatform === LIST_CHECK_TP.training.virtualClassroom)
      vClassroom = vClassroom + 1
  })
  let result = options
  const totalCount = classroom + vClassroom
  if (totalCount && totalCount === listTrainingPlatform.length) {
    if (classroom && !vClassroom) {
      result = options.filter(
        (item) => item.label !== LIST_CHECK_TP.training.online,
      )
    } else if (!classroom && vClassroom) {
      result = [
        options.find((item) => item.label === LIST_CHECK_TP.training.online),
      ]
    }
  }
  setOptionClassroom(result)

  return result
}
