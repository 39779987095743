import React from 'react'
import _ from 'lodash'
import Typography from '@mui/material/Typography'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'

import SelectIcon from '../../../../../../../../components/redux/SelectIcon'
import TextInput from '../../../../../../../../components/Input/TextInput'
import * as Styled from '../../Styled'
import { BoxSelect, BoxInput } from './Styled'

import { handleChange, handleChangeNumber, handleBlurNumber } from '../../event'

const Index = () => {
  const dispatch = useDispatch()

  const { testRound, disableSetting } = useSelector(
    (state) => ({
      testRound: state.eTestingForm.setting.testRound,
      disableSetting:
        state.eTestingForm.disableSetting || state.eTestingForm.onViewMode,
    }),
    shallowEqual,
  )

  return (
    <Styled.BoxForm isGap>
      <Typography variant="h6">จำนวนครั้งในการสอบ</Typography>
      <Styled.BoxFlex isSwitch isSelect paddingLeft={3}>
        <Typography variant="body1">
          กรณีสอบไม่ผ่าน กำหนดให้ผู้เรียนสอบใหม่ได้
        </Typography>
        <BoxSelect roundUnlimit={_.get(testRound, 'roundUnlimit', false)}>
          <SelectIcon
            disabled={disableSetting}
            labelText=""
            value={_.get(testRound, 'roundUnlimit', false)}
            listOption={[
              {
                label: 'ไม่จำกัดครั้ง',
                value: true,
              },
              {
                label: 'ระบุจำนวน',
                value: false,
              },
            ]}
            handleChange={(e) =>
              dispatch(
                handleChange({
                  value: _.get(e, 'target.value', false),
                  key: 'testRound.roundUnlimit',
                }),
              )
            }
          />
          {!_.get(testRound, 'roundUnlimit', false) && (
            <BoxInput>
              <TextInput
                id="round"
                name="round"
                type="basic"
                disabled={disableSetting}
                endAdornmentText={'ครั้ง'}
                value={_.get(testRound, 'round', 1)}
                isShowTextError={false}
                onBlur={(e) =>
                  dispatch(
                    handleBlurNumber({
                      value: e.target.value,
                      key: 'testRound.round',
                    }),
                  )
                }
                onChange={(e) =>
                  dispatch(
                    handleChangeNumber({
                      max: 100,
                      value: e.target.value,
                      key: 'testRound.round',
                    }),
                  )
                }
              />
            </BoxInput>
          )}
        </BoxSelect>
      </Styled.BoxFlex>
    </Styled.BoxForm>
  )
}
export default Index
