import { Box, Typography } from '@mui/material'
import { StyledColumn, StyledRow } from '../../Styled'
import DateRangeInput from '../../../../components/DateRangeInput'
import CourseSelectionRadioGroup from './CourseSelectionRadioGroup'
import _ from 'lodash'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import dayjs from 'dayjs'
import { handleChangeDateRange } from './event'

const DashboardSection = () => {
  const dispatch = useDispatch()
  const { courseForList, countCourseAll, dateRange } = useSelector(
    (state) => ({
      courseForList: state.reportCourseSettingDashboard.courseForList,
      countCourseAll: state.reportCourseSettingDashboard.countCourseAll,
      dateRange: state.reportCourseSettingDashboard.dateRange,
    }),
    shallowEqual,
  )
  const today = new Date()
  const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1)

  return (
    <StyledColumn sx={{ gap: 1.5, mt: 2 }}>
      <StyledRow sx={{ justifyContent: 'space-between' }}>
        <Typography variant="h5">ภาพรวมหลักสูตร</Typography>
        <Box sx={{ width: 300 }}>
          <DateRangeInput
            dateState={{
              startDate: dateRange?.startDate
                ? dateRange?.startDate
                : firstDayOfMonth,
              endDate: dateRange?.endDate ? dateRange?.endDate : today,
              display:
                dateRange?.startDate != '' && dateRange?.endDate != ''
                  ? true
                  : false,
              key: 'selection',
            }}
            onChange={(selectedDates) => {
              const startDate = dayjs(
                _.get(selectedDates, 'startDate', ''),
              ).format('YYYY-MM-DD')
              const endDate = dayjs(_.get(selectedDates, 'endDate', '')).format(
                'YYYY-MM-DD',
              )
              dispatch(
                handleChangeDateRange({
                  startDate: startDate,
                  endDate: endDate,
                }),
              )
            }}
          />
        </Box>
      </StyledRow>

      <StyledRow
        sx={{
          justifyContent: 'space-between',
        }}
      >
        <CourseSelectionRadioGroup
          label="หลักสูตรทั้งหมด"
          count={countCourseAll}
        />
        <StyledRow sx={{ gap: 3 }}>
          {_.map(courseForList, (courseFor, idx) => (
            <CourseSelectionRadioGroup
              idx={idx}
              {...courseFor}
              label="หลักสูตร"
              isShowCourseFor={true}
            />
          ))}
        </StyledRow>
      </StyledRow>
    </StyledColumn>
  )
}
export default DashboardSection
