import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import _ from 'lodash'
import {
  BoxButton,
  BoxCard,
  CardGroup,
} from '../../../../../CourseSetting/Prerequisite/Styled'
import SelectGroupChip from '../../../../../../components/SelectGroupChip'
import { Button, IconButton, Typography } from '@mui/material'
import { DeleteTwoTone } from '@mui/icons-material'
import { handleChange } from '../../events'
import { handleDefaultList } from '../Course/events'

export const onSelectGroupChipChange =
  (list, inputKey, ind, speakerProductType) => (dispatch) => {
    let data = [...speakerProductType]
    data[ind] = list
    dispatch(handleChange(inputKey, data))
  }

export const handleAddSubGroup =
  (inputKey, ind, speakerProductType) => (dispatch) => {
    let data = [...speakerProductType]
    data.splice(ind + 1, 0, [])
    dispatch(handleChange(inputKey, data))
  }

export const handleDeleteSubGroup =
  (inputKey, ind, speakerProductType) => (dispatch) => {
    let data = [...speakerProductType]
    data.splice(ind, 1)
    dispatch(handleChange(inputKey, data))
  }

const Index = ({ value, error, options, inputKey }) => {
  const dispatch = useDispatch()
  useEffect(() => {
    if (value.length < 1) {
      dispatch(handleChange(inputKey, [[]]))
    }
  }, [])
  return (
    <CardGroup sx={{ overflow: 'visible', mb: 3, mt: 0 }}>
      {value.map((group, ind) => {
        return (
          <BoxCard key={ind}>
            <SelectGroupChip
              id="speakerProductType"
              name="speakerProductType"
              textError={error?.[ind]}
              placeholder={'กรุณาเลือก'}
              options={options}
              onChange={(list) =>
                dispatch(onSelectGroupChipChange(list, inputKey, ind, value))
              }
              value={handleDefaultList(options, group)}
            />

            <BoxButton sx={{ mt: 0 }}>
              <Button
                disabled={_.some(value, (e) => e.length < 1)}
                id={`btn-or-${ind}`}
                name={`btn-or-${ind}`}
                variant="outlined"
                size="m"
                onClick={() =>
                  dispatch(handleAddSubGroup(inputKey, ind, value))
                }
              >
                <Typography variant="buttonl">หรือ</Typography>
              </Button>
              <IconButton
                disabled={value.length <= 1}
                onClick={() =>
                  dispatch(handleDeleteSubGroup(inputKey, ind, value))
                }
              >
                <DeleteTwoTone
                  color={
                    value.length <= 1 ? 'action.disabled' : 'action.active'
                  }
                />
              </IconButton>
            </BoxButton>
          </BoxCard>
        )
      })}
    </CardGroup>
  )
}
export default Index
