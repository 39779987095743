import _ from 'lodash'
import {
  loadingDialog,
  openDialog,
  setEnableButton,
  stopLoadingDialog,
} from '../../../../../../redux/slices/dialog'
import {
  setImportFile,
  startLoading,
  stopLoading,
} from '../../../../../../redux/slices/eLearning/courseBatchDetail'
import { store } from '../../../../../../App'
import { postUploadCourseBatch } from '../../../../../../services/eLearning/course/detail'
import CheckDialog from '../CheckDialog'

export const handleImportFile = (dataImport) => (dispatch) => {
  dispatch(startLoading())
  dispatch(setImportFile(dataImport))
  setTimeout(() => {
    dispatch(setEnableButton())
    dispatch(stopLoading())
  }, 500)
}

export const handleSubmitAdd = (uuid) => async (dispatch) => {
  dispatch(loadingDialog())
  const body = handleBatchBody(uuid, false)
  const response = await dispatch(postUploadCourseBatch(body))
  dispatch(stopLoadingDialog())
  if (_.isNil(response.error)) {
    dispatch(
      openDialog({
        type: 'mini',
        maxWidth: '860',
        disableButton: true,
        content: <CheckDialog />,
      }),
    )
  }
}

export const handleBatchBody = (uuid, isSave) => {
  const { file } = store.getState().eLearningCourseBatchDetail
  const formData = new FormData()
  formData.append('courseUuid', uuid)
  formData.append('isSave', isSave)
  formData.append('file', file)
  return formData
}
