import _ from 'lodash'
import {
  agentUpdateProfile,
  agentUpdateProfileFilter,
  prospectProfileDownload,
  prospectProfileFilter,
} from '../../utils/apiPath'
import {
  resetTablePage,
  setFilterProp,
  setFilterTotal,
  setInitialTable,
  setSearchText,
  startLoading,
  stopLoading,
} from '../../redux/slices/table'
import { downloadFile } from '../../services/util'
import {
  prospectProfileCells,
  csUserProfileCells,
  agentUpdateProfileCells,
  agentUpdateProfileUuidCells,
} from '../../constants/manage/prospectProfile'
import { initialState } from '../../redux/slices/table/model'
import callAxios from '../../utils/baseService'

export const onDownload = (selected, sort, order, type) => async (dispatch) => {
  dispatch(startLoading())
  const body = {
    list: selected,
    order: order.toUpperCase(),
    sort: sort === 'updatedAt' ? 'updatedAt' : sort,
    type: type,
  }

  await dispatch(
    downloadFile({
      url: prospectProfileDownload,
      body: body,
      fileName: `รายการ ${type === 'CS' ? 'CS User' : 'Prospect'} Profile.xlsx`,
    }),
  )
  dispatch(stopLoading())
}

export const fetchDataList =
  (
    method,
    table,
    page,
    statusList,
    filterProp,
    keyword,
    setInitial,
    agentType,
    agentStatus = [],
  ) =>
  async (dispatch) => {
    dispatch(startLoading())
    const isInitial = method === 'initial'
    const tableProps = isInitial ? initialState.table : table
    const filter = isInitial ? null : filterProp
    const sortBy = tableProps.sort
    const realPage = page <= 0 ? 1 : +page
    const firstNameTH = isInitial ? '' : _.get(filter, 'firstNameTH', keyword)

    const status = isInitial ? [] : statusList
    const limit = tableProps.limit
    const order = tableProps.order.toString().toUpperCase()

    const tempCode = _.get(filter, 'tempCode', '')
    const lastNameTH = _.get(filter, 'lastNameTH', '')
    const mobileNo = _.get(filter, 'mobileNo', '')
    const email = _.get(filter, 'email', '')
    const unitNumber = _.get(filter, 'unitNumber', '')
    const type = _.defaultTo(agentType, 'AGENT')
    const createdBy = _.get(filter, 'createdBy', '')
    const updatedBy = _.get(filter, 'updatedBy', '')

    const body = {
      tempCode: tempCode,
      firstNameTH: method === 'filter' ? keyword : firstNameTH,
      lastNameTH: lastNameTH,
      mobileNo: mobileNo,
      email: email,
      unitNumber: unitNumber,
      limit: limit,
      order: order.toString().toUpperCase(),
      page: realPage,
      sort: sortBy,
      status: status,
      type: type,
      agentStatus: agentStatus,
      createdBy: createdBy,
      updatedBy: updatedBy,
    }

    const isCancel = await callAxios
      .post(prospectProfileFilter, body)
      .then(({ data }) => {
        const rows = data.result
        dispatch(
          setInitialTable({
            rows,
            allCount: data.totalCount,
            headCells:
              type === 'CS' ? csUserProfileCells : prospectProfileCells,
            placeholder: 'ค้นหาชื่อ',
            searchKey: 'firstNameTH',
            handleSearch: (text) =>
              dispatch(handleQuickSearch(table, text, agentType)),
            onDownload: (selected, sort, order) =>
              dispatch(onDownload(selected, sort, order, type)),
          }),
        )
      })
      .catch((e) => {
        dispatch(
          setInitialTable({
            rows: [],
            allCount: 0,
            headCells: prospectProfileCells,
          }),
        )
        return e.message?.includes('method')
      })

    setInitial && setInitial(false)
    if (!isCancel) dispatch(stopLoading())
  }

export const fetchAgentUpdateDataList =
  (
    method,
    table,
    page,
    statusList,
    filterProp,
    keyword,
    setInitial,
    setDisable,
  ) =>
  async (dispatch) => {
    dispatch(startLoading())
    const isInitial = method === 'initial'
    const tableProps = isInitial ? initialState.table : table
    const sortBy = tableProps.sort
    const realPage = page <= 0 ? 1 : +page

    const limit = tableProps.limit
    const order = tableProps.order.toString().toUpperCase()

    const body = {
      limit: limit,
      order: order.toString().toUpperCase(),
      page: realPage,
      sort: sortBy,
    }

    const isCancel = await callAxios
      .post(agentUpdateProfileFilter, body)
      .then(({ data }) => {
        const rows = data.result
        setDisable(data.inprogress)
        dispatch(
          setInitialTable({
            rows,
            allCount: data.totalCount,
            headCells: agentUpdateProfileCells,
            isCheckBox: false,
          }),
        )
      })
      .catch((e) => {
        dispatch(
          setInitialTable({
            rows: [],
            allCount: 0,
            headCells: agentUpdateProfileCells,
            isCheckBox: false,
          }),
        )
        return e.message?.includes('method')
      })

    setInitial && setInitial(false)
    if (!isCancel) dispatch(stopLoading())
  }

export const fetchUpdateAgentProfileData = async () => {
  await callAxios
    .get(agentUpdateProfile)
    .then(() => {
      window.location.reload()
    })
    .catch((e) => {
      window.location.reload()
      return e.message?.includes('method')
    })
}

export const fetchAgentUpdateDataUuidList =
  (method, table, page, statusList, filterProp, keyword, setInitial, uuid) =>
  async (dispatch) => {
    dispatch(startLoading())
    const isInitial = method === 'initial'
    const tableProps = isInitial ? initialState.table : table
    const sortBy = tableProps.sort
    const realPage = page <= 0 ? 1 : +page

    const limit = tableProps.limit
    const order = tableProps.order.toString().toUpperCase()

    const body = {
      uuid: uuid,
      limit: limit,
      order: order.toString().toUpperCase(),
      page: realPage,
      sort: sortBy,
    }

    const isCancel = await callAxios
      .post(agentUpdateProfileFilter, body)
      .then(({ data }) => {
        const rows = data.result
        dispatch(
          setInitialTable({
            rows,
            allCount: data.totalCount,
            headCells: agentUpdateProfileUuidCells,
            isCheckBox: false,
          }),
        )
      })
      .catch((e) => {
        dispatch(
          setInitialTable({
            rows: [],
            allCount: 0,
            headCells: agentUpdateProfileUuidCells,
            isCheckBox: false,
          }),
        )
        return e.message?.includes('method')
      })

    setInitial && setInitial(false)
    if (!isCancel) dispatch(stopLoading())
  }

export const onFilterClick = (table, filter, type) => (dispatch) => {
  dispatch(setSearchText(''))
  const statusAgentList = []
  const statusList = []
  if (filter.agentChecked) {
    if (type === 'PROSPECT') {
      if (filter.agent?.inActiveChecked) {
        statusAgentList.push('INACTIVE')
      }
      if (filter.agent?.activeChecked) {
        statusAgentList.push('ACTIVE')
      }
      if (filter.agent?.deletedChecked) {
        statusAgentList.push('DELETED')
      }
      if (filter.agent?.stealChecked) {
        statusAgentList.push('STEAL')
      }
      if (filter.agent?.terminatedChecked) {
        statusAgentList.push('TERMINATED')
      }
    }
  }
  if (filter.statusChecked) {
    if (filter.status?.activeChecked) {
      statusList.push('ACTIVE')
    }
    if (filter.status?.inActiveChecked) {
      statusList.push('INACTIVE', 'DELETED')
    }
  }

  const filterText = {
    tempCode: filter.tempCodeChecked ? filter.tempCode : '',
    firstNameTH: filter.nameChecked ? filter.firstNameTH : '',
    lastNameTH: filter.lastNameChecked ? filter.lastNameTH : '',
    mobileNo: filter.mobileNoChecked ? filter.mobileNo : '',
    unitNumber: filter.unitNumberChecked ? filter.unitNumber : '',
    email: filter.emailChecked ? filter.email : '',
    status: filter.statusChecked ? statusList : [],
    agentStatus: filter.agentChecked ? statusAgentList : [],
    createdBy: filter.createdByChecked ? filter.createdBy : '',
    updatedBy: filter.updatedByChecked ? filter.updatedBy : '',
  }
  dispatch(setFilterProp(filterText))
  dispatch(setFilterTotal(filter))
  dispatch(resetTablePage())
  dispatch(
    fetchDataList(
      'filter',
      table,
      1,
      statusList,
      filterText,
      filterText.firstNameTH,
      null,
      type === 'PROSPECT' ? 'AGENT' : type,
      statusAgentList,
    ),
  )
}

export const handleQuickSearch = (table, text, agentType) => (dispatch) => {
  dispatch(setSearchText(text))
  dispatch(setFilterProp(null))
  dispatch(resetTablePage())
  const statusList = ['ACTIVE', 'INACTIVE', 'DELETED']
  dispatch(
    fetchDataList('search', table, 1, statusList, null, text, null, agentType),
  )
}
