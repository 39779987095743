import { store } from '../../../../../App'
import _ from 'lodash'

export const mutateFilterPropToBody = () => {
  const { filterProp } = store.getState().table.filter

  return {
    name: _.get(filterProp, 'name', ''),
    id: _.get(filterProp, 'id', ''),
    openDateStart: _.get(filterProp, 'openDateStart', ''),
    openDateFinish: _.get(filterProp, 'openDateFinish', ''),
    status: _.get(filterProp, 'status', []),
    createdBy: _.get(filterProp, 'createdBy', ''),
    updatedBy: _.get(filterProp, 'updatedBy', ''),
  }
}
